import styled from 'styled-components';
import { TITLE_SIZES } from '../../constants/fonts';
import COLORS from '../../constants/colors';
import { withClient } from '@axeptio/widget-client/src/contexts/ClientContext.js';

const Title = withClient(styled.div`
  &&& {
    background: none !important;
    border: none !important;
    word-break: initial !important;
    text-transform: none !important;
    width: auto !important;
    height: auto !important;
    clear: none !important;

    display: block !important;
    padding: 0 !important;
    margin: ${props => (props.margin ? props.margin : 0)} !important;
    margin-bottom: ${props => (props.noMargin ? 0 : '1rem')} !important;
    font-family: ${props => props.fonts.title.family}, sans-serif !important;
    font-size: ${TITLE_SIZES.H1} !important;
    font-style: normal !important;
    line-height: 1.5 !important;

    ${props =>
      props.light &&
      `
      font-weight: 300 !important;
    `};

    ${props =>
      props.bold &&
      `
      font-weight: 700 !important;
    `};

    ${props =>
      props.color === 'blue' &&
      `
      color: ${COLORS.BLUE} !important;
    `};

    ${props =>
      props.as === 'h2' &&
      `
      font-size: ${TITLE_SIZES.H2} !important;
    `};

    ${props =>
      props.as === 'h3' &&
      `
      font-size: ${TITLE_SIZES.H3} !important;
    `};

    ${props =>
      props.as === 'h4' &&
      `
      font-size: ${TITLE_SIZES.H4} !important;
    `};

    ${props =>
      props.as === 'h5' &&
      `
      font-size: ${TITLE_SIZES.H5} !important;
    `};

    ${props =>
      props.as === 'h6' &&
      `
      font-size: ${TITLE_SIZES.H6} !important;
    `};

    ${props =>
      props.large &&
      `
      font-size: 2.5rem !important;
      margin-bottom: 50px !important;
    `};
  }
`);

export default Title;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSwitch from './ReactSwitch';
import { ClientConsumer } from '@axeptio/widget-client/src/contexts/ClientContext.js';

const Label = styled.label`
  display: block;
  margin: 0;
  width: auto;
  font-size: 17px;
  line-height: 22px;
  height: 28px;

  ${props =>
    props.small &&
    `
    height: 22px;
  `};
`;

class ToggleSwitchDraggable extends Component {
  render() {
    const { onChange, uncheckedIcon, checkedIcon, checked, small, disabled, identifier, ariaLabel, dataFeature } = this.props;

    return (
      <ClientConsumer>
        {({ colors }) => (
          <Label htmlFor={identifier ? `${identifier}-normal-switch` : 'normal-switch'} small={small}>
            <ReactSwitch
              dataFeature={dataFeature}
              checked={typeof checked === 'boolean' ? checked : false}
              small={small}
              onChange={onChange}
              onColor={colors.toggle_on || colors.main}
              offColor={colors.toggle_off || colors.GRAY_400}
              uncheckedIcon={uncheckedIcon ? uncheckedIcon : true}
              checkedIcon={checkedIcon ? checkedIcon : true}
              boxShadow="0px 3px 12px rgba(0, 0, 0, 0.15)"
              activeBoxShadow="0 0 0 10px rgba(0, 0, 0, 0.1)"
              id={identifier ? `${identifier}-normal-switch` : 'normal-switch'}
              disabled={disabled}
              aria-label={ariaLabel}
            />
          </Label>
        )}
      </ClientConsumer>
    );
  }
}
ToggleSwitchDraggable.propTypes = {
  small: PropTypes.bool,
  checked: PropTypes.bool,
  uncheckedIcon: PropTypes.element,
  checkedIcon: PropTypes.element,
  onChange: PropTypes.func.isRequired
};
export default ToggleSwitchDraggable;

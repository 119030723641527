import React, { Suspense, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { withApiContext } from '../../contexts/ApiContext';
import ComponentsMap from './Services/ComponentsMap';
import Footer from '../Footer/Footer';
import { getObjectsDiff } from '../../base/helpers';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import AppHeader from './AppHeader';
import withEditor from './withEditor';
import { Content } from 'src/base/AdminStyles';
import update from 'immutability-helper';
import Templates from './Services/Templates';
import VendorsHydrater from './Services/Cookies/CookiesEditor/VendorsHydrater';
import EmbedForm from './Services/EmbedForm/EmbedForm';
import { withProjectContext } from 'src/contexts/ProjectContext';
import ErrorBoundary from './ErrorBoundary';

const SubjectRightForm = React.lazy(() =>
  import('./Services/DPO/SubjectRightForm').then(module => ({ default: module.SubjectRightForm }))
);
const SubjectRightWizard = React.lazy(() =>
  import('./Services/DPO/SubjectRightForm').then(module => ({ default: module.SubjectRightWizard }))
);
const WrapperWidget = React.lazy(() => import('./Services/TCF/TcfWraped'));
const AugmentedConsentHelperForm = React.lazy(() => import('./Services/Processings/AugmentedConsentHelperForm'));
const AugmentedConsentHelperSelect = React.lazy(() => import('./Services/Processings/AugmentedConsentHelperSelect'));
const ProcessorForm = React.lazy(() => import('./Services/ProcessorForm/ProcessorForm'));
const VendorForm = React.lazy(() => import('./Services/Cookies/CookiesEditor/VendorForm'));
const VendorSelect = React.lazy(() => import('./Services/Cookies/CookiesEditor/VendorSelect'));
const CookiesStepForm = React.lazy(() => import('./Services/Cookies/CookiesEditor/CookiesStepForm'));
const ButtonForm = React.lazy(() => import('./Services/Processings/ButtonForm'));
const CookiesStepWizard = React.lazy(() => import('./Services/Cookies/CookiesEditor/CookiesStepWizard'));
const CookiesSpecialStepWizard = React.lazy(() => import('./Services/Cookies/CookiesEditor/CookiesSpecialStepWizard'));
const ProjectDesign = React.lazy(() => import('./Pages/ProjectDesign'));
const Organization = React.lazy(() => import('./Pages/Organization'));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function RouterComponent({
  breadCrumb = [],
  children,
  locale,
  state,
  api,
  footer = true,
  updateProjectId,
  waitProject,
  ...props
}) {
  const { projectId, config } = useParams();
  const conf = locale.projectConfigurations.filter(c => c.name === config)[0];
  const [breadCrumbInfo, setBreadCrumInfo] = useState({
    ProjectName: '',
    ServiceName: props.serviceName,
    ConfigName: '',
    ConfigPage: conf?.title || ''
  });

  useEffect(() => {
    if (projectId !== state.projectId) {
      updateProjectId(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setBreadCrumInfo({ ...breadCrumbInfo, ConfigPage: conf?.title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const updateFieldBreadCrumb = (field, value) => {
    setBreadCrumInfo({ ...breadCrumbInfo, [field]: value });
  };

  const additionalProps = {
    updateFieldBreadCrumb
  };

  let element = <Suspense fallback={<div></div>}>{React.cloneElement(children, additionalProps)}</Suspense>;

  if (waitProject) {
    if (!props.isProjectFetched) {
      element = null;
    }
  }

  return (
    <ErrorBoundary fallback={Error}>
      <AppHeader breadCrumb={breadCrumb} breadCrumbInfo={breadCrumbInfo} />
      <Content>
        {element}
        {footer && <Footer locale={locale} user={state.user} impersonate={api.impersonatedByAdmin} />}
      </Content>
    </ErrorBoundary>
  );
}

function ServiceWrapper(props) {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const service = props.locale.servicesMap[props.serviceName];
  if (!service || typeof ComponentsMap.Services[service.name] === 'undefined') {
    console.error('unknown service', service, ComponentsMap.Services);
    return <Navigate to="/404" />;
  }

  return (
    <RouterComponent {...props}>
      {React.createElement(ComponentsMap.Services[props.serviceName], {
        ...props,
        api: props.api,
        projectId: projectId,
        service,
        navigate
      })}
    </RouterComponent>
  );
}

function EditorWrapper(props) {
  const { projectId, serviceName, identifier } = useParams();

  const service = props.locale.servicesMap[serviceName];
  if (!service || typeof ComponentsMap.Editors[service.name] === 'undefined') {
    console.error('unknown service', service, ComponentsMap.Editors);
    return <Navigate to="/404" />;
  }

  return (
    <RouterComponent {...props} footer={false}>
      {React.createElement(ComponentsMap.Editors[serviceName], {
        ...props,
        api: props.api,
        projectId: projectId,
        service,
        project: props.project,
        id: identifier,
        defaultValue: props.locale.defaultValues[serviceName],
        updateFieldBreadCrumb: null
      })}
    </RouterComponent>
  );
}

const ConfigWrapper = withProjectContext(props => {
  const { projectId, config } = useParams();

  const conf = props.locale.projectConfigurations.filter(c => c.name === config)[0];
  if (!conf) {
    console.error('unknown configuration', config);
    return <Navigate to="/404" />;
  }
  const CompClass = ComponentsMap.ProjectConfiguration[config];
  if (!CompClass) {
    console.error('unable to find component for', config);
    return <Navigate to="/404" />;
  }

  const ConfigElement = React.createElement(CompClass, {
    ...props,
    projectId: projectId,
    collection: 'projects',
    id: projectId,
    api: props.api,
    user: props.user,
    stats: null
  });

  return (
    <RouterComponent {...props} footer={false} waitProject={conf.useStripe}>
      {conf.useStripe ? (
        <Elements stripe={stripePromise}>
          <Suspense>{ConfigElement}</Suspense>
        </Elements>
      ) : (
        <Suspense>{ConfigElement}</Suspense>
      )}
    </RouterComponent>
  );
});

function OrganizationWrapper(props) {
  const { organizationId } = useParams();
  const [org, setOrg] = useState(null);

  useEffect(() => {
    if (organizationId !== 'new') {
      props.api.getOrganization(organizationId, response => {
        setOrg(response);
      });
    } else {
      setOrg({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!organizationId) {
    return <Navigate to=".." relative="path" />;
  }

  if (!org) {
    return null;
  }

  return (
    <RouterComponent {...props}>
      <Elements stripe={stripePromise}>
        <Suspense fallback={<div></div>}>
          <Organization {...props} value={org} api={props.api} user={props.user} organizationId={org._id} />
        </Suspense>
      </Elements>
    </RouterComponent>
  );
}

function Wrapper(props) {
  const {
    projectId,
    identifier,
    projectName,
    settingsName,
    confirmationScreen,
    index,
    editableComponent,
    editableComponentMain,
    indexMain
  } = useParams();
  const navigate = useNavigate();

  return (
    <RouterComponent {...props} footer={props.footer}>
      {React.createElement(props.element, {
        projectId: projectId,
        service: props.locale.servicesMap[props.serviceName],
        defaultValue: props.locale.defaultValues[props.serviceName],
        project: props.project,
        id: identifier,
        projectName: projectName,
        serviceName: props.serviceName,
        settingsName,
        confirmationScreen,
        stepIndex: index,
        editableComponent,
        editableComponentMain,
        indexMain,
        navigate
      })}
    </RouterComponent>
  );
}

const TCFDesignMain = withApiContext(
  withEditor(props => {
    const { projectId, identifier } = useParams();
    const service = props.locale.servicesMap['tcf'];
    const [defaultContent, setDefaultContent] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDefaultContent = async () => {
        setDefaultContent(
          await props.api.getTCFDefaultContent(props.value.language, props.value.country, props.value.subdivision)
        );
        setLoading(false);
      };
      fetchDefaultContent().then();
    }, [props.api, props.value.language, props.value.country, props.value.subdivision]);

    if (Object.keys(defaultContent).length === 0) return null;

    return (
      <ProjectDesign
        component={({ value, project }) => (
          <Suspense fallback={<div></div>}>
            <WrapperWidget
              value={value}
              project={project}
              shouldRender={true}
              defaultContent={defaultContent}
              loading={loading}
            />
          </Suspense>
        )}
        includes={['color_scheme', 'position']}
        restrictions={[
          'button',
          'paintTransform',
          'style',
          'typography',
          'backdrop_inner',
          'card',
          'title',
          'button_border',
          'widget',
          'toggle_off',
          'toggle_on',
          'consent_button_bg',
          'consent_button_bg_hover',
          'consent_button_text',
          'consent_button_text_hover',
          'consent_button_border',
          'consent_button_border_hover'
        ]}
        projectId={projectId}
        value={props.value}
        api={props.api}
        locale={props.locale}
        service={service}
        project={props.project}
        id={identifier}
      />
    );
  })
);

function isObjectId(id) {
  const objectIdPattern = /^[0-9a-fA-F]{24}$/;
  return objectIdPattern.test(id);
}

/**
 * If you want to use the EditableList component you will have to define here the items and the value
 * You need to define a save function
 */
function EditableComponent(props) {
  const navigate = useNavigate();
  const [mountedProps, setMountedProps] = useState({});
  const [staticItems, setStaticItems] = useState(props.items);

  const MountVendorForm = async () => {
    const { value, api, language, indexMain, editableComponentMain, stepIndex, editableComponent } = props;
    const previewValue = structuredClone(value);
    const hydratedConfig = await new VendorsHydrater({ api, language }).hydrateConfig(previewValue);

    const setFormProps = (value = {}, items = []) => {
      setStaticItems(items);
      setMountedProps({ value, items });
    };

    if (indexMain === 'create') {
      setFormProps();
    } else if (isObjectId(indexMain)) {
      const query = { with: ['creator', 'parentId'], id: indexMain };
      api.fetchDocs(
        'vendors',
        data => {
          const vendor = data.find(vendor => vendor._id === indexMain);
          vendor ? setFormProps(vendor) : navigate('..', { relative: 'path' });
        },
        query
      );
    } else {
      const items = value[editableComponentMain]?.[stepIndex]?.[editableComponent] || [];
      const formValue = hydratedConfig[editableComponentMain]?.[stepIndex]?.[editableComponent]?.[indexMain] || {};
      setFormProps(formValue, items);
    }
  };

  const MountStepForm = async () => {
    const templates = new Templates(props.api);
    const stepDefaultTexts = await templates.getDefaultCookieStepStrings(props.language, props.country, props.subdivision);
    const { value, languagePack, editableComponent, stepIndex } = props;
    const previewValue = structuredClone(value);
    let hydratedConfig = previewValue;

    if (value.googleConsentMode.display) {
      const { title, message, purpose } = languagePack.display.consent_mode_step;
      const como_v2_step = { name: 'google_consent_mode_v2', title, message, purpose, layout: 'como_v2' };
      const position = value.googleConsentMode.position === 'last' ? hydratedConfig.steps.length : 1;
      hydratedConfig.steps.splice(position, 0, como_v2_step);
    }

    ['steps', 'specialSteps'].forEach(key => {
      if (hydratedConfig[key]?.length) {
        hydratedConfig[key] = hydratedConfig[key].map(step => ({
          ...stepDefaultTexts?.[step.name],
          ...step
        }));
      }
    });

    const items = value[editableComponent] || [];
    setStaticItems(items);
    setMountedProps({
      value: items[stepIndex] || {},
      defaultTexts: stepDefaultTexts,
      previewSteps: hydratedConfig.steps,
      previewSettings: hydratedConfig.settings,
      items
    });
  };

  const isNew = props.indexMain === 'new' || props.stepIndex === 'new';
  const EditableComponentMap = {
    vendors: {
      form: VendorForm,
      wizzard: VendorSelect,
      blockIfNotMounted: true,
      mount: () => {
        MountVendorForm();
      },
      onSave: async val => {
        if (!isObjectId(props.indexMain)) {
          val = val?.filter(v => v);
          let step = props.value[props.editableComponentMain];
          step[props.stepIndex] = update(step[props.stepIndex], {
            vendors: { $set: val }
          });
          await props.onUpdateFieldAsync(props.editableComponentMain, step);
          if (Number(props.indexMain) >= 0) {
            navigate('../../', { relative: 'path' });
          } else {
            navigate('../new', { relative: 'path' });
          }
        } else {
          navigate('../new', { relative: 'path' });
        }
      },
      onDelete: async val => {
        if (isObjectId(props.indexMain)) {
          await props.api.client.delete(`${props.api.baseURL}/vault/vendors/${props.indexMain}`);
          navigate('..', { relative: 'path' });
        } else {
          let items = mountedProps.items || staticItems;
          items = update(items, { $splice: [[Number(props.indexMain), 1]] });
          DynamicForm?.onSave(items);
        }
      }
    },
    steps: {
      form: CookiesStepForm,
      wizzard: CookiesStepWizard,
      blockIfNotMounted: true,
      mount: () => {
        MountStepForm();
      },
      onSave: async (val, async) => {
        let vendorsHydrater = new VendorsHydrater({
          api: props.api,
          language: props?.language
        });
        await vendorsHydrater.init();
        if (async || isNew) {
          await props.onUpdateFieldAsync(
            'steps',
            val.map(step => vendorsHydrater.dehydrateStepVendors(step))
          );
        } else {
          props.onUpdateField(
            'steps',
            val.map(step => vendorsHydrater.dehydrateStepVendors(step))
          );
        }
        if (isNew) {
          navigate('../../', { relative: 'path' });
        }
      }
    },
    buttons: {
      form: ButtonForm,
      wizzard: null,
      onSave: async val => {
        await props.onUpdateSpecsAsync({ buttons: { $set: val } });
      }
    },
    specialSteps: {
      form: CookiesStepForm,
      wizzard: CookiesSpecialStepWizard,
      blockIfNotMounted: true,
      mount: () => {
        MountStepForm();
      },
      field: 'specialSteps',
      onSave: async val => {
        await props.onUpdateFieldAsync('specialSteps', val);
        if (isNew) {
          navigate('../../', { relative: 'path' });
        }
      }
    },
    'subject-rights': {
      form: SubjectRightForm,
      wizzard: SubjectRightWizard,
      onSave: async val => {
        let state = {
          value: { ...props.project.DPO } || {}
        };
        state = update(state, { value: { subjectRights: { $set: val } } });
        const diff = getObjectsDiff({
          baseObject: props.project.DPO || {},
          newObject: state.value,
          path: 'DPO'
        });
        await props.api.patchAsync('projects', props.projectId, diff);
        if (isNew) {
          navigate('../../', { relative: 'path' });
        }
      }
    },
    processors: {
      form: ProcessorForm,
      wizzard: null,
      onSave: async val => {
        let state = {
          config: { consentWidgetStrings: {}, ...props.value }
        };
        state.config = update(state.config, {
          processors: { $set: val }
        });
        await props.onUpdateSpecsAsync({ $merge: state.config });
      }
    },
    helpers: {
      form: AugmentedConsentHelperForm,
      wizzard: AugmentedConsentHelperSelect,
      onSave: async val => {
        await props.onUpdateSpecsAsync({ helpers: { $set: val } });
        if (isNew) {
          navigate('../../', { relative: 'path' });
        }
      }
    },
    processingsButtons: {
      form: ButtonForm,
      wizzard: null,
      onSave: async val => {
        await props.onUpdateSpecsAsync({ buttons: { $set: val } });
      }
    },
    embeddings: {
      form: EmbedForm,
      wizzard: null,
      onSave: async val => {
        // DPO doesn't have withEditor because it's not a config
        if (props.serviceName === 'dpo') {
          let state = {
            value: { ...props.project.DPO } || {}
          };
          state = update(state, { value: { embeddings: { $set: val } } });
          const diff = getObjectsDiff({
            baseObject: props.project.DPO || {},
            newObject: state.value,
            path: 'DPO'
          });
          await props.api.patchAsync('projects', props.projectId, diff);
        } else {
          await props.onUpdateFieldAsync('embeddings', val);
        }
      }
    }
  };

  useEffect(() => {
    DynamicForm?.mount?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DynamicForm = EditableComponentMap[props.editableComponent];

  if (!DynamicForm) return <Navigate to="/404" />;

  const DynamicComponent = isNew && DynamicForm.wizzard ? DynamicForm.wizzard : DynamicForm.form;

  if (props.stepIndex === undefined) {
    return <Navigate to=".." relative="path" />;
  }

  if (props.editableComponentMain && props.editableComponent && props.indexMain === undefined) {
    return <Navigate to=".." relative="path" />;
  }

  if (DynamicForm.blockIfNotMounted && !Object.keys(mountedProps).length) return null;

  return React.createElement(DynamicComponent, {
    ...props,
    ...mountedProps,
    isNew: isNew,
    onSave: val => {
      let items = staticItems;
      if (isNew) {
        if (DynamicForm.wizzard && props.wizardCreatesMultipleItems) {
          DynamicForm?.onSave(val);
        } else {
          items = items ? update(items, { $push: [val] }) : [val];
          DynamicForm?.onSave(items);
        }
      } else {
        items = update(items, { [props.indexMain || props.stepIndex]: { $set: val } });
        DynamicForm?.onSave(items);
      }
    },
    onDelete: async val => {
      if (!DynamicForm.onDelete) {
        let items = staticItems;
        items = update(items, { $splice: [[Number(props.indexMain || props.stepIndex), 1]] });
        await DynamicForm?.onSave(items, true);
        navigate('..', { relative: 'path' });
      } else {
        DynamicForm.onDelete(val);
      }
    }
  });
}

export {
  RouterComponent,
  ServiceWrapper,
  EditorWrapper,
  ConfigWrapper,
  OrganizationWrapper,
  Wrapper,
  TCFDesignMain,
  EditableComponent
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../Icon/Icon';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';

const Tooltip = styled.div`
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: 6px;
  padding: 4px 12px;
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
  border-radius: 1000px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.GRAY_900};
  border: 1px solid ${COLORS.BLACK};
  visibility: hidden;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.35s ease;

  ${props =>
    props.show &&
    `
    visibility: visible;
    opacity: 1;
  `};
`;

const Style = styled.button`
  outline: none;
  border: none;
  user-select: none;
  text-decoration: none;
  background: none;

  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 14px 20px;
  font-family: ${FONTS.PRIMARY};
  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.BLUE_900};
  background: ${COLORS.BLUE_100};
  border-radius: 1000px;
  cursor: pointer;
  transition: all 0.15s ease;

  > span:first-child {
    margin-right: 20px;
  }

  &:hover {
    background: ${COLORS.BLUE_200};

    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
  }

  ${props =>
    props.isCopied &&
    `
    svg {
      transform: scale(1.4);
    }
  `};

  ${props =>
    props.small &&
    `
    font-size: 12px;
    padding: 6px 14px;

    ${Tooltip} {
      padding: 2px 8px;
      font-size: 12px;
    }
  `};
`;

const CopyText = ({ text, tooltipLabel, tooltipLabelSuccess, small }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 800);
    }
  });

  return (
    <CopyToClipboard text={text}>
      <Style small={small} isCopied={isCopied} onClick={() => setIsCopied(true)}>
        <span>{text}</span>
        <Icon name={isCopied ? 'check' : 'copy'} size={small ? 12 : 16} color={COLORS.BLUE_800} />
        <Tooltip show={isCopied}>{isCopied ? tooltipLabelSuccess : tooltipLabel}</Tooltip>
      </Style>
    </CopyToClipboard>
  );
};

CopyText.propTypes = {
  text: PropTypes.string,
  tooltipLabel: PropTypes.string,
  tooltipLabelSuccess: PropTypes.string,
  small: PropTypes.bool
};

export default CopyText;

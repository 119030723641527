import React from 'react';
import styled from 'styled-components';
import ReactSelect, { components } from 'react-select';
import FONTS from '../../constants/fonts';
import COLORS, { MAIN_COLORS } from '../../constants/colors';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className="select-icon">
        <path d="M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z" />
      </svg>
    </components.DropdownIndicator>
  );
};

const CustomReactSelect = styled(ReactSelect)`
  .select-icon {
    fill: ${COLORS.GRAY_400};
  }

  &:hover .select-icon {
    fill: ${COLORS.GRAY_800};
  }

  ${props =>
    props.small &&
    `
    .react-select__control {
      padding: 2px 0;
    }
    .react-select__indicators .react-select__indicator{
      padding: 4px 6px 4px 4px;
    }
  `};

  ${props =>
    props.large &&
    `
    .react-select__control {
      min-height: 54px;
      padding: 8px 0;
    }
  `};

  ${props =>
    props.minWidth &&
    `
    .react-select__control {
      min-width: ${props.minWidth};
    }`};

  ${props =>
    props.customSize &&
    `
    .react-select__control {
      min-height: ${props.customSize};
    }
  `};
`;

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    zIndex: 2000,
    fontSize: '13px',
    fontWeight: state.isSelected ? 'bold' : 'normal',
    color: COLORS.GRAY_700,
    backgroundColor: state.isFocused ? COLORS.GRAY_50 : 'transparent',
    ':active': {
      ...provided[':active'],
      backgroundColor: state.isSelected ? COLORS.GRAY_100 : COLORS.YELLOW_200
    },
    borderLeft: state.isSelected ? '2px solid' + MAIN_COLORS.PRIMARY_COLOR : 'none'
  }),
  control: (provided, state) => ({
    ...provided,
    outline: 'none !important',
    appearance: 'none !important',
    fontFamily: FONTS.PRIMARY,
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '1.25',
    height: 'auto',
    minHeight: '34px',
    borderRadius: '6px',
    color: COLORS.GRAY_700,
    backgroundColor: state.isDisabled ? provided.backgroundColor : COLORS.WHITE,
    border: state.isFocused ? '1px solid ' + COLORS.GRAY_700 : '1px solid ' + COLORS.GRAY_300,
    cursor: 'pointer',
    transition: 'all 100ms ease',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(0 0 0 / 10%)' : 'none',
    ':hover': {
      ...provided[':hover'],
      borderColor: COLORS.GRAY_600,
      boxShadow: '0 0 0 2px rgba(0 0 0 / 10%)'
    }
  }),
  multiValue: provided => ({
    ...provided,
    color: COLORS.GRAY_700,
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR
  }),
  menu: provided => ({
    ...provided,
    zIndex: 10000
  })
};

const Select = ({
  small,
  large,
  multi,
  value,
  options,
  onChange,
  placeholder,
  closeMenuOnSelect,
  isDisabled,
  customSize,
  ...props
}) => (
  <CustomReactSelect
    isDisabled={isDisabled}
    styles={customStyles}
    isMulti={multi} // V
    value={value}
    options={options} // v
    placeholder={placeholder} //v
    onChange={onChange} // v
    classNamePrefix="react-select" // V
    closeMenuOnSelect={closeMenuOnSelect}
    small={small}
    large={large}
    customSize={customSize}
    components={{ DropdownIndicator }}
    {...props}
  />
);

export default Select;

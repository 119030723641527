import styled from 'styled-components';
import TypographyLabel from '../TypographyLabel/TypographyLabel';
import CheckboxRadioInput from '../CheckboxRadioInput/CheckboxRadioInput';

const Container = styled.label`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.colors.grey.v200};
  cursor: pointer;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  flex-grow: 1;

  padding: 12px;
  gap: 12px;
  ${({ width }) => width && `width: ${width};`}

  &:hover:not(:has(input:disabled, :focus, :checked)) {
    border: 2px solid ${({ theme }) => theme.colors.grey.v300};
  }

  &:has(input:focus) {
    border: 2px solid ${({ theme }) => theme.colors.grey.secondary};
    box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.colors.grey.v200};
  }

  &:has(input:checked) {
    border: 2px solid ${({ theme }) => theme.colors.grey.secondary};
  }

  &:has(input:disabled) {
    border: 2px solid ${({ theme }) => theme.colors.grey.v100};
    cursor: not-allowed;
  }

  &:has(input:disabled:checked) {
    border: 2px solid ${({ theme }) => theme.colors.grey.v300};
  }
`;

const Input = styled(CheckboxRadioInput)`
  align-self: flex-start;
`;

function CheckboxRadioRow({ type = 'checkbox', value, disabled, name, title, className, onChange, width }) {
  return (
    <Container width={width} className={className}>
      <Input type={type} value={value} disabled={disabled} size="small" name={name} onChange={onChange} />
      {Boolean(title) && (
        <TypographyLabel size="small" accent>
          {title}
        </TypographyLabel>
      )}
    </Container>
  );
}

export default CheckboxRadioRow;

import React from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { withClient } from '@axeptio/widget-client/src/contexts/ClientContext.js';
import Tooltip from '../Tooltip/Tooltip';

const defaultSize = 22;

const Label = styled.span`
  font-family: ${FONTS.PRIMARY};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.125em;
  color: ${COLORS.GRAY_900};
  ${props => props.uppercaseLabel && `text-transform: uppercase;`};
`;

const Checkmark = withClient(styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${defaultSize}px;
  width: ${defaultSize}px;
  border-radius: ${props => (props.type === 'radio' ? '50%' : '4px')};
  border: 1px solid ${COLORS.GRAY_300};
  background-color: ${COLORS.WHITE};
  transition: border 0.15s ease;
  z-index: 50;

  ${props =>
    props.type === 'radio' &&
    `
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${COLORS.WHITE};
      display: none;
    }
  `};

  ${props =>
    props.type === 'checkbox' &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 8px;
      width: 5px;
      height: 12px;
      border: solid ${COLORS.WHITE};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      display: none;
    }
  `};
`);

const RadioCheckboxStyle = withClient(styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0;
  margin-bottom: ${props => (props.noMargin ? 0 : 20)}px;
  height: ${defaultSize + 2}px;
  padding-left: ${defaultSize + 10}px;
  cursor: pointer;
  user-select: none;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    top: 0;
    left: 0;
    height: ${defaultSize + 2}px;
    padding-left: ${defaultSize + 10}px;
    z-index: 100;
    ${props => props.inputWidth && `width: ${props.inputWidth};`};
    &:checked ~ ${Checkmark.WrappedComponent} {
      border-color: ${props => props.colors.main};
      background-color: ${props => props.colors.main};

      &:after {
        display: block;
      }
    }
  }

  &:hover input:not(:checked) ~ ${Checkmark.WrappedComponent} {
    border-color: ${COLORS.GRAY_400};
  }
`);

const RadioCheckbox = ({
  type,
  onChange,
  name,
  checked,
  label,
  noMargin,
  tooltip,
  disabled,
  inputWidth,
  uppercaseLabel = true
}) => (
  <Tooltip text={tooltip}>
    <RadioCheckboxStyle disabled={disabled} htmlFor={name} noMargin={noMargin} inputWidth={inputWidth}>
      <input type={type} name={name} onChange={onChange} id={name} checked={checked} disabled={disabled} />
      <Checkmark type={type} />
      {label && <Label uppercaseLabel={uppercaseLabel}>{label}</Label>}
    </RadioCheckboxStyle>
  </Tooltip>
);

export default RadioCheckbox;

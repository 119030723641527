const processingsTemplates = [
  {
    name: 'allcontact',
    label: 'Gardons le contact ! ',
    description: 'Au lieu d\'imposer un canal de communication à vos clients, proposez leur plutôt de choisir celui qui les intéresse le plus ! C\'est un excellent moyen pour maximiser ses opt-in tout en donnant le contrôle à vos utilisateurs !  ',
    icon: 'icon-consentement-contact',
    title: 'Gardons le contact ! ',
    subtitle: 'Téléphone, eMail, SMS : c\'est vous qui décidez ! ',
    message: 'On aime entretenir une relation personnalisée avec nos clients. Et pour commencer du bon pied, il est important de coller à vos attentes. C\'est pourquoi nous vous proposons de choisir votre moyen de communication privilégié. ',
    isFree: false,
    language: 'fr'
  },
  {
    name: 'newsletter',
    label: 'Newsletter, Liste de diffusion',
    description: 'Canal de communication privilégié par les entreprises, la liste de diffusion par email est encore, et pour longtemps, un passage obligé pour les marques. Chez Axeptio nous sommes compatibles avec les principaux routeurs du marché : nous discutons avec leurs API pour synchroniser les consentements avec les listes de désinscriptions.',
    icon: 'icon-consentement-newsletter',
    title: 'Notre newsletter',
    subtitle: 'Vous l\'aurez à l\'abonne',
    message: 'C\'est Quentin, notre responsable contenus qui a la lourde tâche de composer une newsletter toujours fraîche et intéressante. Il sélectionne des actualités en rapport avec notre produit, quelques GIF bien marrants et des anecdotes sympa pour vos apéros networking.',
    isFree: true,
    language: 'fr'
  },
  {
    name: 'phone',
    label: 'Prospection téléphonique',
    description: 'Gardez langue avec vos clients et offrez leur un contact personnalisé et qualitatif. En plus de leurs consentements, nous vous permettons d\'interroger vos utilisateurs sur leurs disponibilités afin de leur offrir la meilleure expérience possible.',
    icon: 'icon-consentement-phone',
    title: 'Parfois, c\'est mieux de se parler vraiment non ? ',
    subtitle: 'En plus, c\'est vous qui décidez de tout ! ',
    message: 'Guillaume, et son équipe vous appelle toujours avec le sourire. Et parce qu\'il est hors de question de vous déranger, on vous pemet aussi de choisir à quel moment vous voulez être appelé ! Pratique non ? ',
    isFree: false,
    language: 'fr'
  },
  {
    name: 'contest',
    label: 'Jeu concours',
    description: 'Un jeu concours est un bon moyen de collecter des données volontairement et de bonne qualité. Attention cependant à bien établir un règlement et à récolter un consentement conforme.',
    icon: 'icon-consentement-jeu',
    title: 'Ça vous dit de participer à notre jeu ? ',
    subtitle: 'Y\'a plein de choses à gagner ! ',
    message: 'De temps à autre, on lance de petits jeux pour vous gagner des lots sélectionnés par nos équipes. Mais pour être tirer au sort, on doit vous demander si vous êtes d\'accord pour qu\'on utilise vos données. Alors, c\'est oui ? ',
    isFree: false,
    language: 'fr'
  },
  {
    name: 'sms',
    label: 'SMS',
    description: 'Vous voulez envoyer des SMS à vos clients pour, par exemple, les avertir de période de promotion ou de soldes ? Il vous faut leur consentement. Nous avons préparé un modèle parfait pour ça.',
    icon: 'icon-consentement-sms',
    title: 'De temps à autre, un SMS avec des bonnes nouvelles ',
    subtitle: 'Des codes promos ou des invitations à nos évènements ! ',
    message: 'Promis, on abusera pas. Juste ce qu\'il faut pour vous faire profiter de bons plans, avant tout le monde ! Deal ? ',
    isFree: false,
    language: 'fr'
  }
];

export default processingsTemplates;
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useApi } from '../../contexts/ApiContext';
import assets from '../../services/Assets';
import LocalePickerDropdown, { LocaleButton } from '../AxeptioAdmin/LocalePickerDropdown';
import Img from '../Img/Img';
import Modal from '../Modal/Modal';
import { addToast } from '../Toast/Toast';
import OnboardingCompleteStep from './OnboardingCompleteStep';
import UserCompanyCmsStep from './UserCompanyCmsStep';
import UserCompanyFieldStep from './UserCompanyFieldStep';
import UserCompanyStep from './UserCompanyStep';
import UserCompanyTypeStep from './UserCompanyTypeStep';
import UserNameStep from './UserNameStep';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 24px 16px 24px;
`;

function Stepper({ onComplete, user }) {
  const screens = [
    user?.lastName ? null : UserNameStep,
    UserCompanyStep,
    UserCompanyTypeStep,
    UserCompanyFieldStep,
    UserCompanyCmsStep
  ].filter(e => e !== null);

  const [currentStep, setCurrentStep] = useState(0);

  function onNext(skipNb = 0) {
    if (currentStep === screens.length - 1) {
      onComplete?.();
      return;
    }
    setCurrentStep(prev => prev + 1 + skipNb);
  }

  function onBack(skipNb = 0) {
    setCurrentStep(prev => prev - (1 + skipNb));
  }

  return (
    <>
      <Header>
        <Img src={assets.image('logo-axeptio-dark')} height={24} />

        <LocalePickerDropdown>
          <LocaleButton />
        </LocalePickerDropdown>
      </Header>
      {screens.map((Screen, index) => {
        return (
          currentStep === index && (
            <Screen key={index} onBack={onBack} onNext={onNext} footerCount={screens.length} currentStep={currentStep} />
          )
        );
      })}
    </>
  );
}

function OnboardingModal() {
  const api = useApi();

  const [stepperIsComplete, setStepperIsComplete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState();

  const getUser = useCallback(async () => {
    await api
      .getUser(e => {
        if (!e?.data?.profileCompleted) setIsOpen(true);
        setUser(e);
      })
      ?.catch(error => addToast.error(error.message));
  }, [api]);

  const onClose = () => {
    if (stepperIsComplete) setIsOpen(false);
  };

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <Modal withCloseButton={false} customWidth="760px" isOpen={isOpen} onClose={onClose}>
      {stepperIsComplete ? (
        <OnboardingCompleteStep onClose={onClose} />
      ) : (
        <Stepper user={user} onComplete={() => setStepperIsComplete(true)} />
      )}
    </Modal>
  );
}

export default OnboardingModal;

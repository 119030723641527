import styled from 'styled-components';
import media from 'styled-media-query';
import COLORS from '../../constants/colors';

const Breadcrumb = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex-basis: 80%;
  max-width: 80%;
  overflow: hidden;

  > a,
  > span {
    position: relative;
    display: inline-block;
    padding: 2px 4px;
    margin: 0 16px 0 0;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    text-decoration: none;
    color: ${COLORS.GRAY_500};
    border-radius: 4px;

    span {
      display: block;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-child {
      margin: 0;
    }

    &:not(span) {
      &::after {
        content: '';
        position: absolute;
        top: 10px;
        right: -10px;
        display: inline-block;
        width: 8px;
        height: 8px;
        border: 2px solid ${COLORS.GRAY_500};
        border-left: none;
        border-bottom: none;
        transform: rotate(45deg);
      }
    }

    &:hover:not(span) {
      color: ${COLORS.BLUE};
      background-color: ${COLORS.GRAY_50};
      text-decoration: none;
    }
  }

  > span {
    color: ${COLORS.GRAY_900};
  }

  ${media.greaterThan('small')`
    display: flex;
  `};

  ${media.greaterThan('medium')`
    > a,
    > span {
      span {
        max-width: 180px;
      }
    }
  `};
`;

export default Breadcrumb;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/colors';
import Assets from '../../services/Assets';
import useOnClickOutside from '../../base/useOnClickOutside';
import Icon from '../Icon/Icon';
import Overlay from '../Overlay/Overlay';

export const DropdownMenuStyle = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  user-select: none;
`;

export const DropdownMenuItemText = styled.span.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  z-index: 10;
  position: relative;
`;

export const DropdownTrigger = styled.button`
  border: none;
  user-select: none;
  text-decoration: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  font-size: 28px;
  line-height: 1.25;
  font-weight: 600;
  color: ${COLORS.GRAY_800};
  background-color: ${COLORS.GRAY_50};
  border: 2px solid rgba(0 0 0 / 5%);
  border-radius: 1000px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.15s ease;

  &:focus {
    outline: none;
    border: 2px solid rgba(0 0 0 / 25%);
  }

  &:active {
    background-color: ${COLORS.GRAY_100};
    border: 2px solid rgba(0 0 0 / 25%);
  }

  ${props =>
    props.active &&
    `
    background-color: ${COLORS.WHITE};
    border: 2px solid rgba(0 0 0 / 25%);
  `};

  ${props =>
    !props.active &&
    `
    &:hover {
      background-color: ${COLORS.GRAY_50};
      border: 2px solid rgba(0 0 0 / 15%);

      .svgr {
        svg {
          path {
            fill: ${COLORS.GRAY};
            transition: none;
          }
        }
      }
    }
  `};
`;

export const DropdownContent = styled.div`
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease;

  > div {
    z-index: 10;
    position: relative;
    min-width: 360px;
    max-height: 70vh;
    padding: 14px 4px;
    background: ${COLORS.WHITE};
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.05);
    overflow-y: auto;
    transform: translateY(100%);
    transition: all 0.25s ease;
  }

  ${props =>
    props.isOpen &&
    `
    pointer-events: auto;
    visibility: visible;
    opacity: 1;

    > div {
      transform: translateY(0);
    }
  `};

  @media (min-width: 450px) {
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    bottom: auto;
    margin-top: 20px;

    > div {
      padding: 4px;
      border-radius: 8px;
      transform: translateY(0);
      visibility: hidden;
      opacity: 0;
    }

    ${props =>
      props.isOpen &&
      `
      > div {
        visibility: visible;
        opacity: 1;
      }
    `};
  }

  @media (min-width: 768px) {
    > div {
      max-height: 90vh;
    }
  }
`;

export const DropdownMenuTitle = styled.span`
  display: block;
  padding: 12px 12px 4px;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  cursor: default;
  color: ${COLORS.GRAY_400};
`;

export const DropdownMenuSeparator = styled.span`
  display: block;
  margin: 4px -4px;
  height: 1px;
  background: ${COLORS.GRAY_150};
`;

export const DropdownMenuItemStyle = styled.span.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  -webkit-appearance: none;
  background: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 20px 6px;
  width: 100%;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 500;
  text-decoration: none;
  color: ${COLORS.GRAY_700};
  border-radius: 4px;
  cursor: pointer;

  > span:first-of-type {
    margin-right: 4px;
  }

  .svgr,
  img {
    z-index: 10;
    position: relative;
    display: block;
    flex-shrink: 0;
    margin-right: 8px;
  }

  .svgr {
    circle,
    path {
      fill: ${COLORS.GRAY_600};
    }
  }

  ${props =>
    props.withPaint &&
    `
    background: url(${Assets.image('paint_icon_gray')}?w=35) 20px center no-repeat;

    &:hover {
      color: ${props.isLocked ? COLORS.GRAY_700 : COLORS.BLUE};
      background: ${COLORS.GRAY_50} url(${Assets.image('paint_icon_gray')}?w=35) 20px center no-repeat;
    }
  `};

  ${props =>
    props.withoutPaintButHover &&
    `
    &:hover {
      color: ${props.isLocked ? COLORS.GRAY_700 : COLORS.BLUE};
      background: ${COLORS.GRAY_50};
    }
  `};

  ${props =>
    props.isLocked &&
    `
    &::after {
      content: '';
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
    }
  `};

  ${props =>
    props.warning &&
    `
    &:hover {
      color: ${COLORS.REDORANGE};
      background: ${COLORS.GRAY_50};
    }
  `};

  ${props =>
    props.danger &&
    `
    &:hover {
      color: ${COLORS.REDORANGE};
      background: ${COLORS.REDORANGE_50};

      &:hover {
        .svgr {
          circle,
          path {
            fill: ${COLORS.REDORANGE};
          }
        }
      }
    }
  `};

  ${props =>
    props.hover &&
    `
    &:hover {
      color: ${COLORS.GRAY};
      background: ${COLORS.GRAY_50};

      &:hover {
        .svgr {
          circle,
          path {
            fill: ${COLORS.GRAY_900};
          }
        }
      }
    }
  `};
`;

const DropdownMenu = ({ menuItemClicked, children, iconName, ...props }) => {
  const ref = useRef();
  const [menuIsOpen, setMenuIsOpen] = useState(menuItemClicked);

  useOnClickOutside(ref, () => {
    const elements = document.querySelector('#modal_root').childNodes;
    const classes = [];
    elements.forEach(element => {
      if (element.childNodes.length !== 0) {
        classes.push(element.className);
      }
    });
    if (!classes.includes('modal')) {
      setMenuIsOpen(false);
    }
  });

  useEffect(() => {
    if (menuItemClicked) {
      setMenuIsOpen(false);
    }
  }, [menuItemClicked]);

  return (
    <DropdownMenuStyle ref={ref}>
      <DropdownTrigger active={menuIsOpen} onClick={() => setMenuIsOpen(!menuIsOpen)} aria-label={props['aria-label']}>
        <Icon name={iconName ? iconName : 'three_dots_vert'} size={22} color={COLORS.GRAY_700} />
      </DropdownTrigger>
      <DropdownContent isOpen={menuIsOpen}>
        <div>{children}</div>
      </DropdownContent>
      <Overlay mobileOnly show={menuIsOpen} onClick={() => setMenuIsOpen(!menuIsOpen)} />
    </DropdownMenuStyle>
  );
};

export default DropdownMenu;

import { css } from 'styled-components';
import FONTS, { TITLE_SIZES } from '../../constants/fonts';
import COLORS from '../../constants/colors';

const typography = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${FONTS.TITLE};
    line-height: 1.2;
    margin: 0;
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: ${TITLE_SIZES.H1};
  }

  h2 {
    font-size: ${TITLE_SIZES.H2};
  }

  h3 {
    font-size: ${TITLE_SIZES.H3};
  }

  h4 {
    font-size: ${TITLE_SIZES.H4};
  }

  h5 {
    font-size: ${TITLE_SIZES.H5};
  }

  h6 {
    font-size: ${TITLE_SIZES.H6};
  }

  p {
    margin: 0 0 20px;
    font-size: 1rem;
  }

  a {
    color: ${COLORS.BLACK};
    text-decoration: none;
  }
`;

export { typography };

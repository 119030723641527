import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useApi } from '../../contexts/ApiContext';
import { addToast } from '../Toast/Toast';
import Footer from './Footer';
import Select from '../Select/Select';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0px;
  }
`;
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 24px 16px;
  gap: 32px;
`;
const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  gap: 4px;
`;
const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  &:after {
    content: '*';
    color: red;
    margin-left: 4px;
  }
`;
const Error = styled.span`
  font-size: 9px;
  color: red;
`;

const UserCompanyTypeStep = ({ footerCount, currentStep, onBack, onNext }) => {
  const { t } = useTranslation();
  const api = useApi();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      companyType: '',
      companyDepartment: ''
    }
  });

  const companyTypeOptions = [
    { name: t('select_default_placeholder'), value: '' },
    { name: t('onboarding_modal_company_type_agency'), value: 'agency' },
    { name: t('onboarding_modal_company_type_enterprise'), value: 'enterprise' },
    { name: t('onboarding_modal_company_type_self_service'), value: 'selfservice' }
  ];
  const companyDepartmentOptions = [
    { name: t('select_default_placeholder'), value: '' },
    { name: t('onboarding_modal_company_department_tech'), value: 'tech' },
    { name: t('onboarding_modal_company_department_product'), value: 'product' },
    { name: t('onboarding_modal_company_department_marketing'), value: 'marketing' },
    { name: t('onboarding_modal_company_department_data'), value: 'data' },
    { name: t('onboarding_modal_company_department_business'), value: 'business' },
    { name: t('onboarding_modal_company_department_legal'), value: 'legal' },
    { name: t('onboarding_modal_company_department_other'), value: 'other' }
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async data => {
    if (!isLoading) {
      setIsLoading(true);
      await api
        .updateUser({ 'data.company.type': data.companyType, 'data.company.department': data.companyDepartment })
        .then(() => onNext(data.companyType === 'selfservice' ? 1 : 0))
        .catch(error => addToast.error(error.message));
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputWithLabel>
          <Label>{t('onboarding_modal_company_type_input_label')}</Label>
          <Controller
            name="companyType"
            control={control}
            rules={{ required: true, minLength: 1 }}
            render={({ field }) => <Select {...field} options={companyTypeOptions} />}
          />
          {errors?.companyType && <Error>{t('field_required')}</Error>}
        </InputWithLabel>
        <InputWithLabel>
          <Label>{t('onboarding_modal_company_department_input_label')}</Label>
          <Controller
            name="companyDepartment"
            control={control}
            rules={{ required: true, minLength: 1 }}
            render={({ field }) => <Select {...field} options={companyDepartmentOptions} />}
          />
          {errors?.companyDepartment && <Error>{t('field_required')}</Error>}
        </InputWithLabel>
        <Footer count={footerCount} currentStep={currentStep} onBack={() => onBack()} isLoading={isLoading} />
      </FormContainer>
    </Container>
  );
};

export default UserCompanyTypeStep;

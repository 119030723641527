import { localizeString, sanitizeLanguageCode } from '@axeptio/widget-client/src/utils/templateEngine.js';

/**
 * fetch a vendor from the API and return it adapted with given language
 * @param {Object}  vendor
 * @param {string}  language
 * @param {Object}  api
 * @param {?Object[]} categories
 * @returns {Promise<{longDescription: string, policyUrl: string, domain: string, name, _id: *, shortDescription: string, title: string, category: (*), type: (*), isAxeptioVendor: boolean}>}
 */
export async function fetchVendor(vendor, language, api, categories = null) {
  const id = typeof vendor === 'string' ? vendor : vendor._id || vendor.id || vendor.formerId;
  try {
    const { data: localizedVendor } = await api.client.get(`${api.baseURL}/vendors/solutions/${id}/${language}`);
    return localizedVendor;
  } catch (e) {
    api.logError(e);
  }
}

/**
 * return a vendor adapted with given language
 * @param {Object}  vendor
 * @param {string}  language
 * @param {Object[]}  categories
 * @param {boolean} isAxeptioVendor
 * @returns {{longDescription: string, policyUrl: string, domain: string, name, _id: *, shortDescription: string, title: string, category: (*), type: (*), isAxeptioVendor: boolean}}
 */
export function adaptNewVendor(vendor, language, categories, isAxeptioVendor = false) {
  const _id = vendor.id ?? vendor._id;
  vendor = vendor.data ?? vendor;

  return {
    _id,
    isAxeptioVendor,
    domain: vendor.domain || localizeString(vendor.website, { language }, true),
    longDescription: localizeString(vendor.longDescription, { language }, true),
    name: vendor.name,
    policyUrl: localizeString(vendor.policyUrl, { language }, true),
    shortDescription: localizeString(vendor.shortDescription, { language }, true),
    title: localizeString(vendor.title, { language }, true),
    category: categories
      ? categories[vendor.type]?.name ??
        vendor.categories?.map(category => localizeString(category.title, { language }, true)).join(', ') ??
        vendor.category
      : vendor.category,
    type: categories
      ? categories[vendor.type]?.value ?? vendor.categories?.map(category => category.name).join(', ') ?? vendor.type
      : vendor.type
  };
}

/**
 * return vendors categories translated in given language
 * @param {string}  language
 * @param {Object}  api
 * @returns {Promise<Object[]>}
 */
export async function getVendorsCategories(language, api) {
  const categories = {};
  const { data } = await api.client.get(`${api.baseURL}/vendors/categories`);
  data.forEach(category => {
    categories[category.data.name] = {
      name: localizeString(category.data.title, {
        language: sanitizeLanguageCode(language)
      }),
      value: category.data.name,
      id: category.id
    };
  });
  return categories;
}

export function gatherConfigVendorsIds(config) {
  const vendorsIds = [];
  ['steps', 'specialSteps'].forEach(key => {
    config[key]?.forEach(step => vendorsIds.push(...gatherStepVendorsIds(step)));
  });
  return [...new Set(vendorsIds)];
}

export function gatherStepVendorsIds(step) {
  const vendorsIds = [];
  step.vendors?.forEach(vendor => vendorsIds.push(typeof vendor === 'string' ? vendor : vendor?._id));
  return [...new Set(vendorsIds.filter(Boolean))];
}

export function cookiesConfigHasVendors(cookiesConfig) {
  return (
    !!cookiesConfig.steps?.find(step => step.vendors?.length) || !!cookiesConfig.specialSteps?.find(step => step.vendors?.length)
  );
}

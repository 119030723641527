import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import { DropdownMenuItemStyle } from '../../DropdownMenu/DropdownMenu';
import { withAccess } from '../../../contexts/AccessContext';
import Api from '../../../Api';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import Img from '../../Img/Img';
import Assets from '../../../services/Assets';
import { useTranslation } from 'react-i18next';

const LeaveProjectButton = ({ api, type, collection, docId, user }) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const docType = collection.substring(0, collection.length - 1);

  const leaveDocument = () => {
    api.removeUserFrom(collection, docId, user._id, () => {
      return (window.location.href = '/');
    });
  };

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirm}
        title={t('modal_leave_confirm_title')}
        confirmValue={t(`modal_leave_${docType}_confirm_value`)}
        titleIcon="icon-exit"
        alertDescription={t(`${docType}_users_quit_confirm_text`)}
        paragraphDescription={t(`project_users_quit_confirm_text_02`)}
        buttonLabel={t(`${docType}_users_quit_confirm_button`)}
        onConfirmClick={leaveDocument}
        onClose={() => setShowConfirm(false)}
      />
      {type === 'link' ? (
        <a
          href="/projects"
          onClick={e => {
            e.preventDefault();
            setShowConfirm(!showConfirm);
          }}
          rel="noreferrer noopener"
        >
          <DropdownMenuItemStyle warning>
            <Img src={Assets.image('icon-exit')} width={35} />
            <span>{t(`${docType}_users_quit_button`)}</span>
          </DropdownMenuItemStyle>
        </a>
      ) : (
        <Button tertiary onClick={() => setShowConfirm(!showConfirm)}>
          {t(`${docType}_users_quit_button`)}
        </Button>
      )}
    </>
  );
};

LeaveProjectButton.propTypes = {
  collection: PropTypes.oneOf(['projects', 'organizations']).isRequired,
  docId: PropTypes.string.isRequired,
  api: PropTypes.instanceOf(Api).isRequired
};

export default withAccess(LeaveProjectButton);

import React from 'react';
import languages from '@cospired/i18n-iso-languages';
import { supportedLanguages } from '../../base/helpers';
import PropTypes from 'prop-types';
import ReactSelect from '../ReactSelect/ReactSelect';
import LocalizationBackOffice from '../../services/LocalizationBackOffice';
import { useTranslation } from 'react-i18next';

const LocaleSelect = props => {
  const { t } = useTranslation();
  const {
    placeholder,
    locale,
    value,
    onChange,
    availableLocales = {},
    otherLanguages,
    emphasisLanguage,
    multi,
    ...selectProps
  } = props;

  // Sort languages by label
  const sortedLanguages = Object.entries(languages.getNames(locale?.locale?.toLowerCase() || 'en')).sort(
    (languagePairA, languagePairB) => {
      return languagePairA[1].localeCompare(languagePairB[1]);
    }
  );

  // Prepare options for each region
  const supportedLocales = Object.entries(availableLocales)
    // Build labels for each region
    .map(([regionCode, languageCodes]) => {
      const [countryCode, subdivisionCode] = regionCode.split('-');
      const countryName = LocalizationBackOffice.getCountryName(countryCode, locale.locale.toLowerCase());
      const subdivisionName = LocalizationBackOffice.getSubdivisionName(
        countryCode,
        subdivisionCode,
        locale.locale.toLowerCase()
      );
      const label = `${countryName}${subdivisionCode ? ` - (${subdivisionName})` : ''}`;
      return [regionCode, label, languageCodes];
    })
    // Sort regions by label
    .sort((regionPairA, regionPairB) => {
      return regionPairA[1].localeCompare(regionPairB[1]);
    })
    // Build options for each region
    .map(([regionCode, label, languageCodes]) => {
      return {
        label,
        options: sortedLanguages
          // Filter out languages not in the region
          .filter(languagePair => {
            return languageCodes.includes(languagePair[0]);
          })
          // Build options for each language
          .map(languagePair => ({
            value: `${languagePair[0]}-${regionCode}`,
            label: languagePair[1]
          }))
      };
    });

  // Prepare options for other languages
  const otherOptions = sortedLanguages
    // Filter out languages that we don't want to support (allow list)
    .filter(languagePair => {
      return supportedLanguages.includes(languagePair[0]);
    })
    // Build options for each language
    .map(languagePair => ({
      value: languagePair[0],
      label: languagePair[1]
    }));

  // Build options
  const options = [...supportedLocales];
  if (otherLanguages) {
    options.push({
      label: t('project_onboarding_language_others'),
      options: otherOptions
    });
  }

  // Label builder
  const labelBuilder = isoCode => {
    if (emphasisLanguage) {
      const languageName = LocalizationBackOffice.getLanguageNameByLocaleCode(isoCode, locale?.locale?.toLowerCase() || 'en');
      const regionName = LocalizationBackOffice.getRegionNameByLocaleCode(isoCode, locale?.locale?.toLowerCase() || 'en');
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: `<strong>${languageName}</strong>${regionName ? ` ${regionName}` : ''}`
          }}
        />
      );
    } else {
      return LocalizationBackOffice.getLocaleNameByLocaleCode(isoCode, locale?.locale?.toLowerCase() || 'en');
    }
  };

  // Build current option(s)
  let selectValue;
  if (multi) {
    selectValue = value?.map(value => {
      return { value, label: labelBuilder(value) };
    });
  } else {
    selectValue = value ? { value, label: labelBuilder(value) } : undefined;
  }

  return (
    <>
      <ReactSelect
        placeHolder={placeholder ?? t('select_language_placeholder')}
        options={options}
        value={selectValue}
        onChange={option => {
          return multi ? onChange && onChange(option.map(option => option.value)) : onChange && onChange(option.value);
        }}
        multi={multi}
        {...selectProps}
      />
    </>
  );
};

LocaleSelect.propTypes = {
  placeholder: PropTypes.string,
  locale: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  availableLocales: PropTypes.object,
  otherLanguages: PropTypes.bool,
  multi: PropTypes.bool,
  emphasisLanguage: PropTypes.bool,
  onChange: PropTypes.func
};

LocaleSelect.defaultProps = {
  otherLanguages: false,
  multi: false,
  emphasisLanguage: false
};

export default LocaleSelect;

import defaultValues from './defaultValues.js';
import adminStrings from './adminStrings.js';
import augmentedConsentHelpers from './augmentedConsentHelpers.js';
import processingsTemplates from './processingsTemplates.js';
import contractsTemplates from './contractsTemplates.js';
import servicesMap from './servicesMap.js';
import services from './services.js';
import plans from './plans.js';
import colors from './colors.js';
import projectConfigurations from './projectConfigurations.js';
import dpoRights from './dpoRights.js';
import publishMessages from './publishMessages.js';
import countries from './countries.js';
import protectionMechanisms from './protectionMechanisms.js';
import portabilityTemplates from './portabilityTemplates.js';
import claimTypes from './claimTypes.js';
import paintColors from './paintColors.js';
import emailTemplates from './emailTemplates.js';
import accessLimitsMap from './accessLimitsMap.js';
import accessLimits from './accessLimits.js';
import taxIds from './taxIds.js';
import featureFlags from './featureFlags.js';
import tcfTemplates from './tcfTemplates.js';

const index = {
  defaultValues,
  adminStrings,
  augmentedConsentHelpers,
  processingsTemplates,
  contractsTemplates,
  servicesMap,
  services,
  plans,
  colors,
  projectConfigurations,
  dpoRights,
  publishMessages,
  countries,
  protectionMechanisms,
  portabilityTemplates,
  claimTypes,
  paintColors,
  emailTemplates,
  accessLimitsMap,
  accessLimits,
  taxIds,
  featureFlags,
  tcfTemplates
};

export default index;
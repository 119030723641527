import { fetchVendor, getVendorsCategories, cookiesConfigHasVendors, gatherConfigVendorsIds } from '../vendorsHelpers';
import memoizeOne from 'memoize-one';
import structuredClone from '@ungap/structured-clone';

class VendorsHydrater {
  constructor({ api, language }) {
    this.api = api;
    this.vendorCategories = [];
    this.vendorsIds = [];
    this.vendors = new Map();
    this.language = language?.toLowerCase() || 'en';
    this.memoizeGetCategories = memoizeOne(getVendorsCategories);
    return this;
  }

  async init() {
    await this.changeLanguage(this.language);
  }

  async hydrateConfig(config) {
    await this.init();
    await this.setVendorsIds(gatherConfigVendorsIds(config));
    const hydratedConfig = this.hydrateConfigStepsVendors(config);
    return hydratedConfig;
  }

  async changeLanguage(language) {
    this.language = language?.toLowerCase();
    await this.setVendorsCategories();
    if (this.vendorsIds.length) {
      await this.mapVendors(this.vendorsIds);
    }
  }

  async setVendorsCategories() {
    const { api, language } = this;
    this.vendorCategories = await this.memoizeGetCategories(language, api);
  }

  async setVendorsIds(vendorsIds) {
    this.vendorsIds = vendorsIds;
    await this.mapVendors(vendorsIds);
  }

  /**
   * build a Map of consolidated vendors based on language
   * @param {string[]}  ids
   * @returns {Promise<Map>}
   */
  async mapVendors(vendorsIds) {
    const { api, language, vendorCategories } = this;
    await Promise.all(
      vendorsIds.map(async id => {
        const vendor = await fetchVendor(id, language, api, vendorCategories);
        if (!vendor) {
          return;
        }
        this.vendors.set(id, vendor);
      })
    );
    return this.vendors;
  }

  hydrateStepVendors(step) {
    const rehydratedStep = structuredClone(step);
    rehydratedStep.vendors =
      rehydratedStep.vendors?.reduce((acc, currentVendor) => {
        const id = typeof currentVendor === 'string' ? currentVendor : currentVendor?._id;
        let vendor = !id || !this.vendors.has(id) ? currentVendor : this.vendors.get(id);
        if (vendor && !this.vendors.has(id)) {
          delete vendor._id;
        }
        acc.push(vendor);
        return acc;
      }, []) || [];
    return rehydratedStep;
  }

  hydrateConfigStepsVendors(config) {
    const configHasVendors = cookiesConfigHasVendors(config);
    if (!configHasVendors) {
      return config;
    }

    const rehydratedConfig = structuredClone(config);

    ['steps', 'specialSteps'].forEach(key => {
      if (rehydratedConfig[key]?.length) {
        rehydratedConfig[key] = rehydratedConfig[key].map(step => this.hydrateStepVendors(step));
      }
    });
    return rehydratedConfig;
  }

  dehydrateConfigStepsVendors(config) {
    const configHasVendors = cookiesConfigHasVendors(config);
    if (!configHasVendors) {
      return config;
    }

    const dehydratedConfig = structuredClone(config);

    ['steps', 'specialSteps'].forEach(key => {
      if (dehydratedConfig[key]?.length) {
        dehydratedConfig[key] = dehydratedConfig[key].map(step => this.dehydrateStepVendors(step));
      }
    });
    return dehydratedConfig;
  }

  dehydrateStepVendors(step) {
    const dehydratedStep = structuredClone(step);
    dehydratedStep.vendors = dehydratedStep.vendors?.map(vendor => vendor?._id || vendor) || [];
    return dehydratedStep;
  }
}

export default VendorsHydrater;

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import assets from '../../services/Assets';
import Button from '../Button/Button';
import Img from '../Img/Img';
import FONTS from '../../constants/fonts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 16px 24px;

  p {
    margin: 0px;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;
  gap: 16px;
  padding: 48px;
`;
const Title = styled.h1`
  font-family: '${FONTS.SOURCE}';
  color: black;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
`;
const CompleteButton = styled(Button)`
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 12px 30px;
`;

const OnboardingCompleteStep = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Img src={assets.image('logo-axeptio-dark')} width={122} />
      <ContentContainer>
        <Title>{t('onboarding_modal_complete_step_title')}</Title>
        <Img src={assets.image('super-cookie')} width={300} />
        <CompleteButton primary blackFont onClick={onClose}>
          {t('onboarding_modal_complete_step_button_label')}
        </CompleteButton>
      </ContentContainer>
    </Container>
  );
};

export default OnboardingCompleteStep;

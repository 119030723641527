import { useRef, useEffect } from 'react';
import Error from './Pages/Error';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { useLocation } from 'react-router-dom';

export default function ErrorBoundary({ children }) {
  const ref = useRef();
  const location = useLocation();

  useEffect(() => {
    ref.current?.resetErrorBoundary();
  }, [location]);

  return (
    <SentryErrorBoundary ref={ref} fallback={Error}>
      {children}
    </SentryErrorBoundary>
  );
}

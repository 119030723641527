const contractsTemplates = [
  {
    name: 'cgu',
    label: 'Conditions Générales d\'Utilisation',
    description: 'Les conditions générales d\'utilisation (ou CGU) sont un contrat qui encadre et définit l\'utilisation d\'un service mis à disposition par une entreprise à un individu. Ce document a principalement pour objet de limiter la responsabilité du fournisseur du service en cas de mauvaise utilisation par l\'utilisateur du service.',
    icon: 'contracts_cgu',
    isFree: false,
    language: 'fr'
  },
  {
    name: 'cgv',
    label: 'Conditions Générales de Vente',
    description: 'Les conditions générales de vente (CGV) sont des informations fournies par un fournisseur à son client sur les conditions légales de vente de ses produits ou services en l\'absence d\'accord spécifique. Sans mention expresse, l\'achat d\'un bien ou d\'un service à ce fournisseur constitue une acceptation implicite de ces conditions.',
    icon: 'contracts_cgv',
    isFree: true,
    language: 'fr'
  },
  {
    name: 'nda',
    label: 'Accord de confidentialité',
    description: 'Un accord de non-divulgation ou de confidentialité (de l\'anglais non-disclosure agreement - « NDA »), est un contrat entre deux entités qui engage l\'une de ces entités à tenir confidentielles certaines informations que l\'autre sera amenée à lui communiquer.',
    icon: 'contracts_nda',
    isFree: false,
    language: 'fr'
  },
  {
    name: 'eula',
    label: 'Contrat de licence utilisateur',
    description: 'Il s\'agit d\'un contrat liant une personne installant un logiciel affecté par ce type de licence sur un/son ordinateur et l\'éditeur du logiciel. La plupart d\'entre elles, que l\'on dénomme aussi licences de logiciels propriétaires, limitent le nombre de machines sur lesquelles on peut installer le logiciel, le nombre d\'utilisateurs qui peuvent utiliser le logiciel, et contiennent d\'autres limitations qui ne sont pas inhérentes à la technologie.',
    icon: 'contracts_clu',
    isFree: false,
    language: 'fr'
  }
];

export default contractsTemplates;
import React from 'react';
import { FloatingTooltip } from './FloatingTooltip';

const Tooltip = props => {
  const { children, text, hide, display, placement, customTheme } = props;
  return (
    <FloatingTooltip
      renderOpener={props => <div {...props}>{children}</div>}
      content={text && !hide ? text : null}
      display={display}
      placement={placement}
      customTheme={customTheme}
      {...props}
    />
  );
};

export default Tooltip;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import hex2rgb from 'hex-to-rgb';
import COLORS from '../../constants/colors';
import Img from '../Img/Img';
import ActionButton from '../Button/ActionButton';
import Icon from '../Icon/Icon';
import Assets from '../../services/Assets';
import { withClient } from '@axeptio/widget-client/src/contexts/ClientContext.js';
import { getCardPaintURL } from '@axeptio/widget-client/src/components/Paint/Paint.js';
import RadioCheckbox from '../RadioCheckbox/RadioCheckbox';
import Pellet from '../Pellet/Pellet';
import StepImage from '@axeptio/widget-client/src/components/StepImage/StepImage.js';
import FONTS from '../../constants/fonts';

export const CardContent = styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 26px;

  ${props =>
    props.slim &&
    `
    padding:12px;
  `};

  ${props =>
    props.extraslim &&
    `
    padding:0 0 0 26px;
  `};

  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 2px 1px -2px rgba(0, 0, 0, 0.02);
  transition: box-shadow 0.12s ease-out;
  box-sizing: border-box;

  ${props =>
    props.isLocked &&
    `
    opacity: 0.5;
  `};

  ${props =>
    props.isLoading &&
    `
    opacity: 0.5;
  `};

  ${props =>
    props.centerContent &&
    `
    justify-content: center;
  `};

  ${props =>
    props.haveTags &&
    `
    flex-wrap: wrap;
  `};
`;

const CardClickZone = styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  border-radius: 8px;

  &:hover {
    & ~ ${CardContent} {
      box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.06);
    }
  }

  &:active {
    & ~ ${CardContent} {
      box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
    }
  }
`;

const Toolbar = styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  z-index: 120;
  position: absolute;
  top: 4px;
  right: 50px;
  display: flex;
`;

export const CardStyle = withClient(styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  text-decoration: none;
  line-height: 1.5;
  color: ${COLORS.GRAY_900};
  background-color: ${props => props.colors.card};
  border-radius: 8px;
  opacity: ${props => (props.active === false ? 0.5 : 1)};
  user-select: none;

  ${props => props.hideOverflow && 'overflow: hidden'};

  ${props =>
    props.isChecked &&
    `
    box-shadow: inset 0 0 0 1px ${props.colors.main},
                0 0 0 3px rgba(${hex2rgb(props.colors.main)}, 0.25);
  `};
`);

const CardCheckbox = styled.div`
  margin-top: 8px;
  margin-right: -20px;

  > label {
    margin-bottom: 0;
  }
`;

const getPaintURL = ({ paint, colors, size }) => {
  let color = paint === true || paint === '' ? 'blue' : paint;
  let url = Assets.image(`paint_icon_${paint === 'theme' ? 'red' : color}`);
  url += `?w=${size}`;
  if (paint === 'theme') {
    return getCardPaintURL({ w: size, ...colors.paintTransform });
  }
  return url;
};

const CardImage = withClient(styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  margin-right: 14px;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.paint &&
    `
    background: url(${getPaintURL(props)}) no-repeat center center;
    background-size: cover;
  `};

  img {
    display: block !important;
    max-width: 92px !important;
    max-height: 92px !important;
    object-fit: contain !important;
    overflow: hidden !important;
  }
`);

const CardFavicon = styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 14px;

  img {
    display: block;
  }
`;

const CardHeadings = styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  display: flex;
  align-items: center;

  > *:nth-child(2) {
    margin-left: 8px;
  }
`;

export const CardTitle = withClient(styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  ${props => props.limiteOverflow && 'max-width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'}
  font-family: ${props => props.fonts.text.family || FONTS.PRIMARY}, sans-serif;
  color: ${props => props.colors.title || COLORS.GRAY_800};
`);

export const CardSubTitle = withClient(styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  ${props => props.limiteOverflow && 'max-width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'}
  font-family: ${props => props.fonts.text.family || FONTS.PRIMARY}, sans-serif;
  color: ${props => props.colors.subtitle || COLORS.GRAY_800};
`);

export const CardDescription = withClient(styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  &,
  p {
    margin: 5px 0 0;
    font-size: 13px;
    font-weight: 400;
    color: ${props => props.colors.text || COLORS.GRAY_500};
    font-family: ${props => props.fonts.text.family || FONTS.PRIMARY}, sans-serif;
  }
`);

const CardButton = styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  z-index: 100;
  position: absolute;
  top: -8px;
  right: -8px;
`;

const Unlocked = styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: ${COLORS.YELLOW_100};
  border-radius: 8px;
  pointer-events: none;
`;

const TopItems = styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  z-index: 200;
  position: absolute;
  top: 0;
  right: 50px;
`;

const CardTags = styled.div.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  margin-top: 14px;
  flex-basis: 100%;
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 60px;

  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border: 1px solid #ffce43;
  background: #fef7e5;
  color: #52555a;

  border-radius: 13px;
  leading-trim: both;
  text-edge: cap;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

const imageSizes = {
  big: 124,
  medium: 92,
  normal: 64,
  small: 44
};

const Card = props => {
  const {
    title,
    subtitle,
    image,
    active,
    favicon,
    description,
    button,
    checkbox,
    isSelected,
    isUnlocked,
    href,
    isLoading,
    toolbar,
    badge,
    onClick,
    onMouseOver,
    slim,
    extraslim,
    className,
    isFree,
    style,
    dataFeature,
    tags
  } = props;
  const imageSize = props.imageSize ? imageSizes[props.imageSize] : imageSizes.normal;
  return (
    <CardStyle
      style={style}
      active={active}
      href={href}
      isChecked={checkbox ? checkbox.checked : isSelected}
      toolbar={toolbar}
      onMouseOver={onMouseOver}
      className={className}
      dataFeature={dataFeature}
    >
      {onClick ? (
        <CardClickZone dataFeature={dataFeature} isDisabled={checkbox ? checkbox.disabled : false} onClick={onClick} />
      ) : null}
      <CardContent
        dataFeature={dataFeature}
        slim={slim}
        extraslim={extraslim}
        isLoading={isLoading}
        centerContent={props.centerContent}
        haveTags={tags && tags.length > 0}
      >
        {checkbox && (
          <CardCheckbox>
            <RadioCheckbox
              type="checkbox"
              name={checkbox.name}
              onChange={e => checkbox.onToggle(checkbox.name, e.target.checked)}
              checked={checkbox.checked || false}
              disabled={checkbox.disabled}
            />
          </CardCheckbox>
        )}
        {props.handle}
        {image && (
          <CardImage dataFeature={dataFeature} paint={props.paint} size={imageSize}>
            {typeof image === 'string' ? (
              <StepImage dataFeature={dataFeature} src={Assets.image(image)} width={imageSize} />
            ) : (
              image
            )}
          </CardImage>
        )}
        {favicon && (
          <CardFavicon dataFeature={dataFeature} size="16">
            {typeof favicon === 'string' ? <Img dataFeature={dataFeature} src={Assets.image(favicon)} width="16" /> : favicon}
          </CardFavicon>
        )}
        {props.children || (
          <div>
            <CardHeadings dataFeature={dataFeature}>
              <CardTitle dataFeature={dataFeature}>{title}</CardTitle>
            </CardHeadings>

            {subtitle && (
              <CardSubTitle
                dataFeature={dataFeature}
                dangerouslySetInnerHTML={{
                  __html: subtitle
                }}
              />
            )}

            {description && (
              <CardDescription
                dataFeature={dataFeature}
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
            )}
          </div>
        )}
        {button && (
          <CardButton dataFeature={dataFeature} onClick={onClick}>
            {button}
          </CardButton>
        )}
        {isUnlocked && (
          <Unlocked dataFeature={dataFeature}>
            <Icon name="cookie" size={20} color={COLORS.YELLOW} />
          </Unlocked>
        )}
        {badge && !toolbar && <BadgeContainer>{badge}</BadgeContainer>}
        {toolbar && <Toolbar dataFeature={dataFeature}>{toolbar}</Toolbar>}
        {tags && (
          <CardTags dataFeature={dataFeature}>
            {tags?.map(tag => (
              <Pellet
                key={tag.content}
                border
                dataFeature={dataFeature}
                info={tag.style === 'info'}
                error={tag.style === 'error'}
                primary={tag.style === 'primary'}
                success={tag.style === 'success'}
                warning={tag.style === 'warning'}
              >
                {tag.content}
              </Pellet>
            ))}
          </CardTags>
        )}
      </CardContent>
      {isFree && (
        <TopItems dataFeature={dataFeature}>
          <Pellet dataFeature={dataFeature} absolute>
            FREE
          </Pellet>
        </TopItems>
      )}
    </CardStyle>
  );
};

Card.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  favicon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  language: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checkbox: PropTypes.shape({
    onToggle: PropTypes.func,
    name: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool
  }),
  isUnlocked: PropTypes.bool,
  isSelected: PropTypes.bool,
  href: PropTypes.string,
  isLoading: PropTypes.bool,
  toolbar: PropTypes.element,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  slim: PropTypes.bool,
  extraslim: PropTypes.bool,
  isFree: PropTypes.bool,
  badge: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      style: PropTypes.string
    })
  )
};
export const CardIconButton = props => (
  <ActionButton onClick={props.onClick} shapeless="topRight" {...props}>
    <Icon name={props.icon || 'arrow_forward'} />
  </ActionButton>
);

export default Card;

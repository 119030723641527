import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import { DropdownMenuItemStyle } from '../../DropdownMenu/DropdownMenu';
import { withProjectContext } from '../../../contexts/ProjectContext';
import Img from '../../Img/Img';
import Api from '../../../Api';
import Assets from '../../../services/Assets';
import { addToast } from '../../Toast/Toast';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import { onGoingOrIncompleteStripeSubscriptionStatus } from '../../../base/billingHelpers';
import WarningModal from '../../Modal/WarningModal';
import { useTranslation } from 'react-i18next';

const DeleteProjectButton = ({ api, type, project, docId }) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(false);

  useEffect(() => {
    const subscription = project?.billing?.subscription || project?.thirdPartyBilling?.subscription;
    if (onGoingOrIncompleteStripeSubscriptionStatus.includes(subscription?.status)) {
      setActiveSubscription(true);
    }
  }, [project]);

  const deleteProject = async () => {
    try {
      await api.deleteProject(docId);

      return (window.location.href = `/?subscription=${activeSubscription}`);
    } catch (e) {
      api.logError(e);
      addToast.error(e.message);
    }
  };

  const subscriptionStatus = project?.billing?.subscription?.status;

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirm && subscriptionStatus !== 'past_due'}
        title={t('modal_leave_confirm_title')}
        titleIcon="icon-delete"
        confirmValue={project?.name}
        alertDescription={`${t('project_delete_confirm_text')} ${
          activeSubscription ? t('project_delete_cancel_subscription_confirm_text') : ''
        }`}
        paragraphDescription={t('project_delete_confirm_text_02')}
        buttonLabel={`${t('project_delete_confirm_button')}${
          activeSubscription ? t('project_delete_confirm_cancel_subscription') : ''
        }`}
        onConfirmClick={deleteProject}
        onClose={() => setShowConfirm(false)}
      />

      <WarningModal
        isOpen={showConfirm && subscriptionStatus === 'past_due'}
        onClose={() => setShowConfirm(false)}
        modalContent={{
          title: t('project_deletion_unavailable'),
          description: t('subscription_cancellation_unavailable_description')
        }}
      ></WarningModal>

      {type === 'link' ? (
        <a
          href="/projects"
          onClick={e => {
            e.preventDefault();
            setShowConfirm(!showConfirm);
          }}
          rel="noreferrer noopener"
        >
          <DropdownMenuItemStyle warning>
            <Img src={Assets.image('icon-delete')} width={35} />
            <span>{t('project_delete_button')}</span>
          </DropdownMenuItemStyle>
        </a>
      ) : (
        <Button tertiary onClick={() => setShowConfirm(!showConfirm)}>
          {t('project_delete_button')}
        </Button>
      )}
    </>
  );
};

DeleteProjectButton.propTypes = {
  projectId: PropTypes.string.isRequired,
  api: PropTypes.instanceOf(Api).isRequired,
  project: PropTypes.object.isRequired
};

export default withProjectContext(DeleteProjectButton);

import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useApi } from '../../contexts/ApiContext';
import Assets from '../../services/Assets';
import Img from '../Img/Img';
import Input from '../Input/Input';
import Footer from './Footer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from '../Toast/Toast';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0px;
  }
`;
const Informations = styled.div`
  display: flex;
  padding: 4px 16px;
  gap: 16px;
  align-items: center;
`;
const InformationsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
`;
const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 30px;
`;
const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
`;
const Instruction = styled.span`
  font-size: 16px;
`;
const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  &:after {
    content: '*';
    color: red;
    margin-left: 4px;
  }
`;
const Error = styled.span`
  font-size: 9px;
  color: red;
`;

const UserNameStep = ({ onBack, onNext, footerCount, currentStep }) => {
  const { t } = useTranslation();
  const api = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: ''
    }
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async data => {
    if (!isLoading) {
      setIsLoading(true);
      await api
        .updateUser(data)
        .then(() => onNext())
        .catch(error => addToast.error(error.message));
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Informations>
        <InformationsTextContainer>
          <h3>{t('onboarding_modal_learn_about_you_title')}</h3>
          <p>{t('onboarding_modal_learn_about_you_description')}</p>
        </InformationsTextContainer>
        <Img src={Assets.image('Cookie_Love (2)')} width={85} height={93} />
      </Informations>

      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Instruction>{t('onboarding_modal_learn_about_you_instruction')}</Instruction>
        <InputsContainer>
          <InputWithLabel>
            <Label>{t('onboarding_modal_learn_about_you_first_name_input_label')}</Label>
            <Input
              {...register('firstName', { required: true, minLength: 1 })}
              type="text"
              placeholder={t('onboarding_modal_learn_about_you_first_name_input_label')}
              autoFocus
              error={errors.firstName}
            />
            {errors?.firstName && <Error>{t('field_required')}</Error>}
          </InputWithLabel>
          <InputWithLabel>
            <Label>{t('onboarding_modal_learn_about_you_last_name_input_label')}</Label>
            <Input
              {...register('lastName', { required: true, minLength: 1 })}
              type="text"
              placeholder={t('onboarding_modal_learn_about_you_last_name_input_label')}
              error={errors.lastName}
            />
            {errors?.lastName && <Error>{t('field_required')}</Error>}
          </InputWithLabel>
        </InputsContainer>
        <Footer count={footerCount} currentStep={currentStep} onBack={() => onBack()} isLoading={isLoading} />
      </FormContainer>
    </Container>
  );
};

export default UserNameStep;

import React from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/colors';

const AutoSaveStyle = styled.div`
  z-index: 200000;
  position: fixed;
  top: 50px;
  left: 50%;
  padding: 10px 14px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.25;
  color: ${COLORS.WHITE};
  background: ${COLORS.BLUE};
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);
  transition: transform 0.25s ease;

  ${props =>
    props.error &&
    `
    background: ${COLORS.RED};
  `};

  ${props =>
    props.saved &&
    `
    background: ${COLORS.GREEN_200_D};
    box-shadow: none;
    transform: translateX(-50%) translateY(-100%);
    transition: transform 0.25s 2s ease, box-shadow 0.25s 2s;
  `};
`;

const AutoSaveMessage = ({ children, saved, error }) => (
  <AutoSaveStyle saved={saved} error={error}>
    {children}
  </AutoSaveStyle>
);

export default AutoSaveMessage;

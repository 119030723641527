import React from 'react';
import styled, { css } from 'styled-components';
import Img from '../Img/Img';

const sizeStyles = {
  medium: css`
    font-size: 14px;
    height: 26px;
    padding: 0 8px 0 7px;
  `,
  large: css`
    height: 40px;
    font-size: 20px;
    padding: 0 10px;
  `
};

const StyledProductCardLabel = styled.div`
  display: flex;
  align-self: flex-start;
  padding: 0 8px 0 7px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: ${props => props.labelColor || '#ededed'};
  border: 1px solid ${props => props.borderColor || props.labelColor || '#ededed'};
  border-radius: 6px;

  ${props => sizeStyles[props.size]}

  .label-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > span {
    color: #212121;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    margin-left: 6px;

    &.noImg {
      margin-left: 0;
    }
  }
`;

export const ProductCardLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
  gap: 15px;
`;

const ProductCardLabel = ({ labelColor, src = null, alt, labelName, borderColor, size = 'medium' }) => {
  return (
    <StyledProductCardLabel labelColor={labelColor} borderColor={borderColor} size={size}>
      {src && (
        <div className="label-icon">
          <Img src={src} height={22} alt={alt} />
        </div>
      )}
      <span className={!src ? 'noImg' : null}>{labelName}</span>
    </StyledProductCardLabel>
  );
};

export default ProductCardLabel;

import { useEffect, useCallback } from 'react';
import { getUserBrowsingLanguage } from '../../../base/helpers';

// Public Service Announcement
const ProjectSubscriptionAlert = ({ projectId, subscription, user, eventName }) => {
  const keyName = `psa${eventName ? `_${eventName}` : ''}`;

  const updateSubscriptionWarning = useCallback((projectId, date) => {
    const projectIdSeen = getLocalStorage();
    projectIdSeen[projectId] = date.toISOString();
    localStorage.setItem(keyName, JSON.stringify(projectIdSeen));
    // Custom Told app launcher event
    if (eventName) {
      const toldHiddenFields = {
        email: user?.email,
        userId: user?._id,
        subscriptionId: subscription?.id,
        projectId: projectId,
        language: getUserBrowsingLanguage()
      };
      window.told?.('addHiddenFields', toldHiddenFields);
      window.dataLayer.push({
        event: eventName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocalStorage = useCallback(() => {
    const projectIdSeenLocalStorage = localStorage.getItem(keyName);
    let projectIdSeen = {};
    if (projectIdSeenLocalStorage) {
      try {
        projectIdSeen = JSON.parse(projectIdSeenLocalStorage);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
    return projectIdSeen;
  }, [keyName]);

  const checkSubscription = useCallback(() => {
    if (!projectId || !subscription) return;

    const date = new Date(subscription.created * 1000);
    const now = new Date();
    const diff = now - date;
    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

    const projectIdSeen = getLocalStorage();

    // If project created more than 30 days ago
    if (diffDays > 30) {
      if (!projectIdSeen[projectId]) {
        updateSubscriptionWarning(projectId, now);
      } else {
        const lastWarningDate = new Date(projectIdSeen[projectId]);
        const sevenDaysAgo = new Date(now);
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        // If last warning was more than 7 days ago
        if (lastWarningDate <= sevenDaysAgo) {
          updateSubscriptionWarning(projectId, now);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, updateSubscriptionWarning]);

  useEffect(() => {
    checkSubscription();
  }, [checkSubscription]);

  return null;
};

export default ProjectSubscriptionAlert;

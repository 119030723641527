const accessLimitsMap = {
  cookiesVendors: {
    name: 'cookiesVendors',
    type: 'free',
    value: 2,
    errorMessage: 'Nombre de services max atteint',
    getBackToFree: 'Supprimez un ou plusieurs services pour arriver au maximum autorisé',
    service: 'cookies',
    comment: 'C\'est vrai que c\'est plus sympa à plusieurs'
  },
  cookiesSteps: {
    name: 'cookiesSteps',
    type: 'free',
    value: 2,
    errorMessage: 'Nombre d\'étapes de cookies max atteint',
    getBackToFree: 'Supprimez une ou plusieurs étapes dans votre configuration',
    service: 'cookies',
    comment: 'C\'est pas le Tour de France en mode gratuit'
  },
  processingsButtons: {
    name: 'processingsButtons',
    type: 'free',
    value: 2,
    errorMessage: 'Nombre de boutons max atteint',
    getBackToFree: 'Supprimez un ou plusieurs boutons dans le widget de consentement',
    service: 'processings',
    comment: 'Désolé on est à court de Biactol'
  },
  contractsButtons: {
    name: 'contractsButtons',
    type: 'free',
    value: 2,
    errorMessage: 'Nombre de boutons max atteint',
    getBackToFree: 'Supprimez un ou plusieurs boutons dans le widget de contrat',
    service: 'contracts',
    comment: 'Désolé on est à court de Biactol'
  },
  dpoClaim: {
    name: 'dpoClaim',
    type: 'free',
    value: false,
    errorMessage: 'Réclamation DPO activée',
    getBackToFree: 'Désactivez l\'option "réclamation" dans le widget DPO',
    service: 'dpo'
  },
  dpoContact: {
    name: 'dpoContact',
    type: 'free',
    value: false,
    errorMessage: 'Formulaire de contact DPO activé\n',
    getBackToFree: 'Désactivez l\'option "formulaire de contact" dans le widget DPO',
    service: 'dpo'
  },
  watermark: {
    name: 'watermark',
    type: 'free',
    value: true,
    errorMessage: 'Watermark Axeptio caché',
    getBackToFree: 'Réactivez le watermark Axeptio dans la page "Couleur et charte graphique"'
  },
  cookiesEmptyStep: {
    name: 'cookiesEmptyStep',
    type: 'free',
    value: false,
    service: 'cookies'
  },
  cookiesEditStrings: {
    name: 'cookiesEditStrings',
    type: 'free',
    value: false,
    errorMessage: 'Textes des cookies modifiés',
    getBackToFree: 'Réinitialisez les textes dans votre configuration cookies',
    service: 'cookies'
  },
  processingsWidgets: {
    name: 'processingsWidgets',
    type: 'free',
    value: 1,
    errorMessage: 'Nombre de widgets de consentement max atteint',
    getBackToFree: 'Supprimez un ou plusieurs widgets de consentement',
    service: 'processings'
  },
  portabilityWidgets: {
    name: 'portabilityWidgets',
    type: 'free',
    value: 1,
    errorMessage: 'Nombre de widgets de portabilité max atteint',
    getBackToFree: 'Supprimez un ou plusieurs widgets de portabilité',
    service: 'portability'
  },
  contractsWidgets: {
    name: 'contractsWidgets',
    type: 'free',
    value: 1,
    errorMessage: 'Nombre de widgets de contrat max atteint',
    getBackToFree: 'Supprimez un ou plusieurs widgets de contrat',
    service: 'contracts'
  },
  portabilityProcessors: {
    name: 'portabilityProcessors',
    type: 'free',
    value: 2,
    errorMessage: 'Nombre de sous-traitant max atteint',
    getBackToFree: 'Supprimez un ou plusieurs sous-traitant dans le widget de portabilité',
    service: 'portability'
  },
  customImagesUpload: {
    name: 'customImagesUpload',
    type: 'free',
    value: false,
    errorMessage: 'Images "custom" chargées',
    getBackToFree: 'Supprimez les images personnalisées'
  },
  addProjectUsers: {
    name: 'addProjectUsers',
    type: 'free',
    value: true,
    errorMessage: 'Projet multi-utilisateur',
    getBackToFree: 'Supprimez les utilisateurs sur-numéraires',
    service: 'config/users'
  },
  withSaveConsent: {
    name: 'withSaveConsent',
    type: 'free',
    value: false,
    errorMessage: 'Enregistrement des preuves de consentement cookies',
    getBackToFree: 'Décochez l\'option "Enregistrement des preuves de consentement" dans votre configuration cookies',
    service: 'cookies'
  },
  cookiesStepDisablePaint: {
    name: 'cookiesStepDisablePaint',
    type: 'free',
    value: false,
    errorMessage: 'Tâche de peinture désactivée',
    getBackToFree: 'Réactivez l\'option "Désactiver la tâche de peinture"',
    service: 'cookies'
  },
  cookiesStepLayout: {
    name: 'cookiesStepLayout',
    type: 'free',
    errorMessage: 'Type d\'écran cookie premium utilisé',
    getBackToFree: 'Supprimez l\'écran premium',
    service: 'cookies'
  },
  clientCustomButton: {
    name: 'clientCustomButton',
    type: 'free',
    errorMessage: 'Bouton principal personnalisé',
    getBackToFree: 'Décochez l\'option "Utiliser un bouton personnalisé"',
    service: 'config/design'
  },
  clientCustomStyle: {
    name: 'clientCustomStyle',
    type: 'free',
    errorMessage: 'Style personnalisé',
    getBackToFree: 'Remettez les valeurs à zéro dans la section "Couleurs et charte graphique"',
    service: 'config/design'
  },
  hideAxeptioButton: {
    name: 'hideAxeptioButton',
    type: 'free',
    value: false,
    errorMessage: 'Bouton Axeptio caché',
    getBackToFree: 'Désactivez l\'option "cacher le bouton axeptio"',
    service: 'cookies'
  },
  inactiveSubscription: {
    name: 'inactiveSubscription',
    type: 'free',
    errorMessage: 'Abonnement inactif',
    getBackToFree: 'Réactivez votre abonnement',
    service: 'config/subscription'
  },
  unableToFetchSubscription: {
    name: 'unableToFetchSubscription',
    type: 'free',
    errorMessage: 'Impossible de récupérer votre abonnement',
    getBackToFree: 'Contactez nous',
    service: 'config/subscription'
  },
  aboutUs: {
    name: 'aboutUs',
    type: 'free',
    errorMessage: 'Contenu du panneau "à propos d\'Axeptio" modifié',
    getBackToFree: 'Repassez sur la version de base',
    service: 'cookies'
  },
  specialSteps: {
    name: 'specialSteps',
    type: 'paid',
    errorMessage: 'Utilisation de consent wall contextuel',
    getBackToFree: 'Fonctionnalité disponible à partir de la formule Medium',
    service: 'cookies'
  },
  maxProjectsLimitReached: {
    name: 'maxProjectsLimitReached',
    type: 'paid',
    errorMessage: 'Vous avez dépassé la limite des projets alloués à votre organisation',
    getBackToFree: 'Veuillez supprimer les projets en trop pour pouvoir publier. Vous pouvez aussi retirer ce projet de votre organisation et lui prendre un abonnement dédié.'
  },
  contractsV2Widgets: {
    name: 'contractsV2Widgets',
    type: 'paid',
    errorMessage: 'Vous ne pouvez pas utiliser le service Contrats avec votre abonnement.',
    getBackToFree: 'Veuillez ne pas soumettre de version de contrat lors de votre publication',
    service: 'contractsV2'
  },
  unpaidSubscription: {
    name: 'unpaidSubscription',
    type: 'paid',
    errorMessage: 'Défaut de paiement pour l\'abonnement',
    getBackToFree: 'Merci de régulariser votre abonnement'
  },
  contextualConsentWall: {
    name: 'contextualConsentWall',
    type: 'paid',
    errorMessage: 'Vous ne pouvez pas afficher le consent wall avec moins de 5s de délai',
    getBackToFree: 'Fonctionnalité disponible seulement pour la formule Large',
    service: 'cookies'
  },
  noSubscription: {
    name: 'noSubscription',
    type: 'free',
    errorMessage: 'Abonnement absent',
    getBackToFree: 'Un abonnement est nécessaire pour continuer',
    service: 'config/subscription'
  },
  freeTrialProjectsLimitReached: {
    name: 'freeTrialProjectsLimitReached',
    type: 'free',
    errorMessage: 'Limite de projets Free Trial atteinte',
    getBackToFree: 'Vous ne pourrez pas en publier de nouveaux, à moins de souscrire à un abonnement supérieur.',
    service: 'config/subscription'
  },
  noTcfSubscription: {
    name: 'noTcfSubscription',
    type: 'free',
    errorMessage: 'Abonnement insuffisant',
    getBackToFree: 'Un abonnement \'Small\' ou supérieur est nécessaire pour publier une configuration \'Axeptio for Publishers\'',
    service: 'config/subscription'
  }
};

export default accessLimitsMap;
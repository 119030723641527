import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { typography } from './Typography/Typography';

const IMGIX_PATH = 'https://campsi.imgix.net/';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize};
  ${typography};

  fieldset {
    border: none;
  }

  .react-tiny-popover-container {
    z-index: 100000;
    overflow: visible !important;
  }

  @font-face {
    font-family: "SofiaProLight";
    src: url('${IMGIX_PATH}SofiaProLight.eot?#iefix') format('embedded-opentype'),
         url('${IMGIX_PATH}SofiaProLight.otf') format('opentype'),
  	     url('${IMGIX_PATH}SofiaProLight.woff') format('woff'),
         url('${IMGIX_PATH}SofiaProLight.ttf') format('truetype'),
         url('${IMGIX_PATH}SofiaProLight.svg#SofiaProLight') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`;

export default GlobalStyle;

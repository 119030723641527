import React, { useContext } from 'react';
import createContextHOC from './createContextHOC';
const ApiContext = React.createContext({});
const withApiContext = createContextHOC(ApiContext, value => {
  return { api: value };
});
export { withApiContext };
export default ApiContext;
export function useApi() {
  return useContext(ApiContext);
}

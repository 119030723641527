import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import hex2rgb from 'hex-to-rgb';
import { placeholderMixin } from '@axeptio/widget-client/src/base/mixins.js';
import COLORS, { MAIN_COLORS } from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { withClient } from '@axeptio/widget-client/src/contexts/ClientContext.js';
import ToggleSwitchDraggable from '../ToggleSwitchDraggable/ToggleSwitchDraggable';

export const InputGroupStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
  align-items: ${props => (props.horizontal ? 'center' : 'inherit')};
  margin-bottom: ${props => (props.slim ? '0' : '30px')};
  width: ${props => (props.width ? props.width : 'auto')};

  ${props =>
    props.prefix &&
    `
    input {
      padding-left: 86px;
    }
  `};

  ${props =>
    props.prefixWidth &&
    `
    input {
      padding-left: ${props.prefixWidth}px;
    }
  `};

  ${props =>
    props.medium &&
    `
    margin-bottom: 20px;
  `};

  ${props =>
    props.large &&
    `
    margin-bottom: 40px;
  `};

  ${props =>
    props.horizontal &&
    `
    label + input {
      margin-left: 8px;
    }

    input + label {
      margin-left: 8px;
    }
  `};

  ${props =>
    props.well &&
    `
    padding: 16px;
    border: 2px solid ${COLORS.GRAY_100};
    background: ${COLORS.GRAY_50};
    border-radius: 8px;
  `};

  ${props =>
    props.fluid &&
    `
    flex: 1;
  `};
`;

export const FormText = styled.div`
  margin: 8px 0;
  padding: 0;
  white-space: normal;

  &,
  p {
    font-size: 13px;
    color: ${COLORS.GRAY_700};
  }
`;

export const FormUList = styled.ul`
  margin: 0;
  padding: 0;
  white-space: normal;

  &,
  p {
    font-size: 13px;
    color: ${COLORS.GRAY_700};
  }
`;

export const FormListItem = styled.li`
  margin: 0;
  padding: 0;
  white-space: normal;

  &,
  p {
    font-size: 13px;
    color: ${COLORS.GRAY_700};
  }

  &.ax-step-link {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const Label = withClient(styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-family: ${props => props.fonts.text.family || FONTS.PRIMARY}, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  color: ${props => props.colors.text || COLORS.GRAY_900};

  & + ${FormText} {
    margin-top: 0;
  }

  .Label__Unit {
    padding: 2px 4px;
    font-family: Menlo, monospace;
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
    color: ${COLORS.BLUE};
    border: 1px solid ${COLORS.GRAY_200};
    background: ${COLORS.GRAY_50};
    border-radius: 4px;
  }

  ${props =>
    props.nowrap &&
    `
    white-space: nowrap;
  `};

  ${props =>
    props.medium &&
    `
    font-size: 18px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
  `};

  ${props =>
    props.margin &&
    `
    margin: ${props.margin};
    `};

  ${props =>
    props['data-required'] &&
    `
    &::after {
      content: attr(data-required);
      display: inline-flex;
      padding: 4px 3px 3px;
      margin-left: 4px;
      font-size: 9px;
      font-weight: 500;
      line-height: 1;
      border-radius: 3px;
      color: ${COLORS.GRAY_700};
      background: ${COLORS.GRAY_100};
    }
  `};
`);

const Input = withClient(styled.input`
  width: ${props => (props.width ? props.width : 'auto')};
  border: 1px solid ${props => (props.error ? COLORS.RED : COLORS.GRAY_300)};
  -webkit-appearance: none;

  ${props =>
    ['text', 'password', 'email', 'number', 'tel', 'url'].includes(props.type) &&
    `
    outline: none;
    padding: 12px;
    font-family: ${FONTS.PRIMARY};
    font-size: 13px;
    line-height: 1.25;
    color: ${COLORS.BLACK};
    background-color: ${COLORS.WHITE};
    border-radius: 6px;
    transition: all 0.15s ease;
  `};

  ${placeholderMixin(COLORS.GRAY_500)};

  ${props =>
    props.medium &&
    `
    padding: 16px;
    font-size: 16px;
  `};

  ${props =>
    props.colors.main &&
    !props.error &&
    `
    &:focus {
      border-color: ${props.colors.main};
      box-shadow: 0 0 0 3px rgba(${hex2rgb(props.colors.main)}, 0.25);
    }
  `};

  ${props =>
    props.secondary &&
    !props.error &&
    `
    &:focus {
      border-color: ${MAIN_COLORS.SECONDARY_COLOR};
      box-shadow: 0 0 0 3px rgba(${hex2rgb(MAIN_COLORS.SECONDARY_COLOR)}, 0.25);
    }
  `};

  ${props =>
    props.tertiary &&
    !props.error &&
    `
    &:focus {
      border-color: ${MAIN_COLORS.TERTIARY_COLOR};
      box-shadow: 0 0 0 3px rgba(${hex2rgb(MAIN_COLORS.TERTIARY_COLOR)}, 0.25);
    }
  `};

  ${props =>
    props.error &&
    `
    &,
    &:focus {
      border-color: ${MAIN_COLORS.TERTIARY_COLOR};
      box-shadow: 0 0 0 3px rgba(${hex2rgb(MAIN_COLORS.TERTIARY_COLOR)}, 0.25);
    }
  `};

  ${props =>
    props.dark &&
    `
    &:focus {
      border-color: ${COLORS.GRAY_600};
      box-shadow: 0 0 0 3px ${COLORS.GRAY_100};
    }
  `};

  &:read-only {
    background-color: ${COLORS.GRAY_50};
  }

  ${props =>
    props.small &&
    `
    padding: 8px;
    font-size: 12px;
  `};

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `};

  ${props =>
    props.fluid &&
    `
    width: 100%;
  `};

  ${props =>
    props.monospace &&
    `
    font-family: Menlo, monaco, monospace;
  `};

  ${props =>
    props.code &&
    `
    font-family: Monaco, monospace;
  `};
`);

const InputPrefix = styled.span`
  z-index: 100;
  position: absolute;
  top: 1px;
  left: 1px;
  padding: ${props => (props.large ? '16px 8px 16px 16px' : '11px')};
  font-family: ${FONTS.PRIMARY};
  font-size: ${props => (props.large ? '16px' : '14px')};
  line-height: 1.25;
  color: ${COLORS.GRAY_500};
  background: ${COLORS.GRAY_50};
  border-right: 1px solid ${COLORS.GRAY_100};
  border-radius: 8px 0 0 8px;
`;

const InputWithUnitStyle = styled.div`
  display: flex;
  align-items: stretch;

  input {
    z-index: 100;
    position: relative;
    border-radius: 6px 0 0 6px;
  }
`;

const InputUnit = styled.span`
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  color: ${COLORS.BLUE};
  background: ${COLORS.GRAY_50};
  border: 1px solid ${COLORS.GRAY_300};
  border-left: none;
  border-radius: 0 6px 6px 0;
`;

export const InputWithUnit = props => (
  <InputWithUnitStyle>
    <Input {...props} />
    <InputUnit>{props.unit}</InputUnit>
  </InputWithUnitStyle>
);

export const InputGroup = props => (
  <InputGroupStyle {...props}>
    {props.prefix && <InputPrefix large={props.large}>{props.prefix}</InputPrefix>}
    {props.children}
  </InputGroupStyle>
);

const ActionableInputStyle = styled.div`
  position: relative;
  padding: 6px 12px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GRAY_100};
  margin-bottom: -1px;

  &:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  > label {
    display: flex;
    align-items: center;
    > span {
      font-size: 14px;
      flex: 1;
    }
    > label {
      margin-top: 4px;
    }
  }

  > div {
    padding: 8px;
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

export const ActionableInput = ({ dataFeature, checked, setChecked, label, disabled, children }) => {
  return (
    <ActionableInputStyle disabled={disabled}>
      <label>
        <span>{label}</span>
        <ToggleSwitchDraggable dataFeature={dataFeature} onChange={checked => setChecked(checked)} checked={checked} />
      </label>
      {children && checked && <div>{children}</div>}
    </ActionableInputStyle>
  );
};

ActionableInput.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default Input;

import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import COLORS from '../../constants/colors';
import Portal from '../Portal/Portal';

export const PopoverContent = styled.div`
  position: relative;
  padding: ${props => props.padding || '20px'};
  width: 96%;
  background: ${COLORS.WHITE};
  border-radius: 8px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.05);

  ${media.greaterThan('small')`
    width: ${props => props.width || '620px'};

    ${props =>
      props.small &&
      `
      width: ${props.width || '460px'};
    `};
  `};

  &::before,
  &::after {
    content: '';
    z-index: 10;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${COLORS.WHITE};
  }

  &::after {
    z-index: 1;
    margin-top: 3px;
    border-top: 10px solid rgba(0, 0, 0, 0.1);
    filter: blur(2px);
  }

  p {
    margin: 0 0 20px;
    font-size: 14px;
  }

  ${props =>
    props.position === 'top' &&
    `
  `};

  ${props =>
    props.position === 'left' &&
    `

    &::before,
    &::after {
      top: 50%;
      left: 100%;
      margin: 0;
      margin-top: -5px;
      border: none;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid ${COLORS.WHITE};
    }

    &::after {
      z-index: 1;
      margin-left: 3px;
      border-left: 10px solid rgba(0, 0, 0, 0.1);
    }
  `};

  ${props =>
    props.position === 'bottom' &&
    `

    &::before,
    &::after {
      content: '';
      z-index: 10;
      position: absolute;
      top: -10px;
      left: calc(50% - 10px);
      margin-left: 0;
      border: none;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid ${COLORS.WHITE};
    }

    &::after {
      z-index: 1;
      margin-top: -3px;
      border-bottom: 10px solid rgba(0, 0, 0, 0.1);
      filter: blur(2px);
    }
  `};
`;

const Overlay = styled.div`
  position: absolute;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 1000;
  left: ${props => (props.x ? props.x : 0)}px;
  top: ${props => (props.y ? props.y : 0)}px;
  transform: ${props => props.translate};
`;

class Popover extends React.Component {
  childrenRef = React.createRef();
  contentRef = React.createRef();

  state = {
    isOpen: false
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.open();
    }
  }

  open() {
    const rect = this.childrenRef.current.getBoundingClientRect();
    const position = {};
    switch (this.props.position) {
      case 'bottom':
        position.x = Math.floor(rect.x + rect.width / 2);
        position.y = this.props.bottomOffset ? rect.bottom + this.props.bottomOffset : rect.bottom;
        position.translate = 'translateX(-50%)';
        break;
      case 'left':
        position.x = rect.left;
        position.y = Math.floor(rect.y + rect.height / 2);
        position.translate = 'translateX(-100%) translateY(-50%)';
        break;
      case 'right':
        position.x = rect.right;
        position.y = Math.floor(rect.y + rect.height / 2);
        position.translate = 'translateY(-50%)';
        break;
      case 'top':
      default:
        position.x = Math.floor(rect.x + rect.width / 2);
        position.y = Math.floor(rect.top);
        position.translate = 'translateX(-50%) translateY(-100%)';
        break;
    }
    this.setState({ isOpen: true, ...position }, () => {
      this.contentRef.current.focus();
      setTimeout(() => {
        if (this.props.hideOnScroll) {
          window.addEventListener('wheel', this.handleScroll);
        }
        document.addEventListener('click', this.handleClickOutside);
      }, 200);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.open();
    } else {
      if (!this.props.isOpen && this.state.isOpen) {
        this.setState({ isOpen: false });
        window.removeEventListener('wheel', this.handleScroll);
        document.removeEventListener('click', this.handleClickOutside);
      }
    }
  }

  handleClickOutside = event => {
    if (this.contentRef.current !== null) {
      let parent = event.target;
      let lastParent = null;
      let inside = false;
      while (!inside && parent) {
        if (parent === this.contentRef.current) {
          inside = true;
        }
        lastParent = parent;
        parent = parent.parentElement || parent.parentNode;
      }
      // if we clicked outside the popover and we are not in a portal
      if (!inside && lastParent === document) {
        if (typeof this.props.onClickOutside === 'function') {
          this.props.onClickOutside();
        }
      }
    }
  };

  handleScroll = event => {
    if (event.target && this.state.isOpen) {
      this.props.onClickOutside();
    }
  };

  render() {
    const BodyComp = this.props.body;

    return (
      <>
        <Portal>
          <Overlay {...this.state}>
            <PopoverContent
              small={this.props.small}
              position={this.props.position}
              padding={this.props.padding}
              width={this.props.width}
              ref={this.contentRef}
              tabIndex="-1"
            >
              {typeof body === 'function' ? <BodyComp /> : React.cloneElement(this.props.body)}
            </PopoverContent>
          </Overlay>
        </Portal>
        <div ref={this.childrenRef}>{this.props.children}</div>
      </>
    );
  }
}

export default Popover;

import styled from 'styled-components';
import PropTypes from 'prop-types';

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  flex-wrap: ${props => props.wrap || 'wrap'};
  justify-content: ${props => props.justifyContent || 'inherit'};
  justify-items: ${props => props.justifyItems || 'inherit'};
  align-items: ${props => props.alignItems || 'inherit'};
  margin: ${props => (props.margin ? props.margin : 'auto')};
  width: ${props => (props.fluid ? '100%' : 'auto')};
  gap: ${props => (props.gap ? props.gap : '0')};
`;

Flex.propTypes = {
  direction: PropTypes.string,
  wrap: PropTypes.string,
  justifyContent: PropTypes.string,
  justifyItems: PropTypes.string,
  alignItems: PropTypes.string,
  margin: PropTypes.string,
  gap: PropTypes.string,
  fluid: PropTypes.bool
};
export default Flex;

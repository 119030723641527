import React from 'react';
import styled from 'styled-components';
import hex2rgb from 'hex-to-rgb';
import COLORS, { MAIN_COLORS } from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { withClient } from '@axeptio/widget-client/src/contexts/ClientContext.js';

const actionButtonMixin = color => `
  color: ${COLORS.WHITE};
  background-color: ${color};
  box-shadow: 0 3px 10px rgba(${hex2rgb(color)}, 0.15);

  &:hover {
    background-color: ${color};
    box-shadow: 0 5px 20px rgba(${hex2rgb(color)}, 0.3);

    &::after {
      background-color: ${COLORS.BLACK};
      opacity: 0.1;
    }
  }

  .svgr {
    svg {
      line,
      circle,
      polyline {
        stroke: ${COLORS.WHITE};
      }

      rect,
      path,
      polygon {
        fill: ${COLORS.WHITE};
      }
    }
  }
`;

const ActionButtonStyle = withClient(styled.button`
  outline: none;
  border: none;
  user-select: none;
  text-decoration: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.margin || '8px'};
  width: 48px;
  height: 48px;
  font-family: ${FONTS.PRIMARY};
  font-size: 28px;
  line-height: 1.25;
  font-weight: 600;
  color: ${COLORS.GRAY_800};
  background-color: ${COLORS.WHITE};
  border-radius: 1000px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 3px 10px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s ease;

  /* Used to replace darken & rgba */
  &::after {
    content: '';
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: all 0.2s ease;
  }

  .svgr {
    &,
    svg {
      width: 26px;
      height: 26px;

      line,
      circle,
      polyline {
        stroke: ${COLORS.GRAY_800};
      }

      rect,
      path,
      polygon {
        fill: ${COLORS.GRAY_800};
      }
    }
  }

  ${props =>
    !props.disabled &&
    `
    > canvas {
      z-index: 30;
    }
  `};

  ${props =>
    props.floating === 'topLeft' &&
    `
    position: absolute;
    top: 20px;
    left: 20px;
  `};

  ${props =>
    props.floating === 'topRight' &&
    `
    position: absolute;
    top: 20px;
    right: 20px;
  `};

  ${props =>
    props.floating === 'bottomLeft' &&
    `
    position: absolute;
    left: 20px;
    bottom: 20px;
  `};

  ${props =>
    props.floating === 'bottomRight' &&
    `
    position: absolute;
    right: 20px;
    bottom: 20px;
  `};

  ${props =>
    !props.disabled &&
    `
      &:hover {
        background-color: ${COLORS.GRAY_100};
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 4px 20px rgba(0, 0, 0, 0.06);
      }
  `};

  ${props =>
    props.shapeless === 'topLeft' &&
    `
    border-radius: 8px 4px 44px 4px;

    .svgr {
      position: relative;
      bottom: 4px;
      right: 4px;
    }
  `};

  ${props =>
    props.shapeless === 'topRight' &&
    `
    border-radius: 4px 8px 4px 44px;

    .svgr {
      position: relative;
      bottom: 4px;
      left: 4px;
    }
  `};

  ${props =>
    props.shapeless === 'bottomLeft' &&
    `
    border-radius: 4px 44px 4px 8px;

    .svgr {
      position: relative;
      top: 4px;
      right: 4px;
    }
  `};

  ${props =>
    props.shapeless === 'bottomRight' &&
    `
    border-radius: 44px 4px 8px 4px;

    .svgr {
      position: relative;
      top: 4px;
      left: 4px;
    }
  `};

  ${props =>
    props.primary &&
    `
    ${actionButtonMixin(props.colors.main)}
  `};

  ${props =>
    props.secondary &&
    `
    ${actionButtonMixin(MAIN_COLORS.SECONDARY_COLOR)}
  `};

  ${props =>
    props.tertiary &&
    `
    ${actionButtonMixin(MAIN_COLORS.TERTIARY_COLOR)}
  `};
  ${props =>
    props.mimicDropdown &&
    `
    color: ${COLORS.GRAY_800};
    background-color: ${COLORS.GRAY_50};
    border: 2px solid rgba(0 0 0 / 5%);
    &:hover {
      background-color: ${COLORS.GRAY_50};
      border: 2px solid rgba(0 0 0 / 15%);
    }
    &:focus {
    outline: none;
    border: 2px solid rgba(0 0 0 / 25%);
    }

    &:active {
      background-color: ${COLORS.GRAY_100};
      border: 2px solid rgba(0 0 0 / 25%);
    }
  `};

  ${props =>
    props.colorIcon &&
    `
    .svgr {
      svg {
        width: 26px;
        height: 26px;

        line,
        circle,
        polyline {
          stroke: ${props.colorIcon};
        }

        rect,
        path,
        polygon {
          fill: ${props.colorIcon};
        }
      }
    }
  `}

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `};

  ${props =>
    props.small &&
    `
    width: 36px;
    height: 36px;
  `};
`);

const ActionButton = ({ children, ...props }) => <ActionButtonStyle {...props}>{children}</ActionButtonStyle>;

export { ActionButtonStyle };
export default ActionButton;

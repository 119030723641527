import React, { Component } from 'react';
import { AdminStyles } from '../../base/AdminStyles';
import SDK from '@axeptio/widget-client/src/sdk/SDK.js';
import { Toaster } from 'sonner';
import LocaleContext, { LocaleManager } from '../../contexts/LocaleContext';
import ApiContext from '../../contexts/ApiContext';
import { AccessManager } from '../../contexts/AccessContext';
import { correctedCookieVersion, getRedirectUri } from '../../base/helpers';
import { RouterProvider } from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import FeatureFlagsContext, { getFeatureFlagsContextValue } from '../../contexts/FeatureFlagsContext';
import AxeptioRouter from './Routes';
import ProjectContext, {
  ProjectErrorsToast,
  ProjectManager,
  PublishErrorsModalWrapper,
  PublishJobToast
} from 'src/contexts/ProjectContext';
import { addToast } from '../Toast/Toast';
import { withTranslation } from 'react-i18next';

class AxeptioAdmin extends Component {
  state = {
    isFetchingUser: true,
    projects: [],
    user: null,
    adminSDK: null,
    projectId: null
  };

  componentDidMount() {
    const useThisCookieVersion = correctedCookieVersion();
    const impersonatedByAdmin = window.location.search.includes('impersonatedByAdmin=true');
    window.initializeAdminSDK = settings => {
      if (!!useThisCookieVersion && settings.cookiesVersion !== useThisCookieVersion) {
        settings.cookiesVersion = useThisCookieVersion;
      }
      if (settings.userCookiesDomain === 'axeptio.eu' && process.env.REACT_APP_BASE_URL?.includes('localhost')) {
        settings.userCookiesDomain = 'localhost';
      }
      const adminSDK = new SDK(settings);
      this.setState({ adminSDK });
      adminSDK.on('ready', () => {
        window.axeptioSDK = adminSDK;
      });
    };

    if (typeof window.axeptioSettings === 'object') {
      window.initializeAdminSDK(window.axeptioSettings);
    }

    /**
     *  Fallback after 5 seconds if GTM is not loading.
     */
    setTimeout(() => {
      // If the SDK is not initialized by GTM and there is no params impersonatedByAdmin=true, we initialize it
      if (!this.state.adminSDK && !impersonatedByAdmin) {
        console.warn('GTM was not able to run or the Axeptio SDK has not been initialized by GTM');
        window.initializeAdminSDK({
          clientId: '624d5e22e4776e1f019014e2',
          userCookiesDomain: 'axeptio.eu',
          cookiesVersion: 'axeptio prod-en'
        });
      }
    }, 5000);

    if (impersonatedByAdmin) {
      window.initializeAdminSDK({});
    }

    this.props.api.getUser(user => {
      this.setState({ user, isFetchingUser: false });
    });

    this.props.api.onChange(event => {
      if (event === 'signout') {
        this.setState({ user: null, projects: [] });
      }
    });

    this.handleZendeskErrors();

    this.featureFlagsContextValue = getFeatureFlagsContextValue(this.props.api);
  }

  componentDidUpdate() {
    this.featureFlagsContextValue = getFeatureFlagsContextValue(this.props.api);
  }

  handleLanguageChange(language) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'language_change',
      language: language,
      lang: language.toLowerCase()
    });
  }

  handleZendeskErrors() {
    // handle Zendesk Error when agent/admin are redirected for JWT auth
    const params = new URLSearchParams(window.location.search.substring(1));
    if (params.get('kind') === 'error') {
      const message = params.get('message');
      if (message === 'Invalid data from remote sign-in mechanism. Missing name or email.') {
        return (window.location.href = `${process.env.REACT_APP_SUPPORT_PORTAL_URL}/agent`);
      }
      if (message) {
        addToast.error(`Zendesk SSO Error: ${message}`);
      }
    }
  }

  handleToastClose(jobId) {
    if (jobId) {
      this.setState({
        hiddenJobId: jobId
      });
    }
  }

  updateProjectId = projectId => {
    this.setState({ projectId });
  };

  removeInvitationToken() {
    const params = new URLSearchParams(window.location.search);
    params.delete('invitationToken');
    window.history.replaceState({}, document.title, `${window.location.pathname}?${params}`);
  }

  render() {
    const { t } = this.props;
    const params = new URLSearchParams(window.location.search.substring(1));

    if (!this.state.adminSDK || this.state.isFetchingUser) {
      return null;
    }
    if (this.state.user && params.get('invitationToken')) {
      if (!params.get('access_token')) {
        this.props.api.signout();
        window.location.reload();
      } else {
        const invitationToken = params.get('invitationToken');
        this.removeInvitationToken();
        this.props.api.acceptInvitation(this.state.user, invitationToken, user => this.setState({ user }));
        window.history.pushState({}, '', window.location.pathname === '/' ? '/projects' : `/${window.location.pathname}`);
      }
    } else if (this.state.user && getRedirectUri()) {
      return (
        <AccessManager api={this.props.api} user={this.state.user}>
          <LoadingIndicator />
        </AccessManager>
      );
    }

    return (
      <AccessManager api={this.props.api} user={this.state.user}>
        <AdminStyles />
        <ApiContext.Provider value={this.props.api}>
          <FeatureFlagsContext.Provider value={this.featureFlagsContextValue}>
            <LocaleManager onChange={language => this.handleLanguageChange(language)}>
              <Toaster pauseWhenPageIsHidden />
              <LocaleContext.Consumer>
                {locale => (
                  <>
                    <ProjectManager projectId={this.state.projectId} api={this.props.api}>
                      <ProjectContext.Consumer>
                        {projectCtx => (
                          <>
                            <ProjectErrorsToast projectCtx={projectCtx} />
                            <PublishJobToast
                              projectCtx={projectCtx}
                              state={this.state}
                              handleToastClose={id => this.handleToastClose(id)}
                            />
                            <PublishErrorsModalWrapper projectCtx={projectCtx} locale={locale} api={this.props.api} />
                          </>
                        )}
                      </ProjectContext.Consumer>
                      <RouterProvider router={AxeptioRouter(locale, this, t)} />
                    </ProjectManager>
                  </>
                )}
              </LocaleContext.Consumer>
            </LocaleManager>
          </FeatureFlagsContext.Provider>
        </ApiContext.Provider>
      </AccessManager>
    );
  }
}

export default withTranslation()(AxeptioAdmin);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../Modal/Modal';
import Img from '../Img/Img';
import Alert from '../Alert/Alert';
import Button, { ButtonsStack } from '../Button/Button';
import Title from '../Title/Title';
import Input, { InputGroup } from '../Input/Input';
import Paragraph from '../Paragraph/Paragraph';
import Assets from '../../services/Assets';

const Header = styled.div`
  text-align: center;
`;

const Code = styled.code`
  white-space: nowrap;
`;

const ConfirmationModal = ({
  isOpen,
  noPortal,
  title,
  titleIcon,
  alertDescription,
  paragraphDescription,
  confirmValue,
  buttonLabel,
  onConfirmClick,
  onClose
}) => {
  const [typeConfirm, setTypeConfirm] = useState(false);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal noPortal={noPortal} isOpen={isOpen} onClose={onClose}>
      <Header>
        <Img src={Assets.image(titleIcon)} width={55} height={55} />
        <Title as="h2" bold>
          <span>{title}</span>
        </Title>
      </Header>
      <Alert error>{alertDescription}</Alert>
      <InputGroup>
        <Paragraph>
          {paragraphDescription ? `${paragraphDescription} ` : null}
          <strong>
            <Code>{confirmValue || ''}</Code>
          </strong>
        </Paragraph>
        <Input type="text" onChange={e => setTypeConfirm(e.target.value)} />
      </InputGroup>
      <ButtonsStack>
        <Button fluid tertiary onClick={onConfirmClick} disabled={typeConfirm !== confirmValue}>
          {buttonLabel}
        </Button>
      </ButtonsStack>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  noPortal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string.isRequired,
  alertDescription: PropTypes.string.isRequired,
  paragraphDescription: PropTypes.string,
  confirmValue: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ConfirmationModal;

import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'styled-media-query';
import FONTS from '../../constants/fonts';
import COLORS, { MAIN_COLORS } from '../../constants/colors';
import Button, { ButtonsStack } from '../Button/Button';
import RadioCheckbox from '../RadioCheckbox/RadioCheckbox';
import React, { Component, Fragment, useState } from 'react';
import { WidgetWrapper } from '@axeptio/widget-client/src/components/Widget/Widget.js';
import { ClientProvider } from '@axeptio/widget-client/src/contexts/ClientContext.js';
import { createElement } from '@axeptio/widget-client/src/sdk/dom.js';
import { ExpandableToggleButton } from '../Expandable/Expandable';
import Popover from '../Popover/Popover';
import { InputGroupStyle } from '../Input/Input';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';

export const FullHeight = styled.div`
  flex: 1;
  min-height: calc(100vh - ${props => props.heightReduction ?? '64'}px);
`;

export const PreviewTopButtons = styled.div`
  position: absolute;
  top: -1px;
  width: 100%;
  text-align: center;
  z-index: 1;

  button {
    margin: 0 4px;
    display: inline-flex;
    align-items: center;
    border-radius: 0 0 6px 6px;
    border: 1px solid ${COLORS.BLUE_200};
    background: white;
    font-family: ${FONTS.PRIMARY};
    padding: 10px 13px;
    font-size: 14px;
    cursor: pointer;
    color: ${COLORS.GRAY_500};
    transition: box-shadow 0.1s ease-out;

    &:hover {
      box-shadow: 0 2px 5px #00000022, 0 1px 2px #00000011;
    }

    &:focus,
    &:active {
      outline: none;
      background: ${COLORS.BLUE_100};
    }

    &:active {
      box-shadow: none;
    }
  }
`;

export const Editor = styled.div`
  position: relative;
  font-size: 12px;
  font-family: ${FONTS.PRIMARY};
  flex: 1;

  h1 {
    text-align: center;
    font-size: 24px;
    font-family: ${FONTS.TITLE};
  }

  h2 {
    font-size: 18px;
    color: ${MAIN_COLORS.SECONDARY_COLOR};
    font-family: ${FONTS.TITLE};
    margin: 24px 0;
    padding: 8px 30px;
    text-align: center;
    border-bottom: 1px solid ${COLORS.GRAY_200};

    span.hint {
      font-size: 13px;
      color: ${COLORS.GRAY_750};
      font-family: ${FONTS.SECONDARY};
      font-weight: normal;
      display: block;
      text-align: center;
    }
  }

  h3 {
    font-family: ${FONTS.TITLE};
  }

  textarea {
    min-height: 130px;
  }
`;

export const PreviewStyle = styled.div`
  flex-basis: 50%;
  max-width: 50%;
  background: ${COLORS.WHITE};
  border-right: 1px solid ${COLORS.GRAY_200};
  position: relative;
`;

const PresenterWrapper = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  right: 50%;
  display: flex;
  align-items: center;
  justify-items: center;
  overflow: hidden;

  ${media.lessThan('medium')`
    display: none;
  `};
`;

const Presenter = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  ${props =>
    props.maxWidth &&
    `
    > div {
      max-width: 500px;
    }
  `};

  h3 {
    margin-top: 28px;
    font-size: 16px;
  }
`;

export const SplitAdmin = styled.div`
  display: flex;
  flex: 1;

  ${Editor} {
    flex-basis: 50%;
    max-width: 50%;
    background: ${({ theme }) => theme.colors.grey.v50};
  }

  ${media.lessThan('medium')`
    ${PreviewStyle} {
      display: none;
      overflow: hidden;
      position: relative;
    }
    ${PresenterWrapper} {
      display: none;
      overflow: hidden;
    }
    ${Presenter} {
      display: none;
      overflow: hidden;
      position: relative;
    }
    ${Editor} {
      flex-basis: 100%;
      max-width: 100%;
    }
  `};
`;

export class Preview extends Component {
  componentDidMount() {
    this.createFontsLinks();
    this.updateFontsLinks();
  }
  componentDidUpdate(oldProps) {
    if (this.props.project.fonts !== oldProps.project.fonts) {
      this.updateFontsLinks();
    }
  }
  createFontsLinks() {
    this.linkElements = {};
    ['title', 'text'].forEach(role => {
      const el = createElement('link', {
        rel: 'stylesheet',
        media: 'all',
        type: 'text/css'
      });
      window.document.head.appendChild(el);
      this.linkElements[role] = el;
    });
  }
  updateFontsLinks() {
    const { fonts } = this.props.project;
    if (!fonts) {
      return;
    }
    if (Array.isArray(fonts.title.variants) && fonts.title.variants.length) {
      this.linkElements.title.setAttribute(
        'href',
        `https://fonts.googleapis.com/css?family=${`${fonts.title.family}:${fonts.title.variants.join(',')}`}`
      );
    }
    if (Array.isArray(fonts.text.variants) && fonts.text.variants.length) {
      this.linkElements.text.setAttribute(
        'href',
        `https://fonts.googleapis.com/css?family=${`${fonts.text.family}:${fonts.text.variants.join(',')}`}`
      );
    }
  }
  render() {
    let { project, children, presenterMaxWidth } = this.props;
    return (
      <PreviewStyle project>
        <ClientProvider value={project}>
          <PresenterWrapper>
            <Presenter maxWidth={presenterMaxWidth}>{children}</Presenter>
          </PresenterWrapper>
        </ClientProvider>
      </PreviewStyle>
    );
  }
}

export const PreviewOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20000;
  padding: 25px;
  max-width: 400px;

  ${WidgetWrapper} {
    max-height: 40vh;
  }
`;

export const Fieldset = styled.fieldset`
  position: relative;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  border: none;

  ${props =>
    props.projectsPage &&
    `
    min-height: calc(100vh - 107px);
  `};

  ${ExpandableToggleButton} {
    margin-bottom: 18px;
    text-align: center;
    display: block;
  }

  ${media.greaterThan('medium')`
    max-width: ${props => (props.small ? '400px' : '800px')};
  `};

  ${media.greaterThan('large')`
    ${props => props.wide && 'max-width: 80vw'}
  `};

  .chrome-picker {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) !important;
  }
`;

Fieldset.propTypes = {
  wide: PropTypes.bool,
  small: PropTypes.bool,
  projectsPage: PropTypes.bool,
  margin: PropTypes.string,
  padding: PropTypes.string
};

Fieldset.defaultProps = {
  margin: '0 auto',
  padding: '24px'
};

export const AdvancedOptions = styled.div`
  margin: 0 0 30px 30px;
  padding: 20px;
  border: 1px solid ${COLORS.GRAY_200};
  background: ${COLORS.GRAY_50};
  border-radius: 8px;

  > div {
    margin-bottom: 20px;

    label {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const EditorFooterStyle = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 24px;
  display: flex;
  justify-content: center;
  background: ${COLORS.WHITE};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08), 0 0 4px rgba(0, 0, 0, 0.05);

  & div > button {
    margin-left: 14px;
  }
`;

export const ActionFooterStyle = styled.div`
  z-index: 1000;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 24px;
  background: ${COLORS.WHITE};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08), 0 0 4px rgba(0, 0, 0, 0.05);

  ${props =>
    props?.fixed &&
    `
    position: fixed;
  `}

  & > div {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    & > button,
    & > div {
      flex: 1;
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const ActionFooter = ({ children, fixed }) => (
  <ActionFooterStyle fixed={fixed}>
    <div ref={children.ref}>{children}</div>
  </ActionFooterStyle>
);

const navButtonClickHandler =
  (navigate, handler, async, setLoading = null, redirect = false) =>
  event => {
    if (setLoading) {
      setLoading(true);
    }

    if (async && navigate) {
      handler(event, () => {
        navigate('..', { relative: 'path' });
      });
    } else {
      if (handler) {
        handler(event);
        if (redirect) {
          navigate('..', { relative: 'path' });
        }
      } else if (navigate) {
        navigate('..', { relative: 'path' });
      }
    }
  };

export const CloseButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Button
      secondary
      small
      nowrap
      onClick={() => {
        navigate('..', { relative: 'path' });
      }}
    >
      {t('btn_close')}
    </Button>
  );
};

export const SaveButton = ({ onClick, async, simple, redirect = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Fragment>
      {!simple && (
        <Button
          primary
          small
          blackFont
          onClick={() => {
            onClick();
          }}
        >
          {t('btn_save')}
        </Button>
      )}
      <Button
        secondary
        small
        nowrap
        onClick={() => {
          onClick();
          if (!onClick || redirect) {
            navigate('..', { relative: 'path' });
          }
        }}
      >
        {simple ? t('btn_confirm') : t('btn_save_and_close')}
      </Button>
    </Fragment>
  );
};

export const DeleteButton = ({ onClick, children, async, redirect }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Popover
      small
      isOpen={showConfirm}
      onClickOutside={() => setShowConfirm(false)}
      position={'top'}
      body={
        <ButtonsStack>
          <Button fluid tertiary onClick={navButtonClickHandler(navigate, onClick, async, setLoading, redirect)}>
            {isLoading ? <LoadingIndicator small /> : t('button_confirmBox_confirm')}
          </Button>
          <Button fluid secondary onClick={() => setShowConfirm(!showConfirm)}>
            {t('button_confirmBox_cancel')}
          </Button>
        </ButtonsStack>
      }
    >
      <Button fluid small tertiary simple onClick={() => setShowConfirm(!showConfirm)}>
        {children || t('btn_delete')}
      </Button>
    </Popover>
  );
};

export const RemoveButton = ({ onClick, children }) => (
  <Button fluid tertiary small onClick={onClick}>
    {children}
  </Button>
);

export const Hamburger = styled.span`
  position: relative;
  top: 1px;
  display: block;
  flex-shrink: 0;
  width: 18px;
  height: 11px;
  opacity: 0.25;
  margin-right: 20px;
  cursor: row-resize;
  background: -webkit-linear-gradient(top, #000, #000 20%, #fff 0, #fff 40%, #000 0, #000 60%, #fff 0, #fff 80%, #000 0, #000);
  background: linear-gradient(180deg, #000, #000 20%, #fff 0, #fff 40%, #000 0, #000 60%, #fff 0, #fff 80%, #000 0, #000);
`;

export const Dot = styled.div`
  height: ${props => props.size || 16}px;
  width: ${props => props.size || 16}px;
  border-radius: ${props => props.size || 16}px;
  margin-right: ${props => props.size || 16}px;
  display: inline-block;
  background: ${props => props.color};
`;

export const CookiesContainer = styled.div`
  width: 420px;
  position: relative;
`;

export const LabelWithCheckboxStyle = styled.label`
  display: inline-block;

  input {
    margin-right: 8px;
  }

  .LabelWithCheckbox__help {
    display: block;
    font-size: 12px;
    color: ${COLORS.GRAY_400};
  }
`;

export const LabelWithCheckbox = props => (
  <LabelWithCheckboxStyle>
    <RadioCheckbox type="checkbox" label={props.label} {...props} />
    {props.help && <span className="LabelWithCheckbox__help">{props.help}</span>}
  </LabelWithCheckboxStyle>
);

LabelWithCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  noMargin: PropTypes.bool
};
export const NoItem = styled.div`
  padding: 20px;
  text-align: center;

  h4 {
    margin-bottom: 4px;
  }

  p {
    font-size: 13px;
    color: ${COLORS.GRAY_700};
  }
`;

export const NoItemAction = styled.div`
  display: block;
  font-size: 13px;
  color: ${COLORS.GRAY_700};

  &:first-child {
    margin-bottom: 20px;
  }
`;

export const NoItemSeparator = styled.div`
  margin-top: 6px;
  font-size: 11px;
  text-transform: uppercase;
  color: ${COLORS.GRAY_500};
`;

export const ImageSelector = styled.div`
  padding: 16px;
  border: 1px solid ${COLORS.GRAY_200};
  background: ${COLORS.GRAY_50};
  border-radius: 8px;
  margin-top: 8px;
  position: relative;
  .buttons {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

export const ImageDimensions = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 16px;
  }
`;

export const DangerZone = styled.div`
  background: ${COLORS.RED_50};
  border: 2px solid ${COLORS.RED_500};
  border-radius: 6px;
  margin: 0 -16px;
  padding: 16px;
  &::before {
    content: 'DANGER ZONE';
    color: ${COLORS.RED_500};
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 20px;
    display: block;
  }
  ${InputGroupStyle}:last-child {
    margin-bottom: 0;
  }
`;
export const LinkWithIcon = styled.a`
  display: flex;
  align-items: center;
  text-decoration: underline;
  margin-bottom: 8px;
  padding: 2px 0;

  > span {
    margin-right: 4px;
  }

  &:hover {
    text-decoration: none;

    svg path {
      fill: #212121;
    }
  }
`;

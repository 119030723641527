const processingsTemplates = [
  {
    name: 'allcontact',
    label: 'Let\'s keep in touch ! ',
    description: 'Instead of imposing a communication channel to your clients, offer them to choose the one they are most interested in ! This is an excellent way to maximize your opt-in rate while giving control to your users ! ',
    icon: 'icon-consentement-contact',
    title: 'Let\'s keep in touch ! ',
    subtitle: 'Telephone, eMail, SMS : you choose ! ',
    message: 'We like nurturing personalized relationships with our clients. And to start with the right foot, it is important to meet your expectations. This is why we let you choose the medium of communication you prefer. ',
    language: 'en'
  },
  {
    name: 'newsletter',
    label: 'Newsletter, Mailing list',
    description: 'Communication channel favored by companies, mailing list is still, and for a long time, a must for brands. At Axeptio we are compatible with the main routers of the market: we discuss with their APIs to synchronize the consents with the lists of unsubscriptions.',
    icon: 'icon-consentement-newsletter',
    title: 'Our newsletter',
    subtitle: 'We work had to provide great content',
    message: 'You won\'t receive our newsletter until we got your explicit consent.',
    language: 'en'
  },
  {
    name: 'phone',
    label: 'Telephone prospecting',
    description: 'Keep in touch with your customers and offer them a personalized and qualitative contact. In addition to their consent, we allow you to ask your users about their availability in order to offer them the best possible experience.',
    icon: 'icon-consentement-phone',
    title: 'Sometimes it\'s good to talk to a human too',
    subtitle: 'Because we would love to speak to you',
    message: 'Our team is always happy to speak to you on the phone. And because we respect your privacy and you preferences, we need to get your consent first. ',
    language: 'en'
  },
  {
    name: 'contest',
    label: 'Contest',
    description: 'Contests are a good way to collect data voluntarily and of good quality. Be careful, however, to establish a regulation and obtain a consent.',
    icon: 'icon-consentement-jeu',
    title: 'Your personal data are not a game\n',
    subtitle: 'But you and us can win',
    message: 'The data we gather with your participation at our contest is very helpful for our marketing team. If you agree to the processing of your data, both of use win !',
    language: 'en'
  },
  {
    name: 'sms',
    label: 'SMS',
    description: 'Do you want to notify your users about discounts, promotions, tips, etc. SMS is the way to go. But you need their consent !',
    icon: 'icon-consentement-sms',
    title: 'Once in a while, an SMS with good news inside',
    subtitle: 'Promo codes or invites to our events',
    message: 'We won\'t mess with your messages',
    language: 'en'
  }
];

export default processingsTemplates;
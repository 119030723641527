import React from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/colors';
import PropTypes from 'prop-types';

const LoadingIndicatorStyle = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;

  ${props =>
    props.inline
      ? `
    display: inline-flex;
    `
      : `
    display: flex;
    width: 100%;
    height: 100%;
  `}

  .lds-ring {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
  }

  ${props =>
    props.absolute &&
    `
    z-index: 300000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  `};

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 6px;
    border: 4px solid ${props => props.color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color} transparent transparent transparent;
  }

  ${props =>
    props.small &&
    `
    .lds-ring {
      width: 20px;
      height: 20px;
      margin-bottom: -1px;

      div {
        width: 16px;
        height: 16px;
        margin: 2px;
        border: 2px transparent solid;
        border-top-color: ${props.color};
      }
    }
  `}
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingIndicator = ({ color = COLORS.YELLOW, inline = false, small = false, absolute = false }) => (
  <LoadingIndicatorStyle color={color} inline={inline} small={small} absolute={absolute} id="loadindIndicator">
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </LoadingIndicatorStyle>
);

LoadingIndicator.propTypes = {
  color: PropTypes.string,
  absolute: PropTypes.bool,
  small: PropTypes.bool
};
export default LoadingIndicator;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import COLORS from '../../constants/colors';
import Icon from '../Icon/Icon';
import ActionButton from '../Button/ActionButton';
import Portal from '../Portal/Portal';

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;

  ${media.greaterThan('small')`
    top: -30px;
    right: -30px;
  `};
`;

const Style = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;

  ${media.greaterThan('small')`
    padding: 0;
  `};
`;

const Content = styled.div`
  z-index: 1000;
  position: relative;
  margin: auto;
  width: 100%;
  border-radius: 8px;
  background: ${COLORS.WHITE};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.05);

  h1 {
    padding-top: 8px;
  }

  h2 {
    font-weight: 500;
  }

  p {
    margin-bottom: 20px;
    font-size: 14px;
    color: ${COLORS.GRAY_800};
  }

  ${media.greaterThan('small')`
    margin: 60px auto;
    max-width: 620px;
    border-radius: 8px;

    ${props =>
      props.large &&
      `
        max-width: 80%;
    `};

    ${props =>
      props.medium &&
      `
        max-width: 720px;
    `};

    ${props =>
      props.customWidth &&
      `
        max-width: ${props.customWidth};
    `};
  `};

  ${media.greaterThan('large')`
    ${props =>
      props.large &&
      `
        max-width: 1220px;
    `};
  `};
`;

const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  ${props => !props.noOverflow && 'overflow: auto;'}
`;

const Overlay = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

const InnerModal = ({
  isOpen,
  medium,
  large,
  customWidth,
  children,
  footer,
  onClose,
  noOverflow,
  closeOnOutsideClick,
  withCloseButton = true
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Style isOpen={isOpen}>
      <Content medium={medium} large={large} customWidth={customWidth}>
        <Scroll large={large} noOverflow={noOverflow}>
          {children}
        </Scroll>
        {footer && <Footer>{footer}</Footer>}
        {withCloseButton && (
          <CloseButton>
            <ActionButton aria-label={'modal-close-button'} onClick={() => onClose()}>
              <Icon name="close" />
            </ActionButton>
          </CloseButton>
        )}
      </Content>
      <Overlay onClick={() => (closeOnOutsideClick === false ? () => {} : onClose())} />
    </Style>
  );
};

const Modal = props =>
  props.noPortal ? (
    <InnerModal {...props}>{props.children}</InnerModal>
  ) : (
    <Portal isModal>
      <InnerModal {...props}>{props.children}</InnerModal>
    </Portal>
  );

Modal.propTypes = {
  isOpen: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  customWidth: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  onClose: PropTypes.func.isRequired,
  noPortal: PropTypes.bool,
  footer: PropTypes.element
};

export default Modal;

const processings = {
  language: 'fr',
  buttons: [
    { text: 'Je refuse', value: 0, action: 'reject' },
    { text: 'J\'accepte', value: 'full', action: 'accept', color: 'primary' }
  ],
  title: '',
  subTitle: '',
  message: '',
  pictureHeight: 100,
  personalizedConsent: false,
  detailLinkText: 'En savoir plus',
  helpers: [],
  processors: [],
  confirmations: {
    accept: {
      title: 'Super 🤩',
      message:
        'Merci pour votre confiance ! Si jamais vous voulez tout savoir sur notre actualité, n\'hésitez pas non plus à nous suivre sur les réseaux sociaux',
      backButtonText: 'Revenir'
    },
    partial: {
      title: 'OK 😊',
      message: 'C\'est noté ! On ne vous embête pas plus longtemps ! ',
      backButtonText: 'Revenir'
    },
    reject: {
      title: 'Ah... dommage 😢',
      message:
        'Pas de problème, on respecte votre choix. Si jamais vous voulez laisser une chance à notre équipe de vous convaincre par l\'intérêt de sa communication, vous pouvez toujours revenir ici et changer vos préférences.',
      backButtonText: 'Revenir'
    }
  },
  consentWidgetStrings: {}
};

const contracts = {
  language: 'fr',
  buttons: [
    { text: 'Voir le document', primary: false, action: 'show' },
    { text: 'J\'accepte', primary: true, action: 'accept' }
  ],
  title: '',
  subTitle: '',
  message: '',
  fileHeight: 100,
  personalizedConsent: false,
  detailLinkText: 'En savoir plus',
  helpers: [],
  consentWidgetStrings: {}
};

const cookies = {
  language: 'fr',
  aboutUsId: 'base',
  steps: [],
  strings: {},
  a11yStrings: {},
  settings: {},
  uiSettings: {},
  version: {}
};

const portability = {
  language: 'fr',
  title: 'Partage de vos données personnelles',
  message: '',
  name: 'Encart de portabilité sans-titre',
  processors: [],
  consentWidgetStrings: {}
};
const weekdays = [
  {
    name: 'monday',
    value: 'l'
  },
  {
    name: 'tuesday',
    value: 'm'
  },
  {
    name: 'wednesday',
    value: 'm'
  },
  {
    name: 'thursday',
    value: 'j'
  },
  {
    name: 'friday',
    value: 'v'
  },
  {
    name: 'saturday',
    value: 's'
  },
  {
    name: 'sunday',
    value: 'd'
  }
];
const tcf = {
  content: {},
  language: 'fr',
  country: 'EU',
  aboutUsId: 'base',
  vendors: [],
  purposes: [],
  stacks: [],
  googleAdditionalConsent: false,
  googleConsentMode: {},
  settings: {
    continueWithoutConsent: false,
    askNewConsent: false,
    expirationTtlDays: 365
  }
};

const defaultValues = { processings, portability, contracts, cookies, weekdays, tcf };

export default defaultValues;


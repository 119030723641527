const countries = [
  {
    name: 'FRA',
    title: 'France',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'USA',
    title: 'United States of America',
    isEu: false,
    hasAdequacyDecision: false
  },
  {
    name: 'unknown',
    title: 'Unknown',
    isEu: false,
    hasAdequacyDecision: false
  },
  {
    name: 'other',
    title: 'Other',
    isEu: false,
    hasAdequacyDecision: false
  },
  {
    name: 'DEU',
    title: 'Germany',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'AUT',
    title: 'Austria',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'BEL',
    title: 'Belgium',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'BGR',
    title: 'Bulgary',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'CYP',
    title: 'Cyprus',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'HRV',
    title: 'Croatia',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'DNK',
    title: 'Denmark',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'ESP',
    title: 'Spain',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'EST',
    title: 'Estonia',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'FIN',
    title: 'Finland',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'GRC',
    title: 'Greece',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'HUN',
    title: 'Hungary',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'IRL',
    title: 'Ireland',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'ITA',
    title: 'Italy',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'LVA',
    title: 'Latvia',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'LTY',
    title: 'Lithuania',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'LUX',
    title: 'luxembourg',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'MLT',
    title: 'Malta',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'NLD',
    title: 'Netherlands',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'POL',
    title: 'Poland',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'PRT',
    title: 'Portugal',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'CZE',
    title: 'Czech republic',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'ROU',
    title: 'Romania',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'GBR',
    title: 'United Kingdom',
    isEu: true,
    hasAdequacyDecision: true
  },
  {
    name: 'SVK',
    title: 'Slovakia',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'SVN',
    title: 'Slovenia',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'SWE',
    title: 'Sweden',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'ARG',
    title: 'Argentina',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'CAN',
    title: 'Canada',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'GGY',
    title: 'Guernsey',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'IMN',
    title: 'Isle of man',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'JEY',
    title: 'Jersey',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'CHE',
    title: 'Swiss',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'JAP',
    title: 'Japan',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'AND',
    title: 'Andorra',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'FRO',
    title: 'Faroe Islands',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'ISR',
    title: 'Israel',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'NZL',
    title: 'New Zealand',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'URY',
    title: 'Uruguay',
    isEu: false,
    hasAdequacyDecision: true
  }
];

export default countries;
import React from 'react';
import ReactDOM from 'react-dom';
import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import App from './App';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import './i18n';

const rates = samplingContext => {
  if (
    samplingContext.name.includes('billing') ||
    samplingContext.name.includes('Subscription') ||
    process.env.REACT_APP_ENV !== 'production'
  ) {
    return 1;
  } else {
    return 0.8;
  }
};

init({
  dsn: 'https://9e8909eb8a164f08b95f63b3b906ceb0@o561678.ingest.us.sentry.io/5699323',
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  sampleRate: rates,
  tracesSampleRate: rates,
  environment: process.env.REACT_APP_ENV,

  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.25 : 1.0
});

const mount = document.createElement('div');
document.body.appendChild(mount);
ReactDOM.render(<App />, mount);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getBackgroundColor from './getBackgroundColor';
import Icon from '../Icon/Icon';

class ReactSwitch extends Component {
  constructor(props) {
    super(props);
    const { checked, small } = props;

    this.handleDiameter = small ? 22 : 28;
    this.$height = small ? 18 : 24;
    this.$width = small ? 32 : 50;
    this.$handleDiameter = this.handleDiameter || this.$height - 2;

    this.$checkedPos = Math.max(this.$width - this.$height, this.$width - (this.$height + this.$handleDiameter) / 2);
    this.$uncheckedPos = Math.max(0, (this.$height - this.$handleDiameter) / 2);
    this.state = {
      $pos: checked ? this.$checkedPos : this.$uncheckedPos
    };

    this.$onMouseDown = this.$onMouseDown.bind(this);
    this.$onMouseMove = this.$onMouseMove.bind(this);
    this.$onMouseUp = this.$onMouseUp.bind(this);

    this.$onTouchStart = this.$onTouchStart.bind(this);
    this.$onTouchMove = this.$onTouchMove.bind(this);
    this.$onTouchEnd = this.$onTouchEnd.bind(this);
    this.$onTouchCancel = this.$onTouchCancel.bind(this);

    this.$onClick = this.$onClick.bind(this);
    this.$onKeyDown = this.$onKeyDown.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.checked !== prevProps.checked) {
      const $pos = this.props.checked ? this.$checkedPos : this.$uncheckedPos;
      this.setState({ $pos });
    }
  }

  $onDragStart(clientX) {
    this.setState({
      $startX: clientX,
      $hasOutline: true,
      $dragStartingTime: Date.now()
    });
  }

  $onDrag(clientX) {
    const { $startX } = this.state;
    const { checked } = this.props;
    const startPos = checked ? this.$checkedPos : this.$uncheckedPos;
    const newPos = startPos + clientX - $startX;
    const $pos = Math.min(this.$checkedPos, Math.max(this.$uncheckedPos, newPos));
    this.setState({ $pos, $isDragging: true });
  }

  $onDragStop(event) {
    const { $pos, $isDragging, $dragStartingTime } = this.state;
    const { checked, onChange, id } = this.props;

    // Simulate clicking the handle
    if (!$isDragging) {
      this.setState({ $hasOutline: false });
      onChange(!checked, event, id);
      return;
    }
    const timeSinceStart = Date.now() - $dragStartingTime;
    if (timeSinceStart < 250) {
      this.setState({ $isDragging: false, $hasOutline: false });
      onChange(!checked, event, id);
      return;
    }
    if (checked) {
      if ($pos > (this.$checkedPos + this.$uncheckedPos) / 2) {
        this.setState({
          $pos: this.$checkedPos,
          $isDragging: false,
          $hasOutline: false
        });
        return;
      }
      this.setState({ $isDragging: false, $hasOutline: false });
      onChange(false, event, id);
      return;
    }
    if ($pos < (this.$checkedPos + this.$uncheckedPos) / 2) {
      this.setState({
        $pos: this.$uncheckedPos,
        $isDragging: false,
        $hasOutline: false
      });
      return;
    }
    this.setState({ $isDragging: false, $hasOutline: false });
    onChange(true, event, id);
  }

  $onMouseDown(event) {
    // Ignore right click and scroll
    if (typeof event.button === 'number' && event.button !== 0) {
      return;
    }

    this.$onDragStart(event.clientX);
    window.addEventListener('mousemove', this.$onMouseMove);
    window.addEventListener('mouseup', this.$onMouseUp);
  }

  $onMouseMove(event) {
    event.preventDefault();
    this.$onDrag(event.clientX);
  }

  $onMouseUp(event) {
    this.$onDragStop(event);
    window.removeEventListener('mousemove', this.$onMouseMove);
    window.removeEventListener('mouseup', this.$onMouseUp);
  }

  $onTouchStart(event) {
    this.$onDragStart(event.touches[0].clientX);
  }

  $onTouchMove(event) {
    this.$onDrag(event.touches[0].clientX);
  }

  $onTouchEnd(event) {
    event.preventDefault();
    this.$onDragStop(event);
  }

  $onTouchCancel() {
    this.setState({ $hasOutline: false });
  }

  $onClick(event) {
    const { checked, onChange, id } = this.props;
    onChange(!checked, event, id);
  }

  $onKeyDown(event) {
    const { checked, onChange, id } = this.props;
    const { $isDragging } = this.state;
    // Trigger change on spacebar and enter keys (in violation of wai-aria spec).
    if ((event.keyCode === 32 || event.keyCode === 13) && !$isDragging) {
      event.preventDefault();
      onChange(!checked, event, id);
    }
  }

  render() {
    const {
      checked,
      disabled,
      className,
      offColor,
      onColor,
      offHandleColor,
      onHandleColor,
      uncheckedIcon,
      checkedIcon,
      boxShadow,
      activeBoxShadow,
      id,
      'aria-labelledby': ariaLabelledby,
      'aria-label': ariaLabel,
      dataFeature
    } = this.props;

    const { $pos, $isDragging, $hasOutline } = this.state;

    const rootStyle = {
      position: 'relative',
      display: 'inline-flex',
      textAlign: 'left',
      opacity: disabled ? 0.5 : 1,
      borderRadius: this.$height / 2,
      WebkitTransition: 'opacity 0.25s',
      MozTransition: 'opacity 0.25s',
      transition: 'opacity 0.25s',
      touchAction: 'none',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none'
    };

    const backgroundStyle = {
      height: this.$height,
      width: this.$width,
      margin: Math.max(0, (this.$handleDiameter - this.$height) / 2),
      position: 'relative',
      background: getBackgroundColor($pos, this.$checkedPos, this.$uncheckedPos, offColor, onColor),
      borderRadius: this.$height / 2,
      cursor: disabled ? 'default' : 'pointer',
      WebkitTransition: $isDragging ? null : 'background 0.25s',
      MozTransition: $isDragging ? null : 'background 0.25s',
      transition: $isDragging ? null : 'background 0.25s'
    };

    const checkedIconStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
      marginTop: this.$height > 20 ? '1.5px' : 0,
      marginLeft: '3px',
      height: this.$height,
      width: this.$handleDiameter,
      opacity: ($pos - this.$uncheckedPos) / (this.$checkedPos - this.$uncheckedPos),
      pointerEvents: 'none',
      WebkitTransition: $isDragging ? null : 'opacity 0.25s',
      MozTransition: $isDragging ? null : 'opacity 0.25s',
      transition: $isDragging ? null : 'opacity 0.25s'
    };

    const uncheckedIconStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
      marginTop: this.$height > 20 ? '1.5px' : 0,
      marginLeft: '3px',
      height: this.$height,
      width: this.$handleDiameter,
      opacity: 1 - ($pos - this.$uncheckedPos) / (this.$checkedPos - this.$uncheckedPos),
      pointerEvents: 'none',
      WebkitTransition: $isDragging ? null : 'opacity 0.25s',
      MozTransition: $isDragging ? null : 'opacity 0.25s',
      transition: $isDragging ? null : 'opacity 0.25s'
    };

    const handleStyle = {
      height: this.$handleDiameter,
      width: this.$handleDiameter,
      background: getBackgroundColor($pos, this.$checkedPos, this.$uncheckedPos, offHandleColor, onHandleColor),
      cursor: disabled ? 'default' : 'pointer',
      display: 'inline-block',
      borderRadius: '50%',
      position: 'absolute',
      transform: `translateX(${$pos}px)`,
      top: Math.max(0, (this.$height - this.$handleDiameter) / 2),
      left: 0,
      outline: 0,
      boxShadow: $hasOutline ? activeBoxShadow : boxShadow,
      border: 0,
      WebkitTransition: $isDragging ? null : 'background-color 0.25s, transform 0.25s, box-shadow 0.15s',
      MozTransition: $isDragging ? null : 'background-color 0.25s, transform 0.25s, box-shadow 0.15s',
      transition: $isDragging ? null : 'background-color 0.25s, transform 0.25s, box-shadow 0.15s'
    };

    return (
      <div className={className} style={rootStyle}>
        <div
          className="react-switch-bg"
          style={backgroundStyle}
          onClick={disabled ? null : this.$onClick}
          data-feature={dataFeature || undefined}
        />
        <div
          className="react-switch-handle"
          role="checkbox"
          tabIndex={disabled ? null : 0}
          onMouseDown={disabled ? null : this.$onMouseDown}
          onTouchStart={disabled ? null : this.$onTouchStart}
          onTouchMove={disabled ? null : this.$onTouchMove}
          onTouchEnd={disabled ? null : this.$onTouchEnd}
          onTouchCancel={disabled ? null : this.$onTouchCancel}
          onKeyDown={this.$onKeyDown}
          onFocus={() => this.setState({ $hasOutline: true })}
          onBlur={() => this.setState({ $hasOutline: false })}
          style={handleStyle}
          id={id}
          aria-checked={checked}
          aria-disabled={disabled}
          aria-labelledby={ariaLabelledby}
          aria-label={ariaLabel}
          data-feature={dataFeature || undefined}
        >
          {checkedIcon && (
            <div style={checkedIconStyle}>
              <Icon name="check" size={this.props.small ? 16 : 22} color={this.props.onColor} />
            </div>
          )}
          {uncheckedIcon && (
            <div style={uncheckedIconStyle}>
              <Icon name="close" size={this.props.small ? 16 : 22} color={this.props.offColor} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ReactSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  offColor: PropTypes.string,
  onColor: PropTypes.string,
  offHandleColor: PropTypes.string,
  onHandleColor: PropTypes.string,
  uncheckedIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  checkedIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  boxShadow: PropTypes.string,
  activeBoxShadow: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  'aria-labelledby': PropTypes.string,
  'aria-label': PropTypes.string
};

ReactSwitch.defaultProps = {
  disabled: false,
  small: false,
  offColor: '#888',
  onColor: '#080',
  offHandleColor: '#fff',
  onHandleColor: '#fff',
  boxShadow: null,
  activeBoxShadow: '0 0 2px 3px #3bf'
};

export default ReactSwitch;

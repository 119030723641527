import React, { useCallback } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Assets from '../../services/Assets';
import COLORS from '../../constants/colors';
import Modal from './Modal';
import Img from '../Img/Img';
import { ButtonLink, ButtonsToolbar } from '../Button/Button';

const WarningSignModalStyle = styled.div`
  position: relative;
  min-height: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;

  img.publish-errors-img {
    position: absolute;
    left: -60px;
    height: 100px;
  }

  h4 {
    font-weight: 700;
    font-size: 19px;
    line-height: 25px;
    text-align: ${props => props.textAlign || 'center'};
    margin-bottom: 20px;
  }

  .blackFont {
    color: ${COLORS.GRAY_900};
    margin-left: 20px;
  }

  ${media.greaterThan('medium')`
    padding: 15px 30px 20px 120px;

    img.publish-errors-img {
      height: 165px;
    }

    button {
        font-size: 16px;
    }
  `};
`;

const DescriptionStyle = styled.p`
  text-align: ${props => props.textAlign || 'center'};
  font-weight: 500;
  margin-bottom: 40px;
`;

const WarningModal = React.memo(props => {
  const { children, isOpen, onClose, modalContent, onOpenSubscription = false, textAlign } = props;

  const handleCustomClose = useCallback(
    (event, link) => {
      if (link && link.includes('config/subscription' && typeof onOpenSubscription === 'function')) {
        event?.preventDefault();
        onOpenSubscription();
      }
      onClose();
    },
    [onClose, onOpenSubscription]
  );

  if (!modalContent) {
    return null;
  }

  return (
    <Modal medium isOpen={isOpen} onClose={handleCustomClose}>
      <WarningSignModalStyle textAlign={textAlign}>
        <Img
          src={Assets.image(`publish-errors`)}
          height={200}
          className="publish-errors-img"
          onClick={() => {
            if (typeof onOpenSubscription === 'function') {
              onOpenSubscription();
            }
            onClose();
          }}
        />
        <h4>{modalContent.title}</h4>
        <DescriptionStyle dangerouslySetInnerHTML={{ __html: modalContent.description }} textAlign={textAlign} />
        <ButtonsToolbar>
          {modalContent.blackButtonLink && modalContent.blackButtonText && (
            <ButtonLink
              black
              customPadding={'14px 20px'}
              target={modalContent.blackButtonLink.startsWith('https') ? '_blank' : ''}
              rel={modalContent.blackButtonLink.startsWith('https') ? 'noreferrer noopener' : ''}
              onClick={event => handleCustomClose(event, modalContent.blackButtonLink)}
              href={modalContent.blackButtonLink}
            >
              {modalContent.blackButtonText}
            </ButtonLink>
          )}
          {modalContent.primaryButtonLink && modalContent.primaryButtonText && (
            <ButtonLink
              primary
              customPadding={'14px 20px'}
              className="blackFont"
              target={modalContent.primaryButtonLink.startsWith('https') ? '_blank' : ''}
              rel={modalContent.primaryButtonLink.startsWith('https') ? 'noreferrer noopener' : ''}
              onClick={event => handleCustomClose(event, modalContent.primaryButtonLink)}
              href={modalContent.primaryButtonLink}
            >
              {modalContent.primaryButtonText}
            </ButtonLink>
          )}
        </ButtonsToolbar>
        {children}
      </WarningSignModalStyle>
    </Modal>
  );
});

export default WarningModal;

import styled, { css } from 'styled-components';
import { useRef, useEffect } from 'react';

const sizeStyles = {
  small: css`
    width: 16px;
    height: 16px;
  `,
  medium: css`
    width: 20px;
    height: 20px;
  `
};

const Input = styled.input`
  position: absolute;
  opacity: 0;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ size }) => sizeStyles[size]};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

const checkSizeStyle = {
  small: css`
    top: 3px;
    left: 6px;
    width: 2px;
    height: 7px;
  `,
  medium: css`
    top: 3.5px;
    left: 7.5px;
    width: 3px;
    height: 9px;
  `
};

const getCheckboxStyle = size => css`
  border-radius: ${size === 'small' ? 5 : 6}px;

  &:has(${Input}[type='checkbox']:checked) {
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      border: solid ${({ theme }) => theme.colors.white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      ${checkSizeStyle[size]}
    }
  }

  &:has(${Input}[type='checkbox']:indeterminate) {
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      border: solid ${({ theme }) => theme.colors.white};
      border-width: 0 0 2px 0;
      left: 25%;
      right: 25%;
      top: calc(50% - 1px);
    }
  }
`;

const getRadioStyle = size => css`
  border-radius: 50%;

  &:has(${Input}[type='radio']:checked) {
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: ${size === 'small' ? 5 : 7}px;
      height: ${size === 'small' ? 5 : 7}px;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.white};
      transform: translate(-50%, -50%);
    }
  }
`;

const Container = styled.span`
  position: relative;
  flex-shrink: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.grey.v200};

  &:after {
    box-sizing: content-box;
  }

  ${({ size }) => sizeStyles[size]}
  ${({ type, size }) => (type === 'checkbox' ? getCheckboxStyle(size) : getRadioStyle(size))}

  &:has(${Input}:checked, ${Input}[type='checkbox']:indeterminate) {
    background: ${({ theme }) => theme.colors.secondary};
    border: none;
  }

  &:has(${Input}:hover:not(:disabled, :focus)) {
    border-color: ${({ theme }) => theme.colors.grey.v300};
  }

  &:has(${Input}:focus) {
    border-color: ${({ theme }) => theme.colors.grey.v300};
    box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.colors.grey.v200};
  }

  &:has(${Input}:disabled) {
    background: ${({ theme }) => theme.colors.grey.v100};
  }

  &:has(${Input}:disabled:checked, ${Input}[type='checkbox']:disabled:indeterminate) {
    background: ${({ theme }) => theme.colors.grey.v400};
  }
`;

function CheckboxRadioInput({
  type = 'checkbox',
  value,
  disabled,
  name,
  size = 'medium',
  indeterminate = false,
  className,
  onChange
}) {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <Container onClick={e => e.stopPropagation()} type={type} size={size} indeterminate={indeterminate} className={className}>
      <Input
        ref={inputRef}
        type={type}
        size={size}
        checked={value}
        disabled={disabled}
        name={name}
        onChange={e => {
          e.stopPropagation();
          onChange(e.target.checked);
        }}
      />
    </Container>
  );
}

export default CheckboxRadioInput;

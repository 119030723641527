const colors = [
  {
    name: 'main',
    title: '\nPrimary',
    category: 'Base colors'
  },
  {
    name: 'text',
    title: 'Texts',
    category: 'Base colors'
  },
  {
    name: 'title',
    title: 'Title',
    category: 'Base colors'
  },
  {
    name: 'widget',
    title: 'Widget background',
    category: 'Base colors'
  },
  {
    name: 'card',
    title: 'Card background',
    category: 'Base colors'
  },
  {
    name: 'button_text',
    title: 'Buttons text',
    category: 'Base buttons'
  },
  {
    name: 'button_text_hover',
    title: 'Buttons text on rollover',
    category: 'Base buttons'
  },
  {
    name: 'button_border',
    title: 'Buttons border',
    category: 'Base buttons'
  },
  {
    name: 'button_bg',
    title: 'Buttons background',
    category: 'Base buttons'
  },
  {
    name: 'button_bg_hover',
    title: 'Buttons background on rollover',
    category: 'Base buttons'
  },
  {
    name: 'primary_button_text',
    title: 'Primary button text',
    category: 'Main Button'
  },
  {
    name: 'primary_button_text_hover',
    title: 'Primary button text on rollover',
    category: 'Main Button'
  },
  {
    name: 'primary_button_bg',
    title: 'Primary button background',
    category: 'Main Button'
  },
  {
    name: 'primary_button_bg_hover',
    title: 'Primary button background on rollover',
    category: 'Main Button'
  },
  {
    name: 'consent_button_bg',
    title: 'Consent buttons background color',
    'default': '#f4f4f4',
    category: 'Consent Button'
  },
  {
    name: 'consent_button_bg_hover',
    title: 'Consent buttons background color on rollover',
    'default': '#efefef',
    category: 'Consent Button'
  },
  {
    name: 'consent_button_text',
    title: 'Consent buttons text color',
    'default': '#333333',
    category: 'Consent Button'
  },
  {
    name: 'consent_button_text_hover',
    title: 'Consent buttons text color on rollover',
    'default': '#444444',
    category: 'Consent Button'
  },
  {
    name: 'consent_button_border',
    title: 'Consent buttons border',
    'default': '#f4f4f4',
    category: 'Consent Button'
  },
  {
    name: 'consent_button_border_hover',
    title: 'Consent buttons border on rollover',
    'default': '#dfdfdf',
    category: 'Consent Button'
  },
  {
    name: 'toggle_off',
    title: 'Background color for the unchecked toggle switch',
    category: 'Base colors'
  },
  {
    name: 'toggle_on',
    title: 'Background color for the checked toggle switch',
    'default': '#ffc823',
    category: 'Base colors'
  },
  {
    name: 'backdrop_inner',
    title: 'Overlay backdrop inner color',
    category: 'Overlay'
  },
  {
    name: 'backdrop_outer',
    title: 'Overlay backdrop outer color',
    'default': '#ffffff',
    category: 'Overlay'
  }
];

export default colors;
const publishMessages = {
  checking_subscription: {
    name: 'checking_subscription',
    title: 'Vérification de l\'abonnement 👨\u200d✈️',
    description: 'Nous nous assurons que tout est en règle côté facturation !',
    isError: false
  },
  deploying: {
    name: 'deploying',
    title: 'Déploiement de la configuration 🦸🏽\u200d♀️',
    description: 'Allez hop, on met les cookies dans le four ',
    isError: false
  },
  publishFile_success: {
    name: 'publishFile_success',
    title: 'La publication est terminée 🏖',
    description: 'Tout s\'est bien passé. J’aime quand un plan se déroule sans accroc !',
    isError: false
  },
  publishFile_error: {
    name: 'publishFile_error',
    title: 'La publication n\'a pas pu s\'achever 😟',
    description: 'Ooops ! On s\'appelle pour en parler ? ',
    isError: true
  },
  invalidateFile_success: {
    name: 'invalidateFile_success',
    title: 'La publication n\'a pas pu s\'achever 😟',
    description: 'Ooops ! On s\'appelle pour en parler ? ',
    isError: false
  },
  invalidateFile_error: {
    name: 'invalidateFile_error',
    title: 'La publication n\'a pas pu s\'achever 😟',
    description: 'Ooops ! On s\'appelle pour en parler ? ',
    isError: true
  },
  checkInvalidation_error: {
    name: 'checkInvalidation_error',
    title: 'La publication n\'a pas pu s\'achever 😟',
    description: 'Ooops ! On s\'appelle pour en parler ? ',
    isError: true
  },
  checkInvalidation_success: {
    name: 'checkInvalidation_success',
    title: 'Succès de l\'invalidation 🚀',
    description: 'Tout est OK !',
    isError: false
  },
  checkInvalidation_progress: {
    name: 'checkInvalidation_progress',
    title: 'Encore un peu de patience, le four est en train de chauffer  ! 🧐',
    description: 'Votre site est peut-être déjà à jour en France mais on doit aussi s\'occuper du reste du monde 🗺 Vous pouvez continuer à travailler tranquillement pendant ce temps. ',
    isError: false
  },
  putFile_success: {
    name: 'putFile_success',
    title: 'La cuisson est parfaite 🍪 Votre site est à jour ! ',
    description: 'Mission accomplie ! 🤘 Allez jeter un oeil à votre site .... Tadaaaaa !',
    isError: false
  },
  putFile_error: {
    name: 'putFile_error',
    title: 'Upload KO 😟',
    description: 'Houston, we have a problem !',
    isError: true
  },
  complete: {
    name: 'complete',
    title: 'La cuisson est parfaite 🍪 Votre site est à jour ! ',
    description: 'Mission accomplie ! 🤘 Allez jeter un oeil à votre site .... Tadaaaaa !',
    isError: false
  },
  save_customer: {
    name: 'save_customer',
    title: 'Enregistrement des informations de facturation',
    description: 'Enregistrement des informations de facturation',
    isError: false
  },
  create_payment_source: {
    name: 'create_payment_source',
    title: 'Création du moyen de paiement',
    description: 'Nous utilisons Stripe pour l\'enregistrement de vos données de paiement et nous ne stockons aucune information de paiement chez nous.',
    isError: false
  },
  update_subscription: {
    name: 'update_subscription',
    title: 'Modification de votre abonnement',
    description: 'Nous modifions l\'abonnement existant',
    isError: false
  },
  create_subscription: {
    name: 'create_subscription',
    title: 'Création de votre abonnement',
    description: 'Nous créons un abonnement sur Stripe pour gérer votre facturation',
    isError: false
  },
  err_no_such_coupon: {
    name: 'err_no_such_coupon',
    title: 'Le coupon que vous avez utilisé n\'existe pas !',
    description: 'Vérifiez que vous n\'avez pas fait de faute de frappe et qu\'il n\'y a pas des espaces qui se baladent... Sinon contactez notre support et on cherchera.',
    isError: true
  },
  started: {
    name: 'started',
    title: 'Le déploiement vient de commencer',
    description: 'Accrochez vos ceintures !',
    isError: false
  },
  patching_project: {
    name: 'patching_project',
    title: 'Mise à jour de votre configuration',
    description: 'Nous enregistrons les informations de l\'étape précédente dans notre base de données. Rassurez-vous, aucune donnée sensible ne filtre !',
    isError: false
  },
  billing_error: {
    name: 'billing_error',
    title: 'Erreur liée à la facturation',
    description: 'Une erreur est survenue, merci de bien vouloir revérifier',
    isError: true
  },
  billing_fetching: {
    name: 'billing_fetching',
    title: 'Récupération des informations de facturation',
    description: 'Nous appelons Stripe pour récupérer les informations les plus fraiches',
    isError: false
  },
  save_tax_ids: {
    name: 'save_tax_ids',
    title: 'Enregistrement de numéro fiscaux',
    description: 'Stripe est en train d\'enregistrer les numéros de TVA saisis. Cela peut prendre quelques secondes',
    isError: false
  },
  cancel_subscription: {
    name: 'cancel_subscription',
    title: 'Désactivation de l\'abonnement',
    description: 'On est en train de couper l\'abonnement, ça ne devrait pas prendre longtemps. ',
    isError: false
  },
  creating_version_pdf: {
    name: 'creating_version_pdf',
    title: 'Génération du pdf en cours 🖨',
    description: '...'
  },
  uploading_version_pdf: {
    name: 'uploading_version_pdf',
    title: 'Déploiement du PDF en cours',
    description: '...'
  },
  updating_version_pdf_url: {
    name: 'updating_version_pdf_url',
    title: 'Mise à jour de la version',
    description: '...'
  },
  generating_version_json: {
    name: 'generating_version_json',
    title: 'Création de la configuration',
    description: '...'
  },
  uploading_version_json: {
    name: 'uploading_version_json',
    title: 'Déploiement de la configuration 📤',
    description: '...'
  },
  delete_payment_source: {
    name: 'delete_payment_source',
    title: 'Suppression du moyen de paiement',
    description: 'Nous utilisons Stripe pour l\'enregistrement de vos données de paiement et nous ne stockons aucune information de paiement chez nous.',
    isError: false
  },
  change_payment_source: {
    name: 'change_payment_source',
    title: 'Modification du moyen de paiement par défaut',
    description: 'Nous utilisons Stripe pour l\'enregistrement de vos données de paiement et nous ne stockons aucune information de paiement chez nous.',
    isError: false
  }
};

export default publishMessages;
const processings = {
  language: 'en',
  buttons: [
    { text: 'I refuse', value: 0, action: 'reject' },
    { text: 'I agree', value: 'full', action: 'accept', color: 'primary' }
  ],
  title: '',
  subTitle: '',
  message: '',
  pictureHeight: 100,
  personalizedConsent: false,
  detailLinkText: 'Know more',
  helpers: [],
  processors: [],
  confirmations: {
    accept: {
      title: 'Amazing 🤩',
      message: 'Thank you for your trust !',
      backButtonText: 'Go back'
    },
    partial: {
      title: 'That\'s fine 😊',
      message: '',
      backButtonText: 'Go back'
    },
    reject: {
      title: 'OK 😢',
      message: '',
      backButtonText: 'Go back'
    }
  },
  consentWidgetStrings: {}
};
const contracts = {
  language: 'en',
  buttons: [
    { text: 'Show the document', primary: false, action: 'show' },
    { text: 'I agree', primary: true, action: 'accept' }
  ],
  title: '',
  subTitle: '',
  message: '',
  fileHeight: 100,
  personalizedConsent: false,
  detailLinkText: 'Show more',
  helpers: [],
  consentWidgetStrings: {}
};
const cookies = {
  language: 'en',
  aboutUsId: 'base',
  steps: [],
  strings: {},
  a11yStrings: {},
  settings: {},
  uiSettings: {},
  version: {}
};
const portability = {
  language: 'en',
  title: 'Personal data sharing',
  message: '',
  name: 'Untitled data sharing widget',
  processors: [],
  consentWidgetStrings: {}
};
const dpo = {
  title: 'Data Protection Officer',
  name: 'Untitled DPO widget',
  consentWidgetStrings: {}
};
const weekdays = [
  {
    name: 'monday',
    value: 'mon'
  },
  {
    name: 'tuesday',
    value: 'tue'
  },
  {
    name: 'wednesday',
    value: 'wed'
  },
  {
    name: 'thursday',
    value: 'thu'
  },
  {
    name: 'friday',
    value: 'fri'
  },
  {
    name: 'saturday',
    value: 'sat'
  },
  {
    name: 'sunday',
    value: 'sun'
  }
];
const tcf = {
  content: {},
  language: 'en',
  country: 'EU',
  aboutUsId: 'base',
  vendors: [],
  purposes: [],
  stacks: [],
  googleAdditionalConsent: false,
  googleConsentMode: {},
  settings: {
    continueWithoutConsent: false,
    askNewConsent: false,
    expirationTtlDays: 365
  }
};

const defaultValues = { processings, portability, contracts, cookies, dpo, weekdays, tcf };

export default defaultValues;


import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.span`
  display: flex;
  gap: 8px;
`;

const inactiveStyle = css`
  background-color: ${({ theme }) => theme.colors.grey.v200};
  width: 8px;
`;

const activeStyle = css`
  background-color: ${({ theme }) => theme.colors.primary};
  width: 32px;
`;

const BulletStep = styled.span`
  display: inline-block;
  height: 8px;
  border-radius: 4px;
  transition: all 0.25s ease;

  ${({ active }) => (active ? activeStyle : inactiveStyle)};
`;

const BulletSteps = forwardRef(({ count, currentStep, className }, ref) => {
  return (
    <Container ref={ref} className={className}>
      {[...Array(count)].map((_, index) => (
        <BulletStep key={index} active={currentStep === index} />
      ))}
    </Container>
  );
});

export default BulletSteps;

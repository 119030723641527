const emailTemplates = {
  'reset-password': {
    name: 'reset-password',
    templateId: 'd-7fa9ecae07844b95b98913e90054429b'
  },
  invitation: {
    name: 'invitation',
    templateId: 'd-c539563ba2ec4cc6b5466895f2cd1f95'
  },
  invitationOrganization: {
    name: 'invitationOrganization',
    templateId: 'd-9afe799039404203ae826d8862fdf6d0'
  }
};

export default emailTemplates;
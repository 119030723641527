import React, { PureComponent, forwardRef } from 'react';

export default function createContextHOC(Context, getPropsFromContextValue) {
  return function withContext(WrappedComponent, toClass = false) {
    if (toClass) {
      const Wrapper = class extends PureComponent {
        render() {
          return (
            <Context.Consumer>
              {value => {
                return React.createElement(WrappedComponent, Object.assign({}, this.props, getPropsFromContextValue(value)));
              }}
            </Context.Consumer>
          );
        }
      };

      Wrapper.WrappedComponent = WrappedComponent;
      return Wrapper;
    }
    const anonymousContextWrapped = forwardRef((props, ref) => {
      return (
        <Context.Consumer>
          {value => {
            return React.createElement(WrappedComponent, Object.assign({ ref }, props, getPropsFromContextValue(value)));
          }}
        </Context.Consumer>
      );
    });
    anonymousContextWrapped.WrappedComponent = WrappedComponent;
    return anonymousContextWrapped;
  };
}

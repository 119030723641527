import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const WebsiteCaptureStyle = styled.img`
  z-index: 10;
  position: relative;
  display: block;
  width: ${props => (props.width ? props.width : 100)}px;
  height: ${props => (props.height ? props.height : 100)}px;
  border-radius: 8px 8px 0 0;
`;

const isValidHttpUrl = string => {
  try {
    return /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(string);
  } catch (e) {
    return false;
  }
};

const WebsiteCapture = ({ websiteUrl, width, height }) => {
  const defaultUrl = 'https://axeptio.imgix.net/2019/02/placeholder-webx3.png';
  const [imgUrl, setImgUrl] = useState(defaultUrl);

  useEffect(() => {
    let isMounted = true;

    // search and add if needed 'https://' in the url
    const regex = '^(http|https):';
    const setHttp = websiteUrl?.search(regex) ? 'https://' : '';

    if (!websiteUrl || !isValidHttpUrl(setHttp + websiteUrl)) return;

    const fetchData = async () => {
      const API_KEY = process.env.REACT_APP_API_FLASH_KEY;
      const ratio = width / height;
      const w = 1600;
      const h = parseInt(w / ratio);
      const thumbnail_width = parseInt(width * 1.5 < w ? width * 1.5 : w);
      const options = `&format=jpeg&width=${w}&height=${h}&ttl=2592000&thumbnail_width=${thumbnail_width}`;
      const apiFlashUrl = `https://api.apiflash.com/v1/urltoimage?access_key=${API_KEY}&url=${setHttp}${websiteUrl}${options}&response_type=json`;

      try {
        const response = await fetch(apiFlashUrl);
        if (!response.ok) {
          throw new Error(`HTTP ${response.status}`);
        }
        const json = await response.json();
        if (isMounted) {
          setImgUrl(json.url); // Update state only if component is still mounted
        }
      } catch (error) {
        console.error(`Could not get image from ${websiteUrl}:`, error);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [websiteUrl, width, height]);

  return <WebsiteCaptureStyle src={imgUrl} width={width} height={height} alt={`Preview of ${websiteUrl}`} />;
};

WebsiteCapture.propTypes = {
  websiteUrl: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number
};

WebsiteCapture.defaultProps = {
  width: 100,
  height: 100
};

export default WebsiteCapture;
export { WebsiteCaptureStyle };

import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

function Link({ innerRef, href, callBack, dataFeature, children, ...linkProps }) {
  return (
    <ReactRouterLink to={href} {...linkProps}>
      {children}
    </ReactRouterLink>
  );
}

export { Link };

import React, { Component, useContext } from 'react';
import FR from '../data/FR/index.js';
import EN from '../data/EN/index.js';
import { changeLanguage } from 'i18next';

const data = { FR, EN };
const LocaleContext = React.createContext();
function browserLocale() {
  let lang;
  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0];
  } else if (navigator.userLanguage) {
    lang = navigator.userLanguage;
  } else {
    lang = navigator.language;
  }
  return lang;
}
function updateHtmlLangAttribute(lang) {
  if (lang) {
    document.documentElement.lang = lang.toLowerCase();
    changeLanguage(lang.toLowerCase() === 'fr' ? 'fr' : 'en');
  }
}
class LocaleManager extends Component {
  state = this.getInitialState();
  getInitialState() {
    const language = browserLocale();

    const urlParams = new URLSearchParams(window.location.search.substring(1));
    const pushedLanguage =
      urlParams && ['fr', 'en'].includes(urlParams.get('lang')?.toLowerCase()) ? urlParams.get('lang')?.toUpperCase() : null;

    let currentLocale = language.indexOf('fr') > -1 ? 'FR' : 'EN';

    if (window.localStorage.getItem('admin_locale')) {
      currentLocale = window.localStorage.getItem('admin_locale');
    }

    const localState = Object.assign(
      {
        setLocale: locale => {
          if (locale !== this.state.locale) {
            this.setState({ locale });
            window.localStorage.setItem('admin_locale', locale);
          }
        },
        locale: pushedLanguage || currentLocale
      },
      data[pushedLanguage || currentLocale]
    );

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      lang: localState.locale?.toLowerCase()
    });

    return localState;
  }

  static getLocales() {
    return ['EN', 'FR'];
  }

  componentDidMount() {
    updateHtmlLangAttribute(this.state.locale);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.locale !== this.props.locale) {
      this.state.setLocale(this.props.locale);
      updateHtmlLangAttribute(this.props.locale);
    }
    if (prevState.locale !== this.state.locale) {
      updateHtmlLangAttribute(this.state.locale);
      this.setState(data[this.state.locale], () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.locale);
        }
      });
    }
  }

  render() {
    return <LocaleContext.Provider value={this.state}>{this.props.children}</LocaleContext.Provider>;
  }
}
const withLocaleContext = Component => {
  return props => <LocaleContext.Consumer>{locale => <Component {...props} locale={locale} />}</LocaleContext.Consumer>;
};
function useLocale() {
  return useContext(LocaleContext);
}
export { LocaleManager, withLocaleContext, useLocale };
export default LocaleContext;

const tcfTemplates = [
  {
    name: 'tcf-consent-mode',
    label: 'Google Consent Mode v2 ready',
    description: 'Create a configuration with Consent mode enabled',
    icon: 'icon_v2_security',
    'googleconsentmode.display': true,
    'googleconsentmode.ads_data_redaction': true,
    'googleconsentmode.url_passthrough': true,
    recommended: true
  }
];

export default tcfTemplates;
import React from 'react';
import languages from '@cospired/i18n-iso-languages';
import { supportedLanguages } from '../../base/helpers';
import PropTypes from 'prop-types';
import ReactSelect from '../ReactSelect/ReactSelect';
import { useTranslation } from 'react-i18next';

const LanguageSelect = props => {
  const { t } = useTranslation();
  const { placeholder, locale, value, onChange, availableLanguages = [] } = props;
  const supportedOptions = Object.entries(languages.getNames(locale.locale.toLowerCase()))
    .filter(item => {
      return supportedLanguages.includes(item[0]);
    })
    .sort((itemA, itemB) => {
      return itemA[1].localeCompare(itemB[1]);
    })
    .map(item => ({
      value: item[0],
      label: item[1]
    }));
  const translatedOptions = supportedOptions.filter(option => {
    return availableLanguages.includes(option.value);
  });
  const untranslatedOptions = supportedOptions.filter(option => {
    return !availableLanguages.includes(option.value);
  });
  const currentOption = supportedOptions.filter(option => {
    return option.value === value;
  })[0];

  return (
    <>
      <ReactSelect
        placeHolder={placeholder ?? t('select_language_placeholder')}
        options={[
          {
            label: t('project_onboarding_language_available'),
            options: translatedOptions
          },
          {
            label: t('project_onboarding_language_others'),
            options: untranslatedOptions
          }
        ]}
        value={currentOption}
        onChange={option => {
          onChange(option.value);
        }}
      />
    </>
  );
};

LanguageSelect.propTypes = {
  placeholder: PropTypes.string,
  locale: PropTypes.object,
  value: PropTypes.string,
  availableLanguages: PropTypes.array,
  onChange: PropTypes.func
};

export default LanguageSelect;

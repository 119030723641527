import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useApi } from '../../contexts/ApiContext';
import LocaleContext from '../../contexts/LocaleContext';
import Assets from '../../services/Assets';
import CountrySelect from '../CountrySelect/CountrySelect';
import Img from '../Img/Img';
import Input from '../Input/Input';
import { addToast } from '../Toast/Toast';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0px;
  }
`;
const Informations = styled.div`
  display: flex;
  padding: 4px 16px;
  gap: 16px;
  align-items: center;
`;
const InformationsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 24px 16px;
  gap: 32px;
`;
const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  gap: 4px;
`;
const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  &:after {
    content: '*';
    color: red;
    margin-left: 4px;
  }
`;
const Error = styled.span`
  font-size: 9px;
  color: red;
`;

const UserCompanyStep = ({ footerCount, currentStep, onBack, onNext }) => {
  const { t } = useTranslation();
  const api = useApi();
  const locale = useContext(LocaleContext);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      companyName: '',
      companyLocation: 'FR'
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async data => {
    if (!isLoading) {
      setIsLoading(true);
      await api
        .updateUser({ 'data.company.name': data.companyName, 'data.company.country': data.companyLocation })
        .then(() => onNext())
        .catch(error => addToast.error(error.message));
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Informations>
        <InformationsTextContainer>
          <h3>{t('onboarding_modal_welcome_title')}</h3>
          <p>{t('onboarding_modal_welcome_description')}</p>
        </InformationsTextContainer>
        <Img src={Assets.image('Cookie_Love (2)')} width={85} height={93} />
      </Informations>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputWithLabel>
          <Label>{t('onboarding_modal_company_name_input_label')}</Label>
          <Input
            {...register('companyName', { required: true, minLength: 1 })}
            type="text"
            placeholder={t('onboarding_modal_company_name_input_placeholder')}
            error={errors.companyName}
            autoFocus
          />
          {errors?.companyName && <Error>{t('field_required')}</Error>}
        </InputWithLabel>
        <InputWithLabel>
          <Label>{t('onboarding_modal_company_location_input_label')}</Label>
          <Controller
            name="companyLocation"
            control={control}
            render={({ field }) => <CountrySelect locale={locale} {...field} />}
          />
        </InputWithLabel>
        <Footer count={footerCount} currentStep={currentStep} onBack={() => onBack()} isLoading={isLoading} />
      </FormContainer>
    </Container>
  );
};

export default UserCompanyStep;

const emailTemplates = {
  'reset-password': {
    name: 'reset-password',
    templateId: 'd-890563aacda94075bd009a888b1bfe15'
  },
  invitation: {
    name: 'invitation',
    templateId: 'd-9432de034df9481ba834cc948c4393b0'
  },
  invitationOrganization: {
    name: 'invitationOrganization',
    templateId: 'd-8e6bda61cfd342fc8a4fd81890dc2f15'
  }
};

export default emailTemplates;
import styled, { css } from 'styled-components';
import FONTS from '../../constants/fonts';

const sizeStyles = {
  small: css`
    font-size: 14px;
  `,
  medium: css`
    font-size: 16px;
  `
};

const accentStyle = css`
  font-weight: 550;
`;

const Root = styled.span`
  font-family: ${FONTS.SOURCE};
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.grey.secondary};

  ${({ size }) => sizeStyles[size]};
  ${({ accent }) => accent && accentStyle};
`;

function TypographyLabel({ as = 'span', size = 'medium', accent = false, className, children }) {
  return (
    <Root as={as} size={size} accent={accent} className={className}>
      {children}
    </Root>
  );
}

export default TypographyLabel;

import styled from 'styled-components';
import COLORS from '../../constants/colors';
import Icon from '../Icon/Icon';

const Style = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${props => (props.large ? 22 : 16)}px;
  height: ${props => (props.large ? 22 : 16)}px;
  background: ${props => (props.unChecked ? COLORS.WHITE : COLORS.YELLOW)};
  border-radius: 50%;

  ${props =>
    props.unChecked &&
    `
    border: 1px solid ${COLORS.GRAY_400};
  `}
`;

const Check = ({ unChecked, large, ...props }) => (
  <Style unChecked={unChecked} large={large} {...props}>
    <Icon size={large ? 16 : 12} color={unChecked ? COLORS.GRAY_400 : COLORS.WHITE} name={unChecked ? 'close' : 'check'} />
  </Style>
);

export default Check;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast as sonnerToast } from 'sonner'; // eslint-disable-line no-restricted-imports
import Icon from '../Icon/Icon';
import ProgressBar from '../ProgressBar/ProgressBar';
import Portal from '../Portal/Portal';
import { useLocale } from '../../contexts/LocaleContext';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const bulletSize = '26px';

const FloatingContainer = styled.div`
  z-index: 3000;
  position: fixed;
  top: 64px;
  right: 14px;
`;

const Style = styled.div`
  width: 360px;
  font-family: ${FONTS.PRIMARY};
  background: ${COLORS.WHITE};
  color: ${COLORS.GRAY_900};
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 24px;

  ${props =>
    props.toastStyle === 'yellowWarning' &&
    `
      background: ${COLORS.YELLOW_100};

      &::before {
        border-left: 4px solid ${COLORS.YELLOW};
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 8px;
        height: 100%;
        border-radius: 8px;
      }
  `};

  .buttonUpgradeFreeTrial {
    display: inline-block;
    margin: 10px 0 0;
    font-size: 14px;
    font-family: ${FONTS.TITLE};
    font-weight: bold;
    text-align: center;
    color: ${COLORS.GRAY_900};
    background: ${COLORS.YELLOW};
    border-radius: 100px;
    padding: 10px 24px;

    &:hover {
      color: ${COLORS.YELLOW};
      background: ${COLORS.GRAY_900};
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;
  min-height: ${bulletSize};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h6`
  margin: 0;
  padding-right: 10px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.5;
`;

const Description = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 13px;
  color: ${COLORS.GRAY_500};
`;

const Left = styled.div`
  width: 44px;
  height: ${bulletSize};
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
`;

const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${bulletSize};
  height: ${bulletSize};
  background: ${props => (props.error ? COLORS.REDORANGE : props.warning ? COLORS.YELLOW : COLORS.GREEN_200_D)};
  border-radius: 50%;
  box-shadow: 0 0 0 2px ${props => (props.error ? COLORS.RED_100 : props.warning ? COLORS.YELLOW_100 : COLORS.GREEN_200_D)};
`;

const Body = styled.div`
  padding-top: 14px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-top: 4px;

  > button ~ button {
    margin-left: 8px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background: ${COLORS.GRAY_100};
  }
`;

const iconsByStatus = {
  error: 'close',
  warning: 'warning',
  success: 'check'
};

const InnerToast = ({
  title,
  status,
  message,
  children,
  description,
  progress,
  actions,
  onClose,
  toastStyle,
  inline,
  ...props
}) => {
  const locale = useLocale();
  const publishMessage = locale?.publishMessages[message];

  function getLeft() {
    if (!status) {
      return null;
    }
    if (status === 'loading') {
      return (
        <Left>
          <LoadingIndicator small inline />
        </Left>
      );
    }

    return (
      <Left>
        <Bullet error={status === 'error'} warning={status === 'warning'}>
          <Icon size={18} name={iconsByStatus[status]} color={status === 'warning' ? '#212121' : '#ffffff'} />
        </Bullet>
      </Left>
    );
  }

  const toast = (
    <Style hasBullet={status} toastStyle={toastStyle} inline={inline} {...props}>
      {getLeft()}

      <Content>
        {publishMessage && (
          <>
            <Title>{publishMessage.title}</Title>
            {Boolean(publishMessage.description) && <Description>{publishMessage.description}</Description>}
          </>
        )}

        {title && !publishMessage && <Title>{title}</Title>}
        {message && !publishMessage && <Description>{message}</Description>}
        {description && !publishMessage && <Description>{description}</Description>}
        {children && <Body>{children}</Body>}
        {onClose && (
          <CloseButton onClick={onClose}>
            <Icon size={16} name="close" color={COLORS.GRAY_900} />
          </CloseButton>
        )}
        {progress > 0 && <ProgressBar sticky size={progress} />}
        {actions && <Actions>{actions}</Actions>}
      </Content>
    </Style>
  );

  return inline ? toast : <FloatingContainer>{toast}</FloatingContainer>;
};

const requiredProps = {
  status: PropTypes.oneOf(['error', 'success', 'warning', 'loading']),
  message: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  progress: PropTypes.number,
  actions: PropTypes.element,
  onClose: PropTypes.func
};

InnerToast.propTypes = requiredProps;

const Toast = props =>
  props.noPortal ? (
    <InnerToast {...props} />
  ) : (
    <Portal>
      <InnerToast {...props} />
    </Portal>
  );

Toast.propTypes = requiredProps;

Toast.defaultProps = {
  title: 'Here is a toast'
};

function addToastWithStatus(title, { message, status, duration, actions }) {
  const toastId = sonnerToast.custom(
    toastId => (
      <Toast
        title={title}
        message={message}
        status={status}
        actions={actions}
        noPortal
        inline
        onClose={() => sonnerToast.dismiss(toastId)}
      />
    ),
    {
      duration
    }
  );
  return toastId;
}

function addToast(title, { message = null, actions } = {}) {
  addToastWithStatus(title, { message, actions, status: null });
}

addToast.success = (title, { message = null, actions } = {}) =>
  addToastWithStatus(title, { message, actions, status: 'success' });

addToast.error = (title, { message = null, actions } = {}) => addToastWithStatus(title, { message, actions, status: 'error' });

addToast.warning = (title, { message = null, actions } = {}) =>
  addToastWithStatus(title, { message, actions, status: 'warning' });

addToast.promise = (promise, title, { message = null, actions } = {}) => {
  const toastId = addToastWithStatus(title, { message, actions, status: 'loading', duration: Infinity });
  promise.finally(() => sonnerToast.dismiss(toastId));
};

export default memo(Toast);

export { addToast };

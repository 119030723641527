import React, { Component, useContext } from 'react';
import createContextHOC from './createContextHOC';
import accessLimits from '../components/AxeptioAdmin/Data/accessLimits';
import Button from '../components/Button/Button';
import LocaleContext from './LocaleContext';
import { openConfigurationPage } from '../components/AxeptioAdmin/Pages/ConfigurationPageCard';
import { withTranslation } from 'react-i18next';

const AccessContext = React.createContext();

class AccessManager extends Component {
  state = {
    type: 'free',
    activeServices: [],
    user: this.props.user,
    fetchUserOrganizations: cb => {
      this.props.api.fetchDocs('organizations', cb);
    },
    setProject: (project, projectAccessMapping = null, cb) => {
      this.setState(
        {
          type: this.getTypeFromProject(project),
          activeServices: Object.entries(projectAccessMapping?.services || {})
            .filter(([key, value]) => value)
            .map(([key]) => key),
          project: project
        },
        cb
      );
    },
    getSubscriptionURL: () => {
      return `/projects/${this.state.project._id}/config/subscription`;
    },
    hasUnlockedService: serviceName => {
      return this.state.activeServices.indexOf(serviceName) > -1;
    },
    can: (service, action) => {
      if (this.state.hasUnlockedService(service)) {
        return true;
      }
      return !!this.state.getLimit(action);
    },
    getLimit: (service, action) => {
      if (this.state.hasUnlockedService(service)) {
        return null;
      }
      const limits = accessLimits.filter(limit => limit.name === action && limit.type === this.state.type);
      return limits[0] ? limits[0].value : null;
    },
    openSubscription: (locale, api) => {
      openConfigurationPage({
        project: this.state.project,
        conf: locale.projectConfigurations.filter(conf => conf.name === 'subscription')[0]
      });
    }
  };
  getTypeFromProject(project) {
    const subscription = project?.billing?.subscription || project?.thirdPartyBilling?.subscription;
    if (subscription?.status !== 'active') {
      return 'free';
    }
    return 'paid';
  }
  render() {
    return <AccessContext.Provider value={this.state}>{this.props.children}</AccessContext.Provider>;
  }
}

const TranslatedAccessManager = withTranslation()(AccessManager);

const getProps = value => {
  return {
    user: value.user,
    accessType: value.type,
    canAccess: value.can.bind(value),
    getAccessLimit: value.getLimit.bind(value),
    setProjectForAccess: value.setProject.bind(value),
    fetchUserOrganizations: value.fetchUserOrganizations.bind(value),
    hasUnlockedService: value.hasUnlockedService.bind(value),
    isPaidUser: value.activeServices.length > 0,
    getSubscriptionURL: value.getSubscriptionURL.bind(value),
    activeServices: value.activeServices,
    getUpgradeAction: () => (
      <LocaleContext.Consumer>
        {locale => (
          <div>
            <span>{value.t('access_upgrade_text')}</span>
            &nbsp;
            <Button secondary small simple onClick={() => value.openSubscription.call(locale, this.props.api)}>
              {value.t('access_upgrade_btn')}
            </Button>
          </div>
        )}
      </LocaleContext.Consumer>
    )
  };
};
const withAccess = createContextHOC(AccessContext, getProps);

function useAccess() {
  return useContext(AccessContext);
}

export { withAccess, useAccess, TranslatedAccessManager as AccessManager };
export default AccessContext;

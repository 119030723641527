import update from 'immutability-helper';
import React, { Suspense } from 'react';
import { createBrowserRouter, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { withAccess } from 'src/contexts/AccessContext';
import assets from 'src/services/Assets';
import { getObjectsDiff } from '../../base/helpers';
import OnboardingModal from '../../components/OnboardingModal/OnboardingModal';
import { withApiContext } from '../../contexts/ApiContext';
import { withProjectContext } from '../../contexts/ProjectContext';
import {
  ConfigWrapper,
  EditableComponent,
  EditorWrapper,
  OrganizationWrapper,
  RouterComponent,
  ServiceWrapper,
  TCFDesignMain,
  Wrapper
} from './RoutesWrapper';
import withEditor from './withEditor';

const NotFound = React.lazy(() => import('./Pages/404'));
const Error = React.lazy(() => import('./Pages/Error'));
const ProjectOnboarding = React.lazy(() => import('./ProjectOnboarding'));
const StringsForm = React.lazy(() => import('./Services/StringsForm/StringsForm'));
const NotificationCenter = React.lazy(() => import('./Pages/NotificationCenter'));
const TcfVendors = React.lazy(() => import('./Services/TCF/TcfVendors'));
const TcfSettingsForm = React.lazy(() => import('./Services/TCF/TcfIntegration'));
const Project = React.lazy(() => import('./Project'));
const StringsStepForm = React.lazy(() => import('./Services/StringsForm/StringsStepForm'));
const LoginForm = React.lazy(() => import('../LoginForm/LoginForm'));
const ImageChooser = React.lazy(() => import('./Services/Cookies/CookiesEditor/ImageChooser'));
const SettingsForm = React.lazy(() => import('./Services/Cookies/CookiesEditor/SettingsForm'));
const ProcessingConfirmationEditor = React.lazy(() => import('./Services/Processings/ProcessingConfirmationEditor'));
const UserAccount = React.lazy(() => import('./Pages/UserAccount'));
const SaasMantra = React.lazy(() => import('./Pages/SaasMantra'));
const ConsentEditor = React.lazy(() => import('./Services/Consent/ConsentEditor/ConsentEditor'));
const CSTools = React.lazy(() => import('./Pages/CSTools/CSTools'));
const CSToolsListing = React.lazy(() => import('./Pages/CSTools/CSToolsListing'));
const ServicesAccess = React.lazy(() => import('./Pages/CSTools/ServicesAccess'));
const FeatureFlags = React.lazy(() => import('./Pages/CSTools/FeatureFlags'));
const Projects = React.lazy(() => import('./Pages/Projects'));

export const routes = (locale, __self, t) => {
  const RouterProps = {
    locale,
    state: __self.state,
    api: __self.props.api,
    user: __self.state.user,
    ...__self,
    ...__self.props
  };

  const createElementWithWrapper = ({ breadCrumb = [], serviceName = null, footer = false, element = <></> }) => (
    <Wrapper {...RouterProps} serviceName={serviceName} footer={footer} element={element} breadCrumb={breadCrumb} />
  );

  if (!__self.state.user && !__self.state.isFetchingUser) {
    const params = new URLSearchParams(window.location.search.substring(1));

    const routes = [
      {
        path: '*',
        element: (
          <Suspense fallback={<div></div>}>
            <LoginForm
              api={__self.props.api}
              mode={__self.state.authMode}
              accessToken={params.get('access_token')}
              locale={locale}
              onUser={user => {
                if (window.location.href.includes('/zendesk/sso')) {
                  __self.props.api.handleZendeskSso();
                } else {
                  __self.removeInvitationToken();
                  __self.setState({ user });
                }
              }}
            />
          </Suspense>
        )
      }
    ];
    return routes;
  }

  const mainProjectsBreadCrumb = [{ title: t('projects_title'), key: 'ProjectId' }];
  const mainConfigBreadCrumb = [...mainProjectsBreadCrumb, { key: 'ServiceName' }, { key: 'ConfigName' }];

  const routes = [
    {
      element: (
        <>
          {__self.featureFlagsContextValue.featureFlagIsEnabled('projectsOnboardingModal') && <OnboardingModal />}
          <Outlet />
        </>
      ),
      errorElement: (
        <Suspense fallback={<div></div>}>
          <Error {...RouterProps} />
        </Suspense>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to="/projects" />
        },
        {
          path: '*',
          element: (
            <Suspense fallback={<div></div>}>
              <NotFound />
            </Suspense>
          )
        },
        {
          path: '/error',
          element: (
            <Suspense fallback={<div></div>}>
              <Error {...RouterProps} />
            </Suspense>
          )
        },
        {
          path: '/zendesk/sso',
          loader: async () => {
            await __self.props.api.handleZendeskSso();
            return null;
          },
          element: <>Zendesk connexion...</>
        },
        {
          path: '/notifications',
          element: (
            <RouterComponent breadCrumb={[{ title: t('notification_breadcrumb_title') }]} {...RouterProps}>
              <NotificationCenter api={__self.props.api} />
            </RouterComponent>
          )
        },
        {
          path: '/account',
          children: [
            {
              path: '',
              element: (
                <RouterComponent breadCrumb={[{ title: t('account_breadcrumb_title') }]} {...RouterProps}>
                  <UserAccount {...__self.props} user={__self.state.user} api={__self.props.api} />
                </RouterComponent>
              )
            },
            {
              path: 'organizations/:organizationId?',
              element: (
                <OrganizationWrapper
                  breadCrumb={[{ title: t('account_breadcrumb_title') }, { title: t('organization_breadcrumb_title') }]}
                  {...RouterProps}
                />
              )
            }
          ]
        },
        {
          path: '/saasmantra',
          element: (
            <RouterComponent breadCrumb={[{ title: 'SaasMantra' }]} {...RouterProps}>
              <SaasMantra {...__self.props} user={__self.state.user} />
            </RouterComponent>
          )
        },
        {
          path: '/projects',
          children: [
            {
              path: '',
              element: (
                <RouterComponent breadCrumb={[]} {...RouterProps}>
                  <Projects
                    user={__self.state.user}
                    onAddProject={(projectId, projectName) => {
                      __self.startOnboarding(projectId, projectName);
                    }}
                  />
                </RouterComponent>
              )
            },
            {
              path: 'onboarding/:projectId',
              element: <Navigate to="../../" relative="path" />
            },
            {
              path: 'onboarding/:projectId?/:projectName?',
              element: createElementWithWrapper({
                breadCrumb: [{ title: t('projects_title') }, { title: 'Onboarding' }],
                serviceName: 'cookies',
                element: ProjectOnboarding
              })
            },
            {
              path: ':projectId',
              children: [
                {
                  path: '',
                  element: createElementWithWrapper({
                    breadCrumb: [...mainProjectsBreadCrumb],
                    element: Project
                  })
                },
                {
                  path: 'config',
                  children: [
                    {
                      path: '',
                      element: <Navigate to=".." relative="path" />
                    },
                    {
                      path: ':config',
                      element: <ConfigWrapper breadCrumb={[...mainProjectsBreadCrumb, { key: 'ConfigPage' }]} {...RouterProps} />
                    },
                    {
                      path: 'design/illustration',
                      element: createElementWithWrapper({
                        serviceName: 'cookies',
                        breadCrumb: [...mainProjectsBreadCrumb, { title: 'Design' }, { title: 'Illustration' }],
                        element: withApiContext(props => {
                          const navigate = useNavigate();
                          return (
                            <ImageChooser
                              {...props}
                              api={props.api}
                              tag="axeptio-button"
                              onChange={async image => {
                                await props.api.patch('projects', props.projectId, {
                                  'customButton.active': true,
                                  'customButton.image': image
                                });
                                navigate('..', { relative: 'path' });
                              }}
                            />
                          );
                        })
                      })
                    }
                  ]
                },
                {
                  path: ':serviceName/:identifier',
                  children: [
                    {
                      path: '',
                      element: <EditorWrapper breadCrumb={[...mainConfigBreadCrumb]} {...RouterProps} />
                    }
                  ]
                },
                {
                  path: 'tcf',
                  children: [
                    {
                      path: '',
                      element: (
                        <ServiceWrapper
                          serviceName={'tcf'}
                          breadCrumb={[...mainProjectsBreadCrumb, { key: 'ServiceName' }]}
                          {...RouterProps}
                        />
                      )
                    },
                    {
                      path: ':identifier/vendors',
                      element: createElementWithWrapper({
                        serviceName: 'tcf',
                        element: TcfVendors,
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'Vendors' }]
                      })
                    },
                    {
                      path: ':identifier/integration',
                      element: createElementWithWrapper({
                        serviceName: 'tcf',
                        element: TcfSettingsForm,
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'Integration' }]
                      })
                    },
                    {
                      path: ':identifier/design',
                      element: createElementWithWrapper({
                        serviceName: 'tcf',
                        element: TCFDesignMain,
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'Design' }]
                      })
                    },
                    {
                      path: ':identifier/illustration',
                      element: createElementWithWrapper({
                        serviceName: 'tcf',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'Illustration' }],
                        element: withApiContext(
                          withEditor(props => {
                            const navigate = useNavigate();
                            return (
                              <ImageChooser
                                {...props}
                                api={props.api}
                                tag="cookies"
                                filterOut="deprecated"
                                value={assets.image('')}
                                onChange={async image => {
                                  await props.onUpdateFieldAsync('content', {
                                    ...props.value.content,
                                    logo: encodeURI(image),
                                    logoWidth: 120,
                                    logoHeight: 120
                                  });
                                  navigate('..', { relative: 'path' });
                                }}
                              />
                            );
                          })
                        )
                      })
                    }
                  ]
                },
                {
                  path: 'cookies',
                  children: [
                    {
                      path: '',
                      element: (
                        <ServiceWrapper
                          serviceName={'cookies'}
                          breadCrumb={[...mainProjectsBreadCrumb, { key: 'ServiceName' }]}
                          {...RouterProps}
                        />
                      )
                    },
                    {
                      path: ':identifier/integration',
                      element: createElementWithWrapper({
                        serviceName: 'cookies',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'Integration' }],
                        element: withApiContext(
                          withEditor(props => {
                            return (
                              <SettingsForm
                                project={props.project}
                                config={props.value}
                                value={props.value.settings}
                                api={props.api}
                                onSave={settings => props.onUpdateField('settings', settings)}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/strings',
                      element: createElementWithWrapper({
                        serviceName: 'cookies',
                        breadCrumb: [...mainConfigBreadCrumb, { title: t('breadcrumb_text') }],
                        element: withApiContext(
                          withEditor(props => {
                            return (
                              <StringsForm
                                {...props}
                                service={'cookies'}
                                strings={props.value.strings}
                                a11yStrings={props.value.a11yStrings}
                                value={props.value['strings']}
                                config={props.value}
                                onSave={(strings, a11yStrings) => {
                                  if (a11yStrings) {
                                    props.onUpdateSpecs({
                                      strings: { $set: strings },
                                      a11yStrings: { $set: a11yStrings }
                                    });
                                  } else {
                                    props.onUpdateSpecs({ strings: { $set: strings } });
                                  }
                                }}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/:editableComponent/:index?',
                      element: createElementWithWrapper({
                        serviceName: 'cookies',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'item' }],
                        element: withApiContext(
                          withEditor(props => {
                            return (
                              <EditableComponent
                                {...props}
                                parentStrings={props.value.strings}
                                a11yParentStrings={props.value.a11yStrings}
                                language={props.value?.language?.toLowerCase() || 'en'}
                                country={props.value?.country}
                                subdivision={props.value?.subdivision}
                                regulation={props.regulation}
                                regulationFeatures={props.regulationFeatures}
                                regulationDefaultValues={props.regulationDefaultValues}
                                onOpenSubscription={props.onOpenSubcription}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/:editableComponent/:index/illustration',
                      element: createElementWithWrapper({
                        serviceName: 'cookies',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'item' }, { title: 'Illustration' }],
                        element: withApiContext(
                          withEditor(props => {
                            const navigate = useNavigate();
                            return (
                              <ImageChooser
                                {...props}
                                api={props.api}
                                tag="cookies"
                                filterOut="deprecated"
                                value={assets.image(props.value[props.editableComponent][props.stepIndex].layout)}
                                onChange={async image => {
                                  let value = props.value[props.editableComponent];
                                  value[props.stepIndex].image = image;
                                  await props.onUpdateFieldAsync(props.editableComponent, value);
                                  navigate('..', { relative: 'path' });
                                }}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/:editableComponent/:index?/strings',
                      element: createElementWithWrapper({
                        serviceName: 'cookies',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'item' }, { title: t('breadcrumb_text') }],
                        element: withApiContext(
                          withEditor(props => {
                            return (
                              <StringsStepForm
                                {...props}
                                inStep
                                service="cookies"
                                strings={props.value[props.editableComponent][props.stepIndex].strings}
                                a11yStrings={props.value[props.editableComponent][props.stepIndex].a11yStrings}
                                parentStrings={props.value.strings}
                                a11yParentStrings={props.value.a11yStrings}
                                onSave={(strings, a11yStrings) => {
                                  let value = props.value[props.editableComponent];
                                  value[props.stepIndex].strings = strings;
                                  value[props.stepIndex].a11yStrings = a11yStrings;
                                  props.onUpdateField([props.editableComponent], value);
                                }}
                                language={props.value.language}
                                country={props.value.country}
                                subdivision={props.value.subdivision}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/:editableComponentMain/:index?/:editableComponent/:indexMain?',
                      element: createElementWithWrapper({
                        serviceName: 'cookies',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'item' }, { title: 'item' }],
                        element: withApiContext(
                          withEditor(
                            withAccess(props => {
                              return (
                                <EditableComponent
                                  {...props}
                                  items={props.value[props.editableComponent] || []}
                                  maxItems={props.getAccessLimit('cookies', 'cookiesVendors')}
                                  language={props.value.language}
                                  wizardCreatesMultipleItems={true}
                                />
                              );
                            })
                          )
                        )
                      })
                    }
                  ]
                },
                {
                  path: 'dpo',
                  children: [
                    {
                      path: '',
                      element: (
                        <ServiceWrapper
                          serviceName={'dpo'}
                          breadCrumb={[...mainProjectsBreadCrumb, { key: 'ServiceName' }]}
                          {...RouterProps}
                        />
                      )
                    },
                    {
                      path: 'strings',
                      element: createElementWithWrapper({
                        serviceName: 'dpo',
                        breadCrumb: [...mainProjectsBreadCrumb, { title: 'DPO' }, { title: t('breadcrumb_text') }],
                        element: withProjectContext(
                          withApiContext(props => {
                            if (!props.isProjectFetched) return null;
                            let state = {
                              value: { ...props.project.DPO } || {}
                            };
                            return (
                              <StringsForm
                                {...props}
                                service="dpo"
                                strings={state.value.strings}
                                config={{ language: locale.locale }}
                                api={props.api}
                                onSave={strings => {
                                  let state = {
                                    value: { ...props.project.DPO } || {}
                                  };
                                  state = update(state, { value: { strings: { $set: strings } } });
                                  const diff = getObjectsDiff({
                                    baseObject: props.project.DPO || {},
                                    newObject: state.value,
                                    path: 'DPO'
                                  });
                                  props.api.patch('projects', props.projectId, diff);
                                }}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':editableComponent/:index?',
                      element: createElementWithWrapper({
                        serviceName: 'dpo',
                        breadCrumb: [...mainProjectsBreadCrumb, { title: 'DPO' }, { title: 'item' }],
                        element: withProjectContext(
                          withApiContext(props => {
                            if (!props.isProjectFetched) return null;
                            let state = {
                              value: { ...props.project.DPO } || {}
                            };
                            let value = {};
                            let items = [];
                            if (props.editableComponent === 'subject-rights') {
                              value = props.stepIndex !== 'new' ? state?.value?.subjectRights[props.stepIndex] : {};
                              items = state.value?.subjectRights;
                            } else if (props.editableComponent === 'embeddings') {
                              value = props.stepIndex !== 'new' ? state?.value?.embeddings[props.stepIndex] : {};
                              items = state.value?.embeddings;
                            }
                            return <EditableComponent {...props} items={items} value={value} />;
                          })
                        )
                      })
                    }
                  ]
                },
                {
                  path: 'portability',
                  children: [
                    {
                      path: '',
                      element: (
                        <ServiceWrapper
                          serviceName={'portability'}
                          breadCrumb={[...mainProjectsBreadCrumb, { key: 'ServiceName' }]}
                          {...RouterProps}
                        />
                      )
                    },
                    {
                      path: ':identifier/strings',
                      element: createElementWithWrapper({
                        serviceName: 'portability',
                        breadCrumb: [...mainProjectsBreadCrumb, { key: 'ConfigName' }, { title: t('breadcrumb_text') }],
                        element: withApiContext(
                          withEditor(props => {
                            return (
                              <StringsForm
                                {...props}
                                inStep
                                service="portability"
                                strings={props.value.strings}
                                config={props.value}
                                api={props.api}
                                onSave={strings => props.onUpdateField('strings', strings)}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/consent?/:editableComponent/:index?',
                      element: createElementWithWrapper({
                        serviceName: 'portability',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'item' }],
                        element: withApiContext(
                          withEditor(props => {
                            let items =
                              props.editableComponent === 'embeddings' ? props.value.embeddings : props.value.processors;
                            let value =
                              props.editableComponent === 'embeddings'
                                ? props.value.embeddings[props.stepIndex] || {}
                                : props.value.processors[props.stepIndex] || {};
                            return <EditableComponent {...props} items={items} value={value} />;
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/consent',
                      element: createElementWithWrapper({
                        serviceName: 'portability',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'Consent' }],
                        element: withApiContext(
                          withEditor(props => {
                            return (
                              <ConsentEditor
                                {...props}
                                languagePack={props.languagePack}
                                api={props.api}
                                project={props.project}
                                value={props.value}
                                onChange={value => {
                                  props.onUpdateSpecs({ $merge: value });
                                }}
                              />
                            );
                          })
                        )
                      })
                    }
                  ]
                },
                {
                  path: 'processings',
                  children: [
                    {
                      path: '',
                      element: (
                        <ServiceWrapper
                          serviceName={'processings'}
                          breadCrumb={[...mainProjectsBreadCrumb, { key: 'ServiceName' }]}
                          {...RouterProps}
                        />
                      )
                    },
                    {
                      path: ':identifier/illustration',
                      element: createElementWithWrapper({
                        serviceName: 'processings',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'Illustration' }],
                        element: withApiContext(
                          withEditor(props => {
                            const navigate = useNavigate();
                            return (
                              <ImageChooser
                                {...props}
                                api={props.api}
                                tag="processings"
                                filterOut="deprecated"
                                value={assets.image('')}
                                onChange={async image => {
                                  props.handleChange({ target: { name: 'picture', value: image } });
                                  setTimeout(() => {
                                    navigate('..', { relative: 'path' });
                                  }, 2000);
                                }}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/:confirmationScreen',
                      element: createElementWithWrapper({
                        serviceName: 'processings',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'item' }],
                        element: withApiContext(
                          withEditor(props => {
                            if (!['accept', 'reject', 'partial'].includes(props.confirmationScreen)) {
                              return <Navigate to=".." relative="path" />;
                            }
                            return (
                              <ProcessingConfirmationEditor
                                {...props}
                                languagePack={props.languagePack}
                                project={props.project}
                                value={props.value.confirmations[props.confirmationScreen]}
                                onChange={(confirmationScreenValue, cb) => {
                                  props.onUpdateSpecs(
                                    {
                                      confirmations: {
                                        [props.confirmationScreen]: {
                                          $set: confirmationScreenValue
                                        }
                                      }
                                    },
                                    cb
                                  );
                                }}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/consent',
                      element: createElementWithWrapper({
                        serviceName: 'processings',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'Consent' }],
                        element: withApiContext(
                          withEditor(props => {
                            return (
                              <ConsentEditor
                                {...props}
                                languagePack={props.languagePack}
                                api={props.api}
                                project={props.project}
                                value={props.value}
                                onChange={value => {
                                  props.onUpdateSpecs({ $merge: value });
                                }}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/consent/:editableComponent/:index?',
                      element: createElementWithWrapper({
                        serviceName: 'processings',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'item' }],
                        element: withApiContext(
                          withEditor(props => {
                            return (
                              <EditableComponent
                                {...props}
                                items={props.value.processors}
                                value={props.value.processors[props.stepIndex] || {}}
                              />
                            );
                          })
                        )
                      })
                    },
                    {
                      path: ':identifier/:editableComponent/:index?',
                      element: createElementWithWrapper({
                        serviceName: 'processings',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'item' }],
                        element: withApiContext(
                          withEditor(
                            withAccess(props => {
                              const suppProps =
                                props.editableComponent === 'processingsButtons'
                                  ? {
                                      actions: ['accept', 'show', 'reject'],
                                      maxItems: props.getAccessLimit('processings', 'processingsButtons'),
                                      newItemDefaultValue: { action: 'accept' }
                                    }
                                  : {};

                              const key = props.editableComponent === 'processingsButtons' ? 'buttons' : props.editableComponent;
                              return (
                                <EditableComponent
                                  {...props}
                                  {...suppProps}
                                  items={props.value[key]}
                                  value={
                                    props.stepIndex === 'new'
                                      ? {}
                                      : props.value[key][props.stepIndex] || suppProps.newItemDefaultValue || {}
                                  }
                                />
                              );
                            })
                          )
                        )
                      })
                    }
                  ]
                },
                {
                  path: 'contracts',
                  children: [
                    {
                      path: '',
                      element: (
                        <ServiceWrapper
                          serviceName={'contracts'}
                          breadCrumb={[...mainProjectsBreadCrumb, { key: 'ServiceName' }]}
                          {...RouterProps}
                        />
                      )
                    },
                    {
                      path: ':identifier/:editableComponent/:index?',
                      element: createElementWithWrapper({
                        serviceName: 'contracts',
                        breadCrumb: [...mainConfigBreadCrumb, { title: 'item' }],
                        element: withApiContext(
                          withEditor(props => {
                            return (
                              <EditableComponent
                                {...props}
                                items={props.value.buttons}
                                value={props.value.buttons[props.stepIndex] || {}}
                                actions={['accept', 'show', 'reject']}
                              />
                            );
                          })
                        )
                      })
                    }
                  ]
                },
                {
                  path: 'contractsV2',
                  children: [
                    {
                      path: '',
                      element: (
                        <ServiceWrapper
                          serviceName={'contractsV2'}
                          breadCrumb={[...mainProjectsBreadCrumb, { key: 'ServiceName' }]}
                          {...RouterProps}
                        />
                      )
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ];

  if (__self.props.api.isCurrentUserCustomerSupport()) {
    routes.push(
      ...[
        {
          path: '/cs-tools',
          children: [
            {
              path: '',
              element: (
                <RouterComponent breadCrumb={[{ title: t('cstools_breadcrumb_title') }]} {...RouterProps}>
                  <CSTools api={__self.props.api} />
                </RouterComponent>
              )
            },
            {
              path: 'audit-logs',
              element: (
                <RouterComponent breadCrumb={[{ title: 'CS Tools' }, { title: 'Audit Logs' }]} {...RouterProps}>
                  <CSToolsListing
                    api={__self.props.api}
                    entity={'audit-logs'}
                    searchPlaceholder={'search by id, email or action. multiple search terms are separated by ;'}
                    toolsFunctions={'audit-logs'}
                  />
                </RouterComponent>
              )
            },
            {
              path: 'users',
              element: (
                <RouterComponent breadCrumb={[{ title: 'CS Tools' }, { title: 'Users' }]} {...RouterProps}>
                  <CSToolsListing
                    api={__self.props.api}
                    entity={'user'}
                    searchPlaceholder={'search users by id or email'}
                    toolsFunctions={'users'}
                  />
                </RouterComponent>
              )
            },
            {
              path: 'projects',
              children: [
                {
                  path: '',
                  element: (
                    <RouterComponent breadCrumb={[{ title: 'CS Tools' }, { title: 'Projects' }]} {...RouterProps}>
                      <CSToolsListing
                        api={__self.props.api}
                        entity={'projects'}
                        searchPlaceholder={'search projects by id, name, key, organizationId, creator email'}
                        toolsFunctions={'projects'}
                      />
                    </RouterComponent>
                  )
                },
                {
                  path: ':projectIdCsTools/services-access',
                  element: (
                    <RouterComponent
                      breadCrumb={[{ title: 'CS Tools' }, { title: 'Projects' }, { title: 'Project Services Access' }]}
                      {...RouterProps}
                    >
                      <ServicesAccess api={__self.props.api} documentType={'project'} />
                    </RouterComponent>
                  )
                }
              ]
            },
            {
              path: 'organizations',
              children: [
                {
                  path: '',
                  element: (
                    <RouterComponent breadCrumb={[{ title: 'CS Tools' }, { title: 'Organizations' }]} {...RouterProps}>
                      <CSToolsListing
                        api={__self.props.api}
                        entity={'organizations'}
                        searchPlaceholder={'search organization by id, name, creator email'}
                        toolsFunctions={'organizations'}
                      />
                    </RouterComponent>
                  )
                },
                {
                  path: ':organizationId/services-access',
                  element: (
                    <RouterComponent
                      breadCrumb={[{ title: 'CS Tools' }, { title: 'Organizations' }, { title: 'Organization Services Access' }]}
                      {...RouterProps}
                    >
                      <ServicesAccess api={__self.props.api} documentType={'organization'} />
                    </RouterComponent>
                  )
                }
              ]
            },
            {
              path: 'automator',
              element: (
                <RouterComponent breadCrumb={[{ title: 'CS Tools' }, { title: 'Automator Jobs' }]} {...RouterProps}>
                  <CSToolsListing
                    api={__self.props.api}
                    entity={'jobs'}
                    searchPlaceholder={'search job by id, domain, creator email'}
                    toolsFunctions={'jobs'}
                  />
                </RouterComponent>
              )
            },
            {
              path: 'feature-flags',
              element: (
                <RouterComponent breadCrumb={[{ title: 'CS Tools' }, { title: 'Feature flags' }]} {...RouterProps}>
                  <FeatureFlags />
                </RouterComponent>
              )
            }
          ]
        }
      ]
    );
  }

  return routes;
};

const AxeptioRouter = (locale, __self, t) => createBrowserRouter(routes(locale, __self, t));
export default AxeptioRouter;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Style = styled.div`
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.15s ease;

  ${props =>
    props.mobileOnly &&
    `
    @media (min-width: 450px) {
      display: none;
    }
  `};

  ${props =>
    props.show &&
    `
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  `};
`;

const Overlay = ({ mobileOnly, show, onClick }) => <Style mobileOnly={mobileOnly} show={show} onClick={onClick} />;

Overlay.propTypes = {
  mobileOnly: PropTypes.bool,
  show: PropTypes.bool,
  onClick: PropTypes.func
};

export default Overlay;

import { getSubscriptionName, onGoingOrIncompleteStripeSubscriptionStatus } from '../../base/billingHelpers';
import { snake2CamelCase } from '../../base/helpers';

export const parseServices = project => {
  const serviceKeys = Object.keys(project.services);
  return serviceKeys?.reduce((allConfigs, serviceName) => {
    const projectServices = project.services[serviceName];
    if (Array.isArray(projectServices) && projectServices.length > 0) {
      projectServices.forEach(service => {
        const config = {
          data: {
            id: service.id,
            project_id: project.id,
            service_name: serviceName,
            ...service.data
          },
          service_name: serviceName
        };
        allConfigs.push(config);
      });
    }
    return allConfigs;
  }, []);
};

export const parsePlan = project => {
  const subscription = [
    project.data?.billing?.subscription,
    project.data?.thirdPartyBilling?.subscription,
    project.data?.organization?.subscription
  ].find(sub => onGoingOrIncompleteStripeSubscriptionStatus.includes(sub?.status));

  const subscriptionName = getSubscriptionName(subscription);

  const subscriptionLabel =
    subscription?.items?.data?.find(item => item?.price.metadata?.label)?.price.metadata?.label ||
    getSubscriptionName(subscription, true);

  return { subscriptionName, subscriptionLabel };
};

export const getProjectConfigName = project => {
  if (project.service_name === 'contracts_v2') {
    return project?.data?.title || project?.config?.title;
  }
  return project?.data?.name;
};

export const getProjectConfigLink = project => {
  const configId = project.id || project._id;
  const projectId = project.projectId || project.data?.projectId || project.id;
  const serviceName = snake2CamelCase(project?.service_name);
  return `/projects/${projectId}/${serviceName}/${configId}`;
};

import React, { Suspense } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as LogoIconPlain } from './svg/logo_icon_plain.svg';
import { ReactComponent as LogoAxeptio } from './svg/logo_axeptio.svg';
import { ReactComponent as LogoIconPlainClose } from './svg/logo_icon_plain_close.svg';
import { ReactComponent as Close } from './svg/close.svg';
import { ReactComponent as Check } from './svg/check.svg';

function LazyImport(filename) {
  if (
    window?.axeptioXslim ||
    // eslint-disable-next-line no-undef
    (typeof AXEPTIO_XSLIM !== 'undefined' && AXEPTIO_XSLIM)
  ) {
    return import(/* webpackChunkName: "[request]-xslim" */ `${filename}`);
  } else if (
    window?.axeptioSlim ||
    // eslint-disable-next-line no-undef
    (typeof AXEPTIO_SLIM !== 'undefined' && AXEPTIO_SLIM)
  ) {
    return import(/* webpackChunkName: "[request]-slim" */ `${filename}`);
  } else if (
    window?.axeptioNext ||
    // eslint-disable-next-line no-undef
    (typeof AXEPTIO_NEXT !== 'undefined' && AXEPTIO_NEXT)
  ) {
    return import(/* webpackChunkName: "[request]-next" */ `${filename}`);
  } else if (
    window?.axeptioPR ||
    // eslint-disable-next-line no-undef
    (typeof AXEPTIO_PR !== 'undefined' && AXEPTIO_PR)
  ) {
    return import(/* webpackChunkName: "[request]-pr" */ `${filename}`);
  } else {
    return import(/* webpackChunkName: "[request]" */ `${filename}`);
  }
}

const Add = React.lazy(() => LazyImport('./component/add'));
const AddOutline2 = React.lazy(() => LazyImport('./component/add_outline_2'));
const ArrowBack = React.lazy(() => LazyImport('./component/arrow_back'));
const ArrowDown2 = React.lazy(() => LazyImport('./component/arrow_down_2'));
const ArrowForward = React.lazy(() => LazyImport('./component/arrow_forward'));
const ArrowForward2 = React.lazy(() => LazyImport('./component/arrow_forward_2'));
const Rollback = React.lazy(() => LazyImport('./component/rollback'));
const ArrowLeft2 = React.lazy(() => LazyImport('./component/arrow_left_2'));
const ArrowRight2 = React.lazy(() => LazyImport('./component/arrow_right_2'));
const ArrowLeft3 = React.lazy(() => LazyImport('./component/arrow_left_3'));
const ArrowRight3 = React.lazy(() => LazyImport('./component/arrow_right_3'));
const ArrowUp2 = React.lazy(() => LazyImport('./component/arrow_up_2'));
const Cards = React.lazy(() => LazyImport('./component/cards'));
const Chip = React.lazy(() => LazyImport('./component/chip'));
const ColumnSort002 = React.lazy(() => LazyImport('./component/column_sort'));
const Cookie002 = React.lazy(() => LazyImport('./component/cookie'));
const Copy002 = React.lazy(() => LazyImport('./component/copy'));
const DoubleArrowLeft002 = React.lazy(() => LazyImport('./component/double_arrow_left'));
const DoubleArrowRight002 = React.lazy(() => LazyImport('./component/double_arrow_right'));
const Download = React.lazy(() => LazyImport('./component/download'));
const DownloadXLS = React.lazy(() => LazyImport('./component/downloadxls'));
const DownloadPDF = React.lazy(() => LazyImport('./component/downloadpdf'));
const ExclamationMark001 = React.lazy(() => LazyImport('./component/exclamation_mark'));
const Info = React.lazy(() => LazyImport('./component/info'));
const InterrogationPoint = React.lazy(() => LazyImport('./component/interrogation_point'));
const List = React.lazy(() => LazyImport('./component/list'));
const Lock3Unlocked = React.lazy(() => LazyImport('./component/lock_3_unlocked'));
const Lock3 = React.lazy(() => LazyImport('./component/lock_3'));
const LogoIcon = React.lazy(() => LazyImport('./component/logo_icon'));
const ManageAccounts001 = React.lazy(() => LazyImport('./component/manage_accounts'));
const Menu = React.lazy(() => LazyImport('./component/menu'));
const Logout = React.lazy(() => LazyImport('./component/logout'));
const Remove2 = React.lazy(() => LazyImport('./component/remove_2'));
const Search = React.lazy(() => LazyImport('./component/search'));
const Support001 = React.lazy(() => LazyImport('./component/support'));
const SupportAgent001 = React.lazy(() => LazyImport('./component/support_agent'));
const Duplicate = React.lazy(() => LazyImport('./component/duplicate'));
const Edit = React.lazy(() => LazyImport('./component/edit'));
const Trash = React.lazy(() => LazyImport('./component/trash'));
const TranslateOutline = React.lazy(() => LazyImport('./component/translate_outline'));
const DuplicateOutline = React.lazy(() => LazyImport('./component/duplicate_outline'));
const EditOutline = React.lazy(() => LazyImport('./component/edit_outline'));
const TrashOutline = React.lazy(() => LazyImport('./component/trash_outline'));
const Settings = React.lazy(() => LazyImport('./component/settings'));
const Visible03 = React.lazy(() => LazyImport('./component/visible'));
const Invisible03 = React.lazy(() => LazyImport('./component/invisible'));
const ThreeDotsHoriz001 = React.lazy(() => LazyImport('./component/three_dots_horiz'));
const ThreeDotsVert001 = React.lazy(() => LazyImport('./component/three_dots_vert'));
const Axeptio = React.lazy(() => LazyImport('./component/axeptio'));
const Warning = React.lazy(() => LazyImport('./component/warning'));
const WarningV2 = React.lazy(() => LazyImport('./component/warningV2'));
const WarningDS = React.lazy(() => LazyImport('./component/warningDS'));
const ButtonStop = React.lazy(() => LazyImport('./component/button_stop'));
const ButtonPlay = React.lazy(() => LazyImport('./component/button_play'));
const ButtonPause = React.lazy(() => LazyImport('./component/button_pause'));
const Notification = React.lazy(() => LazyImport('./component/notification'));
const Cgu = React.lazy(() => LazyImport('./component/cgu'));
const PrivacyPolicy = React.lazy(() => LazyImport('./component/privacy_policy'));
const LegalNotice = React.lazy(() => LazyImport('./component/legal_notice'));
const Dpa = React.lazy(() => LazyImport('./component/dpa'));
const Organization = React.lazy(() => LazyImport('./component/organization'));
const Google = React.lazy(() => LazyImport('./component/google'));
const Iab = React.lazy(() => LazyImport('./component/iab'));
const Clock = React.lazy(() => LazyImport('./component/clock'));
const Dollar = React.lazy(() => LazyImport('./component/dollar'));

const ICONS = {
  google: () => (
    <Suspense fallback={<div></div>}>
      <Google />
    </Suspense>
  ),
  iab: () => (
    <Suspense fallback={<div></div>}>
      <Iab />
    </Suspense>
  ),
  add: () => (
    <Suspense fallback={<div></div>}>
      <Add />
    </Suspense>
  ),
  add_outline_2: () => (
    <Suspense fallback={<div></div>}>
      <AddOutline2 />
    </Suspense>
  ),
  arrow_back: () => (
    <Suspense fallback={<div></div>}>
      <ArrowBack />
    </Suspense>
  ),
  rollback: () => (
    <Suspense fallback={<div></div>}>
      <Rollback />
    </Suspense>
  ),
  arrow_down_2: () => (
    <Suspense fallback={<div></div>}>
      <ArrowDown2 />
    </Suspense>
  ),
  arrow_forward: () => (
    <Suspense fallback={<div></div>}>
      <ArrowForward />
    </Suspense>
  ),
  arrow_forward_2: () => (
    <Suspense fallback={<div></div>}>
      <ArrowForward2 />
    </Suspense>
  ),
  arrow_left_2: () => (
    <Suspense fallback={<div></div>}>
      <ArrowLeft2 />
    </Suspense>
  ),
  arrow_right_2: () => (
    <Suspense fallback={<div></div>}>
      <ArrowRight2 />
    </Suspense>
  ),
  arrow_left_3: () => (
    <Suspense fallback={<div></div>}>
      <ArrowLeft3 />
    </Suspense>
  ),
  arrow_right_3: () => (
    <Suspense fallback={<div></div>}>
      <ArrowRight3 />
    </Suspense>
  ),
  arrow_up_2: () => (
    <Suspense fallback={<div></div>}>
      <ArrowUp2 />
    </Suspense>
  ),
  check: Check,
  cards: () => (
    <Suspense fallback={<div></div>}>
      <Cards />
    </Suspense>
  ),
  chip: () => (
    <Suspense fallback={<div></div>}>
      <Chip />
    </Suspense>
  ),
  close: Close,
  column_sort: () => (
    <Suspense fallback={<div></div>}>
      <ColumnSort002 />
    </Suspense>
  ),
  cookie: () => (
    <Suspense fallback={<div></div>}>
      <Cookie002 />
    </Suspense>
  ),
  copy: () => (
    <Suspense fallback={<div></div>}>
      <Copy002 />
    </Suspense>
  ),
  double_arrow_left: () => (
    <Suspense fallback={<div></div>}>
      <DoubleArrowLeft002 />
    </Suspense>
  ),
  double_arrow_right: () => (
    <Suspense fallback={<div></div>}>
      <DoubleArrowRight002 />
    </Suspense>
  ),
  download: () => (
    <Suspense fallback={<div></div>}>
      <Download />
    </Suspense>
  ),
  download_xls: () => (
    <Suspense fallback={<div></div>}>
      <DownloadXLS />
    </Suspense>
  ),
  download_pdf: () => (
    <Suspense fallback={<div></div>}>
      <DownloadPDF />
    </Suspense>
  ),
  exclamation_mark: () => (
    <Suspense fallback={<div></div>}>
      <ExclamationMark001 />
    </Suspense>
  ),
  info: () => (
    <Suspense fallback={<div></div>}>
      <Info />
    </Suspense>
  ),
  interrogation_point: () => (
    <Suspense fallback={<div></div>}>
      <InterrogationPoint />
    </Suspense>
  ),
  list: () => (
    <Suspense fallback={<div></div>}>
      <List />
    </Suspense>
  ),
  lock_3_unlocked: () => (
    <Suspense fallback={<div></div>}>
      <Lock3Unlocked />
    </Suspense>
  ),
  lock_3: () => (
    <Suspense fallback={<div></div>}>
      <Lock3 />
    </Suspense>
  ),
  logo_axeptio: LogoAxeptio,
  logo_icon_plain: LogoIconPlain,
  logo_icon_plain_close: LogoIconPlainClose,
  logo_icon: () => (
    <Suspense fallback={<div></div>}>
      <LogoIcon />
    </Suspense>
  ),
  manage_accounts: () => (
    <Suspense fallback={<div></div>}>
      <ManageAccounts001 />
    </Suspense>
  ),
  menu: () => (
    <Suspense fallback={<div></div>}>
      <Menu />
    </Suspense>
  ),
  logout: () => (
    <Suspense fallback={<div></div>}>
      <Logout />
    </Suspense>
  ),
  remove_2: () => (
    <Suspense fallback={<div></div>}>
      <Remove2 />
    </Suspense>
  ),
  search: () => (
    <Suspense fallback={<div></div>}>
      <Search />
    </Suspense>
  ),
  support: () => (
    <Suspense fallback={<div></div>}>
      <Support001 />
    </Suspense>
  ),
  support_agent: () => (
    <Suspense fallback={<div></div>}>
      <SupportAgent001 />
    </Suspense>
  ),
  duplicate: () => (
    <Suspense fallback={<div></div>}>
      <Duplicate />
    </Suspense>
  ),
  edit: () => (
    <Suspense fallback={<div></div>}>
      <Edit />
    </Suspense>
  ),
  trash: () => (
    <Suspense fallback={<div></div>}>
      <Trash />
    </Suspense>
  ),
  translate_outline: () => (
    <Suspense fallback={<div></div>}>
      <TranslateOutline />
    </Suspense>
  ),
  duplicate_outline: () => (
    <Suspense fallback={<div></div>}>
      <DuplicateOutline />
    </Suspense>
  ),
  edit_outline: () => (
    <Suspense fallback={<div></div>}>
      <EditOutline />
    </Suspense>
  ),
  trash_outline: () => (
    <Suspense fallback={<div></div>}>
      <TrashOutline />
    </Suspense>
  ),
  settings: () => (
    <Suspense fallback={<div></div>}>
      <Settings />
    </Suspense>
  ),
  organization: () => (
    <Suspense fallback={<div></div>}>
      <Organization />
    </Suspense>
  ),
  visible: () => (
    <Suspense fallback={<div></div>}>
      <Visible03 />
    </Suspense>
  ),
  invisible: () => (
    <Suspense fallback={<div></div>}>
      <Invisible03 />
    </Suspense>
  ),
  three_dots_horiz: () => (
    <Suspense fallback={<div></div>}>
      <ThreeDotsHoriz001 />
    </Suspense>
  ),
  three_dots_vert: () => (
    <Suspense fallback={<div></div>}>
      <ThreeDotsVert001 />
    </Suspense>
  ),
  axeptio: () => (
    <Suspense fallback={<div></div>}>
      <Axeptio />
    </Suspense>
  ),
  warning: () => (
    <Suspense fallback={<div></div>}>
      <Warning />
    </Suspense>
  ),
  warningV2: () => (
    <Suspense fallback={<div />}>
      <WarningV2 />
    </Suspense>
  ),
  warningDS: () => (
    <Suspense fallback={<div />}>
      <WarningDS />
    </Suspense>
  ),
  button_play: () => (
    <Suspense fallback={<div></div>}>
      <ButtonPlay />
    </Suspense>
  ),
  button_stop: () => (
    <Suspense fallback={<div></div>}>
      <ButtonStop />
    </Suspense>
  ),
  button_pause: () => (
    <Suspense fallback={<div />}>
      <ButtonPause />
    </Suspense>
  ),
  notification: () => (
    <Suspense fallback={<div></div>}>
      <Notification />
    </Suspense>
  ),
  cgu: () => (
    <Suspense fallback={<div></div>}>
      <Cgu />
    </Suspense>
  ),
  privacy_policy: () => (
    <Suspense fallback={<div></div>}>
      <PrivacyPolicy />
    </Suspense>
  ),
  legal_notice: () => (
    <Suspense fallback={<div></div>}>
      <LegalNotice />
    </Suspense>
  ),
  dpa: () => (
    <Suspense fallback={<div></div>}>
      <Dpa />
    </Suspense>
  ),
  clock: () => (
    <Suspense fallback={<div></div>}>
      <Clock />
    </Suspense>
  ),
  dollar: () => (
    <Suspense fallback={<div></div>}>
      <Dollar />
    </Suspense>
  )
};

const DEFAULT_SIZE = 48;

const IconStyleContainer = styled.span.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  display: flex;
`;

const IconStyle = styled.span.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  position: relative;
  width: ${props => props.mediaSize || DEFAULT_SIZE}px;
  height: ${props => props.mediaSize || DEFAULT_SIZE}px;
  display: inline-flex;

  svg {
    z-index: 0;
    position: relative;
    width: ${props => props.mediaSize || DEFAULT_SIZE}px;
    height: ${props => props.mediaSize || DEFAULT_SIZE}px;
    max-width: none;
    max-height: none;
  }

  &&& svg {
    line,
    polyline {
      stroke: ${props => props.color};
      transition: 0.15s ease;
    }

    rect,
    path,
    circle,
    polygon {
      fill: ${props => props.color};
      transition: 0.15s ease;
    }
  }
`;

const Icon = ({ name, size, color, className, ariaHidden, ariaLabel, role, dataFeature }) => {
  const SvgIcon = ICONS[name];
  let iconClassName = 'svgr';

  if (className) {
    iconClassName += ` ${className}`;
  }
  return (
    <IconStyleContainer dataFeature={dataFeature}>
      <IconStyle mediaSize={size ? size : DEFAULT_SIZE} color={color} className={iconClassName} dataFeature={dataFeature}>
        {ariaHidden ? (
          <SvgIcon aria-hidden="true" focusable="false" aria-label={ariaLabel} role={role} />
        ) : (
          <SvgIcon aria-label={ariaLabel} role={role} />
        )}
      </IconStyle>
    </IconStyleContainer>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  ariaHidden: PropTypes.bool,
  ariaLabel: PropTypes.string,
  role: PropTypes.string
};

export { ICONS, IconStyle };
export default Icon;

const portabilityTemplates = [
  {
    name: 'gdpr',
    label: 'GDPR Data portability',
    description: 'Use GDPR as a positive marketing argument.',
    icon: 'icon_v2_portabilité',
    title: 'Personal data portability',
    message: 'We work with partners to whom we can share some of your data, we sort them out and we can assure you that they respect the best standards of protection and security. For you, it means more relevant offers tailored to your needs, without having to be recognized.',
    language: 'en'
  },
  {
    name: 'friendly',
    label: 'Friendly and fun',
    description: 'Use humanness and friendliness to create empathy',
    icon: 'icon_v2_nice_talk',
    title: 'Like you, we have friends!',
    message: 'In order to offer you ever more interesting deals, we have established partnerships with companies that share our values and our tastes for the chosen marketing, and not undergone!',
    language: 'en'
  }
];

export default portabilityTemplates;
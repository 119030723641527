import React, { Fragment } from 'react';
import Card, { CardIconButton } from '../Card/Card';
import { arrayMove } from 'react-sortable-hoc';
import { SortableCard, SortableCardList } from '../Card/SortableCard';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EditableList(props) {
  const {
    url,
    items,
    onChange,
    render,
    cardTitle,
    cardDescription,
    cardImage,
    cardPaint,
    cardActive,
    cardProps,
    newItemTitle,
    newItemDescription,
    slim,
    extraslim,
    itemURL
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Link to={url + '/new'} relative="path">
        <Card
          title={newItemTitle || t('editable_list_new_item_title')}
          dataFeature={`add_new_${url}`}
          description={newItemDescription || t('editable_list_new_item_description')}
          button={<CardIconButton primary icon="add" />}
        />
      </Link>

      <SortableCardList onSortEnd={({ oldIndex, newIndex }) => onChange(arrayMove(items, oldIndex, newIndex))}>
        {Array.isArray(items) &&
          items.map((item, index) => (
            <SortableCard
              key={`partner-${index}`}
              index={index}
              title={cardTitle ? cardTitle(item) : ''}
              description={cardDescription ? cardDescription(item) : ''}
              image={cardImage ? cardImage(item) : null}
              paint={cardPaint || ''}
              active={cardActive ? cardActive(item) : true}
              button={url ? <CardIconButton /> : null}
              {...(cardProps ? cardProps(item, index) : {})}
              slim={slim || null}
              extraslim={extraslim || null}
              onClick={() => {
                let stepIndex = index;
                if (itemURL) {
                  stepIndex = itemURL(item);
                }
                navigate(url + '/' + stepIndex, { relative: 'path' });
              }}
            >
              {render ? render(item, props) : null}
            </SortableCard>
          ))}
      </SortableCardList>
    </Fragment>
  );
}

export default EditableList;

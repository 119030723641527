/* Based on https://github.com/radzionc/reactkit/blob/f2c1e9bc0e115af1687c284ed817cd154ab30fae/lib/ui/Tooltip.tsx#L1-L106 */

import { useRef, useState } from 'react';
import {
  offset,
  shift,
  flip,
  size,
  useFloating,
  autoUpdate,
  useInteractions,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  arrow,
  FloatingArrow,
  useTransitionStyles
} from '@floating-ui/react';
import styled, { css } from 'styled-components';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';

export const baseContainerStyle = css`
  padding: 4px 12px;
  font-family: ${FONTS.PRIMARY};

  font-size: 13px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.GRAY_900};
  border: 1px solid ${COLORS.BLACK};
  line-height: 1.5;
  text-align: center;
  border-radius: 4px;
  white-space: normal;
  min-width: 150px;
  max-width: 400px;
`;

const Container = styled.div`
  ${baseContainerStyle}

  ${props =>
    props.customtheme === 'light' &&
    css`
      background-color: white;
      color: #212121;
      border-color: white;
    `}
`;

const Arrow = styled(FloatingArrow)`
  fill: ${COLORS.GRAY_900};

  ${props =>
    props.customtheme === 'light' &&
    css`
      fill: white;
    `}
`;

export const FloatingTooltip = props => {
  const { content, renderOpener, placement, display, customTheme } = props;
  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);

  const {
    refs: { setReference, setFloating },
    floatingStyles,
    context
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement || 'top',
    middleware: [
      offset(12),
      flip(),
      shift(),
      size({
        apply({ elements }) {
          if (props.maxWidth) {
            elements.floating.style.maxWidth = `${props.maxWidth}px`;
          }
        }
      }),
      arrow({
        element: arrowRef
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'scale(0.8)'
    }
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  const renderOpenerParams = { ref: setReference, ...getReferenceProps() };
  if (display) {
    renderOpenerParams.style = { display };
  }

  return (
    <>
      {renderOpener(renderOpenerParams)}
      {isOpen && content && (
        <div ref={setFloating} style={{ ...floatingStyles, zIndex: 10000 }} {...getFloatingProps()}>
          <Container style={transitionStyles} customtheme={customTheme}>
            <Arrow
              tipRadius={2}
              height={8}
              ref={arrowRef}
              context={context}
              customtheme={customTheme}
              staticOffset={placement === 'right-start' ? '15%' : null}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
          </Container>
        </div>
      )}
    </>
  );
};

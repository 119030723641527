const dpoRights = [
  {
    name: 'access',
    label: 'Right of access (Art. 15)'
  },
  {
    name: 'rectification',
    label: 'Right to rectification (Art. 16)'
  },
  {
    name: 'erasure',
    label: 'Right to erasure (also known as: right to be forgotten) (Art. 17)'
  },
  {
    name: 'restriction',
    label: 'Right to restrict processing (Art. 18)'
  },
  {
    name: 'dataportability',
    label: 'Right to data portability (Art. 20)'
  },
  {
    name: 'object',
    label: 'Right to object to processing (Art. 21)'
  }
];

export default dpoRights;
import { Component } from 'react';
import ReactDOM from 'react-dom';

class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    if (this.props.isModal) {
      this.el.classList.add('modal');
    }
    this.modalRoot = document.getElementById('modal_root');
  }

  componentDidMount() {
    this.modalRoot?.appendChild(this.el);
  }
  componentWillUnmount() {
    this.modalRoot?.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Portal;

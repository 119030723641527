const FONTS = {
  PRIMARY: '"Rubik", sans-serif',
  SECONDARY: '"Montserrat", sans-serif',
  TITLE: '"Rubik", sans-serif',
  CODE: '"Courier New", sans-serif',
  SOURCE: '"Source Sans Pro", sans-serif'
};

const TITLE_SIZES = {
  H1: '30px',
  H2: '24px',
  H3: '20px',
  H4: '18px',
  H5: '16px',
  H6: '14px'
};

export { TITLE_SIZES };
export default FONTS;

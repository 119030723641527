const localStorageKey = 'featureFlags';

export const featureFlags = {
  projectsOnboardingModal: 'Enable onboarding modal',
  comoV2OptionalParameters: 'Enable the switch for optional parameters in Como V2 for Brands'
};

export function getFeatureFlags() {
  try {
    const featureFlags = JSON.parse(localStorage.getItem(localStorageKey));
    return typeof featureFlags === 'object' && featureFlags !== null ? featureFlags : {};
  } catch (error) {
    return {};
  }
}

export function featureFlagIsEnabled(featureFlag) {
  const featureFlags = getFeatureFlags();
  return featureFlags[featureFlag] ?? false;
}

export function setFeatureFlag(featureFlag, enabled) {
  const featureFlags = getFeatureFlags();
  featureFlags[featureFlag] = enabled;
  localStorage.setItem(localStorageKey, JSON.stringify(featureFlags));
}

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonsToolbar } from '../Button/Button';
import { InputGroup } from '../Input/Input';
import RadioCheckbox from '../RadioCheckbox/RadioCheckbox';
import { onGoingStripeSubscriptionStatus } from '../../base/billingHelpers';
import WarningModal from './WarningModal';
import { useTranslation } from 'react-i18next';

const DontShowAgainCheckboxStyle = styled.div`
  margin-right: 10px;
  padding-top: 10px;
`;

function WarningModalFreeTrialLimit(props) {
  const { t } = useTranslation();
  const { onClose, project, projectOrganization, isOpen, api, onConfirm } = props;
  const [isUserPartOfPaidOrganization, setUserPartOfOfPaidOrganization] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const checkIfUserIsPartOfPaidOrganization = async () => {
    if (!project) return;
    setUserPartOfOfPaidOrganization(await api.isCurrentUserFromAPaidOrganization());
  };

  useEffect(() => {
    checkIfUserIsPartOfPaidOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * we can't change plan if :
   * - project is part of an organization with an ongoing subscription
   * - project has a third party billing with an ongoing subscription
   * - if the modal is displayed outside of a project context (for instance in projects listing), we just change the contact text/link
   */
  let allowToChangePlan = false;
  if (project) {
    allowToChangePlan = !(
      (projectOrganization && onGoingStripeSubscriptionStatus.includes(projectOrganization.subscription?.status)) ||
      onGoingStripeSubscriptionStatus.includes(project.thirdPartyBilling?.subscription?.status)
    );
  } else {
    allowToChangePlan = !isUserPartOfPaidOrganization;
  }

  const contactText = allowToChangePlan
    ? t('projects_freetrial_limit_reached_book_demo')
    : t('projects_freetrial_limit_reached_contact_cs');

  const contactLink = allowToChangePlan ? `https://axept.io/demo` : 'mailto:wecare@axeptio.eu';

  const modalContent = {
    title: t('projects_freetrial_limit_reached_title'),
    description: t('projects_freetrial_limit_reached_description')
  };

  return (
    <WarningModal isOpen={isOpen} onClose={onClose} medium modalContent={modalContent}>
      {project ? (
        <ButtonsToolbar>
          {allowToChangePlan ? (
            <Button
              small
              black
              onClick={e => {
                e.preventDefault();
                window.location.href = `/projects/${project._id}/config/subscription`;
              }}
            >
              {t('projects_freetrial_limit_reached_choose_plan')}
            </Button>
          ) : null}
          <Button
            className="blackFont"
            primary
            small
            onClick={e => {
              e.preventDefault();
              window.open(contactLink, '_blank');
            }}
          >
            {contactText}
          </Button>
        </ButtonsToolbar>
      ) : (
        <InputGroup horizontal slim>
          <DontShowAgainCheckboxStyle>
            <RadioCheckbox
              key={'FreeTrialWarningDontShowAgain'}
              type="checkbox"
              name={'FreeTrialWarningDontShowAgain'}
              checked={dontShowAgain}
              onChange={e => {
                if (e.target.checked) {
                  window.localStorage.setItem('FreeTrialWarningDontShowAgain', 'true');
                } else {
                  window.localStorage.removeItem('FreeTrialWarningDontShowAgain');
                }
                setDontShowAgain(e.target.checked);
              }}
              noMargin
              uppercaseLabel={false}
              label={t('projects_freetrial_limit_dont_show_again')}
            />
          </DontShowAgainCheckboxStyle>
          <Button
            className="blackFont"
            small
            primary
            onClick={e => {
              e.preventDefault();
              onConfirm();
            }}
          >
            {t('projects_freetrial_limit_reached_understand')}
          </Button>
        </InputGroup>
      )}
    </WarningModal>
  );
}
export default WarningModalFreeTrialLimit;

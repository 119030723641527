const countries = [
  {
    name: 'FRA',
    title: 'France',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'USA',
    title: 'États-Unis',
    isEu: false,
    hasAdequacyDecision: false
  },
  {
    name: 'unknown',
    title: 'Inconnu',
    isEu: false,
    hasAdequacyDecision: false
  },
  {
    name: 'other',
    title: 'Autre',
    isEu: false,
    hasAdequacyDecision: false
  },
  {
    name: 'DEU',
    title: 'Allemagne',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'AUT',
    title: 'Autriche',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'BEL',
    title: 'Belgique',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'BGR',
    title: 'Bulgary',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'CYP',
    title: 'Chypre',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'HRV',
    title: 'Croatie',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'DNK',
    title: 'Danemark',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'ESP',
    title: 'Espagne',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'EST',
    title: 'Estonie',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'FIN',
    title: 'Finlande',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'GRC',
    title: 'Grèce',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'HUN',
    title: 'Hongrie',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'IRL',
    title: 'Irlande',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'ITA',
    title: 'Italie',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'LVA',
    title: 'Lettonie',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'LTY',
    title: 'Lituanie',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'LUX',
    title: 'Luxembourg',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'MLT',
    title: 'Malte',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'NLD',
    title: 'Pays-Bas',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'POL',
    title: 'Pologne',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'PRT',
    title: 'Portugal',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'CZE',
    title: 'République Tchèque',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'ROU',
    title: 'Roumanie',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'GBR',
    title: 'Royaume-Uni',
    isEu: true,
    hasAdequacyDecision: true
  },
  {
    name: 'SVK',
    title: 'Slovaquie',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'SVN',
    title: 'Slovénie',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'SWE',
    title: 'Suède',
    isEu: true,
    hasAdequacyDecision: false
  },
  {
    name: 'ARG',
    title: 'Argentine',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'CAN',
    title: 'Canada',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'GGY',
    title: 'Guernesey',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'IMN',
    title: 'Île de Man',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'JEY',
    title: 'Jersey',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'CHE',
    title: 'Suisse',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'JAP',
    title: 'Japon',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'AND',
    title: 'Andorre',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'FRO',
    title: 'Îles Féroé',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'ISR',
    title: 'Israël',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'NZL',
    title: 'Nouvelle-Zélande',
    isEu: false,
    hasAdequacyDecision: true
  },
  {
    name: 'URY',
    title: 'Uruguay',
    isEu: false,
    hasAdequacyDecision: true
  }
];

export default countries;
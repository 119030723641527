import styled from 'styled-components';
import media from 'styled-media-query';

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 30px;

  ${media.greaterThan('medium')`
    ${props =>
      !props.fluid &&
      `
      padding: 0;
      max-width: 768px;
    `};
  `}

  ${media.greaterThan('large')`
    ${props =>
      !props.fluid &&
      `
      padding: 0;
      max-width: 1170px;
    `};
  `}

  ${media.greaterThan('huge')`
    padding: 0;
    max-width: 1380px;
  `}


  ${props =>
    props.maxWidth === 'medium' &&
    `
    max-width: 768px !important;
  `};

  ${props =>
    props.maxWidth === 'large900' &&
    media.greaterThan('large')`
    max-width: 900px !important;
  `};

  ${props =>
    props.maxWidth === 'large' &&
    `
    max-width: 1170px !important;
  `};

  ${props =>
    props.maxWidth === 'normal' &&
    `
    max-width: 1440px !important;
  `};

  ${props =>
    props.maxWidth === 'huge' &&
    `
    max-width: 1920px !important;
  `};
`;

export default Container;

const contractsTemplates = [
  {
    'aNonDisclosureAgreementIsAContractBetweenTwoOrganizationsThat"': 'tos',
    label: 'Terms of service',
    description: 'The terms of service are a contract that surrounds and defines the use of a service made available by a company to an individual. The main aim of this document is to limit the service provider\'s liability should the user "misuse the service."',
    icon: 'contracts_cgu',
    language: 'en'
  },
  {
    'aNonDisclosureAgreementIsAContractBetweenTwoOrganizationsThat"': 'termsOfSale',
    label: 'Terms of sales',
    description: 'The terms of sale are a text provided by a provider to its customer on the legal sales terms and conditions of its products or services, in the absence of a specific agreement. Even without express agreement, the purchase of goods or services from this provider constitutesthe buyer\'s implicit acceptance of these terms.',
    icon: 'contracts_cgv',
    language: 'en'
  },
  {
    'aNonDisclosureAgreementIsAContractBetweenTwoOrganizationsThat"': 'nda',
    label: 'Non-disclosure agreement',
    description: 'A non-disclosure agreement is a contract between two organizations that obliges one of the parties to keep the information communicated bythe other confidential',
    icon: 'contracts_nda',
    language: 'en'
  },
  {
    'aNonDisclosureAgreementIsAContractBetweenTwoOrganizationsThat"': 'eula',
    label: 'End-user licence agreement',
    description: 'This is a contract between a person who installs software affected by this kind of license on a computer and the software publisher. Most of them, also known as proprietary software licenses, limit the number of machines on which you can install the software and the number of users who can use the software, and contain other limitations that are not inherent to the technology.',
    icon: 'contracts_clu',
    language: 'en'
  }
];

export default contractsTemplates;
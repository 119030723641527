import styled from 'styled-components';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';

const ButtonText = styled.button`
  outline: none;
  border: none;
  user-select: none;
  text-decoration: none;
  background: none;
  padding: 0;
  -webkit-appearance: none;
  font-family: ${FONTS.PRIMARY};
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.GRAY_600};
  cursor: pointer;

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `};

  ${props =>
    !props.disabled &&
    `
    &:hover {
      color: ${COLORS.GRAY};
    }
  `};

  ${props =>
    props.primary &&
    `
    color: ${COLORS.YELLOW};
  `};

  ${props =>
    !props.disabled &&
    props.primary &&
    `
    &:hover {
      color: ${COLORS.YELLOW_200_D};
    }
  `};
`;

export default ButtonText;

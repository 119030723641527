import styled from 'styled-components';
import COLORS from '../../constants/colors';

const MiniPellet = styled.span`
  position: relative;
  display: inline-flex;
  padding: 4px 3px 3px;
  margin-top: 1px;
  font-size: 8px;
  font-weight: 400;
  font-family: ${props => props.fontFamily || 'inherit'};
  line-height: 1;
  letter-spacing: 0.125em;
  border-radius: 3px;
  color: ${COLORS.GRAY_700};
  background: ${COLORS.GRAY_100};
`;

export default MiniPellet;

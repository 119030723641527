const paintColors = [
  {
    hex: '#4a148c',
    hue: 285,
    sat: -12,
    bri: -28
  },
  {
    hex: '#311b92',
    hue: 281,
    sat: -12,
    bri: -26
  },
  {
    hex: '#1a237e',
    hue: 258,
    sat: -55,
    bri: -7
  },
  {
    hex: '#303f9f',
    hue: 245,
    sat: -46,
    bri: -1
  },
  {
    hex: '#512da8',
    hue: 267,
    sat: -46,
    bri: 1
  },
  {
    hex: '#673ab7',
    hue: 272,
    sat: -39,
    bri: 6
  },
  {
    hex: '#9575cd',
    hue: 272,
    sat: -59,
    bri: 26
  },
  {
    hex: '#d1c4e9',
    hue: 272,
    sat: -79,
    bri: 52
  },
  {
    hex: '#c5cae9',
    hue: 243,
    sat: -84,
    bri: 55
  },
  {
    hex: '#7986cb',
    hue: 243,
    sat: -64,
    bri: 28
  },
  {
    hex: '#3f51b5',
    hue: 243,
    sat: -46,
    bri: 8
  },
  {
    hex: '#7b1fa2',
    hue: 286,
    sat: -46,
    bri: 2
  },
  {
    hex: '#9c27b0',
    hue: 294,
    sat: -30,
    bri: 3
  },
  {
    hex: '#ba68c8',
    hue: 294,
    sat: -52,
    bri: 25
  },
  {
    hex: '#e1bee7',
    hue: 294,
    sat: -79,
    bri: 53
  },
  {
    hex: '#880e4f',
    hue: 314,
    sat: -12,
    bri: -28
  },
  {
    hex: '#e91e63',
    hue: 332,
    sat: 5,
    bri: -9
  },
  {
    hex: '#c2185b',
    hue: 333,
    sat: -29,
    bri: -1
  },
  {
    hex: '#f06292',
    hue: 340,
    sat: -44,
    bri: 27
  },
  {
    hex: '#f8bbd0',
    hue: 340,
    sat: -75,
    bri: 55
  },
  {
    hex: '#b71c1c',
    hue: 21,
    sat: -6,
    bri: -12
  },
  {
    hex: '#d32f2f',
    hue: 352,
    sat: -30,
    bri: 6
  },
  {
    hex: '#f44336',
    hue: 359,
    sat: -18,
    bri: 15
  },
  {
    hex: '#e57373',
    hue: 359,
    sat: -51,
    bri: 30
  },
  {
    hex: '#ffcdd2',
    hue: 358,
    sat: -74,
    bri: 61
  },
  {
    hex: '#0d47a1',
    hue: 225,
    sat: -35,
    bri: -3
  },
  {
    hex: '#1976d2',
    hue: 225,
    sat: -27,
    bri: 14
  },
  {
    hex: '#2196f3',
    hue: 205,
    sat: 18,
    bri: 19
  },
  {
    hex: '#64b5f6',
    hue: 205,
    sat: -20,
    bri: 34
  },
  {
    hex: '#bbdefb',
    hue: 205,
    sat: -63,
    bri: 53
  },
  {
    hex: '#b3e5fc',
    hue: 199,
    sat: -50,
    bri: 53
  },
  {
    hex: '#4fc3f7',
    hue: 198,
    sat: -5,
    bri: 35
  },
  {
    hex: '#03a9f4',
    hue: 198,
    sat: 31,
    bri: 23
  },
  {
    hex: '#0288d1',
    hue: 198,
    sat: 28,
    bri: 11
  },
  {
    hex: '#01579b',
    hue: 198,
    sat: 18,
    bri: -7
  },
  {
    hex: '#006064',
    hue: 182,
    sat: 18,
    bri: -7
  },
  {
    hex: '#0097a7',
    hue: 185,
    sat: 28,
    bri: 13
  },
  {
    hex: '#00bcd4',
    hue: 187,
    sat: 49,
    bri: 25
  },
  {
    hex: '#b2ebf2',
    hue: 185,
    sat: -47,
    bri: 53
  },
  {
    hex: '#b2dfdb',
    hue: 179,
    sat: -59,
    bri: 49
  },
  {
    hex: '#c8e6c9',
    hue: 152,
    sat: -72,
    bri: 52
  },
  {
    hex: '#4db6ac',
    hue: 179,
    sat: -4,
    bri: 27
  },
  {
    hex: '#81c784',
    hue: 152,
    sat: -36,
    bri: 35
  },
  {
    hex: '#4caf50',
    hue: 152,
    sat: -4,
    bri: 22
  },
  {
    hex: '#388e3c',
    hue: 152,
    sat: -27,
    bri: 12
  },
  {
    hex: '#194d33',
    hue: 151,
    sat: -70,
    bri: -8
  },
  {
    hex: '#33691e',
    hue: 151,
    sat: -29,
    bri: -2
  },
  {
    hex: '#689f38',
    hue: 86,
    sat: 11,
    bri: 26
  },
  {
    hex: '#8bc34a',
    hue: 83,
    sat: 26,
    bri: 41
  },
  {
    hex: '#aed581',
    hue: 83,
    sat: -2,
    bri: 49
  },
  {
    hex: '#dcedc8',
    hue: 83,
    sat: -58,
    bri: 63
  },
  {
    hex: '#f0f4c3',
    hue: 72,
    sat: -32,
    bri: 65
  },
  {
    hex: '#dce775',
    hue: 75,
    sat: 49,
    bri: 59
  },
  {
    hex: '#cddc39',
    hue: 75,
    sat: 38,
    bri: 53
  },
  {
    hex: '#afb42b',
    hue: 73,
    sat: 38,
    bri: 39
  },
  {
    hex: '#827717',
    hue: 71,
    sat: 15,
    bri: 18
  },
  {
    hex: '#f57f17',
    hue: 31,
    sat: 95,
    bri: 45
  },
  {
    hex: '#fbc02d',
    hue: 41,
    sat: 100,
    bri: 64
  },
  {
    hex: '#ffeb3b',
    hue: 48,
    sat: 100,
    bri: 76
  },
  {
    hex: '#fff176',
    hue: 48,
    sat: 100,
    bri: 80
  },
  {
    hex: '#fff9c4',
    hue: 48,
    sat: 19,
    bri: 80
  },
  {
    hex: '#ffecb3',
    hue: 40,
    sat: 9,
    bri: 76
  },
  {
    hex: '#ffd54f',
    hue: 49,
    sat: 100,
    bri: 67
  },
  {
    hex: '#ffc107',
    hue: 49,
    sat: 100,
    bri: 60
  },
  {
    hex: '#ffa000',
    hue: 49,
    sat: 100,
    bri: 48
  },
  {
    hex: '#ff6f00',
    hue: 49,
    sat: 100,
    bri: 31
  },
  {
    hex: '#e65100',
    hue: 52,
    sat: 83,
    bri: 12
  },
  {
    hex: '#f57c00',
    hue: 52,
    sat: 83,
    bri: 28
  },
  {
    hex: '#ff9800',
    hue: 52,
    sat: 83,
    bri: 42
  },
  {
    hex: '#ffb74d',
    hue: 52,
    sat: 83,
    bri: 53
  },
  {
    hex: '#ffe0b2',
    hue: 48,
    sat: -20,
    bri: 65
  },
  {
    hex: '#bf360c',
    hue: 8,
    sat: -32,
    bri: 4
  },
  {
    hex: '#e64a19',
    hue: 23,
    sat: -22,
    bri: 15
  },
  {
    hex: '#ff5722',
    hue: 23,
    sat: 24,
    bri: 28
  },
  {
    hex: '#ff8a65',
    hue: 23,
    sat: 24,
    bri: 45
  },
  {
    hex: '#ffccbc',
    hue: 23,
    sat: -24,
    bri: 65
  },
  {
    hex: '#d7ccc8',
    hue: 23,
    sat: -92,
    bri: 54
  },
  {
    hex: '#a1887f',
    hue: 14,
    sat: -89,
    bri: 30
  },
  {
    hex: '#795548',
    hue: 14,
    sat: -76,
    bri: 10
  },
  {
    hex: '#5d4037',
    hue: 14,
    sat: -86,
    bri: 1
  },
  {
    hex: '#3e2723',
    hue: 14,
    sat: -83,
    bri: -9
  },
  {
    hex: '#263238',
    hue: 283,
    sat: -92,
    bri: -9
  },
  {
    hex: '#455a64',
    hue: 235,
    sat: -92,
    bri: 10
  },
  {
    hex: '#607d8b',
    hue: 205,
    sat: -83,
    bri: 15
  },
  {
    hex: '#90a4ae',
    hue: 205,
    sat: -89,
    bri: 31
  },
  {
    hex: '#cfd8dc',
    hue: 205,
    sat: -93,
    bri: 52
  },
  {
    hex: '#ffffff',
    hue: 205,
    sat: -100,
    bri: 100
  },
  {
    hex: '#d9d9d9',
    hue: 205,
    sat: -100,
    bri: 53
  },
  {
    hex: '#969696',
    hue: 205,
    sat: -100,
    bri: 27
  },
  {
    hex: '#525252',
    hue: 205,
    sat: -100,
    bri: 3
  },
  {
    hex: '#000000',
    hue: 205,
    sat: -100,
    bri: -18
  }
];

export default paintColors;
const protectionMechanisms = [
  {
    name: 'contractual_clause_standard_protection',
    title: 'Clauses contractuelles de protection types'
  },
  {
    name: 'contractual_clause_specific_protection',
    title: 'Clauses contractuelles de protection spécifiques'
  },
  {
    name: 'bcr',
    title: 'Règles d’entreprises contraignantes (ou Binding Corporate Rules : BCR)'
  },
  {
    name: 'approved_code_of_conduct',
    title: 'Un code de conduite approuvé'
  },
  {
    name: 'approved_certification_mechanism',
    title: 'Un mécanisme de certification approuvé'
  },
  {
    name: 'other_protective_measures',
    title: 'D’autres mesures de protection'
  },
  {
    name: 'none',
    title: 'Aucune mesure n’a été prise'
  }
];

export default protectionMechanisms;
import languages from '@cospired/i18n-iso-languages';
import Localization from '@axeptio/widget-client/src/services/Localization.js';

class Templates {
  /**
   * @param {Api} api
   */
  constructor(api) {
    this.api = api;
  }

  async getCookieTemplatesSummary(language) {
    const response = await this.api.client.get(`/templates/cookie/summary?language=${language}`);
    return response.data;
  }

  async getCookieTemplate(name, language, country, subdivision) {
    const response = await this.api.client.get(
      `/templates/cookie/compiled/${name}?language=${language}&country=${country}&subdivision=${subdivision}`
    );
    return response.data;
  }

  async getDefaultCookieTemplate(language, country, subdivision) {
    const response = await this.api.client.get(
      `/templates/cookie/compiled/default?language=${language}&country=${country}&subdivision=${subdivision}`
    );
    return response.data;
  }

  async getCookieStepTemplatesSummary(language, type) {
    const params = {
      language,
      type
    };
    const response = await this.api.client.get(`/templates/cookie-step/summary`, { params });
    return response.data;
  }

  async getLayouts() {
    const response = await this.api.client.get(`/templates/cookie-step/compiled`);
    return Object.fromEntries(
      response.data.map(step => {
        return [step?.layout, { hasVendors: step?.hasVendors }];
      })
    );
  }

  async getCookieStepTemplates(language) {
    const response = await this.api.client.get(`/templates/cookie-step/compiled?language=${language}`);
    return response.data;
  }

  async getDefaultCookieStepStrings(language, country, subdivision) {
    const response = await this.api.client.get(
      `/templates/cookie-step/strings?language=${language}&country=${country}&subdivision=${subdivision}`
    );
    return response.data;
  }

  async getDefaultStepStringsAndSummary(language) {
    const [strings, summary] = await Promise.all([
      this.getDefaultCookieStepStrings(language),
      this.getCookieStepTemplatesSummary(language)
    ]);
    return summary.reduce((acc, step) => {
      acc[step.name] = { ...step, ...strings[step.name] };
      return acc;
    }, {});
  }

  async getLocaleCookieStep(language) {
    const steps = await this.getCookieStepTemplates(language);
    return steps.reduce((acc, step) => {
      acc[step.name] = step;
      return acc;
    }, {});
  }

  async getCookieStepTemplate(name, language, country, subdivision) {
    const response = await this.api.client.get(
      `/templates/cookie-step/compiled/${name}?language=${language}&country=${country}&subdivision=${subdivision}`
    );
    return response.data;
  }

  static getCookieTitle(projectName, language) {
    return `${languages.getName(language, 'en')} ${projectName} Cookies`;
  }

  static getContractTitle(projectName, contractLabel) {
    return `${contractLabel} - ${projectName}`;
  }

  static getCookieName(projectName, language, country, subdivision, cookies) {
    const locale = Localization.getLocaleCode(language, country, subdivision);
    const base = `${projectName.toLowerCase()}-${locale}`;
    let name = base;
    if (Array.isArray(cookies)) {
      const existingNames = cookies.map(cookie => cookie.data?.name) || [];
      let index = 1;
      while (existingNames.includes(name)) {
        ++index;
        name = `${base}-${index}`;
      }
    }
    return name;
  }

  static getDefaultName(projectName, templateName, language, country, subdivision, items) {
    const locale = Localization.getLocaleCode(language, country, subdivision);
    const base = `${projectName.toLowerCase()}-${templateName.toLowerCase()}-${locale}`.toLowerCase();
    let name = base;
    if (Array.isArray(items)) {
      const existingNames = items.map(item => item.data?.name) || [];
      let index = 1;
      while (existingNames.includes(name)) {
        ++index;
        name = `${base}-${index}`;
      }
    }
    return name;
  }
}

export default Templates;

import { isValid, isAfter, parseISO } from 'date-fns';
import stripeProducts from '../data/products';
import { arraysContainsSameValues } from './helpers';

export const onGoingStripeSubscriptionStatus = ['active', 'trialing', 'past_due'];
export const activeStripeSubscriptionStatus = ['active', 'trialing'];
export const onGoingOrIncompleteStripeSubscriptionStatus = ['active', 'trialing', 'past_due', 'incomplete'];

const Services = stripeProducts.filter(product => product.metadata.backoffice !== undefined);

let servicesPlans = {};
Services.forEach(service => {
  const prices = service.prices.filter(
    price => price?.active && price?.recurring !== null && typeof price?.recurring === 'object'
  );
  servicesPlans[service.id] = {
    monthly: prices.find(price => price?.recurring?.interval === 'month' && price?.currency === 'eur'),
    yearly: prices.find(price => price?.recurring?.interval === 'year' && price?.currency === 'eur')
  };
});

export const checkIsUnlocked = isUnlocked => {
  if (!isUnlocked || typeof isUnlocked === 'boolean') {
    return !!isUnlocked;
  }

  const isUnlockedAsDate = parseISO(isUnlocked);

  if (!isValid(isUnlockedAsDate)) {
    return false;
  }

  return isAfter(isUnlockedAsDate, new Date());
};

/**
 * @param {Object} subscription stripe subscription object
 * @param {boolean} [checkIsOngoing = true] return false if subscription is not active, trialing, past_due or incomplete
 *
 * @returns {boolean}
 */
export function isFreeTrialSubscription(subscription, checkIsOngoing = true) {
  const isFreeTrial = !!subscription?.items?.data.find(
    item =>
      freeTrialProjectPrices.includes(item.price.id) ||
      (item.price.product === process.env.REACT_APP_SELFSERVICEPROJECT_METERED_PRODUCT &&
        (item.price.nickname?.includes('Free Trial') ||
          item.price.metadata?.label === 'Free Trial' ||
          item.price.metadata?.monthlyFlatFee === '0'))
  );
  return checkIsOngoing ? isFreeTrial && onGoingOrIncompleteStripeSubscriptionStatus.includes(subscription?.status) : isFreeTrial;
}

export const freeTrialProjectPrices = [
  process.env.REACT_APP_FREETRIAL_METERED_PRICE_MONTHLY,
  process.env.REACT_APP_FREETRIAL_METERED_PRICE_YEARLY,
  process.env.REACT_APP_FREETRIAL_METERED_PRICE_MULTICURRENCY_MONTHLY
];

export function getSubscriptionName(subscription, shortOnly = false) {
  if (!subscription?.items?.data) {
    return '';
  }
  const productPrices = subscription.items.data.map(item => {
    const product = stripeProducts.find(product => product.id === item.price?.product)?.name;
    const price = item.price?.nickname?.replace('Flat fee for ', '');
    return { product, price };
  });
  const completeLabels = [];
  const shortLabels = [];
  productPrices.forEach(({ product, price }) => {
    completeLabels.push(`${price ? price.trim() : ''}${price ? ` (${product})` : product}`);
    shortLabels.push(`${price ? price.trim() : ''}`);
  });
  const labels = shortOnly ? shortLabels : completeLabels;
  return [...new Set(labels.filter(Boolean))].join(', ');
}

export const formatStripeDate = value => new Date(value * 1000).toLocaleDateString();

export function compareOldPriceToActivePrices(oldPrice, activePrices) {
  const differences = {
    isFlatFeeAmountDifferent: false,
    isGraduatedFeeDifferent: false,
    areServicesDifferent: false,
    areFeaturesDifferent: false,
    areStatsDifferent: false
  };

  const activePriceToCompare = activePrices.find(
    price =>
      price.recurring?.interval === oldPrice.recurring?.interval &&
      (price.nickname === oldPrice.nickname || price.metadata?.label === oldPrice.metadata?.label)
  );
  if (!activePriceToCompare) {
    Object.keys(differences).forEach(key => (differences[key] = true));
    return differences;
  }
  const typeOfPrice = oldPrice.tiers_mode === 'graduated' ? 'graduated' : 'flatFee';

  const oldFlatFeePrice = (() => {
    if (typeOfPrice === 'flatFee') {
      return oldPrice;
    }
    const product = stripeProducts.find(one => one.id === oldPrice.product);
    return product?.prices.find(price => price.id === oldPrice.metadata?.flatFeePriceId);
  })();

  if (oldFlatFeePrice?.unit_amount !== activePriceToCompare.unit_amount) {
    differences.isFlatFeeAmountDifferent = true;
  }

  const oldGraduatedPrice = (() => {
    if (typeOfPrice === 'graduated') {
      return oldPrice;
    }
    const product = stripeProducts.find(one => one.id === oldPrice.product);
    return product?.prices.find(price => price.id === oldPrice.metadata?.tieredPriceId);
  })();

  if (!oldGraduatedPrice?.tiers?.[0] || !activePriceToCompare?.tiers?.[0]) {
    differences.isGraduatedFeeDifferent = true;
  } else {
    const oldFirstTier = oldGraduatedPrice.tiers[0];
    const activeFirstTier = activePriceToCompare.tiers[0];
    if (oldFirstTier.up_to !== activeFirstTier.up_to || oldFirstTier.unit_amount !== activeFirstTier.unit_amount) {
      differences.isGraduatedFeeDifferent = true;
    } else {
      const oldLastTier = oldGraduatedPrice.tiers[oldGraduatedPrice.tiers.length - 1];
      const activeLastTier = activePriceToCompare.tiers[activePriceToCompare.tiers.length - 1];
      if (
        oldLastTier.unit_amount !== activeLastTier.unit_amount ||
        oldLastTier.unit_amout_decimal !== activeLastTier.unit_amout_decimal
      ) {
        differences.isGraduatedFeeDifferent = true;
      }
    }
  }

  const metadataToCompare = [
    'services',
    'features',
    'statsAllowedAggregateBy',
    'statsAreFiltersAllowed',
    'statsKpi',
    'statsPeriod',
    'statsWidgets'
  ];

  for (const metadata of metadataToCompare) {
    const defaultValueProp = (() => {
      if (metadata === 'services') {
        return 'areServicesDifferent';
      }
      if (metadata === 'features') {
        return 'areFeaturesDifferent';
      }
      return 'areStatsDifferent';
    })();

    if (differences[defaultValueProp]) {
      continue;
    }
    const oldValues = new Set();
    oldPrice.metadata?.[metadata]?.split(',').forEach(value => {
      oldValues.add(value.trim());
    });

    stripeProducts
      .find(product => product.id === oldPrice.product)
      ?.metadata?.[metadata]?.split(',')
      .forEach(value => {
        oldValues.add(value.trim());
      });

    const activeValues = new Set();
    activePriceToCompare.metadata?.[metadata]?.split(',').forEach(value => {
      activeValues.add(value.trim());
    });

    stripeProducts
      .find(product => product.id === activePriceToCompare.product)
      ?.metadata?.[metadata]?.split(',')
      .forEach(value => {
        activeValues.add(value.trim());
      });

    if (!arraysContainsSameValues([...oldValues], [...activeValues])) {
      differences[defaultValueProp] = true;
    }
  }
  return differences;
}

/**
 * @param {string} priceId
 * @returns {Object}
 */
export function getProductBasedOnPriceId(priceId) {
  return stripeProducts.find(product => product.prices.find(price => price.id === priceId));
}

/**
 * @param {string} priceId
 * @returns {Object}
 */
export function getPrice(priceId) {
  let foundPrice;
  for (const product of stripeProducts) {
    const price = product.prices.find(price => price.id === priceId);
    if (price) {
      foundPrice = price;
      break;
    }
  }
  return foundPrice;
}

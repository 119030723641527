import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import Page from '../../Page/Page';
import AppHeader from '../AppHeader';
import Container from '../../Container/Container';
import { FullHeight } from '../Elements';
import { Label } from '../../Input/Input';
import Button from '../../Button/Button';
import { useNavigate, useRouteError } from 'react-router-dom';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  gap: 20px;

  label {
    justify-content: center;
  }
`;
function Error() {
  const navigate = useNavigate();
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      captureException(error);
      console.error(error);
    }
  }, [error]);

  return (
    <>
      <AppHeader breadCrumb={[{ title: 'Error' }]} breadCrumbInfo={{}} />
      <Page>
        <Container>
          <FullHeight>
            <Content>
              <Label id="notFound">Page error</Label>
              <Button
                onClick={() => {
                  navigate('/');
                }}
                primary
              >
                Back home
              </Button>
            </Content>
          </FullHeight>
        </Container>
      </Page>
    </>
  );
}

export default Error;

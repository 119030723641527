import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withLocaleContext } from '../../contexts/LocaleContext';
import Flex from '../Flex/Flex';
import Img from '../Img/Img';
import Assets from '../../services/Assets';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import COLORS from '../../constants/colors';
import Templates from './Services/Templates';
import Spacer from '../Spacer/Spacer';
import Pellet from '../Pellet/Pellet';
import Alert from '../Alert/Alert';
import { onGoingOrIncompleteStripeSubscriptionStatus } from '../../base/billingHelpers';
import { withTranslation } from 'react-i18next';

const PublishErrorModalStyle = styled.div`
  .body {
    margin-bottom: 24px;
  }
  .description p {
    font-size: 13px;
    margin-bottom: 4px;
  }
  .errors {
    margin-bottom: 24px;
    overflow-y: auto;
    max-height: 300px;
    border-radius: 5px;
    border: 1px solid #e5e5f0;
  }
  .actions {
    text-align: center;
  }
  .illustration {
    margin: 24px 24px 0 0;
  }
  h1 {
    color: ${COLORS.RED_800};
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    color: ${COLORS.RED_900};
    text-transform: uppercase;
    font-size: 13px;
    text-indent: 15px;
    font-weight: bold;
  }
`;
const PublishErrorStyle = styled.div`
  padding: 8px;
  background: #fdfdfd;
  border-bottom: 1px solid #e5e5f0;
  &:last-child {
    border-bottom: none;
  }
  img {
    margin-right: 6px;
  }
  .errorMessage {
    font-weight: bold;
    color: #444;
    em {
      font-weight: normal;
    }
  }
  .getBackToFree {
    font-style: italic;
    font-size: 12px;
  }
`;

const getErrorMessage = async (locale, type, limit, expected, actual, api) => {
  if (!limit) {
    return { title: type };
  }
  let details = '';
  if (type === 'cookiesStepLayout') {
    const templates = new Templates(api);
    const cookieStepTemplatesSummary = await templates.getCookieStepTemplatesSummary(locale.locale.toLowerCase());
    details = cookieStepTemplatesSummary.filter(template => template.layout === actual)[0]?.label;
  } else {
    if (actual) {
      details = actual;
      if (expected) {
        details += ' / ' + expected;
      }
    }
  }
  return {
    title: limit.errorMessage,
    details
  };
};
const getService = (locale, limit) => {
  if (!limit || !limit.service) {
    return { name: '' };
  }
  if (limit.service.indexOf('config') === 0) {
    const name = limit.service.substring(7);
    return locale.projectConfigurations
      .filter(cnf => cnf.name === name)
      .map(cnf => {
        return {
          url: `config/${cnf.url}`,
          icon: cnf.icon
        };
      })[0];
  }
  return {
    url: locale.servicesMap[limit.service].name,
    icon: locale.servicesMap[limit.service].icon
  };
};

const PublishError = withLocaleContext(
  class PublishError extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        errorMessage: {
          title: ''
        },
        limit: null
      };
    }
    async componentDidMount() {
      const { type, expected, actual, locale, api } = this.props;
      const limit = locale.accessLimitsMap[type];
      const errorMessage = await getErrorMessage(locale, type, limit, expected, actual, api);
      this.setState({ errorMessage, limit });
    }

    render() {
      const { identifier, locale, projectId } = this.props;
      const { errorMessage, limit } = this.state;
      const service = getService(locale, limit);
      return (
        <PublishErrorStyle>
          {limit && (
            <Flex>
              {service && <Img src={Assets.image(service.icon)} alt="service icon" width={40} />}
              <div>
                <div className="errorMessage">
                  {errorMessage.title} {errorMessage.details && <em>({errorMessage.details})</em>}
                </div>
                <div className="getBackToFree">
                  <a href={`/projects/${projectId}/${service.url || ''}/${identifier || ''}?disable_publish_error=1`}>
                    {limit.getBackToFree}
                  </a>
                </div>
              </div>
            </Flex>
          )}
        </PublishErrorStyle>
      );
    }
  }
);

class PublishErrorsModal extends PureComponent {
  render() {
    const { errors, locale, projectId, api, project, projectOrganization, t } = this.props;
    const projectHasActiveSubscription = onGoingOrIncompleteStripeSubscriptionStatus.includes(
      project.billing?.subscription?.status
    );
    const organizationHasActiveSubscription = onGoingOrIncompleteStripeSubscriptionStatus.includes(
      projectOrganization?.subscription?.status
    );

    const errorsIsArray = Array.isArray(errors); // config errors
    const errorsIsString = typeof errors === 'string'; // API errors
    return (
      <Modal isOpen={true} onClose={this.props.onClose}>
        <PublishErrorModalStyle>
          <Flex wrap="nowrap" className="body">
            <div className="illustration">
              <Img src={Assets.image('publish-errors')} width={140} />
            </div>
            <div>
              <h1>{t('publish_error_freemium_title')}</h1>
              <h2>{t('publish_error_freemium_subtitle')}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: t('publish_error_freemium_description')
                }}
              />
            </div>
          </Flex>
          <h3>{t('publish_error_errors')}</h3>
          <div className="errors">
            {errorsIsArray
              ? errors.map((error, index) => <PublishError {...error} projectId={projectId} key={index} api={api} />)
              : null}
            {errorsIsString ? (
              <Alert error>
                <Spacer small>
                  <Pellet error>{t('publish_error_technicalError_label')}</Pellet>
                </Spacer>
                <p>{t('publish_error_technicalError_message')} :</p>
                <code>{errors}</code>
              </Alert>
            ) : null}
          </div>
          <div className="actions">
            {!projectHasActiveSubscription && organizationHasActiveSubscription ? (
              <a
                href={locale.locale === 'FR' ? 'https://www.axept.io/fr/contactez-nous#form' : 'https://www.axept.io/contact-us'}
                target="_blank"
                rel="noreferrer"
              >
                <Button primary onClick={this.props.onClose}>
                  {t('contact_sales_modal_description')}.<br />
                  {t('contact_sales_modal_title')}
                </Button>
              </a>
            ) : (
              <a href={`/projects/${projectId}/config/subscription`}>
                <Button primary onClick={this.props.onClose}>
                  {t('access_upgrade_btn')}
                </Button>
              </a>
            )}
          </div>
        </PublishErrorModalStyle>
      </Modal>
    );
  }
}

export default withLocaleContext(withTranslation()(PublishErrorsModal));

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import COLORS from '../../constants/colors';

const Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${props => (props.size ? `${props.size}%` : '0%')};
  background-color: ${COLORS.YELLOW};
  border-radius: 1000px;
  transition: all 350ms ease;
`;

const Style = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  margin: auto;
  background-color: ${COLORS.GRAY_100};
  border-radius: 1000px;
  overflow: hidden;

  ${props =>
    props.sticky &&
    `
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    border-radius: 0 0 1000px 1000px;

    ${Bar} {
      border-radius: 0;
    }
  `};
`;

const ProgressBar = ({ size, sticky }) => (
  <Style sticky={sticky}>
    <Bar size={size} />
  </Style>
);

ProgressBar.propTypes = {
  stiky: PropTypes.bool,
  size: PropTypes.number
};

export default ProgressBar;

const claimTypes = [
  {
    name: 'access',
    label: 'Demande d\'accès à mes données personnelles'
  },
  {
    name: 'modification',
    label: 'Demande de rectification de mes données personnelles'
  },
  {
    name: 'opposition',
    label: 'Demande d\'opposition à un traitement'
  },
  {
    name: 'oblivion',
    label: 'Droit à l\'oubli'
  },
  {
    name: 'restriction',
    label: 'Demande de restriction d\'un traitement'
  },
  {
    name: 'portability',
    label: 'Portabilité de mes données personnelles'
  },
  {
    name: 'other',
    label: 'Autre'
  }
];

export default claimTypes;
import styled from 'styled-components';
import COLORS from '../../constants/colors';

const TableWrapper = styled.div`
  ${props =>
    props?.maxWidth &&
    `
    max-width: ${props.maxWidth}px;
    margin: auto;
  `}
  padding: ${props => props.padding ?? '4px 0'};
  background: ${COLORS.WHITE};
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.05);
  border-radius: ${props => props.radius ?? '4px'};
  overflow: ${props => props.overflow ?? 'scroll'};
  ${props =>
    props?.overflowX &&
    `
    overflow: hidden;
    overflow-x: ${props.overflowX};
  `}
  ${props =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight};
  `};
  ${props =>
    props.radius &&
    `table tr:first-child th:first-child {
       border-top-left-radius: ${props.radius};
    }
    table tr:first-child th:last-child {
       border-top-right-radius: ${props.radius};
    }
    table tr:last-child td:last-child {
       border-bottom-right-radius: ${props.radius};
    }
    table tr:last-child td:first-child {
        border-bottom-left-radius: ${props.radius};
    }`}
  table tr td, table tr th {
    text-align: ${props => props.textAlign ?? 'initial'};
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }

  thead {
    background: ${COLORS.WHITE};
    border-bottom: 1px solid ${COLORS.GRAY_100};

    th {
      padding: 12px 8px;
      font-size: 13px;
      font-weight: 400;
      text-align: left;
      color: ${COLORS.GRAY_500};

      &:first-of-type {
        padding-left: 20px;
      }

      &:last-of-type {
        padding-right: 20px;
      }
    }
  }
`;

export const TableRow = styled.tr`
  z-index: 10;
  position: relative;
  background: ${COLORS.WHITE};
  border-bottom: 1px solid ${props => (props.color ? COLORS[props.color + '_100'] : COLORS.GRAY_100)};

  &:nth-child(${props => (props.color ? 'even' : 'odd')}) {
    background: ${props => (props.color ? COLORS[props.color + '_50'] : COLORS.GRAY_50)};
  }

  th {
    ${props => (props.center ? `text-align: center ` : `text-align: left;`)};
  }

  &:last-of-type {
    border-bottom: none;
  }

  ${props =>
    props.hasHover &&
    `
    &:hover {
    ${props.onClick && 'cursor:pointer;'}
      background: ${props.color ? COLORS[props.color + '_100'] : COLORS.GRAY_100};
    }
  `};

  ${props =>
    props.isToggled &&
    `
    z-index: 100;
    background: ${COLORS.WHITE};
    outline: 2px solid ${COLORS.YELLOW};
  `};

  td {
    padding: 14px 8px;
    font-size: 13px;
    ${props => props.slim && `padding: 7px 5px;`};
  }
`;

export const TableTd = styled.td`
  margin: 0;
  font-weight: 400;
  line-height: 100%;
  text-align: left;
  white-space: ${props => props.whiteSpace || 'nowrap'};

  ${props =>
    props.center &&
    `
    text-align: center;
  `};

  ${props =>
    props.valign &&
    `
    vertical-align: middle;
  `};

  &:first-of-type {
    padding-left: 20px;
  }

  &:last-of-type {
    padding-right: 20px;
  }
`;

export const TableReset = styled.div`
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0 0 20px;
    border: 1px solid ${COLORS.GRAY_200};
    overflow: hidden;
  }

  tr:nth-child(2n) {
    background: ${COLORS.GRAY_50};
  }

  th,
  td {
    font-size: 12px;
    padding: 10px;
  }

  th {
    color: ${COLORS.WHITE};
    background: ${COLORS.GRAY_900};
    border-right: 1px solid ${COLORS.GRAY_600};
    text-align: left;
  }

  td {
    border-bottom: 1px solid ${COLORS.GRAY_100};
    border-right: 1px solid ${COLORS.GRAY_100};
  }
`;

export default TableWrapper;

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';

const AlertStyle = styled.div`
  margin: 0;
  margin-bottom: 20px;
  padding: 16px 20px;
  font-family: ${FONTS.PRIMARY};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-align: ${props => (props.textCenter ? 'center' : 'left')};
  color: ${COLORS.GRAY_900};
  border-radius: 4px;
  border-left: 4px solid ${COLORS.GRAY_900};
  background: ${COLORS.GRAY_50};
  box-shadow: 0 14px 24px -8px rgba(0, 0, 0, 0.08), 0 2px 6px rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.05);

  ${props =>
    props.onClick &&
    `
    cursor: pointer;
  `};

  ul {
    margin: 10px 0;
    padding: 0 0 0 40px;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  a {
    color: ${COLORS.GRAY_900};
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${props =>
    props.margin &&
    `
    margin: ${props.margin} !important;
  `};

  ${props =>
    props.noMargin &&
    `
    margin: 0 !important;
  `};

  ${props =>
    props.largeMargin &&
    `
    margin-bottom: 40px;
  `};

  ${props =>
    props.noLeftRadius &&
    `
    border-radius: 0 4px 4px 0;
`};

  ${props =>
    props.small &&
    `
    margin-bottom: 10px;
    padding: 8px 10px;
    font-size: 12px;
    line-height: 1.25;

    p {
      font-size: 12px;
    }
  `};

  ${props =>
    props.info &&
    `
    border-color: ${COLORS.BLUE};
    background: ${COLORS.BLUE_100};
  `};

  ${props =>
    props.warning &&
    `
    border-color: ${COLORS.YELLOW};
    background: ${COLORS.YELLOW_100};
  `};

  ${props =>
    props.error &&
    `
    border-color: ${COLORS.REDORANGE};
    background: ${COLORS.REDORANGE_50};
  `};

  ${props =>
    props.success &&
    `
    border-color: ${COLORS.GREEN_200_D};
    background: ${COLORS.GREEN_50};
  `};
`;

const Alert = forwardRef(
  ({ onClick, children, info, warning, error, success, small, margin, noMargin, largeMargin, textCenter, noLeftRadius }, ref) => (
    <AlertStyle
      ref={ref}
      onClick={onClick}
      info={info}
      warning={warning}
      error={error}
      success={success}
      small={small}
      margin={margin}
      noMargin={noMargin}
      largeMargin={largeMargin}
      textCenter={textCenter}
      noLeftRadius={noLeftRadius}
    >
      {children}
    </AlertStyle>
  )
);

export default Alert;

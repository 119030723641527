import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Button from '../Button/Button';
import { ButtonLink } from '../Button/Button';
import Assets from '../../services/Assets';
import { withLocaleContext } from '../../contexts/LocaleContext';
import { withProjectContext } from '../../contexts/ProjectContext';
import Modal from '../Modal/Modal';
import Pellet from '../Pellet/Pellet';
import Table, { TableRow, TableTd } from '../Table/Table';
import Expandable from '../Expandable/Expandable';
import Card, { CardSubTitle, CardTitle } from '../Card/Card';
import parallel from 'async/parallel';
import * as PropTypes from 'prop-types';
import MiniPellet from '../MiniPellet/MiniPellet';
import Flex from '../Flex/Flex';
import Img from '../Img/Img';
import COLORS, { SHADOWS } from '../../constants/colors';
import Check from '../Check/Check';
import Icon from '../Icon/Icon';
import ToggleSwitchDraggable from '../ToggleSwitchDraggable/ToggleSwitchDraggable';
import { ListHeader } from '@axeptio/widget-client/src/components/Cookies/CookiesWidget/Elements.js';
import { checkIsUnlocked, onGoingOrIncompleteStripeSubscriptionStatus } from '../../base/billingHelpers';
import { isProjectInDuePayment } from '../../base/helpers';
import LocalizationBackOffice from '../../services/LocalizationBackOffice';
import Alert from '../Alert/Alert';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const ProjectOption = styled.div`
  cursor: pointer;
  border-radius: 16px;
  background: ${props => (props.checked ? COLORS.YELLOW_50 : 'white')};
  border: 1px solid ${props => (props.checked ? COLORS.YELLOW_300 : COLORS.GRAY_100)};
  padding: 16px;
  .check {
    float: right;
  }
  &:first-child {
    margin-right: 20px;
  }
  div {
    font-size: 11px;
  }
`;

const Service = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 15px;
  > a {
    margin-right: 15px;
  }
`;

const UnpublishedWarning = styled.div`
  position: relative;
  background: ${COLORS.YELLOW_100};
  padding: 20px 20px 20px 28px;

  &::before {
    border-left: 4px solid ${COLORS.YELLOW};
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 8px;
    height: 100%;
  }
`;

const ModalNoPaymentWarning = styled.div`
  position: relative;
  min-height: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;

  img.happy_cookie {
    position: absolute;
    left: -20px;
    top: -20px;
    height: 100px;
  }

  h4 {
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
  }

  p {
    margin: 0;
    font-family: 'Source Sans Pro';
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    color: #000;
  }

  button {
    letter-spacing: 0;
    margin-top: 20px;
    font-weight: 700;
    font-size: 16px;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-left: 8px;
      }
    }
  }

  ${media.greaterThan('medium')`
    padding: 0 130px;

    img.happy_cookie {
      height: 222px;
    }
  `};
`;

const BannerCookiesWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  background: ${COLORS.YELLOW_100};
  border-radius: 8px;
  box-shadow: ${SHADOWS.CARD_SHADOW};
  padding: 14px 20px;
  border-left: 8px solid ${COLORS.YELLOW};
  margin: 12px 0;

  p {
    margin: 0 0 0 10px;
    color: ${COLORS.BLACK};
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
`;

const CardTitleCustom = styled(CardTitle)`
  color: ${COLORS.BLACK};
  font-size: 18px;
  font-style: normal;
  line-height: 16px;
`;

const CardSubTitleCustom = styled(CardSubTitle)`
  color: ${COLORS.GRAY_700};
  font-style: normal;
  line-height: 14px;
`;

const PublishOptionsModalButton = styled(Button)`
  color: ${COLORS.GRAY};
  font-size: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
`;

function PublishBannerWarnings({ cookiesWarning, cookiesPublishWarning, t }) {
  const warnings = [
    { display: cookiesWarning, errorMessage: t('publishModal_warning_vendors') },
    {
      display: cookiesPublishWarning,
      errorMessage: t('publishModal_warning_not_published'),
      icon: 'visible'
    }
  ];

  return (
    <>
      {warnings.map(warning => {
        const { display, errorMessage, icon } = warning;
        if (display) {
          return (
            <BannerCookiesWarning icon={icon}>
              <p>{errorMessage}</p>
              {icon && <Icon name={icon} size={12} className="icon" />}
            </BannerCookiesWarning>
          );
        }
        return null;
      })}
    </>
  );
}

class PublishOptionsModal extends Component {
  state = {
    vaultConfigs: {
      cookies: [],
      tcf: [],
      processings: [],
      contracts: []
    },
    contentVersions: {
      contractsV2: []
    },
    publishPayload: {
      project: {
        dpo: true,
        design: true
      },
      cookies: [],
      tcf: [],
      processings: [],
      contracts: [],
      contractsV2: {}
    },
    isAllCheckedServices: {}
  };

  componentDidMount() {
    const { fetchConfigurations, fetchUnpublishedVersions } = this.props;

    const tasks = []
      .concat(
        Object.keys(this.state.vaultConfigs).map(service => {
          return cb =>
            fetchConfigurations(service).response.then(configs =>
              this.setState(state => {
                state.vaultConfigs[service] = configs;
                return state;
              }, cb)
            );
        })
      )
      .concat(
        Object.keys(this.state.contentVersions).map(service => {
          return cb => {
            fetchUnpublishedVersions(service === 'contractsV2' ? 'contracts' : service).then(versions => {
              this.setState(state => {
                state.contentVersions[service] = versions;
                return state;
              }, cb);
            });
          };
        })
      );
    parallel(tasks, () => {
      const configs = Object.keys(this.state.vaultConfigs)
        .map(service => this.state.vaultConfigs[service].map(config => ({ service, config })))
        .flat();

      if (configs.length === 1) {
        const { service, config } = configs[0];
        this.setState({
          allFetched: true,
          publishPayload: { ...this.state.publishPayload, [service]: [config.id] },
          isAllCheckedServices: { [service]: true }
        });
        return;
      }

      this.setState({ allFetched: true });
    });
  }

  setProjectProperty(property, value) {
    this.setState(state => {
      state.publishPayload.project[property] = value;
      return state;
    });
  }

  toggleVaultConfig(service, id, checked) {
    this.setState(state => {
      state.publishPayload[service] = checked
        ? state.publishPayload[service].concat([id])
        : state.publishPayload[service].filter(existingId => existingId !== id);
      state.isAllCheckedServices[service] = state.vaultConfigs[service].length === state.publishPayload[service].length;
      return state;
    });
  }

  configHasVendorsOrCoMoV2(config) {
    const hasVendorsInSteps = steps => steps?.some(step => step.published !== false && step?.vendors?.length > 0);
    const foundVendors = hasVendorsInSteps(config.data?.steps) || hasVendorsInSteps(config.data?.specialSteps);

    return foundVendors || config.data?.googleConsentMode?.display;
  }

  isAllChecked = service => {
    return this.state.vaultConfigs[service].length === this.state.publishPayload[service].length;
  };

  toggleAllConfigs(service, checked) {
    this.state.vaultConfigs[service].forEach(conf => {
      this.toggleVaultConfig(service, conf.id, checked);
    });

    this.setState(state => {
      state.isAllCheckedServices[service] = checked;
      return state;
    });
  }

  toggleContentVersion(service, id, versionId, checked) {
    this.setState(state => {
      state.publishPayload[service][id] = checked ? versionId : null;
      return state;
    });
  }

  isCookiesServiceMissingVendors(service) {
    if (service === 'cookies') {
      return this.state.publishPayload.cookies
        .map(id => this.state.vaultConfigs.cookies.find(conf => conf.id === id))
        .some(conf => !this.configHasVendorsOrCoMoV2(conf));
    }
    return false;
  }

  isCookiesNotPublishable(service) {
    if (service === 'cookies') {
      return this.state.publishPayload.cookies
        .map(id => this.state.vaultConfigs.cookies.find(conf => conf.id === id))
        .some(conf => !conf.data.published);
    }
    return false;
  }

  render() {
    let { isOpen, onClose, locale, onPublish, disabled, t } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <h1>{t('publishModal_title')}</h1>
        <p>{t('publishModal_explanations')}</p>
        <div style={{ marginBottom: 20 }}>
          <h3>{t('publishModal_projectSettings_title')}</h3>
          <Flex justifyContent="space-between" fluid wrap="nowrap">
            <ProjectOption
              onClick={() => this.setProjectProperty('design', !this.state.publishPayload.project.design)}
              checked={this.state.publishPayload.project.design}
            >
              <Check large className="check" unChecked={!this.state.publishPayload.project.design} />
              <Img src={Assets.image('project_design')} width={100} />
              <h6>{t('publishModal_publishDesign_title')}</h6>
              <div>{t('publishModal_publishDesign_text')}</div>
            </ProjectOption>
            <ProjectOption
              slim
              onClick={() => this.setProjectProperty('dpo', !this.state.publishPayload.project.dpo)}
              checked={this.state.publishPayload.project.dpo}
            >
              <Check large className="check" unChecked={!this.state.publishPayload.project.dpo} />
              <Img src={Assets.image('new_service_icon_dpo')} width={100} />
              <h6>{t('publishModal_publishDpo_title')}</h6>
              <div>{t('publishModal_publishDpo_text')}</div>
            </ProjectOption>
          </Flex>
        </div>
        {Object.keys(this.state.vaultConfigs).map(service =>
          this.state.vaultConfigs[service].length ? (
            <Service key={service}>
              <h3>{locale.servicesMap[`${service}`].title}</h3>
              <PublishBannerWarnings
                t={t}
                cookiesWarning={this.isCookiesServiceMissingVendors(service)}
                cookiesPublishWarning={this.isCookiesNotPublishable(service)}
              />
              {this.state.vaultConfigs[service].length > 1 && (
                <ListHeader>
                  <p className="ax-header-label">{t('publishModal_toggleAll')}</p>
                  <ToggleSwitchDraggable
                    checked={this.state.isAllCheckedServices?.[service]}
                    onChange={checked => {
                      this.toggleAllConfigs(service, checked);
                    }}
                  />
                </ListHeader>
              )}
              <div>
                {this.state.vaultConfigs[service].map(conf => {
                  const noCookies = service === 'cookies' && !this.configHasVendorsOrCoMoV2(conf);
                  const tcf = service === 'tcf';
                  const noVendors = tcf && !conf.data?.vendors?.length;
                  return (
                    <Card
                      key={conf.id}
                      {...conf}
                      slim
                      checkbox={{
                        checked: this.state.publishPayload[service].includes(conf.id),
                        onToggle: (name, checked) => {
                          this.toggleVaultConfig(service, conf.id, checked);
                        }
                      }}
                      onClick={() => {
                        this.toggleVaultConfig(service, conf.id, !this.state.publishPayload[service].includes(conf.id));
                      }}
                    >
                      <div style={{ marginLeft: 20, width: '100%' }}>
                        <Flex justifyContent="space-between" wrap="nowrap" fluid>
                          <Flex direction="column" gap="6px" fluid>
                            <Flex alignItems="center" justifyContent="start" gap="6px" margin="none">
                              <CardTitleCustom>{conf.data?.title}</CardTitleCustom>
                              {service === 'cookies' && (
                                <Icon name={conf?.data?.published ? 'visible' : 'invisible'} size={14} className="icon" />
                              )}
                            </Flex>
                            <CardSubTitleCustom>{conf.data?.name}</CardSubTitleCustom>
                          </Flex>
                          <span>
                            <Pellet default border>
                              {LocalizationBackOffice.getLocaleName(
                                conf.data?.language,
                                conf.data?.country,
                                conf.data?.subdivision,
                                locale.locale
                              ) || t('unknown_card_language')}
                            </Pellet>
                          </span>
                        </Flex>
                        <Flex justifyContent="space-between" alignItems="center" margin="8px 0 0 0">
                          <MiniPellet>{conf.id}</MiniPellet>
                          <span>
                            {tcf && (
                              <Pellet warning border>
                                TCF
                              </Pellet>
                            )}
                            {noCookies && (
                              <Pellet error border>
                                {t('publishModal_no_cookies')}
                              </Pellet>
                            )}
                            {noVendors && (
                              <Pellet error border>
                                {t('publishModal_no_vendors')}
                              </Pellet>
                            )}
                          </span>
                        </Flex>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Service>
          ) : null
        )}
        {Object.keys(this.state.contentVersions).map(service =>
          this.state.contentVersions[service].filter(doc => doc.unpublishedVersions.length > 0).length ? (
            <Service key={service}>
              <h3>{locale.servicesMap[`${service}`].title}</h3>
              <div>
                {this.state.contentVersions[service]
                  .filter(doc => doc.unpublishedVersions.length > 0)
                  .map(doc => {
                    const latestVersion = doc.unpublishedVersions[doc.unpublishedVersions.length - 1];

                    const selectedVersion = doc.unpublishedVersions.filter(
                      v => v._id === this.state.publishPayload[service][doc._id]
                    )[0];

                    const config = selectedVersion ? selectedVersion.config : latestVersion.config;

                    return (
                      <Card
                        slim
                        key={doc.currentId}
                        checkbox={{
                          onToggle: (name, checked) => {
                            this.toggleContentVersion(service, doc.currentId, latestVersion.version, checked);
                          },
                          checked: !!this.state.publishPayload[service][doc.currentId]
                        }}
                      >
                        <div style={{ marginLeft: 20 }}>
                          <CardTitle
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.toggleContentVersion(
                                service,
                                doc.currentId,
                                latestVersion.version,
                                !this.state.publishPayload[service][doc.currentId]
                              );
                            }}
                          >
                            {config?.title}
                          </CardTitle>
                          <div>
                            <Expandable toggleButtonMargin labelOpen="Hide details" labelClosed="Show versions">
                              <div style={{ width: '100%', overflowY: 'auto' }}>
                                <Table maxHeight={'300px'} style={{ marginTop: 10 }}>
                                  <table>
                                    <thead>
                                      <tr>
                                        <TableTd>Tag</TableTd>
                                        <TableTd>Name</TableTd>
                                        <TableTd>Created At</TableTd>
                                        <TableTd>Created By</TableTd>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {doc.unpublishedVersions.map(version => (
                                        <TableRow
                                          slim
                                          key={version._id}
                                          onClick={() => {
                                            this.toggleContentVersion(service, doc.currentId, version.version, true);
                                          }}
                                        >
                                          <TableTd>
                                            <Pellet>{version.tag}</Pellet>
                                          </TableTd>
                                          <TableTd
                                            style={{
                                              maxWidth: 200,
                                              whiteSpace: 'break-spaces'
                                            }}
                                          >
                                            {version.name}
                                          </TableTd>
                                          <TableTd
                                            style={{
                                              whiteSpace: 'break-spaces'
                                            }}
                                          >
                                            {new Date(version.publishedAt).toLocaleString()}
                                          </TableTd>
                                          <TableTd>{version.publishedByUser.email}</TableTd>
                                          <TableTd>
                                            <input
                                              type="radio"
                                              name={doc.currentId}
                                              value={version.version}
                                              checked={this.state.publishPayload[service][doc.currentId] === version.version}
                                              onChange={e => {
                                                this.toggleContentVersion(service, doc.currentId, parseInt(e.target.value), true);
                                              }}
                                            />
                                          </TableTd>
                                        </TableRow>
                                      ))}
                                    </tbody>
                                  </table>
                                </Table>
                              </div>
                            </Expandable>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
              </div>
            </Service>
          ) : null
        )}

        <Alert info>{t('publish_modal_integration_message')}</Alert>

        <Flex alignItems="center" justifyContent="flex-end" fluid gap="10px">
          <Link to={'/projects/' + this.props.projectId + '?integrationSidebar=open'}>
            <Button
              secondary
              onClick={() => {
                onClose();
              }}
            >
              {t('integrate_project_button')}
            </Button>
          </Link>
          <PublishOptionsModalButton
            dataFeature="publish"
            primary
            blackFont
            onClick={() => onPublish(this.state.publishPayload)}
            disabled={disabled}
          >
            {t('publish_button_publish')}
          </PublishOptionsModalButton>
        </Flex>
      </Modal>
    );
  }
}

PublishOptionsModal.propTypes = {
  isOpen: PropTypes.any,
  onClose: PropTypes.any,
  locale: PropTypes.any,
  onPublish: PropTypes.any,
  fetchConfigurations: PropTypes.any,
  fetchUnpublishedVersions: PropTypes.any
};

class PublishModal extends Component {
  state = {
    publishParams: {},
    publishingCookies: false,
    showPublishingHelpOneTime: true
  };

  getPublishPayload(publishParams) {
    if (Array.isArray(publishParams.cookies) && publishParams.cookies.length > 0) {
      this.setState({ publishingCookies: true });
    }
    return {
      project: publishParams.project,
      contractsV2: Object.keys(publishParams.contractsV2).map(id => {
        return {
          id,
          latestVersion: publishParams.contractsV2[id]
        };
      }),
      cookies: publishParams.cookies.map(id => {
        return { id };
      }),
      tcf: publishParams.tcf.map(id => {
        return { id };
      }),
      processings: publishParams.processings.map(id => {
        return { id };
      }),
      contracts: publishParams.contracts.map(id => {
        return { id };
      })
    };
  }

  render() {
    const {
      open,
      onClose,
      startProjectPublish,
      locale,
      project,
      projectOrganization,
      projectAccessMapping,
      projectMetadata,
      publishErrors,
      t
    } = this.props;

    const subscription =
      project?.billing?.subscription || project?.thirdPartyBilling?.subscription || projectOrganization?.subscription;

    const isFreeWithoutSubscription =
      !onGoingOrIncompleteStripeSubscriptionStatus.includes(subscription?.status) &&
      !onGoingOrIncompleteStripeSubscriptionStatus.includes(projectOrganization?.subscription?.status) &&
      new Date(project.createdAt) > new Date(process.env.REACT_APP_NEWPRICES_DATE) &&
      !(checkIsUnlocked(project.isUnlocked) || checkIsUnlocked(projectOrganization?.isUnlocked));

    const hasUnlockedServices =
      Object.entries(projectAccessMapping?.services || {})
        .map(([key, value]) => ['cookies', 'processings', 'contracts', 'contractsV2'].includes(key) && value)
        .filter(Boolean).length > 0;

    const isPaymentDue = isProjectInDuePayment({
      ...project,
      organization: projectOrganization
    });

    if (publishErrors?.length !== 0 && this.state.showPublishingHelpOneTime) {
      // no setState here as it will cause infinite re-render
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.showPublishingHelpOneTime = false;
    }

    return (
      <>
        {open && (
          <>
            <PublishOptionsModal
              isOpen={!isFreeWithoutSubscription || hasUnlockedServices}
              onClose={onClose}
              locale={locale}
              onPublish={publishParams => {
                onClose();
                startProjectPublish(this.getPublishPayload(publishParams));
              }}
              {...this.props}
            />

            <Modal isOpen={isFreeWithoutSubscription && !hasUnlockedServices} onClose={onClose} medium>
              <ModalNoPaymentWarning>
                <Img src={Assets.image(`happy_cookie`)} height={222} className="happy_cookie" />
                <h4>{t('publishModal_freeTrialWithoutSubscription')}</h4>
                <p>{t('publishModal_freeTrialWithoutSubscription_integration')}</p>
                <Button
                  primary
                  blackFont
                  onClick={e => {
                    e.preventDefault();
                    window.location.href = `/projects/${this.props.projectId}/config/subscription`;
                  }}
                >
                  {t('button_confirmBox_okLetsGo')}
                  <Icon name="arrow_forward_2" size={18} className="icon" />
                </Button>

                <Link to={'/projects/' + this.props.projectId + '?integrationSidebar=open'}>
                  <Button
                    secondary
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {t('integrate_project_button')}
                  </Button>
                </Link>
              </ModalNoPaymentWarning>
            </Modal>
          </>
        )}

        <Modal
          isOpen={
            this.state.showPublishingHelpOneTime &&
            this.state.publishingCookies &&
            !projectMetadata.lastPublishedAt &&
            !isFreeWithoutSubscription &&
            !isPaymentDue.isPaymentDue
          }
          onClose={() =>
            this.setState({
              publishingCookies: false,
              showPublishingHelpOneTime: false
            })
          }
        >
          <h2>{t('project_unpublished_warning_toast_title')}</h2>
          <UnpublishedWarning>{t('project_unpublished_warning_toast_text')}</UnpublishedWarning>
          <FlexContainer>
            <ButtonLink
              secondary
              className="buttonUpgradeFreeTrial"
              target="_blank"
              rel="noreferrer noopener"
              href={t('project_unpublished_warning_toast_link')}
            >
              {t('project_unpublished_warning_toast_button_title')}
            </ButtonLink>

            <Button
              primary
              blackFont
              onClick={() =>
                this.setState({
                  publishingCookies: false,
                  showPublishingHelpOneTime: false
                })
              }
            >
              {t('project_unpublished_warning_toast_text_ok_for_me')}
            </Button>
          </FlexContainer>
        </Modal>
      </>
    );
  }
}

export default withProjectContext(withLocaleContext(withTranslation()(PublishModal)));

const taxIds = [
  {
    country: 'Émirats Arabes Unis',
    countryCode: 'AE',
    type: 'ae_trn',
    name: 'TRN',
    pattern: 123456789012345,
    description: 'TRN — Émirats Arabes Unis'
  },
  {
    country: 'Australie',
    countryCode: 'AU',
    type: 'au_abn',
    name: 'AU ABN',
    pattern: 12345678912,
    description: 'AU ABN — Australie'
  },
  {
    country: 'Brésil',
    countryCode: 'BR',
    type: 'br_cnpj',
    name: 'CNPJ',
    pattern: '01.234.456/5432-10',
    description: 'CNPJ — Brésil'
  },
  {
    country: 'Brésil',
    countryCode: 'BR',
    type: 'br_cpf',
    name: 'CPF',
    pattern: '123.456.789-87',
    description: 'CPF — Brésil'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    type: 'ca_bn',
    name: 'BN',
    pattern: 123456789,
    description: 'BN — Canada'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    type: 'ca_qst',
    name: 'QST',
    pattern: '1234567890TQ1234',
    description: 'QST — Canada'
  },
  {
    country: 'Suisse',
    countryCode: 'CH',
    type: 'ch_vat',
    name: 'TVA',
    pattern: 'CHE-123.456.789 MWST',
    description: 'TVA — Suisse'
  },
  {
    country: 'Chili',
    countryCode: 'CL',
    type: 'cl_tin',
    name: 'TIN',
    pattern: '12.345.678-K',
    description: 'TIN — Chili'
  },
  {
    country: 'Espagne',
    countryCode: 'ES',
    type: 'es_cif',
    name: 'CIF',
    pattern: 'A12345678',
    description: 'CIF — Espagne'
  },
  {
    country: 'Autriche',
    countryCode: 'AT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'ATU12345678',
    description: 'TVA — Autriche'
  },
  {
    country: 'Belgique',
    countryCode: 'BE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'BE0123456789',
    description: 'TVA — Belgique'
  },
  {
    country: 'Bulgarie',
    countryCode: 'BG',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'BG0123456789',
    description: 'TVA — Bulgarie'
  },
  {
    country: 'Chypre',
    countryCode: 'CY',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'CY12345678Z',
    description: 'TVA — Chypre'
  },
  {
    country: 'République Tchèque',
    countryCode: 'CZ',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'CZ1234567890',
    description: 'TVA — République Tchèque'
  },
  {
    country: 'Allemagne',
    countryCode: 'DE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'DE123456789',
    description: 'TVA — Allemagne'
  },
  {
    country: 'Danemark',
    countryCode: 'DK',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'DK12345678',
    description: 'TVA — Danemark'
  },
  {
    country: 'Estonie',
    countryCode: 'EE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'EE123456789',
    description: 'TVA — Estonie'
  },
  {
    country: 'Espagne',
    countryCode: 'ES',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'ESA1234567Z',
    description: 'TVA — Espagne'
  },
  {
    country: 'Finlande',
    countryCode: 'FI',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'FI12345678',
    description: 'TVA — Finlande'
  },
  {
    country: 'France',
    countryCode: 'FR',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'FRAB123456789',
    description: 'TVA — France'
  },
  {
    country: 'Royaume-Uni',
    countryCode: 'GB',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'GB123456789',
    description: 'TVA — Royaume-Uni'
  },
  {
    country: 'Grèce',
    countryCode: 'GR',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'EL123456789',
    description: 'TVA — Grèce'
  },
  {
    country: 'Croatie',
    countryCode: 'HR',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'HR12345678912',
    description: 'TVA — Croatie'
  },
  {
    country: 'Hongrie',
    countryCode: 'HU',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'HU12345678912',
    description: 'TVA — Hongrie'
  },
  {
    country: 'Irelande',
    countryCode: 'IE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'IE1234567AB',
    description: 'TVA — Irelande'
  },
  {
    country: 'Italie',
    countryCode: 'IT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'IT12345678912',
    description: 'TVA — Italie'
  },
  {
    country: 'Lithuanie',
    countryCode: 'LT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'LT123456789123',
    description: 'TVA — Lithuanie'
  },
  {
    country: 'Luxembourg',
    countryCode: 'LU',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'LU12345678',
    description: 'TVA — Luxembourg'
  },
  {
    country: 'Lettonie',
    countryCode: 'LV',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'LV12345678912',
    description: 'TVA — Lettonie'
  },
  {
    country: 'Malte',
    countryCode: 'MT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'MT12345678',
    description: 'TVA — Malte'
  },
  {
    country: 'Pays-Bas',
    countryCode: 'NL',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'NL123456789B12',
    description: 'TVA — Pays-Bas'
  },
  {
    country: 'Pologne',
    countryCode: 'PL',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'PL1234567890',
    description: 'TVA — Pologne'
  },
  {
    country: 'Portugal',
    countryCode: 'PT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'PT123456789',
    description: 'TVA — Portugal'
  },
  {
    country: 'Roumaine',
    countryCode: 'RO',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'RO1234567891',
    description: 'TVA — Roumaine'
  },
  {
    country: 'Suède',
    countryCode: 'SE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'SE123456789123',
    description: 'TVA — Suède'
  },
  {
    country: 'Slovénie',
    countryCode: 'SI',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'SI12345678',
    description: 'TVA — Slovénie'
  },
  {
    country: 'Slovaquie',
    countryCode: 'SK',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'SK1234567891',
    description: 'TVA — Slovaquie'
  },
  {
    country: 'Hong Kong',
    countryCode: 'HK',
    type: 'hk_br',
    name: 'BR',
    pattern: 12345678,
    description: 'BR — Hong Kong'
  },
  {
    country: 'Indonesie',
    countryCode: 'ID',
    type: 'id_npwp',
    name: 'NPWP',
    pattern: '12.345.678.9-012.345',
    description: 'NPWP — Indonesie'
  },
  {
    country: 'Inde',
    countryCode: 'IN',
    type: 'in_gst',
    name: 'GST',
    pattern: '12ABCDE3456FGZH',
    description: 'GST — Inde'
  },
  {
    country: 'Japon',
    countryCode: 'JP',
    type: 'jp_cn',
    name: 'Hōjin Bangō',
    pattern: 1234567891234,
    description: 'Hōjin Bangō — Japon'
  },
  {
    country: 'Corée, République de',
    countryCode: 'KR',
    type: 'kr_brn',
    name: 'BRN',
    pattern: '123-45-67890',
    description: 'BRN — Corée, République de'
  },
  {
    country: 'Liechtenstein',
    countryCode: 'LI',
    type: 'li_uid',
    name: 'UID',
    pattern: 'CHE123456789',
    description: 'UID — Liechtenstein'
  },
  {
    country: 'Mexique',
    countryCode: 'MX',
    type: 'mx_rfc',
    name: 'RFC',
    pattern: 'ABC010203AB9',
    description: 'RFC — Mexique'
  },
  {
    country: 'Malaysie',
    countryCode: 'MY',
    type: 'my_frp',
    name: 'FRP',
    pattern: 12345678,
    description: 'FRP — Malaysie'
  },
  {
    country: 'Malaysie',
    countryCode: 'MY',
    type: 'my_itn',
    name: 'ITN',
    pattern: 'C 1234567890',
    description: 'ITN — Malaysie'
  },
  {
    country: 'Malaysie',
    countryCode: 'MY',
    type: 'my_sst',
    name: 'SST',
    pattern: 'A12-3456-78912345',
    description: 'SST — Malaysie'
  },
  {
    country: 'Norvège',
    countryCode: 'NO',
    type: 'no_vat',
    name: 'VAT',
    pattern: '123456789MVA',
    description: 'VAT — Norvège'
  },
  {
    country: 'Nouvelle Zélande',
    countryCode: 'NZ',
    type: 'nz_gst',
    name: 'GST',
    pattern: 123456789,
    description: 'GST — Nouvelle Zélande'
  },
  {
    country: 'Russie',
    countryCode: 'RU',
    type: 'ru_inn',
    name: 'INN',
    pattern: 1234567891,
    description: 'INN — Russie'
  },
  {
    country: 'Arabie Saoudite',
    countryCode: 'SA',
    type: 'sa_vat',
    name: 'VAT',
    pattern: 123456789012345,
    description: 'VAT — Arabie Saoudite'
  },
  {
    country: 'Singapour',
    countryCode: 'SG',
    type: 'sg_gst',
    name: 'GST',
    pattern: 'M12345678X',
    description: 'GST — Singapour'
  },
  {
    country: 'Singapour',
    countryCode: 'SG',
    type: 'sg_uen',
    name: 'UEN',
    pattern: '123456789F',
    description: 'UEN — Singapour'
  },
  {
    country: 'Thaïland',
    countryCode: 'TH',
    type: 'th_vat',
    name: 'VAT',
    pattern: 1234567891234,
    description: 'VAT — Thaïland'
  },
  {
    country: 'Taïwan',
    countryCode: 'TW',
    type: 'tw_vat',
    name: 'VAT',
    pattern: 12345678,
    description: 'VAT — Taïwan'
  },
  {
    country: 'États-Unis',
    countryCode: 'US',
    type: 'us_ein',
    name: 'EIN',
    pattern: '12-3456789',
    description: 'EIN — États-Unis'
  },
  {
    country: 'Afrique du Sud',
    countryCode: 'ZA',
    type: 'za_vat',
    name: 'VAT',
    pattern: 4123456789,
    description: 'VAT — Afrique du Sud'
  }
];

export default taxIds;
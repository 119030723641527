import styled, { css } from 'styled-components';
import Img from '../Img/Img';
import Assets from '../../services/Assets';
import TypographyLabel from '../TypographyLabel/TypographyLabel';
import CheckboxRadioInput from '../CheckboxRadioInput/CheckboxRadioInput';

const containerSizeStyles = {
  small: css`
    height: 82px;
    padding: 12px;
    gap: 8px;
  `,
  medium: css`
    height: 126px;
    padding: 24px;
    gap: 12px;
  `
};

const Container = styled.label`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.colors.grey.v200};
  cursor: pointer;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  ${({ size }) => containerSizeStyles[size]};
  ${({ width }) => width && `width: ${width};`}

  &:hover:not(:has(input:disabled, :focus, :checked)) {
    border: 2px solid ${({ theme }) => theme.colors.grey.v300};
  }

  &:has(input:focus) {
    border: 2px solid ${({ theme }) => theme.colors.grey.secondary};
    box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.colors.grey.v200};
  }

  &:has(input:checked) {
    border: 2px solid ${({ theme }) => theme.colors.grey.secondary};
  }

  &:has(input:disabled) {
    border: 2px solid ${({ theme }) => theme.colors.grey.v100};
    cursor: not-allowed;
  }

  &:has(input:disabled:checked) {
    border: 2px solid ${({ theme }) => theme.colors.grey.v300};
  }
`;

const Middle = styled.span`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const Description = styled(TypographyLabel)`
  display: block;
  color: ${({ theme }) => theme.colors.grey.v500};
`;

const Input = styled(CheckboxRadioInput)`
  align-self: flex-start;
`;

const imageSize = {
  medium: { width: 62, height: 68 },
  small: { width: 42, height: 45 }
};

function CheckboxRadioCard({
  type = 'checkbox',
  value,
  disabled,
  name,
  image,
  title,
  description,
  size = 'medium',
  className,
  onChange,
  width
}) {
  return (
    <Container width={width} size={size} className={className}>
      <Img {...imageSize[size]} src={Assets.image(image)} alt={image} noCustomizations />
      <Middle>
        {Boolean(title) && (
          <TypographyLabel size="small" accent>
            {title}
          </TypographyLabel>
        )}
        {Boolean(description) && <Description size="small">{description}</Description>}
      </Middle>
      <Input type={type} value={value} disabled={disabled} name={name} size={size} onChange={onChange} />
    </Container>
  );
}

export default CheckboxRadioCard;

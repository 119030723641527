import { getUserBrowsingLanguage } from '../base/helpers';
import { parsePlan, parseServices } from '../components/ProjectComponents/useProjectsDataParser';

function triggerInAppShakeTempSurvey(projects, user) {
  if (!localStorage.getItem('survey_enterprise_for_shake')) {
    const isFirstAgencyOrEnterprise = projects.some(project =>
      ['agency', 'enterprise'].includes(project.organization?.customer?.metadata?.customerType)
    );
    if (isFirstAgencyOrEnterprise) {
      const surveyForShake = window.dataLayer.filter(item => item.event === 'survey_enterprise_for_shake');
      // If we didn't push the event already and it's an agency with a NEW pricing
      if (!surveyForShake[0]) {
        const toldHiddenFields = {
          email: user?.email,
          userId: user?._id,
          language: getUserBrowsingLanguage()
        };
        window.told?.('addHiddenFields', toldHiddenFields);

        window.dataLayer.push({
          event: 'survey_enterprise_for_shake'
        });

        localStorage.setItem('survey_enterprise_for_shake', 1);
      }
    }
  }
}

export function getProjectsWithConfs(api, user, pagination, query, searchTerm, sort) {
  const { response, abort } = api.getProjectsWithConfs(pagination, query, searchTerm, sort);

  return {
    response: response.then(result => {
      if (!result) {
        return null;
      }

      const { data, headers } = result;
      triggerInAppShakeTempSurvey(data, user);

      return {
        projects: data,
        pagination: {
          ...pagination,
          totalCount: parseInt(headers['x-total-count']) || 0,
          lastPage: parseInt(headers['x-last-page']) || 0
        }
      };
    }),
    abort
  };
}

export async function fetchProjects(api, user, pagination, searchTerm) {
  return new Promise(resolve => {
    api.getProjects(
      (projects, headers) => {
        triggerInAppShakeTempSurvey(projects, user);

        resolve({
          projects,
          pagination: {
            ...pagination,
            totalCount: parseInt(headers['x-total-count']) || 0,
            lastPage: parseInt(headers['x-last-page']) || 0
          }
        });
      },
      pagination,
      { embed: ['organization'], with: ['metadata'] },
      searchTerm
    );
  });
}

export function formatProjectsTableData(projects, featureFlagIsEnabled) {
  if (!projects) return [];

  return projects.map(project => {
    return {
      ...project,
      plan: parsePlan(project),
      subRows: parseServices(project)
    };
  });
}

export const progress = status => {
  switch (status) {
    case 'started':
      return 10;
    case 'checking_subscription':
      return 25;
    case 'deploying':
      return 40;
    case 'putFile_success':
      return 60;
    case 'invalidateFile_success':
      return 60;
    case 'checkInvalidation_progress':
      return 80;
    case 'publishFile_success':
    case 'complete':
      return 100;
    default:
      return 0;
  }
};

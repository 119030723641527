import styled, { createGlobalStyle } from 'styled-components';
import FONTS from '../constants/fonts';
import COLORS from '../constants/colors';
import styledNormalize from 'styled-normalize';
import { typography } from './Typography/Typography';
import media from 'styled-media-query';

export const AdminStyles = createGlobalStyle`
  ${styledNormalize};
  ${typography};

  html,
  body {
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  body {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${FONTS.PRIMARY};
    font-size: 1rem;
    line-height: 1.5;
    color: ${COLORS.BLACK};
    background: ${({ theme }) => theme.colors.grey.v50};
    transition: background 1s ease-in-out;
    overflow: hidden;

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
    .ck.ck-balloon-panel{
      z-index: 20000;
    }
    .modal_reorder_helper {
      z-index: 50000;
    }
  }

  .modal_reorder_helper {
    z-index: 50000 !important;
  }

  .sr-only {
    border: 0 !important;
    clip: rect(1px,1px,1px,1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }

  .sr-only-focusable:focus {
    clip: auto !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  max-height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${media.greaterThan('large')`
    height: calc(100vh - 86px);
    > div:first-child {
      margin-bottom: 20px;
    }
  `};
`;

function pushUser(user) {
  try {
    if (!Array.isArray(window.dataLayer)) {
      window.dataLayer = [];
    }
    const createdDate = user?.createdAt ? new Date(user.createdAt) : null;
    const now = new Date();
    const diffTime = createdDate ? Math.abs(now - createdDate) : 0;
    const oneDay = 1000 * 60 * 60 * 24; // One day in milliseconds
    const diffDays = createdDate ? Math.ceil(diffTime / oneDay) : 0;

    window.dataLayer.push({
      user: {
        created_at: createdDate ? createdDate.toISOString().substring(0, 10) : null,
        from: diffDays || 0,
        id: user?._id,
        country: user?.data?.country
      }
    });
  } catch (err) {
    console.error(err);
  }
}

module.exports = { pushUser };

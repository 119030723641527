import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import GlobalStyle from '../base/styles';
import Api from '../Api';
import AxeptioAdmin from '../components/AxeptioAdmin/AxeptioAdmin';
import '../themes/fonts.css';
import Provider from '@axeptio/design-system/build/src/Provider/index';

const App = styled.div`
  position: relative;
  height: 100vh;

  ${media.lessThan('small')`
    max-height: -webkit-fill-available;
  `};
`;

class Cookies extends Component {
  state = {
    api: new Api({ baseURL: process.env.REACT_APP_API_URL })
  };

  render() {
    return (
      <App>
        <GlobalStyle />
        <Provider>
          <AxeptioAdmin api={this.state.api} />
        </Provider>
      </App>
    );
  }
}

export default Cookies;

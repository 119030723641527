const dpoRights = [
  {
    name: 'portability',
    label: 'Droit à la portabilité des données',
    defaultValue: 'Merci de nous communiquer votre demande d\'accès au données par courrier simple ou par email. Afin de nous prémunir de tout risque de fuite de données ou d\'usurpation d\'identité, merci de joindre à cette demande une preuve de votre identité ainsi que vos identifiants sur notre site.'
  },
  {
    name: 'erasure',
    label: 'Droit à l\'oubli',
    defaultValue: 'La suppression de votre compte utilisateur aura pour effet d\'automatiquement supprimer toute information personnelle de nos bases de données'
  },
  {
    name: 'rectification',
    label: 'Droit de rectification',
    defaultValue: 'Pour toute modification de données personnelles vous concernant, vous pouvez vous rendre dans votre compte utilisateur et procéder aux modifications souhaitées. Si jamais vous ne parvenez pas à modifier les informations, merci de nous adresser un email avec votre identifiant et la nature des changements que vous souhaitez apporter.'
  },
  {
    name: 'restriction',
    label: 'Droit à la limitation du traitement',
    defaultValue: 'Si vous souhaitez que nous mettions un terme ou limitons un traitement portant sur vos données personnelles, merci de nous l\'indiquer en précisant le traitement en question ainsi que vos identifiants.'
  }
];

export default dpoRights;
export function nestedUpdate(name, value) {
  const path = name.split('.');
  const spec = {};
  let prop = spec;
  path.forEach((p, i) => {
    prop[p] = {};
    if (i === path.length - 1) {
      prop[p] = { $set: value };
    } else {
      prop = prop[p];
    }
  });
  return spec;
}

/**
 * Deep diff between two objects
 * @param  {Object} baseObject  the original object
 * @param  {Object} newObject the updated object
 * @param  {string}  path prepend path to every changes field
 * @param  {boolean}  dotNotation return the result with dot notation for arrays: ready for mongodb $set/$unset operators
 * @param  {boolean}  undefinedAsEmptyString  return '' instead of undefined if true
 * @param  {boolean}  keepArrayChangedDetails return specific path/value of array changes if true, else return the complete array path/value if there's some changes in it
 * @return {Object} a new object which represents the diff with paths as properties name and values in it
 */
export function getObjectsDiff({
  baseObject,
  newObject,
  path = '',
  dotNotation = true,
  undefinedAsEmptyString = true,
  keepArrayChangedDetails = false
}) {
  const changes = {};

  let lastKnownArrayValue = undefined;
  let lastKnownArrayPath = undefined;

  function walkObject(baseObject, newObject, path) {
    for (const key of Object.keys(baseObject)) {
      const currentPath = Array.isArray(baseObject) && !dotNotation ? path + `[${key}]` : path === '' ? key : `${path}.${key}`;

      if (!currentPath.includes(lastKnownArrayPath)) {
        // resetting those values if we're not anymore in the last know array path
        lastKnownArrayValue = undefined;
        lastKnownArrayPath = undefined;
      }

      if (newObject?.[key] === undefined && baseObject[key] !== undefined) {
        // value has been removed
        if (currentPath.includes(lastKnownArrayPath)) {
          changes[lastKnownArrayPath] = lastKnownArrayValue;
        } else {
          changes[currentPath] = Array.isArray(baseObject[key]) ? [] : undefinedAsEmptyString ? '' : undefined;
        }
      }
    }
    for (const [key, value] of Object.entries(newObject || {})) {
      const currentPath = Array.isArray(newObject) && !dotNotation ? path + `[${key}]` : path === '' ? key : `${path}.${key}`;

      if (!currentPath.includes(lastKnownArrayPath)) {
        lastKnownArrayValue = undefined;
        lastKnownArrayPath = undefined;
      }
      if (Array.isArray(value) && lastKnownArrayValue === undefined && !keepArrayChangedDetails) {
        lastKnownArrayValue = value;
        lastKnownArrayPath = currentPath;
      }

      if (baseObject[key] === undefined && newObject[key] !== undefined) {
        // new value inserted
        if (currentPath.includes(lastKnownArrayPath)) {
          changes[lastKnownArrayPath] = lastKnownArrayValue;
        } else {
          changes[currentPath] = value;
        }
      } else if (value !== baseObject[key]) {
        // value changed / different than based object or not strictly equal
        if (typeof value === 'object' && typeof baseObject[key] === 'object' && baseObject[key] !== null) {
          // let's check inside
          walkObject(baseObject[key], value, currentPath);
        } else {
          // replaced
          if (currentPath.includes(lastKnownArrayPath)) {
            changes[lastKnownArrayPath] = lastKnownArrayValue;
          } else {
            changes[currentPath] = value;
          }
        }
      }
    }
  }

  walkObject(baseObject, newObject, path);
  // Fix for empty strings. More information here: https://github.com/axeptio/caas-styleguide/pull/2187
  // And here: https://linear.app/axeptio/issue/DEV-6842/analyse-disparity-in-patch-usage#comment-be72a578
  Object.keys(changes).forEach(diffKey => {
    if (changes[diffKey] === '') changes[diffKey] = null;
  });
  return changes;
}

/**
 * Ensure matching concat of stats data when multiple types (consents, hits) are fetched
 * @param  {string[]} statTypes i.e consents, hits
 * @param  {Object} data  raw stats results by types
 * @param  {string} groupByDimension  i.e host, device, browser...
 * @param  {string} aggregateBy i.e day
 * @return {Object} merged data
 */
export function processStatsTypesResults(statTypes, data, groupByDimension = '', aggregateBy = '') {
  let mergedData = {
    dimensions: {},
    results: []
  };

  let dates = [];
  let maxLength = 0;
  statTypes.forEach(type => {
    // Merge dimensions and only add unique values for each one
    Object.keys(data[type].dimensions).forEach(dimension => {
      if (!mergedData.dimensions[dimension]) {
        mergedData.dimensions[dimension] = [];
      }
      mergedData.dimensions[dimension] = [...new Set([...mergedData.dimensions[dimension], ...data[type].dimensions[dimension]])];
    });
    if (aggregateBy) {
      for (const result of data[type].results) {
        if (!result.date) break;
        dates.push(result.date);
      }
      dates = [...new Set([...dates])];
    }
    maxLength = data[type].results.length > maxLength ? data[type].results.length : maxLength;
  });

  let mergedResults = [];

  if (groupByDimension && mergedData.dimensions[groupByDimension]?.length > 0) {
    mergedData.dimensions[groupByDimension].forEach(dimension => {
      let allFound = true;
      let result = {};
      for (const type of statTypes) {
        const found = data[type].results.find(result => result[groupByDimension] === dimension);
        if (!found) {
          allFound = false;
          break;
        }
        result = { ...result, ...found };
      }
      if (allFound && Object.keys(result).length) {
        mergedResults.push(result);
      }
    });
  } else if (aggregateBy && dates.length) {
    dates.forEach(date => {
      let allFound = true;
      let result = {};
      for (const type of statTypes) {
        const found = data[type].results.find(result => result.date === date);
        if (!found) {
          allFound = false;
          break;
        }
        result = { ...result, ...found };
      }
      if (allFound && Object.keys(result).length) {
        mergedResults.push(result);
      }
    });
  } else {
    mergedResults = Array.from({ length: maxLength }, (v, i) => {
      let result = {};
      for (const type of statTypes) {
        if (!data[type].results[i]) {
          return null;
        }
        result = { ...result, ...data[type].results[i] };
      }
      return result;
    }).filter(Boolean);
  }
  mergedData.results = mergedResults;

  return mergedData;
}

export function isEmailValid(value) {
  const regex = new RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  );
  return regex.test(value);
}

/**
 * filter an object based on a props list
 * @param  {Object} obj
 * @param  {string[]} props
 * @return {Object} filtered new object
 */
export function filterProps(obj, props) {
  return Object.assign({}, ...props.map(v => (!!obj[`${v}`] ? { [v]: obj[v] } : null)));
}

export function snake2CamelCase(string) {
  return string.replace(/_(\w)/g, ($, $1) => $1.toUpperCase());
}

export function snake2PascalCase(string) {
  let s = snake2CamelCase(string);
  return `${s.charAt(0).toUpperCase()}${s.substr(1)}`;
}

export function generateTwitterWebIntent(param) {
  const enc = encodeURIComponent;
  const baseUrl = 'https://twitter.com/intent/tweet';
  const hashtags = enc(Array.isArray(param.hashtags) ? param.hashtags.join(',') : param.hashtags);
  return `${baseUrl}?url=${enc(param.url)}&text=${enc(param.text)}&hashtags=${hashtags}`;
}

/**
 *
 * @param {number}  amount
 * @param {string?} [locale]
 * @param {Object?}  [options]
 * @returns {string}
 */
export function formatMoneyAmount(amount, locale, options = {}) {
  locale = locale ?? (navigator.language || 'fr-FR');
  if (options?.hasOwnProperty('currency')) {
    if (!options.currency) {
      delete options.currency;
    } else {
      options.currency = options.currency.toUpperCase();
    }
  }
  options = {
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...options,
    style: 'currency'
  };
  return new Intl.NumberFormat(locale, options).format(amount);
}
/**
 * @param {string}  currency
 * @param {string?} [locale = navigator.language || 'fr-FR']
 * @param {('symbol'|'code'|'name'|'narrowSymbol')} [currencyDisplay = 'symbol']
 * @returns {string}
 */
export function formatCurrency(currency, locale, currencyDisplay) {
  if (!currency) {
    throw new Error('currency is required');
  }
  currency = currency.toUpperCase();
  locale = locale ?? (navigator.language || 'fr-FR');

  currencyDisplay = ['symbol', 'code', 'name', 'narrowSymbol'].includes(currencyDisplay) ? currencyDisplay : 'symbol';

  const options = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currencyDisplay,
    currency,
    style: 'currency'
  };
  return (0).toLocaleString(locale, options).replace(/\d/g, '').trim();
}

export function formatQuantityWithSIPrefix(quantity, locale, options) {
  locale = locale ?? (navigator.language || 'fr-FR');
  options = {
    ...(options || {}),
    notation: 'compact'
  };
  return new Intl.NumberFormat(locale, options).format(quantity).replace(/\s/g, '');
}

export function formatNumber(number, locale = navigator.language || 'fr-FR', options) {
  locale = locale ?? (navigator.language || 'fr-FR');
  options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...(options || {})
  };
  return new Intl.NumberFormat(locale, options).format(number);
}

export function browserLocale() {
  let lang;
  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0];
  } else if (navigator.userLanguage) {
    lang = navigator.userLanguage;
  } else {
    lang = navigator.language;
  }
  return lang;
}

// For backoffice usage, do not rely on browser language which can be different from the user's language (set by clicking on the language switcher)
export function getUserBrowsingLanguage() {
  const language = browserLocale();
  const storedLanguage = window.localStorage.getItem('admin_locale');
  const lang = storedLanguage || (language.indexOf('fr') > -1 ? 'fr' : 'en');

  return lang.toLowerCase();
}

/**
 * subscriptionEntity in return can be either "project" or "organization", meaning that the payment status is related to this entity's subscription
 * @param {?Object} project
 * @returns {{isPaymentDue: boolean, subscriptionEntity: string}}
 */
export function isProjectInDuePayment(project) {
  const result = { isPaymentDue: false, subscriptionEntity: 'project' };
  if (!project) {
    return result;
  }

  const subscription = project.billing?.subscription || project.thirdPartyBilling?.subscription;

  result.isPaymentDue = ['past_due', 'unpaid', 'incomplete_expired'].includes(subscription?.status);
  const defaultSourceId =
    project.billing?.latestInvoice?.default_payment_method ||
    project.billing?.latestInvoice?.default_source ||
    project.billing?.subscription?.default_payment_method ||
    project.billing?.subscription?.default_source ||
    project.billing?.customer?.invoice_settings?.default_payment_method ||
    project.billing?.customer?.default_source?.id ||
    project.billing?.customer?.default_source;
  if (
    subscription?.status === 'past_due' &&
    (subscription.latest_invoice?.status === 'open' || project.billing.latestInvoice?.status === 'open') &&
    project.billing.sources?.find(source => source.id === defaultSourceId)?.type === 'sepa_debit'
  ) {
    // if the subscription source is SEPA and the subscription status is past_due and the next invoice status is open => the payment has yet to be processed, the status past_due is not really relevant
    result.isPaymentDue = false;
  }

  if (
    project.organization?.subscription &&
    !project.organization.subscription.status?.includes['canceled'] &&
    !result.isPaymentDue
  ) {
    const organization = project.organization;
    let isPaymentDue = ['past_due', 'unpaid', 'incomplete_expired', 'incomplete'].includes(organization.subscription.status);
    if (isPaymentDue) {
      result.isPaymentDue = isPaymentDue;
      result.subscriptionEntity = 'organization';
      const defaultSourceId =
        organization.latestInvoice?.default_payment_method ||
        organization.latestInvoice?.source ||
        organization.subscription.default_source ||
        organization.customer?.default_source?.id ||
        organization.customer?.default_source;
      if (
        organization.subscription.status === 'past_due' &&
        organization.latestInvoice?.status === 'open' &&
        organization.sources?.find(source => source.id === defaultSourceId)?.type === 'sepa_debit'
      ) {
        // see above with project case
        result.isPaymentDue = false;
      }
    }
  }
  return result;
}

/**
 *
 * @param entity
 */
export function isInDuePayment(entity) {}
export function isOrganizationInDuePayment(organization) {}

export const getContractV2Id = name => {
  const contractV2Configs = window.axeptioSDK?.config?.contractsV2;
  const userLang = browserLocale()?.indexOf('fr') > -1 ? 'fr' : 'en'; // get lang from html lang attribute or default to browser setting
  const chosenLang = document?.documentElement?.lang?.toLowerCase() || userLang;

  return contractV2Configs
    .filter(config => chosenLang === config.language)
    .find(config => config.name === `${name}_${chosenLang}`)?.name;
};

export const correctedCookieVersion = () => {
  const name = 'axeptio-prod';
  const cookiesConfigs = window.axeptioSDK?.config?.cookies || [];
  let currentLanguage = document?.documentElement?.lang?.toLowerCase() || 'en';
  if (window.localStorage.getItem('admin_locale')) {
    currentLanguage = window.localStorage.getItem('admin_locale');
  }

  return cookiesConfigs
    .filter(config => currentLanguage.toLowerCase() === config.language)
    .find(config => config.name === `${name}-${currentLanguage.toLowerCase()}`)?.name;
};

export const supportedLanguages = [
  'en',
  'fr',
  'it',
  'ar',
  'be',
  'bg',
  'bn',
  'bo',
  'ca',
  'cs',
  'da',
  'de',
  'el',
  'es',
  'et',
  'fi',
  'hi',
  'hr',
  'hu',
  'hy',
  'ga',
  'is',
  'ja',
  'ko',
  'lt',
  'lv',
  'mt',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sq',
  'sr',
  'sv',
  'th',
  'tr',
  'uk',
  'ur',
  'vi',
  'zh'
];

export function isLiteralObject(obj) {
  return !!obj && obj.constructor === Object;
}

export function validateUrl(url) {
  try {
    if (!url) return undefined;
    const decodedUrl = decodeURIComponent(url);
    const parsedUrl = new URL(decodedUrl);
    if (!['http:', 'https:'].includes(parsedUrl.protocol)) {
      return undefined;
    }
    return parsedUrl.toString();
  } catch (error) {
    return undefined;
  }
}

export function getRedirectUri() {
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  return validateUrl(urlParams.redirectUri || urlParams.redirectURI);
}

export function capitalize(string) {
  if (typeof string !== 'string') return '';
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export function getSubscriptionStatusClassProps(status) {
  const classProps = {
    primary: false,
    info: false,
    warning: false,
    error: false,
    success: false
  };
  switch (status) {
    case 'active':
      classProps.success = true;
      break;
    case 'canceled':
    case 'incomplete_expired':
      classProps.error = true;
      break;
    case 'past_due':
    case 'unpaid':
      classProps.warning = true;
      break;
    case 'trialing':
      classProps.info = true;
      break;
    default:
      break;
  }
  return classProps;
}

export function getAllObjectLeaves(obj) {
  const leaves = [];
  function getLeaves(obj) {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        getLeaves(obj[key]);
      } else {
        leaves.push(obj[key]);
      }
    }
  }
  getLeaves(obj);
  return leaves;
}

export function arraysContainsSameValues(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every(item => arr2.includes(item));
}

export function mapUrlWithStack(stack, pathName) {
  // Split the path into segments, removing empty segments
  const pathSegments = pathName.split('/').filter(segment => segment);

  let currentSegmentIndex = 0;

  // Initialize the resulting stack with URLs
  const newStack = stack.map((item, index) => {
    const isLastItem = index === stack.length - 1;
    let url = '';

    if (item.title === 'item' && currentSegmentIndex < pathSegments.length - 1) {
      // For "item" titles, we take two segments
      url = '/' + pathSegments.slice(0, currentSegmentIndex + 2).join('/');
      currentSegmentIndex += 2;
    } else if (currentSegmentIndex < pathSegments.length) {
      // For other titles, we take one segment
      url = '/' + pathSegments.slice(0, currentSegmentIndex + 1).join('/');
      currentSegmentIndex += 1;
    }

    // Attach the URL only if it's not the last item
    if (!isLastItem && url) {
      return { ...item, url: url };
    } else {
      return { ...item };
    }
  });

  return newStack;
}

const featureFlags = [
  {
    id: 'cookies__specialSteps',
    name: 'Écrans spéciaux',
    description: 'Configurez des écrans spéciaux qui enrichissent le comportement du widget de cookies Axeptio. Des types d\'écrans spéciaux différents vous permettront de solliciter l\'internaute en rapport à son activité sur la page web et ainsi apporter une UX de consentement contextuelle et raffraichissante.',
    image: 'cookie-warning',
    stability: 'beta',
    scope: 'project'
  },
  {
    id: 'contracts_v2',
    name: 'Contrats V2',
    image: 'FAQ-prestataire',
    stability: 'alpha',
    scope: 'project'
  }
];

export default featureFlags;
import React from 'react';

const Img = ({ src, width, height, loading, dataFeature, noCustomizations, ...props }) => {
  if (!src) {
    return null;
  }
  const urlEncodedSrc = src.replace(/\s/g, '%20');
  const w = width ? width : 'auto';
  const h = height ? height : 'auto';
  const imageW = width ? width : null;
  const imageH = height ? height : null;
  const customizations = noCustomizations ? '' : `auto=format&fit=crop&w=${w}&h=${h}&`;
  const IMAGE = `${urlEncodedSrc}?${customizations}dpr=1`;
  const IMAGE_2X = `${urlEncodedSrc}?${customizations}dpr=2`;
  const IMAGE_3X = `${urlEncodedSrc}?${customizations}dpr=3`;
  const srcSet = `${IMAGE} 1x, ${IMAGE_2X} 2x, ${IMAGE_3X} 3x`;
  return (
    <img
      src={IMAGE}
      srcSet={noCustomizations ? null : srcSet}
      width={imageW}
      height={imageH}
      alt=""
      loading={loading}
      data-feature={dataFeature}
      {...props}
    />
  );
};

export default Img;

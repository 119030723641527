import styled from 'styled-components';

const Spacer = styled.div`
  margin-bottom: ${props => (props.noMargin ? 0 : 20)}px;

  ${props =>
    props.tiny &&
    `
    margin-bottom: 4px;
  `};

  ${props =>
    props.small &&
    `
    margin-bottom: 10px;
  `};

  ${props =>
    props.large &&
    `
    margin-bottom: 30px;
  `};

  ${props =>
    props.huge &&
    `
    margin-bottom: 50px;
  `};

  ${props =>
    props.custom &&
    `
    margin-bottom: ${props.custom}px;
  `};

  ${props =>
    props.flex &&
    `
    display: flex;
  `};
`;

export default Spacer;

const featureFlags = [
  {
    id: 'cookies__specialSteps',
    name: 'Special Steps',
    description: 'Add special steps to your cookie configuration to provide a better and contextualized user experience.',
    image: 'cookie-warning',
    stability: 'beta',
    scope: 'project'
  },
  {
    id: 'contracts_v2',
    name: 'Contrats V2',
    image: 'FAQ-prestataire',
    stability: 'alpha',
    scope: 'project'
  }
];

export default featureFlags;
import React, { Component } from 'react';
import styled from 'styled-components';
import { FormText, InputGroup, Label } from '../../../Input/Input';
import Input from '../../../Input/Input';
import { ActionFooter, CloseButton, DeleteButton, Editor, Fieldset, FullHeight } from '../../Elements';
import Flex from '../../../Flex/Flex';
import update from 'immutability-helper';
import EditableList from '../../EditableList';
import Alert from '../../../Alert/Alert';
import Button from '../../../Button/Button';
import Pages, { Page } from './Pages';
import Expandable from '../../../Expandable/Expandable';
import WebsiteCapture, { WebsiteCaptureStyle } from '../../../WebsiteCapture/WebsiteCapture';
import Select, { SelectStyle } from '../../../Select/Select';
import ButtonGroup, { Btn } from '../../../ButtonGroup/ButtonGroup';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const defaultEmbed = {
  pages: [{ url: '', regex: false }],
  cssMethod: 'replace',
  checkboxDetailsText: '',
  checkboxText: '',
  displayAs: 'widget'
};

const TokenInputStyle = styled.div`
  display: flex;
  input {
    flex: 1;
    border-radius: 0 6px 6px 0;
  }
  ${SelectStyle} {
    width: 250px;
    flex: 1;
    select {
      border-radius: 6px 0 0 6px;
    }
  }
`;

const EmbedListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${WebsiteCaptureStyle} {
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin-right: 20px;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    font-family: monospace;
    list-style-type: none;
  }
`;

const getPreviewURL = (embed, baseURL) => {
  if (!Array.isArray(embed.pages) || embed.pages.length === 0) {
    return baseURL;
  }
  const page = embed.pages.filter(page => page.regex !== true)[0];
  return page ? `${baseURL}/${page.url}` : baseURL;
};

export const EmbedList = ({ value, onChange, project }) => {
  const { t } = useTranslation();
  return (
    <EditableList
      projectId={project._id}
      items={value}
      onChange={onChange}
      newItemTitle={t('embed_list_new_item_title')}
      newItemDescription={t('embed_list_new_item_description')}
      render={embed => {
        return (
          <EmbedListItem>
            <WebsiteCapture width={225} height={150} websiteUrl={getPreviewURL(embed, project.websiteURL)} />
            <div>
              <h5>URLs</h5>
              {Array.isArray(embed.pages) && (
                <ul>
                  {embed.pages.map((page, i) => (
                    <li key={`page-${i}`}>
                      {project.websiteURL.endsWith('/') ? project.websiteURL : project.websiteURL + '/'}
                      {page.url}
                    </li>
                  ))}
                </ul>
              )}
              {embed.cssTarget && (
                <div>
                  <h5>{t('embed_form_embed_list_injection')}</h5>
                  <div>
                    {t('embed_form_embed_list_css_target')}
                    <code>{embed.cssTarget}</code>
                  </div>
                  <div>
                    {t('embed_form_embed_list_css_method')}
                    <code>{embed.cssMethod}</code>
                  </div>
                </div>
              )}
              <div>
                <h5>{t('embed_form_embed_list_display')}</h5>
                <div>
                  {t('embed_form_embed_list_displayedAs')}
                  &nbsp;
                  <strong>{t(`embed_form_displayAs_${embed.displayAs || 'widget'}`)}</strong>
                </div>
              </div>
            </div>
          </EmbedListItem>
        );
      }}
      formClass={EmbedForm}
      formProps={{ project }}
      url="embeddings"
    />
  );
};

const tokenExamples = {
  innerHTML: 'header .menu li:eq(0)',
  attr: 'div.username[data-id]',
  getVar: 'myApp.user.id',
  value: '.contactForm input.email'
};

class EmbedForm extends Component {
  state = {
    value: Object.assign({}, defaultEmbed, this.props.value),
    newPage: { url: '', regex: false }
  };

  constructor(props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  handleFieldChange(event) {
    const newState = update(this.state, {
      value: { [event.target.name]: { $set: event.target.value } }
    });
    this.setState(newState);
    this.props.onSave(newState.value);
  }

  handleValueChange = state => {
    this.setState({ value: state.value });
    this.props.onSave(state.value);
  };

  updateDisplayAs(value) {
    return event => {
      event.preventDefault();
      const newSate = update(this.state, {
        value: { displayAs: { $set: value } }
      });
      this.setState(newSate);
      this.props.onSave(newSate.value);
    };
  }

  render() {
    const embed = this.state.value || {};
    const project = this.props.project || { websiteURL: '' };
    const { t } = this.props;
    return (
      <Editor>
        <FullHeight>
          <h2>{t('embed_injection_title')}</h2>
          <Fieldset>
            <InputGroup>
              <Label>{t('embed_pages_label')}</Label>
              {(!Array.isArray(embed.pages) || embed.pages.length === 0) && (
                <Alert warning>{t('embed_pages_empty_warning')}</Alert>
              )}
              <Pages>
                {Array.isArray(embed.pages) &&
                  embed.pages.map((page, index) => (
                    <Page
                      key={`page-${index}`}
                      index={index}
                      value={page}
                      domain={project.websiteURL}
                      onChange={val => {
                        this.handleValueChange(
                          update(this.state, {
                            value: { pages: { [index]: { $set: val } } }
                          })
                        );
                      }}
                      onDelete={() => {
                        this.handleValueChange(
                          update(this.state, {
                            value: { pages: { $splice: [[index, 1]] } }
                          })
                        );
                      }}
                    />
                  ))}

                <p style={{ marginTop: 10 }}>
                  <Button
                    small
                    onClick={e => {
                      e.preventDefault();
                      this.handleValueChange(
                        update(this.state, {
                          value: { pages: { $push: [{}] } }
                        })
                      );
                    }}
                  >
                    {t('embed_form_addPage')}
                  </Button>
                </p>
              </Pages>
              <FormText
                dangerouslySetInnerHTML={{
                  __html: t('embed_regex_help')
                }}
              />
            </InputGroup>
            <InputGroup>
              <Label>{t('embed_form_displayAs_label')}</Label>
              <ButtonGroup>
                <Btn onClick={this.updateDisplayAs('widget')} primary={!embed.displayAs || embed.displayAs === 'widget'}>
                  {t('embed_form_displayAs_widget')}
                </Btn>
                <Btn onClick={this.updateDisplayAs('checkbox')} primary={embed.displayAs === 'checkbox'}>
                  {t('embed_form_displayAs_checkbox')}
                </Btn>
                <Btn onClick={this.updateDisplayAs('button')} primary={embed.displayAs === 'button'}>
                  {t('embed_form_displayAs_button')}
                </Btn>
              </ButtonGroup>
            </InputGroup>
            {embed.displayAs === 'button' && (
              <InputGroup>
                <Label>{t('embed_form_buttonText_label')}</Label>
                <Input
                  type="text"
                  name="buttonText"
                  placeholder={t('embed_form_buttonText_placeholder')}
                  onChange={this.handleFieldChange}
                  value={embed.buttonText}
                />
              </InputGroup>
            )}
            {embed.displayAs === 'checkbox' && (
              <>
                <InputGroup>
                  <Label>{t('embed_form_checkboxText_label')}</Label>
                  <Input
                    type="text"
                    name="checkboxText"
                    placeholder={t('embed_form_checkboxText_placeholder')}
                    onChange={this.handleFieldChange}
                    value={embed.checkboxText}
                  />
                </InputGroup>
                <InputGroup>
                  <Label>{t('embed_form_checkboxDetailsText_label')}</Label>
                  <Input
                    type="text"
                    name="checkboxDetailsText"
                    placeholder={t('embed_form_checkboxDetailsText_placeholder')}
                    onChange={this.handleFieldChange}
                    value={embed.checkboxDetailsText}
                  />
                </InputGroup>
              </>
            )}
            <InputGroup>
              <Label>{t('embed_selector_label')}</Label>
              <Input type="text" value={embed.cssTarget || ''} name="cssTarget" onChange={this.handleFieldChange} code />
              <FormText>{t('embed_selector_help')}</FormText>
            </InputGroup>
            <InputGroup>
              <Label>{t('embed_injection_method')}</Label>
              <select name="cssMethod" onChange={this.handleFieldChange} value={embed.cssMethod}>
                <option value="first">{t('embed_form_injection_method_first')}</option>
                <option value="last">{t('embed_form_injection_method_last')}</option>
                <option value="eq">{t('embed_form_injection_method_eq')}</option>
                <option value="before">{t('embed_form_injection_method_before')}</option>
                <option value="after">{t('embed_form_injection_method_after')}</option>
                <option value="replace">{t('embed_form_injection_method_replace')}</option>
              </select>
            </InputGroup>
            {this.state.value.cssMethod === 'eq' && (
              <InputGroup>
                <Label>{t('embed_form_injection_method_eq_label')}</Label>
                <Input
                  type="number"
                  min={0}
                  name="cssInjectEq"
                  value={embed.cssInjectEq || ''}
                  onChange={this.handleFieldChange}
                />
              </InputGroup>
            )}
            <img src={`https://static.axept.io/embed_gifs/${embed.cssMethod}.gif`} alt={embed.cssMethod} />
          </Fieldset>

          <h2>{t('embed_binding_parent_title')}</h2>
          <Fieldset>
            <InputGroup>
              <Label>{t('embed_form_token_label')}</Label>
              <TokenInputStyle>
                <Select
                  name="tokenMethod"
                  value={embed.tokenMethod || ''}
                  onChange={this.handleFieldChange}
                  emptyOption={{ name: '', value: '' }}
                  options={[
                    {
                      value: 'value',
                      name: t('embed_form_tokenMethod_value')
                    },
                    {
                      value: 'attr',
                      name: t('embed_form_tokenMethod_attr')
                    },
                    {
                      value: 'innerHTML',
                      name: t('embed_form_tokenMethod_innerHTML')
                    },
                    {
                      value: 'getVar',
                      name: t('embed_form_tokenMethod_getVar')
                    }
                  ]}
                />
                <Input
                  type="text"
                  name="token"
                  value={embed.token || ''}
                  placeholder={tokenExamples[embed.tokenMethod]}
                  onChange={this.handleFieldChange}
                />
              </TokenInputStyle>
              <FormText>{t('embed_form_token_help')}</FormText>
            </InputGroup>
            <InputGroup>
              <Label>{t('embed_form_elementsToHide_label')}</Label>
              <Input type="text" name="elementsToHide" value={embed.elementsToHide || ''} onChange={this.handleFieldChange} />
              <FormText>{t('embed_form_elementsToHide_help')}</FormText>
            </InputGroup>
            <Expandable labelClosed={t('embeddings_showAdvanced')} labelOpen={t('embeddings_hideAdvanced')}>
              <InputGroup>
                <Label>{t('embed_form_boundInputSelector_label')}</Label>
                <Input
                  type="text"
                  name="boundInputSelector"
                  placeholder={t('embed_form_boundInputSelector_placeholder')}
                  value={embed.boundInputSelector || ''}
                  onChange={this.handleFieldChange}
                />
                <FormText>{t('embed_form_boundInputSelector_help')}</FormText>
              </InputGroup>
              <InputGroup>
                <Label
                  dangerouslySetInnerHTML={{
                    __html: t('embed_form_boundTokenSelector_label')
                  }}
                />
                <Input
                  type="text"
                  name="boundTokenSelector"
                  placeholder={t('embed_form_boundTokenSelector_placeholder')}
                  value={embed.boundTokenSelector || ''}
                  onChange={this.handleFieldChange}
                />
                <FormText
                  dangerouslySetInnerHTML={{
                    __html: t('embed_form_boundTokenSelector_help')
                  }}
                />
              </InputGroup>
            </Expandable>
          </Fieldset>
          <h2>{t('embed_form_injection_margins_title')}</h2>
          <Fieldset>
            <InputGroup>
              <Label>{t('embed_form_injection_margins_label')}</Label>
              <Flex wrap="nowrap" direction="row">
                {['Top', 'Right', 'Left', 'Bottom'].map(side => (
                  <input
                    key={side}
                    type="text"
                    name={`margin${side}`}
                    onChange={this.handleFieldChange}
                    placeholder={side}
                    value={embed[`margin${side}`] || ''}
                  />
                ))}
              </Flex>
            </InputGroup>
            <InputGroup>
              <Label>{t('embed_form_injection_shadow_label')}</Label>
              <input
                type="checkbox"
                checked={embed.shadow !== false}
                onChange={e =>
                  this.handleFieldChange({
                    target: { name: 'shadow', value: e.target.checked }
                  })
                }
              />
            </InputGroup>
            <InputGroup>
              <Label>{t('embed_form_injection_minwidth_label')}</Label>
              <Flex wrap="nowrap" direction="row">
                <input
                  name="minWidth"
                  type="text"
                  onChange={this.handleFieldChange}
                  value={embed.minWidth || ''}
                  placeholder="minWidth"
                />
                <input
                  name="maxWidth"
                  type="text"
                  onChange={this.handleFieldChange}
                  value={embed.maxWidth || ''}
                  placeholder="maxWidth"
                />
              </Flex>
            </InputGroup>
            <InputGroup>
              <Label>{t('embed_form_injection_minheight_label')}</Label>
              <Flex wrap="nowrap" direction="row">
                <input
                  name="minHeight"
                  type="text"
                  onChange={this.handleFieldChange}
                  value={embed.minHeight || ''}
                  placeholder="minHeight"
                />
                <input
                  name="maxHeight"
                  type="text"
                  onChange={this.handleFieldChange}
                  value={embed.maxHeight || ''}
                  placeholder="maxHeight"
                />
              </Flex>
            </InputGroup>
          </Fieldset>
        </FullHeight>
        <ActionFooter>
          {!this.props.isNew && <DeleteButton onClick={this.props.onDelete} />}
          <CloseButton />
        </ActionFooter>
      </Editor>
    );
  }
}

export default withTranslation()(EmbedForm);

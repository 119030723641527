const protectionMechanisms = [
  {
    name: 'contractual_clause_standard_protection',
    title: 'Contractual clause, standard protection'
  },
  {
    name: 'contractual_clause_specific_protection',
    title: 'Contractual clause, specific protection'
  },
  {
    name: 'bcr',
    title: 'Binding Corporate Rules : BCR'
  },
  {
    name: 'approved_code_of_conduct',
    title: 'Approved code of conduct'
  },
  {
    name: 'approved_certification_mechanism',
    title: 'Approved certification mechanism'
  },
  {
    name: 'other_protective_measures',
    title: 'Other protective measures'
  },
  {
    name: 'none',
    title: 'No measure'
  }
];

export default protectionMechanisms;
import styled from 'styled-components';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';

const fontSize = new Map([
  ['small', '0.875rem'],
  ['xsmall', '0.750rem']
]);

const Paragraph = styled.p`
  margin: ${props => (props.margin ? props.margin : '0')};
  margin-bottom: ${props => (props.mb ? props.mb : '40px')};
  font-family: ${FONTS.PRIMARY};
  font-size: ${props => fontSize.get(props.size) ?? '1rem'};
  line-height: 1.5;
  color: ${COLORS.GRAY_900};

  ${props => props.center && `text-align: center;`};

  a:hover {
    text-decoration: underline;
  }
`;

export default Paragraph;

import React from 'react';
import { withProfiler } from '@sentry/react';
import Cookies from './apps/Cookies';
import MaintenanceMode from './components/MaintenanceMode/MaintenanceMode';

const App = () => {
  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    // TODO: Handle multilang and fix withLocaleContext issue
    return (
      <MaintenanceMode
        title="We are down for maintenance"
        description="We’ll be back soon, time to make yourself a ☕, a 🍵 or any comforting beverage of your choice, along with some 🍪 of course !"
      />
    );
  }

  return <Cookies />;
};

export default withProfiler(App);

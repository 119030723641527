const tcfTemplates = [
  {
    name: 'tcf-consent-mode',
    label: 'Prêt pour le Consent Mode V2 de Google',
    description: 'Créer une configuration avec le Consent Mode activé',
    icon: 'icon_v2_security',
    'googleconsentmode.display': true,
    'googleconsentmode.ads_data_redaction': true,
    'googleconsentmode.url_passthrough': true,
    recommended: true
  }
];

export default tcfTemplates;
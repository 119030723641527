import { cloneElement, useState, useRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  FloatingPortal,
  autoUpdate,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  FloatingFocusManager,
  useMergeRefs,
  useClick,
  useListNavigation
} from '@floating-ui/react';
import Icon from '@axeptio/design-system/build/src/Core/Icon';
import COLORS from '../../constants/colors';
import { useLocale } from '../../contexts/LocaleContext';

const ArrowIcon = styled(Icon)`
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.25s cubic-bezier(0.5, 0, 0, 1);
`;

const itemButtonActive = css`
  color: ${({ theme }) => theme.colors.primaryPalette.v500};
`;

const itemButtonInactive = css`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    ${ArrowIcon} {
      transform: translateX(0px);
      opacity: 1;
    }
  }
`;

const ItemButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 169px;
  padding: 15px 30px;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  border: none;
  background: none;
  text-align: left;
  border-radius: 10px;

  ${props => (props.active ? itemButtonActive : itemButtonInactive)}
`;

const Item = forwardRef(({ children, active, ...buttonProps }, ref) => {
  return (
    <ItemButton ref={ref} active={active} disabled={active} {...buttonProps}>
      {children}
      {active ? <Icon iconSize={15} name="Check" /> : <ArrowIcon iconSize={15} name="ArrowNext" />}
    </ItemButton>
  );
});

const cardStickToTop = css`
  border-radius: 0px 0px 10px 10px;
  clip-path: inset(0px -30px -30px -30px);
`;

const cardClosedAtTheTop = css`
  border-radius: 10px;
`;

const Card = styled.div`
  background: ${COLORS.WHITE};
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.1), 0px 2px 6px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  ${({ stickToTop }) => (stickToTop ? cardStickToTop : cardClosedAtTheTop)}
`;

export default function LocalePickerDropdown({ children, stickToTop = false }) {
  const { locale: currentLocale, setLocale } = useLocale();
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const { refs, context, floatingStyles } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 10000000
          });
        },
        padding: 10
      })
    ]
  });

  const listRef = useRef([]);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useRole(context),
    useDismiss(context),
    useClick(context),
    useListNavigation(context, { listRef, activeIndex, onNavigate: setActiveIndex, loop: true })
  ]);

  const referenceRef = useMergeRefs([refs.setReference, children.ref]);

  const localeLabels = {
    FR: 'Français',
    EN: 'English'
  };

  return (
    <>
      {cloneElement(children, { ref: referenceRef, ...getReferenceProps(children.props) })}

      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context}>
            <Card
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              onClick={() => setOpen(false)}
              stickToTop={stickToTop}
            >
              {Object.keys(localeLabels).map((locale, index) => (
                <Item
                  key={locale}
                  ref={node => (listRef.current[index] = node)}
                  active={currentLocale === locale}
                  tabIndex={activeIndex === index ? 0 : -1}
                  {...getItemProps({
                    onClick: () => setLocale(locale)
                  })}
                >
                  {localeLabels[locale]}
                </Item>
              ))}
            </Card>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}

const StyledLocaleButton = styled.button`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: 'Source Sans Pro';
  font-weight: 700;
  height: 100%;
  padding: 10px;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
  }
`;

export const LocaleButton = forwardRef((buttonProps, ref) => {
  const currentLocale = useLocale().locale;

  return (
    <StyledLocaleButton ref={ref} type="button" {...buttonProps}>
      <Icon iconSize={15} name="Planet" />
      {currentLocale?.toUpperCase() ?? ''}
    </StyledLocaleButton>
  );
});

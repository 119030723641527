const plans = [
  {
    name: 'agency_standard',
    title: 'Offre Agence Standard',
    description: 'Nombre de projets illimités.\nPersonnalisation graphique complète.\nHébergement des preuves de consentement sur les serveurs d\'Axeptio, cappé à 1 million de preuves de consentement par mois.\n',
    icon: 'cookie-BO-megaphone',
    yearlyPrice: 990,
    monthlyPrice: 99,
    isPlanActive: true,
    isPlanSlim: false
  }
];

export default plans;
import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Button from '../Button/Button';
import Assets from '../../services/Assets';
import { withLocaleContext } from '../../contexts/LocaleContext';
import { withProjectContext } from '../../contexts/ProjectContext';
import PublishModal from './PublishModal';
import WarningModalFreeTrialLimit from '../Modal/WarningModalFreeTrialLimit';
import { onGoingOrIncompleteStripeSubscriptionStatus, isFreeTrialSubscription } from '../../base/billingHelpers';
import { FloatingTooltip } from '../Tooltip/FloatingTooltip';
import NoConfigToPublishModal from './NoConfigToPublishModal';
import { withApiContext } from '../../contexts/ApiContext';
import { withTranslation } from 'react-i18next';

const PublishButtonStyle = styled.div`
  ${props =>
    props.absolute &&
    `
    position: absolute;
    top: 6px;
    right: 12px;
  `};

  display: inline-flex;

  .PublishButton_Action {
    display: flex;
    align-items: center;

    button:first-child ~ button {
      margin-left: 10px;
    }
  }

  ${media.lessThan('small')`
    .PublishButton__Services {
      display: none;
    }
  `};

  .PublishButton__Services {
    span {
      margin-right: 4px;
      float: left;
      display: block;
      height: 40px;
      width: 40px;
      background: url(${Assets.image('paint_icon_gray')}?w=40);
      transition: transform 0.5s ease-out, opacity 0.3s ease-out;
      transform: ${props => (props.confirm ? 'translateX(0)' : 'translateX(500px)')};
      opacity: ${props => (props.confirm ? 1 : 0)};
    }
  }
`;

async function fetchConfigCount(fetchConfigurations, api, projectId) {
  const services = ['cookies', 'tcf', 'processings', 'contracts'];
  const configs = (await Promise.all(services.map(service => fetchConfigurations(service).response))).flat();
  const contractsV2 = await api.client.get(`${api.baseURL}/content/contracts?data.projectId=${projectId}`);
  return configs.length + contractsV2?.data?.length || 0;
}

class PublishButton extends Component {
  state = {
    confirm: false,
    publishModal: false,
    freeTrialProjectsCount: null,
    openFreeTrialLimitModal: false,
    openNoConfigToPublishModal: false,
    configCount: null
  };

  async getFreeTrialProjectsCount() {
    const count = await this.props.api?.countFreeTrialProjects();
    this.setState({ freeTrialProjectsCount: count });
  }

  fetchConfigCount = async () => {
    const count = await fetchConfigCount(this.props.fetchConfigurations, this.props.api, this.props.projectId);
    this.setState({ configCount: count });
  };

  async componentDidMount() {
    await this.getFreeTrialProjectsCount();
    await this.fetchConfigCount();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.api?.user?._id !== prevProps.api?.user?._id) {
      await this.getFreeTrialProjectsCount();
      await this.fetchConfigCount();
    }
  }

  onClickPublish = () => {
    const { project, projectOrganization } = this.props;
    const subscription =
      project?.billing?.subscription || project?.thirdPartyBilling?.subscription || projectOrganization?.subscription;

    if (this.state.configCount === 0) {
      this.setState({ openNoConfigToPublishModal: true });
      return;
    }

    if (this.state.freeTrialProjectsCount === 1 && isFreeTrialSubscription(subscription)) {
      this.setState({ publishModal: true });
      return;
    }

    if (
      this.state.freeTrialProjectsCount >= 1 &&
      (isFreeTrialSubscription(subscription) ||
        (!onGoingOrIncompleteStripeSubscriptionStatus.includes(subscription?.status) &&
          !onGoingOrIncompleteStripeSubscriptionStatus.includes(projectOrganization?.subscription?.status)))
    ) {
      this.setState({ openFreeTrialLimitModal: true });
      return;
    }

    this.setState({ publishModal: true });
  };

  render() {
    const { absolute, small, locale, xsmall, disabled, api, project, projectId, projectOrganization, t } = this.props;
    const { openFreeTrialLimitModal, publishModal, openNoConfigToPublishModal, freeTrialProjectsCount, configCount } = this.state;

    const loading = freeTrialProjectsCount === null || configCount === null;

    return (
      <PublishButtonStyle absolute={absolute} confirm={this.state.confirm}>
        <div className="PublishButton_Action">
          <PublishModal open={publishModal} onClose={() => this.setState({ publishModal: false })} />

          <WarningModalFreeTrialLimit
            isOpen={openFreeTrialLimitModal}
            onClose={() => this.setState({ openFreeTrialLimitModal: false })}
            project={project}
            projectOrganization={projectOrganization}
            locale={locale}
            api={api}
          />

          {openNoConfigToPublishModal && (
            <NoConfigToPublishModal projectId={projectId} onClose={() => this.setState({ openNoConfigToPublishModal: false })} />
          )}

          <FloatingTooltip
            renderOpener={props => (
              <span {...props}>
                <Button
                  onClick={() => this.onClickPublish()}
                  primary
                  blackFont
                  small={small}
                  xsmall={xsmall}
                  disabled={disabled || loading}
                >
                  {t('publish_button_publish')}
                </Button>
              </span>
            )}
            content={configCount === 0 ? t('publish_button_no_configurations_tooltip') : null}
          />
        </div>
      </PublishButtonStyle>
    );
  }
}

export default withApiContext(withProjectContext(withLocaleContext(withTranslation()(PublishButton))));

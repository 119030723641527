const adminStrings = {
  cookie_widget_copyright: 'Consentements certifiés par',
  steps_title: 'Étapes',
  steps_add: 'Ajouter une étape',
  design_title: 'Design',
  design_main: 'Couleur principale',
  design_paint: 'Couleur des tâches',
  advanced_title: 'Configuration avancée',
  advanced_strings: 'Textes des boutons du widget',
  advanced_strings_desc: 'Vous pourrez modifier chaque texte et libellés des fenêtres de cookies.',
  advanced_settings: 'Intégration des cookies sur votre site',
  advanced_settings_desc: 'Permet de modifier le délai d\'ouverture et les conditions d\'apparition, la connexion avec Google Tag Manager ou la récupération des événements de consentement.',
  advanced_version: 'Gestion de la version',
  advanced_version_desc: 'Renommer cette version et configurer sa mise en ligne.',
  btn_previewChanges: 'Prévisualiser les changements',
  btn_save: 'Enregistrer',
  strings_loadPreset: 'Charger un pack de texte',
  strings_title: 'Modifier les textes',
  step_addStep: 'Nouvelle étape',
  step_name: 'Identifiant unique de l\'étape',
  step_title: 'Titre',
  step_policyURL: 'Lien vers la politique de confidentialité',
  step_topTitle: 'Sur-titre',
  step_subTitle: 'Sous-titre',
  step_message: 'Message',
  step_vendors: 'Cookies',
  step_addVendor: 'Ajouter un nouveau cookie',
  step_allowOptOut: 'Afficher le bouton "refuser" à cette étape',
  step_collapseVendors: 'Cacher les cookies pour les afficher au clic',
  step_showToggleAllSwitch: 'Ajouter une case à cocher pour tout cocher / décocher',
  step_image: 'Illustration',
  step_strings: 'Modifier les textes des boutons pour cette étape',
  step_save: 'Enregistrer',
  step_remove: 'Supprimer cette étape',
  vendorsList_title: 'Ajouter un nouveau cookie',
  vendors_list_policy_mention: 'Consulter la politique de confidentialité de',
  vendor_title: 'Intitulé du cookie',
  vendor_name: 'Nom unique du service',
  vendor_name_hint: 'Ce nom sera transmis à la page web hôte afin d\'activer le service lorsque l\'utilisateur aura donné son consentement',
  vendor_shortDescription: 'Description courte (une ligne)',
  vendor_longDescription: 'Description longue',
  vendor_domain: 'Nom de domaine du cookie',
  vendor_domain_hint: 'Cela nous permet d\'afficher un joli favicon !',
  vendor_save: 'Enregistrer',
  vendor_remove: 'Supprimer ce cookie',
  versions_title: 'Versions pour le projet',
  versions_addNew: 'Créer une nouvelle version',
  versions_addNew_desc: 'Vous pouvez créer plusieurs versions de configuration pour tester et mesurer leurs performances sur votre site.',
  version_name: 'Intitulé de la version',
  version_name_placeholder: 'Donnez un nom à cette version pour l\'identifier facilement',
  version_active: 'En ligne',
  version_distribution: 'Distribution',
  version_distribution_unit: '%',
  app_title: 'Bonjour et bienvenue !',
  app_intro: 'Chez Axeptio, on n\'est pas très fan des bandeaux cookies qui cachent la moitié de l\'écran ou qui sautent à la gorge des utilisateurs. C\'est pour ça qu\'on a développé une nouvelle façon de recueillir le consentement de vos utilisateurs en matière de cookies. ',
  login_title: 'Pour commencer, identifiez-vous avec votre compte Axeptio',
  login_email: 'Email',
  login_password: 'Mot de passe',
  services_title: 'Mes widgets',
  services_cookies_title: 'Cookies',
  services_cookies_description: 'Goûtez aux cookies par Axeptio : une approche fraîche et respectueuse de vos utilisateurs pour recueillir leur consentement en matière de cookies. Des idées simples (temporisation, transparence, style conversationnel) au service d\'une meilleure conversion.',
  services_privacypolicy_title: 'Politique de confidentialité',
  services_privacypolicy_description: 'Générez facilement votre politique de confidentialité grâce au formulaire simple et ludique conçu par notre avocat Maître Christophe Landat. Non seulement vous serez couverts, mais en plus vos utilisateurs vont enfin tout comprendre.',
  services_marketingconsents_title: 'Recueil des consentements marketing',
  services_marketingconsents_description: 'Newsletters, SMS, jeux concours... Mettez votre marketing au carré avec un recueil de consentement contextuel et strictement conforme aux exigences réglementaires. Cerise sur le gâteau, vous pouvez désormais gérer un consentement graduel, le "oui, mais..."',
  services_contracts_title: 'Documents et contrats',
  services_contracts_description: 'Conditions générales de vente ou d\'utilisation, accord de confidentialité, etc. Nous mettons à votre disposition un bloc de consentement conforme, intégrant une visionneuse de PDF et une signature électronique certifiée par notre partenaire DocuSign',
  services_portability_title: 'Portabilité des données à des partenaires',
  services_portability_description: 'Vous voulez partager des données à caractère personnel à des tiers ? Nous avons ce qu\'il vous faut ! Comme pour les consentements marketing, présentez à vos utilisateurs un encart rassurant et détaillé qui les rassure et les engage dans la portabilité de leurs données.',
  services_dpo_title: 'Délégué à la Protection des Données (DPO)',
  services_dpo_description: 'Créez une fiche d\'identification de votre délégué à la protection des données. Vous pourrez ensuite ajouter un encart sur votre site pour mettre en valeur votre mise en conformité RGPD et offrir un accès simple et direct pour vos utilisateurs.',
  dpo_fullname_label: 'Nom',
  dpo_organization_label: 'Nom de l\'entreprise / personne morale',
  dpo_title_label: 'Titre',
  dpo_email_label: 'Email',
  dpo_address_label: 'Adresse postale',
  dpo_telephone_label: 'Téléphone',
  dpo_bio_label: 'Biographie',
  dpo_bio_picture: 'Photo',
  integration_domains_newItem_title: 'Ajouter un nouveau domaine',
  integration_domains_newItem_description: 'Référencer le domaine sur lequel s\'installe Axeptio nous permet de contrôler l\'identité de la page appelante et ainsi nous assurer qu\'aucun usage abusif n\'est fait.',
  integration_sdk_title: 'Chargez le SDK Axeptio',
  integration_sdk_instructions: 'Pour intégrer Axeptio sur votre site, il suffit d\'ajouter ces quelques lignes de codes au pied de chacune de vos pages.',
  integration_domains_title: 'Sites & noms de domaines actifs',
  gdpr_automated_decisions_information_label: 'Information spécifique',
  gdpr_automated_decisions_information_help: 'Précisez ici à l\'utilisateur : \n- son droit de ne pas faire l\'objet d\'un traitement automatisé\n- son droit à demander des explications sur la raison du traitement\n- son droit à demander une intervention humaine sur les résultats générés par les algorithmes',
  gdpr_automated_decisions_title: 'Prise de décisions individuelles automatisées, y compris profilage',
  gdpr_automated_decisions_description: 'En tant que responsable du traitement des données personnelles, vous êtes tenu d\'informer l\'utilisateur lorsqu\'il fait l\'objet de décisions fondées exclusivement sur un traitement automatisé (comprendre logiciel).',
  gdpr_automated_decisions_contact_label: 'Personne ou service à contacter pour toute demande liée au profilage',
  gdpr_automated_decisions_contact_help: 'Indiquez la procédure ou les coordonnées permettant à l\'utilisateur de votre service de demander une intervention humaine et / ou d\'obtenir des informations sur le traitement automatisé dont ses données ont fait l\'objet.',
  gdpr_consent_withdraw_label: 'Droit au retrait du consentement préalablement donné',
  gdpr_datatypes_label: 'Nature des données personnelles traitées',
  gdpr_datatypes_disclaimer: 'Axeptio n\'autorise pas le recueil de données personnelles sensibles, comme la religion, les orientations politiques ou les données de santé.',
  gdpr_purpose_label: 'Finalité du traitement des données personnelles',
  gdpr_processors_label: 'Sous-traitants des données personnelles',
  gdpr_consent_duration_label: 'Durée de validité du consentement',
  gdpr_processing_duration_label: 'Durée de validité du traitement',
  strings_change_tone: 'Choisir un ton',
  strings_change_tone_placeholder: 'Sélectionner un preset',
  projects_create: 'Créer un nouveau projet',
  sign_out: 'Déconnexion',
  projects_title: 'Mes projets',
  cookies_categories_custom_title: 'Nouvelle catégorie personnalisée',
  cookies_categories_custom_description: 'Créer un nouvelle étape à partir de zéro.',
  cookies_vendors_new: 'Ajouter un nouveau cookie',
  cookies_vendors_edit: 'Modifier le cookie',
  cookies_vendors_new_title: 'Nouveau cookie',
  vendors_searchBox_placeholder: 'tapez le nom de l\'outil que vous recherchez',
  vendors_custom_title: 'Créer un cookie perso',
  vendors_custom_description: 'Vous ne trouvez pas le cookie dans la liste proposée ? Pas de soucis, cliquez ici pour en créer un "from scratch" !',
  vendors_addSelected: 'Ajouter les éléments sélectionnés',
  gdpr_processors_new_title: 'Nouveau sous-traitant',
  gdpr_processors_new_description: 'Ajouter un sous-traitant pour ce traitement de données à caractère personnel.',
  grpd_processors_help: 'Précisez ici les sociétés et les outils qui auront accès à ces données personnelles. Cela peut être l\'outil qui vous sert à envoyer les mails, ou encore une société que vous employez pour réaliser de la prospection téléphonique. Attention, cela ne vaut que pour ce traitement en particulier.',
  gdpr_storage_duration_label: 'Durée de conservation des données à caractère personnel',
  gdpr_storage_duration_url: 'https://docs.google.com/forms/d/e/1FAIpQLSd7EzCtVs1nhtVNKOQLspojWGLgGXSAK_-jUmChQiII4PCdcg/viewform',
  gdpr_storage_duration_title: 'Calculateur de durée de conservation des données personnelles',
  gdpr_processing_purpose: 'Finalité du traitement des données personnelles',
  embed_regex_help: 'Si vous souhaitez cibler plusieurs pages avec une même injection, vous pouvez utiliser une <a href="https://jbbarth.com/posts/2008-05-16-introduction-aux-expressions-regulieres.html" target="_blank" rel="noopener noreferrer">Expression Régulière</a>. Pour cela, écrivez votre expression régulière dans le champ URL et cochez la case RegExp. Une fois votre configuration déployé, le script d\'Axeptio évaluera cette expression régulière contre la valeur de <code>window.location</code>.',
  embed_regex_label: 'RegExp',
  embed_pages_placeholder: 'a-propos',
  embed_selector_label: 'Élément cible pour l\'injection (sélecteur CSS)',
  embed_selector_help: 'Afin d\'identifier l\'emplacement du widget sur la page, nous utilisons un sélecteur CSS qui permet de désigner un élément parent, voisin ou à remplacer sur lequel nous viendrons placer votre widget. Pour identifier le sélecteur adapté, vous pouvez par exemple utiliser l\'extension Chrome SelectorGadget qui vous permettra d\'identifier, sans rentrer dans le code de la page, le sélecteur optimal pour placer votre widget.',
  embed_injection_title: 'Injection du widget dans vos pages web',
  embed_pages_label: 'Liste des pages web sur lesquelles le widget doit apparaître',
  embed_injection_method: 'Méthode d\'injection du widget',
  embed_injection_method_first: 'à l\'intérieur de l\'élément cible, en premier',
  embed_injection_method_last: 'à l\'intérieur de l\'élément cible, en dernier',
  embed_injection_method_eq: 'à l\'intérieur de l\'élément cible, à une position déterminée (index)',
  embed_injection_method_before: 'avant l\'élément cible',
  embed_injection_method_after: 'après l\'élément cible',
  embed_injection_method_replace: 'remplacer l\'élément l\'élement cible',
  login_reset_password_success: 'C’est tout bon ! Un mail pour définir un nouveau mot de passe vient de vous être envoyé. À tout de suite !\'',
  login_reset_password_error: 'Un problème est survenu, vérifiez le mail indiqué ou contactez notre support en utilisant la bulle de chat.',
  login_error: 'Nous ne parvenons pas à vous identifier, merci de vérifier votre saisie',
  consent_buttons_action_accept: 'Acceptation totale',
  consent_buttons_action_partial: 'Acceptation partielle',
  consent_buttons_action_reject: 'Refus',
  consent_buttons_action_show: 'Voir le document',
  processings_content_title: 'Rédactionnel',
  processings_user_experience_title: 'Personnalisation de l\'expérience utilisateur',
  processings_gdpr_title: 'Détails du consentement, traitement des données personnelles',
  processings_email_form_title: 'Propriétés du champ Email',
  processings_form_text: 'Voici le code qui sera présent dans votre formulaire',
  processings_message_form_title: 'Propriétés du champ Message',
  processings_message_form_label: 'Taille du champ',
  processings_message_form_text: 'Voici le code qui sera présent dans votre formulaire',
  processings_time_of_day_title: 'Propriétés du champ Message',
  processings_time_of_day_form_text: 'Voici le code qui sera présent dans votre formulaire',
  processings_weekdays_title: 'Propriétés du champ Message',
  processings_weekdays_form_text: 'Voici le code qui sera présent dans votre formulaire',
  processings_augmented_consent_form_label: 'Label',
  processings_augmented_consent_form_control_label: 'Attribut "Name" du contrôle',
  processings_augmented_consent_form_control_text: 'Nom du champ qui sera ajouté dans votre formulaire au moment de l\'envoi de ce dernier.',
  processings_button_form_label: 'Texte du bouton',
  processings_button_form_action_label: 'Action',
  processings_button_form_title_label: '"Title" du bouton',
  processings_button_form_title_text: 'Petit texte affiché lorsque l\'utilisateur garde sa souris sur le bouton',
  processings_button_form_value_label: 'Valeur du bouton',
  processings_button_form_color_label: 'Couleur',
  processings_confirmation_editor_label: 'Titre',
  processings_confirmation_editor_message_label: 'Message',
  processings_confirmation_editor_back_button_label: 'Texte du bouton retour',
  contracts_editor_title_label: 'Titre',
  contracts_editor_message_label: 'Message',
  contracts_editor_is_required_label: 'Est requis',
  contracts_editor_is_required_text: 'Lorsque vous cochez cette case, Axeptio va ajouter au formulaire qui contient ce Widget une case à cocher marquée comme requise. S\'il s\'agit d\'un simple formulaire HTML, ce sont les navigateurs qui se chargeront d\'effectuer cette validation. S\'il s\'agit en revanche d\'un formulaire envoyé en Ajax, il vous faudra récupérer en Javascript l\'information de "coche" ou non depuis cet élément.',
  contracts_editor_dropzone_label: 'Ajouter un document',
  contracts_editor_buttons_label: 'Buttons',
  cookies_editor_title_label: 'Gestion de la version',
  cookies_editor_name_label: 'Cookies version',
  cookies_editor_step_wizard_name_label: 'Écrans informatifs',
  cookies_editor_step_wizard_category_card_label: 'Modèles de catégories',
  cookies_editor_step_wizard_main_step_title: 'Étapes principales',
  cookies_editor_step_wizard_many_cookie_step_title: 'Vous avez beaucoup de cookies ?',
  cookies_editor_step_wizard_many_cookie_step_text: 'Regroupez-les en differentes étapes',
  cookies_editor_step_wizard_other_cookie_step_title: 'Autres écrans',
  cookies_editor_step_wizard_other_cookie_step_text: 'Configurations plus avancées',
  cookies_editor_version_wizard_name_label: 'Nom de la version',
  cookies_editor_version_wizard_save_button: 'Confirmer',
  cookies_editor_settings_form_integration_title: 'Intégration du SDK Axeptio',
  cookies_editor_settings_form_integration_text: 'Pour intégrer les cookies sur votre site, il faut tout d\'abord intégrer le script Axeptio dans le pied de vos pages !',
  cookies_editor_settings_form_opening_title: 'Paramètres d\'ouverture',
  cookies_editor_settings_form_opening_label: 'Délai d\'apparition',
  cookies_editor_settings_form_opening_text: '<p>Indiquez ici combien de temps en millisecondes le script Axeptio va attendre avant d\'afficher les cookies à l\'utilisateur. <strong>Nous vous recommandons d\'attendre entre 4 et 6 secondes.</strong></p>',
  cookies_editor_settings_form_hide_button_label: 'Cacher le bouton Axeptio une fois les choix de l\'utilisateur confirmés',
  cookies_editor_settings_form_scroll_threshold_label: 'Hauteur de défilement à partir de laquelle les cookies sont présentés à l\'utilisateur',
  cookies_editor_settings_form_scroll_threshold_text: 'Axeptio interprète le scroll dans la page comme un témoignage d\'engagement de l\'utilisateur envers le contenu qu\'il consulte.',
  cookies_editor_settings_form_storage_key_title: 'Enregistrement des préférences utilisateur',
  cookies_editor_settings_form_storage_key_label: 'Clé de stockage des préférences utilisateurs',
  cookies_editor_settings_form_storage_key_text: 'Nom du cookie ou de la propriété dans laquelle Axeptio va enregistrer le choix de l\'utilisateur. Vous pouvez lui donner un nom particulier si vous souhaitez y accéder côté client en Javascript ou même côté serveur.',
  cookies_editor_settings_form_storage_type_label: 'Où enregistrer le choix de l\'utilisateur',
  cookies_editor_settings_form_storage_type_text: 'Vous pouvez choisir la manière dont vous souhaitez procéder à l\'enregistrement des préférences de vos utilisateurs en matière de cookies. <strong>Attention</strong>, le <code>sessionStorage</code>n\'est pas persistant.',
  cookies_editor_settings_form_gtm_label: 'Envoyer des événements Google Tag Manager',
  cookies_editor_settings_form_gtm_text: '<p>Si vous utilisez <a href="https://tagmanager.google.com" target="_blank" rel="noopener nofollow noreferrer" >Google Tag Manager</a>, Axeptio est capable d\'envoyer automatiquement des déclencheurs de type "Événement personnalisé" lorsque l\'utilisateur a validé ses choix ou lorsque des choix ont été préalablement enregistrés.</p><p>Axeptio va envoyer autant de déclencheurs que de services acceptés par l\'utilisateur. Le nom de chaque événement personnalisé sera de la forme suivante : <code>axeptio_activate_<strong>nomDuService</strong></code>.</p>',
  cookies_editor_settings_form_gtm_trigger_text: 'Voici les noms des événements personnalisés qui pourront être envoyés :',
  cookies_editor_step_form_message_label: 'Passer automatiquement au bout de ',
  cookies_editor_vendor_form_title_label: 'Titre',
  cookies_editor_vendor_form_name_label: 'Nom',
  cookies_editor_vendor_form_name_text: 'Ce nom sera reçu par votre page lorsque l\'utilisateur aura complété le processus de configuration. Attention, il ne peut pas contenir le symbole \'.\' (point)',
  cookies_editor_vendor_form_short_description_label: 'Description courte',
  cookies_editor_vendor_form_long_description_label: 'Description longue',
  cookies_editor_vendor_form_domain_label: 'Nom de domaine',
  cookies_editor_vendor_form_domain_text: 'Ce domaine va être utilisé pour aller chercher le favicon : ',
  cookies_editor_vendor_form_policy_url_label: 'URL vers la politique de confidentialité',
  cookies_editor_vendor_form_delete_button: 'Supprimer ce cookie',
  cookies_editor_vendor_form_save_button: 'Valider',
  cookies_editor_vendor_version_remove_button: 'Supprimer cette version',
  domain_check_domain_label: 'Nom de domaine',
  domain_check_error_text: 'Une erreur est survenue, merci de vérifier à nouveau',
  domain_check_installation_label: 'Vérifier l\'installation du SDK Axeptio',
  domain_check_installed_message: 'Le SDK est bien présent sur le site et le clientID est conforme.',
  domain_check_not_installed_message: 'Le SDK n\'a pas été trouvé sur le site.',
  domain_check_last_check_label: 'Dernière vérification : ',
  domain_check_checking_progress: 'Vérification en cours',
  domain_check_check_now: 'Vérifier maintenant',
  dpo_editor_page_title: 'Coordonnées',
  dpo_editor_fieldset_infos_title: 'Informations additionnelles',
  dpo_editor_textarea_label: 'Bio',
  dpo_editor_phone_label: 'Téléphone',
  dpo_editor_picture_label: 'Photo de profil',
  dpo_editor_fieldset_contact_title: 'Contact et réclamation',
  dpo_editor_claim_form_label: 'Autoriser la saisie d\'une réclamation',
  dpo_editor_contact_form_label: 'Afficher le formulaire de contact',
  dpo_editor_fieldset_rights_title: 'Exercice des droits',
  dpo_editor_subject_rights_label: 'Quelles procédures voulez-vous présenter à vos utilisateurs ?',
  dpo_rights_page_title: 'Utilisez les modèles pré-configurés...',
  dpo_rights_new_right_title: 'Ou partez de zéro',
  dpo_rights_title_label: 'Intitulé',
  dpo_rights_description_label: 'Description',
  embed_form_embed_list_title: 'Pages',
  embed_form_embed_list_css_target: 'Cible : ',
  embed_form_embed_list_css_method: 'Méthode : ',
  embed_form_injection_method_first: 'à l\'intérieur de l\'élément, au début',
  embed_form_injection_method_last: 'à l\'intérieur de l\'élément, à la fin',
  embed_form_injection_method_eq: 'à l\'intérieur de l\'élément, à un index spécifique',
  embed_form_injection_method_before: 'avant l\'élément',
  embed_form_injection_method_after: 'après l\'élément',
  embed_form_injection_method_replace: 'remplacer l\'élément',
  embed_form_injection_method_eq_label: 'Index de l\'élément',
  embed_form_injection_margins_title: 'Habillage CSS',
  embed_form_injection_margins_label: 'Marges',
  embed_form_injection_shadow_label: 'Ombre portée',
  embed_form_injection_minwidth_label: 'Width',
  embed_form_injection_minheight_label: 'Height',
  content_fieldset_title: 'Titre',
  content_fieldset_subtitle: 'Sous-titre',
  content_fieldset_message_label: 'Message',
  content_fieldset_image_chooser_label: 'Icône',
  content_fieldset_detail_link_text: 'Texte du lien vers les détails du consentement',
  dpo_fieldset_title_label: 'Titre ou intitulé du poste',
  dpo_fieldset_title_placeholder: 'Délégué à la protection des données',
  dpo_fieldset_fullname_label: 'Nom complet',
  dpo_fieldset_fullname_placeholder: 'Nom et prénom de votre DPO',
  dpo_fieldset_organization_label: 'Organisation / Entreprise',
  dpo_fieldset_organization_text: 'S\'il appartient à une autre entreprise (DPO externe)',
  dpo_fieldset_email_label: 'Email',
  dpo_fieldset_email_text: 'Adresse à laquelle seront envoyées les réclamations',
  dpo_fieldset_address_label: 'Adresse',
  embeddings_fieldset_title: 'Intégrations',
  embeddings_fieldset_name_label: 'Identifiant du widget',
  project_infos_name_label: 'Intitulé du projet',
  project_infos_name_text: 'Nom du site, nom du client, etc.',
  project_infos_website_url_label: 'Adresse URL principale du projet',
  user_experience_helpers_label: 'Le consentement augmenté, "Oui, mais..."',
  user_experience_buttons_label: 'Buttons',
  user_experience_consent_label: 'Écrans de confirmations',
  portability_editor_name_label: 'Nom du bloc',
  portability_editor_title_label: 'Intitulé',
  portability_editor_message_label: 'Explication',
  portability_editor_embed_list_label: 'Intégrations',
  portability_card_title: 'Encart de portabilité sans titre',
  processor_form_identifier_label: 'Identifiant sous-traitant *',
  processor_form_name_label: 'Nom du sous-traitant',
  processor_short_description_label: 'Description courte',
  processor_role_label: 'Rôle du sous-traitant',
  processor_role_text: 'Décrire ici la finalité pour laquelle les données personnelles de vos utilisateurs sont transmises à ce tiers. Si le sous-traitant souhaite réaliser des traitements sans rapport avec le traitement initial, il devra solliciter un nouveau consentement.',
  processor_is_global_label: 'S\'agit-il d\'une organisation internationale ?',
  processor_country_label: 'Pays',
  processor_country_other_label: 'Précisez',
  processor_protection_mechanism_label: 'Mécanismes de protections mis en œuvre',
  processor_is_privacy_shield_label: 'Cette organisation adhère-t-elle au Privacy Shield ?',
  processor_website_label: 'Site internet',
  project_design_widget_title: 'Widget Preview',
  project_design_widget_text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea quisquam repellat soluta! A ab aliquid, architecto autem consequuntur doloribus facere facilis hic incidunt maiores non, nostrum perferendis quas sit soluta.',
  project_design_widget_short_description: 'Éditeur de la solution Axeptio',
  project_design_widget_website_short_description: 'Lorem ipsum dolor sit amet',
  project_design_paint_hue_title: 'Couleurs',
  project_design_paint_hue_colors: 'Tâche de peinture',
  project_design_primary_colors: 'Couleur primaire et textes',
  project_design_secondary_buttons_colors: 'Boutons secondaires',
  project_design_primary_button_colors: 'Bouton principal',
  project_design_consent_button_colors: 'Bouton de consentement',
  project_design_project_infos_title: 'Informations du projet',
  project_design_dpo_title: 'Le Délégué à la Protection des Données personnelles (DPO)',
  project_integration_text: '<p>Pour intégrer Axeptio sur votre site, il faut ajouter ces quelques lignes de codes au pied de chacune de vos pages. Cela va permettre de charger le script Axeptio qui va ensuite s\'occuper de piloter l\'injection des widgets, l\'appararition des cookies, etc.</p>\n<p>Si vous ne savez pas comment procéder pour ajouter ce code à votre site, vous pouvez vous rendre sur notre guide&nbsp;:&nbsp;\n<a href="https://developers.axeptio.eu/" target="_blank" rel="noopener noreferrer">Intégration du script Axeptio</a></p>',
  project_integration_title: 'Enregistrement des preuves de consentement et identification de l\'utilisateur',
  project_integration_step_one: '<h4><strong>Étape 1</strong> Génération du token</h4>\n<p>Les preuves de consentement GDPR Axeptio sont rattachées à un identifiant unique, que nous appelons <code>token</code>. Par défaut, si vous ne spécifiez rien, Axeptio va générer un token aléatoire à chaque chargement de page.</p>\n<p>Lorsqu\'un de nos widget est affiché au sein d\'un formulaire de votre site, nous ajoutons automatiquement à ce formulaire un nouveau champ masqué, appelé <code>axeptio_token</code>, qui contient la valeur du token générée aléatoirement. Lorsque le formulaire est envoyé, le champ <code>axeptio_token</code> est envoyé à votre serveur.</p>',
  project_integration_step_two: '<h4><strong>Étape 2</strong> Enregistrement du token</h4>\n<p>Il convient ensuite d\'enregistrer chez vous, en même temps que les données du formulaire, le token associé au consentement, afin de démontrer, en cas de réclamation ou de contrôle, la validité du consentement donné.</p>\n<p>Afin de présenter à votre utilisateur un historique de son consentement, il vous faut préciser au script Axeptio le <code>token</code> que vous aurez préalablement associé à l\'utilisateur dans votre base de données. Cela se fait en passant l\'attribut <code>data-token</code> à la balise.&nbsp;</p>',
  project_integration_step_button: 'Montrez-moi !',
  project_integration_step_three: '<h4><strong>Étape 3</strong> Origine de l\'identifiant</h4>\n<p>Pour plus de praticité, vous avez la possibilité de passer à Axeptio un identifiant de votre utilisateur, comme une propriété <code>user_id</code>, un code client ou même une adresse email. L\'essentiel étant que cet identifiant soit absolument unique dans votre base de donnée.</p>\n<p>Dans le cas d\'un formulaire d\'inscription ou d\'un formulaire de passage de commande, il est très probable qu\'un identifiant soit généré immédiatement <b>après</b> l\'envoi du formulaire et que vous souhaitiez y rattacher l\'historique du consentement utilisateur. Axeptio met à disposition une API spécifiquement pour ce besoin, qui permet de réattribuer l\'historique associé à un token existant à un nouveau token.</p>',
  project_integration_token_recovery: '<h4>Récupération du token utilisateur dans la page</h4>\n<p>Dans l\'hypothèse où l\'identifiant de votre utilisateur est accessible directement dans la page, comme par exemple dans un menu "mon compte" ou une variable <code>user</code> dans votre page.</p>\n<p>Si vous souhaitez utiliser cet identifiant, plutôt que de le préciser côté serveur, vous pouvez utiliser les méthodes de récupération de token du SDK Axeptio.</p>',
  project_integration_recovery_content: '<ul>\n  <li>\n    <label>Récupérer le contenu d\'un élément</label>\n    <p>\n      <code>\n        <b>data-token</b>\n        ="innerHTML:header.main .user span.name"\n      </code>\n    </p>\n  </li>\n  <li>\n    <label>\n      Récupérer la valeur d\'un attribut d\'un élément\n    </label>\n    <p>\n      <code>\n        <b>data-token</b>\n        ="attr(value):input[type=\'hidden\'][name=\'username\']"\n      </code>\n    </p>\n  </li>\n  <li>\n    <label>\n      Accéder à une variable définie dans l\'object \n      <code>window</code>\n    </label>\n    <p>\n      <code>\n        <b>data-token</b>\n        ="getVar:myCMS.user.id"\n      </code>\n    </p>\n  </li>\n</ul>',
  project_invoices_card_title: 'Vous n\'avez pas encore de facture',
  project_invoices_widget_button_download: 'Télécharger',
  project_invoices_widget_button_pay: 'Payer',
  project_invoices_invoice_title: 'Facture N°',
  project_invoices_invoice_line_info: 'Période',
  project_invoices_invoice_date: 'Date',
  project_invoices_invoice_id: 'Identifiant',
  project_invoices_invoice_amount_due: 'Total',
  project_invoices_invoice_amount_paid: 'Réglé',
  project_invoices_invoice_amount_remaining: 'Montant restant dû',
  publish_button_confirm: 'Confirmer',
  publish_button_cancel: 'Annuler',
  publish_button_publish: 'Publier',
  publish_footer_explanation: 'Pour mettre en production les modifications apportées à votre projet, il vous faut publier votre configuration sur notre réseau de distribution de contenu. Seuls les services actifs dans votre formule seront publiés :\n',
  publish_footer_publish: 'Publier',
  publish_footer_activate: 'Activer le projet pour le publier',
  app_header_projects: 'Mes projets',
  project_users_invitation_label: 'Inviter un utilisateur',
  project_users_invitation_button: 'Envoyer',
  project_users_quit_button: 'Quitter ce projet',
  project_users_quit_confirm_text: 'Attention ! Quitter ce projet le fera disparaître de la liste de vos projets.',
  project_users_quit_confirm_text_02: 'Merci de bien vouloir confirmer votre action en tapant ce texte dans le champs ci-dessous:\n',
  project_users_quit_confirm_button: 'Je confirme que je souhaite quitter ce projet',
  project_users_quit_cancel_button: 'Annuler',
  subscription_monthly_price_label: '€ / mois',
  subscription_billing_services_title: 'Services',
  subscription_billing_details_title: 'Facturation',
  subscription_billing_title: 'Paiement',
  subscription_voucher_label: 'Coupon de réduction',
  subscription_voucher_text: 'Vous avez reçu un coupon de réduction ? Inscrivez le dans ce champ pour bénéficier de la ristourne 🥳',
  subscription_periodicity_title: 'Periodicité',
  subscription_periodicity_yearly: 'Abonnement annuel',
  subscription_periodicity_monthly: 'Abonnement mensuel',
  subscription_credit_card_label: 'Carte de paiement',
  subscription_credit_card_button: 'Supprimer la carte',
  subscription_credit_card_number: 'Numéro de la carte',
  subscription_credit_card_expiry: 'Expiration',
  subscription_credit_card_cvc: 'CVC',
  subscription_credit_card_postal_code: 'Code Postal',
  subscription_total_label: 'Récapitulatif du montant H.T. de votre abonnement.',
  subscription_periodicity_month: 'mois',
  subscription_periodicity_year: 'an',
  subscription_fisrt_step_button: '1/3 • Étape suivante',
  subscription_second_step_button: '2/3 • Étape suivante',
  subscription_billing_valid_button: 'Valider l\'abonnement',
  subscription_billing_valid_button_billing_infos: 'Entrer les informations de facturation',
  subscription_billing_valid_button_payment_method: 'Sélectionner une méthode de paiement',
  subscription_fisrt_step_alert: '1/3 • Sélectionnez au moins un service',
  subscription_second_step_alert: '2/3 • Veuillez compléter vos coordonnées de facturation',
  subscription_third_step_alert: '3/3 • Ajoutez un moyen de paiement',
  subscription_unsubscribe_from_all_services: 'Désactiver mon abonnement',
  project_modal_title: 'Félicitations, votre nouveau projet est créé !',
  project_modal_subtitle: 'Il faut maintenant installer notre "bout de code" sur votre site.',
  project_modal_description: 'Pensez à l\'installer également sur vos sites de développement ou de test ! Pour le pilotage des cookies, nous vous invitons à également installer',
  project_modal_close_button: 'C\'est bon pour moi !',
  project_services_title: 'Créer et modifier les widgets',
  project_configuration_title: 'Configuration',
  project_form_infos_title: 'Informations du projet',
  project_form_dpo_title: 'Le Délégué à la Protection des Données personnelles (DPO)',
  project_form_confirm_button: 'Confirmer la création du projet',
  project_form_saving_failed: 'La sauvegarde de votre projet a échoué',
  sdk_integration_token_title: 'Ceci est une valeur d\'exemple à modifier en fonction de votre intégration',
  sdk_integration_options_label: 'Charger une version particulière pour votre gestion des Cookies',
  sdk_integration_version_placeholder: 'Si vous laissez vide, la première version sera chargée',
  sdk_integration_user_token_label: 'Token utilisateur',
  sdk_integration_user_token_text: 'Le token est la clé cryptée qui nous permet d’identifier votre utilisateur. Il est utilisé pour les services suivants : traitement de données personnels, documents contractuels, portabilité des données.',
  sdk_integration_not_a_dev: 'Le code n\'est pas votre tasse de thé ?',
  sdk_integration_send_button: 'Envoyer une notice au dev',
  projects_filter: 'Filtrer les projets',
  auth_seperator_label: 'Ou',
  auth_fb_login_title: 'Facebook Login',
  auth_fb_login_description: 'Connexion avec votre compte Facebook',
  auth_intro_line: 'Bienvenue chez Axeptio',
  auth_email_label: 'Adresse email',
  auth_password_label: 'Mot de passe',
  auth_btn_login: 'Connexion',
  auth_signin_link_signup: 'Je veux créer un compte',
  auth_signin_link_resetpassword: 'Mot de passe oublié ?',
  auth_btn_resetpassword: 'Obtenir un lien de réinitialisation',
  auth_resetpassword_link_signin: 'J\'ai un compte, je veux me connecter',
  auth_resetpassword_link_signup: 'En fait je crois que je n\'ai pas de compte',
  auth_resetpassword_token: 'Entrez le code de vérification reçu par mail',
  auth_resetpassword_password: 'Mot de passe',
  auth_resetpassword_passwordrepeat: 'Mot de passe (encore une fois)',
  auth_resetpassword_update: 'Modifier mon mot de passe',
  auth_resetpassword_impossible: 'Impossible de changer votre mot de passe, car vous vous êtes connecté avec votre compte Google ou Facebook',
  auth_displayname_label: 'Votre nom',
  auth_lastname_label: 'Votre nom',
  auth_firstname_label: 'Votre prénom',
  auth_passwordrepeat_label: 'Mot de passe (encore !)',
  auth_btn_signup: 'Créer mon compte !',
  auth_signup_link_signin: 'Euh... je crois que j\'ai déjà un compte en fait.',
  auth_connect_with: 'Je préfère utiliser un de mes comptes',
  auth_password_wrong: 'Votre mot de passe doit contenir au moins 8 caractères dont au moins une majuscule, une minuscule, un chiffre et un de ces caractères spéciaux (!@#.$%^&*)',
  auth_marketing_consent_label: 'En cochant cette case, j\'accepte de recevoir de délicieuses informations croustillantes, tout droit sorties du four d\'Axeptio.',
  item_users_add: 'Collaborer',
  item_users_remove: 'Arrêter le partage',
  users_title: 'Utilisateurs',
  project_design_text_font_title: 'Police de caractère des textes',
  project_design_title_font_title: 'Police de caractère des titres',
  project_design_borderRadius_title: 'Border Radius (coins arrondis)',
  project_design_padding_title: 'Padding (marge intérieure)',
  project_design_load_font: 'Laisser Axeptio charger la police sur le site\n',
  project_design_body_size_title: 'Taille du corps',
  project_design_title_size_title: 'Taille des titres',
  project_design_load_font_help: 'Cochez cette case si vous choisissez une police qui n\'est pas déjà chargée sur la page.',
  consent_editor_string_widgetTitle: 'Titre du widget',
  consent_details_view_title: 'Détail du consentement',
  consent_details_card_title: 'Modifier le détail du consentement',
  consent_details_card_description: 'Finalité du traitement des données, sous-traitants, modalités de retrait de consentement, profilage, etc.',
  embeddings_customInputName_label: 'Attribut "name" custom',
  embeddings_customInputName_help: 'Vous pouvez personnaliser la valeur de l\'attribut "name" de la case à cocher cachée derrière le bloc Axeptio. Ainsi, lors du traitement de vos formulaires côté serveur, vous pourrez récupérer la valeur du consentement comme vous le souhaitez.',
  embed_form_boundInputSelector_label: 'Lier le consentement à une case à cocher existante',
  embed_form_boundInputSelector_help: 'Dans certains cas, vous souhaiterez utiliser une case à cocher existante dans la page dans laquelle s\'intègre notre widget. Cela peut notamment être pratique lorsque la case est ajoutée par un module tiers ou lorsque que des règles de validité du formulaire sont dépendantes de la valeur d\'une case précise. Copiez ici le sélecteur CSS pointant vers la case à cocher ou le bouton radio à actionner lorsque l\'utilisateur donne son consentement. Le consentement de l\'utilisateur sera alors répercuté automatiquement sur cette dernière ou ce dernier.',
  embed_form_boundInputSelector_placeholder: 'input[type="checkbox"][name="contact_form_newsletter"]',
  embed_form_elementsToHide_label: 'Éléments à cacher',
  embed_form_elementsToHide_help: 'Si vous avez choisi d\'utiliser des cases à cocher existantes pour faire transiter l\'information de consentement, ou alors si vous avez fait passer la valeur du token dans un champ que vous ne voulez pas montrer à l\'utilisateur, vous pouvez indiquer ici les sélecteurs CSS du ou des éléments qu\'Axeptio doit cacher lors de l\'injection du widget. Séparer les sélecteurs par des virgules.',
  embed_form_boundTokenSelector_label: 'Passer la valeur du token à un <code>&lt;input/&gt;</code> du site.',
  embed_form_boundTokenSelector_help: 'Par défaut, Axeptio va créer un champ caché nommé <code>"axeptio_token"</code> dans lequel il va renseigner le token utilisateur correspondant à la session. Ainsi, lorsque vous recevez les données du formulaire, vous recevez également dans vos variables POST une valeur pour la propriété <code>"axeptio_token"</code>. Si vous utilisez un form builder ou que vous avez besoin de faire transiter le token par un autre champ, vous pouvez utiliser cette fonctionnalité. En spécifiant ici le chemin CSS vers une balise de type <code>text</code> ou <code>hidden</code>, lors de la validation du consentement par l\'utilisateur, Axeptio mettra à jour la valeur du champ en spécifiant le token utilisé.',
  embed_form_boundTokenSelector_placeholder: 'input[name="token"]',
  project_design_button_style: 'Style du bouton',
  custom_button_label: 'Utiliser un bouton personnalisé',
  custom_button_image_size_label: 'Taille de l\'image',
  custom_button_label_name: 'Label',
  custom_button_icon: 'Icon',
  custom_button_padding_y: 'Hauteur du bouton (Padding Y)',
  custom_button_padding_x: 'Largeur du bouton (Padding X)',
  custom_button_font_size: 'Taille de la police (Font size)',
  custom_button_font_weight: 'Epaisseur de la police (Font weight)',
  custom_button_line_height: 'Interlignage (Line height)',
  custom_button_text_color: 'Couleur du texte',
  custom_button_bg_color: 'Couleur du fond',
  custom_button_bg_transparent: 'Utiliser un fond transparent',
  custom_button_box_shadow: 'Ajouter une ombre portée',
  custom_button_border_radius: 'Border radius',
  project_design_reset_colors: 'Réinitialiser les couleurs par défaut',
  project_design_widget_position_label: 'Position du widget',
  project_design_widget_position_left: 'À gauche',
  project_design_widget_position_right: 'À droite',
  project_design_widget_offsetY_label: 'Décalage vertical du bouton (offset Y)',
  project_design_widget_offsetX_label: 'Décalage horizontal du bouton (offset X)',
  embed_pages_empty_warning: 'Attention ! Cette intégration ne comporte aucune page. En l\'état, elle sera sans effet sur votre site. Cliquez sur le bouton + pour ajouter une page.',
  embeddings_javascriptIntegration_label: 'Intégration Javascript',
  embeddings_javascriptIntegration_help: 'Vous pouvez également "monter" le widget directement dans votre page en utilisant l\'API impérative. La méthode <code>mountWidget</code> accepte un objet composé des propriétés <code>service</code>, <code>name</code> ou <code>id</code> et <code>node</code>. L\'attribut <code>node</code> doit pointer vers l\'élément DOM dans lequel vous souhaitez injecter le widget. Dans le code ci-dessous, nous avons utilisé la méthode <code>getElementById</code>, mais vous pouvez par exemple utiliser un sélecteur jQuery à la place : <code>$(\'#my-element\').get(0)</code>.',
  project_consents_pagination_preferences_title: 'Préférences de l\'utilisateur',
  project_consents_pagination_headers_title: 'En-têtes de requête',
  project_consents_pagination_back_button: 'Précédent',
  project_consents_pagination_next_button: 'Suivant',
  project_consents_pagination_per_page: 'par page',
  project_consents_pagination_page: 'page',
  project_consents_token_label: 'Token',
  project_consents_service_label: 'Service',
  project_consents_service_processings: 'Traitement des données personnelles',
  project_consents_service_contracts: 'Documents contractuels',
  project_consents_service_portability: 'Partage des données',
  project_consents_widget_id: 'ID du widget',
  project_consents_filter_button: 'Filtrer',
  project_consents_table_th_uid: 'UID',
  project_consents_table_th_service: 'Service',
  project_consents_table_th_widget: 'WidgetID',
  project_consents_table_th_user_token: 'User Token',
  project_consents_table_th_date: 'Date',
  vendors_limit_description: 'Basculez sur une offre payante pour débloquer d\'autres services',
  vendors_limit_title: 'Nombre de services max atteint',
  change_strings_title: 'Textes du widget',
  service_limit_reached_title: 'Nombre maximum de widgets atteint',
  service_limit_reached_description: 'Pour créer d\'autres widgets, activez le service {SERVICE} dans la section Gestion de ma formule.',
  service_no_item_title: 'Pour obtenir le snippet à intégrer dans votre script, vous devez avoir au moins une configuration créée.',
  service_no_item_paragraph: 'Cela signifie que notre widget ne peut pas s\'afficher sur votre site pour le moment.',
  service_no_item_button: 'Créer une nouvelle configuration cookies',
  service_no_item_choose_a_config: 'Choisissez parmi les configurations existantes ci-dessous 👇🏻',
  service_no_item_or: 'Ou',
  dpo_free_limit_title: 'Ces fonctionnalités ne sont pas disponibles',
  dpo_free_limit_description: 'Pour les activer, souscrivez au service DPO dans la section Gestion de ma formule',
  change_strings_description: 'Vous pouvez modifier chaque texte et libellé des widgets',
  project_billing_title: 'Facturation',
  confirm_delete: 'Êtes-vous sûr de vouloir supprimer cet élément ? Sa suppression est définitive.',
  cookies_editor_settings_form_hide_button_help: 'Attention, cacher le bouton Axeptio vous oblige à placer un lien dans le footer de votre site pour que l\'utilisateur puisse rouvrir le widget à tout moment. Pour cela, vous pouvez par exemple utiliser le code suivant :',
  cookies_editor_settings_form_hide_button_a_content: 'Cliquez-ici pour modifier vos préférences en matière de cookies',
  cookies_editor_settings_ask_new_consent: 'Redemander le consentement si un nouveau vendor est présent dans le projet',
  project_color_label: 'Couleur principale du projet',
  cookies_editor_entitled_label: 'Intitulé',
  consent_widget_string_form_table_row_login: 'Identifiant',
  consent_widget_string_form_table_row_value: 'Valeur',
  subject_right_form_card_title: 'Nouveau droit',
  embed_form_new_page_label: 'Nouvelle page',
  embed_list_new_item_title: 'Ajouter ce widget sur mon site',
  embed_list_new_item_description: 'Vous pouvez charger ce widget dans la page de votre choix et à l\'endroit que vous voulez',
  embed_binding_parent_title: 'Liaison au formulaire parent',
  content_field_set_button_illustration_value_modify: 'Modifier l\'illustration',
  content_field_set_button_illustration_value_choose: 'Choisir une illustration',
  content_field_set_button_illustration_title: 'Modifier l\'illustration',
  button_form_value_none: 'Aucune',
  button_form_value_primary: 'Primaire',
  button_form_value_secondary: 'Secondaire',
  button_form_value_tertiary: 'Tertiaire',
  processings_time_of_day_options_value_morning: 'Matin',
  processings_time_of_day_options_value_lunch: 'Midi',
  processings_time_of_day_options_value_afternoon: 'Après-midi',
  processings_time_of_day_options_value_evening: 'Soirée',
  processings_weekdays_monday: 'L',
  processings_weekdays_tuesday: 'M',
  processings_weekdays_wednesday: 'M',
  processings_weekdays_thursday: 'J',
  processings_weekdays_friday: 'V',
  processings_weekdays_saturday: 'S',
  processings_weekdays_sunday: 'D',
  btn_save_and_close: 'Enregistrer et fermer',
  btn_confirm: 'Confirmer',
  btn_delete: 'Supprimer',
  gdpr_datatypes_warning: 'Attention ! ',
  gdpr_datatypes_content: 'Le recueil du consentement de l\'utilisateur doit être impérativement spécifique. Si vous déclarez plusieurs types de données personnelles différentes pour un même consentement, assurez-vous bien qu\'elles servent la même finalité de traitement.',
  gdpr_datatypes_data_input_placeholder: 'Nature de la donnée, par ex : nom, prénom, etc.',
  gdpr_datatypes_button_add: 'Ajouter',
  dropzone_drop_content: 'Déposer vos fichiers ici ou ',
  dropzone_drop_content_bold: 'sélectionnez un fichier',
  dropzone_added_label: 'Documents ajoutés',
  custom_strings_CookiesOptOutDisclaimer: 'Attention : avant de décocher cette case, assurez-vous que vos cookies répondent bien',
  custom_strings_CookiesOptOutDisclaimer_after_link: 'aux conditions réglementaires et légales rappelées par la CNIL',
  custom_strings_CGenericDisclaimer_content: 'Êtes-vous sûrs d\'avoir tenu compte de tous nos avertissements ?',
  custom_strings_CookiesDurationDisclaimer: 'Veuillez vous assurer que la durée de validité des cookies est conforme à la réglementation applicable.',
  custom_strings_CookiesDurationDisclaimer_1: 'La durée du consentement par défaut est de 6 mois mais peut être modifiée en suivant les instructions suivantes :',
  custom_strings_CookiesDurationDisclaimer_faq_link: 'https://support.axeptio.eu/hc/fr/articles/4402466807185',
  custom_strings_CookiesDurationDisclaimer_2: 'Si vous modifiez la durée du consentement, veuillez vous assurer que la durée de validité des cookies est conforme à la réglementation applicable.',
  custom_strings_GdprPurposeDisclaimer_alert: 'Vous êtes tenus de préciser les finalités du traitement qui doivent être "déterminées, explicites et légitimes et ne pas être traitées ultérieurement de manière incompatible avec ces finalités". Article 5.B.',
  custom_strings_GdprPurposeDisclaimer_title: 'Article 5 - Principes relatifs au traitement des données à caractère personnel',
  custom_strings_GdprPurposeDisclaimer_personal_data: 'Les données à caractère personnel doivent être :',
  custom_strings_GdprPurposeDisclaimer_personal_data_a: 'a) traitées de manière licite, loyale et transparente au regard de la personne concernée (licéité, loyauté, transparence);',
  custom_strings_GdprPurposeDisclaimer_personal_data_b: 'b) collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées ultérieurement d\'une manière incompatible avec ces finalités; le traitement\nultérieur à des fins archivistiques dans l\'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques n\'est pas considéré, conformément à l\'article 89, paragraphe 1, comme incompatible avec les finalités initiales (limitation des finalités);',
  custom_strings_GdprPurposeDisclaimer_personal_data_c: 'c) adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées (minimisation des données);',
  custom_strings_GdprPurposeDisclaimer_personal_data_d: 'd) exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude);',
  custom_strings_GdprPurposeDisclaimer_personal_data_e: 'e) conservées sous une forme permettant l\'identification des personnes concernées pendant une durée n\'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées; les données à caractère personnel peuvent être conservées pour des durées plus longues dans la mesure où elles seront traitées exclusivement à des fins archivistiques dans l\'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques conformément à l\'article 89, paragraphe 1, pour autant que soient mises en œuvre les mesures techniques et organisationnelles appropriées requises par le présent règlement afin de garantir les droits et libertés de la personne concernée (limitation de la conservation);',
  custom_strings_GdprPurposeDisclaimer_personal_data_f: 'f) traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d\'origine accidentelle, à l\'aide de mesures techniques ou organisationnelles appropriées (intégrité et confidentialité);',
  custom_strings_GdprPurposeDisclaimer_controller: 'Le responsable du traitement est responsable du respect du paragraphe 1 et est en mesure de démontrer que celui-ci est respecté (responsabilité).',
  custom_strings_GdprCustomTextDisclaimer_alert: 'Veillez à n\'utiliser que des',
  custom_strings_GdprCustomTextDisclaimer_alert_bold: '“termes compréhensibles et aisément accessibles et formulés en des termes clairs et simples”',
  custom_strings_GdprCustomTextDisclaime_title: 'Article 7 - Conditions applicables au consentement',
  custom_strings_GdprCustomTextDisclaime_1: 'Dans les cas où le traitement repose sur le consentement, le responsable du traitement est en mesure de démontrer que la personne concernée a donné son consentement au traitement de données à caractère personnel la concernant.',
  custom_strings_GdprCustomTextDisclaime_2: 'Si le consentement de la personne concernée est donné dans le cadre d\'une déclaration écrite qui concerne également d\'autres questions, la demande de consentement est présentée sous une forme qui la distingue clairement de ces autres questions, sous une forme compréhensible et aisément accessible, et formulée en des termes clairs et simples. Aucune partie de cette déclaration qui constitue une violation du présent règlement n\'est contraignante.',
  custom_strings_GdprCustomTextDisclaime_3: 'La personne concernée a le droit de retirer son consentement à tout moment. Le retrait du consentement ne compromet pas la licéité du traitement fondé sur le consentement effectué avant ce retrait. La personne concernée en est informée avant de donner son consentement. Il est aussi simple de retirer que de donner son consentement.',
  custom_strings_GdprCustomTextDisclaime_4: 'Au moment de déterminer si le consentement est donné librement, il y a lieu de tenir le plus grand compte de la question de savoir, entre autres, si l\'exécution d\'un contrat, y compris la fourniture d\'un service, est subordonnée au consentement au traitement de données à caractère personnel qui n\'est pas nécessaire à l\'exécution dudit contrat.',
  custom_strings_Article32Disclaimer_alert: 'Attention à respecter les exigences de l\'article 82  de la loi du 6 janvier 1978 en utilisant des formulations "claires et complètes".',
  custom_strings_Article32Disclaimer_legal_1: 'Tout abonné ou utilisateur d\'un service de communications électroniques doit être informé de manière claire et complète, sauf s\'il l\'a été au préalable, par le responsable du traitement ou son représentant :',
  custom_strings_Article32Disclaimer_legal_2: 'Ces accès ou inscriptions ne peuvent avoir lieu qu\'à condition que l\'abonné ou la personne utilisatrice ait exprimé, après avoir reçu cette information, son consentement qui peut résulter de paramètres appropriés de son dispositif de connexion ou de tout autre dispositif placé sous son contrôle.',
  custom_strings_Article32Disclaimer_legal_3: 'Ces dispositions ne sont pas applicables si l\'accès aux informations stockées dans l\'équipement terminal de l\'utilisateur ou l\'inscription d\'informations dans l\'équipement terminal de l\'utilisateur :',
  custom_strings_Article32Disclaimer_legal_4: 'Soit, a pour finalité exclusive de permettre ou faciliter la communication par voie électronique ;',
  custom_strings_Article32Disclaimer_legal_5: 'Soit, est strictement nécessaire à la fourniture d\'un service de communication en ligne à la demande expresse de l\'utilisateur.',
  custom_strings_Disclaimer: 'Attention à vos formulations. L\'information délivrée à l\'utilisateur doit toujours être "claire et complète" pour respecter les exigences légales.',
  credit_card_expires_label: 'Expires',
  billing_form_label_socialReason: 'Raison sociale',
  billing_form_label_name: 'Nom du contact',
  billing_form_label_email: 'Email de facturation',
  billing_form_label_address: 'Adresse',
  billing_form_label_postalCode: 'Code postal',
  billing_form_label_city: 'Ville',
  billing_form_label_country: 'Pays',
  consent_editor_title: 'Modifier les textes du widget',
  cookies_step_wizard_category: 'Catégorie vierge',
  project_design_card_example_title: 'Carte d\'exemple',
  project_design_card_example_description: 'Hello World',
  axeptio_consent_save: 'Consentement enregistré',
  axeptio_consent_consentID: 'ID consentement',
  axeptio_consent_personalID: 'ID personnel',
  sdk_integration_code_generate: 'Générer un token à chaque affichage',
  sdk_integration_code_server: 'Injecter le token côté serveur',
  sdk_integration_code_innerHTML: 'Récupérer le token à l\'intérieur d\'une balise HTML de la page',
  sdk_integration_code_attribute: 'Récupérer la valeur d\'un attribut d\'un élément',
  sdk_integration_code_variable: 'Accéder à une variable définie dans la page',
  project_integration_old_token: 'ancienToken',
  project_integration_new_token: 'nouveauToken',
  editable_list_new_item_title: 'Nouveau',
  editable_list_new_item_description: 'Ajouter un nouvel élément',
  project_design_style_title: 'Style du widget',
  project_design_typo_title: 'Typographie',
  font_chooser_category_label: 'Catégorie',
  font_chooser_family_label: 'Famille',
  font_chooser_variant_label: 'Variantes',
  font_chooser_custom_font_title: 'Nom de la police (font-family)',
  paint_hue_picker_option_color_chart: 'Nuancier',
  paint_hue_picker_option_sliders: 'Curseurs',
  paint_hue_label_color: 'Teinte',
  paint_hue_label_saturation: 'Saturation',
  paint_hue_label_brightness: 'Luminosité',
  widget_consent_certified: 'Consentements certifiés par',
  processing_widget_button_modify: 'Modifier mes choix',
  user_experience_fieldset_card_accept: 'Acceptation',
  user_experience_fieldset_card_partial_accept: 'Acceptation partielle',
  user_experience_fieldset_card_refusal: 'Refus',
  dpo_editor_add_right: 'Ajouter un droit',
  locale_FR: 'Français',
  locale_EN: 'Anglais',
  locale_DE: 'Allemand',
  locale_NL: 'Néerlandais',
  locale_ES: 'Espagnol',
  locale_PT: 'Portugais',
  locale_IT: 'Italien',
  CookiesCard_noTitle: 'Version sans titre',
  btn_close_2: 'Fermer',
  btn_close: 'Enregistrer et fermer',
  project_onboarding_title: 'Nouveau projet',
  project_onboarding_url_title: 'Quelle est l\'adresse de votre site ?',
  project_onboarding_next_button: 'Suivant',
  project_onboarding_button_save: 'Enregistrer les modifications',
  project_onboarding_button_saving: 'Enregistrement...',
  project_onboarding_integration_title: 'Intégration du module sur votre site',
  project_onboarding_integration_setup_01: 'Copiez/Collez ce script dans la balise',
  project_onboarding_integration_setup_02: 'de votre site',
  project_onboarding_cookies_title: 'Cookies',
  project_onboarding_cookies_description: 'Une solution innovante et respectueuse de vos utilisateurs',
  project_onboarding_forms_title: 'Formulaires',
  project_onboarding_forms_description: 'Des consentements granulaires qui évitent l\'effritement de vos bases de données.',
  project_onboarding_dpo_title: 'DPO',
  project_onboarding_dpo_description: 'Déployez facilement une fiche d\'identité ainsi qu\'un formulaire de contact pour isoler ces demandes spécifiques',
  project_onboarding_step_website: 'Adresse du site',
  project_onboarding_step_integration: 'Intégration',
  project_onboarding_step_config: 'Configuration',
  project_onboarding_language_title: 'Choisir la langue de votre projet ',
  project_onboarding_language_placeholder: 'Français, Anglais...',
  project_onboarding_language_available: 'Traductions disponibles',
  project_onboarding_language_others: 'Autres langues',
  embed_form_createButton_label: 'Insérer un bouton au lieu du widget',
  embed_form_createButton_help: 'Si l\'espace vous est compté, vous pouvez insérer dans votre site un bouton Axeptio qui vous permet d\'ouvrir le widget en surimpression sur la page, comme apparaîssent nos cookies.',
  embed_form_buttonText_label: 'Libellé du bouton',
  embed_form_addPage: 'Ajouter une page',
  embeddings_showAdvanced: 'Afficher les options d\'intégration avancées',
  embeddings_hideAdvanced: 'Cacher les options d\'intégration avancées',
  cookies_editor_entitled_placeholder: 'Par ex : Site vitrine Français, Blog, etc.',
  cookies_editor_name_placeholder: 'Par ex : site_v1, fr, blog_en',
  processings_weekdays_daysList_label: 'Liste des jours',
  processings_weekdays_daysList_day: 'Jour',
  processings_weekdays_daysList_displayed: 'Texte affiché',
  processings_weekdays_daysList_active: 'Actif',
  button_delete_service_item: 'Supprimer',
  button_confirmBox_confirm: 'Confirmer',
  button_confirmBox_cancel: 'Annuler',
  title_delete_service_item: 'Supprimer la configuration :',
  title_rename_service_item: 'Renommer la configuration :',
  title_duplicate_service_item: 'Dupliquer la configuration :',
  button_rename_service_item: 'Renommer',
  button_duplicate_service_item: 'Dupliquer',
  file_upload_comp_choose_file: 'Choisissez un fichier',
  file_upload_comp_upload_file: 'Importer le fichier',
  file_upload_comp_upload_files: 'Importer les fichiers',
  cookies_editor_settings_pages_label: 'Charger cette version sur les pages suivantes :',
  strings_change_tone_apply: 'Appliquer',
  strings_change_tone_title: 'Utiliser nos valeurs par défaut\n',
  embed_form_buttonText_placeholder: 'Si vous laissez vide, Axeptio utilisera le titre du bloc\n',
  embed_form_embed_list_displayedAs: 'Affiché comme :',
  embed_form_embed_list_displayedAs_button: 'bouton',
  embed_form_embed_list_displayedAs_widget: 'widget',
  embed_form_embed_list_display: 'Affichage',
  embed_form_embed_list_injection: 'Injection dans les pages',
  embed_form_tokenMethod_value: 'Récupérer la valeur d\'un champ du formulaire (email par ex.)',
  embed_form_tokenMethod_attr: 'Valeur d\'un attribut d\'une balise HTML dans la page',
  embed_form_tokenMethod_innerHTML: 'Récupération du contenu d\'une balise HTML',
  embed_form_tokenMethod_getVar: 'Lecture d\'une variable javascript dans la page',
  embed_form_token_label: 'Identification de l\'utilisateur',
  project_consents_download_button: 'Télécharger l\'intégralité des preuves de consentement',
  cookies_editor_settings_form_javascriptIntegration_label: 'Intégration "custom" en Javascript',
  cookies_editor_settings_form_javascriptIntegration_html: 'Un événement "cookies:complete" est déclenché au moment où un choix utilisateur est sauvegardé. Pour intercepter cet événement, utilisez la méthode <code>on</code>. L\'argument de cette fonction est un objet litéral composé qui contient en clé le nom des vendors et en value un booléen.',
  cookies_editor_settings_form_javascriptIntegration_comment: '// L\'objet choices contient le détail des choix réalisés par l\'utilisateur sous forme d\'un objet litéral',
  home_cookies_params: 'Gérez vos cookies',
  image_chooser_customImageUpload_locked: 'L\'upload d\'images personnalisées n\'est pas disponible dans l\'offre gratuite',
  image_chooser_customImageUpload_unlockButton: 'Débloquer la fonctionnalité',
  cookies_editor_language_label: 'Langue du widget',
  auto_save_saving: 'Enregistrement des modifications...',
  auto_save_saved: 'Modifications enregistrées',
  cookies_step_iab_title: 'Intégration IAB CMP Transparency Framework',
  cookies_step_iab_stub_title: 'CMP Stub',
  cookies_step_iab_stub_formText: 'Attention, il vous faut impérativement charger le "stub" au plus haut dans la balise &lt;head&gt; de votre document.',
  cookies_step_iab_consentString_title: 'Obtenir la consentString',
  cookies_step_iab_consentString_formText: 'Pour récupérer la consentString, vous pouvez écouter l\'événement cookies:consentString',
  cookies_step_iab_consentString_comment: 'Pass the consent string to your advertising partner',
  cookies_editor_saveConsent_label: 'Enregistrer la preuve du consentement dans l\'API Axeptio',
  step_disablePaint: 'Désactiver la tâche de peinture',
  step_image_pick: 'Ajouter une illustration',
  step_image_delete: 'Supprimer l\'illustration',
  step_image_change: 'Choisir une autre illustration',
  step_disablePaint_upgrade: 'Cette fonctionnalité n\'est pas disponible pour votre formule, cliquez ici pour la modifier.',
  access_upgrade_text: 'Cette fonctionnalité n\'est pas disponible dans votre formule.',
  access_upgrade_btn: 'Modifier ma formule',
  cookies_editor_empty_preview: '<p>Pour l\'instant votre widget Axeptio est vide.<p>\n<p>Afin de rendre les cookies plus digestes, nous avons choisi de découper l\'expérience utilisateur en autant d\'étapes.</p>\n<p>Dans la partie droite de l\'administration, sous le titre <b>étapes</b>, vous trouverez un bouton vous permettant d\'ajouter votre premier écran.</p>',
  subscription_upgrade_main_title: 'Débloquez instantanément toutes les fonctionnalités premium',
  subscription_upgrade_main_description: 'Vous intensifiez votre collecte et vos traîtements ? Basculez dès maintenant sur notre formule PRO pour modifier tout ce que vous voulez, et personnaliser nos widgets à vos couleurs.',
  subscription_upgrade_main_button: 'Mise à niveau vers la formule PRO',
  subscription_upgrade_first_mention: 'Démarrer un essai gratuit de 30 jours',
  subscription_upgrade_second_mention: 'Annulation à tout moment',
  subscription_upgrade_slide_01_title: 'Un widget complet et personnalisé pour vos cookies',
  subscription_upgrade_slide_01_image: 'icon-cookies',
  subscription_upgrade_list_item_01: 'Ajoutez autant de Cookies que vous le voulez.',
  subscription_upgrade_list_item_02: 'Rangez-les dans des étapes pour une meilleure digestion.',
  subscription_upgrade_list_item_03: 'Personnalisez la solution à vos couleurs.',
  gdpr_storage_duration_unit: 'mois',
  cookies_editor_settings_form_storage_duration_label: 'Durée de conservation du consentement',
  cookies_editor_settings_form_storage_duration_unit: 'Jours',
  project_design_consentButton_borderRadius_title: 'Border radius des boutons de consentement\n',
  cookies_editor_step_form_waitTimeout_label: 'Passer à cet écran après...',
  publish_error_freemium_title: 'Publication impossible',
  publish_error_freemium_description: '<p>Malheureusement la publication de votre configuration n\'a pu aboutir car les limites de votre offre ont été atteintes.</p><p>Si vous souhaitez la publier en l\'état, vous pouvez activer votre projet en cliquant sur le bouton en bas. Vous pouvez sinon revenir dans les limites de votre offre en rétablissant les éléments suivants.</p>',
  publish_error_freemium_subtitle: 'Vous avez dépassé les bornes des limites',
  project_design_reset_fonts: 'Réinitialiser les polices',
  account_info_title: 'Informations',
  account_identities_title: 'Authentifications',
  account_organizations_title: 'Mon compte agence',
  orgs_blling_info_title: 'Informations de facturation',
  orgs_users_title: 'Comptes Utilisateurs ',
  orgs_plan_title: 'Abonnement à l\'offre agence',
  orgs_plan_interval: 'Fréquence de facturation',
  orgs_plan_product: 'Type d\'offre',
  orgs_plan_payment_source: 'Mode de paiement',
  account_info_email: 'Email',
  account_info_lastName: 'Nom',
  account_info_firstName: 'Prénom',
  account_info_displayName: 'Nom visible',
  account_info_companyName: 'Nom de votre entreprise',
  orgs_plan_subscription_update: 'Modifier l\'abonnement',
  orgs_plan_subscription_end: 'Résilier l\'abonnement',
  orgs_plan_subscription_start: 'Souscrire',
  subscription_paymentType_sepa_debit: 'Prélévements SEPA (IBAN)',
  subscription_paymentType_card: 'Cartes de crédit',
  billing_form_label_vat: 'Numéro de TVA / Identifiant de Taxe',
  publish_error_errors: 'Anomalies détectées lors de la publication',
  cookies_create_vendor: 'Ajouter un nouveau cookie',
  subscription_payment_title: 'Moyens de paiement',
  subscription_services_title: 'Types de widgets actifs',
  subscription_plan_title: 'Découvrez nos saveurs',
  currency_eur: '€',
  vendor_select_customVendors: 'Les cookies que vous avez créés',
  vendor_select_standardVendors: 'Les cookies standards',
  project_onboarding_button_submit: 'Créer le projet',
  project_onboarding_name_title: 'Comment appeler ce projet ?',
  cookies_editor_projectDesign: 'Personnalisez la solution à vos couleurs.',
  cookies_editor_projectIntegration: 'Intégrez sur votre site',
  all_in_one_wizard_main_title: 'Pour quelles finalités souhaitez-vous\nrécolter des consentements ?',
  all_in_one_wizard_main_text_0: 'L’utilisation et le traitement de données personnelles doivent s’inscrire dans un but précis.\n',
  all_in_one_wizard_main_text_1: 'La finalité doit être déterminée, légitime et explicite.',
  all_in_one_wizard_add_channel_button: 'Ajouter un canal',
  all_in_one_wizard_settings_button: 'Paramétrer',
  all_in_one_wizard_card_title_0: 'Newsletter',
  all_in_one_wizard_card_text_0: 'Je veux pouvoir inscrire de nouveaux abonnés dans ma liste de diffusion',
  all_in_one_wizard_card_title_1: 'SMS',
  all_in_one_wizard_card_text_1: 'Je veux pouvoir envoyer des SMS à des prospects',
  all_in_one_wizard_card_title_2: 'Phoning',
  all_in_one_wizard_card_text_2: 'Je veux pouvoir appeler mes prospects',
  all_in_one_wizard_card_title_3: 'Magazine papier',
  all_in_one_wizard_card_text_3: 'Je veux pouvoir envoyer du courrier postal à mes prospects',
  all_in_one_wizard_card_title_4: 'Notifications',
  all_in_one_wizard_card_text_4: 'Je veux pouvoir rendre visite à mes propects',
  all_in_one_wizard_card_title_5: 'Réseaux sociaux',
  all_in_one_wizard_card_text_5: 'Je veux que mes clients me suivent sur les réseaux sociaux',
  step_sameWeightForAcceptAndRejectButtons: 'Utiliser la même mise en forme pour les boutons accepter et refuser',
  nav_agencyButton: 'Agence',
  content_fieldset_name: 'Intitulé',
  empty_items: 'Aucun élément ici',
  project_dropdown_id: 'Identifiant du projet',
  project_dropdown_id_copy: 'Copier',
  project_dropdown_id_copied: 'Copié',
  project_dropdown_duplicate_project: 'Dupliquer le projet',
  project_dropdown_add_group_project: 'Ajouter à un groupe',
  project_dropdown_manage_group_project: 'Gérer les groupes',
  project_id_explainer: '<div>',
  project_design_reset_all: 'Tout réinitialiser',
  cookies_editor_settings_steps_label_1: 'Intégration du SDK',
  cookies_editor_settings_steps_label_2: 'Paramètre d\'ouverture',
  cookies_editor_settings_steps_label_3: 'Synchronisation',
  cookies_editor_settings_link_label: 'Liens vers la documentation',
  cookies_editor_settings_form_link_next_button: 'Étape suivante',
  cookies_editor_settings_form_link_doc_button: 'Voir la documentation détaillée',
  cookies_editor_settings_form_sync_label: 'Synchroniser les événements utilisateurs',
  cookies_editor_settings_form_sync_text: '<p>Votre widget est prêt. Mais pour que notre solution prenne véritablement le contrôle sur vos cookies, il reste une petite étape que vous pouvez réaliser de 2 façons. Si vous n\'avez pas de développeur sous la main, on vous conseille d\'utiliser Google Tag Manager (Outil 100% gratuit proposé par Google : <a href={"https://tagmanager.google.com"} target="_blank" rel="noreferrer noopener" >tagmanager.google.com</a>)</p>',
  cookies_editor_settings_form_select_button_left: 'Google Tag Manager',
  cookies_editor_settings_form_select_button_right: 'Intégration custom',
  vendors_select_button_custom: 'Mes cookies',
  vendors_select_button_chat: 'Chat en ligne',
  vendors_select_button_testing: 'A/B testing',
  vendors_select_button_monitoring: 'Monitoring',
  vendors_select_button_marketing: 'Marketing',
  vendors_select_button_crm: 'CRM',
  vendors_select_button_email: 'Emailing',
  vendors_select_button_other: 'Autres',
  vendors_link_find_cookies: 'Besoin d\'aide pour identifier les cookies utilisés sur votre site ?',
  billing_error_title: 'Oups !',
  billing_complete_message: '<p>\n  Votre paiement est bien passé et votre abonnement a bien été débloqué.<br/>\n  Vous pouvez dès à présent profiter des fonctionnalités Premium.<br/>\n  Toute l\'équipe vous remercie pour votre confiance.\n</p>',
  billing_error_message: 'Veuillez réessayer en vérifiant si tous les champs sont bien\ncomplétés et si vos informations de facturation sont correctes.\n<span>\n  Si ça ne fonctionne toujours pas, contactez notre équipe en utilisant le module de chat positionné en bas à droite de votre fenêtre.\n</span>',
  billing_modal_button: 'Compris !',
  select_language_placeholder: 'Sélectionnez la langue de cette configuration',
  project_design_disableScrollHijack_label: 'Ne pas intercepter le scroll de la page',
  project_design_disableScrollHijack_help: 'Par défaut, Axeptio va intercepter le scroll natif du navigateur web afin de pouvoir rester au premier plan et ne pas créer d\'affichages perturbant les internautes. Dans certains cas cette fonctionnalité peut gêner la navigation. En cochant cette case vous la désactivez.',
  billing_form_label_TaxId: 'Ajouter un numéro de TVA',
  subscription_taxIncluded: 'TTC',
  subscription_taxExcluded: 'HT',
  subscription_cancel_subscription: 'Résilier l\'abonnement',
  subscription_edit_subscription: 'Valider la modification',
  subscription_existingSourceExplainer: 'Vous avez un moyen de paiement défini sur cet abonnement. Il\n              est enregistré par notre sous-traitant Stripe et sera utilisé\n              pour vous prélever les futures échéances.',
  subscription_changeSource_label: 'Pour modifier le moyen de paiement pour cet abonnement, renseignez un nouveau numéro dans le formulaire en dessous.',
  subscription_isProfessional_yes: 'Professionnel',
  subscription_isProfessional_no: 'Particulier',
  billing_form_label_companyName: 'Raison sociale',
  billing_form_label_isProfessional: 'Vous êtes',
  subscription_edit_customer: 'Modifier les informations de facturation',
  billing_form_label_individualName: 'Nom et Prénom',
  billing_form_label_taxRate: 'TVA',
  textAlign_left: 'Gauche',
  textAlign_right: 'Droite',
  textAlign_center: 'Centré',
  cookies_editor_titleTextAlign_label: 'Alignement des titres',
  cookies_editor_contentTextAlign_label: 'Alignement du contenu',
  project_design_boxShadow_default: 'Ombre par défaut',
  project_design_boxShadow_none: 'Pas d\'ombre',
  project_design_boxShadow_custom: 'Ombre personnalisée (CSS)',
  project_design_colorPicker_submit: 'Valider',
  project_design_boxShadow_label: 'Ombre portée',
  subscription_card_period_label: 'Période en cours',
  subscription_card_period_from: 'Du',
  subscription_card_period_to: 'au',
  subscription_card_amount_label: 'Montant HT',
  subscription_card_status_label: 'Statut',
  subscription_taxIds_placeholder: 'Vous n\'avez pas encore renseigné de numéro de TVA. On sait que c\'est toujours un peu relou d\'aller chercher l\'info, mais en vrai si vous êtes en Europe et que vous renseignez votre TVA intracommunautaire, vous pourrez récupérer la TVA sur le montant de votre abonnement, vu qu\'on applique la TVA Française à 20%.',
  subscription_changeSource_button: 'Modifier le mode de paiement',
  project_onboarding_organization_title: 'Organisation à laquelle appartient le projet',
  organizationSelect_emptyOption: 'Aucune, c\'est un projet indépendant',
  organizationSelect_placeHolder: 'Choisissez une organisation existante',
  project_organization_label: 'Organisation à laquelle appartient le projet',
  subscription_congrats_title: 'Bien joué !',
  subscription_congrats_subtitle: 'Vous faites partie du club maintenant ',
  subscription_congrats_paragraph: 'Nous sommes contents et fiers que vous nous ayez choisi. Normalement tout est bon. Si vous avez des modifications qui n\'ont pas pu être publiées, c\'est le moment de le faire :',
  subscription_congrats_spread_title: '🥰 Partagez votre amour des cookies 🥰 ',
  subscription_congrats_spread_description: 'Saviez-vous que vous pouviez aider votre réseau à se mettre en conformité tout en restant cool ? Il suffit de leur parler de nous, pardi !',
  subscription_congrats_spread_tweet_text: 'On a choisi @axeptio_eu pour mettre notre site au carré',
  subscription_congrats_spread_tweet_link_text: 'Je fais un tweet',
  subscription_congrats_spread_tweet_hashtags: 'rgpd,cookies,ux',
  subscription_congrats_spread_facebook_link_text: 'Je "like" ou "follow" la page',
  subscription_congrats_spread_facebook_page: 'https://facebook.com/axeptio',
  subscription_congrats_link_project: 'Retour à mon projet',
  subscription_congrats_link_project_design: 'Modifier l\'apparence des widgets',
  subscription_congrats_link_documentation: 'Aller lire la documentation',
  subscription_congrats_link_documentation_url: 'https://developers.axeptio.eu/v/francais/',
  subscription_congrats_spread_tweet_url: 'https://axeptio.eu',
  integration_copiedCode: 'Copié',
  integration_copyCode: 'Copier',
  integration_code_title: 'Notre "bout de code" à intégrer dans votre site ',
  auth_country_label: 'Pays',
  'integration_docURL_all-integrations': 'https://support.axeptio.eu/hc/fr/sections/24131788478737-Plugins-et-Integration',
  'integration_docURL_big-commerce': 'https://support.axeptio.eu/hc/fr/articles/17629787618321-Integration-Axeptio-dans-un-projet-BigCommerce',
  integration_docURL_bubble: 'https://support.axeptio.eu/hc/fr/articles/24851749428241-Plugin-Axeptio-Bubble',
  integration_docURL_drupal: 'https://support.axeptio.eu/hc/fr/articles/17627389096209-Plugin-Axeptio-Drupal',
  'integration_docURL_google-tag-manager': 'https://support.axeptio.eu/hc/en-gb/articles/4404040466321-Tag-Manager-Interface',
  integration_docURL_hubspot: 'https://support.axeptio.eu/hc/fr/articles/24449020823825-Int%C3%A9gration-Axeptio-Hubspot',
  integration_docURL_joomla: 'https://support.axeptio.eu/hc/fr/articles/17629983128081-Int%C3%A9gration-Joomla',
  integration_docURL_magento: 'https://support.axeptio.eu/hc/fr/articles/22046907336849-Plugin-Axeptio-Magento',
  integration_docURL_prestashop: 'https://support.axeptio.eu/hc/fr/articles/17618369976337-Plugin-Axeptio-Prestashop',
  integration_docURL_shopify: 'https://support.axeptio.eu/hc/fr/articles/17628559386641-Plugin-Axeptio-Shopify',
  integration_docURL_squarespace: 'https://support.axeptio.eu/hc/fr/articles/17629521827345-Int%C3%A9gration-avec-Square-Space',
  integration_docURL_typeform: 'https://developers.axeptio.eu/integration/integration-cms/typeform',
  integration_docURL_webflow: 'https://support.axeptio.eu/hc/fr/articles/24851994775953-Plugin-Axeptio-Webflow',
  integration_docURL_wix: 'https://support.axeptio.eu/hc/fr/articles/21894790192785-Int%C3%A9gration-Axeptio-Wix',
  integration_docURL_wordpress: 'https://support.axeptio.eu/hc/fr/articles/17616260428561-Plugin-Axeptio-Wordpress',
  axeptio_support_request_link: 'https://meetings.hubspot.com/thomas-angot/set-up',
  axeptio_support_docs_link: 'https://support.axeptio.eu/hc/fr/articles/22570846852753-Installation-du-Google-Consent-Mode-v2',
  'integration_cms_all-integrations': 'Plugins et Intégration',
  'integration_cms_big-commerce': 'Big Commerce',
  integration_cms_bubble: 'Bubble',
  integration_cms_drupal: 'Drupal',
  'integration_cms_google-tag-manager': 'Google Tag Manager',
  integration_cms_hubspot: 'HubSpot',
  integration_cms_joomla: 'Joomla',
  integration_cms_magento: 'Magento',
  integration_cms_prestashop: 'Prestashop',
  integration_cms_shopify: 'Shopify',
  integration_cms_squarespace: 'Squarespace',
  integration_cms_typeform: 'Typeform',
  integration_cms_webflow: 'Webflow',
  integration_cms_wix: 'Wix',
  integration_cms_wordpress: 'Wordpress',
  embed_form_checkboxText_label: 'Texte de la case à cocher',
  embed_form_checkboxText_placeholder: 'J\'accepte de recevoir...',
  embed_form_checkboxDetailsText_label: 'Texte du lien vers le widget',
  embed_form_checkboxDetailsText_placeholder: 'En savoir plus',
  embed_form_displayAs_widget: 'Widget',
  embed_form_displayAs_button: 'Bouton',
  embed_form_displayAs_checkbox: 'Checkbox',
  embed_form_displayAs_label: 'Mode d\'affichage',
  project_users_delete_modal_title: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
  project_users_delete_button: 'Supprimer',
  project_users_cancel_delete_button: 'Annuler',
  about_us_base: 'Texte de base',
  about_us_trusted3rdParty: 'Tiers de confiance',
  about_us_trusted3rdPartyWithoutImage: 'Tiers de confiance - Sans image',
  about_us_label: 'À propos d\'Axeptio',
  about_us_description: 'Écran qui apparaît lorsque l\'utilisateur intéragit avec notre mention',
  step_image_dimensions: 'Dimensions',
  step_image_dimensions_width: 'Largeur',
  step_image_dimensions_height: 'Hauteur',
  account_info_country: 'Pays',
  account_info_company: 'Entreprise',
  account_info_email_disclaimer: 'Pour modifier l\'email rattaché à votre compte, merci de contacter notre support technique.',
  cookies_editor_specialSteps_title: 'Écrans spéciaux',
  project_design_overlayBackdrop_opacity_label: 'Opacité du fond de l\'overlay',
  project_design_overlayBackdrop_style_label: 'Style du remplissage de l\'overlay',
  project_design_overlayBackdrop_style_radial: 'Radial',
  project_design_overlayBackdrop_style_solid: 'Couleur unie',
  step_special_apparition_title: 'Apparition',
  step_special_showWhenUserClicksOnALink: 'Afficher après un clic sur un lien',
  step_special_showAfterScroll: 'Afficher après une distance de scroll',
  step_special_showAfterDelay: 'Afficher après un délai',
  unit_seconds: 'secondes',
  step_apparition_title: 'Apparition',
  step_publishStep: 'Publier cet écran',
  project_form_featureFlags_title: 'Fonctionnalités à découvrir (et tester)',
  project_form_featureFlags_description: 'Attention, ces fonctionalités vous sont proposées en accès anticipé. Elles sont susceptible d\'évoluer dans leur fonctionnement ou leur tarification.',
  step_special_showAfterPopState: 'Afficher après un événement d\'historique (window.onpopstate)',
  project_onboarding_duplicate_title: 'Dupliquer',
  project_onboarding_duplicate_warning: 'Ce projet sera créé à partir de',
  subscription_per_domain: 'par domaine',
  subscription_choose_plan: 'Choisir ce plan',
  step_welcome_showContinueWithoutConsent: 'Afficher un bouton "Continuer sans accepter" au dessus du widget',
  step_acceptAndRejectButtons: 'Actions d\'acceptation / refus / fermeture',
  subscription_choosen_plan: 'Plan actuel',
  subscription_summary: 'Récapitulatif de paiement',
  project_design_widget_section_vendors: 'Liste de tiers (cookies ou sous-traitants)',
  project_design_widget_consentButton: 'Bouton de consentement',
  project_design_widget_cards: 'Cartes',
  project_design_widget_discardButton: 'Fermer le widget',
  project_design_discardButton_borderRadius_title: 'Coins arrondis du bouton fermer',
  button_togglePublish_service_item_published: 'Dépublier',
  button_togglePublish_service_item_unpublished: 'Publier',
  subscription_cancel_subscription_title: 'Êtes-vous sûr de vouloir résilier votre abonnement ?',
  project_invoices_invoice_vat: 'TVA',
  project_invoices_invoice_excvat: 'HT',
  project_invoices_invoice_incvat: 'TTC',
  project_invoices_invoice_payment: 'Paiement',
  project_invoices_invoice_payment_allpaid: 'Soldée',
  project_invoices_invoice_payment_unpaid: 'A solder',
  subscription_billing_invoices_list: 'Mes factures',
  project_consents_download_daterange: 'Télécharger les preuves de consentement',
  project_consents_download_selected_daterange: 'Télécharger sur cette période',
  step_welcome_continueWithoutConsentShouldRejectCookies: '"Continuer sans accepter" vaut refus des cookies',
  project_consents_daterange: 'Période',
  support_btn: 'Support',
  subscription_no_item: 'Aucun abonnement',
  subscription_no_item_description: 'Tous les abonnements disponibles sont visibles en haut de cette page.',
  gdpr_storage_duration_text: 'La durée de conservation des données peut être: imposée par la loi, recommandée par une autorité de contrôle (ex:  <a href="https://www.cnil.fr/fr/les-durees-de-conservation-des-donnees" target="_blank" rel="noreferrer noopener">CNIL</a>), ou choisie par vos soins en veillant à ne pas dépasser une durée de conservation raisonnable.',
  gdpr_datatypes_help: 'Est une donnée à caractère personnel toute donnée permettant d\'identifier directement ou indirectement un résident de l\'espace économique européen (UE, Islande, Liechtenstein et Norvège). Dans le cas d\'un formulaire, il s\'agit des champs que vous demandez à vos utilisateurs de remplir',
  gdpr_consent_withdraw_help: 'Indiquez ici la procédure qui permet à votre utilisateur de retirer son consentement. Attention, le consentement doit pouvoir être retiré aussi facilement qu\'il a été donné.',
  subscription_cancel_subscription_cancel: 'Annuler',
  processor_country_gdpr: 'Ce pays est soumis au RGPD',
  processor_country_adequacy_decision: 'Ce pays bénéficie d\'une décision d\'adéquation de la Commission Européenne (garantie équivalente au RGPD).',
  subscription_periodicity_yearly_mobile: 'Annuel',
  subscription_periodicity_monthly_mobile: 'Mensuel',
  subscription_sidebar_missing_infos: 'Il nous manque encore quelques informations:',
  subscription_form_errors_companyName: 'Nom et Prénom',
  subscription_form_errors_email: 'Adresse email',
  subscription_form_errors_address: 'Adresse postale',
  subscription_form_errors_postalCode: 'Code postal',
  subscription_form_errors_paymentCard: 'Carte bleue',
  project_stats_modal_title: 'Les statistiques... et tac !',
  project_stats_modal_text: '\n        <p><em>\n          Ça fait longtemps qu\'on travaille dessus, et au moins aussi longtemps\n          que vous nous l\'avez demandé ! C\'est officiel, c\'est en production,\n          désormais vous retrouverez dans votre console d\'administration les\n          statistiques de consentement et de visites des sites sur lesquels vous\n          avez installé nos petits cookies.</em>\n        </p>\n        <h2>Comment ça marche ?</h2>\n        <p>\n          Concernant les métriques de consentement, nous analysons les preuves\n          que nous stockons pour vous dans notre registre de preuves de\n          consentement. Cette analyse consiste à recouper, heure par heure, et\n          dimension par dimension les informations d\'acceptation, de refus et de\n          configuration. Cela vaut également pour le recueil des consentements\n          marketing.\n        </p>\n        <p>\n          Pour vos statistiques de visites, nous agrégeons les journaux\n          anonymisés de notre CDN et comptabilisons les visiteurs uniques et\n          récurrents en nous appuyant sur les statuts HTTP des requêtes. De\n          plus, le script Axeptio que vous chargez sur votre site va adjoindre\n          un paramètre r=0 ou r=1 à la requête chargeant votre configuration en\n          fonction de la présence d\'un consentement existant ou non sur le\n          navigateur de l\'internaute.\n        </p>\n        <h2>\n          Est-ce que c\'est un traceur, et dois-je rajouter Axeptio dans la liste\n          de mes cookies ?\n        </h2>\n        <p>\n          Non. Nous n\'individualisons pas les requêtes et n\'utilisons aucune\n          technologie de tracking. Nous ne sommes pas en mesure de retracer un\n          parcours utilisateur, seulement identifier quelles visites sont des\n          premières visites et lesquelles sont des visites suivantes.\n        </p>',
  project_stats_modal_showButton: 'À propos des statistiques',
  project_stats_modal_title_v2: 'Les statistiques... et tac !',
  project_stats_modal_text_v2: '\n        <p><em>Ça fait longtemps qu\'on travaille dessus, et au moins aussi longtemps que vous nous l\'avez demandé ! C\'est officiel, c\'est en production, désormais vous retrouverez dans votre console d\'administration les statistiques de consentement et de visites des sites sur lesquels vous avez installé nos petits cookies.</em>\n        </p>\n        <h2>Comment ça marche ?</h2>\n        <p>Concernant les métriques de consentement, nous analysons les preuves que nous stockons pour vous dans notre registre de preuves de consentement. Cette analyse consiste principalement à recueillir les informations d\'acceptation, de refus et de consentement partiel. Cela vaut également pour le recueil des consentements marketing.\n        </p>\n        <p>\n          Pour vos statistiques de visites, nous n\'individualisons pas les requêtes et n\'utilisons aucune technologie de tracking. Vous n\'avez donc pas besoin de lister Axeptio parmi votre liste de cookies.\n        </p>\n',
  project_stats_kpi_newVisitor: 'Premières visites',
  project_stats_kpi_returningVisitor: 'Visites suivantes',
  project_stats_kpi_visitor: 'Pages vues',
  project_stats_kpi_optInRate: 'Tx. Acceptation',
  project_stats_kpi_interactionRate: 'Tx. Interaction',
  project_stats_dailyOverview: 'Aperçu journalier',
  project_stats_consentDistribution: 'Répartition des consentements',
  project_stats_consentDistributionByDevice: 'Consentements / Terminal',
  project_stats_visitsByHosts: 'Pages vues / Nom d\'hôte',
  project_stats_devicesDistribution: 'Répartition des terminaux',
  project_stats_optInRateByDevice: 'Tx. Acceptation par terminal',
  project_stats_optInRateByConfig: 'Tx. Acceptation par configuration',
  project_stats_metrics_newVisitor: 'Première visite',
  project_stats_metrics_returningVisitor: 'Visite suivante',
  project_stats_metrics_nonInteractiveReturningVisitor: 'Visite suivante sans consentement',
  project_stats_metrics_accept: 'Acceptation',
  project_stats_metrics_reject: 'Refus',
  project_stats_metrics_partial: 'Acceptation partielle',
  project_stats_metrics_bot: 'Bot',
  project_stats_dimension_device: 'Terminal',
  project_stats_dimension_host: 'Nom d\'hôte',
  project_stats_dimension_config: 'Configuration',
  project_stats_dimension_browser: 'Navigateur',
  project_stats_dimension_collection: 'Type de consentement',
  project_stats_dimension_os: 'Système d\'exploitation',
  project_stats_filter_from: 'Du',
  project_stats_filter_to: 'Au',
  project_stats_filter_period: 'Période',
  project_stats_filter_dimension: 'Filtres',
  step_welcome_discardConsentExpiration: 'Mémorisation du refus utilisateur',
  step_welcome_discardConsentExpiration_page: 'Pour la page en cours',
  step_welcome_discardConsentExpiration_session: 'Pour la session en cours',
  step_welcome_discardConsentExpiration_days: 'Pour un certain nombre de jours',
  step_welcome_discardConsentExpiration_sameAsAccept: 'Comme pour l\'acceptation',
  step_welcome_discardConsentExpirationDays_label: 'Pendant combien de temps',
  unit_days: 'Jours',
  project_stats_interactionRateByHosts: 'Tx. Interaction par nom de domaine',
  project_stats_interactionRateByDevice: 'Tx. Interaction par type de device',
  project_stats_dailyRates: 'Évolution du taux de consentement',
  project_stats_dailyVisitsOverview: 'Premières visites vs. Visites suivantes',
  subscription_active: 'Activé',
  subscription_canceled: 'Annulé',
  subscription_legacy_plan: 'Ancienne formule',
  subscription_legacy_plan_description: 'Des formules plus récentes que la vôtre sont disponibles.<br/>\nCes dernières comportent <strong><em>peut-être</em></strong> de nouvelles fonctionnalités.<br/>\nRetrouvez et comparez nos offres actuelles ci-dessous.',
  edit: 'Modifier',
  select_all: 'Tout',
  vendors_table_editor: 'Editeur',
  vendors_table_icon: 'Icône',
  vendors_table_category: 'Catégorie',
  vendors_table_created_by: 'Créé par',
  vendors_table_language: 'Langue',
  vendors_table_go_to_page: 'Aller à la page',
  vendors_table_show: 'Afficher',
  vendors_table_page_of: 'sur',
  project_main_title: 'Essentiel',
  step_welcome_showDiscardButtonOnDesktop: 'Afficher le bouton "Continuer sans accepter" sur desktop',
  user_invitation_advices: 'Vous pouvez inviter plusieurs personnes à la fois en séparant les mails par un ;<br>\nVous pouvez aussi préciser un nom avant en encadrant le mail par des <><br>\nex: <code>Marc Dupont &lt;md118@example.com&gt;; hddp@example.net; Pete &lt;Pete@example.org&gt;</code><br>\nVous pouvez réinviter un utilisateur en renseignant à nouveau son email.',
  wrong_email_address_format: 'Addresse(s) mail(s) mal formatées',
  invitation_sent_projects: 'Le ou les utilisateurs concernés vont recevoir un mail les invitant à créer un compte pour rejoindre le projet.',
  invitation_sent_organizations: 'Le ou les utilisateurs concernés vont recevoir un mail les invitant à créer un compte pour rejoindre votre organisation.',
  organization_users_quit_button: 'Quitter cette organisation',
  organization_users_quit_confirm_text: 'Attention ! En quittant cette organisation, vous n\'y aurez plus accès depuis l\'interface.',
  organization_users_quit_confirm_button: 'Je confirme que je souhaite quitter cette organisation',
  stripe_card_holder: 'Nom du porteur',
  stripe_card_holder_placeholder: 'Marcel Patulacci',
  subscription_incomplete: 'Incomplete',
  subscription_subtotal: 'Sous total',
  subscription_form_errors_cardHolder: 'Nom du porteur',
  invitation_sent_title: 'Votre invitation a bien été envoyée ! ',
  errors_auth_mail_already_exists: 'Ce mail est déjà utilisé',
  selected_cookies_config_name: 'Identifiant de la version de configuration de cookies sélectionnée',
  errors_version_id_already_exists: 'Cet identifiant existe déjà, veuillez en choisir un autre',
  user_menu_administration: 'Administration',
  project_delete_button: 'Supprimer le projet',
  project_delete_confirm_text: 'Attention ! Vous êtes sur le point de supprimer définitivement ce projet. Cette opération est irréversible et il ne nous sera pas possible de le restaurer en cas d\'erreur.',
  project_delete_cancel_subscription_confirm_text: 'Votre abonnement actif sera également résilié.',
  project_delete_confirm_text_02: 'Merci de bien vouloir confirmer votre action en tapant ce texte dans le champs ci-dessous:\n',
  project_delete_confirm_button: 'Je confirme que je souhaite supprimer ce projet',
  project_delete_confirm_cancel_subscription: ' et résilier l\'abonnement actif',
  subscription_voucher_button: 'Appliquer',
  modal_leave_confirm_title: 'Êtes-vous vraiment sûr ?',
  modal_leave_project_confirm_value: 'quitter-ce-projet',
  modal_delete_project_confirm_value: 'supprimer-ce-projet',
  modal_leave_organization_confirm_value: 'quitter-cette-organisation',
  consent_wall_policy_url_label: 'Lire la politique de confidentialité',
  project_stats_upgrade_modal_title: 'Découvrez nos saveurs',
  project_stats_upgrade_modal_subtitle: 'Démarrez gratuitement et passez sur la formule pro quand vous en ressentirez le besoin !',
  paid_feature_only_text: 'Cette fonctionnalité n\'est disponible qu\'avec nos formules payantes',
  project_stats_go_premium_title: 'Fonctionnalité payante',
  project_stats_reset_filters_button: 'Réinitialiser les filtres',
  contact_sales_modal_title: 'Veuillez contacter votre conseiller commercial',
  contact_sales_modal_description: 'Désolé, cette fonctionnalité n\'est pas disponible avec votre formule',
  contact_sales_modal_button_label: 'Nous contacter',
  project_stats_statsAllowedAggregateBy: 'Les statistiques sont aggrégées par',
  hour: 'heure',
  day: 'jour',
  week: 'semaine',
  month: 'mois',
  year: 'année',
  subscription_taxId_not_valid: 'Oups ! On dirait que votre numéro de TVA intracommunautaire est invalide !',
  billing_form_button_addTaxId: 'Ajouter',
  billing_form_button_removeTaxId: 'Supprimer',
  subscription_active_description: 'L\'abonnement est en règle et le paiement le plus récent a été effectué avec succès.',
  subscription_trialing_description: 'L\'abonnement est actuellement en période d\'essai et vous pouvez configurer votre produit en toute sécurité pour votre client. L\'abonnement passe automatiquement à actif lorsque le premier paiement est effectué.',
  subscription_incomplete_description: 'Le paiement a échoué lorsque vous avez créé l\'abonnement. Un paiement réussi doit être effectué dans les 23 heures pour activer l\'abonnement.',
  subscription_incomplete_expired_description: 'Le paiement initial de l\'abonnement a échoué et aucun paiement réussi n\'a été effectué dans les 23 heures suivant la création de l\'abonnement.',
  subscription_past_due_description: 'Le paiement sur la dernière facture a échoué ou n\'a pas été tenté.',
  subscription_canceled_description: 'L\'abonnement a été annulé.',
  subscription_unpaid_description: 'La dernière facture n\'a pas été payée mais l\'abonnement reste en place. La dernière facture reste ouverte et les factures continuent d\'être générées mais les paiements ne sont pas tentés.',
  subscription_contact_support: 'Si le problème persiste, veuillez <a href="https://support.axeptio.eu/" target="_blank">contacter le support technique</a>.',
  vendors_search_bar_placeholder: 'cookies disponibles',
  vendors_search_bar_placeholder_text: 'Rechercher un cookie',
  project_stats_statsPeriod: 'Période filtrable',
  project_stats_statsPeriod_lastWeek: 'Semaine dernière',
  project_stats_statsPeriod_lastMonth: 'Mois dernier',
  project_stats_statsPeriod_lastYear: 'Année dernière',
  project_stats_statsPeriod_allTime: 'Pas de limite',
  vendor_delete_confirmation_text: 'Souhaitez vous vraiment supprimer ce vendor ?',
  subscription_custom_project_product: 'Offre Entreprise sur mesure',
  subscription_custom_project_product_description: 'Votre abonnement est une offre qui a été faite sur mesure pour vos besoin, différente de nos abonnements standards ci-dessous',
  subscription_voucher_invalid: 'Coupon invalide',
  billing_form_label_required: 'requis',
  dropdown_account: 'Mon compte',
  dropdown_menu_title: 'Menu',
  subscription_custom_plan_label: 'Plan personnalisé',
  project_name_missing: 'Merci de renseigner un nom pour ce projet',
  project_language_missing: 'Merci de choisir une ou plusieurs langues pour votre projet',
  stats_limit_on_config_filter: 'Fitrer les configurations de cookies ne nous permet pas d\'afficher certaines données (notamment les données relatives aux visites)',
  project_stats_optInRate: 'Taux d\'acceptation',
  subscription_stripe_error_label: 'Erreur liée à Stripe',
  subscription_stripe_error_code: 'Code erreur',
  subscription_plan_chooser_disabled_contact_sales: 'Le changement d\'abonnement est désactivé car vous bénéficiez d\'un abonnement personnalisé. Si vous souhaitez le changer, vous pouvez contacter notre <a href="https://www.axeptio.eu/contact" target="_blank" rel="noopener nofollow noreferrer">service commercial</a>.',
  project_design_enableScrollHijack_label: 'Intercepter le scroll de la page',
  project_design_enableScrollHijack_help: 'Par défaut, Axeptio ne va pas intercepter le scroll natif du navigateur web afin de pouvoir continuer à "scroller" normalement sur le site. En cochant cette case vous désactivez le scroll du site lorsque Axeptio est ouvert.',
  payment_sepa_mandate: 'Mandat',
  subscription_invalid_email: 'E-mail invalide',
  subscription_form_errors_IBAN: 'IBAN',
  add_organization: 'Ajouter l\'organisation',
  stripe_add_credit_card: 'Ajoutez un moyen de paiement',
  step_welcome_discardConsentPosition: 'Position du bouton',
  step_welcome_discardConsentPosition_top: 'Au dessus',
  step_welcome_discardConsentPosition_inner: 'À l\'intérieur',
  home_search_no_result: 'Désolé, on n\'a rien trouvé !',
  unknown_card_language: 'Langue inconnue',
  btn_saving: 'Enregistrement...',
  subscription_billing_credit_notes_list: 'Mes avoirs',
  project_credit_notes_credit_note_title: 'Avoir N°',
  project_consents_service_cookies: 'Gestion des cookies',
  billingform_stripecustomerfound_title: 'On s\'est déjà vu quelque part ?',
  billingform_stripecustomerfound_subtitle: 'Grâce à votre email, nous avons retrouvé ces informations de facturation dans vos autres projets/organisations. Souhaitez-vous les réutiliser, ainsi que vos moyens de paiement ?',
  billingform_stripecustomerfound_button_approve: 'Utiliser ces valeurs existantes',
  billingform_stripecustomerfound_button_cancel: 'Refuser',
  subscription_unlimited_projects: 'Projets illimités',
  subscription_max_projects: 'Projets max',
  contractsEditor_confSettings: 'Réglages de la configuration',
  contractsEditor_confSettings_language: 'Langue',
  contractsEditor_createVersion_btn: 'Créer une nouvelle version',
  contractsEditor_confSettings_title: 'Titre',
  contractsEditor_confSettings_description: 'Description',
  contractsEditor_confSettings_rename: 'Renommer cette configuration',
  contractsEditor_confSettings_delete: 'Suppression de la configuration',
  contractsEditor_confSettings_delete_btn: 'Supprimer pour de vrai',
  contractsEditor_sectionSettings: 'Réglages de la section courante',
  contractsEditor_sectionSettings_title: 'Titre de la section',
  contractsEditor_sectionSettings_title_help: 'Ce titre sera affiché dans la table de matières ainsi que dans les liens internes',
  contractsEditor_sectionSettings_name: 'Nom technique de la section',
  contractsEditor_sectionSettings_name_help: 'Utilisé pour naviguer au sein du widget',
  contractsEditor_sectionSettings_displayInToC: 'Afficher dans la table des matières',
  contractsEditor_sectionSettings_delete: 'Supprimer la section courante',
  contractsEditor_sectionSettings_delete_btn: 'Supprimer pour de vrai',
  contractsEditor_blockSettings: 'Réglages du bloc sélectionné',
  contractsEditor_blockSettings_delete: 'Supprimer',
  contractsEditor_sections: 'Sections',
  contractsEditor_sections_reorder_help: 'Pour réordonner les sections, glisser et déposer les éléments de la liste',
  contractsEditor_sections_addNew: 'Ajouter une section',
  contractsEditor_strings: 'Textes du widget',
  contractsEditor_strings_availableVariables: 'Variables disponibles',
  contractsEditor_users: 'Utilisateurs',
  versioningModal_levels_major_title: 'Changements majeurs ',
  versioningModal_levels_major_text: 'Vous avez procédé à des changements radicaux qui nécessitent de redemander le consentement à tous les utilisateurs pour le document dans son intégralité.',
  versioningModal_levels_minor_title: 'Changements localisés',
  versioningModal_levels_minor_text: 'Si vous avez apportés quelques légères modifications et souhaitez présenter aux utilisateurs une vue leur présentant les ajouts, suppressions et modifications.',
  versioningModal_levels_patch_title: 'Changements cosmétiques',
  versioningModal_levels_patch_text: 'Choisissez cette option si vous avez procédé à des modifications de mise en forme qui n\'altèrent pas la structure ou la compréhension du contrat.',
  versioningModal_title: 'Déployer une nouvelle version',
  versioningModal_versionName: 'Nom de la version à publier',
  versioningModal_versionName_help: 'Nous vous invitons à donner un nom signifiant à cette version. Vous pouvez utiliser ce champ pour refléter votre propre système de versions.',
  versioningModal_level: 'Importance des changements',
  versioningModal_requiresConsent: 'Requiert un nouveau consentement',
  versioningModal_createVersion_btn: 'Créer la version VERSION_NUMBER',
  contractsEditor_export_sections_json: 'Exporter les sections en JSON',
  contractsEditor_import_sections_json: 'Importer les sections en JSON',
  contractsEditor_import_sections_json_wrong_type: 'Vous devez fournir un fichier JSON',
  contractsEditor_import_sections_validation_error: 'Erreur de validation de structure de vos sections',
  contractsEditor_import_sections_reading_error: 'Erreur de lecture du fichier',
  contractsEditor_import_sections_syntax_error: 'Erreur de syntaxe dans le JSON',
  contractsEditor_import_docx: 'Importer un document Word (.docx)',
  contractsEditor_import_docx_wrong_type: 'Vous devez fournir un fichier .docx',
  contractsEditor_doc_link: 'Acceder à la documentation',
  contractsEditor_docx_template: 'Modèle d\'un document Word (.docx)',
  contractsEditor_import_sections_reading_error_docx: 'Votre docx doit être structuré avec des titres pour analyser correctement ses sections',
  subscription_create_customer: 'Enregistrer les informations de facturation',
  subscription_create_customer_cancel_button: 'Annuler',
  stripe_sepa_debit_holder: 'Nom du porteur',
  subscription_createSource_button: 'Créer le mode de paiement',
  subscription_createSource_button_card: 'Ajouter une nouvelle carte de paiement',
  subscription_createSource_button_sepa: 'Ajouter un nouvel IBAN',
  subscription_createCard_label: 'Ajouter une nouvelle carte de paiement',
  subscription_deleteSource_tooltip: 'Supprimer ce moyen de paiement',
  subscription_deleteSource_confirmation: 'Voulez vous vraiment supprimer ce moyen de paiement?',
  subscription_deleteSource_confirmation_button: 'Voulez vous vraiment supprimer ce moyen de paiement?',
  subscription_SetSourceAsSubscriptionDefault_tooltip: 'Définir comme moyen de paiement par défaut pour cet abonnement',
  subscription_createSepaDebit_label: 'Ajouter un nouvel IBAN',
  subscription_SetSourceAsCustomerDefault_tooltip: 'Définir comme moyen de paiement par défaut pour ces informations de facturation',
  subscription_form_errors_paymentSource: 'Moyen de paiement',
  contractsEditor_nextSection_button: 'Section suivante',
  contractsEditor_prevSection_button: 'Section précédente',
  contractsEditor_EditableBlocks_title: 'Titre',
  contractsEditor_EditableBlocks_spacer: 'Espacement',
  contractsEditor_EditableBlocks_linkToSection: 'Lien vers section',
  contractsEditor_EditableBlocks_image: 'Image',
  contractsEditor_EditableBlocks_richText: 'Texte riche',
  contractsEditor_blockSettings_hasBackground: 'Avec fond',
  contractsEditor_importSections_title: 'Cet import va écraser vos sections en cours. Souhaitez-vous continuer ?',
  contractsEditor_importSections_cancel: 'Annuler',
  contractsEditor_importSections_confirm: 'Confirmer l\'import',
  publishModal_title: 'Options de publication',
  publishModal_warning_vendors: 'Attention : vous êtes sur le point de publier un bandeau sans aucun cookie renseigné',
  publishModal_warning_not_published: 'Attention : vous êtes sur le point de publier une bannière qui n\'est pas marquée comme publiée',
  publishModal_no_cookies: 'Aucun cookie renseigné',
  publishModal_explanations: 'Avant de publier une nouvelle version de votre configuration Axeptio, merci de vérifier les paramètres suivants et assurez vous d\'avoir sélectionné les versions souhaitées. Cochez les documents que vous voulez déployer, et cliquez sur Publier dès que vous êtes prêts.',
  publishModal_projectSettings: 'Paramètres du projet',
  publishModal_publishDesign_title: 'Publier les changements de design',
  publishModal_publishDesign_text: 'Vous avez modifié le design de votre projet, des couleurs ou des polices? Cochez cette case pour les publier avec cette version.',
  publishModal_publishDpo_title: 'Publier les changements du DPO',
  publishModal_publishDpo_text: 'Vous avez modifié des informations sur votre DPO et souhaitez mettre à jour son widget ? Cocher cette case pour les publier avec cette version.',
  custom_strings_Article32Disclaimer_legal_1_1: 'De la finalité de toute action tendant à accéder, par voie de transmission électronique, à des informations déjà stockées dans son équipement terminal de communications électroniques, ou à inscrire des informations dans cet équipement ;',
  custom_strings_Article32Disclaimer_legal_1_2: 'Des moyens dont il dispose pour s\'y opposer.',
  project_invoices_invoice_payment_uncollectible: 'Non due',
  contractsEditor_keywords_label: 'Mots-clé',
  contractsEditor_keywords_help: 'Vous pouvez indiquer des mots-clé (3 caractères minimum), séparés par une virgule. Ils serviront pour la recherche, en plus du texte de la section.',
  step_showPurposes: 'Afficher les finalités',
  step_purpose: 'Finalité de traitement',
  subscription_meteredBilling_minUsage: 'pour {criteria} sur cette période',
  subscription_meteredBilling_graduatedPricing: 'tarification progressive au delà',
  subscription_meteredBilling_from: 'de',
  subscription_meteredBilling_to: 'à',
  subscription_meteredBilling_publishedProjects: 'Projets publiés',
  subscription_meteredBilling_price: 'Tarif',
  subscription_meteredBilling_nextInvoiceAmount: 'Prochaine facture',
  payment_failed_message: 'Attention ! Vous avez des projets en défaut de paiement. Merci de mettre à jour le(s) projet(s) concerné(s) :',
  payment_failed_short_message: 'Défaut de paiement !',
  subscription_meteredBilling_pageViews: 'Pages vues',
  subscription_meteredBilling_seeFeatures: 'Voir services et fonctionnalités',
  subscription_disable_agilitation_iban: 'Vous ne pouvez pas utiliser notre propre IBAN ! 🕵️',
  subscription_agencyMetered_publishedProjectsCount: 'Projets actuellement publiés',
  subscription_meteredBilling_cancelation_nextInvoice: 'Vous devrez régler la facture suivante',
  publishModal_freeTrialWithoutSubscription: 'Avant d’aller plus loin, vous devez souscrire à une formule (même free).',
  publishModal_freeTrialWithoutSubscription_integration: 'Au fait, avez-vous intégré notre service sur votre site ? Si vous ne l\'avez pas encore fait, consultez la démarche et publiez ensuite !',
  button_confirmBox_okLetsGo: 'Choisir une formule',
  subscription_agencyMetered_autoUpscaleNotification: 'En fonction du nombre de projets publiés, votre formule basculera automatiquement à la formule supérieure',
  subscription_meteredBilling_minUsage_freeTrial: 'pour {count} nouveaux visiteurs uniques sur cette période',
  subscription_meteredBilling_newVisitors: 'Nouveaux visiteurs uniques',
  publish_error_technicalError_label: 'Erreur technique',
  publish_error_technicalError_message: 'Une erreur technique a été rencontrée. Veuillez réessayer dans quelques minutes. Si ça ne fonctionne toujours pas, merci de contacter le support en leur indiquant le message d\'erreur suivant                                                                      ',
  home_cgu_params: 'CGU',
  home_data_privacy_params: 'Politique de confidentialité',
  home_legal_notice_params: 'Mentions légales',
  home_dpa_params: 'Accord de sous-traitance de données',
  user_account_preferred_language: 'Langue',
  user_account_select_preferred_language_placeholder: 'Sélectionnez votre langue',
  cookies_editor_settings_form_whitelist_label: 'URL où le widget Axeptio ne doit pas s\'afficher',
  cookies_editor_settings_form_whitelist_text: 'Liste d\'URL où le widget ne doit pas s\'afficher. Vous pouvez insérer plusieurs paths en les séparant par des virgules.',
  cookies_editor_settings_form_allowEscapeKey_label: 'Fermer la fenêtre contextuelle du widget avec la touche Échap lorsque l\'élément est focalisé.',
  network_error: 'Erreur de réseau : il semblerait qu\'un bloqueur de pub (ex: AdBlock Plus, AdBlock, uBlock ...) bloque la connexion à nos serveurs. Veuillez le désactiver (ou rajouter axept.io en whitelist) et rafraichir la page avant de tenter une nouvelle connexion.',
  contractsEditor_sectionSettings_excludeFromPdf: 'Exclure du PDF du contrat',
  header_warning_freeTrialLimitBrokenAt: 'Vous avez dépassé les bornes des limites des 200 nouveaux visiteurs uniques',
  header_warning_freeTrialLimitBrokenAt_upgradeLink: 'Mettre à niveau',
  project_consents_table_no_data: 'Aucun consentement',
  contractsEditor_template_new_section_title: 'Section sans titre',
  contractsEditor_template_new_sections_html: '<p>Contenu de la nouvelle section</p>',
  contractsEditor_template_cannot_download_pdf: 'Vous ne pouvez pas télécharger le PDF en mode édition',
  contractsEditor_template_untitled_contract_title: 'Contrat sans titre',
  contractsEditor_template_untitled_contract_edit_me: 'Modifiez-moi',
  contractsEditor_template_untitled_contract_double_click_edit: 'Double-cliquez pour modifier',
  contractsEditor_versions: 'Versions',
  contractsEditor_versions_latest_published: 'Dernière version publiée dans la configuration du projet',
  notification_breadcrumb_title: 'Notifications',
  notification_btn_title_singular: 'notification',
  notification_btn_title_plural: 'notifications',
  notifications_main_title: 'Notifications',
  notifications_you_ve_got: 'Vous avez',
  notifications_to_read_singular: 'notification à lire',
  notifications_to_read_plural: 'notifications à lire',
  notifications_mark_as_read: 'Marquer comme lu',
  notifications_no_notification_yet: 'Pas encore de notification',
  subscription_scheduledUpdate_to: 'Changement d\'abonnement vers',
  subscription_scheduledUpdate_for: 'planifié pour le',
  subscription_scheduledUpdate_toastMessage: 'Votre changement d\'abonnement a bien été planifié pour la fin de la période en cours',
  publishModal_toggleAll: 'Tout cocher',
  scanner_domain: 'Domaine à parcourir',
  scanner_follow_subdomain: 'Parcourir les sous-domaines ',
  scanner_update_configuration: 'Configuration à mettre à jour',
  scanner_now_schedule: 'Maintenant',
  scanner_day_schedule: 'Tous les jours',
  scanner_week_schedule: 'Toutes les semaines',
  scanner_month_schedule: 'Tous les mois',
  scanner_schedule: 'Fréquence du scan',
  scanner_sends_email_result: 'Envoyer les resultats aux mails',
  scanner_stop_schedule: 'Arrêter la tâche programmée',
  scanner_start_schedule: 'Relancer la tâche programmée',
  scanner_stop_schedule_button: 'Arrêter',
  scanner_start_schedule_button: 'Planifier',
  scanner_identifier: 'Identifiant',
  scanner_job_details: 'Resultat du scan',
  scanner_result_domain: 'Domaine',
  scanner_result_identifier: 'Id du scanner',
  scanner_result_started_at: 'Lancé le',
  scanner_created_by: 'Crée par',
  scanner_pages_scanned: 'Nombre de pages scannées',
  scanner_finished_at: 'Fini le',
  scanner_nb_vendors_found: 'Nombre de services tiers trouvés',
  scanner_vendors_identified: 'Services tiers identifiés',
  scanner_result_name: 'Nom',
  scanner_result_category: 'Catégorie',
  scanner_result_company: 'Compagnie',
  scanner_result_pages: 'Pages',
  scanner_result_triggered: 'Conformité',
  scanner_result_details: 'Détails',
  scanner_result_description: 'Description',
  scanner_vendors_unidentified: 'Services tiers non identifiés',
  scanner_create_new_report: 'Planifier un nouveau scanner',
  scanner_create_new_report_button: 'Planifier le scanner',
  scanner_modify_report: 'Modifier le scanner',
  scanner_modify_report_button: 'Modifier le scanner',
  scanner_last_scan: 'Dernier scanner',
  scanner_KPI_date: 'Date',
  scanner_KPI_vendor: 'Services tiers trouvés',
  scanner_KPI_errors: 'Services tiers non conforme',
  scanner_KPI_categories: 'Catégories',
  scanner_KPI_unknown: 'Services tiers inconnus',
  scanner_schedule_title: 'Scans programmés',
  scanner_new_schedule_job: 'Planifier un nouveau scanner',
  scanner_result_history: 'Historique des scanners',
  scanner_result_status: 'Statut',
  scanner_result_status_done: 'Terminé',
  scanner_result_status_active: 'En cours',
  scanner_result_status_error: 'En erreur',
  scanner_result_status_error_display_action: 'Afficher l\'erreur',
  scanner_result_status_pending: 'En attente',
  scanner_result_actions: 'Actions',
  scanner_result_download_pdf: 'Télécharger le résultat en PDF',
  scanner_result_download_xls: 'Télécharger le résultat en XSLX',
  scanner_result_compliant: 'Conforme',
  scanner_result_uncompliant: 'Non conforme',
  scanner_copy: 'Copier',
  scanner_copied: 'Copié',
  scanner_delete_history: 'Supprimer cet historique',
  scanner_create_new_email: 'Ajouter',
  scanner_email_error: 'n\'est pas valide',
  scanner_no_email_options_message: 'Saisissez vos emails',
  project_stats_kpi_pageviews_v2: 'Pages vues',
  project_stats_kpi_visitor_v2: 'Visiteurs',
  project_stats_kpi_newVisitor_v2: 'Nouveaux visiteurs',
  project_stats_kpi_consentRate_v2: 'Tx. consentement',
  project_stats_kpi_interactionRate_v2: 'Tx. interaction',
  project_stats_kpi_bounceRate_v2: 'Tx. rebond',
  project_stats_chart_consents_v2: 'Consentements',
  project_stats_consentDistribution_v2: 'Répartition des consentements',
  project_stats_consentDistributionByDevice_v2: 'Consentements / Terminal',
  project_stats_visitsByHosts_v2: 'Pages vues / Nom de domaine',
  project_stats_devicesDistribution_v2: 'Répartition des terminaux',
  project_stats_consentRateByDevice_v2: 'Tx. consentement par terminal',
  project_stats_consentRateByConfig_v2: 'Tx. consentement par configuration',
  project_stats_metrics_visitor_v2: 'Visiteurs',
  project_stats_metrics_newVisitor_v2: 'Nouveaux visiteurs',
  project_stats_metrics_accept_v2: 'Consentement',
  project_stats_metrics_reject_v2: 'Refus',
  project_stats_metrics_partial_v2: 'Consentement partiel',
  project_stats_metrics_bot_v2: 'Bot',
  project_stats_metrics_pageview_v2: 'Pages vues',
  project_stats_metrics_consentRate_v2: 'Taux de consentement',
  project_stats_metrics_interactionRate_v2: 'Taux d\'interaction',
  project_stats_metrics_bounceRate_v2: 'Taux de rebond',
  project_stats_chart_visitors_v2: 'Visiteurs',
  project_stats_dimension_device_v2: 'Terminal',
  project_stats_dimension_host_v2: 'Nom de domaine',
  project_stats_dimension_config_v2: 'Configuration',
  project_stats_dimension_browser_v2: 'Navigateur',
  project_stats_dimension_collection_v2: 'Type de consentement',
  project_stats_dimension_os_v2: 'Système d\'exploitation',
  project_stats_filter_from_v2: 'Du',
  project_stats_filter_to_v2: 'Au',
  project_stats_filter_period_v2: 'Période',
  project_stats_filter_dimension_v2: 'Filtres',
  unit_days_v2: 'Jours',
  project_stats_interactionRateByHosts_v2: 'Tx. Interaction par nom de domaine',
  project_stats_interactionRateByDevice_v2: 'Tx. Interaction par type de terminal',
  project_stats_dailyRates_v2: 'Évolution du taux de consentement',
  project_stats_bounceRateByDuration_all_visitors_legend_v2: 'Pourcentage de l\'ensemble des visiteurs qui sont partis avant x secondes, quel que soit le nombre de pages qu\'ils aient vues.',
  project_stats_bounceRateByDuration_visitors_that_bounced_legend_v2: 'Pourcentage de visiteurs ayant vu une seule page et qui sont partis avant x secondes.',
  project_stats_bounceRateByDuration_opening_timeout_legend_v2: 'Délai d\'apparition',
  project_stats_bounceRateByDuration_opening_timeout_legend_description_v2: 'Délai paramétré avant apparition du widget, par configuration.',
  project_vendor_axeptio_cookies: 'Cookies Axeptio',
  project_vendor_my_cookies: 'Mes cookies persos',
  project_unpublished_warning_toast_title: 'Une dernière étape',
  project_unpublished_warning_toast_text: 'Une fois votre widget en ligne, vous devez penser à conditionner\nle dépôt de vos cookies en fonction du consentement de vos\nvisiteurs. Dans le cas contraire, ils continueront à être déposés,\nquel que soit leur avis !',
  project_unpublished_warning_toast_text_ok: 'Tout est ok ?',
  project_unpublished_warning_toast_text_ok_for_me: 'Ok pour moi !',
  project_unpublished_warning_toast_link: 'https://support.axeptio.eu/hc/fr/articles/4404040466321-Interface-Tag-Manager',
  project_unpublished_warning_toast_button_title: 'Centre d\'aide',
  scanner_form_create_conf: 'Créer une nouvelle configuration',
  vendor_duplicated_view: 'Fait moi voir !',
  vendor_duplicated: 'Duplication faite !',
  vendor_duplicated_info: 'Le cookie {} vient d\'être ajouté à vos cookies persos',
  scanner_schedule_card_next_scan: 'Prochain scan le ',
  subscription_review_payment_method: 'Examiner le mode de paiement',
  scanner_created_cookies: 'Créer une nouvelle configuration en',
  scanner_coming_soon: 'Bientot disponible...',
  scanner_identifier_helper: 'Définit le nom du scan que vous allez mettre en place, mais aussi ceux des configurations que celui-ci va créer',
  scanner_options: 'Options',
  scanner_sent_to: 'Envoyé à',
  scanner_helpertip_creation: 'Cette option n\'est pas disponible pour les scanners quotidiens et hebdomadaires',
  scanner_result_creation: 'Langue des projets créés',
  dpo_editor_illustration_label: 'Image affichée en haut à droite',
  dpo_editor_illustration_helper: 'Pour un affichage optimal, Axeptio recommande de mettre une image de 100px X 150px',
  subscription_sepa_delay_warning: 'Un délai de 2 à 14j peut être nécessaire pour traiter votre paiement.',
  project_consents_service_contractsV2: 'Documents contractuels (Terms)',
  project_invoices_widget_button_downloading: 'Téléchargement en cours...',
  cookies_editor_consent_wall_title: 'Consent Wall Contextuel',
  contractsEditor_error_uniqueName: 'Vous avez déjà un contrat portant ce nom sur ce projet',
  subscription_features_titles: 'Fonctionnalités',
  subscription_features_show_more: 'Voir plus',
  subscription_features_show_less: 'Voir moins',
  project_consents_pagination_total: 'total',
  subscription_meteredBilling_termsConsents: 'Consentements Terms',
  contractsEditor_confSettings_rename_warning: 'Rappelez-vous également de changer le nom de configuration dans votre code d\'intégration Terms.',
  step_special_enableOnMobile: 'Activer le consent wall contextuel sur mobile',
  auth_updatePassword_logoutWarning: 'Vous devrez vous connecter à nouveau une fois le changement effectué.',
  my_usage_title: 'Mon utilisation',
  my_usage_periodicity: 'Période',
  my_usage_periodicity_month: 'Mensuelle',
  my_usage_periodicity_year: 'Annuelle',
  my_usage_from: 'Du',
  my_usage_to: 'au',
  my_usage_progress_title: 'Dans mon plan',
  my_usage_usage_type_pageViews: 'pages vues',
  my_usage_usage_type_termsConsents: 'consentements',
  my_usage_usage_type_publishedProjects: 'projets',
  my_usage_limit_exceeded: 'La limite d\'abonnement a été dépassée.',
  marketplace_title: 'Analytics Connectors',
  marketplace_text: 'Axeptio est connecté à plusieurs services pour vous simplifier l\'integration et ajouter de nouvelles fonctionnalités',
  marketplace_piano_title: 'Piano Analytics',
  marketplace_piano_input_function_name: 'Nom de la fonction',
  marketplace_piano_input_function_description: 'Changez cette valeur si vous êtes sûr de vous',
  marketplace_visitors_analytics_title: 'Visitor Analytics',
  marketplace_visitors_analytics_input_function_value: 'va',
  marketplace_visitors_analytics_description: 'En activant le service, nous envoyons 2 événements automatiquement à Visitor Analytics pour l\'affichage du Widget et l\'interaction de l\'utilisateur',
  marketplace_activate_service: 'Activer le service',
  marketplace_piano_activate_privacy_mode: 'Activer le privacy mode',
  marketplace_piano_activate_privacy_mode_desc: 'Activation du privacy mode',
  marketplace_save_button_service: 'Enregistrer',
  marketplace_googleanalytics_title: 'Google Analytics 4',
  marketplace_googleanalytics_input_function_name: 'Nom de la fonction',
  marketplace_googleanalytics_input_function_description: 'Changez cette valeur si vous êtes sûr de vous',
  marketplace_piano_input_function_value: 'pa',
  marketplace_googleanalytics_input_function_value: 'gtag',
  marketplace_publish_widgets_toast_title: 'Publiez vos widgets',
  marketplace_publish_widgets_toast_description: 'Vos widgets doivent être publiés à nouveau pour appliquer vos changements',
  scanner_helpertip_update: 'Cette option nous permet de mettre à jour les configuration que vous souhaitez. \nNos automates ne supprimeront pas votre travail, il ne se chargera que d\'ajouter de nouveau vendors si nécessaires.\nIl est vivement conseillé de cocher l\'option <b>Supprimer les services tiers</b> afin d\'avoir de meilleurs résultats. Vérifier toujours une configuration mise à jour avant de la publier !',
  scanner_delete_vendors: 'Laisser notre outil gérer votre configuration',
  auth_error_email_already_exists: 'Un utilisateur est déjà enregistré pour cet email',
  auth_error_invalid_email: 'Adresse email invalide',
  auth_password_number: 'Un chiffre',
  auth_password_lowercase: 'Une minuscule',
  auth_password_uppercase: 'Une majuscule',
  auth_password_special_char: 'Un caractère spécial',
  auth_password_length: '8 caractères minimum',
  auth_error_reset_password_nonlocal_provider: 'Vous ne pouvez pas réinitialiser votre mot de passe avec un accès SSO (Google Signup, Facebook Login)',
  auth_captcha_error: 'La vérification anti-robot a échoué, veuillez réessayer',
  scanner_stylesheet_button: 'Appliquer le design du site au widget',
  scanner_stylesheet_warning_helper: 'Attention, en cochant cette case vous autorisez notre outil à modifier les paramètres de design graphique de votre projet et donc <b> de l’ensemble de ses configurations</b>, y compris celles déjà existantes.',
  scanner_stylesheet_warning_helper_schedule: 'Attention, vous avez activé la fonctionnalité de design automatique. Cette action autorise notre outil à modifier les paramètres de design graphique de votre projet et donc <b>de l’ensemble de ses configurations</b>, y compris celles déjà existantes.',
  user_invitation_email_language: 'Langue du mail de l\'invitation',
  user_invitation_email: 'Adresse(s) email à inviter',
  scanner_domain_helper: 'Notre scanner parcourt 70 pages maximum, si vous souhaitez faire un scan plus approfondi, veuillez contacter notre support',
  doc_project: 'Projet',
  doc_organization: 'Organisation',
  user_account_pending_invitations: 'Invitations en attente',
  user_account_accept_invitation: 'Accepter l\'invitation',
  user_account_delete_invitation: 'Supprimer l\'invitation',
  user_account_invited_by: 'Invité par',
  project_stats_kpi_quickBounceRate_v2: 'Tx. rebond rapide',
  project_stats_kpi_hits_v2: 'Hits',
  project_stats_kpi_pageview_v2: 'Pages vues',
  project_stats_kpi_optInRate_v2: 'Tx. opt-in',
  project_stats_kpi_consentRateMobile_v2: 'Tx. consent, mobile',
  project_stats_kpi_consentRateDesktop_v2: 'Tx. consent, desktop',
  project_stats_kpi_interactionRateMobile_v2: 'Tx. inter. mobile',
  project_stats_kpi_interactionRateDesktop_v2: 'Tx. inter. desktop',
  project_stats_metrics_visitorsPercent_v2: 'Tous les visiteurs',
  project_stats_metrics_bounceVisitorsPercent_v2: 'Visiteurs avec rebond',
  project_stats_bounceRateByDuration_v2: 'Pourcentage de visiteurs ayant quitté le site après x secondes',
  stepDefaultPlaceholder: '(vide)',
  emailValidationRequired: 'Pour commencer à utiliser nos services, merci de valider votre adresse email (lien reçu par mail). Si vous ne retrouvez pas le mail, vous pouvez cliquer ici:',
  emailValidationRequired_resendEmail: 'Envoyez le à nouveau',
  emailValidationRequired_resendEmail_success: 'Email envoyé !',
  project_stats_kpi_pageviews_tooltip_v2: 'Nombre total de pages sur lesquelles un utilisateur a navigué. Une page visitée plusieurs fois est comptée plusieurs fois.',
  project_stats_kpi_pageview_tooltip_v2: 'Nombre total de pages sur lesquelles un utilisateur a navigué. Une page visitée plusieurs fois est comptée plusieurs fois.',
  project_stats_kpi_visitor_tooltip_v2: 'Nombre de visiteurs uniques sur une période donnée. Si le visiteur revient durant cette période, il n’est compté qu’une seule fois.',
  project_stats_kpi_newVisitor_tooltip_v2: 'Nombre de visiteurs n’étant jamais venus sur le site avant la période étudiée.',
  project_stats_kpi_consentRate_tooltip_v2: 'Pourcentage d’utilisateurs ayant accepté ou partiellement accepté les cookies par rapport à l’ensemble des visiteurs accepté ou refusé les cookies.',
  project_stats_kpi_interactionRate_tooltip_v2: 'Pourcentage de visiteurs ayant interagi avec le widget par rapport à ceux ayant eu la possibilité de le faire, c’est-à-dire les nouveaux visiteurs ainsi que les anciens visiteurs qui n’ont jamais interagi avec le widget',
  project_stats_kpi_optInRate_tooltip_v2: 'Pourcentage de visiteurs ayant accepté les cookies par rapport à l’ensemble des visiteurs ayant eu la possibilité de le faire, c’est-à-dire les nouveaux visiteurs ainsi que les anciens visiteurs qui n’ont jamais interagi avec le widget. Le taux d’opt-in est égal au taux de consentement multiplié par le taux d’interaction',
  project_stats_kpi_hits_tooltip_v2: 'Un "hit" est comptabilisé lorsque qu\'un internaute sollicite nos serveurs pour télécharger le script Axeptio. Ce compteur représente une fraction du trafic réel, car les navigateurs conservent notre script en mémoire. Cette valeur était préalablement utilisée pour décompter les "pageviews".',
  project_stats_kpi_bounceRate_tooltip_v2: 'Pourcentage de visiteurs ayant consulté une seule page et ayant quitté le site',
  project_stats_kpi_quickBounceRate_tooltip_v2: 'Pourcentage de visiteurs ayant consulté une seule page et ayant quitté le site en moins de 5 secondes',
  project_stats_kpi_consentRateMobile_tooltip_v2: 'Pourcentage d’utilisateurs sur mobile ayant accepté ou partiellement accepté les cookies par rapport à l’ensemble des visiteurs accepté ou refusé les cookies.',
  project_stats_kpi_consentRateDesktop_tooltip_v2: 'Pourcentage d’utilisateurs sur PC ayant accepté ou partiellement accepté les cookies par rapport à l’ensemble des visiteurs accepté ou refusé les cookies.',
  project_stats_kpi_interactionRateMobile_tooltip_v2: 'Pourcentage de visiteurs sur mobile ayant interagi avec le widget par rapport à ceux ayant eu la possibilité de le faire, c’est-à-dire les nouveaux visiteurs ainsi que les anciens visiteurs qui n’ont jamais interagi avec le widget',
  project_stats_kpi_interactionRateDesktop_tooltip_v2: 'Pourcentage de visiteurs sur PC ayant interagi avec le widget par rapport à ceux ayant eu la possibilité de le faire, c’est-à-dire les nouveaux visiteurs ainsi que les anciens visiteurs qui n’ont jamais interagi avec le widget',
  untitled: 'Sans titre',
  cookies_editor_stepImageAlign_label: 'Alignement de l\'image d\'illustration',
  stepImageAlign_left: 'Gauche',
  stepImageAlign_right: 'Droite',
  stepImageAlign_center: 'Centré',
  resetAll: 'Réinitialiser toutes les chaînes aux valeurs par défaut',
  scanner_dont_delete_vendors: 'Ajouter une étape supplémentaire contenant tous les services tiers manquants',
  scanner_delete_vendors_info: 'Notre outil va se charger de modifier les services tiers déclarés dans vos étapes. Il ne modifie et ne supprime pas son contenu, mais désactivera la publication des étapes non utilisées.',
  reset_text_to: 'Réinitialiser le texte à',
  clear_text: 'Clear text',
  service_button_translate: 'Traduire',
  service_button_translate_title: 'Traduire cette configuration',
  service_button_translate_from: 'De',
  service_button_translate_to: 'Vers',
  service_button_translate_duplicate_config: 'Dupliquer la configuration et traduire',
  service_button_translate_button: 'Traduire',
  service_button_translate_cancel_button: 'Annuler',
  service_button_translate_in_progress_title: 'Traduction en cours...',
  service_button_translate_success: 'Votre configuration est traduite. Veuillez la vérifier avant de la publier.',
  service_button_translate_success2: 'Les descriptions de vos cookies personnalisés ne sont pas traduites',
  service_button_translate_review: 'Vérifier la traduction',
  service_button_translate_close: 'Fermer',
  service_button_translate_success_title: 'Configuration traduite !',
  service_button_translate_unavailable_title: 'La traduction automatique n\'est pas disponible',
  service_button_translate_unavailable_tooltip: 'Traduction indisponible',
  service_button_translate_unavailable_description: 'Pour bénéficier de cette fonctionnalité, un plan supérieur est nécessaire.',
  service_button_translate_unavailable_button: 'Découvrez nos plans',
  service_button_translate_error_select_language: 'Veuillez choisir une langue pour la traduction',
  service_button_translate_no_default_title: 'La langue n\'est pas définie',
  service_button_translate_no_default_description: 'Veuillez sélectionner la langue de la configuration dans la page des paramètres.',
  service_button_translate_no_default_button: 'Accéder aux paramètres de configuration',
  service_button_translate_ai_translation: 'Traduire mes textes personnalisés avec l’IA',
  marketplace_matomo_title: 'Matomo',
  marketplace_piwik_title: 'Piwik',
  marketplace_beyable_title: 'Beyable',
  marketplace_matomo_input_function_value: 'matomo',
  marketplace_piwik_input_function_value: 'piwik',
  marketplace_beyable_input_function_value: 'beyable',
  marketplace_matomo_description: 'En activant le service, nous envoyons 2 événements automatiquement à Matomo pour l\'affichage du Widget et l\'interaction de l\'utilisateur',
  marketplace_piwik_description: 'En activant le service, nous envoyons 2 événements automatiquement à Piwik pour l\'affichage du Widget et l\'interaction de l\'utilisateur',
  marketplace_beyable_description: 'En activant le service, nous envoyons 2 événements automatiquement à Beyable pour l\'affichage du Widget et l\'interaction de l\'utilisateur',
  marketplace_googleanalytics_description: 'En activant le service, nous envoyons 2 événements automatiquement à Google Analytics pour l\'affichage du Widget et l\'interaction de l\'utilisateur',
  marketplace_piano_description: 'En activant le service, nous envoyons 2 événements automatiquement à Piano pour l\'affichage du Widget et l\'interaction de l\'utilisateur',
  cstools_breadcrumb_title: 'CS Tools',
  cstools_users_breadcrumb_title: 'Users',
  enterprise_only_feature: 'Fonctionnalité réservée au plan Entreprise',
  disclaimer_reject_buttons_loi25: 'Bien que non-obligatoire dans la Loi 25, laisser le bouton refuser préserve la confiance que les visiteurs accordent à votre marque.',
  marketplace_plausible_title: 'Plausible',
  marketplace_plausible_description: 'En activant le service, nous envoyons 2 événements automatiquement à Plausible pour l\'affichage du Widget et l\'interaction de l\'utilisateur',
  marketplace_plausible_input_function_value: 'plausible',
  marketplace_fathom_title: 'Fathom',
  marketplace_fathom_description: 'En activant le service, nous envoyons 2 événements automatiquement à Fathom pour l\'affichage du Widget et l\'interaction de l\'utilisateur',
  marketplace_fathom_input_function_value: 'fathom',
  marketplace_amplitude_title: 'Amplitude',
  marketplace_amplitude_description: 'En activant le service, nous envoyons 2 événements automatiquement à Amplitude pour l\'affichage du Widget et l\'interaction de l\'utilisateur',
  marketplace_amplitude_input_function_value: 'amplitude',
  projects_freetrial_limit_reached: 'Vous avez atteint la limite de projets Free Trial. Vous ne pourrez pas en publier de nouveaux, à moins de souscrire à un abonnement supérieur.',
  projects_published: 'Publié',
  projects_unpublished: 'Non publié',
  projects_freetrial_limit_reached_title: 'Limite de projets Free Trial atteinte',
  projects_freetrial_limit_reached_description: 'Vous avez atteint la limite de projets Free Trial.<br />Pour pouvoir en publier d\'autres, découvrez nos formules les plus adaptées.',
  projects_freetrial_limit_reached_choose_plan: 'Choisir une formule',
  projects_freetrial_limit_reached_book_demo: 'Réserver une démo',
  projects_freetrial_limit_reached_understand: 'Continuer',
  projects_freetrial_limit_reached_contact_cs: 'Contactez votre conseiller client',
  projects_and_configs_table_title: 'Projets & configurations',
  projects_and_configs_table_header_name: 'Nom',
  projects_and_configs_table_header_last_modified: 'Modifié le',
  projects_and_configs_table_header_status: 'Statut',
  projects_and_configs_table_header_plan: 'Plan',
  projects_and_configs_table_header_type: 'Type',
  projects_and_configs_table_type_project: 'Projet',
  group_search_bar_placeholder: 'Rechercher un groupe',
  projects_search_bar_placeholder: 'Rechercher un projet',
  projects_no_projects_yet: 'Vous n\'avez pas encore de projet',
  projects_no_results_found: 'Aucun projet trouvé',
  projects_clear_filters_button: 'Effacer les filtres',
  projects_filters_button: 'Filtres',
  projects_fetch_error: 'Le récupération de vos projets a échoué',
  project_filter_product: 'Produit',
  project_filter_region: 'Région',
  project_filter_language: 'Langue',
  project_filter_creation_date: 'Date de création',
  project_filter_date_between: 'Entre',
  project_filter_date_anytime: 'N\'importe quand',
  project_filter_date_after: 'Après le',
  project_filter_date_before: 'Avant le',
  project_filter_date_option_today: 'Aujourd\'hui',
  project_filter_date_option_last_7_days: '7 derniers jours',
  project_filter_date_option_last_30_days: '30 derniers jours',
  project_filter_date_option_this_year: 'Cette année',
  project_filter_date_option_last_year: 'L\'année dernière',
  project_filter_date_option_custom: 'Période personnalisée',
  project_filter_subscription: 'Abonnement',
  project_filter_status: 'Statut',
  project_filter_status_published: 'Publié',
  project_filter_status_unpublished: 'Non publié',
  projects_filters_search_button: 'Rechercher',
  comov2_banner: '🎉&nbsp;&nbsp;<b>NOUVEAUTÉ - </b>Axeptio vous offre la meilleure expérience avec <b>Consent Mode V2</b> de Google. <a target="_blank" href="https://info.axeptio.eu/fr/google-consent-mode-v2" rel="noopener" target="_blank">Cliquer ici</a> pour en savoir plus !',
  cookies_editor_consent_mode_v2_title: 'Consent Mode V2',
  cookies_editor_consent_mode_v2_display_label: 'Activer le Consent Mode V2 (étape)',
  cookies_editor_consent_mode_v2_enable_optional_consent_types_label: 'Activer les paramètres optionels du Consent Mode V2',
  cookies_editor_consent_mode_v2_position_label: 'Emplacement de l\'étape',
  cookies_editor_consent_mode_v2_position_second: 'Juste après l\'écran de bienvenue',
  cookies_editor_consent_mode_v2_position_last: 'Dernière étape',
  cookies_editor_consent_mode_v2_advanced_settings_label: 'Paramètres avancés',
  cookies_editor_consent_mode_v2_advanced_settings_ads_data_redaction_label: 'Masquer les données relatives aux annonces',
  cookies_editor_consent_mode_v2_advanced_settings_url_passthrough_label: 'Transmettre des informations sur les clics sur les annonces, les ID client et les identifiants de session dans les URL',
  cookies_editor_consent_mode_v2_warning: 'Vous pouvez utiliser une configuration "ConsentModeV2" dans votre widget pour les services suivants. Veuillez noter qu\'une configuration "ConsentModeV2" est obligatoire pour garantir le bon fonctionnement de ces services dans les territoires suivants : l\'Espace économique européen (EEE) et la Suisse.',
  cookies_editor_consent_mode_v2_warning_CA: 'Vous pouvez utiliser une configuration "ConsentModeV2" dans votre widget pour les services suivants:',
  cookies_editor_consent_mode_v2_warning_EU: 'Nous avons trouvé un fournisseur nécessitant une configuration "ConsentModeV2" dans votre widget. À partir du 6 mars 2024, ConsentModeV2 est obligatoire pour garantir le bon fonctionnement de ces services.',
  cookies_editor_consent_mode_v2_advanced_settings_doc_label: 'En savoir plus sur les paramètres avancées...',
  cookies_editor_consent_mode_v2_advanced_settings_doc_url: 'https://developers.google.com/tag-platform/security/guides/consent?hl=fr#passthroughs',
  auth_connect_with_google: 'Connexion avec Google',
  auth_connect_with_facebook: 'Connexion avec Facebook',
  projects_freetrial_limit_dont_show_again: 'Ne plus me montrer',
  cookies_editor_settings_form_inp_label: 'Activer les améliorations INP',
  cookies_editor_settings_focus_when_open_label: 'Prendre le focus quand le widget s\'ouvre (pratique pour l\'accessibilité)',
  unknown_card_regulation: 'Aucune réglementation sélectionnée',
  cookies_editor_regulation_label: 'Réglementation associée',
  cookies_editor_regulation_description: 'Choisissez la langue et la région, et nous ajusterons les paramètres en fonction de la réglementation la plus appropriée pour votre widget.',
  cookies_editor_no_regulation: 'Veuillez sélectionner une langue et une région pour obtenir la réglementation associée.',
  tcf_admin_banner: '<b>#MigrationTCF</b> : Vous voyez ce message car vous êtes potentiellement concerné(e) par la migration vers la CMP Axeptio For Publishers. <a href="https://info.axeptio.eu/fr/axeptio-for-publishers-migration" target="_blank">Cliquez ici</a> pour savoir ce que vous devez faire.',
  tcf_admin_tooltip: '#MigrationTCF : Vous voyez ce message car vous êtes potentiellement concerné(e) par la migration vers la CMP Axeptio For Publishers.',
  project_page_v2_on_demand: 'Sur demande',
  project_page_v2_modal_title: 'Ce produit n\'est pas compris dans votre abonnement',
  project_page_v2_modal_demo_text: 'Demander une démo',
  project_page_v2_modal_demo_link: 'https://meetings.hubspot.com/product-demo-bo/axeptio',
  project_page_v2_modal_text_contractsV2: 'Si notre outil de gestion contractuelle Terms vous intéresse, découvrez-le plus en détails sur notre site, ou en réservant une démo avec un membre de notre équipe.',
  project_page_v2_modal_discover_text_contractsV2: 'Découvrir Terms',
  project_page_v2_modal_discover_link_contractsV2: 'https://www.axept.io/fr/terms-consentement-contractuel',
  project_page_v2_modal_text_processings: 'Si notre outil de gestion des consentements marketing Subs vous intéresse, découvrez-le plus en détails sur notre site, ou en réservant une démo avec un membre de notre équipe.',
  project_page_v2_modal_discover_text_processings: 'Découvrir Subs',
  project_page_v2_modal_discover_link_processings: 'https://www.axept.io/fr/widget-de-consentement',
  project_page_v2_modal_text_tcf: 'Si notre bandeau de consentement dédié aux éditeurs vous intéresse, découvrez-le plus en détails sur notre site, ou en réservant une démo avec un membre de notre équipe.',
  project_page_v2_modal_discover_text_tcf: 'Découvrir Publishers',
  project_page_v2_modal_discover_link_tcf: 'https://www.axept.io/fr/blog/axeptio-for-publishers',
  service_tcf_welcome_screen_title: 'Texte',
  service_tcf_title: 'Titre',
  service_tcf_title_placeholder: 'Professionelle et gratuite',
  service_tcf_surtitle: 'Sur titre',
  service_tcf_surtitle_placeholder: 'Pour une information de qualité',
  service_tcf_subtitle: 'Sous titre',
  service_tcf_subtitle_placeholder: 'Nous avons besoin des cookies',
  service_tcf_standard_text: 'Texte',
  service_tcf_standard_text_warning: 'Ce texte n\'est pas modifiable. Veuillez contacter le support si vous avez une demande.',
  service_tcf_cookie_statement_url: 'Url de la charte relative aux cookies',
  service_tcf_privacy_url: 'Url de la politique de confidentialité',
  service_tcf_time_to_live: 'Durée de vie du consentement',
  service_tcf_select_vendors_title: 'Sélectionnez vos vendors',
  service_tcf_select_vendors_description: 'Vous pouvez ajouter ou supprimer des vendors ainsi que leurs purposes/features/restrictions.',
  cookies_editor_consent_mode_v2_illustrations_label: 'Illustrations',
  cookies_editor_consent_mode_v2_illustrations_display_label: 'Afficher les illustrations Cookies dans l\'étape Consent Mode v2',
  service_tcf_show_vendors_selected_only: 'Afficher uniquement les vendors sélectionés',
  service_tcf_all_vendors: 'Tous les vendors',
  service_tcf_collapsed_vendor_legitimate_interest: 'Le vendor invoque son intérêt légitime pour :',
  service_tcf_storage_duration: 'Durée de stockage',
  service_tcf_storage_duration_unit: 'jours',
  service_tcf_collected_data_categories: 'Catégories de données collectées',
  service_tcf_more_information: 'Plus d\'information',
  service_tcf_vendor_privacy_policy: 'Politique de confidentialité',
  service_tcf_vendor_list: 'Provenance',
  service_tcf_select_stack_title: 'Sélectionnez vos stacks',
  service_tcf_select_stack_info: 'Les vendors que vous avez sélectionnés exigent',
  service_tcf_select_stack_desc: 'Veuillez sélectionner les stacks que vous souhaitez regrouper dans le widget pour éviter d\'afficher la liste complète des stack. Vous êtes libre de décider quelle stack vous souhaitez afficher.',
  service_tcf_search_by_name: 'Rechercher un vendor',
  project_page_modal_limit_reached_title: 'Limite de pages vues atteinte',
  project_page_modal_limit_reached_text: 'Vous avez atteint la limite de pages vues de votre formule.\u2028<br />Vous serez désormais facturé pour chaque page vue supplémentaire.<br />\u2028Il est peut-être temps de découvrir les formules les plus adaptées.',
  project_page_modal_limit_reached_contact_us_link: 'https://meetings.hubspot.com/thomas-angot',
  project_page_modal_limit_reached_contact_us_label: 'Contactez nous',
  project_page_modal_limit_reached_plan_label: 'Choisir une formule',
  billing_form_label_currency: 'Devise',
  cookies_editor_consent_mode_v2_default_documentation_text: 'Lorsque vous activez le Consent Mode v2, n\'oubliez pas de définir les paramètres par défaut pour qu\'il fonctionne correctement. ',
  cookies_editor_consent_mode_v2_default_documentation_link_text: 'Voir notre documentation pour en savoir plus.',
  cookies_editor_consent_mode_v2_default_documentation_link_href: 'https://support.axeptio.eu/hc/fr/articles/22570846852753#h_01HPPCB6MNG8SS99QEQ4H7PCN5',
  tcf_editor_consent_mode_v2_display_label: 'Activer le Consent Mode V2',
  cookies_editor_consent_mode_v2_alert_info_next_visitor_show_cmp: 'La prochaine fois qu\'un visiteur ayant déjà interagi avec votre CMP reviendra, elle apparaîtra une nouvelle fois pour présenter la page Consent Mode V2.',
  auto_save_error: 'Enregistrement a échoué',
  tcf_editor_cs_user_introduction: 'Vous pouvez modifier ce texte car vous êtes un CS users. L\'utilisateur ne peut pas modifier ce texte.',
  tcf_editor_manage_cookies_website_link_field_label: 'Lien "Gérer les cookies" de votre site web',
  tcf_editor_manage_cookies_app_button_field_label: 'Bouton "Gérer les cookies" de votre application mobile',
  tcf_editor_cookies_complete_javascript: 'Un événement "cookies:complete" est déclenché au moment où un choix utilisateur est sauvegardé. Pour intercepter cet événement, utilisez la méthode <code>on</code>.',
  tcf_editor_cookies_complete_documentation: 'https://support.axeptio.eu/hc',
  tcf_editor_cmp_version: 'CMP version',
  marketplace_modal_warning_title: 'Pour activer cette option, vous devez avoir un abonnement supérieur',
  marketplace_modal_warning_description: 'Les connexions analytics permettent de remonter des événements dans votre outil de mesure d\'audience.<br />Ainsi, vous pouvez centraliser l\'ensemble stats dans cet outil pour une meillleure lisibilité',
  marketplace_modal_warning_btn_more_info_link: 'https://www.axept.io/fr/demo',
  marketplace_modal_warning_btn_more_info_label: 'Demander une démo',
  marketplace_modal_warning_btn_upgrade_label: 'Mettre à jour mon abonnement',
  publishModal_no_vendors: 'Aucun vendor renseigné',
  tcf_color_scheme: 'Color Scheme',
  tcf_color_scheme_light: 'Light mode',
  tcf_color_scheme_dark: 'Dark mode',
  tcf_editor_expiration_ttl: 'Délai maximum de consentement pour demander à nouveau le consentement',
  tcf_editor_open_widget: 'Ouvrir le widget programatiquement',
  tcf_editor_import_vendors: 'VEUILLEZ ENTRER UNE LISTE D\'IDS SÉPARÉS PAR DES VIRGULES',
  tcf_edtior_import_vendors_desc: 'IAB et/ou id Axeptio personnalisé',
  tcf_editor_import_button: 'Importer',
  tcf_editor_import_vendors_button: 'Importer la liste des vendors',
  tcf_editor_create_vendor_button: 'Créer un nouveau vendor',
  tcf_vendor_legitimate_interest: 'Objectifs d’intérêts légitimes',
  tcf_vendor_storage_duration: 'Durée de stockage',
  tcf_vendor_collected_data: 'Catégories de données collectées',
  tcf_vendor_purpose: 'Finalité',
  mandatory: 'Obligatoire',
  tcf_position_centered: 'Centré',
  tcf_position_bottom_left: 'En bas à gauche',
  tcf_position_bottom_right: 'En bas à droite',
  project_design_tcf_widget_position: 'Position du widget',
  cookies_editor_no_regulation_no_translation: 'Nous n\'avons pas encore de traductions ni de réglementation associé pour la langue que vous avez selectionné.',
  subscription_tabs_subscription_title: 'Ma formule',
  subscription_tabs_payment_title: 'Paiement et facturation',
  subscription_tabs_payment_address_edit_button: 'Modifier mon adresse',
  tcf_vendors_tcString: 'tcString',
  tcf_vendors_tcString_description: '<p>Collez votre tcString. <a target="_blank" href="https://support.axeptio.eu/hc/fr/articles/24045909792529-2-Configurer-le-widget-TCF#h_01HW33S71GETPNHD2RB6XEVPMB">Besoin d\'aide ?</a></p>',
  tcf_editor_import_tcString_button: 'Importer une tcString',
  step_message_hidden_button: 'Message supplémentaire (si vous cachez le bouton Axeptio)',
  subscription_meteredBilling_monthlyActiveUsers: 'Utilisateurs actifs mensuels',
  custom_strings_ClearFormulationsDisclaimer_alert: 'Si vous souhaitez personnaliser les formulations de votre widget, assurez-vous d\'employer en toute circonstance des formulations claires et facilement compréhensibles pour les utilisateurs afin de respecter vos obligations légales et/ou réglementaires.',
  project_stats_title_web_kpis: 'Web KPIs',
  project_stats_title_app_sdk_kpis: 'App SDK KPIs',
  tcf_editor_additional_consent: 'Additional Consent V2',
  tcf_editor_additional_consent_activate: 'Activer Addtional Consent V2',
  tcf_editor_additional_consent_read_more: 'En savoir plus sur Addtionial Consent v2',
  tcf_editor_additional_consent_read_more_link: 'https://support.google.com/admob/answer/9681920?hl=fr',
  marketplace_documentation_link: 'https://support.axeptio.eu/hc/fr/sections/24093133691537-Analytics-Connectors',
  marketplace_tagmanager_title: 'Google Tag Manager',
  marketplace_tagmanager_description: 'Envoyez les events consent.display et consent.answer via un dataLayer.push()',
  recommended: 'Recommandé',
  comov2_description: 'Les données sont collectées dans le but de personnaliser et de mesurer l\'efficacité de la publicité.',
  comov2_link: 'https://www.axept.io/blog/google-consent-mode-v2',
  comov2_link_label: 'En savoir plus sur la manière dont Google traite les informations personnelles',
  project_comov2_modal_title: 'Activez le Consent Mode v2',
  project_comov2_modal_text_start: 'Nous avons constaté que une ou plusieurs de vos configurtions cookie (celles avec le label',
  project_comov2_modal_text_end: ') requièrent le Consent Mode V2 pour être conformes.',
  project_comov2_modal_text_final: 'Remédiez à cela en quelques clics seulement !',
  comov2_label_pellet: 'CoMoV2 !',
  comov2_pellet_tooltip: 'Cette configuration requiert le Consent Mode V2 pour être conforme',
  project_comov2_modal_btn_contact: 'Contactez l\'équipe Axeptio',
  project_comov2_modal_btn_docs: 'Suivre la documentation',
  subscription_cancellation_unavailable: 'Vous ne pouvez pas résilier votre abonnement actuellement.',
  subscription_cancellation_unavailable_description: 'Votre compte présente un solde débiteur, vous ne pouvez pas résilier votre abonnement. Merci de contacter <a href="mailto:compta@agilitation.fr">compta@agilitation.fr</a> afin de régulariser votre situation.',
  project_deletion_unavailable: 'Vous ne pouvez pas supprimer votre projet actuellement.',
  no_config_to_publish_modal_title: 'Avant de publier, vous devez sélectionner un produit et y créer un configuration.',
  publish_button_no_configurations_tooltip: 'Ajoutez d\'abord une configuration',
  integrate_project_button: 'Intégrer sur votre site',
  publish_modal_integration_message: 'Au fait, avez-vous intégré notre service sur votre site ? Si vous ne l\'avez pas encore fait, consultez la démarche et publiez ensuite !',
  project_sidebar_description: 'Chargez la configuration d\'Axeptio dans votre script avant de le publier en suivant l\'une des options disponibles.',
  project_sidebar_description_gtm: 'Vous devez coller cet identifiant de projet dans le champ Axeptio CMP.',
  project_sidebar_description_cms: 'Vous devez coller cet identifiant de projet dans le champ Axeptio CMP. Consultez la documentation relative au CMS que vous gérez.',
  project_sidebar_description_global: 'Vous devez d\'abord intégrer ce code dans la balise <head> ou <body>. Veillez à le charger le plus tôt possible pour éviter qu\'il ne soit bloqué par d\'autres scripts.',
  project_sidebar_title_project_id: 'IDENTIFIANT DU PROJET',
  project_sidebar_button_tab_global: 'Générale',
  project_sidebar_button_cta_documentation: 'Lien vers la documentation',
  project_sidebar_button_cta_gtm_documentation: 'Consultez la documentation sur Tag Manager Interface',
  project_sidebar_button_cta_gtm_url: 'https://support.axeptio.eu/hc/fr/articles/4404040466321-Interface-Google-Tag-Manager',
  project_sidebar_collapse_me: 'Réduire la barre latérale',
  project_sidebar_button_cta_link_doc_global: 'https://support.axeptio.eu/hc/fr/articles/4404040521105-Installation-d-Axeptio-sur-le-site-web',
  disclaimer_show_purposes: 'Si vous désactivez ce module, vous devrez lister les finalités des cookies dans le texte de bienvenue de votre widget en utilisant des termes clairs et précis.',
  project_infos_double_subscription_title: 'Changement d’organisation',
  project_infos_double_subscription_description: 'Vous êtes sur le point de changer d’organisation.<br /><br />Cela signifie que ce projet sera désormais inclus dans l\'abonnement de votre organisation.',
  project_infos_double_subscription_button_confirm: 'Je confirme',
  project_infos_double_subscription_button_return: 'Retour',
  project_infos_double_subscription_success_msg: 'Votre organisation sera mise à jour quand vous aurez sauvegardé.',
  subscription_legacy_plan_description_with_differences: 'Des formules plus récentes que la vôtre sont disponibles.<br />\nCes dernières comportent des nouvelles fonctionnalités.<br />\nRetrouvez et comparez nos offres actuelles ci-dessous.',
  project_subscription_no_access_title: 'Votre abonnement est géré ailleurs (par une agence, dans un CMS, ...)',
  project_subscription_no_access_description: 'Afin de changer de formule, veuillez contacter notre support par email en cliquant ci-dessous',
  contact_support_button_label: 'Contacter le support',
  project_subscription_no_access_contact_subject: 'Je souhaite changer mon abonnement',
  breadcrumb_text: 'Modifier les textes',
  onboarding_modal_welcome_title: 'Bonjour et bienvenue sur Axeptio !',
  onboarding_modal_welcome_description: 'Avant de commencer, faisons connaissance. Ces quelques questions nous aideront à mieux vous connaître et à savoir ce que vous faites. Ne vous inquiétez pas, cela ne prendra que quelques secondes !',
  onboarding_modal_company_name_input_label: 'Quel est le nom de votre entreprise ?',
  onboarding_modal_company_location_input_label: 'Où se trouve votre entreprise ?',
  onboarding_modal_company_name_input_placeholder: 'Apple',
  onboarding_modal_learn_about_you_title: 'Bonjour ! Nous aimerions en savoir plus sur vous',
  onboarding_modal_learn_about_you_description: 'Ces quelques questions nous aideront à mieux vous connaître et à savoir ce que vous faites. Ne vous inquiétez pas, cela ne prendra que quelques secondes !',
  onboarding_modal_learn_about_you_instruction: 'Veuillez confirmer vos nom et prénom, ou les faire apparaître dans le bon champ.',
  onboarding_modal_learn_about_you_first_name_input_label: 'Prénom',
  onboarding_modal_learn_about_you_last_name_input_label: 'Nom',
  field_required: 'Ce champ est requis.',
  onboarding_modal_company_type_input_label: 'Dans quel type d\'entreprise travaillez-vous ?',
  onboarding_modal_company_field_input_label: 'Comment avez-vous utilisé Axeptio ?',
  onboarding_modal_company_type_agency: 'Mon agence digitale a choisi Axeptio pour nos différents sites web (plan d\'agence)',
  onboarding_modal_company_type_enterprise: 'Mon entreprise a choisi Axeptio (plan entreprise)',
  onboarding_modal_company_type_self_service: 'J\'ai personnellement choisi Axeptio (plan en libre-service)',
  onboarding_modal_company_department_tech: 'Tech',
  onboarding_modal_company_department_product: 'Produit',
  onboarding_modal_company_department_marketing: 'Marketing',
  onboarding_modal_company_department_data: 'Data',
  onboarding_modal_company_department_business: 'Business',
  onboarding_modal_company_department_legal: 'Juridique',
  onboarding_modal_company_department_other: 'Autre',
  select_default_placeholder: 'Choissisez ...',
  onboarding_modal_company_field_of_industry_label: 'Quel est votre domaine d\'activité ?',
  onboarding_modal_company_department_input_label: 'Pour quel type d\'agence travaillez-vous ?',
  onboarding_modal_company_field_of_industry_publishers: 'Éditeurs & médias',
  onboarding_modal_company_field_of_industry_real_estate: 'Immobilier',
  onboarding_modal_company_field_of_industry_tech: 'Technologie & logiciels',
  onboarding_modal_company_field_of_industry_children: 'Enfance',
  onboarding_modal_company_field_of_industry_sport: 'Sport',
  onboarding_modal_company_field_of_industry_insurance: 'Assurance & services financiers',
  onboarding_modal_company_field_of_industry_health: 'Santé & produits pharmaceutiques',
  onboarding_modal_company_field_of_industry_telecom: 'Télécom',
  onboarding_modal_company_field_of_industry_consumer_goods: 'Biens de consommation',
  onboarding_modal_company_field_of_industry_tourism: 'Tourisme & Voyages',
  onboarding_modal_company_field_of_industry_culture: 'Culture & divertissement',
  onboarding_modal_company_field_of_industry_manufacturing: 'Industrie manufacturière',
  onboarding_modal_company_field_of_industry_retail: 'Vente au détail',
  onboarding_modal_company_field_of_industry_agriculture: 'Agriculture',
  onboarding_modal_company_field_of_industry_education: 'L\'éducation',
  onboarding_modal_company_field_of_industry_food: 'Alimentation & boissons',
  onboarding_modal_company_field_of_industry_npo: 'Organisation à but non lucratif',
  onboarding_modal_company_field_of_industry_other: 'Autre',
  onboarding_modal_company_agency_kind_digital: 'Marketing numérique (360 agences)',
  onboarding_modal_company_agency_kind_seo: 'Agences de référencement',
  onboarding_modal_company_agency_kind_paid_acquisition: 'Acquisition payante',
  onboarding_modal_company_agency_kind_data: 'Data & BI',
  onboarding_modal_company_agency_kind_web_dev: 'Développement web',
  onboarding_modal_company_agency_kind_e_commerce: 'Développement E-commerce',
  onboarding_modal_company_agency_kind_analytics: 'Analyse et suivi',
  onboarding_modal_company_agency_kind_design: 'Design',
  onboarding_modal_company_agency_kind_cms_crm: 'Intégrateur CMS/CRM',
  onboarding_modal_company_agency_kind_esn: 'ESN',
  onboarding_modal_company_agency_kind_consultant: 'Consultant',
  onboarding_modal_company_cms_label: 'Utilisez-vous un CMS ?',
  yes: 'Oui',
  nope: 'Non',
  onboarding_modal_company_which_cms_label: 'Quel CMS utilisez-vous ?',
  onboarding_modal_complete_step_title: 'Merci pour vos réponses & bienvenue sur Axeptio !',
  onboarding_modal_complete_step_button_label: 'Allons-y!',
  save_before_leaving_modal_title: 'Vous partez sans enregistrer ?',
  save_before_leaving_modal_description: 'Attention, si vous partez sans enregistrer, tous vos changements seront perdus !',
  save_before_leaving_modal_leave_button: 'Partir sans enregistrer',
  save_before_leaving_modal_save_button: 'Enregistrer et fermer',
  project_menu_config_modal_delete: 'Supprimer configuration',
  project_menu_config_modal_translate_config: 'Traduire cette configuration',
  project_menu_config_modal_translate_title: 'Révision des traductions générées par l\'IA',
  project_menu_config_modal_translate_ai_text: 'Nous utilisons des outils d\'intelligence artificielle pour fournir Axeptio® en plusieurs versions linguistiques. Nous vous recommandons de vérifier l\'exactitude et la compréhensibilité de ces traductions pour vos utilisateurs finaux.',
  regulation_choice: 'Choix de la réglementation',
  language_available_config: 'Langues disponibles pour cette config',
  multiple_language_config: 'Multilingue',
  language_preview: 'Langue à éditer',
  multiple_language_code: 'multilingue',
  config_multilingue: 'Configuration multilingue',
  config_multilingue_desc: 'Une regulation plusieurs langues',
  projects_group_all_projects: 'Tous les projets',
  projects_group_list_new_group: 'Nouveau groupe',
  projects_group_list_create_modal_title: 'Créer un nouveau groupe',
  projects_group_list_duplicate_modal_title: 'Dupliquer le groupe',
  projects_group_list_rename_modal_title: 'Renommer le groupe',
  projects_group_list_create_modal_group_name: 'Nom du groupe',
  projects_group_list_create_modal_select_orga: 'Selectionnez une organisation',
  projects_group_list_create_modal_no_orga_choise: 'Pas d\'organization (Garder pour moi)',
  projects_group_list_create_modal_select_orga_instruction: 'Ce groupe sera ajouté à l\'organisation sélectionnée',
  btn_cancel: 'Annuler',
  projects_group_list_created_message_success: 'Le groupe a été créé avec succès',
  projects_group_list_created_message_error: 'Le groupe n\'a pas pu être créé',
  projects_group_list_updated_message_success: 'Le groupe a été modifié avec succès',
  projects_group_list_updated_message_error: 'Le groupe n\'a pas pu être modifié',
  projects_group_list_duplicate_message_success: 'Le groupe a été dupliqué avec succès',
  projects_group_list_duplicate_message_error: 'Le groupe n\'a pas pu être dupliqué',
  projects_group_list_deleted_message_success: 'Le groupe a été supprimé avec succès',
  projects_group_list_deleted_message_error: 'Le groupe n\'a pas pu être supprimé',
  projects_group_manage_modal_title: 'Gérer les groupes',
  projects_group_delete_modal_title: 'Supprimer le groupe',
  projects_group_delete_modal_description: 'Vous êtes sur le point de supprimer le groupe " {group_name} " (qui contient {projects_count} projets). Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer ?',
  projects_group_list_duplicate_message_error_same_name: 'Veuillez renseigner un nom différent du groupe d\'origine',
  projects_group_create_a_new_group: 'Créer un nouveau groupe',
  projects_add_to_group_btn: 'Ajouter au groupe',
  projects_group_selected: 'selectionné(s)',
  project: 'projet',
  projects_group_manage_modal_empty_state: 'Vous n\'avez pas encore de groupe créé. Veuillez en créer un en utilisant le bouton ci-dessous',
  projects_group_manage_modal_filtered_empty_state: 'Aucun groupe ne correspond à votre recherche',
  account_breadcrumb_title: 'Compte',
  organization_breadcrumb_title: 'Organisation',
  user_account_avatar: 'Avatar',
  no_description_available: 'pas de description disponible'
};

export default adminStrings;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { Link } from '../../contexts/NavigationContext';
import ActionButton from '../Button/ActionButton';
import { withLocaleContext } from '../../contexts/LocaleContext';
import { withProjectContext } from '../../contexts/ProjectContext';
import PublishButton from './PublishButton';
import DropdownMenu, {
  DropdownMenuItemStyle,
  DropdownMenuItemText,
  DropdownMenuSeparator,
  DropdownMenuTitle
} from '../DropdownMenu/DropdownMenu';
import Icon from '../Icon/Icon';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Assets from '../../services/Assets';
import Pellet from '../Pellet/Pellet';
import Img from '../Img/Img';
import { withApiContext } from '../../contexts/ApiContext';
import CopyText from '../CopyText/CopyText';
import LeaveProjectButton from './LeaveProjectButton/LeaveProjectButton';
import DeleteProjectButton from './DeleteProjectButton/DeleteProjectButton';
import Alert from '../Alert/Alert';
import Toast from '../Toast/Toast';
import { isProjectInDuePayment, getContractV2Id, mapUrlWithStack } from '../../base/helpers';
import { checkIsUnlocked, onGoingStripeSubscriptionStatus } from '../../base/billingHelpers';
import Tooltip from '../Tooltip/Tooltip';
import InfoBanner from './InfoBanner';
import ComponentsMap from './Services/ComponentsMap';
import ProjectSubscriptionAlert from './Features/ProjectSubscriptionAlert';
import LocalePickerDropdown, { LocaleButton } from './LocalePickerDropdown';
import { useLocation, useNavigate, useParams, Link as ReactRouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Root = styled.header`
  z-index: 200001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.WHITE};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08), 0 0 4px -4px rgba(0, 0, 0, 0.12);

  h2 {
    margin: 0;
    font-size: 18px;
    font-family: ${FONTS.TITLE};
    text-align: center;
    color: ${COLORS.GRAY_900};
  }
`;

const Navigation = styled.div`
  position: absolute;
  top: 0;
  left: 14px;
  height: 50px;
  display: flex;
  align-items: center;

  ${props =>
    !props.right &&
    `
    > a,
    > button {
      margin-right: 10px;
    }

    > div {
      > button {
        margin-right: 4px;
      }
    }
  `};

  ${props =>
    props.right &&
    `
    left: auto;
    right: 14px;

    > div:not(:last-child) {
      margin-right: 8px;
    }

    .PublishButton_Action {
      z-index: 200;
      position: relative;
    }
  `};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const NotificationButton = styled.div`
  position: relative;

  > button:hover {
    background: ${COLORS.GRAY_150};
  }

  button .svgr,
  button .svgr svg {
    width: 22px !important;
    height: 22px !important;
  }
`;

const NotificationBadge = styled.sup`
  position: absolute;
  top: -4px;
  right: -4px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  min-width: 16px;
  height: 16px;

  font-size: 10px;
  font-weight: 500;
  color: white;

  background-color: ${COLORS.BLUE_700};
  border-radius: 20px;
`;

const WarningFreeTrial = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
  background: ${COLORS.YELLOW};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 0;

  &:hover {
    background: ${COLORS.YELLOW_600};
  }
`;

const ToastBody = styled.div`
  font-size: 14px;
  color: ${COLORS.GRAY_600};
`;

const MenuItem = ({ project, name, title, icon, isFree, onClick, dataFeature }) => {
  const href = `/projects/${project.id}/config/${name}`;

  return (
    <Link dataFeature={dataFeature} href={href} callBack={onClick}>
      <DropdownMenuItemStyle dataFeature={dataFeature} withPaint={icon}>
        <Img dataFeature={dataFeature} src={Assets.image(icon)} width={35} />
        <DropdownMenuItemText dataFeature={dataFeature}>{title}</DropdownMenuItemText>
        {!isFree && (
          <Pellet dataFeature={dataFeature} primary>
            PRO
          </Pellet>
        )}
      </DropdownMenuItemStyle>
    </Link>
  );
};

const MenuServiceItem = ({ project, name, title, icon, isFree, onClick, dataFeature }) => {
  const href = `/projects/${project.id}/${name}`;

  return (
    <Link dataFeature={dataFeature} href={href} callBack={onClick}>
      <DropdownMenuItemStyle dataFeature={dataFeature} withoutPaintButHover={true}>
        <Img dataFeature={dataFeature} src={Assets.image(icon)} width={35} />
        <DropdownMenuItemText dataFeature={dataFeature}>{title}</DropdownMenuItemText>
      </DropdownMenuItemStyle>
    </Link>
  );
};

function replaceTitle(array, oldTitle, newTitle = null) {
  if (newTitle) {
    array.forEach(item => {
      if (item.key === oldTitle) {
        item.title = newTitle;
      }
    });
  }
  return array;
}

function GenerateBreadCrumb({ viewStack }) {
  const location = useLocation();
  if (!viewStack || viewStack.length <= 0) return null;

  const breadCrumbWithUrl = mapUrlWithStack(viewStack, location.pathname);
  return (
    <Breadcrumb>
      {breadCrumbWithUrl.slice(0, breadCrumbWithUrl.length - 1).map((v, i) => (
        <ReactRouterLink key={i} to={v.url}>
          <span>{v.title}</span>
        </ReactRouterLink>
      ))}
      <span>
        <span>{breadCrumbWithUrl[breadCrumbWithUrl.length - 1].title}</span>
      </span>
    </Breadcrumb>
  );
}

const AppHeader = props => {
  const {
    api,
    projectId,
    locale,
    project,
    projectOrganization,
    isProjectFetched,
    projectAccessMapping,
    breadCrumb = [],
    breadCrumbInfo,
    accessToInvoices,
    accessToSubscription
  } = props;
  const { t } = useTranslation();

  const [menuItemClicked, setMenuItemClicked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [PopulatedViewStack, setPopulatedViewStack] = useState([...breadCrumb]);
  const { serviceName } = useParams();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState('0');
  const [hideFreeTrialWarning, setHideFreeTrialWarning] = useState(false);

  const getProjectConfigurations = category => locale.projectConfigurations.filter(elm => elm.category === category);

  const displayFreeTrialWarning =
    !(
      checkIsUnlocked(project?.isUnlocked) ||
      checkIsUnlocked(projectOrganization?.isUnlocked) ||
      checkIsUnlocked(project?.granularUnlocking?.services?.cookies)
    ) &&
    !!project?.billing?.subscription?.metadata?.freeTrialLimitBrokenAt &&
    !onGoingStripeSubscriptionStatus.includes(projectOrganization?.subscription?.status);

  useEffect(() => {
    //Desactiving this request because it's not used.
    if (false) {
      async function getUnreadNotificationsCount() {
        const result = await api.getUnreadNotificationsCount();
        setUnreadNotificationsCount(result);
      }
      getUnreadNotificationsCount();
    }
  }, [api]);

  function verifyAccessToAuditTracerConf() {
    if (
      api.user?.isAdmin ||
      checkIsUnlocked(projectOrganization?.isUnlocked) ||
      api.user?.email?.includes('@studiometa.fr') ||
      process.env.REACT_APP_ENV !== 'production'
    ) {
      return true;
    }
    const requiredAutomatorServices = ['scanner', 'stylesheet', 'provisioning', 'emailing'];

    const allowedServices = Object.entries(projectAccessMapping?.services || {})
      .filter(([key, value]) => value)
      .map(([key]) => key);

    const allowedAutomatorServices = allowedServices
      .map(service => service.startsWith('automator') && service.replace('automator', '').toLowerCase())
      .filter(Boolean);

    if (allowedAutomatorServices.includes('global') && project.granularUnlocking?.services?.automatorGlobal) {
      return true;
    }
    if (!allowedAutomatorServices.includes('global')) {
      for (const action of requiredAutomatorServices) {
        if (!allowedAutomatorServices.includes(action)) {
          return false;
        }
      }
    }
    return api.user?.groups.includes(`organization_${projectOrganization?._id}`);
  }
  const isPaymentDue = isProjectInDuePayment({
    ...project,
    organization: projectOrganization
  });

  const firstItem = location.pathname.split('/').length > 2;

  useEffect(() => {
    let tmp = [...breadCrumb];
    tmp = replaceTitle(tmp, 'ProjectId', project?.name);
    tmp = replaceTitle(tmp, 'ServiceName', locale?.servicesMap[serviceName ?? breadCrumbInfo?.ServiceName]?.title);
    tmp = replaceTitle(tmp, 'ConfigName', breadCrumbInfo?.ConfigName);
    tmp = replaceTitle(tmp, 'ConfigPage', breadCrumbInfo?.ConfigPage);
    setPopulatedViewStack(tmp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumb, project, breadCrumbInfo]);

  useEffect(() => {
    setMenuItemClicked(true);
    const timeoutId = setTimeout(() => setMenuItemClicked(false), 300);
    return () => clearTimeout(timeoutId);
  }, [location]);

  return (
    <Root>
      {displayFreeTrialWarning && !hideFreeTrialWarning ? (
        <Toast status="warning" title="Free trial" onClose={() => setHideFreeTrialWarning(true)}>
          <ToastBody>
            {t('header_warning_freeTrialLimitBrokenAt')}
            <br />
            <a className="buttonUpgradeFreeTrial" href={`/projects/${projectId}/config/subscription`}>
              {t('header_warning_freeTrialLimitBrokenAt_upgradeLink')}
            </a>
          </ToastBody>
        </Toast>
      ) : null}

      {projectId && project && project.billing && project.billing.subscription && api && api.user ? (
        <ProjectSubscriptionAlert
          projectId={projectId}
          subscription={project.billing.subscription}
          user={api.user}
          eventName={'bo_nps'}
        />
      ) : null}

      <Content>
        <Navigation>
          {firstItem && breadCrumb.length === 0 && (
            <ActionButton
              small
              margin="0"
              onClick={() => {
                navigate('..', { relative: 'path' });
              }}
            >
              <Icon size={20} name="arrow_back" />
            </ActionButton>
          )}
          {breadCrumb && breadCrumb.length > 0 && (
            <ActionButton
              small
              margin="0"
              onClick={() => {
                navigate('..', { relative: 'path' });
              }}
            >
              <Icon size={20} name="arrow_back" />
            </ActionButton>
          )}

          <LocalePickerDropdown stickToTop>
            <LocaleButton />
          </LocalePickerDropdown>
        </Navigation>
        <GenerateBreadCrumb viewStack={PopulatedViewStack} />
        <Navigation right>
          {isProjectFetched ? (
            <>
              {isPaymentDue.isPaymentDue && (
                <Alert error small margin={'0px 10px'}>
                  <Link
                    href={
                      isPaymentDue.subscriptionEntity === 'project'
                        ? `/projects/${project.id}/config/subscription`
                        : `/account/organizations/${project.organizationId}`
                    }
                  >
                    {t('payment_failed_short_message')}
                  </Link>
                </Alert>
              )}

              {displayFreeTrialWarning && hideFreeTrialWarning ? (
                <WarningFreeTrial onClick={() => setHideFreeTrialWarning(!hideFreeTrialWarning)} title="Free trial">
                  <Icon name="warning" size={18} color={COLORS.GRAY} />
                </WarningFreeTrial>
              ) : null}

              <PublishButton disabled={location === '/projects/onboarding'} small />
              <DropdownMenu menuItemClicked={menuItemClicked} iconName="settings" aria-label="Project settings">
                <>
                  <DropdownMenuTitle>{t('project_dropdown_id')}</DropdownMenuTitle>
                  <DropdownMenuItemStyle as="span">
                    <CopyText
                      small
                      text={project._id}
                      tooltipLabel={t('project_dropdown_id_copy')}
                      tooltipLabelSuccess={t('project_dropdown_id_copied')}
                    />
                  </DropdownMenuItemStyle>
                  <DropdownMenuTitle>{t('project_main_title')}</DropdownMenuTitle>
                  {getProjectConfigurations('main').map(conf => {
                    if (conf.name !== 'scanner' || verifyAccessToAuditTracerConf()) {
                      return <MenuItem project={project} {...conf} key={conf.name} />;
                    }
                    return null;
                  })}

                  {locale.services
                    .filter(s => (s.isActive || true) && ComponentsMap.Services[s.name])
                    .filter(s => s.isActiveV2 !== true)
                    .filter(s => s.name !== 'portability')
                    .map(service => (
                      <MenuServiceItem project={project} {...service} key={service.name} />
                    ))}

                  <DropdownMenuTitle>{t('project_configuration_title')}</DropdownMenuTitle>
                  {getProjectConfigurations('config').map(conf => (
                    <MenuItem project={project} {...conf} key={conf.name} />
                  ))}
                  {!accessToInvoices && !accessToSubscription ? null : (
                    <>
                      <DropdownMenuTitle>{t('project_billing_title')}</DropdownMenuTitle>
                      {getProjectConfigurations('billing')
                        .filter(
                          conf =>
                            (conf.name === 'invoices' && accessToInvoices) ||
                            (conf.name === 'subscription' && accessToSubscription)
                        )
                        .map(conf => (
                          <MenuItem project={project} {...conf} key={conf.name} />
                        ))}
                    </>
                  )}

                  {process.env.REACT_APP_ENV === 'staging' && getProjectConfigurations('staging').length > 0 ? (
                    <>
                      <DropdownMenuTitle>{'Staging'}</DropdownMenuTitle>
                      {getProjectConfigurations('staging').map(conf => (
                        <MenuItem project={project} {...conf} key={conf.name} />
                      ))}
                    </>
                  ) : (
                    ''
                  )}

                  <DropdownMenuSeparator />
                  <DropdownMenuTitle>{t('user_menu_administration')}</DropdownMenuTitle>
                  <LeaveProjectButton type="link" api={api} docId={projectId} collection="projects" />
                  <DeleteProjectButton type="link" api={api} docId={projectId} collection="projects" />
                </>
              </DropdownMenu>
            </>
          ) : null}

          <DropdownMenu menuItemClicked={menuItemClicked} iconName="three_dots_vert" aria-label="Settings">
            <>
              <Link href="/account">
                <DropdownMenuItemStyle hover>
                  <Icon name="manage_accounts" size={24} />
                  <span>{t('dropdown_account')}</span>
                </DropdownMenuItemStyle>
              </Link>

              <a href={process.env.REACT_APP_SUPPORT_PORTAL_URL} target="_blank" rel="noopener noreferrer">
                <DropdownMenuItemStyle hover>
                  <Icon name="support" size={24} />
                  <span>{t('support_btn')}</span>
                </DropdownMenuItemStyle>
              </a>

              <DropdownMenuSeparator />

              <span onClick={() => window.openAxeptioCookies()}>
                <DropdownMenuItemStyle hover>
                  <Icon name="cookie" size={24} />
                  <span>{t('home_cookies_params')}</span>
                </DropdownMenuItemStyle>
              </span>
              <span
                onClick={() =>
                  window.axeptioSDK?.openContract(getContractV2Id('privacy_policy'), {
                    readOnly: true
                  })
                }
              >
                <DropdownMenuItemStyle hover>
                  <Icon name="privacy_policy" size={24} />
                  <span>{t('home_data_privacy_params')}</span>
                </DropdownMenuItemStyle>
              </span>
              <span
                onClick={() =>
                  window.axeptioSDK?.openContract(getContractV2Id('legal_notice'), {
                    readOnly: true
                  })
                }
              >
                <DropdownMenuItemStyle hover>
                  <Icon name="legal_notice" size={24} />
                  <span>{t('home_legal_notice_params')}</span>
                </DropdownMenuItemStyle>
              </span>
              <span
                onClick={() =>
                  window.axeptioSDK?.openContract(
                    {
                      name: getContractV2Id('terms_of_use')
                    },
                    { readOnly: true }
                  )
                }
              >
                <DropdownMenuItemStyle hover>
                  <Icon name="cgu" size={24} />
                  <span>{t('home_cgu_params')}</span>
                </DropdownMenuItemStyle>
              </span>
              <span
                onClick={() =>
                  window.axeptioSDK?.openContract(
                    {
                      name: getContractV2Id('dpa')
                    },
                    { readOnly: true }
                  )
                }
              >
                <DropdownMenuItemStyle hover>
                  <Icon name="dpa" size={24} />
                  <span>{t('home_dpa_params')}</span>
                </DropdownMenuItemStyle>
              </span>
              <DropdownMenuSeparator />

              <DropdownMenuItemStyle as="button" onClick={() => api.signout()} danger={true}>
                <Icon name="logout" size={20} />
                <span>{t('sign_out')}</span>
              </DropdownMenuItemStyle>
            </>
          </DropdownMenu>

          {api.isCurrentUserCustomerSupport() ? (
            <Tooltip text={'CS Tools'}>
              <ActionButton
                small
                margin="0"
                mimicDropdown
                colorIcon={COLORS.ORANGE}
                onClick={e => {
                  e.preventDefault();
                  navigate('/cs-tools');
                }}
              >
                <Icon size={20} name="support" />
              </ActionButton>
            </Tooltip>
          ) : null}

          <NotificationButton>
            <ActionButton
              small
              margin="0"
              onClick={e => {
                e.preventDefault();
                navigate('/notifications');
              }}
              mimicDropdown
              title={`${unreadNotificationsCount} ${
                unreadNotificationsCount <= 1 ? t('notification_btn_title_singular') : t('notification_btn_title_plural')
              } `}
            >
              <Icon size={22} color={COLORS.GRAY_700} name="notification" />
            </ActionButton>
            {unreadNotificationsCount > 0 && <NotificationBadge>{unreadNotificationsCount}</NotificationBadge>}
          </NotificationButton>
        </Navigation>
        <InfoBanner projectId={projectId} />
      </Content>
    </Root>
  );
};

export default withApiContext(withLocaleContext(withProjectContext(AppHeader)));

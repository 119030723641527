import React, { useContext } from 'react';
import createContextHOC from './createContextHOC';
import { getFeatureFlags, setFeatureFlag, featureFlagIsEnabled } from '../utils/featureFlags';

const FeatureFlagsContext = React.createContext({});

const withFeatureFlags = createContextHOC(FeatureFlagsContext, ({ getFeatureFlags, setFeatureFlag, featureFlagIsEnabled }) => {
  return { getFeatureFlags, setFeatureFlag, featureFlagIsEnabled };
});

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}

export function getFeatureFlagsContextValue(api) {
  return {
    getFeatureFlags: () => {
      if (api?.isCurrentUserCustomerSupport()) {
        return getFeatureFlags();
      }
      return {};
    },
    setFeatureFlag: (featureFlag, enabled) => {
      if (api?.isCurrentUserCustomerSupport()) {
        setFeatureFlag(featureFlag, enabled);
      }
    },
    featureFlagIsEnabled: featureFlag => {
      if (api?.isCurrentUserCustomerSupport()) {
        return featureFlagIsEnabled(featureFlag);
      }
      return false;
    }
  };
}

export { withFeatureFlags };

export default FeatureFlagsContext;

const colors = [
  {
    name: 'main',
    title: 'Principale',
    description: 'Couleur principale',
    category: 'Couleurs de base'
  },
  {
    name: 'text',
    title: 'Textes',
    category: 'Couleurs de base'
  },
  {
    name: 'title',
    title: 'Titres',
    category: 'Couleurs de base'
  },
  {
    name: 'widget',
    title: 'Fond des widgets',
    category: 'Couleurs de base'
  },
  {
    name: 'card',
    title: 'Fond des cartes',
    category: 'Couleurs de base'
  },
  {
    name: 'button_text',
    title: 'Texte des boutons',
    category: 'Boutons de base'
  },
  {
    name: 'button_text_hover',
    title: 'Texte des boutons au survol',
    category: 'Boutons de base'
  },
  {
    name: 'button_border',
    title: 'Bordure des boutons',
    category: 'Boutons de base'
  },
  {
    name: 'button_bg',
    title: 'Fond des boutons',
    category: 'Boutons de base'
  },
  {
    name: 'button_bg_hover',
    title: 'Fond des boutons au survol',
    category: 'Boutons de base'
  },
  {
    name: 'primary_button_text',
    title: 'Texte du bouton principal',
    category: 'Bouton Principal'
  },
  {
    name: 'primary_button_text_hover',
    title: 'Texte du bouton principal au survol',
    category: 'Bouton Principal'
  },
  {
    name: 'primary_button_bg',
    title: 'Fond du bouton principal',
    category: 'Bouton Principal'
  },
  {
    name: 'primary_button_bg_hover',
    title: 'Fond du bouton principal au survol',
    category: 'Bouton Principal'
  },
  {
    name: 'consent_button_bg',
    title: 'Fond des boutons de consentement',
    'default': '#f4f4f4',
    category: 'Bouton de consentement'
  },
  {
    name: 'consent_button_bg_hover',
    title: 'Fond des boutons de consentement au survol',
    'default': '#efefef',
    category: 'Bouton de consentement'
  },
  {
    name: 'consent_button_text',
    title: 'Texte des boutons de consentement',
    'default': '#333333',
    category: 'Bouton de consentement'
  },
  {
    name: 'consent_button_text_hover',
    title: 'Texte des boutons de consentement au survol',
    'default': '#444444',
    category: 'Bouton de consentement'
  },
  {
    name: 'consent_button_border',
    title: 'Contour des boutons de consentement',
    'default': '#f4f4f4',
    category: 'Bouton de consentement'
  },
  {
    name: 'consent_button_border_hover',
    title: 'Coutour des boutons de consentement au survol',
    'default': '#dfdfdf',
    category: 'Bouton de consentement'
  },
  {
    name: 'toggle_off',
    title: 'Couleur de fond de la checkbox déselectionnée',
    category: 'Couleurs de base'
  },
  {
    name: 'toggle_on',
    title: 'Couleur de fond de la checkbox selectionnée',
    'default': '#ffc823',
    category: 'Couleurs de base'
  },
  {
    name: 'backdrop_inner',
    title: 'Centre du dégradé de l\'overlay',
    category: 'Overlay'
  },
  {
    name: 'backdrop_outer',
    title: 'Extérieur du dégradé de l\'overlay',
    'default': '#ffffff',
    category: 'Overlay'
  }
];

export default colors;
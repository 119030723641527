import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import COLORS, { MAIN_COLORS } from '../../constants/colors';
import hex2rgb from 'hex-to-rgb';
import FONTS from '../../constants/fonts';
import { Label } from '../Input/Input';

// Also used in ReactSelect
export const SelectCss = css`
  outline: none !important;
  appearance: none !important;
  padding: 0 12px;
  width: 100%;
  font-family: ${FONTS.PRIMARY};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.25;
  height: 42px;
  border-radius: 6px;
  color: ${COLORS.GRAY_700};
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GRAY_300};
  cursor: pointer;
  transition: all 0.1s ease;

  ${props =>
    props.small &&
    `
    height: 34px;
  `};

  ${props =>
    props.large &&
    `
    height: 54px;
  `};

  &:hover,
  &:focus,
  &:focus-within {
    outline: none;
    border-color: ${COLORS.GRAY_600};
    box-shadow: 0 0 0 2px rgba(0 0 0 / 10%);
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectStyle = styled.div`
  position: relative;
  display: flex;
  min-width: 200px;

  select {
    ${SelectCss};

    ${props =>
      props.color &&
      `
      &:focus {
        border-color: ${MAIN_COLORS.PRIMARY_COLOR};
        box-shadow: 0 0 0 3px rgba(${hex2rgb(MAIN_COLORS.PRIMARY_COLOR)}, 0.25);
      }
    `};
    ${props =>
      props.disabled &&
      `
    cursor: not-allowed;
    opacity: 0.5;
  `};
  }

  ${props => props.large && ` min-width: 400px;`};
  ${props =>
    props.xsmall &&
    `
    min-width: 100px;
    max-width: 150px; 
    
    > select {
        font-size: 12px;
    }
    `};

  .select-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 22px;
    height: 22px;
    fill: ${COLORS.GRAY_400};
    pointer-events: none;
    transform: translateY(-50%);
    transition: all 0.15s ease;
  }

  &:hover {
    .select-icon {
      fill: ${COLORS.GRAY_800};
    }
  }

  ${props =>
    props.fluid &&
    `
    width: 100%;
  `};
`;

const Select = forwardRef(
  (
    { emptyOption, placeHolder, options, label, onChange, value, name, children, xsmall, small, large, fluid, disabled = false },
    ref
  ) => (
    <Group ref={ref}>
      {label && <Label>{label}</Label>}
      <SelectStyle xsmall={xsmall} small={small} large={large} fluid={fluid} disabled={disabled}>
        <select
          disabled={disabled}
          onChange={onChange}
          value={value || (typeof value === 'undefined' && placeHolder ? '$$placeholder' : '')}
          name={name}
        >
          {placeHolder && (
            <option value={placeHolder.value || '$$placeholder'} disabled>
              {placeHolder.name}
            </option>
          )}
          {emptyOption && <option value={emptyOption.value || ''}>{emptyOption.name}</option>}
          {Array.isArray(options)
            ? options.map((option, index) => (
                <option key={`${option.value}-${index}`} value={option.value}>
                  {option.name}
                </option>
              ))
            : children}
        </select>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="select-icon">
          <path d="M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z" />
        </svg>
      </SelectStyle>
    </Group>
  )
);

export default Select;
export { SelectStyle };

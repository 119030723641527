import React from 'react';
import styled from 'styled-components';

const Style = styled.span.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  position: relative;
  padding: 4px 4px 4px 6px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  border-radius: 3px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  letter-spacing: 0.05em;
  color: #52555a;
  background-color: #f9fbff;

  ${props =>
    props.border &&
    `
    border: 1px solid #d1d4d8;
  `};

  ${props =>
    props.small &&
    `
    font-size: 10px;
  `};

  ${props =>
    props.fontWeight &&
    `
    font-weight: ${props.fontWeight};
  `};

  ${props => props.fontFamily && `font-family: ${props.fontFamily};`};

  ${props =>
    props.primary &&
    `
    color: #dea912;
    background-color: #fef7e5;
    border-color: #fae4a6;
  `};

  ${props =>
    props.info &&
    `
    color: #123988;
    background-color: #e6eeff;
    border-color: #a5bff1;
  `};

  ${props =>
    props.warning &&
    `
    color: #dea912;
    background-color: #fff8e8;
    border-color: #fae4a6;
  `};

  ${props =>
    props.error &&
    `
    color: #94332e;
    background-color: #fbeae9;
    border-color: #f9a6a2;
  `};

  ${props =>
    props.success &&
    `
    color: #247a70;
    background-color: #ecf9f7;
    border-color: #b6e2dd;
  `};

  ${props =>
    props.absolute &&
    `
    z-index: 200;
    position: absolute;
    top: 8px;
    right: 8px;
  `};

  ${props =>
    props.displayHover &&
    `
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 5px 2px #d1d4d8;
      }

      &:active {
        box-shadow: 0 0 3px 1px #d1d4d8;
      }

    `};
`;

const Pellet = ({
  info,
  error,
  primary,
  success,
  warning,
  absolute,
  children,
  dataFeature,
  small,
  fontWeight,
  fontFamily,
  border,
  ...restProps
}) => {
  return (
    <Style
      info={info}
      error={error}
      primary={primary}
      success={success}
      warning={warning}
      absolute={absolute}
      dataFeature={dataFeature}
      small={small}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      border={border}
      {...restProps}
    >
      {children}
    </Style>
  );
};

export default Pellet;

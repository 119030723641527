const projectConfigurations = [
  {
    url: 'consents',
    name: 'consents',
    title: 'Consents registry',
    description: 'All the consents Axeptio collected',
    icon: 'helper_hours',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'main',
    dataFeature: 'consents_registry'
  },
  {
    url: 'scanner',
    name: 'scanner',
    title: 'Shake - Cookie Scanner',
    icon: 'icon_cookiescan',
    paint: 'gray',
    useStripe: false,
    isFree: 'VRAI',
    category: 'main'
  },
  {
    url: 'statsV1',
    name: 'statsV1',
    title: 'Statistics V1',
    icon: 'helper_frequency',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'deprecated',
    dataFeature: 'statistics'
  },
  {
    url: 'design',
    name: 'design',
    title: 'Colors and Styleguide',
    description: 'Dress our widgets to your colors',
    icon: 'project_design',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'config',
    dataFeature: 'customization'
  },
  {
    url: 'invoices',
    name: 'invoices',
    title: 'Invoices',
    description: 'Access all invoices for this project',
    icon: 'project_invoices',
    paint: 'gray',
    useStripe: true,
    isFree: true,
    category: 'billing'
  },
  {
    url: 'subscription',
    name: 'subscription',
    title: 'Manage my subscription',
    description: 'Choose which services are activated and enter your billing details',
    icon: 'project_subscription',
    paint: 'gray',
    useStripe: true,
    isFree: true,
    category: 'billing'
  },
  {
    url: 'infos',
    name: 'infos',
    title: 'Project details',
    description: 'Change basic information about the project.',
    icon: 'icon-formulaire',
    paint: 'gray',
    useStripe: true,
    isFree: true,
    category: 'config'
  },
  {
    url: 'integration',
    name: 'integration',
    title: 'Integration',
    icon: 'helper_double_optin',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'config'
  },
  {
    url: 'users',
    name: 'users',
    title: 'Users and accounts',
    description: 'Invite collaborators to join this project.',
    icon: 'project_users',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'config'
  },
  {
    url: 'stats',
    name: 'stats',
    title: 'Statistics',
    icon: 'helper_frequency',
    paint: 'gray',
    useStripe: 'FAUX',
    isFree: 'VRAI',
    category: 'main',
    dataFeature: 'statistics'
  },
  {
    url: 'marketplace',
    name: 'marketplace',
    title: 'Analytics Connectors',
    icon: 'icon_plug_connect',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'config'
  }
];

export default projectConfigurations;
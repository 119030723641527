import React from 'react';
import styled from 'styled-components';
import ActionButton, { ActionButtonStyle } from '../../../Button/ActionButton';
import Icon from '../../../Icon/Icon';
import COLORS from '../../../../constants/colors';
import { useTranslation } from 'react-i18next';

const Pages = styled.div`
  label {
    margin-right: 5px;
    font-size: 12px;
    font-family: Monaco, monospace;
  }

  ${ActionButtonStyle.WrappedComponent} {
    height: 30px;
    width: 30px;
  }
`;
const PageStyle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -1px;
  padding: 4px 8px;
  background: ${props => (props.isNew ? COLORS.GRAY_50 : COLORS.GRAY_100)};
  border: 1px solid ${COLORS.GRAY_200};
  flex-wrap: wrap;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

const PageInput = styled.div`
  display: flex;
  align-items: baseline;
  flex: 1;
  margin-right: 8px;
  font-size: 13px;
  line-height: 1;
  color: ${COLORS.BLACK};
  background-color: ${COLORS.WHITE};
  border: 1px solid ${props => (props.error ? COLORS.RED : COLORS.GRAY_300)};
  border-radius: 6px;
  transition: all 0.15s ease;
  overflow: hidden;

  span {
    font-family: Menlo, monospace;
    color: ${COLORS.GRAY_500};
    padding-left: 12px;
    font-size: 12px;
  }

  input,
  input:focus {
    outline: none;
    border: none;
    font-size: 12px;
    padding: 12px;
    padding-left: 0;
    font-family: Menlo, monospace;
    flex: 1;
  }
`;

const Page = ({ value, onChange, onDelete, isNew, placeholder, domain, index }) => {
  const { t } = useTranslation();
  return (
    <PageStyle isNew={isNew}>
      {isNew ? <label>{t('embed_form_new_page_label')}</label> : <label>#{index + 1}</label>}
      <PageInput>
        <span className="domain" onClick={e => e.target.parentNode.querySelector('input').focus()}>
          {domain.endsWith('/') ? domain.slice(0, -1) : domain}
        </span>
        <input
          type="text"
          placeholder={placeholder}
          value={value.url}
          onChange={e => onChange({ url: e.target.value, regex: value.regex })}
        />
      </PageInput>
      <label>
        <input type="checkbox" checked={value.regex} onChange={e => onChange({ url: value.url, regex: e.target.checked })} />{' '}
        {t('embed_regex_label')}
      </label>
      {!isNew ? (
        <ActionButton tertiary onClick={onDelete}>
          <Icon name="close" />
        </ActionButton>
      ) : (
        <ActionButton primary>
          <Icon name="add" />
        </ActionButton>
      )}
    </PageStyle>
  );
};

export default Pages;
export { Page, PageInput, PageStyle };

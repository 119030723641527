const products = [
  {
    id: 'prod_enterpriseMeteredTest',
    metadata: {
      meteredCriteria: 'pageViews',
      statsAllowedAggregateBy: 'day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsKpi: 'pageview,visitor,newVisitor,consentRate,interactionRate,optinRate,hits,quickBounceRate,consentRateMobile,consentRateDesktop,interactionRateMobile,interactionRateDesktop',
      statsPeriod: 'lastYear',
      statsWidgets: 'dailyOverview,optInRate,dailyVisitsOverview,visitsByHosts,consentDistribution,optInRateByDevice,devicesDistribution,consentDistributionByDevice,optInRateByConfig,dailyRates,interactionRate,bounceRateByDuration',
      title_EN: 'Graduated Enterprise plan',
      title_FR: 'Offre Entreprise progressive',
      zd_priority: 'high'
    },
    active: true,
    name: 'Graduated Enterprise',
    type: 'service',
    prices: [
      {
        id: 'price_1Plp1fB0P8XBvhyKikskTHPf',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1723195343,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customPrice: 'true',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1Plp1EB0P8XBvhyKLS1c0S8y',
          graduatedFirstPaidTierFees: '{"eur":0.00032,"usd":0.00032,"cad":0.00032,"chf":0.00032,"gbp":0.00032}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":5001,"usd":5001,"cad":5001,"chf":5001,"gbp":5001}',
          yearlyMinUsage: '12000000'
        },
        nickname: 'yearly 12M page views/year (custom 5001)',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.032',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Plp1EB0P8XBvhyKLS1c0S8y',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1723195316,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customPrice: 'true',
          multiCurrency: 'true',
          tieredPriceId: 'price_1Plp1fB0P8XBvhyKikskTHPf',
          yearlyFlatFees: '{"eur":5001,"usd":5001,"cad":5001,"chf":5001,"gbp":5001}'
        },
        nickname: 'Flat fee for yearly 12M page views/year (custom 5001)',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 500100,
        unit_amount_decimal: '500100'
      },
      {
        id: 'price_1OgSL4B0P8XBvhyKUPweEWzV',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140158,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSL4B0P8XBvhyKXnGSUa4r',
          graduatedFirstPaidTierFees: '{"eur":0.0000035,"usd":0.0000035,"cad":0.0000035,"chf":0.0000035,"gbp":0.0000035}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":200000,"usd":200000,"cad":200000,"chf":200000,"gbp":200000}',
          yearlyMinUsage: '50000000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 50B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 50000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.00035',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSL4B0P8XBvhyKXnGSUa4r',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140158,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSL4B0P8XBvhyKUPweEWzV',
          yearlyFlatFees: '{"eur":200000,"usd":200000,"cad":200000,"chf":200000,"gbp":200000}'
        },
        nickname: 'Flat fee for yearly 50B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 20000000,
        unit_amount_decimal: '20000000'
      },
      {
        id: 'price_1OgSL1B0P8XBvhyKndmulrDl',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140155,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSL1B0P8XBvhyKdys2hyfw',
          graduatedFirstPaidTierFees: '{"eur":0.0000043,"usd":0.0000043,"cad":0.0000043,"chf":0.0000043,"gbp":0.0000043}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":160000,"usd":160000,"cad":160000,"chf":160000,"gbp":160000}',
          yearlyMinUsage: '35000000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 35B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 35000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.00043',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSL1B0P8XBvhyKdys2hyfw',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140155,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSL1B0P8XBvhyKndmulrDl',
          yearlyFlatFees: '{"eur":160000,"usd":160000,"cad":160000,"chf":160000,"gbp":160000}'
        },
        nickname: 'Flat fee for yearly 35B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 16000000,
        unit_amount_decimal: '16000000'
      },
      {
        id: 'price_1OgSKyB0P8XBvhyKcgqqk0k9',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140152,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKyB0P8XBvhyKEIUKaq5T',
          graduatedFirstPaidTierFees: '{"eur":0.000005,"usd":0.000005,"cad":0.000005,"chf":0.000005,"gbp":0.000005}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":110000,"usd":110000,"cad":110000,"chf":110000,"gbp":110000}',
          yearlyMinUsage: '20000000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 20B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0005',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKyB0P8XBvhyKEIUKaq5T',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140152,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKyB0P8XBvhyKcgqqk0k9',
          yearlyFlatFees: '{"eur":110000,"usd":110000,"cad":110000,"chf":110000,"gbp":110000}'
        },
        nickname: 'Flat fee for yearly 20B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 11000000,
        unit_amount_decimal: '11000000'
      },
      {
        id: 'price_1OgSKvB0P8XBvhyKT2fe4ibZ',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140149,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKvB0P8XBvhyKYJNU6JYN',
          graduatedFirstPaidTierFees: '{"eur":0.0000057999999999999995,"usd":0.0000057999999999999995,"cad":0.0000057999999999999995,"chf":0.0000057999999999999995,"gbp":0.0000057999999999999995}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":90000,"usd":90000,"cad":90000,"chf":90000,"gbp":90000}',
          yearlyMinUsage: '15000000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 15B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 15000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.00058',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKvB0P8XBvhyKYJNU6JYN',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140149,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKvB0P8XBvhyKT2fe4ibZ',
          yearlyFlatFees: '{"eur":90000,"usd":90000,"cad":90000,"chf":90000,"gbp":90000}'
        },
        nickname: 'Flat fee for yearly 15B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9000000,
        unit_amount_decimal: '9000000'
      },
      {
        id: 'price_1OgSKtB0P8XBvhyKtzgfeXt9',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140147,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKsB0P8XBvhyK2E7Q9AaM',
          graduatedFirstPaidTierFees: '{"eur":0.0000065,"usd":0.0000065,"cad":0.0000065,"chf":0.0000065,"gbp":0.0000065}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":70000,"usd":70000,"cad":70000,"chf":70000,"gbp":70000}',
          yearlyMinUsage: '10000000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 10B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 10000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.00065',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKsB0P8XBvhyK2E7Q9AaM',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140146,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKtB0P8XBvhyKtzgfeXt9',
          yearlyFlatFees: '{"eur":70000,"usd":70000,"cad":70000,"chf":70000,"gbp":70000}'
        },
        nickname: 'Flat fee for yearly 10B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 7000000,
        unit_amount_decimal: '7000000'
      },
      {
        id: 'price_1OgSKpB0P8XBvhyKiR9ZHgqk',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140143,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKpB0P8XBvhyKZdXgsWPj',
          graduatedFirstPaidTierFees: '{"eur":0.000008999999999999999,"usd":0.000008999999999999999,"cad":0.000008999999999999999,"chf":0.000008999999999999999,"gbp":0.000008999999999999999}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":50000,"usd":50000,"cad":50000,"chf":50000,"gbp":50000}',
          yearlyMinUsage: '5000000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 5B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0009',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKpB0P8XBvhyKZdXgsWPj',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140143,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKpB0P8XBvhyKiR9ZHgqk',
          yearlyFlatFees: '{"eur":50000,"usd":50000,"cad":50000,"chf":50000,"gbp":50000}'
        },
        nickname: 'Flat fee for yearly 5B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 5000000,
        unit_amount_decimal: '5000000'
      },
      {
        id: 'price_1OgSKmB0P8XBvhyKg5UJ1etC',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140140,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKmB0P8XBvhyKjI7VOO7w',
          graduatedFirstPaidTierFees: '{"eur":0.000011,"usd":0.000011,"cad":0.000011,"chf":0.000011,"gbp":0.000011}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":37000,"usd":37000,"cad":37000,"chf":37000,"gbp":37000}',
          yearlyMinUsage: '3000000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 3B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 3000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0011',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKmB0P8XBvhyKjI7VOO7w',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140140,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKmB0P8XBvhyKg5UJ1etC',
          yearlyFlatFees: '{"eur":37000,"usd":37000,"cad":37000,"chf":37000,"gbp":37000}'
        },
        nickname: 'Flat fee for yearly 3B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 3700000,
        unit_amount_decimal: '3700000'
      },
      {
        id: 'price_1OgSKkB0P8XBvhyKqcDOXDnV',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140138,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKjB0P8XBvhyKdmQl9KPJ',
          graduatedFirstPaidTierFees: '{"eur":0.000014999999999999999,"usd":0.000014999999999999999,"cad":0.000014999999999999999,"chf":0.000014999999999999999,"gbp":0.000014999999999999999}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":26000,"usd":26000,"cad":26000,"chf":26000,"gbp":26000}',
          yearlyMinUsage: '1500000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 1.5B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1500000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0015',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKjB0P8XBvhyKdmQl9KPJ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140137,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKkB0P8XBvhyKqcDOXDnV',
          yearlyFlatFees: '{"eur":26000,"usd":26000,"cad":26000,"chf":26000,"gbp":26000}'
        },
        nickname: 'Flat fee for yearly 1.5B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2600000,
        unit_amount_decimal: '2600000'
      },
      {
        id: 'price_1OgSKhB0P8XBvhyKN4id2KA2',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140135,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKgB0P8XBvhyKtGgTH5Ge',
          graduatedFirstPaidTierFees: '{"eur":0.00002,"usd":0.00002,"cad":0.00002,"chf":0.00002,"gbp":0.00002}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":22000,"usd":22000,"cad":22000,"chf":22000,"gbp":22000}',
          yearlyMinUsage: '1000000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 1B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.002',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKgB0P8XBvhyKtGgTH5Ge',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140134,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKhB0P8XBvhyKN4id2KA2',
          yearlyFlatFees: '{"eur":22000,"usd":22000,"cad":22000,"chf":22000,"gbp":22000}'
        },
        nickname: 'Flat fee for yearly 1B page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2200000,
        unit_amount_decimal: '2200000'
      },
      {
        id: 'price_1OgSKdB0P8XBvhyKqAggXlFw',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140131,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKdB0P8XBvhyKcJEozwfI',
          graduatedFirstPaidTierFees: '{"eur":0.000026,"usd":0.000026,"cad":0.000026,"chf":0.000026,"gbp":0.000026}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}',
          yearlyMinUsage: '500000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 500M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0026',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKdB0P8XBvhyKcJEozwfI',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140131,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKdB0P8XBvhyKqAggXlFw',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}'
        },
        nickname: 'Flat fee for yearly 500M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500000,
        unit_amount_decimal: '1500000'
      },
      {
        id: 'price_1OgSKbB0P8XBvhyKD11ksd1o',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140129,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKaB0P8XBvhyKYmahS5qb',
          graduatedFirstPaidTierFees: '{"eur":0.00004,"usd":0.00004,"cad":0.00004,"chf":0.00004,"gbp":0.00004}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":10000,"usd":10000,"cad":10000,"chf":10000,"gbp":10000}',
          yearlyMinUsage: '200000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 200M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 200000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.004',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKaB0P8XBvhyKYmahS5qb',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140128,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKbB0P8XBvhyKD11ksd1o',
          yearlyFlatFees: '{"eur":10000,"usd":10000,"cad":10000,"chf":10000,"gbp":10000}'
        },
        nickname: 'Flat fee for yearly 200M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1000000,
        unit_amount_decimal: '1000000'
      },
      {
        id: 'price_1OgSKYB0P8XBvhyKWYBIZtJ4',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140126,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKXB0P8XBvhyKmaUTNxyb',
          graduatedFirstPaidTierFees: '{"eur":0.00006500000000000001,"usd":0.00006500000000000001,"cad":0.00006500000000000001,"chf":0.00006500000000000001,"gbp":0.00006500000000000001}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}',
          yearlyMinUsage: '100000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 100M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 100000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0065',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKXB0P8XBvhyKmaUTNxyb',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140125,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKYB0P8XBvhyKWYBIZtJ4',
          yearlyFlatFees: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}'
        },
        nickname: 'Flat fee for yearly 100M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 800000,
        unit_amount_decimal: '800000'
      },
      {
        id: 'price_1OgSKTB0P8XBvhyKkQOzN3yK',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140121,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSKSB0P8XBvhyKYrxsCIr4',
          graduatedFirstPaidTierFees: '{"eur":0.00011,"usd":0.00011,"cad":0.00011,"chf":0.00011,"gbp":0.00011}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":7000,"usd":7000,"cad":7000,"chf":7000,"gbp":7000}',
          yearlyMinUsage: '50000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 50M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 50000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.011',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSKSB0P8XBvhyKYrxsCIr4',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140120,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKTB0P8XBvhyKkQOzN3yK',
          yearlyFlatFees: '{"eur":7000,"usd":7000,"cad":7000,"chf":7000,"gbp":7000}'
        },
        nickname: 'Flat fee for yearly 50M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 700000,
        unit_amount_decimal: '700000'
      },
      {
        id: 'price_1OgSKQB0P8XBvhyKPqe84zoE',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140118,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSK6B0P8XBvhyKRQrf9f5r',
          graduatedFirstPaidTierFees: '{"eur":0.00019,"usd":0.00019,"cad":0.00019,"chf":0.00019,"gbp":0.00019}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":6000,"usd":6000,"cad":6000,"chf":6000,"gbp":6000}',
          yearlyMinUsage: '25000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 25M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 25000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.019',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSK6B0P8XBvhyKRQrf9f5r',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140098,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSKQB0P8XBvhyKPqe84zoE',
          yearlyFlatFees: '{"eur":6000,"usd":6000,"cad":6000,"chf":6000,"gbp":6000}'
        },
        nickname: 'Flat fee for yearly 25M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 600000,
        unit_amount_decimal: '600000'
      },
      {
        id: 'price_1OgSK4B0P8XBvhyKCrdqtuxq',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707140096,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgSJfB0P8XBvhyKAsjmlR7L',
          graduatedFirstPaidTierFees: '{"eur":0.00032,"usd":0.00032,"cad":0.00032,"chf":0.00032,"gbp":0.00032}',
          multiCurrency: 'true',
          services: 'cookies,processings,contracts,dpo,portability',
          yearlyFlatFees: '{"eur":4800,"usd":4800,"cad":4800,"chf":4800,"gbp":4800}',
          yearlyMinUsage: '12000000',
          zd_priority: 'high'
        },
        nickname: 'yearly 12M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.032',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgSJfB0P8XBvhyKAsjmlR7L',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707140071,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgSK4B0P8XBvhyKCrdqtuxq',
          yearlyFlatFees: '{"eur":4800,"usd":4800,"cad":4800,"chf":4800,"gbp":4800}'
        },
        nickname: 'Flat fee for yearly 12M page views/year',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 480000,
        unit_amount_decimal: '480000'
      },
      {
        id: 'price_1LdV0oB0P8XBvhyKdIUVT6jw',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1662106442,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 249000,
            unit_amount_decimal: '249000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_enterpriseMeteredTest',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 249000,
        unit_amount_decimal: '249000'
      },
      {
        id: 'price_1LNC0OB0P8XBvhyKFvSPVaAm',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220132,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2160000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0025',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNC0NB0P8XBvhyKhxLLwdOr',
          monthlyFlatFee: '4506',
          monthlyMinUsage: '180000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 180M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2160000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0025',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNC0NB0P8XBvhyKhxLLwdOr',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220131,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4506000,
            unit_amount_decimal: '4506000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNC0OB0P8XBvhyKFvSPVaAm'
        },
        nickname: 'Flat fee for  yearly 180M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4506000,
        unit_amount_decimal: '4506000'
      },
      {
        id: 'price_1LNC0KB0P8XBvhyKBwtTLoHG',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220128,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1920000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0028',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNC0KB0P8XBvhyKxXmqf2q3',
          monthlyFlatFee: '4406',
          monthlyMinUsage: '160000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 160M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1920000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0028',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNC0KB0P8XBvhyKxXmqf2q3',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220128,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4406000,
            unit_amount_decimal: '4406000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNC0KB0P8XBvhyKBwtTLoHG'
        },
        nickname: 'Flat fee for  yearly 160M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4406000,
        unit_amount_decimal: '4406000'
      },
      {
        id: 'price_1LNC0HB0P8XBvhyKoGigv0d5',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220125,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1680000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.003',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNC0HB0P8XBvhyK9l2WkNoV',
          monthlyFlatFee: '4259',
          monthlyMinUsage: '140000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 140M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1680000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.003',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNC0HB0P8XBvhyK9l2WkNoV',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220125,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4259000,
            unit_amount_decimal: '4259000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNC0HB0P8XBvhyKoGigv0d5'
        },
        nickname: 'Flat fee for  yearly 140M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4259000,
        unit_amount_decimal: '4259000'
      },
      {
        id: 'price_1LNC0EB0P8XBvhyKevnrZkjs',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220122,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1440000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0034',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNC0EB0P8XBvhyKoXbeQKJ5',
          monthlyFlatFee: '4050',
          monthlyMinUsage: '120000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 120M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1440000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0034',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNC0EB0P8XBvhyKoXbeQKJ5',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220122,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4050000,
            unit_amount_decimal: '4050000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNC0EB0P8XBvhyKevnrZkjs'
        },
        nickname: 'Flat fee for  yearly 120M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4050000,
        unit_amount_decimal: '4050000'
      },
      {
        id: 'price_1LNC0BB0P8XBvhyKz0paNOD9',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220119,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0038',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNC0BB0P8XBvhyKqxS9qlLn',
          monthlyFlatFee: '3774',
          monthlyMinUsage: '100000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 100M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1200000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0038',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNC0BB0P8XBvhyKqxS9qlLn',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220119,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3774000,
            unit_amount_decimal: '3774000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNC0BB0P8XBvhyKz0paNOD9'
        },
        nickname: 'Flat fee for  yearly 100M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 3774000,
        unit_amount_decimal: '3774000'
      },
      {
        id: 'price_1LNC09B0P8XBvhyKYT5NoWtX',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220117,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 960000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNC08B0P8XBvhyKeupdYAaT',
          monthlyFlatFee: '3429',
          monthlyMinUsage: '80000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 80M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 960000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0043',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNC08B0P8XBvhyKeupdYAaT',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220116,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3429000,
            unit_amount_decimal: '3429000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNC09B0P8XBvhyKYT5NoWtX'
        },
        nickname: 'Flat fee for  yearly 80M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 3429000,
        unit_amount_decimal: '3429000'
      },
      {
        id: 'price_1LNC05B0P8XBvhyKwJi68x7l',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220113,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 840000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0046',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNC05B0P8XBvhyK8zrjivSr',
          monthlyFlatFee: '3199',
          monthlyMinUsage: '70000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 70M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 840000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0046',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNC05B0P8XBvhyK8zrjivSr',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220113,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3199000,
            unit_amount_decimal: '3199000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNC05B0P8XBvhyKwJi68x7l'
        },
        nickname: 'Flat fee for  yearly 70M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 3199000,
        unit_amount_decimal: '3199000'
      },
      {
        id: 'price_1LNC03B0P8XBvhyKm9gHHy63',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220111,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0057',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNC02B0P8XBvhyKAQ68YM7e',
          monthlyFlatFee: '2827',
          monthlyMinUsage: '50000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 50M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 600000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0057',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNC02B0P8XBvhyKAQ68YM7e',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220110,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2827000,
            unit_amount_decimal: '2827000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNC03B0P8XBvhyKm9gHHy63'
        },
        nickname: 'Flat fee for  yearly 50M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2827000,
        unit_amount_decimal: '2827000'
      },
      {
        id: 'price_1LNBzzB0P8XBvhyK94se2hj9',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220107,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 480000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0055',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzzB0P8XBvhyKEm76EoZA',
          monthlyFlatFee: '2195',
          monthlyMinUsage: '40000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 40M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 480000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0055',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzzB0P8XBvhyKEm76EoZA',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220107,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2195000,
            unit_amount_decimal: '2195000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzzB0P8XBvhyK94se2hj9'
        },
        nickname: 'Flat fee for  yearly 40M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2195000,
        unit_amount_decimal: '2195000'
      },
      {
        id: 'price_1LNBzxB0P8XBvhyK7OaMe5ZP',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220105,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 360000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0064',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzwB0P8XBvhyKhkjbcDgZ',
          monthlyFlatFee: '1908',
          monthlyMinUsage: '30000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 30M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 360000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0064',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzwB0P8XBvhyKhkjbcDgZ',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220104,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1908000,
            unit_amount_decimal: '1908000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzxB0P8XBvhyK7OaMe5ZP'
        },
        nickname: 'Flat fee for  yearly 30M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1908000,
        unit_amount_decimal: '1908000'
      },
      {
        id: 'price_1LNBzuB0P8XBvhyKYj5IckwH',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220102,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 300000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0062',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBztB0P8XBvhyKAXkZbMUp',
          monthlyFlatFee: '1556',
          monthlyMinUsage: '25000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 25M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 300000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0062',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBztB0P8XBvhyKAXkZbMUp',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220101,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1556000,
            unit_amount_decimal: '1556000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzuB0P8XBvhyKYj5IckwH'
        },
        nickname: 'Flat fee for  yearly 25M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1556000,
        unit_amount_decimal: '1556000'
      },
      {
        id: 'price_1LNBzqB0P8XBvhyKERJ5FQIu',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220098,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.007',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzpB0P8XBvhyKPIb2gI3i',
          monthlyFlatFee: '1409',
          monthlyMinUsage: '20000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 20M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 240000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.007',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzpB0P8XBvhyKPIb2gI3i',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220097,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1409000,
            unit_amount_decimal: '1409000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzqB0P8XBvhyKERJ5FQIu'
        },
        nickname: 'Flat fee for  yearly 20M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1409000,
        unit_amount_decimal: '1409000'
      },
      {
        id: 'price_1LNBzmB0P8XBvhyKJZw3t8A2',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220094,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 180000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0082',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzmB0P8XBvhyKQd3E0iuV',
          monthlyFlatFee: '1225',
          monthlyMinUsage: '15000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 15M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 180000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0082',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzmB0P8XBvhyKQd3E0iuV',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220094,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1225000,
            unit_amount_decimal: '1225000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzmB0P8XBvhyKJZw3t8A2'
        },
        nickname: 'Flat fee for  yearly 15M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1225000,
        unit_amount_decimal: '1225000'
      },
      {
        id: 'price_1LNBzkB0P8XBvhyKncm92aeA',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220092,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.01',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzjB0P8XBvhyKn6stl8oA',
          monthlyFlatFee: '999',
          monthlyMinUsage: '10000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 10M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 120000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.01',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzjB0P8XBvhyKn6stl8oA',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220091,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 999000,
            unit_amount_decimal: '999000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzkB0P8XBvhyKncm92aeA'
        },
        nickname: 'Flat fee for  yearly 10M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 999000,
        unit_amount_decimal: '999000'
      },
      {
        id: 'price_1LNBzhB0P8XBvhyKoIcyLhkp',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220089,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 84000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0114',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzhB0P8XBvhyK1vo71ezo',
          monthlyFlatFee: '799',
          monthlyMinUsage: '7000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 7M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 84000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0114',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzhB0P8XBvhyK1vo71ezo',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220089,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 799000,
            unit_amount_decimal: '799000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzhB0P8XBvhyKoIcyLhkp'
        },
        nickname: 'Flat fee for  yearly 7M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 799000,
        unit_amount_decimal: '799000'
      },
      {
        id: 'price_1LNBzeB0P8XBvhyKbxGWeKNw',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220086,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 72000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0117',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzeB0P8XBvhyKHWT8nkyc',
          monthlyFlatFee: '699',
          monthlyMinUsage: '6000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 6M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 72000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0117',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzeB0P8XBvhyKHWT8nkyc',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220086,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 699000,
            unit_amount_decimal: '699000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzeB0P8XBvhyKbxGWeKNw'
        },
        nickname: 'Flat fee for  yearly 6M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 699000,
        unit_amount_decimal: '699000'
      },
      {
        id: 'price_1LNBzbB0P8XBvhyKu0rRrUMq',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220083,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.012',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzbB0P8XBvhyKSC4Q4rW2',
          monthlyFlatFee: '599',
          monthlyMinUsage: '5000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 5M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.012',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzbB0P8XBvhyKSC4Q4rW2',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220083,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 599000,
            unit_amount_decimal: '599000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzbB0P8XBvhyKu0rRrUMq'
        },
        nickname: 'Flat fee for  yearly 5M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 599000,
        unit_amount_decimal: '599000'
      },
      {
        id: 'price_1LNBzXB0P8XBvhyKrMhghj7N',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220079,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 36000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0183',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzXB0P8XBvhyKro5MxRiO',
          monthlyFlatFee: '549',
          monthlyMinUsage: '3000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 3M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 36000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0183',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzXB0P8XBvhyKro5MxRiO',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220079,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 549000,
            unit_amount_decimal: '549000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzXB0P8XBvhyKrMhghj7N'
        },
        nickname: 'Flat fee for  yearly 3M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 549000,
        unit_amount_decimal: '549000'
      },
      {
        id: 'price_1LNBzUB0P8XBvhyK4t3wDcl1',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220076,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 24000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0225',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzUB0P8XBvhyK4lND69sZ',
          monthlyFlatFee: '449',
          monthlyMinUsage: '2000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 2M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 24000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0225',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzUB0P8XBvhyK4lND69sZ',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220076,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 449000,
            unit_amount_decimal: '449000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzUB0P8XBvhyK4t3wDcl1'
        },
        nickname: 'Flat fee for  yearly 2M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 449000,
        unit_amount_decimal: '449000'
      },
      {
        id: 'price_1LNBzRB0P8XBvhyKlriLD4LB',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220073,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0233',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzRB0P8XBvhyKkeZgxARX',
          monthlyFlatFee: '349',
          monthlyMinUsage: '1500000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 1,5M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 18000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0233',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzRB0P8XBvhyKkeZgxARX',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220073,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 349000,
            unit_amount_decimal: '349000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzRB0P8XBvhyKlriLD4LB'
        },
        nickname: 'Flat fee for  yearly 1,5M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 349000,
        unit_amount_decimal: '349000'
      },
      {
        id: 'price_1LNBzOB0P8XBvhyK0Gwa7CXR',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220070,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0299',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzNB0P8XBvhyKeKOXoNO8',
          monthlyFlatFee: '299',
          monthlyMinUsage: '1000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 1M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0299',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzNB0P8XBvhyKeKOXoNO8',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220069,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 299000,
            unit_amount_decimal: '299000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzOB0P8XBvhyK0Gwa7CXR'
        },
        nickname: 'Flat fee for  yearly 1M page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 299000,
        unit_amount_decimal: '299000'
      },
      {
        id: 'price_1LNBzLB0P8XBvhyKoPFnBkZQ',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220067,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0498',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1LNBzLB0P8XBvhyKtJV5wfvT',
          monthlyFlatFee: '249',
          monthlyMinUsage: '500000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 500k page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0498',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzLB0P8XBvhyKtJV5wfvT',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220067,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 249000,
            unit_amount_decimal: '249000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LNBzLB0P8XBvhyKoPFnBkZQ'
        },
        nickname: 'Flat fee for  yearly 500k page views/month',
        product: 'prod_enterpriseMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 249000,
        unit_amount_decimal: '249000'
      }
    ]
  },
  {
    id: 'prod_termsTest',
    metadata: {
      WARNING: 'DO NOT EDIT METADATA !!! 🙏',
      meteredCriteria: 'termsConsents',
      services: 'contractsV2',
      title_EN: 'Contractual Consent Management (Terms)',
      title_FR: 'Recueil des consentements contractuels (Terms)',
      zd_priority: 'high'
    },
    active: true,
    name: 'Contractual Consent Management (Terms)',
    type: 'service',
    prices: [
      {
        id: 'price_1P95gAB0P8XBvhyKvBQ9vVMh',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713964566,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P95g7B0P8XBvhyKERaJJxQU',
          graduatedFirstPaidTierFees: '{"eur":0.0003,"usd":0.0003,"cad":0.0003,"chf":0.0003,"gbp":0.0003}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}',
          yearlyMinUsage: '20000000'
        },
        nickname: 'yearly Terms: 20M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.03',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P95g7B0P8XBvhyKERaJJxQU',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713964563,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P95gAB0P8XBvhyKvBQ9vVMh',
          yearlyFlatFees: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}'
        },
        nickname: 'Flat fee for yearly Terms : 20M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2000000,
        unit_amount_decimal: '2000000'
      },
      {
        id: 'price_1P95g5B0P8XBvhyKAYQTufyH',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713964561,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P95g3B0P8XBvhyKWtNBNOPn',
          graduatedFirstPaidTierFees: '{"eur":0.0005,"usd":0.0005,"cad":0.0005,"chf":0.0005,"gbp":0.0005}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}',
          yearlyMinUsage: '15000000'
        },
        nickname: 'yearly Terms: 15M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 15000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.05',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P95g3B0P8XBvhyKWtNBNOPn',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713964559,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P95g5B0P8XBvhyKAYQTufyH',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}'
        },
        nickname: 'Flat fee for yearly Terms : 15M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500000,
        unit_amount_decimal: '1500000'
      },
      {
        id: 'price_1P95g2B0P8XBvhyKTDIPngQN',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713964558,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P95g0B0P8XBvhyKKtBmpvG7',
          graduatedFirstPaidTierFees: '{"eur":0.0007,"usd":0.0007,"cad":0.0007,"chf":0.0007,"gbp":0.0007}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":12000,"usd":12000,"cad":12000,"chf":12000,"gbp":12000}',
          yearlyMinUsage: '8000000'
        },
        nickname: 'yearly Terms: 8M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 8000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.07',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P95g0B0P8XBvhyKKtBmpvG7',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713964556,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P95g2B0P8XBvhyKTDIPngQN',
          yearlyFlatFees: '{"eur":12000,"usd":12000,"cad":12000,"chf":12000,"gbp":12000}'
        },
        nickname: 'Flat fee for yearly Terms : 8M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1200000,
        unit_amount_decimal: '1200000'
      },
      {
        id: 'price_1P95fyB0P8XBvhyKgPhvCrEu',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713964554,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P95fwB0P8XBvhyKrmHmI4Fg',
          graduatedFirstPaidTierFees: '{"eur":0.001,"usd":0.001,"cad":0.001,"chf":0.001,"gbp":0.001}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}',
          yearlyMinUsage: '4000000'
        },
        nickname: 'yearly Terms: 4M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 4000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P95fwB0P8XBvhyKrmHmI4Fg',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713964552,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P95fyB0P8XBvhyKgPhvCrEu',
          yearlyFlatFees: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}'
        },
        nickname: 'Flat fee for yearly Terms : 4M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 800000,
        unit_amount_decimal: '800000'
      },
      {
        id: 'price_1P95fuB0P8XBvhyKI9TDvJiG',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713964550,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P95frB0P8XBvhyKGwVcAF0b',
          graduatedFirstPaidTierFees: '{"eur":0.0015,"usd":0.0015,"cad":0.0015,"chf":0.0015,"gbp":0.0015}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}',
          yearlyMinUsage: '2000000'
        },
        nickname: 'yearly Terms: 2M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.15',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P95frB0P8XBvhyKGwVcAF0b',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713964547,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P95fuB0P8XBvhyKI9TDvJiG',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}'
        },
        nickname: 'Flat fee for yearly Terms : 2M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 500000,
        unit_amount_decimal: '500000'
      },
      {
        id: 'price_1P95fpB0P8XBvhyKMNfRTBjF',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713964545,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P95fpB0P8XBvhyKiFwuKKe1',
          graduatedFirstPaidTierFees: '{"eur":0.002,"usd":0.002,"cad":0.002,"chf":0.002,"gbp":0.002}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":3000,"usd":3000,"cad":3000,"chf":3000,"gbp":3000}',
          yearlyMinUsage: '1000000'
        },
        nickname: 'yearly Terms: 1M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.2',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P95fpB0P8XBvhyKiFwuKKe1',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713964545,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P95fpB0P8XBvhyKMNfRTBjF',
          yearlyFlatFees: '{"eur":3000,"usd":3000,"cad":3000,"chf":3000,"gbp":3000}'
        },
        nickname: 'Flat fee for yearly Terms : 1M consents/year',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 300000,
        unit_amount_decimal: '300000'
      },
      {
        id: 'price_1P8kl6B0P8XBvhyKQqeM05ab',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713884148,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8kl4B0P8XBvhyKtRlolcQH',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}',
          yearlyMinUsage: '20000000'
        },
        nickname: 'yearly : 20M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.03',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8kl4B0P8XBvhyKtRlolcQH',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713884146,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8kl6B0P8XBvhyKQqeM05ab',
          yearlyFlatFee: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}'
        },
        nickname: 'Flat fee for yearly : 20M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2000000,
        unit_amount_decimal: '2000000'
      },
      {
        id: 'price_1P8kl2B0P8XBvhyK2KFZxXfM',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713884144,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8kl1B0P8XBvhyKqtxMmRV2',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}',
          yearlyMinUsage: '15000000'
        },
        nickname: 'yearly : 15M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 15000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.05',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8kl1B0P8XBvhyKqtxMmRV2',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713884143,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8kl2B0P8XBvhyK2KFZxXfM',
          yearlyFlatFee: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}'
        },
        nickname: 'Flat fee for yearly : 15M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500000,
        unit_amount_decimal: '1500000'
      },
      {
        id: 'price_1P8kkzB0P8XBvhyKI78zvRS9',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713884141,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8kkxB0P8XBvhyKxi06pxz1',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":12000,"usd":12000,"cad":12000,"chf":12000,"gbp":12000}',
          yearlyMinUsage: '8000000'
        },
        nickname: 'yearly : 8M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 8000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.07',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8kkxB0P8XBvhyKxi06pxz1',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713884139,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8kkzB0P8XBvhyKI78zvRS9',
          yearlyFlatFee: '{"eur":12000,"usd":12000,"cad":12000,"chf":12000,"gbp":12000}'
        },
        nickname: 'Flat fee for yearly : 8M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1200000,
        unit_amount_decimal: '1200000'
      },
      {
        id: 'price_1P8kkuB0P8XBvhyKrwg0x8QZ',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713884136,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8kksB0P8XBvhyK5Kj6JAUD',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}',
          yearlyMinUsage: '4000000'
        },
        nickname: 'yearly : 4M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 4000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8kksB0P8XBvhyK5Kj6JAUD',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713884134,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8kkuB0P8XBvhyKrwg0x8QZ',
          yearlyFlatFee: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}'
        },
        nickname: 'Flat fee for yearly : 4M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 800000,
        unit_amount_decimal: '800000'
      },
      {
        id: 'price_1P8kkqB0P8XBvhyKdMst2aK9',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713884132,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8kkoB0P8XBvhyK3j5S25F9',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}',
          yearlyMinUsage: '2000000'
        },
        nickname: 'yearly : 2M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.15',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8kkoB0P8XBvhyK3j5S25F9',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713884130,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8kkqB0P8XBvhyKdMst2aK9',
          yearlyFlatFee: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}'
        },
        nickname: 'Flat fee for yearly : 2M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 500000,
        unit_amount_decimal: '500000'
      },
      {
        id: 'price_1P8kkmB0P8XBvhyKfEXeKgmx',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713884128,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8kkmB0P8XBvhyK7uuFEYUq',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":3000,"usd":3000,"cad":3000,"chf":3000,"gbp":3000}',
          yearlyMinUsage: '1000000'
        },
        nickname: 'yearly : 1M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.2',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8kkmB0P8XBvhyK7uuFEYUq',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713884128,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8kkmB0P8XBvhyKfEXeKgmx',
          yearlyFlatFee: '{"eur":3000,"usd":3000,"cad":3000,"chf":3000,"gbp":3000}'
        },
        nickname: 'Flat fee for yearly : 1M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 300000,
        unit_amount_decimal: '300000'
      },
      {
        id: 'price_1P8kiJB0P8XBvhyKpckvTa4o',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713883975,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8kiHB0P8XBvhyKxOIm3s2s',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}',
          yearlyMinUsage: '20000000'
        },
        nickname: 'yearly : 20M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.03',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8kiHB0P8XBvhyKxOIm3s2s',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713883973,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8kiJB0P8XBvhyKpckvTa4o',
          yearlyFlatFee: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}'
        },
        nickname: 'Flat fee for yearly : 20M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2000000,
        unit_amount_decimal: '2000000'
      },
      {
        id: 'price_1P8kiFB0P8XBvhyKX8pDEj42',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713883971,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8kiDB0P8XBvhyKfjZcYFFm',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}',
          yearlyMinUsage: '15000000'
        },
        nickname: 'yearly : 15M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 15000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.05',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8kiDB0P8XBvhyKfjZcYFFm',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713883969,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8kiFB0P8XBvhyKX8pDEj42',
          yearlyFlatFee: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}'
        },
        nickname: 'Flat fee for yearly : 15M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500000,
        unit_amount_decimal: '1500000'
      },
      {
        id: 'price_1P8kiBB0P8XBvhyKA6G8119a',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713883967,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8kiAB0P8XBvhyKKA0Bvpie',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":12000,"usd":12000,"cad":12000,"chf":12000,"gbp":12000}',
          yearlyMinUsage: '8000000'
        },
        nickname: 'yearly : 8M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 8000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.07',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8kiAB0P8XBvhyKKA0Bvpie',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713883966,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8kiBB0P8XBvhyKA6G8119a',
          yearlyFlatFee: '{"eur":12000,"usd":12000,"cad":12000,"chf":12000,"gbp":12000}'
        },
        nickname: 'Flat fee for yearly : 8M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1200000,
        unit_amount_decimal: '1200000'
      },
      {
        id: 'price_1P8ki8B0P8XBvhyKhinzycVW',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713883964,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8ki6B0P8XBvhyKRCbplWdT',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}',
          yearlyMinUsage: '4000000'
        },
        nickname: 'yearly : 4M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 4000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8ki6B0P8XBvhyKRCbplWdT',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713883962,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8ki8B0P8XBvhyKhinzycVW',
          yearlyFlatFee: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}'
        },
        nickname: 'Flat fee for yearly : 4M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 800000,
        unit_amount_decimal: '800000'
      },
      {
        id: 'price_1P8ki4B0P8XBvhyKsSetRX0Q',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713883960,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8ki3B0P8XBvhyKBUSNQoUa',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}',
          yearlyMinUsage: '2000000'
        },
        nickname: 'yearly : 2M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.15',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8ki3B0P8XBvhyKBUSNQoUa',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713883959,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8ki4B0P8XBvhyKsSetRX0Q',
          yearlyFlatFee: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}'
        },
        nickname: 'Flat fee for yearly : 2M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 500000,
        unit_amount_decimal: '500000'
      },
      {
        id: 'price_1P8ki2B0P8XBvhyKQFLX0Rce',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713883958,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1P8ki1B0P8XBvhyKx2dMDmHd',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFee: '{"eur":3000,"usd":3000,"cad":3000,"chf":3000,"gbp":3000}',
          yearlyMinUsage: '1000000'
        },
        nickname: 'yearly : 1M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.2',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8ki1B0P8XBvhyKx2dMDmHd',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713883957,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8ki2B0P8XBvhyKQFLX0Rce',
          yearlyFlatFee: '{"eur":3000,"usd":3000,"cad":3000,"chf":3000,"gbp":3000}'
        },
        nickname: 'Flat fee for yearly : 1M monthly active users',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 300000,
        unit_amount_decimal: '300000'
      },
      {
        id: 'price_1Mo22MB0P8XBvhyKJ1CrQCJ4',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679393006,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.114',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo22JB0P8XBvhyKmterKTew',
          yearlyFlatFee: '11400',
          yearlyMinUsage: '10000000'
        },
        nickname: 'yearly Terms for Enterprise (10M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 10000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.114',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo22JB0P8XBvhyKmterKTew',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679393003,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1140000,
            unit_amount_decimal: '1140000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo22MB0P8XBvhyKJ1CrQCJ4'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (10M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1140000,
        unit_amount_decimal: '1140000'
      },
      {
        id: 'price_1Mo22IB0P8XBvhyKFdLC2ZCW',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679393002,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 9000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1056',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo22GB0P8XBvhyKrVMrfa1N',
          yearlyFlatFee: '9500',
          yearlyMinUsage: '9000000'
        },
        nickname: 'yearly Terms for Enterprise (9M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 9000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1056',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo22GB0P8XBvhyKrVMrfa1N',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679393000,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 950000,
            unit_amount_decimal: '950000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo22IB0P8XBvhyKFdLC2ZCW'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (9M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 950000,
        unit_amount_decimal: '950000'
      },
      {
        id: 'price_1Mo22FB0P8XBvhyKC6w50duK',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679392999,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0988',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo22DB0P8XBvhyKxvRS1BqM',
          yearlyFlatFee: '7900',
          yearlyMinUsage: '8000000'
        },
        nickname: 'yearly Terms for Enterprise (8M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 8000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0988',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo22DB0P8XBvhyKxvRS1BqM',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392997,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 790000,
            unit_amount_decimal: '790000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo22FB0P8XBvhyKC6w50duK'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (8M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 790000,
        unit_amount_decimal: '790000'
      },
      {
        id: 'price_1Mo22AB0P8XBvhyKG2Tllh2Y',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679392994,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 7000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0943',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo228B0P8XBvhyK5rBz4yXa',
          yearlyFlatFee: '6600',
          yearlyMinUsage: '7000000'
        },
        nickname: 'yearly Terms for Enterprise (7M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 7000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0943',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo228B0P8XBvhyK5rBz4yXa',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392992,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 660000,
            unit_amount_decimal: '660000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo22AB0P8XBvhyKG2Tllh2Y'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (7M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 660000,
        unit_amount_decimal: '660000'
      },
      {
        id: 'price_1Mo227B0P8XBvhyKHBUEUwiK',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679392991,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0917',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo225B0P8XBvhyK27801whA',
          yearlyFlatFee: '5500',
          yearlyMinUsage: '6000000'
        },
        nickname: 'yearly Terms for Enterprise (6M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0917',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo225B0P8XBvhyK27801whA',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392989,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 550000,
            unit_amount_decimal: '550000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo227B0P8XBvhyKHBUEUwiK'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (6M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 550000,
        unit_amount_decimal: '550000'
      },
      {
        id: 'price_1Mo222B0P8XBvhyK4ctVKd55',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679392986,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.092',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo222B0P8XBvhyK8m3SHjD7',
          yearlyFlatFee: '4600',
          yearlyMinUsage: '5000000'
        },
        nickname: 'yearly Terms for Enterprise (5M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.092',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo222B0P8XBvhyK8m3SHjD7',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392986,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 460000,
            unit_amount_decimal: '460000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo222B0P8XBvhyK4ctVKd55'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (5M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 460000,
        unit_amount_decimal: '460000'
      },
      {
        id: 'price_1Mo21IB0P8XBvhyKthzmLv72',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679392940,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1025',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo21HB0P8XBvhyKFOmGmGMg',
          yearlyFlatFee: '4100',
          yearlyMinUsage: '4000000'
        },
        nickname: 'yearly Terms for Enterprise (4M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 4000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1025',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo21HB0P8XBvhyKFOmGmGMg',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392939,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 410000,
            unit_amount_decimal: '410000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo21IB0P8XBvhyKthzmLv72'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (4M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 410000,
        unit_amount_decimal: '410000'
      },
      {
        id: 'price_1Mo206B0P8XBvhyKbyFrFsGJ',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679392866,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1233',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo205B0P8XBvhyKRRAGrVse',
          yearlyFlatFee: '3700',
          yearlyMinUsage: '3000000'
        },
        nickname: 'yearly Terms for Enterprise (3M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 3000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1233',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo205B0P8XBvhyKRRAGrVse',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392865,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 370000,
            unit_amount_decimal: '370000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo206B0P8XBvhyKbyFrFsGJ'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (3M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 370000,
        unit_amount_decimal: '370000'
      },
      {
        id: 'price_1Mo1y3B0P8XBvhyKlm3zgOil',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679392739,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1675',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo1y2B0P8XBvhyK2CvnaST8',
          yearlyFlatFee: '3350',
          yearlyMinUsage: '2000000'
        },
        nickname: 'yearly Terms for Enterprise (2M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1675',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo1y2B0P8XBvhyK2CvnaST8',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392738,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 335000,
            unit_amount_decimal: '335000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo1y3B0P8XBvhyKlm3zgOil'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (2M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 335000,
        unit_amount_decimal: '335000'
      },
      {
        id: 'price_1Mo1xhB0P8XBvhyKdLK836oQ',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679392717,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.3',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1Mo1xgB0P8XBvhyK5mc0t85d',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '3000',
          yearlyMinUsage: '1000000'
        },
        nickname: 'yearly Terms for Enterprise (1M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.3',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mo1xgB0P8XBvhyK5mc0t85d',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392716,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          tieredPriceId: 'price_1Mo1xhB0P8XBvhyKdLK836oQ'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (1M consents/year)',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 300000,
        unit_amount_decimal: '300000'
      },
      {
        id: 'price_1Mo1xeB0P8XBvhyKhQxmtlMc',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392714,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19900,
            unit_amount_decimal: '19900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'agency'
        },
        nickname: 'monthly Terms for Agency',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19900,
        unit_amount_decimal: '19900'
      },
      {
        id: 'price_1Mo1xdB0P8XBvhyKTzfCELTD',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392713,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏'
        },
        nickname: 'yearly Terms Trial',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'price_1Mo1xbB0P8XBvhyK1fpeLIl1',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1679392711,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏'
        },
        nickname: 'monthly Terms Trial',
        product: 'prod_termsTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      }
    ]
  },
  {
    id: 'prod_appSdkTest',
    metadata: {
      WARNING: 'DO NOT EDIT METADATA !!! 🙏',
      features: 'appSdkTcf',
      label: 'App SDK',
      meteredCriteria: 'monthlyActiveUsers',
      statsAllowedAggregateBy: 'day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsKpi: 'appSdkUsers,appSdkNewUsers,appSdkInteractionRate,appSdkConsentRate,appSdkOptInRate,appSdkQuickBounceRate',
      statsPeriod: 'lastYear',
      statsWidgets: 'appSdkDailyConsentsOverview,appSdkDailyConsentsRates,appSdkDailyUsersOverview',
      title_EN: 'App SDK',
      title_FR: 'SDK App',
      zd_priority: 'high'
    },
    active: true,
    name: 'App SDK',
    type: 'service',
    prices: [
      {
        id: 'price_1P951JB0P8XBvhyKRf4L9ILX',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962033,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P951IB0P8XBvhyKX0AzCnFy',
          graduatedFirstPaidTierFees: '{"eur":0.0005,"usd":0.0005,"cad":0.0005,"chf":0.0005,"gbp":0.0005}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '50000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":40000,"usd":40000,"cad":40000,"chf":40000,"gbp":40000}',
          yearlyMinUsage: '600000000'
        },
        nickname: 'yearly : 50M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 600000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.05',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P951IB0P8XBvhyKX0AzCnFy',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962032,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P951JB0P8XBvhyKRf4L9ILX',
          yearlyFlatFees: '{"eur":40000,"usd":40000,"cad":40000,"chf":40000,"gbp":40000}'
        },
        nickname: 'Flat fee for yearly : 50M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4000000,
        unit_amount_decimal: '4000000'
      },
      {
        id: 'price_1P951HB0P8XBvhyK4W8ufX99',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962031,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P951FB0P8XBvhyKZtsJfHFv',
          graduatedFirstPaidTierFees: '{"eur":0.001,"usd":0.001,"cad":0.001,"chf":0.001,"gbp":0.001}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '20000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":25000,"usd":25000,"cad":25000,"chf":25000,"gbp":25000}',
          yearlyMinUsage: '240000000'
        },
        nickname: 'yearly : 20M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 240000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P951FB0P8XBvhyKZtsJfHFv',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962029,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P951HB0P8XBvhyK4W8ufX99',
          yearlyFlatFees: '{"eur":25000,"usd":25000,"cad":25000,"chf":25000,"gbp":25000}'
        },
        nickname: 'Flat fee for yearly : 20M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2500000,
        unit_amount_decimal: '2500000'
      },
      {
        id: 'price_1P951DB0P8XBvhyKQmG1Fm1V',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962027,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P951BB0P8XBvhyKLv8EFAE7',
          graduatedFirstPaidTierFees: '{"eur":0.0015,"usd":0.0015,"cad":0.0015,"chf":0.0015,"gbp":0.0015}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '10000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}',
          yearlyMinUsage: '120000000'
        },
        nickname: 'yearly : 10M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 120000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.15',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P951BB0P8XBvhyKLv8EFAE7',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962025,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P951DB0P8XBvhyKQmG1Fm1V',
          yearlyFlatFees: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}'
        },
        nickname: 'Flat fee for yearly : 10M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2000000,
        unit_amount_decimal: '2000000'
      },
      {
        id: 'price_1P951AB0P8XBvhyKozwW8PPl',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962024,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P9519B0P8XBvhyKiBubMHIr',
          graduatedFirstPaidTierFees: '{"eur":0.0025,"usd":0.0025,"cad":0.0025,"chf":0.0025,"gbp":0.0025}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '5000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}',
          yearlyMinUsage: '60000000'
        },
        nickname: 'yearly : 5M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.25',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P9519B0P8XBvhyKiBubMHIr',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962023,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P951AB0P8XBvhyKozwW8PPl',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}'
        },
        nickname: 'Flat fee for yearly : 5M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500000,
        unit_amount_decimal: '1500000'
      },
      {
        id: 'price_1P9516B0P8XBvhyKTvwOths8',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962020,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P9514B0P8XBvhyKMBisefBz',
          graduatedFirstPaidTierFees: '{"eur":0.005,"usd":0.005,"cad":0.005,"chf":0.005,"gbp":0.005}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '1500000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":10000,"usd":10000,"cad":10000,"chf":10000,"gbp":10000}',
          yearlyMinUsage: '18000000'
        },
        nickname: 'yearly : 1.5M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 18000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.5',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P9514B0P8XBvhyKMBisefBz',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962018,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P9516B0P8XBvhyKTvwOths8',
          yearlyFlatFees: '{"eur":10000,"usd":10000,"cad":10000,"chf":10000,"gbp":10000}'
        },
        nickname: 'Flat fee for yearly : 1.5M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1000000,
        unit_amount_decimal: '1000000'
      },
      {
        id: 'price_1P9513B0P8XBvhyKkKMSVvoF',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962017,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P9512B0P8XBvhyKDS20XWWw',
          graduatedFirstPaidTierFees: '{"eur":0.007,"usd":0.007,"cad":0.007,"chf":0.007,"gbp":0.007}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '1000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":7500,"usd":7500,"cad":7500,"chf":7500,"gbp":7500}',
          yearlyMinUsage: '12000000'
        },
        nickname: 'yearly : 1M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.7',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P9512B0P8XBvhyKDS20XWWw',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962016,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P9513B0P8XBvhyKkKMSVvoF',
          yearlyFlatFees: '{"eur":7500,"usd":7500,"cad":7500,"chf":7500,"gbp":7500}'
        },
        nickname: 'Flat fee for yearly : 1M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 750000,
        unit_amount_decimal: '750000'
      },
      {
        id: 'price_1P9510B0P8XBvhyKvwQDuhiV',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962014,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P950zB0P8XBvhyK49GofYqJ',
          graduatedFirstPaidTierFees: '{"eur":0.00875,"usd":0.00875,"cad":0.00875,"chf":0.00875,"gbp":0.00875}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '500000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}',
          yearlyMinUsage: '6000000'
        },
        nickname: 'yearly : 500K monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 0,
            flat_amount_decimal: '0',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: 0,
            flat_amount_decimal: '0',
            unit_amount: null,
            unit_amount_decimal: '0.875',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P950zB0P8XBvhyK49GofYqJ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962013,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P9510B0P8XBvhyKvwQDuhiV',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}'
        },
        nickname: 'Flat fee for yearly : 500K monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 500000,
        unit_amount_decimal: '500000'
      },
      {
        id: 'price_1P8N14B0P8XBvhyKCnV5HpCl',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713792882,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P8N12B0P8XBvhyKs63klvfj',
          graduatedFirstPaidTierFees: '{"eur":0.007,"usd":0.007,"cad":0.007,"chf":0.007,"gbp":0.007}',
          label: 'Mobile SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '1000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":7500,"usd":7500,"cad":7500,"chf":7500,"gbp":7500}',
          yearlyMinUsage: '12000000'
        },
        nickname: 'yearly : 1M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.7',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P8N12B0P8XBvhyKs63klvfj',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713792880,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P8N14B0P8XBvhyKCnV5HpCl',
          yearlyFlatFees: '{"eur":7500,"usd":7500,"cad":7500,"chf":7500,"gbp":7500}'
        },
        nickname: 'Flat fee for yearly : 1M monthly active users',
        product: 'prod_appSdkTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 750000,
        unit_amount_decimal: '750000'
      }
    ]
  },
  {
    id: 'prod_mobileSdkTest',
    metadata: {
      WARNING: 'DO NOT EDIT METADATA !!! 🙏',
      features: 'appSdkTcf',
      meteredCriteria: 'monthlyActiveUsers',
      statsAllowedAggregateBy: 'day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsKpi: 'appSdkUsers,appSdkNewUsers,appSdkInteractionRate,appSdkConsentRate,appSdkOptInRate,appSdkQuickBounceRate',
      statsPeriod: 'lastYear',
      statsWidgets: 'appSdkDailyConsentsOverview,appSdkDailyConsentsRates,appSdkDailyUsersOverview',
      title_EN: 'App SDK',
      title_FR: 'SDK App',
      zd_priority: 'high'
    },
    active: false,
    name: 'App SDK',
    type: 'service',
    prices: [
      {
        id: 'price_1P6CYJB0P8XBvhyKbo8WgKzo',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1713276003,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P6CWDB0P8XBvhyKddZi3iS4',
          graduatedFirstPaidTierFees: '{"eur":0.00875,"usd":0.00875,"cad":0.00875,"chf":0.00875,"gbp":0.00875}',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '500000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}',
          yearlyMinUsage: '6000000'
        },
        nickname: 'yearly : 500K monthly active users',
        product: 'prod_mobileSdkTest',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.875',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P6CWDB0P8XBvhyKddZi3iS4',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713275873,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P6CYJB0P8XBvhyKbo8WgKzo',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}'
        },
        nickname: 'Flat fee for yearly : 500K monthly active users',
        product: 'prod_mobileSdkTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 500000,
        unit_amount_decimal: '500000'
      }
    ]
  },
  {
    id: 'prod_agencyMeteredTest',
    metadata: {
      backoffice_agency: 'metered_standard',
      features: 'customization',
      meteredCriteria: 'publishedProjects',
      services: 'cookies,dpo,automatorScanner,automatorStylesheet,automatorProvisioning,automatorEmailing,tcf,analyticsConnectors',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview',
      title_EN: 'Graduated Agency plan',
      title_FR: 'Offre Agence progressive',
      zd_priority: 'high'
    },
    active: true,
    name: 'Graduated Agency',
    type: 'service',
    prices: [
      {
        id: 'price_1Ox8QVB0P8XBvhyKnDC9DkdP',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1711115191,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_agencyMeteredTest',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'price_1OviMOB0P8XBvhyKp4pDSjwj',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1710776664,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 100000,
            unit_amount_decimal: '100000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 100000,
            unit_amount_decimal: '100000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 100000,
            unit_amount_decimal: '100000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 100000,
            unit_amount_decimal: '100000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 120000,
            unit_amount_decimal: '120000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Cookies scanner • Email support • Payment by bank transfer • Complete Analytics • Consent Wall • TCF • Dedicated CS • API Provisioning • AB testing • Max 750k page views/project',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Audit des traceurs • Support tel/mail • Paiement par virement • Stats complètes • Consent Wall • TCF • CS Dédié • Accès API • AB testing • Max 750k pages vues/projet',
          features: 'customization, consentWall',
          label: 'Agencies 2024',
          monthlyFlatFees: '{"eur":1000,"usd":1200,"cad":1000,"chf":1000,"gbp":1000}',
          multiCurrency: 'true',
          pageViewsLimit: '750000',
          partOfAutoUpscale: 'false',
          services: 'cookies,dpo,processings,automatorScanner,automatorStylesheet,automatorProvisioning,automatorEmailing',
          statsAllowedAggregateBy: 'day,week,month, year',
          statsAreFiltersAllowed: 'true',
          statsKpi: 'pageview,visitor,newVisitor,consentRate,interactionRate,optinRate,hits,quickBounceRate,consentRateMobile,consentRateDesktop,interactionRateMobile,interactionRateDesktop',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,optInRate,consentDistribution,visitsByHosts,consentDistributionByDevice,optInRateByDevice,devicesDistribution,bounceRateByDuration',
          zd_priority: 'high'
        },
        nickname: 'Agencies 2024 monthly',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 100000,
        unit_amount_decimal: '100000'
      },
      {
        id: 'price_1OFHFWB0P8XBvhyKD48mkC7u',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1700662674,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 3000,
                unit_amount_decimal: '3000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2000,
                unit_amount_decimal: '2000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2000,
                unit_amount_decimal: '2000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2000,
                unit_amount_decimal: '2000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2500,
                unit_amount_decimal: '2500',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Email support • Payment by bank transfer • Complete Analytics • Consent Wall • Dedicated CS • API Provisioning • AB testing',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement • Stats complètes • Consent Wall • CS Dédié • API Provisioning • AB testing',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1OFHFWB0P8XBvhyKKlhgonD5',
          graduatedFirstPaidTierFees: '{"eur":20,"usd":25,"cad":30,"chf":20,"gbp":20}',
          label: 'Agencies 2023',
          meteredCriteria: 'publishedProjects',
          monthlyFlatFees: '{"eur":600,"usd":700,"cad":900,"chf":600,"gbp":600}',
          monthlyMinUsage: '25',
          multiCurrency: 'true',
          pageViewsLimit: '750000',
          partOfAutoUpscale: 'false',
          services: 'cookies,dpo,processings,contracts',
          statsAllowedAggregateBy: 'day,week,month, year',
          statsAreFiltersAllowed: 'true',
          statsKpi: 'pageview,visitor,newVisitor,consentRate,interactionRate,optinRate,hits,quickBounceRate,consentRateMobile,consentRateDesktop,interactionRateMobile,interactionRateDesktop',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,optInRate,consentDistribution,visitsByHosts,consentDistributionByDevice,optInRateByDevice,devicesDistribution,bounceRateByDuration',
          zd_priority: 'high'
        },
        nickname: 'Agencies 2023 monthly (25 published projects/month)',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 25
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 2000,
            unit_amount_decimal: '2000',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OFHFWB0P8XBvhyKKlhgonD5',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1700662674,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 90000,
            unit_amount_decimal: '90000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 70000,
            unit_amount_decimal: '70000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OFHFWB0P8XBvhyKD48mkC7u'
        },
        nickname: 'Flat fee for Agencies 2023 monthly (25 published projects/month)',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 60000,
        unit_amount_decimal: '60000'
      },
      {
        id: 'price_1OFDQpB0P8XBvhyKY8kil1cJ',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1700647999,
        currency: 'cad',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 90000,
            unit_amount_decimal: '90000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Test CAD Jéjé',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 90000,
        unit_amount_decimal: '90000'
      },
      {
        id: 'price_1OFDJiB0P8XBvhyKDfsrXJ7g',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1700647558,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 40115,
            unit_amount_decimal: '40115'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 38686,
            unit_amount_decimal: '38686'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 34912,
            unit_amount_decimal: '34912'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 43793,
            unit_amount_decimal: '43793'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'test multidevise Jéjé',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 60000,
        unit_amount_decimal: '60000'
      },
      {
        id: 'price_1OCjt8B0P8XBvhyKMjFxKQ6T',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1700057778,
        currency: 'cad',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 3000,
                unit_amount_decimal: '3000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2500,
                unit_amount_decimal: '2500',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'test devise 2 (CAD first)',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 25
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 3000,
            unit_amount_decimal: '3000',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OCf6KB0P8XBvhyKVVHBqpvj',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1700039376,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 3000,
                unit_amount_decimal: '3000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 60000,
                flat_amount_decimal: '60000',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2000,
                unit_amount_decimal: '2000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'test devise',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 60000,
            flat_amount_decimal: '60000',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 25
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 2000,
            unit_amount_decimal: '2000',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Mrda1B0P8XBvhyK5omhR8W5',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1680252305,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 1000,
                unit_amount_decimal: '1000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          label: 'small'
        },
        nickname: null,
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 10
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 1000,
            unit_amount_decimal: '1000',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzEB0P8XBvhyKZKXN9q4I',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658220060,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 56
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • email support • Payment by bank transfer • Complete Analytics • Contextual Consent Wall • Mobile SDK',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Stats complètes • Consent Wall Contextuel • SDK mobile',
          features: 'consentWall, mobileSDK',
          flatFeePriceId: 'price_1LNBzDB0P8XBvhyKsz6CRxeD',
          label: 'Premium',
          monthlyFlatFee: '300',
          monthlyMinUsage: '56',
          partOfAutoUpscale: 'true',
          statsAllowedAggregateBy: 'day,week,month, year',
          statsAreFiltersAllowed: 'true',
          statsKpi: 'pageview,visitor,newVisitor,consentRate,interactionRate,optinRate,hits,quickBounceRate,consentRateMobile,consentRateDesktop,interactionRateMobile,interactionRateDesktop',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,optInRate,consentDistribution,visitsByHosts,consentDistributionByDevice,optInRateByDevice,devicesDistribution,bounceRateByDuration'
        },
        nickname: 'Premium monthly (56 published projects/month)',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 56
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBzDB0P8XBvhyKsz6CRxeD',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658220059,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 30000,
            unit_amount_decimal: '30000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LNBzEB0P8XBvhyKZKXN9q4I'
        },
        nickname: 'Flat fee for Premium monthly (56 published projects/month)',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 30000,
        unit_amount_decimal: '30000'
      },
      {
        id: 'price_1LNBzAB0P8XBvhyKFEBCfV4m',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658220056,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • email support • Payment by bank transfer • Extended Analytics • Contextual Consent Wall',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Stats étendues • Consent Wall Contextuel',
          features: 'consentWall',
          flatFeePriceId: 'price_1LNBz9B0P8XBvhyKWqYaQaL7',
          label: 'Pro',
          monthlyFlatFee: '220',
          monthlyMinUsage: '35',
          partOfAutoUpscale: 'true',
          statsAllowedAggregateBy: 'day,week,month',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastMonth',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,optInRate,consentDistribution,visitsByHosts,bounceRateByDuration'
        },
        nickname: 'Pro monthly (35 published projects/month)',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 35
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBz9B0P8XBvhyKWqYaQaL7',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658220055,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 22000,
            unit_amount_decimal: '22000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LNBzAB0P8XBvhyKFEBCfV4m'
        },
        nickname: 'Flat fee for Pro monthly (35 published projects/month)',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 22000,
        unit_amount_decimal: '22000'
      },
      {
        id: 'price_1LNBz6B0P8XBvhyKgkjititv',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658220052,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • email support • Payment by bank transfer • Basic Analytics',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Stats de base',
          flatFeePriceId: 'price_1LNBz6B0P8XBvhyKmnNy51Gc',
          label: 'Standard',
          monthlyFlatFee: '149',
          monthlyMinUsage: '20',
          partOfAutoUpscale: 'true'
        },
        nickname: 'Standard monthly (20 published projects/month)',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBz6B0P8XBvhyKmnNy51Gc',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658220052,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 14900,
            unit_amount_decimal: '14900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LNBz6B0P8XBvhyKgkjititv'
        },
        nickname: 'Flat fee for Standard monthly (20 published projects/month)',
        product: 'prod_agencyMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 14900,
        unit_amount_decimal: '14900'
      }
    ]
  },
  {
    id: 'prod_projectSelfServiceMeteredTest',
    metadata: {
      meteredCriteria: 'pageViews',
      services: 'cookies,dpo',
      title_EN: 'Graduated self-service Project plan',
      title_FR: 'Offre Projet self-service progressive'
    },
    active: true,
    name: 'Graduated self-service Project',
    type: 'service',
    prices: [
      {
        id: 'price_1OopGuB0P8XBvhyKWBaeHVNe',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1709134936,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • Marketing consents • email support • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Consentements marketing • Support tel/mail • Personnalisation graphique • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          graduatedFirstPaidTierFees: '{"eur":0,"usd":0,"cad":0,"chf":0,"gbp":0}',
          label: 'Free Trial',
          meteredCriteria: 'newVisitors',
          monthlyFlatFee: '0',
          monthlyMinUsage: '200',
          multiCurrency: 'true',
          services: 'cookies,processings',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          zd_priority: 'low'
        },
        nickname: 'Free Trial monthly (200 new visitors/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 200
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OoSC4B0P8XBvhyKKqLPSyFg',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1709046224,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1OoSC3B0P8XBvhyKPRuWSa4z',
          graduatedFirstPaidTierFees: '{"eur":0.00039,"usd":0.00039,"cad":0.00039,"chf":0.00039,"gbp":0.00039}',
          label: 'Large',
          monthlyFlatFees: '{"eur":129,"usd":129,"cad":129,"chf":129,"gbp":129}',
          monthlyMinUsage: '500000',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          yearlyFlatFees: '{"eur":1393,"usd":1393,"cad":1393,"chf":1393,"gbp":1393}',
          yearlyMinUsage: '6000000',
          zd_priority: 'high'
        },
        nickname: 'Large yearly (6M page views/year)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.039',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OoSC3B0P8XBvhyKPRuWSa4z',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1709046223,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OoSC4B0P8XBvhyKKqLPSyFg',
          yearlyFlatFees: '{"eur":1393,"usd":1393,"cad":1393,"chf":1393,"gbp":1393}'
        },
        nickname: 'Flat fee for Large yearly (6M page views/year)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 139300,
        unit_amount_decimal: '139300'
      },
      {
        id: 'price_1OoSC2B0P8XBvhyKIHrgF470',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1709046222,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1OoSC1B0P8XBvhyKvN1pOAsZ',
          graduatedFirstPaidTierFees: '{"eur":0.00039,"usd":0.00039,"cad":0.00039,"chf":0.00039,"gbp":0.00039}',
          label: 'Large',
          monthlyFlatFees: '{"eur":129,"usd":129,"cad":129,"chf":129,"gbp":129}',
          monthlyMinUsage: '500000',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          zd_priority: 'high'
        },
        nickname: 'Large monthly (500K page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.039',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OoSC1B0P8XBvhyKvN1pOAsZ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1709046221,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          monthlyFlatFees: '{"eur":129,"usd":129,"cad":129,"chf":129,"gbp":129}',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OoSC2B0P8XBvhyKIHrgF470'
        },
        nickname: 'Flat fee for Large monthly (500K page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 12900,
        unit_amount_decimal: '12900'
      },
      {
        id: 'price_1OoSBzB0P8XBvhyK79xj0AeH',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1709046219,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics • Contextual Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques • Consent Wall Contextuel',
          features: 'customization, contextualConsentWall',
          flatFeePriceId: 'price_1OoSByB0P8XBvhyKAYau4yNG',
          graduatedFirstPaidTierFees: '{"eur":0.00049,"usd":0.00049,"cad":0.00049,"chf":0.00049,"gbp":0.00049}',
          label: 'Medium',
          monthlyFlatFees: '{"eur":69,"usd":69,"cad":69,"chf":69,"gbp":69}',
          monthlyMinUsage: '100000',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          yearlyFlatFees: '{"eur":745,"usd":745,"cad":745,"chf":745,"gbp":745}',
          yearlyMinUsage: '1200000',
          zd_priority: 'normal'
        },
        nickname: 'Medium yearly (1.2M page views/year)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1200000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OoSByB0P8XBvhyKAYau4yNG',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1709046218,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OoSBzB0P8XBvhyK79xj0AeH',
          yearlyFlatFees: '{"eur":745,"usd":745,"cad":745,"chf":745,"gbp":745}'
        },
        nickname: 'Flat fee for Medium yearly (1.2M page views/year)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 74500,
        unit_amount_decimal: '74500'
      },
      {
        id: 'price_1OoSBtB0P8XBvhyKrcpQ4ObT',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1709046213,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics • Contextual Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques • Consent Wall Contextuel',
          features: 'customization, contextualConsentWall',
          flatFeePriceId: 'price_1OoSBoB0P8XBvhyKWguoBJOw',
          graduatedFirstPaidTierFees: '{"eur":0.00049,"usd":0.00049,"cad":0.00049,"chf":0.00049,"gbp":0.00049}',
          label: 'Medium',
          monthlyFlatFees: '{"eur":69,"usd":69,"cad":69,"chf":69,"gbp":69}',
          monthlyMinUsage: '100000',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          zd_priority: 'normal'
        },
        nickname: 'Medium monthly (100K page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 100000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OoSBoB0P8XBvhyKWguoBJOw',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1709046208,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          monthlyFlatFees: '{"eur":69,"usd":69,"cad":69,"chf":69,"gbp":69}',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OoSBtB0P8XBvhyKrcpQ4ObT'
        },
        nickname: 'Flat fee for Medium monthly (100K page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 6900,
        unit_amount_decimal: '6900'
      },
      {
        id: 'price_1OoSBkB0P8XBvhyKQSlO38EH',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1709046204,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1OoSBRB0P8XBvhyK7JxHqSVg',
          graduatedFirstPaidTierFees: '{"eur":0.0018,"usd":0.0018,"cad":0.0018,"chf":0.0018,"gbp":0.0018}',
          label: 'Small',
          monthlyFlatFees: '{"eur":29,"usd":29,"cad":29,"chf":29,"gbp":29}',
          monthlyMinUsage: '5000',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          yearlyFlatFees: '{"eur":313,"usd":313,"cad":313,"chf":313,"gbp":313}',
          yearlyMinUsage: '60000',
          zd_priority: 'low'
        },
        nickname: 'Small yearly (60K page views/year)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OoSBRB0P8XBvhyK7JxHqSVg',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1709046185,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OoSBkB0P8XBvhyKQSlO38EH',
          yearlyFlatFees: '{"eur":313,"usd":313,"cad":313,"chf":313,"gbp":313}'
        },
        nickname: 'Flat fee for Small yearly (60K page views/year)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 31300,
        unit_amount_decimal: '31300'
      },
      {
        id: 'price_1OoS4yB0P8XBvhyKYRMWpCWC',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1709045784,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1OoS3TB0P8XBvhyKIk7m1nh5',
          graduatedFirstPaidTierFees: '{"eur":0.0018,"usd":0.0018,"cad":0.0018,"chf":0.0018,"gbp":0.0018}',
          label: 'Small',
          monthlyFlatFees: '{"eur":29,"usd":29,"cad":29,"chf":29,"gbp":29}',
          monthlyMinUsage: '5000',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          zd_priority: 'low'
        },
        nickname: 'Small monthly (5K page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OoS3TB0P8XBvhyKIk7m1nh5',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1709045691,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          monthlyFlatFees: '{"eur":29,"usd":29,"cad":29,"chf":29,"gbp":29}',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OoS4yB0P8XBvhyKYRMWpCWC'
        },
        nickname: 'Flat fee for Small monthly (5K page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2900,
        unit_amount_decimal: '2900'
      },
      {
        id: 'price_1Oeg0rB0P8XBvhyKSKQIBKDX',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1706716065,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1Oeg0qB0P8XBvhyKjKKyFqmy',
          label: 'Large',
          monthlyFlatFee: '129',
          monthlyMinUsage: '500000',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          yearlyFlatFee: '1392',
          yearlyMinUsage: '6000000',
          zd_priority: 'high'
        },
        nickname: 'Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.039',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Oeg0qB0P8XBvhyKjKKyFqmy',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1706716064,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139200,
            unit_amount_decimal: '139200'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1Oeg0rB0P8XBvhyKSKQIBKDX'
        },
        nickname: 'Flat fee for Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 139200,
        unit_amount_decimal: '139200'
      },
      {
        id: 'price_1Oeg0oB0P8XBvhyKx6UFodez',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1706716062,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1Oeg0oB0P8XBvhyKUXAdQ1bB',
          label: 'Large',
          monthlyFlatFee: '129',
          monthlyMinUsage: '500000',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'high'
        },
        nickname: 'Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.039',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Oeg0oB0P8XBvhyKUXAdQ1bB',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1706716062,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1Oeg0oB0P8XBvhyKx6UFodez'
        },
        nickname: 'Flat fee for Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 12900,
        unit_amount_decimal: '12900'
      },
      {
        id: 'price_1NzK4zB0P8XBvhyKdwPg85ed',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1696860305,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1NzK4yB0P8XBvhyKcXU30BgQ',
          label: 'Large',
          monthlyFlatFee: '129',
          monthlyMinUsage: '500000',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          yearlyFlatFee: '1392',
          zd_priority: 'high'
        },
        nickname: 'Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NzK4yB0P8XBvhyKcXU30BgQ',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1696860304,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139200,
            unit_amount_decimal: '139200'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1NzK4zB0P8XBvhyKdwPg85ed'
        },
        nickname: 'Flat fee for Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 139200,
        unit_amount_decimal: '139200'
      },
      {
        id: 'price_1NzK4vB0P8XBvhyKsFVmaJJQ',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1696860301,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1NzK4vB0P8XBvhyK99yGkIg8',
          label: 'Large',
          monthlyFlatFee: '129',
          monthlyMinUsage: '500000',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'high'
        },
        nickname: 'Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NzK4vB0P8XBvhyK99yGkIg8',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1696860301,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1NzK4vB0P8XBvhyKsFVmaJJQ'
        },
        nickname: 'Flat fee for Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 12900,
        unit_amount_decimal: '12900'
      },
      {
        id: 'price_1NzK4gB0P8XBvhyKQo4nBlCX',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1696860286,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Basic statistics • Contextual Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques basiques • Consent Wall Contextuel',
          features: 'customization, contextualConsentWall',
          flatFeePriceId: 'price_1NzK4gB0P8XBvhyK7rcwXs4F',
          label: 'Medium',
          monthlyFlatFee: '69',
          monthlyMinUsage: '100000',
          services: 'cookies,dpo,tcf',
          yearlyFlatFee: '744',
          zd_priority: 'normal'
        },
        nickname: 'Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1200000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NzK4gB0P8XBvhyK7rcwXs4F',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1696860286,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74400,
            unit_amount_decimal: '74400'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1NzK4gB0P8XBvhyKQo4nBlCX'
        },
        nickname: 'Flat fee for Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 74400,
        unit_amount_decimal: '74400'
      },
      {
        id: 'price_1NzK3MB0P8XBvhyKpoJkWCjD',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1696860204,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Basic statistics • Contextual Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques basiques • Consent Wall Contextuel',
          features: 'customization, contextualConsentWall',
          flatFeePriceId: 'price_1NzK3MB0P8XBvhyK1EBdcXNZ',
          label: 'Medium',
          monthlyFlatFee: '69',
          monthlyMinUsage: '100000',
          services: 'cookies,dpo,tcf',
          zd_priority: 'normal'
        },
        nickname: 'Medium monthly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 100000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NzK3MB0P8XBvhyK1EBdcXNZ',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1696860204,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1NzK3MB0P8XBvhyKpoJkWCjD'
        },
        nickname: 'Flat fee for Medium monthly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 6900,
        unit_amount_decimal: '6900'
      },
      {
        id: 'price_1NzK2AB0P8XBvhyKjE5sYrP4',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1696860130,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1NzK25B0P8XBvhyKHcpICGon',
          label: 'Small',
          monthlyFlatFee: '29',
          monthlyMinUsage: '5000',
          services: 'cookies,dpo,tcf',
          yearlyFlatFee: '312',
          zd_priority: 'low'
        },
        nickname: 'Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NzK25B0P8XBvhyKHcpICGon',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1696860125,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31200,
            unit_amount_decimal: '31200'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1NzK2AB0P8XBvhyKjE5sYrP4'
        },
        nickname: 'Flat fee for Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 31200,
        unit_amount_decimal: '31200'
      },
      {
        id: 'price_1NzK1fB0P8XBvhyKXVdbBggy',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1696860099,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1NzK1IB0P8XBvhyKOYkSYmG4',
          label: 'Small',
          monthlyFlatFee: '29',
          monthlyMinUsage: '5000',
          services: 'cookies,dpo,tcf',
          zd_priority: 'low'
        },
        nickname: 'Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NzK1IB0P8XBvhyKOYkSYmG4',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1696860076,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1NzK1fB0P8XBvhyKXVdbBggy'
        },
        nickname: 'Flat fee for Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2900,
        unit_amount_decimal: '2900'
      },
      {
        id: 'price_1MWL91B0P8XBvhyKHS30g3GA',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1675176551,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16200,
            unit_amount_decimal: '16200'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          createdFromPrice: 'price_1M9nsmB0P8XBvhyKIGyAkjIG',
          tieredPriceId: 'price_1LrH3vB0P8XBvhyKOdGePXCW'
        },
        nickname: 'Flat fee for Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 16200,
        unit_amount_decimal: '16200'
      },
      {
        id: 'price_1MWL08B0P8XBvhyKkD31Z5RF',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1675176000,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          createdFromPrice: 'price_1LrGpZB0P8XBvhyKdnJws5fM',
          tieredPriceId: 'price_1LrGwAB0P8XBvhyKr81P8Wmg'
        },
        nickname: 'Flat fee for Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      },
      {
        id: 'price_freeTrialDailyTest',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1673621534,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          label: 'test roro'
        },
        nickname: 'pour test roro, ne pas toucher',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'day',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 200
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1M9nsmB0P8XBvhyKKhGGHWg9',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1669805476,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 52920,
            unit_amount_decimal: '52920'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          createdFromPrice: 'price_1LNBysB0P8XBvhyKQtxP0rjg',
          tieredPriceId: 'price_1LNBytB0P8XBvhyKbkC5BsOC'
        },
        nickname: 'Flat fee for Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 52920,
        unit_amount_decimal: '52920'
      },
      {
        id: 'price_1M9nsmB0P8XBvhyKtROjOhKF',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1669805476,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 106920,
            unit_amount_decimal: '106920'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          createdFromPrice: 'price_1LNByyB0P8XBvhyKJpE4BKcG',
          tieredPriceId: 'price_1LNBz0B0P8XBvhyKi8GXpOya'
        },
        nickname: 'Flat fee for Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 106920,
        unit_amount_decimal: '106920'
      },
      {
        id: 'price_1M9nsmB0P8XBvhyKIGyAkjIG',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1669805476,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9720,
            unit_amount_decimal: '9720'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          createdFromPrice: 'price_1LrGqEB0P8XBvhyKCY4r3i1U',
          tieredPriceId: 'price_1LrH3vB0P8XBvhyKOdGePXCW'
        },
        nickname: 'Flat fee for Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9720,
        unit_amount_decimal: '9720'
      },
      {
        id: 'price_1M6yrjB0P8XBvhyKBhxlZOZ9',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1669132711,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 52920,
            unit_amount_decimal: '52920'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          createdFromPrice: 'price_1LNBysB0P8XBvhyKQtxP0rjg',
          tieredPriceId: 'price_1LNBytB0P8XBvhyKbkC5BsOC'
        },
        nickname: 'Flat fee for Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 52920,
        unit_amount_decimal: '52920'
      },
      {
        id: 'price_1M6yrjB0P8XBvhyKBPDBiBhD',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1669132711,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 106920,
            unit_amount_decimal: '106920'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          createdFromPrice: 'price_1LNByyB0P8XBvhyKJpE4BKcG',
          tieredPriceId: 'price_1LNBz0B0P8XBvhyKi8GXpOya'
        },
        nickname: 'Flat fee for Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 106920,
        unit_amount_decimal: '106920'
      },
      {
        id: 'price_1M6yrjB0P8XBvhyKOUdOGZBL',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1669132711,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9720,
            unit_amount_decimal: '9720'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          createdFromPrice: 'price_1LrGqEB0P8XBvhyKCY4r3i1U',
          tieredPriceId: 'price_1LrH3vB0P8XBvhyKOdGePXCW'
        },
        nickname: 'Flat fee for Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9720,
        unit_amount_decimal: '9720'
      },
      {
        id: 'price_1LrH3vB0P8XBvhyKOdGePXCW',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1665389411,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement',
          flatFeePriceId: 'price_1MWL91B0P8XBvhyKHS30g3GA',
          label: 'Small',
          monthlyFlatFee: '15',
          monthlyMinUsage: '5000',
          services: 'cookies,dpo,tcf',
          zd_priority: 'low'
        },
        nickname: 'Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LrGwAB0P8XBvhyKr81P8Wmg',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1665388930,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement',
          features: 'customization',
          flatFeePriceId: 'price_1MWL08B0P8XBvhyKkD31Z5RF',
          label: 'Small',
          monthlyFlatFee: '15',
          monthlyMinUsage: '5000',
          services: 'cookies,dpo,tcf',
          zd_priority: 'low'
        },
        nickname: 'Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LrGqEB0P8XBvhyKCY4r3i1U',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1665388562,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10800,
            unit_amount_decimal: '10800'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LrH3vB0P8XBvhyKOdGePXCW'
        },
        nickname: 'Flat fee for Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10800,
        unit_amount_decimal: '10800'
      },
      {
        id: 'price_1LrGpZB0P8XBvhyKdnJws5fM',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1665388521,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 900,
            unit_amount_decimal: '900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LrGwAB0P8XBvhyKr81P8Wmg'
        },
        nickname: 'Flat fee for Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 900,
        unit_amount_decimal: '900'
      },
      {
        id: 'price_1LNBz0B0P8XBvhyKi8GXpOya',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220046,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Graphic customization • Contextual Consent Wall • Basic Analytics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Consent Wall Contextuel • Stats de base',
          features: 'customization,consentWall,cookiesConfigTranslation',
          flatFeePriceId: 'price_1M9nsmB0P8XBvhyKtROjOhKF',
          label: 'Large',
          monthlyFlatFee: '99',
          monthlyMinUsage: '500000',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'high'
        },
        nickname: 'Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNByyB0P8XBvhyKJpE4BKcG',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220044,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LNBz0B0P8XBvhyKi8GXpOya'
        },
        nickname: 'Flat fee for Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      },
      {
        id: 'price_1LNByxB0P8XBvhyKIHlhmaLa',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220043,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Graphic customization • Contextual Consent Wall • Basic Analytics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Consent Wall Contextuel • Stats de base',
          features: 'customization,consentWall,cookiesConfigTranslation',
          flatFeePriceId: 'price_1LNByxB0P8XBvhyKhl9wOtF8',
          label: 'Large',
          monthlyFlatFee: '99',
          monthlyMinUsage: '500000',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'high'
        },
        nickname: 'Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNByxB0P8XBvhyKhl9wOtF8',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220043,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LNByxB0P8XBvhyKIHlhmaLa'
        },
        nickname: 'Flat fee for Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      },
      {
        id: 'price_1LNBytB0P8XBvhyKbkC5BsOC',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220039,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Graphic customization',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique',
          features: 'customization',
          flatFeePriceId: 'price_1M9nsmB0P8XBvhyKKhGGHWg9',
          label: 'Medium',
          monthlyFlatFee: '49',
          monthlyMinUsage: '100000',
          zd_priority: 'normal'
        },
        nickname: 'Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1200000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBysB0P8XBvhyKQtxP0rjg',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220038,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 58800,
            unit_amount_decimal: '58800'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LNBytB0P8XBvhyKbkC5BsOC'
        },
        nickname: 'Flat fee for Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 58800,
        unit_amount_decimal: '58800'
      },
      {
        id: 'price_1LNBypB0P8XBvhyK9TTOAi6b',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220035,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Graphic customization',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique',
          features: 'customization, contextualConsentWall',
          flatFeePriceId: 'price_1LNBypB0P8XBvhyK5tO5FSSb',
          label: 'Medium',
          monthlyFlatFee: '49',
          monthlyMinUsage: '100000',
          zd_priority: 'normal'
        },
        nickname: 'Medium monthly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 100000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBypB0P8XBvhyK5tO5FSSb',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220035,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LNBypB0P8XBvhyK9TTOAi6b'
        },
        nickname: 'Flat fee for Medium monthly (100k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      },
      {
        id: 'price_1LNBymB0P8XBvhyKS1xW8KL0',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220032,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.018',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement',
          flatFeePriceId: 'price_1LNBylB0P8XBvhyKOMDCENyC',
          label: 'Small',
          monthlyFlatFee: '9',
          monthlyMinUsage: '50000',
          zd_priority: 'low'
        },
        nickname: 'Small yearly (50k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 600000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.018',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBylB0P8XBvhyKOMDCENyC',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220031,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10800,
            unit_amount_decimal: '10800'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LNBymB0P8XBvhyKS1xW8KL0'
        },
        nickname: 'Flat fee for Small yearly (50k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10800,
        unit_amount_decimal: '10800'
      },
      {
        id: 'price_1LNBykB0P8XBvhyKzlpLOCuZ',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220030,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.018',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement',
          flatFeePriceId: 'price_1LNByjB0P8XBvhyKgZMndQF4',
          label: 'Small',
          monthlyFlatFee: '9',
          monthlyMinUsage: '50000',
          zd_priority: 'low'
        },
        nickname: 'Small monthly (50k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 50000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.018',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNByjB0P8XBvhyKgZMndQF4',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658220029,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 900,
            unit_amount_decimal: '900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          tieredPriceId: 'price_1LNBykB0P8XBvhyKzlpLOCuZ'
        },
        nickname: 'Flat fee for Small monthly (50k page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 900,
        unit_amount_decimal: '900'
      },
      {
        id: 'price_1LNByhB0P8XBvhyKKMDgP87v',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220027,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2400
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Graphic customization  • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Basic Analytics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats de base',
          features: 'customization,consentWall',
          label: 'Free Trial',
          monthlyFlatFee: '0',
          monthlyMinUsage: '200',
          services: 'cookies,processings,contracts,dpo,portability',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'low'
        },
        nickname: 'Free Trial yearly (200 page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2400
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LNBygB0P8XBvhyKAWphwCpf',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658220026,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Graphic customization  • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Basic Analytics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats de base',
          features: 'customization,consentWall',
          label: 'Free Trial',
          meteredCriteria: 'newVisitors',
          monthlyFlatFee: '0',
          monthlyMinUsage: '200',
          services: 'cookies,processings,contracts,dpo,portability',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'low'
        },
        nickname: 'Free Trial monthly (200 page views/month)',
        product: 'prod_projectSelfServiceMeteredTest',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 200
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_N19K2ukRAZtLjy',
    metadata: {},
    active: true,
    name: 'Technical integration',
    type: 'service',
    prices: [
      {
        id: 'price_1MH720B0P8XBvhyKOLeK5Z1d',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1671547380,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_N19K2ukRAZtLjy',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      }
    ]
  },
  {
    id: 'prod_enterprise_metered_test',
    metadata: {
      description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
      description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
      features: 'customization, consentWall',
      meteredCriteria: 'pageViews',
      services: 'cookies,processings,contracts,dpo,portability',
      statsAllowedAggregateBy: 'day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsPeriod: 'lastYear',
      statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution, optInRateByDevice, devicesDistribution, consentDistributionByDevice, optInRateByConfig, dailyRates, interactionRate',
      title_EN: 'Graduated Enterprise plan',
      title_FR: 'Offre Entreprise progressive',
      zd_priority: 'high'
    },
    active: false,
    name: 'Graduated Enterprise',
    type: 'service',
    prices: [
      {
        id: 'price_1LE7EvB0P8XBvhyKNIaRGxl6',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056861,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 699000,
                flat_amount_decimal: '699000',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 72000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0117',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'yearly 6M page views/month',
        product: 'prod_enterprise_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 699000,
            flat_amount_decimal: '699000',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 72000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0117',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EuB0P8XBvhyKElogWT0A',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056860,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 599000,
                flat_amount_decimal: '599000',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.012',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'yearly 5M page views/month',
        product: 'prod_enterprise_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 599000,
            flat_amount_decimal: '599000',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 60000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.012',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EtB0P8XBvhyKBgfJfVuc',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056859,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 549000,
                flat_amount_decimal: '549000',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 36000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0183',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'yearly 3M page views/month',
        product: 'prod_enterprise_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 549000,
            flat_amount_decimal: '549000',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 36000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0183',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EsB0P8XBvhyKdlFPG0KK',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056858,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 449000,
                flat_amount_decimal: '449000',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 24000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0225',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'yearly 2M page views/month',
        product: 'prod_enterprise_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 449000,
            flat_amount_decimal: '449000',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 24000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0225',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7ErB0P8XBvhyKhXF40Iu4',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056857,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 349000,
                flat_amount_decimal: '349000',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0233',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'yearly 1,5M page views/month',
        product: 'prod_enterprise_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 349000,
            flat_amount_decimal: '349000',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 18000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0233',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EqB0P8XBvhyKpMsdBBBF',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056856,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 299000,
                flat_amount_decimal: '299000',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0299',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'yearly 1M page views/month',
        product: 'prod_enterprise_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 299000,
            flat_amount_decimal: '299000',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0299',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EpB0P8XBvhyKrg5tUYa0',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056855,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 249000,
                flat_amount_decimal: '249000',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0498',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'yearly 500k page views/month',
        product: 'prod_enterprise_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 249000,
            flat_amount_decimal: '249000',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0498',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_agency_metered_test',
    metadata: {
      backoffice_agency: 'metered',
      features: 'customization',
      meteredCriteria: 'publishedProjects',
      services: 'cookies,dpo',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview',
      zd_priority: 'high'
    },
    active: false,
    name: 'Graduated Agency',
    type: 'service',
    prices: [
      {
        id: 'price_1LE7EoB0P8XBvhyKB9IW6Pzr',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056854,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 30000,
                flat_amount_decimal: '30000',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • email support • Payment by bank transfer • Complete Analytics • Contextual Consent Wall • Mobile SDK',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Stats complètes • Consent Wall Contextuel • SDK mobile',
          features: 'consentWall, mobileSDK',
          label: 'Premium',
          statsAllowedAggregateBy: 'day,week,month, year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, dailyVisitsOverview, optInRate, consentDistribution, visitsByHosts, consentDistributionByDevice, optInRateByDevice, devicesDistribution'
        },
        nickname: 'Premium monthly (20 published projects/month)',
        product: 'prod_agency_metered_test',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 30000,
            flat_amount_decimal: '30000',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EnB0P8XBvhyK9sHqhxPv',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056853,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 22000,
                flat_amount_decimal: '22000',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Contextual Consent Wall • Extended Analytics • email support • Payment by bank transfer',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Stats étendues • Consent Wall Contextuel',
          features: 'consentWall',
          label: 'Pro',
          statsAllowedAggregateBy: 'day,week,month',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastMonth',
          statsWidgets: 'dailyOverview, dailyVisitsOverview, optInRate, consentDistribution, visitsByHosts'
        },
        nickname: 'Pro monthly (20 published projects/month)',
        product: 'prod_agency_metered_test',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 22000,
            flat_amount_decimal: '22000',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EmB0P8XBvhyKy5kOuUD3',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056852,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 14900,
                flat_amount_decimal: '14900',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • email support • Payment by bank transfer • Basic Analytics',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Stats de base',
          label: 'Standard'
        },
        nickname: 'Standard monthly (20 published projects/month)',
        product: 'prod_agency_metered_test',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 14900,
            flat_amount_decimal: '14900',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_project_selfservice_metered_test',
    metadata: {
      description_EN: 'description EN',
      meteredCriteria: 'pageViews',
      services: 'cookies,dpo'
    },
    active: false,
    name: 'Graduated self-service Project',
    type: 'service',
    prices: [
      {
        id: 'price_1LE7ElB0P8XBvhyKCs4D8UqX',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056851,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 118800,
                flat_amount_decimal: '118800',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Contextual Consent Wall • Basic Analytics • email support • Payment by bank transfer',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consent Wall Contextuel • Stats de base • Support tel/mail • Paiement par virement',
          features: 'customization,consentWall',
          label: 'Large',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'high'
        },
        nickname: 'Large yearly (500k page views/month)',
        product: 'prod_project_selfservice_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 118800,
            flat_amount_decimal: '118800',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EkB0P8XBvhyKCbQeTw0v',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056850,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 9900,
                flat_amount_decimal: '9900',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Graphic customization • Contextual Consent Wall • Basic Analytics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Consent Wall Contextuel • Stats de base',
          features: 'customization,consentWall',
          label: 'Large',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'high'
        },
        nickname: 'Large monthly (500k page views/month)',
        product: 'prod_project_selfservice_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 9900,
            flat_amount_decimal: '9900',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 500000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EiB0P8XBvhyKvAgGDWLg',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056848,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 58800,
                flat_amount_decimal: '58800',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Graphic customization',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique',
          features: 'customization',
          label: 'Medium',
          zd_priority: 'normal'
        },
        nickname: 'Medium yearly (100k page views/month)',
        product: 'prod_project_selfservice_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 58800,
            flat_amount_decimal: '58800',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 1200000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EiB0P8XBvhyKz3NyddzH',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056848,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 4900,
                flat_amount_decimal: '4900',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Graphic customization',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique',
          features: 'customization',
          label: 'Medium',
          zd_priority: 'normal'
        },
        nickname: 'Medium monthly (100k page views/month)',
        product: 'prod_project_selfservice_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 4900,
            flat_amount_decimal: '4900',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 100000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EgB0P8XBvhyKG9lKeFLD',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056846,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 10800,
                flat_amount_decimal: '10800',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 600000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.018',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement',
          label: 'Small',
          zd_priority: 'low'
        },
        nickname: 'Small yearly (50k page views/month)',
        product: 'prod_project_selfservice_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 10800,
            flat_amount_decimal: '10800',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 600000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.018',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LE7EfB0P8XBvhyKXBC8iuvj',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1656056845,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 900,
                flat_amount_decimal: '900',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 50000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.018',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement',
          label: 'Small',
          zd_priority: 'low'
        },
        nickname: 'Small monthly (50k page views/month)',
        product: 'prod_project_selfservice_metered_test',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 900,
            flat_amount_decimal: '900',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 50000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.018',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_LtNaCXr71ssdZQ',
    metadata: {},
    active: true,
    name: 'Offre Projet Self Service',
    type: 'service',
    prices: [
      {
        id: 'projects_selfservice_viewsMetered_100k_monthly_test',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1655456300,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 9900,
                flat_amount_decimal: '9900',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.099',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Project Self Service 100k views/month',
        product: 'prod_LtNaCXr71ssdZQ',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 9900,
            flat_amount_decimal: '9900',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 100000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.099',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'projects_selfservice_viewsMetered_25k_monthly_test',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1655456162,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 4900,
                flat_amount_decimal: '4900',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 25000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.196',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Project Self Service 25k views/month',
        product: 'prod_LtNaCXr71ssdZQ',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 4900,
            flat_amount_decimal: '4900',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 25000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.196',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'projects_selfservice_viewsMetered_1k_monthly_test',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1655455587,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 900,
                flat_amount_decimal: '900',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 1000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.9',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Project Self Service 1k views/month',
        product: 'prod_LtNaCXr71ssdZQ',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 900,
            flat_amount_decimal: '900',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 1000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.9',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_LkT8NhtnzTfDFr',
    metadata: {
      backoffice_agency: 'metered_standard',
      basedOnProduct: 'KING_SIZE',
      description_EN: 'Unlimited projects. Complete graphic customization. Hosting of proofs of consent on Axeptio\'s servers, capped at 1 million proofs of consent per month.',
      description_FR: 'Nombre de projets illimités • Personnalisation graphique complète • Hébergement des preuves de consentement sur les serveurs d\'Axeptio, plafonné à 1 million de preuves de consentement par mois.',
      meteredCriteria: 'publishedProjects',
      services: 'cookies, processings, contracts, dpo, portability',
      title_EN: 'Agency Standard Graduated Offer',
      title_FR: 'Offre Agence Standard Progressive',
      zd_priority: 'high'
    },
    active: true,
    name: 'product_agency_metered_roro',
    type: 'service',
    prices: [
      {
        id: 'agency_metered_test_yearly',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1655113075,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 178800,
                flat_amount_decimal: '178800',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 5400,
                unit_amount_decimal: '5400',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 3000,
                unit_amount_decimal: '3000',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2400,
                unit_amount_decimal: '2400',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 1200,
                unit_amount_decimal: '1200',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 240,
                unit_amount_decimal: '240',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_LkT8NhtnzTfDFr',
        recurring: {
          aggregate_usage: 'max',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 178800,
            flat_amount_decimal: '178800',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 5400,
            unit_amount_decimal: '5400',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 3000,
            unit_amount_decimal: '3000',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 2400,
            unit_amount_decimal: '2400',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 1200,
            unit_amount_decimal: '1200',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 240,
            unit_amount_decimal: '240',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'agency_metered_test_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1655112904,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 14900,
                flat_amount_decimal: '14900',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_LkT8NhtnzTfDFr',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 2,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 14900,
            flat_amount_decimal: '14900',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LA9E2B0P8XBvhyKUUcktGJg',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1655111182,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 4900,
                flat_amount_decimal: '4900',
                unit_amount: null,
                unit_amount_decimal: null,
                up_to: 1000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '4.9',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_LkT8NhtnzTfDFr',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 4900,
            flat_amount_decimal: '4900',
            unit_amount: null,
            unit_amount_decimal: null,
            up_to: 1000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '4.9',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'agency_metered_roro',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1653400493,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 745,
                unit_amount_decimal: '745',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_LkT8NhtnzTfDFr',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 745,
            unit_amount_decimal: '745',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_JftJ9qCmnPbOtp',
    metadata: {
      backoffice: 'choco',
      basedOnProduct: 'CHOCOLAT',
      description_EN: '< 150K monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents',
      description_FR: '< 150K visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements',
      features: 'customization',
      maximumMonthlyVisitors: '150000',
      services: 'cookies,dpo,contractsV2',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview'
    },
    active: true,
    name: 'Chocolat',
    type: 'service',
    prices: [
      {
        id: 'price_1L1SW5B0P8XBvhyKNtDxK4UY',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1653040385,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19999900,
            unit_amount_decimal: '19999900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftJ9qCmnPbOtp',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 19999900,
        unit_amount_decimal: '19999900'
      },
      {
        id: 'price_1KtvyBB0P8XBvhyKKLW3YlBm',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651246979,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 18000,
            unit_amount_decimal: '18000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftJ9qCmnPbOtp',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 18000,
        unit_amount_decimal: '18000'
      },
      {
        id: 'price_1KtqZTB0P8XBvhyKtHligbyB',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651226227,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftJ9qCmnPbOtp',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      },
      {
        id: 'price_1J3GfAB0P8XBvhyKExURFZ8m',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623918804,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftJ9qCmnPbOtp',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      },
      {
        id: 'price_1J3GevB0P8XBvhyKdVuToEXU',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623918789,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 18000,
            unit_amount_decimal: '18000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftJ9qCmnPbOtp',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 18000,
        unit_amount_decimal: '18000'
      },
      {
        id: 'price_1J2XWiB0P8XBvhyKvX9vClEv',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623745300,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftJ9qCmnPbOtp',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      },
      {
        id: 'price_1J2XWiB0P8XBvhyKdCDGYkvN',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1623745300,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          test: 'yo'
        },
        nickname: null,
        product: 'prod_JftJ9qCmnPbOtp',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      }
    ]
  },
  {
    id: 'prod_LiuB1F7A4aJYCg',
    metadata: {},
    active: true,
    name: 'Licence Axeptio',
    type: 'service',
    prices: [
      {
        id: 'price_1L1SMoB0P8XBvhyKCAZqYVfk',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1653039810,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10000,
            unit_amount_decimal: '10000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_LiuB1F7A4aJYCg',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10000,
        unit_amount_decimal: '10000'
      }
    ]
  },
  {
    id: 'prod_JftL6qE4lBsjre',
    metadata: {
      backoffice: '2choco',
      basedOnProduct: 'DEUX_CHOCOS',
      description_EN: '< 300K monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support',
      description_FR: '< 300K visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail',
      features: 'customization',
      maximumMonthlyVisitors: '300000',
      services: 'cookies,processings,contracts,dpo,contractsV2',
      statsAllowedAggregateBy: 'hour,day,week,month',
      statsAreFiltersAllowed: 'true',
      statsPeriod: 'lastMonth',
      statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution'
    },
    active: true,
    name: '2 chocos',
    type: 'service',
    prices: [
      {
        id: 'price_1L18kkB0P8XBvhyKOdI0MK5k',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1652964414,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5880,
            unit_amount_decimal: '5880'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftL6qE4lBsjre',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 5880,
        unit_amount_decimal: '5880'
      },
      {
        id: 'price_1J3GfiB0P8XBvhyKw40gbstB',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623918838,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 49000,
            unit_amount_decimal: '49000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftL6qE4lBsjre',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 49000,
        unit_amount_decimal: '49000'
      },
      {
        id: 'price_1J3GfWB0P8XBvhyKujO60GVi',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623918826,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 49000,
            unit_amount_decimal: '49000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftL6qE4lBsjre',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 49000,
        unit_amount_decimal: '49000'
      },
      {
        id: 'price_1J2XYtB0P8XBvhyKN7I1HXLN',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623745435,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          txr_1JpqkWB0P8XBvhyKZ3FsmOSt: '70610226',
          txr_1JwU8yB0P8XBvhyKoj8F9acw: '70610025'
        },
        nickname: null,
        product: 'prod_JftL6qE4lBsjre',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      },
      {
        id: 'price_1J2XYtB0P8XBvhyK7nnjNsb5',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623745435,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftL6qE4lBsjre',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      }
    ]
  },
  {
    id: 'prod_K1iIiKrczMtFMe',
    metadata: {
      backoffice_agency: 'standard',
      basedOnProduct: 'KING_SIZE',
      description_EN: 'Unlimited projects. Complete graphic customization. Hosting of proofs of consent on Axeptio\'s servers, capped at 1 million proofs of consent per month.',
      description_FR: 'Nombre de projets illimités. Personnalisation graphique complète. Hébergement des preuves de consentement sur les serveurs d\'Axeptio, plafonné à 1 million de preuves de consentement par mois.',
      services: 'cookies, processings, contracts, dpo, portability',
      title_EN: 'Agency Standard Offer',
      title_FR: 'Offre Agence Standard',
      zd_priority: 'high'
    },
    active: true,
    name: 'Agency Standard',
    type: 'service',
    prices: [
      {
        id: 'price_1L0oFRB0P8XBvhyK87mUhlFE',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1652885593,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 180000,
            unit_amount_decimal: '180000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_K1iIiKrczMtFMe',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 180000,
        unit_amount_decimal: '180000'
      },
      {
        id: 'price_1KFEyrB0P8XBvhyKCwCbeYuJ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1641548609,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99999,
            unit_amount_decimal: '99999'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'offre agency custom placée dans agency standard',
        product: 'prod_K1iIiKrczMtFMe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99999,
        unit_amount_decimal: '99999'
      },
      {
        id: 'price_1K6u5kB0P8XBvhyKpIHNjXS5',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1639561688,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_K1iIiKrczMtFMe',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'AgencyYearlyFull',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1634139976,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_K1iIiKrczMtFMe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      },
      {
        id: 'price_1JNesLB0P8XBvhyKf51DSQ5O',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1628778437,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,dailyRates'
        },
        nickname: null,
        product: 'prod_K1iIiKrczMtFMe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'price_1JNesLB0P8XBvhyKVJGFRulF',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1628778437,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,dailyRates'
        },
        nickname: null,
        product: 'prod_K1iIiKrczMtFMe',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      }
    ]
  },
  {
    id: 'prod_LhmneINPbTHlvl',
    metadata: {},
    active: true,
    name: 'agency_metered',
    type: 'service',
    prices: [
      {
        id: 'price_1L0NDiB0P8XBvhyKesWvPVLc',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1652781698,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 800,
                unit_amount_decimal: '800',
                up_to: 10
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 600,
                unit_amount_decimal: '600',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_LhmneINPbTHlvl',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 0,
            flat_amount_decimal: '0',
            unit_amount: 800,
            unit_amount_decimal: '800',
            up_to: 10
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 600,
            unit_amount_decimal: '600',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_Lfz4Zy7QlO70KQ',
    metadata: {},
    active: true,
    name: 'Offre Entreprise sur mesure',
    type: 'service',
    prices: [
      {
        id: 'price_1Kyd62B0P8XBvhyKlZOd6N22',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1652366070,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16500,
            unit_amount_decimal: '16500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_Lfz4Zy7QlO70KQ',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 16500,
        unit_amount_decimal: '16500'
      }
    ]
  },
  {
    id: 'prod_KpW77rmFswSXsx',
    metadata: {},
    active: true,
    name: 'SubscriptionTestProduct',
    type: 'service',
    prices: [
      {
        id: 'price_1KwsLSB0P8XBvhyKiUIbQP8g',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651948030,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_KpW77rmFswSXsx',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'price_1K9r5AB0P8XBvhyKMEfMwhcl',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1640265104,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000,
            unit_amount_decimal: '2000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'SubscriptionTestPrice',
        product: 'prod_KpW77rmFswSXsx',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2000,
        unit_amount_decimal: '2000'
      }
    ]
  },
  {
    id: 'prod_LdfMpnojMSs6yq',
    metadata: {},
    active: true,
    name: 'Support Prioritaire Grand compte',
    type: 'service',
    prices: [
      {
        id: 'price_1KwO1MB0P8XBvhyKySNkhZCh',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651831464,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 180000,
            unit_amount_decimal: '180000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_LdfMpnojMSs6yq',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 180000,
        unit_amount_decimal: '180000'
      }
    ]
  },
  {
    id: 'prod_LdfJWeNYoypFsu',
    metadata: {},
    active: true,
    name: 'Support Prioritaire Grand compte',
    type: 'service',
    prices: [
      {
        id: 'price_1KwNz4B0P8XBvhyKopWxhl1L',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651831322,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 180000,
            unit_amount_decimal: '180000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_LdfJWeNYoypFsu',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 180000,
        unit_amount_decimal: '180000'
      }
    ]
  },
  {
    id: 'prod_JftNQjl4Q1HC3t',
    metadata: {
      backoffice: 'kingsize',
      basedOnProduct: 'KING_SIZE',
      description_EN: '< 2M monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support • Payment by bank transfer • Native application iOs and Andoid',
      description_FR: '< 2M visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement • Application native iOs et Andoid',
      features: 'customization, consentWall',
      maximumMonthlyVisitors: '1000000',
      services: 'cookies,contracts,processings,dpo,privacypolicy,contractsV2',
      statsAllowedAggregateBy: 'hour,day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsPeriod: 'allTime',
      statsWidgets: 'dailyOverview, optInRate,dailyVisitsOverview, visitsByHosts, consentDistribution, optInRateByDevice, devicesDistribution, consentDistributionByDevice, optInRateByConfig, dailyRates'
    },
    active: true,
    name: 'King size',
    type: 'service',
    prices: [
      {
        id: 'price_1KtvIUB0P8XBvhyKqVXaXi3e',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651244394,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 199000,
            unit_amount_decimal: '199000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          Code_Comptable: '70610000'
        },
        nickname: null,
        product: 'prod_JftNQjl4Q1HC3t',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 199000,
        unit_amount_decimal: '199000'
      },
      {
        id: 'price_1KttIqB0P8XBvhyKotor4T4U',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651236728,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19900,
            unit_amount_decimal: '19900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftNQjl4Q1HC3t',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 19900,
        unit_amount_decimal: '19900'
      },
      {
        id: 'price_1KtWYnB0P8XBvhyKzLc8AzJG',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651149305,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12000,
            unit_amount_decimal: '12000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftNQjl4Q1HC3t',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 12000,
        unit_amount_decimal: '12000'
      },
      {
        id: 'price_1K6u60B0P8XBvhyKt01JTrfx',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1639561704,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 90000,
            unit_amount_decimal: '90000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftNQjl4Q1HC3t',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 90000,
        unit_amount_decimal: '90000'
      },
      {
        id: 'price_1J3GggB0P8XBvhyKjaPUGsRa',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623918898,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 199000,
            unit_amount_decimal: '199000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftNQjl4Q1HC3t',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 199000,
        unit_amount_decimal: '199000'
      },
      {
        id: 'price_1J3GgWB0P8XBvhyK9WlmvqD9',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623918888,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 199000,
            unit_amount_decimal: '199000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftNQjl4Q1HC3t',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 199000,
        unit_amount_decimal: '199000'
      },
      {
        id: 'price_1J2XatB0P8XBvhyKMcQYfE6g',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623745559,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19900,
            unit_amount_decimal: '19900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftNQjl4Q1HC3t',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19900,
        unit_amount_decimal: '19900'
      },
      {
        id: 'price_1J2XatB0P8XBvhyKwqBlpbC6',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623745559,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19900,
            unit_amount_decimal: '19900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftNQjl4Q1HC3t',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19900,
        unit_amount_decimal: '19900'
      }
    ]
  },
  {
    id: 'prod_JftMYU7tL5rV2m',
    metadata: {
      backoffice: '3chocos',
      basedOnProduct: 'TRIPLE_CHOCOS',
      description_EN: '< 1M monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support • Payment by bank transfer\n',
      description_FR: '< 1M visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement',
      features: 'customization, consentWall',
      maximumMonthlyVisitors: '1000000',
      services: 'cookies,processings,contracts,dpo,privacypolicy,portability,contractsV2',
      statsAllowedAggregateBy: 'hour,day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsPeriod: 'lastYear',
      statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, optInRateByDevice, devicesDistribution, visitsByHosts, consentDistributionByDevice, consentDistribution'
    },
    active: true,
    name: 'Triple chocos',
    type: 'service',
    prices: [
      {
        id: 'price_1KttiTB0P8XBvhyKptPV4vCU',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651238317,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftMYU7tL5rV2m',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'price_1J3GgEB0P8XBvhyKggeKFfrJ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623918870,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftMYU7tL5rV2m',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'price_1J3Gg0B0P8XBvhyKRr12UliK',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623918856,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftMYU7tL5rV2m',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'price_1J2Xa2B0P8XBvhyKxexzjZUs',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623745506,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftMYU7tL5rV2m',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      },
      {
        id: 'price_1J2Xa2B0P8XBvhyK7rBZKz0e',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623745506,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JftMYU7tL5rV2m',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      }
    ]
  },
  {
    id: 'prod_E6SxQTROyE7ezA',
    metadata: {
      zd_priority: 'high',
      zd_whatever: 'wtfbbq'
    },
    active: true,
    name: 'Axeptio Cookies',
    type: 'service',
    prices: [
      {
        id: 'price_1KtpeTB0P8XBvhyK4s6m8ZeE',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651222693,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_E6SxQTROyE7ezA',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      },
      {
        id: 'cookies_yearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1544076504,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal',
          zd_whatever: 'wtfbbq'
        },
        nickname: 'Yearly',
        product: 'prod_E6SxQTROyE7ezA',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      },
      {
        id: 'cookies_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1544076473,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal',
          zd_whatever: 'wtfbbq'
        },
        nickname: 'Monthly',
        product: 'prod_E6SxQTROyE7ezA',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      }
    ]
  },
  {
    id: 'prod_LUHsCcmuX9j1Et',
    metadata: {},
    active: true,
    name: 'SubscriptionTestProduct',
    type: 'service',
    prices: [
      {
        id: 'price_1KnJIIB0P8XBvhyKjiuMEWJ1',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1649668342,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000,
            unit_amount_decimal: '2000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          Account_Account: 'AntoineTest'
        },
        nickname: 'SubscriptionTestPrice',
        product: 'prod_LUHsCcmuX9j1Et',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2000,
        unit_amount_decimal: '2000'
      }
    ]
  },
  {
    id: 'prod_E6SyL66GA3B5ZG',
    metadata: {},
    active: true,
    name: 'Axeptio Processings',
    type: 'service',
    prices: [
      {
        id: 'price_1Kg53sB0P8XBvhyKU2Yh5UHt',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1647945336,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 112100,
            unit_amount_decimal: '112100'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_E6SyL66GA3B5ZG',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 112100,
        unit_amount_decimal: '112100'
      },
      {
        id: 'processings_yearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1544076585,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10000,
            unit_amount_decimal: '10000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Yearly',
        product: 'prod_E6SyL66GA3B5ZG',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10000,
        unit_amount_decimal: '10000'
      },
      {
        id: 'processings_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1544076566,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000,
            unit_amount_decimal: '1000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          zd_priority: 'high',
          zd_whatever: 'bonus'
        },
        nickname: 'Monthly',
        product: 'prod_E6SyL66GA3B5ZG',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1000,
        unit_amount_decimal: '1000'
      }
    ]
  },
  {
    id: 'prod_Kv4o1uv30GcYxi',
    metadata: {
      backoffice_agency: 'custom',
      basedOnProduct: 'KING_SIZE',
      description_EN: 'Unlimited projects. Complete graphic customization. Hosting of proofs of consent on Axeptio\'s servers, capped at 300 000 proofs of consent per month.',
      description_FR: 'Nombre de projets illimités. Personnalisation graphique complète. Hébergement des preuves de consentement sur les serveurs d\'Axeptio, plafonné à 300 000 preuves de consentement par mois.',
      services: 'cookies, processings, contracts, dpo, portability',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview',
      title_EN: 'Agency Custom Dedicated',
      title_FR: 'Offre Agence Sur Mesure'
    },
    active: true,
    name: 'Offre Agence sur Mesure de test',
    type: 'service',
    prices: [
      {
        id: 'price_1KFEezB0P8XBvhyKNU8iuZup',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1641547377,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 66600,
            unit_amount_decimal: '66600'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Offre agency custom de test placée dans le produit adéquat',
        product: 'prod_Kv4o1uv30GcYxi',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 66600,
        unit_amount_decimal: '66600'
      }
    ]
  },
  {
    id: 'prod_KYzuoeWm7I2Sqa',
    metadata: {
      isCustomProjectProduct: 'true'
    },
    active: true,
    name: 'Offre Entreprise sur mesure',
    type: 'service',
    prices: [
      {
        id: 'price_1JtrudB0P8XBvhyKxtzr3zCH',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1636455047,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 198400,
            unit_amount_decimal: '198400'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'TRIPLE_CHOCOS',
          description_EN: '< 300K monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support',
          description_FR: '< 300K visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail',
          isCustomProjectProductPrice: 'true',
          services: 'cookies,processings,contracts,portability,dpo'
        },
        nickname: 'Offre 1984',
        product: 'prod_KYzuoeWm7I2Sqa',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 198400,
        unit_amount_decimal: '198400'
      }
    ]
  },
  {
    id: 'prod_JbTfG3U7JW1XnI',
    metadata: {
      support_level: 'premium'
    },
    active: true,
    name: 'Produit de tests',
    type: 'service',
    prices: [
      {
        id: 'price_1IyGhvB0P8XBvhyKmlbbZsWN',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1622727335,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          support_level: 'premium'
        },
        nickname: null,
        product: 'prod_JbTfG3U7JW1XnI',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      }
    ]
  },
  {
    id: 'prod_J4vYcfyL8PEOX4',
    metadata: {},
    active: true,
    name: 'YetAnotherTeam',
    type: 'service',
    prices: [
      {
        id: 'price_1ISlgyB0P8XBvhyKIz6T7pJA',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1615219944,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 1500,
                flat_amount_decimal: '1500',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 30
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.01',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_J4vYcfyL8PEOX4',
        recurring: {
          aggregate_usage: null,
          interval: 'day',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 1500,
            flat_amount_decimal: '1500',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 30
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.01',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_J4vKASIWD1qtbx',
    metadata: {},
    active: true,
    name: 'AnotherTeam',
    type: 'service',
    prices: [
      {
        id: 'price_1ISlUGB0P8XBvhyKwdJcRim3',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1615219156,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 1500,
                flat_amount_decimal: '1500',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 30
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.01',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_J4vKASIWD1qtbx',
        recurring: {
          aggregate_usage: null,
          interval: 'day',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 1500,
            flat_amount_decimal: '1500',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 30
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.01',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_J4uDepso8P2uvP',
    metadata: {},
    active: true,
    name: 'Team',
    type: 'service',
    prices: [
      {
        id: 'price_1ISkTpB0P8XBvhyKacATjT3a',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1615215285,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 1500,
                flat_amount_decimal: '1500',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 300000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.01',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_J4uDepso8P2uvP',
        recurring: {
          aggregate_usage: null,
          interval: 'day',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 1500,
            flat_amount_decimal: '1500',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 300000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.01',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1ISkPDB0P8XBvhyKOnljOeHH',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1615214999,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 1500,
                flat_amount_decimal: '1500',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 300000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.01',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_J4uDepso8P2uvP',
        recurring: {
          aggregate_usage: null,
          interval: 'day',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 1500,
            flat_amount_decimal: '1500',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 300000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.01',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_ICwAjB9TEMeYtJ',
    metadata: {},
    active: true,
    name: 'Cach\'Cam',
    type: 'service',
    prices: [
      {
        id: 'price_1HeKmlB0P8XBvhyK6oqgS7Hh',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1603200115,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_ICwAjB9TEMeYtJ',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      },
      {
        id: 'price_1HcWIbB0P8XBvhyKXHjJWn6j',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602767717,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000,
            unit_amount_decimal: '1000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_ICwAjB9TEMeYtJ',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 1000,
        unit_amount_decimal: '1000'
      }
    ]
  },
  {
    id: 'prod_ICw8Kaj9HnpOo1',
    metadata: {
      offers: 'standard,enterprise',
      services: 'cookies',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview, dailyVisitsOverview'
    },
    active: true,
    name: 'Cookies',
    type: 'service',
    prices: [
      {
        id: 'enterprise',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602767640,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 159900,
            unit_amount_decimal: '159900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'enterprise',
        product: 'prod_ICw8Kaj9HnpOo1',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 159900,
        unit_amount_decimal: '159900'
      },
      {
        id: 'price_1HcWHNB0P8XBvhyKCL1QAAKK',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602767640,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 14900,
            unit_amount_decimal: '14900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'enterprise',
        product: 'prod_ICw8Kaj9HnpOo1',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 14900,
        unit_amount_decimal: '14900'
      },
      {
        id: 'price_1HcWHNB0P8XBvhyKcshUEGGq',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602767640,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19000,
            unit_amount_decimal: '19000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'standard',
        product: 'prod_ICw8Kaj9HnpOo1',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19000,
        unit_amount_decimal: '19000'
      },
      {
        id: 'standard',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602767640,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1900,
            unit_amount_decimal: '1900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'enterprise',
        product: 'prod_ICw8Kaj9HnpOo1',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1900,
        unit_amount_decimal: '1900'
      },
      {
        id: 'price_1HcWHMB0P8XBvhyKhp4ul1H0',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602767640,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'enterprise',
        product: 'prod_ICw8Kaj9HnpOo1',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      },
      {
        id: 'price_1HcWHMB0P8XBvhyKbktuA9Fs',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602767640,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'enterprise',
        product: 'prod_ICw8Kaj9HnpOo1',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'price_1HcWFvB0P8XBvhyKIF2STUwE',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602767551,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'standard',
        product: 'prod_ICw8Kaj9HnpOo1',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      },
      {
        id: 'price_1HcWFvB0P8XBvhyKe9KWRyjh',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602767551,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'standard',
        product: 'prod_ICw8Kaj9HnpOo1',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      }
    ]
  },
  {
    id: 'prod_E6SzlIJLa4StdW',
    metadata: {},
    active: true,
    name: 'Axeptio Contracts',
    type: 'service',
    prices: [
      {
        id: 'contracts_yearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1567678014,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Contracts Yearly',
        product: 'prod_E6SzlIJLa4StdW',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'contracts_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1544076627,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Monthly',
        product: 'prod_E6SzlIJLa4StdW',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      }
    ]
  },
  {
    id: 'prod_FazjLyr5k5JJfJ',
    metadata: {},
    active: true,
    name: 'Agency Dedicated',
    type: 'service',
    prices: [
      {
        id: 'agency_dedicated_yearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1566913937,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 150000,
            unit_amount_decimal: '150000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {
          support_level: 'Premium'
        },
        nickname: 'Agency Dedicated Yearly',
        product: 'prod_FazjLyr5k5JJfJ',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 150000,
        unit_amount_decimal: '150000'
      },
      {
        id: 'agency_dedicated_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1565770296,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Agency Dedicated Monthly',
        product: 'prod_FazjLyr5k5JJfJ',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      }
    ]
  },
  {
    id: 'prod_FaziLB0pmQT7cH',
    metadata: {
      zd_priority: 'high',
      zd_whatever: 'wtfbbq'
    },
    active: false,
    name: 'Agency Standard',
    type: 'service',
    prices: [
      {
        id: 'agency_standard_yearly',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1565417272,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Agency Standard Yearly',
        product: 'prod_FaziLB0pmQT7cH',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'agency_standard_monthly',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1565417250,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Agency Standard Monthly',
        product: 'prod_FaziLB0pmQT7cH',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      }
    ]
  },
  {
    id: 'prod_E6T2t60vAQIXmU',
    metadata: {},
    active: true,
    name: 'Axeptio DPO',
    type: 'service',
    prices: [
      {
        id: 'dpo_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547552155,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'DPO Monthly',
        product: 'prod_E6T2t60vAQIXmU',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'dpo_yearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547552141,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'DPO Yearly',
        product: 'prod_E6T2t60vAQIXmU',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      }
    ]
  },
  {
    id: 'prod_E6WeDa7z6PHn8c',
    metadata: {},
    active: true,
    name: 'Axeptio Privacy Policy',
    type: 'service',
    prices: [
      {
        id: 'privacypolicy_yearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547552120,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Privacy Policy Yearly',
        product: 'prod_E6WeDa7z6PHn8c',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'privacypolicy_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547552068,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Privacy Policy Monthly',
        product: 'prod_E6WeDa7z6PHn8c',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      }
    ]
  },
  {
    id: 'prod_E6T035WMF6s4qZ',
    metadata: {},
    active: true,
    name: 'Axeptio Portability',
    type: 'service',
    prices: [
      {
        id: 'portability',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1544076699,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10000,
            unit_amount_decimal: '10000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Yearly',
        product: 'prod_E6T035WMF6s4qZ',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10000,
        unit_amount_decimal: '10000'
      },
      {
        id: 'portability_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1544076684,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000,
            unit_amount_decimal: '1000'
          }
        },
        custom_unit_amount: null,
        livemode: false,
        lookup_key: null,
        metadata: {},
        nickname: 'Monthly',
        product: 'prod_E6T035WMF6s4qZ',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1000,
        unit_amount_decimal: '1000'
      }
    ]
  }
];

export default products;
const augmentedConsentHelpers = [
  {
    name: 'message',
    label: 'Champ message personnalisé',
    description: 'Laissez votre utilisateur vous parler et ajoutant un champ texte à l\'intérieur du bloc de recueil de consentement.',
    icon: 'helpers_custom',
    active: true,
    defaultName: 'message',
    defaultLabel: 'Dîtes-nous ce que vous préférez',
    isFree: true
  },
  {
    name: 'email',
    label: 'Adresse email dédiée',
    description: '87% des Français utilisent des adresses mail poubelles pour ne pas encombrer leur boites personnelles. Pour les entreprises, c\'est autant de contact qualifiés perdus. Jouez la transparence en laissant la choix à l\'utilisateur de vous donner une adresse mail spécifique pour les mailings.',
    icon: 'helpers_mail',
    active: true,
    defaultName: 'email',
    defaultLabel: 'Indiquez votre email poubelle, pas de soucis ;-)',
    isFree: false
  },
  {
    name: 'weekdays',
    label: 'Choix des jours de la semaine',
    description: 'Qu\'il s\'agisse de SMS, de coups de fils, voire même de mails, laissez à vos utilisateurs la possibilité de choisir le ou les jours de la semaine qui <b>lui</b> conviennent',
    icon: 'helper_days',
    active: true,
    defaultName: 'weekdays',
    defaultLabel: 'Dîtes-nous quel jour vous convient',
    isFree: false
  },
  {
    name: 'timeOfDay',
    label: 'Moment de la journée',
    description: 'Matin, midi et soir ? Ou sinon juste à l\'heure qui arrange votre utilisateur... Laissez-le choix entre les périodes de la journée qui lui conviendront le mieux',
    icon: 'helper_hours',
    active: true,
    defaultName: 'timeOfDay',
    defaultLabel: 'Indiquez-nous l\'horaire qui vous arrange',
    isFree: false
  },
  {
    name: 'frequency',
    label: 'Choix de la fréquence',
    description: 'Tous les jours, toutes les semaines, une fois par an... Une gestion granulaire de la pression publicitaire contentera vos clients et vous donnera une image respectueuse et maline.',
    icon: 'helper_frequency',
    active: false,
    defaultName: 'frequency',
    isFree: false
  },
  {
    name: 'doubleOptIn',
    label: 'Double opt-in à retardement',
    description: 'Et si vos utilisateurs vous offraient le bénéfice du doute. Une sorte de période d\'essai pour votre marketing. Au bout d\'un mois, on lui redemande s\'il est toujours OK pour recevoir vos contenus. Sans réponse de sa part, il est automatiquement désinscrit.',
    icon: 'icon_v2_integration',
    active: false,
    defaultName: 'doubleOptIn',
    isFree: false
  }
];

export default augmentedConsentHelpers;
const plans = [
  {
    name: 'agency_standard',
    title: 'Compte Agence Standard',
    description: 'Nombre de projets illimités.\nPersonnalisation graphique complète.\nHébergement des preuves de consentement sur les serveurs d\'Axeptio, cappé à 1 million de preuves de consentement par mois.\n',
    yearlyPrice: 990,
    monthlyPrice: 99,
    isPlanActive: true,
    isPlanSlim: false
  },
  {
    name: 'agency_dedicated',
    title: 'Agence Agence Forte volumétrie',
    description: 'Nombre de projets illimités.\nPersonnalisation graphique complète.\nHébergement des preuves de consentement sur un serveur de base de données dédié (non compris dans l\'abonnement).',
    yearlyPrice: 1500,
    monthlyPrice: 150,
    isPlanActive: true,
    isPlanSlim: true
  }
];

export default plans;
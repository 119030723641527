import React, { forwardRef } from 'react';
import Select from '../Select/Select';
import { Country } from 'country-state-city';
import CountriesInFrench from 'i18n-iso-countries/langs/fr.json';

const countries = Country.getAllCountries();
const countriesTranslations = {
  FR: CountriesInFrench.countries
};

const CountrySelect = forwardRef((props, ref) => {
  const updatedProps = { ...props, value: props.value ? props.value : 'FR' };
  return (
    <Select
      ref={ref}
      options={countries
        .map(country => {
          let localizedCountry = country.name;
          if (countriesTranslations[props.locale.locale]) {
            localizedCountry = countriesTranslations[props.locale.locale][country.isoCode];
          }
          const parts = [localizedCountry, country.flag];

          return {
            name: parts.join('  '),
            value: country.isoCode
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))}
      {...updatedProps}
    />
  );
});

export default CountrySelect;

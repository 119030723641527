import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import Cookies from 'js-cookie';
import tcfRelatedProjects from '../../data/tempTcfRelatedProjects';
import { useTranslation } from 'react-i18next';

const BannerOfferWrapper = styled.div`
  z-index: 100000000;
  position: fixed;
  width: 100%;
  top: 64px;
`;

const BannerOffer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5%;
  padding: 20px 42px 20px 20px;
  width: 99%;
  background: #212121;
  color: #fff;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 2px 6px 0px rgba(0, 0, 0, 0.05), 0px 12px 24px 0px rgba(0, 0, 0, 0.1);

  a {
    text-decoration: underline;
    color: #ffce43;
    padding: 2px 0;
    &:hover {
      text-decoration: none;
    }
  }

  button {
    position: absolute;
    top: 18px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    padding: 0;
    height: 28px;
    width: 28px;
    border: 0;
    background: transparent;
    color: #ffce43;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    padding: 5px;

    &:hover {
      background: #ffce43;
      transform: rotate(90deg);

      > span svg path {
        fill: #212121;
      }
    }
  }
`;

/**
 * Not used now, but might be used again in the future.
 */
const InfoBanner = ({ projects, projectId }) => {
  const [tcfBanner, setTcfBanner] = useState('hide');
  const { t } = useTranslation();

  const areListsIntersecting = (listOfObjects, listOfIds) => {
    if (!listOfObjects || !listOfIds || listOfObjects.length === 0 || listOfIds.length === 0) {
      return false;
    }

    for (let obj of listOfObjects) {
      if (listOfIds.includes(obj?._id)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const displayTcfBanner = () => {
      const projectInTcfList = tcfRelatedProjects.includes(projectId);
      const showBanner = new Date() < new Date('2024-03-02');

      if (
        (projectInTcfList || areListsIntersecting(projects, tcfRelatedProjects)) &&
        showBanner &&
        Cookies.get('tcfBannerAdmin') !== 'hide'
      ) {
        setTcfBanner('show');
      } else {
        setTcfBanner('hide');
      }
    };

    displayTcfBanner();
  }, [projectId, projects]);

  const clickBannerButton = () => {
    setTcfBanner('hide');
    Cookies.set('tcfBannerAdmin', 'hide', { expires: 5 });
  };

  return (
    <>
      {tcfBanner !== 'hide' && (
        <BannerOfferWrapper>
          <BannerOffer>
            <div
              dangerouslySetInnerHTML={{
                __html: t('tcf_admin_banner')
              }}
            />
            <button onClick={clickBannerButton}>
              <Icon size={22} name="close" color="white" />
            </button>
          </BannerOffer>
        </BannerOfferWrapper>
      )}
    </>
  );
};

export default InfoBanner;

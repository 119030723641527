const projectConfigurations = [
  {
    url: 'consents',
    name: 'consents',
    title: 'Registre des consentements',
    description: 'Accédez à la liste de tous les consentements recueillis par Axeptio dans le cadre des traitements de données personnelles.',
    icon: 'helper_hours',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'main',
    dataFeature: 'consents_registry'
  },
  {
    url: 'scanner',
    name: 'scanner',
    title: 'Shake - Cookie Scanner',
    icon: 'icon_cookiescan',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'main'
  },
  {
    url: 'statsV1',
    name: 'statsV1',
    title: 'Statistiques V1',
    icon: 'helper_frequency',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'deprecated',
    dataFeature: 'statistics'
  },
  {
    url: 'marketplace',
    name: 'marketplace',
    title: 'Analytics Connectors',
    icon: 'icon_plug_connect',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'config'
  },
  {
    url: 'design',
    name: 'design',
    title: 'Couleurs et charte graphique',
    description: 'Afin de s\'intégrer au mieux dans vos sites et applications, vous pouvez ici modifier les couleurs et les fonts de ce projet',
    icon: 'project_design',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'config',
    dataFeature: 'customization'
  },
  {
    url: 'invoices',
    name: 'invoices',
    title: 'Factures',
    description: 'Consultez et téléchargez les factures pour ce projet.',
    icon: 'project_invoices',
    paint: 'gray',
    useStripe: true,
    isFree: true,
    category: 'billing'
  },
  {
    url: 'subscription',
    name: 'subscription',
    title: 'Gérer ma formule',
    description: 'Choisissez quels modules sont activés ou migrez vers un pack',
    icon: 'project_subscription',
    paint: 'gray',
    useStripe: true,
    isFree: true,
    category: 'billing'
  },
  {
    url: 'infos',
    name: 'infos',
    title: 'Informations',
    description: 'Modifiez ici les informations de base de votre projet, son intitulé, les URLs, etc.',
    icon: 'icon-formulaire',
    paint: 'gray',
    useStripe: true,
    isFree: true,
    category: 'config'
  },
  {
    url: 'integration',
    name: 'integration',
    title: 'Installation',
    description: 'Récupérez le code pour installer notre module et suivez les étapes pas à pas',
    icon: 'helper_double_optin',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'config'
  },
  {
    url: 'users',
    name: 'users',
    title: 'Utilisateurs et comptes',
    description: 'Invitez des collaborateurs à participer à l\'édition du projet. Vous pourrez également modifier les droits des utilisateurs existants ou les retirer du projet.',
    icon: 'project_users',
    paint: 'gray',
    useStripe: false,
    isFree: true,
    category: 'config'
  },
  {
    url: 'stats',
    name: 'stats',
    title: 'Statistiques',
    icon: 'helper_frequency',
    paint: 'gray',
    useStripe: 'FAUX',
    isFree: 'VRAI',
    category: 'main',
    dataFeature: 'statistics'
  }
];

export default projectConfigurations;
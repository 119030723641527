const services = [
  {
    name: 'cookies',
    title: 'Cookies banner',
    description: 'Taste cookies by Axeptio. A refreshing and respectful approach to CMP. Simple ideas (wait, be transparent, conversational) to increase opt-in rates and nurture brand image.',
    icon: 'icon_v2_cookie_happy',
    collection: 'cookies',
    templates: true,
    isversioned: true,
    monthlyPrice: '15 €',
    yearlyPrice: '150 €',
    planMonthly: 'plan_EK56NVSaUTYeVX',
    planYearly: 'plan_EK59JO3Qc05BEZ',
    page_title: 'Your users will finally like cookies again',
    page_subtitle: 'Reassuring, transparent and engaging',
    illustration: 'new_service_hover_cookies_v2_en',
    illustration_width: 600,
    comment_img: 'new_service_logo_1',
    comment_img_size: 600,
    existingItemsTitle: 'Existing configurations',
    newItemTitle: 'Create a new cookies configuration',
    no_item_title: 'You have no cookie configuration.',
    no_item_button: 'Create a new cookie configuration',
    newItemDescription: 'Feel free to create different versions of configuration to test and measure how they perform. Keep in mind that this will only apply to the domain you specified for the project.',
    isActive: true,
    isPlanActive: true,
    isPlanSlim: false,
    endpoint: 'vault',
    isActiveV2: true,
    labelName: 'Brands',
    labelColor: '#F7ECCF',
    labelIcon: 'product_icon_cookie',
    newIllustration: 'product_cookie_en',
    bgColor: '#FAE4A6',
    newTitle: 'My cookie banners',
    newDescription: 'The iconic consent banner: manage your cookies, change texts, colors and much more.',
    order: 1,
    featureToggle: false
  },
  {
    name: 'themes',
    title: 'Graphic Themes Management',
    description: 'Easily generate the visual of your widget using changeable themes from your website.',
    icon: 'artlovo',
    paint: 'yellow',
    collection: 'themes',
    templates: false,
    isversioned: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    planMonthly: 'plan_EK50il5T7mxm9G',
    planYearly: 'plan_ELW93Mgba1zjLr',
    page_title: 'Generate your graphic customizations online',
    page_subtitle: 'Flexibility on the graphic style of your widget in a few clicks',
    illustration: 'service_cookies_hover',
    illustration_width: 300,
    comment_img: 'new_service_logo_2',
    comment_img_size: 600,
    no_item_title: 'You don\'t have any graphic theme.',
    no_item_button: 'Create a new theme',
    isActive: false,
    isPlanActive: false,
    isPlanSlim: true,
    endpoint: 'vault',
    featureToggle: false
  },
  {
    name: 'processings',
    title: 'Marketing Consent Management ',
    description: 'Newsletter, telephone prospection, SMS, etc. Make sure you get an educated and contextual consent for every marketing operation. Not only will you meet legal requirements, but you will also reinforce trust and likeability',
    icon: 'icon-consentement-newsletter',
    collection: 'processings',
    templates: true,
    isversioned: false,
    monthlyPrice: '10 €',
    yearlyPrice: '100 €',
    planMonthly: 'plan_EK55PXn95UPRqQ',
    planYearly: 'plan_EK555jEoVfSLQc',
    page_title: 'Enter the positive marketing era',
    page_subtitle: 'The best way to comply with regulation and generate opt-in',
    illustration: 'new_service_hover_processing_v2_en',
    illustration_width: 600,
    comment_img: 'new_service_logo_2',
    comment_img_size: 600,
    existingItemsTitle: 'Your marketing consent management ',
    newItemTitle: 'Add a new processing widget',
    no_item_title: 'You have no marketing consent configuration. ',
    no_item_button: 'Create a new configuration',
    newItemDescription: 'Consent is the right legal basis for any marketing data processing. Create as many widgets as you need.',
    isActive: true,
    isPlanActive: true,
    isPlanSlim: true,
    endpoint: 'vault',
    dataFeature: 'marketing_consents',
    isActiveV2: true,
    labelName: 'Subs',
    labelColor: '#ECF9F7',
    labelIcon: 'product_icon_subs',
    newIllustration: 'product_subs_en',
    bgColor: '#B6E2DD',
    newTitle: 'My marketing consents',
    newDescription: 'An insert to manage subscriptions to your newsletters, forms or contests.',
    order: 4,
    featureToggle: false
  },
  {
    name: 'contracts',
    title: 'Contractual Consent Management ',
    description: 'Use the Axeptio widget to request contractual agreement. ',
    icon: 'icon_v2_papers',
    collection: 'contracts',
    templates: true,
    isversioned: false,
    monthlyPrice: '15 €',
    yearlyPrice: '150 €',
    planMonthly: 'plan_EK54BWp89WXHz3',
    planYearly: 'plan_EK54w5PASnn7pD',
    page_title: 'Keep an eye on your checkboxes',
    page_subtitle: 'ToS, EULA, NDA... ',
    illustration: 'new_service_hover_contracts',
    illustration_width: 600,
    comment_img: 'new_service_logo_3',
    comment_img_size: 600,
    existingItemsTitle: 'Your documents',
    newItemTitle: 'New document',
    no_item_title: 'You have no contractual consent configuration',
    no_item_button: 'Create a new configuration',
    isActive: true,
    isPlanActive: true,
    isPlanSlim: true,
    endpoint: 'vault',
    dataFeature: 'contract_consents',
    featureToggle: false
  },
  {
    name: 'contractsV2',
    title: 'Terms',
    description: 'New enhanced version of our contract consents widget !',
    icon: 'icon_v2_terms',
    collection: 'contracts',
    templates: false,
    isversioned: false,
    monthlyPrice: 15,
    yearlyPrice: 150,
    planMonthly: 'plan_EK54BWp89WXHz3',
    planYearly: 'plan_EK54w5PASnn7pD',
    page_title: 'Keep an eye on your checkboxes',
    page_subtitle: 'ToS, EULA, NDA... ',
    illustration: 'new_service_hover_contracts_v2_en',
    illustration_width: 600,
    comment_img: 'new_service_logo_3',
    comment_img_size: 600,
    existingItemsTitle: 'Your documents',
    newItemTitle: 'New document',
    no_item_title: 'You have no contractual consent configuration',
    no_item_button: 'Créer une nouvelle configuration',
    isActive: true,
    isPlanActive: true,
    isPlanSlim: true,
    endpoint: 'content',
    dataFeature: 'contract_consents_v2',
    isActiveV2: true,
    labelName: 'Terms',
    labelColor: '#FFF0E5',
    labelIcon: 'product_icon_terms',
    newIllustration: 'product_terms_en',
    bgColor: '#FAC6A1',
    newTitle: 'My general terms and conditions ',
    newDescription: 'An insert to display your terms and conditions. More transparency, less disputes.',
    order: 3,
    featureToggle: false
  },
  {
    name: 'portability',
    title: 'Personal Data Sharing',
    description: 'You want to share personal data with third parties? We have what you need ! As with marketing consents, give your users a reassuring and detailed widget.',
    icon: 'icon_v2_portabilité',
    collection: 'portability',
    templates: true,
    isversioned: true,
    monthlyPrice: '10 €',
    yearlyPrice: '100 €',
    planMonthly: 'plan_EK52rY1ao30RDA',
    planYearly: 'plan_EK52mn0VmTfRfM',
    page_title: 'A reassuring widget for data sharing',
    page_subtitle: 'Simple ideas to convince your customers',
    illustration: 'service-icon-portability',
    illustration_width: 600,
    comment_img: 'new_service_logo_4',
    comment_img_size: 600,
    existingItemsTitle: 'Your data sharing widgets',
    newItemTitle: 'Create a new data sharing widget',
    no_item_title: 'You have no confuguration for personal data processing',
    no_item_button: 'Create a new configuration',
    isActive: false,
    isPlanActive: false,
    isPlanSlim: true,
    endpoint: 'vault',
    featureToggle: false
  },
  {
    name: 'dpo',
    title: 'Data Protection Officer',
    description: 'Create an identification card for your data protection officer. Add it to your website to showcase your GDPR compliance and provide simple and direct access for your users.',
    icon: 'icon_v2_DPO',
    collection: 'dpo',
    templates: false,
    isversioned: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    inBundle: 'pro',
    planMonthly: 'plan_EK50B7g5jAMCGd',
    planYearly: 'plan_EK50V8LPUD3TDo',
    page_title: 'Put your team under the spotlight',
    page_subtitle: 'A straightforward access for your users',
    illustration: 'new_service_hover_dpo',
    illustration_width: 600,
    comment_img: 'new_service_logo_1',
    comment_img_size: 600,
    existingItemsTitle: 'Your DPO Card',
    no_item_title: 'You have no configuration for your DPO\'s ID',
    no_item_button: 'Create a new configuration',
    isActive: true,
    isPlanActive: false,
    isPlanSlim: true,
    endpoint: 'vault',
    featureToggle: false
  },
  {
    name: 'privacypolicy',
    title: 'Privacy Policy Generator',
    description: 'Generate your privacy policy easily with the simple and fun form designed by our lawyer Maître Christophe Landat. Written by a legal professional, it will be more accessible to your users.',
    icon: 'service-icon-privacypolicy',
    paint: 'yellow',
    templates: false,
    isversioned: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    planMonthly: 'plan_EK50il5T7mxm9G',
    planYearly: 'plan_ELW93Mgba1zjLr',
    page_title: 'Generate a privacy policy',
    illustration: 'service-icon-privacypolicy',
    illustration_width: 300,
    comment_img: 'new_service_logo_2',
    comment_img_size: 600,
    no_item_title: 'You have no configuration for your privacy policy generator',
    no_item_button: 'Create a new configuration',
    isActive: false,
    isPlanActive: false,
    isPlanSlim: true,
    endpoint: 'vault',
    featureToggle: false
  },
  {
    name: 'allInOne',
    title: 'All In One',
    description: 'description all in one',
    icon: 'service-icon-privacypolicy',
    paint: 'yellow',
    collection: 'all-in-one',
    templates: true,
    isversioned: true,
    page_title: 'titre all in one\n',
    page_subtitle: 'soustitre all in one',
    page_features: 'feature all in one',
    illustration: 'service-icon-privacypolicy',
    illustration_width: 300,
    comment_img: 'new_service_logo_3',
    comment_img_size: 600,
    no_item_title: 'You have no All In One configuration.',
    no_item_button: 'Create a new configuration',
    isActive: true,
    isPlanActive: false,
    featureToggle: false
  },
  {
    name: 'shake',
    title: 'Shake',
    isActive: false,
    labelName: 'Shake',
    labelColor: '#E6EEFF',
    labelIcon: 'product_icon_shake',
    comingSoon: true,
    comingSoonLabel: 'Coming soon',
    comingSoonText: '<b>Shake, your compliance scanner is coming soon!<br /><br />You\'ll be able to take advantage of a new tool that will be great for your cookie-banners!</b> ',
    newIllustration: 'product_shake_en',
    bgColor: '#A5BFF1',
    newTitle: 'My compliance scans',
    newDescription: 'Scan and update my cookie banners to keep my compliance up to date.',
    featureToggle: false
  },
  {
    name: 'tcf',
    title: 'TCF Cookies Banner',
    description: 'TCF for publishers',
    icon: 'icon_v2_cookie_happy',
    collection: 'tcf',
    templates: true,
    illustration: 'new_service_hover_cookies_v2',
    illustration_width: 600,
    comment_img: 'new_service_logo_1',
    comment_img_size: 600,
    existingItemsTitle: 'Existing configurations',
    newItemTitle: 'Create a new cookies configuration',
    no_item_title: 'You have no TCF configuration.',
    no_item_button: 'Create a new TCF configuration',
    newItemDescription: 'Feel free to create different versions of configuration to test and measure how they perform. Keep in mind that this will only apply to the domain you specified for the project.',
    isActive: false,
    isActiveV2: true,
    labelName: 'Publishers',
    labelColor: '#E6EEFF',
    labelIcon: 'product_icon_cookie',
    newIllustration: 'product_publishers_en',
    bgColor: '#A5BFF1',
    newTitle: 'My TCF cookie banners',
    newDescription: 'A tailored version for websites with advertising, compatible with IAB\'s TCF v2.2.',
    order: 2,
    featureToggle: false
  }
];

export default services;
import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useApi } from '../../contexts/ApiContext';
import { addToast } from '../Toast/Toast';
import Footer from './Footer';
import CheckboxRadioRow from '../CheckboxRadioRow/CheckboxRadioRow';
import CheckboxRadioCard from '../CheckboxRadioCard/CheckboxRadioCard';
import { useTranslation } from 'react-i18next';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 20px;

  p {
    margin: 0px;
  }
`;
const CheckboxRadioRowContainer = styled.form`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
`;
const CheckboxRadioContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
`;
const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  &:after {
    content: '*';
    color: red;
    margin-left: 4px;
  }
`;
const Error = styled.span`
  font-size: 9px;
  color: red;
`;

const UserCompanyCmsStep = ({ footerCount, currentStep, onBack, onNext }) => {
  const { t } = useTranslation();
  const api = useApi();

  const [isLoading, setIsLoading] = useState(false);
  // Null state allow to know if the user has selected a value or not
  const [isWithCms, setIsWithCms] = useState(null);
  const [cmsSelected, setCmsSelected] = useState([]);
  const [user, setUser] = useState();
  const [cmsError, setCmsError] = useState(null);

  const onSubmit = async event => {
    event.preventDefault();
    if (isWithCms === null || (isWithCms && cmsSelected.length === 0)) {
      setCmsError(t.field_required);
    } else if (!isLoading) {
      setIsLoading(true);
      await api
        .updateUser({ 'data.company.cms': cmsSelected, 'data.profileCompleted': true })
        .then(() => onNext())
        .catch(error => addToast.error(error.message));
      setIsLoading(false);
    }
  };

  const updateCmsSelected = value => {
    if (cmsSelected.includes(value)) {
      setCmsSelected(old => old.filter(item => item !== value));
    } else {
      setCmsSelected([...cmsSelected, value]);
    }
  };

  const getUser = useCallback(async () => {
    await api
      .getUser(e => {
        setUser(e);
      })
      ?.catch(error => addToast.error(error.message));
  }, [api]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const cms = [
    { value: 'Wordpress', title: 'Wordpress', image: 'logo-CMS_wordpress' },
    { value: 'Shopify', title: 'Shopify', image: 'logo-CMS_shopify' },
    { value: 'Wix', title: 'Wix', image: 'logo-CMS_wix' },
    { value: 'Squarespace', title: 'Squarespace', image: 'logo-CMS_squarespace' },
    { value: 'Joomla', title: 'Joomla', image: 'logo-CMS_joomla' },
    { value: 'Prestashop', title: 'Prestashop', image: 'logo-CMS_prestashop' },
    { value: 'Drupal', title: 'Drupal', image: 'Drupal' },
    { value: 'Magento', title: 'Magento', image: 'logo-CMS_magento' },
    { value: 'Webflow', title: 'Webflow', image: 'Webflow' },
    { value: 'Other', title: 'Other', image: 'Hubspot' }
  ];

  return (
    <Container onSubmit={onSubmit}>
      <Label>{t('onboarding_modal_company_cms_label')}</Label>
      <CheckboxRadioRowContainer>
        <CheckboxRadioRow
          title={t('yes')}
          name="yes"
          type="radio"
          value={isWithCms}
          onChange={() => {
            setIsWithCms(true);
            setCmsError(null);
          }}
        />
        <CheckboxRadioRow
          title={t('nope')}
          name="no"
          type="radio"
          value={isWithCms === false}
          onChange={() => {
            setIsWithCms(false);
            setCmsSelected([]);
            setCmsError(null);
          }}
        />
      </CheckboxRadioRowContainer>

      {isWithCms && (
        <>
          <Label>{t('onboarding_modal_company_which_cms_label')}</Label>
          <CheckboxRadioContainer>
            {cms.map(field => (
              <CheckboxRadioCard
                key={field.value}
                title={field.title}
                name={field.value}
                image={field.image}
                size="small"
                value={cmsSelected.includes(field.value)}
                onChange={() => {
                  updateCmsSelected(field.value);
                  setCmsError(null);
                }}
                width="215px"
              />
            ))}
          </CheckboxRadioContainer>
        </>
      )}
      {cmsError && <Error>{cmsError}</Error>}
      <Footer
        count={footerCount}
        currentStep={currentStep}
        onBack={() => onBack(user?.data?.company?.type !== 'selfservice' ? 0 : 1)}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default UserCompanyCmsStep;

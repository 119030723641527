import React, { Component } from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/colors';

export const ExpandableContent = styled.div`
  margin: 0 -10px;
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out !important;
`;

export const ExpandableToggleButton = styled.button`
  outline: none;
  border: none;
  background: none;
  user-select: none;
  padding: 0;
  margin-bottom: ${props => (props.toggleButtonMargin ? 0 : 20)}px;
  color: ${COLORS.GRAY_500};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.15s ease;

  &:hover {
    color: ${COLORS.GRAY_900};
  }
`;

class Expandable extends Component {
  state = {
    open: false,
    height: 0
  };
  expandable = React.createRef();
  inner = React.createRef();

  heightCheckLoopActive = false;

  setHeight(cb) {
    if (!this.state.open) {
      if (this.state.height !== 0) {
        return this.setState({ height: 0 }, cb);
      } else {
        return cb();
      }
    }
    window.requestAnimationFrame(() => {
      if (this.inner.current) {
        const height = getComputedStyle(this.inner.current).height;
        if (this.state.height !== height) {
          return this.setState({ height }, cb);
        }
      }
      cb();
    });
  }

  componentDidMount() {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open }, () => {
        this.startHeightCheckLoop();
      });
    } else {
      this.startHeightCheckLoop();
    }
  }

  componentWillUnmount() {
    this.stopHeightCheckLoop();
  }

  startHeightCheckLoop() {
    const loop = () => {
      this.setHeight(() => {
        if (this.heightCheckLoopActive) {
          setTimeout(loop, 250);
        }
      });
    };

    this.heightCheckLoopActive = true;
    loop();
  }

  stopHeightCheckLoop() {
    this.heightCheckLoopActive = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          this.setHeight(() => {});
        }
      );
    }
  }

  handleExpand() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { open } = this.state;
    const { labelClosed, labelOpen, children } = this.props;
    return (
      <div>
        {labelClosed && labelOpen && (
          <ExpandableToggleButton
            type="button"
            onClick={() => this.handleExpand()}
            toggleButtonMargin={this.props.toggleButtonMargin}
          >
            {open ? labelOpen : labelClosed}
          </ExpandableToggleButton>
        )}
        <ExpandableContent
          ref={this.expandable}
          style={{ maxHeight: this.state.open ? this.state.height : 0 }}
          aria-hidden={this.state.open ? 'false' : 'true'}
          tabIndex={this.state.open ? '0' : '-1'}
        >
          <div ref={this.inner}>{children}</div>
        </ExpandableContent>
      </div>
    );
  }
}

export default Expandable;

import Card from '../../Card/Card';
import Assets from '../../../services/Assets';
import React from 'react';
import { Link } from 'react-router-dom';

export const openConfigurationPage = ({ conf, project }) => {
  window.location = '/projects/' + project._id + '/config/' + conf.url;
};

const ConfigurationPageCard = props => (
  <Link to={props.isLocked ? null : 'config/' + props.conf.url} relative="path">
    <Card
      title={props.conf.title}
      description={props.conf.description}
      paint={props.conf.paint}
      imageSize="medium"
      image={Assets.image(props.conf.icon)}
      {...props}
    />
  </Link>
);

export default ConfigurationPageCard;

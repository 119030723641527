import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import AxeptioLogo from '@axeptio/widget-client/src/components/AxeptioLogo/AxeptioLogo.js';
import media from 'styled-media-query';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';
import { getContractV2Id, correctedCookieVersion } from '../../base/helpers';
import { useTranslation } from 'react-i18next';

const Root = styled.footer`
  font-family: ${FONTS.SOURCE};
  background: ${COLORS.GRAY};
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 300;
  margin-top: auto;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${media.greaterThan('medium')`
    padding: 15px 0;
  `};

  ${media.lessThan('medium')`
    padding: 20px 0;
  `};

  ${media.greaterThan('large')`
    padding: 0;
    height: 36px;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
  `};
`;

const bulletMixin = css`
  position: absolute;
  display: inline-block;
  content: '';
  border-radius: 4px;
  height: 4px;
  width: 4px;
  margin: 0;
  background-color: ${COLORS.GRAY_150};
  left: -2px;
  top: calc(50% - 2px);
`;

const GroupLinks = styled.div`
  display: flex;
  align-items: center;

  ${media.greaterThan('large')`

    ${props =>
      props.left &&
      `
      justify-content: right;
    `};

    ${props =>
      props.right &&
      `
      justify-content: left;
    `};

    ${props =>
      (props.left || props.right) &&
      `
      width: calc(50% - 90px);
    `};
  `};

  ${media.greaterThan('medium')`
    > div:nth-child(2):before,
    > div:nth-child(3):before {
      ${bulletMixin};
    }
  `};

  ${media.lessThan('medium')`
    display: inline;
    text-align: center;
  `};

  ${media.lessThan('small')`
    display: flex;
    text-align: center;
    width: 100%;
    flex-direction: column;
  `};
`;

const FooterLink = styled.div`
  position: relative;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  color: ${COLORS.GRAY_150};
  cursor: pointer;
  padding: 5px 20px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  ${media.lessThan('medium')`
    display: inline-block;
    margin: 2px 0;
  `};
`;

const Logo = styled(FooterLink)`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  position: absolute;
  left: calc(50% - 50px);
  height: 36px;
  cursor: default;
  padding: 0;

  ${media.lessThan('large')`
    position: relative;
    order: -1;
    justify-self: stretch;
    left: unset;
    width: 100%;
  `};
`;

const LegalNotice = styled(FooterLink)`
  ${media.between('medium', 'large')`
    &:before {
      ${bulletMixin};
    }
  `};
`;

const Footer = ({ locale, user, impersonate }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (window.axeptioSDK) {
      window.axeptioSDK.on('ready', () => {
        if (window.axeptioSettings?.cookiesVersion !== correctedCookieVersion()) {
          window.axeptioSDK.setCookiesVersion(correctedCookieVersion());
        }
      });

      if (user && !impersonate) {
        window.axeptioSDK.on('ready', () => {
          const contractIdToCheck = getContractV2Id('terms_of_use');
          if (contractIdToCheck) {
            window.axeptioSDK.checkContracts(
              [{ name: contractIdToCheck, consentFor: user._id }] // An array of contract id is required
            );
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <Root>
      <Wrapper>
        <GroupLinks left>
          <FooterLink onClick={() => window.openAxeptioCookies()}>{t('home_cookies_params')}</FooterLink>

          <FooterLink
            onClick={() =>
              window.axeptioSDK?.openContract(getContractV2Id('privacy_policy'), {
                readOnly: true
              })
            }
          >
            {t('home_data_privacy_params')}
          </FooterLink>

          <FooterLink
            onClick={() =>
              window.axeptioSDK?.openContract(
                {
                  name: getContractV2Id('terms_of_use')
                },
                { readOnly: true }
              )
            }
          >
            {t('home_cgu_params')}
          </FooterLink>
        </GroupLinks>

        <Logo>
          <AxeptioLogo size={100} height={50} color={'white'} />
        </Logo>

        <GroupLinks right>
          <LegalNotice
            onClick={() =>
              window.axeptioSDK?.openContract(getContractV2Id('legal_notice'), {
                readOnly: true
              })
            }
          >
            {t('home_legal_notice_params')}
          </LegalNotice>

          <FooterLink
            onClick={() =>
              window.axeptioSDK?.openContract(
                {
                  name: getContractV2Id('dpa')
                },
                { readOnly: true }
              )
            }
          >
            {t('home_dpa_params')}
          </FooterLink>
        </GroupLinks>
      </Wrapper>
    </Root>
  );
};

export default Footer;

const accessLimitsMap = {
  cookiesVendors: {
    name: 'cookiesVendors',
    type: 'free',
    value: 2,
    errorMessage: 'Vendors limit reached',
    service: 'cookies'
  },
  cookiesSteps: {
    name: 'cookiesSteps',
    type: 'free',
    value: 2,
    errorMessage: 'Cookies steps count',
    service: 'cookies'
  },
  processingsButtons: {
    name: 'processingsButtons',
    type: 'free',
    value: 2,
    errorMessage: 'Max buttons count',
    service: 'processings'
  },
  contractsButtons: {
    name: 'contractsButtons',
    type: 'free',
    value: 2,
    errorMessage: 'Max buttons count',
    service: 'contracts'
  },
  dpoClaim: {
    name: 'dpoClaim',
    type: 'free',
    value: false,
    errorMessage: 'DPO claim activated',
    service: 'dpo'
  },
  dpoContact: {
    name: 'dpoContact',
    type: 'free',
    value: false,
    errorMessage: 'DPO contact form activated',
    service: 'dpo'
  },
  watermark: {
    name: 'watermark',
    type: 'free',
    value: true,
    errorMessage: 'Axeptio watermark hidden'
  },
  cookiesEmptyStep: {
    name: 'cookiesEmptyStep',
    type: 'free',
    value: false,
    service: 'cookies'
  },
  cookiesEditStrings: {
    name: 'cookiesEditStrings',
    type: 'free',
    value: false,
    errorMessage: 'Modified cookies copy',
    service: 'cookies'
  },
  processingsWidgets: {
    name: 'processingsWidgets',
    type: 'free',
    value: 1,
    errorMessage: 'Max consent widgets',
    service: 'processings'
  },
  portabilityWidgets: {
    name: 'portabilityWidgets',
    type: 'free',
    value: 1,
    errorMessage: 'Max portability widgets',
    service: 'portability'
  },
  contractsWidgets: {
    name: 'contractsWidgets',
    type: 'free',
    value: 1,
    errorMessage: 'Max contracts widgets ',
    service: 'contracts'
  },
  portabilityProcessors: {
    name: 'portabilityProcessors',
    type: 'free',
    value: 2,
    errorMessage: 'Max sub-processors limit reached',
    service: 'portability'
  },
  customImagesUpload: {
    name: 'customImagesUpload',
    type: 'free',
    value: false,
    errorMessage: 'Custom images used'
  },
  addProjectUsers: {
    name: 'addProjectUsers',
    type: 'free',
    value: true,
    errorMessage: 'Mult-user project'
  },
  withSaveConsent: {
    name: 'withSaveConsent',
    type: 'free',
    value: false,
    errorMessage: 'Save consent proofs',
    getBackToFree: 'Uncheck option "Save consent proof" in your cookies configuration',
    service: 'cookies'
  },
  cookiesStepDisablePaint: {
    name: 'cookiesStepDisablePaint',
    type: 'free',
    value: false,
    errorMessage: 'Paint disabled',
    service: 'cookies'
  },
  cookiesStepLayout: {
    name: 'cookiesStepLayout',
    type: 'free',
    errorMessage: 'Premium cookie step',
    service: 'cookies'
  },
  clientCustomButton: {
    name: 'clientCustomButton',
    type: 'free',
    errorMessage: 'Customized Button',
    service: 'config/design'
  },
  clientCustomStyle: {
    name: 'clientCustomStyle',
    type: 'free',
    errorMessage: 'Customized Style',
    service: 'config/design'
  },
  hideAxeptioButton: {
    name: 'hideAxeptioButton',
    type: 'free',
    value: false,
    errorMessage: 'Button hidden',
    service: 'cookies'
  },
  inactiveSubscription: {
    name: 'inactiveSubscription',
    type: 'free',
    errorMessage: 'Inactive subscription',
    getBackToFree: 'Bring back your subscription to life'
  },
  unableToFetchSubscription: {
    name: 'unableToFetchSubscription',
    type: 'free',
    errorMessage: 'Unable to fetch your subscription',
    getBackToFree: 'Something wrong happened when we tried to get your current subscription from Stripe. Please contact us'
  },
  aboutUs: {
    name: 'aboutUs',
    type: 'free',
    errorMessage: 'Content of the "About Axeptio" panel changed',
    getBackToFree: 'Select the first option',
    service: 'cookies'
  },
  specialSteps: {
    name: 'specialSteps',
    type: 'paid',
    errorMessage: 'Use of contextual consent wall',
    getBackToFree: 'Feature available starting from Medium plan',
    service: 'cookies'
  },
  maxProjectsLimitReached: {
    name: 'maxProjectsLimitReached',
    type: 'paid',
    errorMessage: 'You\'ve reached the max projects limit for your organization',
    getBackToFree: 'Please delete enough projects to be able to publish your organization projects configs. You can also remove this project from the organization and pay for a dedicated subscription for this project.'
  },
  contractsV2Widgets: {
    name: 'contractsV2Widgets',
    type: 'paid',
    errorMessage: 'You can\'t use contracts service with your subscription',
    getBackToFree: 'Please don\'t submit any contract version while publishing',
    service: 'contractsV2'
  },
  unpaidSubscription: {
    name: 'unpaidSubscription',
    type: 'paid',
    errorMessage: 'Subscription past due',
    getBackToFree: 'Please regularize your subscription payment'
  },
  contextualConsentWall: {
    name: 'contextualConsentWall',
    type: 'paid',
    errorMessage: 'You can\'t show the contextual consent wall with a delay below 5s',
    getBackToFree: 'Feature available only for Large plan',
    service: 'cookies'
  },
  noSubscription: {
    name: 'noSubscription',
    type: 'free',
    errorMessage: 'No subscription',
    getBackToFree: 'A subscription is required',
    service: 'config/subscription'
  },
  freeTrialProjectsLimitReached: {
    name: 'freeTrialProjectsLimitReached',
    type: 'free',
    errorMessage: 'Free trial projects limit reached',
    getBackToFree: 'You won\'t be able to publish a new one, unless you subscribe to an upper plan.',
    service: 'config/subscription'
  },
  noTcfSubscription: {
    name: 'noTcfSubscription',
    type: 'free',
    errorMessage: 'Insufficient subscription',
    getBackToFree: 'A \'Small\' subscription or higher is required to publish an \'Axeptio for Publishers\' configuration ',
    service: 'config/subscription'
  }
};

export default accessLimitsMap;
const portabilityTemplates = [
  {
    name: 'gdpr',
    label: 'Portabilité des données persos GDPR',
    description: 'Et si le GDPR devenait un argument marketing. Grâce à ce wording, vous mettez en avant un des nouveaux droits fondamentaux consacrés par le règlement européen, tout en rendant la vieillote "case à cocher partenaires" beaucoup plus désirable.',
    icon: 'icon_v2_portabilité',
    title: 'Portabilité de vos données personnelles',
    message: 'Nous travaillons avec des partenaires à qui nous pouvons partager un peu de vos données, nous les trions sur le volet et pouvons vous assurer qu\'ils respectent les meilleurs standards de protection et de sécurisation. Pour vous, ça veut dire des offres plus pertinentes et adaptées à vos attentes, sans avoir à vous faire reconnaître.',
    isFree: false,
    language: 'fr'
  },
  {
    name: 'friendly',
    label: 'Formulation sympathique',
    description: 'Jouez la carte de l\'empathie avec une tournure plus sympathique',
    icon: 'icon_v2_nice_talk',
    title: 'Comme vous, on a des potes',
    message: 'Afin de vous proposer des deals toujours plus intéressants, nous avons noués des partenariats avec des entreprises qui partagent nos valeurs et notre goûts pour le marketing choisi, et non subi ! ',
    isFree: true,
    language: 'fr'
  }
];

export default portabilityTemplates;
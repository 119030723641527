const adminStrings = {
  cookie_widget_copyright: 'Consents verified by',
  steps_title: 'Steps',
  steps_add: 'Add a step',
  design_title: 'Design',
  design_main: 'Main color',
  design_paint: 'Paint color',
  advanced_title: 'Advanced configuration',
  advanced_strings: 'Widget button texts',
  advanced_strings_desc: 'Here you can edit every string or label inside your cookie widget.',
  advanced_settings: 'Integration on the website',
  advanced_settings_desc: 'Grab the code, connect to your GTM and tweak the apparition settings',
  advanced_version: 'Versioning',
  advanced_version_desc: 'Rename this version',
  btn_previewChanges: 'Preview changes',
  btn_save: 'Save',
  strings_loadPreset: 'Load a text preset',
  strings_title: 'Change the texts',
  step_addStep: 'New step',
  step_name: 'Step unique identifier',
  step_title: 'Title',
  step_policyURL: 'Privacy Policy URL',
  step_topTitle: 'Surtitle',
  step_subTitle: 'Subtitle',
  step_message: 'Message',
  step_vendors: 'Vendors',
  step_addVendor: 'Add a new vendor',
  step_allowOptOut: 'Show the opt-out button on this screen (recommended)',
  step_collapseVendors: 'Expand / Collapse vendors list',
  step_showToggleAllSwitch: 'Show "toggle all" switch',
  step_image: 'Illustration',
  step_strings: 'Edit the button texts for this step',
  step_save: 'Save',
  step_remove: 'Remove this step',
  vendorsList_title: 'Add a new vendor',
  vendors_list_policy_mention: 'See the privacy policy of',
  vendor_title: 'Vendor title',
  vendor_name: 'Vendor unique name',
  vendor_name_hint: 'This name will be sent to the website to activate the service if the user consents. It is case sensitive!',
  vendor_shortDescription: 'Short description (one liner)',
  vendor_longDescription: 'Long description',
  vendor_domain: 'Vendor\'s domain',
  vendor_domain_hint: 'With the domain we\'ll be able to show a nice favicon',
  vendor_save: 'Save',
  vendor_remove: 'Delete this service',
  versions_title: 'Versions',
  versions_addNew: 'Create a new version',
  versions_addNew_desc: 'Feel free to create different versions of configuration to test and measure how they perform. Keep in mind that this will only apply on the domain you specified for the project.',
  version_name: 'Title of the version',
  version_name_placeholder: 'Name the version to identify it rapidly',
  version_active: 'Active',
  version_distribution: 'Distribution',
  version_distribution_unit: '%',
  app_title: 'Hi and welcome',
  app_intro: 'At Axeptio, we are not very fan of cookies banners that hide half of the screen or jump at the throats of users. That\'s why we have developed a new way to collect the consent of your users regarding cookies.',
  login_title: 'It all starts with a login',
  login_email: 'Email',
  login_password: 'Password',
  services_title: 'My services',
  services_cookies_title: '#N/A',
  services_cookies_description: '#N/A',
  services_privacypolicy_title: '#N/A',
  services_privacypolicy_description: '#N/A',
  services_marketingconsents_title: '#N/A',
  services_marketingconsents_description: '#N/A',
  services_contracts_title: '#N/A',
  services_contracts_description: '#N/A',
  services_portability_title: '#N/A',
  services_portability_description: '#N/A',
  services_dpo_title: '#N/A',
  services_dpo_description: '#N/A',
  dpo_fullname_label: 'Name',
  dpo_organization_label: 'Name of the organization / legal entity',
  dpo_title_label: 'Title',
  dpo_email_label: 'Email address',
  dpo_address_label: 'Postal address\n',
  dpo_telephone_label: 'Telephone',
  dpo_bio_label: 'Bio',
  dpo_bio_picture: 'Photo',
  integration_domains_newItem_title: 'Add a new domain',
  integration_domains_newItem_description: 'Referencing multiple domains allows us to verify that the SDK is correctly installed',
  integration_sdk_title: 'Add the Axeptio SDK',
  integration_sdk_instructions: 'To integrate Axeptio on your site, just add these lines of code at the foot of each of your pages.',
  integration_domains_title: 'Websites and active domain names',
  gdpr_automated_decisions_information_label: 'You have to notifiy the user of: \n- his right to refuse to an automated processing\n- his right to get explanations about the results of an automated processing\n- his right to ask for a human to review the results of an auotmated processing',
  gdpr_automated_decisions_information_help: 'Specify here to the user:\n- his right not to be subject to automated processing\n- his right to ask for explanations on the reason for the treatment\n- his right to request a human intervention on the results generated by the algorithms',
  gdpr_automated_decisions_title: 'Automated decisions, including profiling',
  gdpr_automated_decisions_description: 'Because you\'re responsible of the procesing of personal data, you are required to inform the user when decisions are fonded on automated decisions (e.g. algorithm)',
  gdpr_automated_decisions_contact_label: 'Person to contact for every profiling requests',
  gdpr_automated_decisions_contact_help: 'Indicate here how a user can request a human to review the results of the processing',
  gdpr_consent_withdraw_label: 'Consent withdrawal right',
  gdpr_datatypes_label: 'Types of personal data being processed',
  gdpr_datatypes_disclaimer: 'Axeptio does not allow the collection of sensitive data, such as religion, political opinions or health data.',
  gdpr_purpose_label: 'Purpose of the processing',
  gdpr_processors_label: 'Processors',
  gdpr_consent_duration_label: 'Consent duration',
  gdpr_processing_duration_label: 'Processing duration',
  strings_change_tone: 'Choose a tone',
  strings_change_tone_placeholder: 'Select a preset',
  projects_create: 'Create a new project',
  sign_out: 'Sign out',
  projects_title: 'My Projects',
  cookies_categories_custom_title: 'Create a custom category',
  cookies_categories_custom_description: 'Start from scratch and build the widget the way you want it.',
  cookies_vendors_new: 'Add a new vendor',
  cookies_vendors_edit: 'Edit the vendor',
  cookies_vendors_new_title: 'New vendor',
  vendors_searchBox_placeholder: 'Type the name of the service you\'re looking for',
  vendors_custom_title: 'Create a personal cookie',
  vendors_custom_description: 'You can not find the service you\'re looking for? No problem, click here and start from scratch.',
  vendors_addSelected: 'Add the selected vendors',
  gdpr_processors_new_title: 'New processor',
  gdpr_processors_new_description: 'Add a processor',
  grpd_processors_help: 'Indicate hiere the companies, services or tools that will have access to the personal data you\'re collecting. This can ben the marketing automation software, or a call-center... ',
  gdpr_storage_duration_label: 'Storage duration',
  gdpr_storage_duration_text: 'Storage duration can be: required by law, recommanded by supervisory authority, or chosen by you as long as it\'s reasonable.',
  gdpr_storage_duration_url: '*gdpr_storage_duration_url',
  gdpr_storage_duration_title: '*gdpr_storage_duration_title',
  gdpr_datatypes_help: 'The data subjects are identifiable if they can be directly or indirectly identified, especially by reference to an identifier such as a name, an identification number, location data, an online identifier or one of several special characteristics, which expresses the physical, physiological, genetic, mental, commercial, cultural or social identity of these natural persons. In practice, these also include all data which are or can be assigned to a person in any kind of way. For example, the telephone, credit card or personnel number of a person, account data, number plate, appearance, customer number or address are all personal data.\n\nSince the definition includes “any information,” one must assume that the term “personal data” should be as broadly interpreted as possible. \n',
  gdpr_processing_purpose: 'Purpose of the personal data processing',
  embed_regex_help: 'If you want to target several pages with the same injection code, you can check the RegExp checkbox. Once your changes are published, our SDK will evaluate your regexp agains <code>window.location.pathname</code>',
  embed_regex_label: 'RegExp',
  embed_pages_placeholder: 'about',
  embed_selector_label: 'Target element for injection (CSS Selector)',
  embed_selector_help: 'To place the widget where you want on the webpage, we use a CSS selector to determine a sibling or parent element to "mount" the widget. A convenient way to discover to right CSS selector for the job is to use SelectorGadget, a browser extension.',
  embed_injection_title: 'Inject the widget on your pages',
  embed_pages_label: 'Pages where the widget must be injected',
  embed_injection_method: 'Injection method',
  embed_injection_method_first: 'inside the target element, at first',
  embed_injection_method_last: 'inside the target element, at last',
  embed_injection_method_eq: 'inside the target element, a a specified index',
  embed_injection_method_before: 'before the target element',
  embed_injection_method_after: 'after the target element',
  embed_injection_method_replace: 'replace the target element',
  login_reset_password_success: 'It\'s all set ! A mail containing a special magic link has been sent ',
  login_reset_password_error: 'An error occured.',
  login_error: 'We are unable to identify you. Please check your details.',
  consent_buttons_action_accept: 'Total agreement',
  consent_buttons_action_partial: 'Partial agreement',
  consent_buttons_action_reject: 'Rejection',
  consent_buttons_action_show: 'See the document',
  processings_content_title: 'Content editing',
  processings_user_experience_title: 'User experience customization',
  processings_gdpr_title: 'Consent details, personal data processing',
  processings_email_form_title: 'Email field properties',
  processings_form_text: 'Here\'s the code that will be added into your form',
  processings_message_form_title: 'Message field property',
  processings_message_form_label: 'Number of rows (height)',
  processings_message_form_text: 'Here\'s the code that will be added into your form',
  processings_time_of_day_title: 'Time of day field properties',
  processings_time_of_day_form_text: 'Here\'s the code that will be added into your form',
  processings_weekdays_title: 'Weekdays field properties',
  processings_weekdays_form_text: 'Here\'s the code that will be added into your form',
  processings_augmented_consent_form_label: 'Label',
  processings_augmented_consent_form_control_label: '"name" attribute of the control',
  processings_augmented_consent_form_control_text: 'Name of the field that will be added to your form',
  processings_button_form_label: 'Button text',
  processings_button_form_action_label: 'Action',
  processings_button_form_title_label: 'Button "title" attribute',
  processings_button_form_title_text: 'Small text displayed when the mouse is hovering the button',
  processings_button_form_value_label: 'Button value',
  processings_button_form_color_label: 'Color',
  processings_confirmation_editor_label: 'Title',
  processings_confirmation_editor_message_label: 'Message',
  processings_confirmation_editor_back_button_label: 'Back button text',
  contracts_editor_title_label: 'Title',
  contracts_editor_message_label: 'Message',
  contracts_editor_is_required_label: 'Is required',
  contracts_editor_is_required_text: 'If you activate this switch, Axeptio will add to the form containing the widget an input of type checkbox with a "required" attribute. If it\'s a simple HTML form, web browsers are going to handle the form validation for you. On the other hand, if the form is sent using Ajax, you will have to code a custom validation function.',
  contracts_editor_dropzone_label: 'Add a document',
  contracts_editor_buttons_label: 'Buttons',
  cookies_editor_title_label: 'Versions',
  cookies_editor_name_label: 'Cookies version',
  cookies_editor_step_wizard_name_label: 'Informative screens',
  cookies_editor_step_wizard_category_card_label: 'Category templates',
  cookies_editor_step_wizard_main_step_title: 'Main steps\n',
  cookies_editor_step_wizard_many_cookie_step_title: 'Do you have a lot of cookies ?',
  cookies_editor_step_wizard_many_cookie_step_text: 'Group them in different steps',
  cookies_editor_step_wizard_other_cookie_step_title: 'Other screens',
  cookies_editor_step_wizard_other_cookie_step_text: 'Advanced configuration',
  cookies_editor_version_wizard_name_label: 'Name of the version',
  cookies_editor_version_wizard_save_button: 'Confirm',
  cookies_editor_settings_form_integration_title: 'SDK Integration',
  cookies_editor_settings_form_integration_text: 'To integrate cookies on your site, you must first integrate the script Axeptio in the foot of your pages!',
  cookies_editor_settings_form_opening_title: 'Opening settings',
  cookies_editor_settings_form_opening_label: 'Opening timeout',
  cookies_editor_settings_form_opening_text: '<p>Indicate here how long the widget waits before popping. <strong>We recommend to wait between 4 and 6 seconds</strong></p>',
  cookies_editor_settings_form_hide_button_label: 'Hide the Axeptio button when user has completed the process',
  cookies_editor_settings_form_scroll_threshold_label: 'Scroll height threshold to trigger the cookies widget.',
  cookies_editor_settings_form_scroll_threshold_text: 'Axeptio interprets scrolling as a mark of engagent.',
  cookies_editor_settings_form_whitelist_label: 'URL where the Axeptio widget should not be displayed',
  cookies_editor_settings_form_whitelist_text: 'List of URLs where the widget should not be displayed. You can insert several paths separated by commas.',
  cookies_editor_settings_form_allowEscapeKey_label: 'Close widget popup with Esc key when element is focused.',
  cookies_editor_settings_form_storage_key_title: 'Saving user preferences',
  cookies_editor_settings_form_storage_key_label: 'Storage key',
  cookies_editor_settings_form_storage_key_text: 'Name of the cookie or name of the property used to save the users choices.',
  cookies_editor_settings_form_storage_type_label: 'Where do you want to store user preferences',
  cookies_editor_settings_form_storage_type_text: 'Choose which method you want to use to store user preferences. Beware ! SessionStorage is not persistent.',
  cookies_editor_settings_form_gtm_label: 'Send events to Google Tag Manager',
  cookies_editor_settings_form_gtm_text: 'If you\'re using GTM, Axeptio is able to push events into GTM dataLayer. ',
  cookies_editor_settings_form_gtm_trigger_text: 'OK, here are the custom events that Axeptio may trigger',
  cookies_editor_step_form_message_label: 'Skip automatically after ',
  cookies_editor_vendor_form_title_label: 'Title',
  cookies_editor_vendor_form_name_label: 'Name',
  cookies_editor_vendor_form_name_text: 'This name is the text that your webpage will receive when the user completes the cookies process.',
  cookies_editor_vendor_form_short_description_label: 'Short description',
  cookies_editor_vendor_form_long_description_label: 'Long description',
  cookies_editor_vendor_form_domain_label: 'Domain name',
  cookies_editor_vendor_form_domain_text: 'The domain name is used to fetch the Favicon',
  cookies_editor_vendor_form_policy_url_label: 'Privacy Policy URL',
  cookies_editor_vendor_form_delete_button: 'Delete this vendor',
  cookies_editor_vendor_form_save_button: 'Confirm',
  cookies_editor_vendor_version_remove_button: 'Delete this version',
  domain_check_domain_label: 'Domain name',
  domain_check_error_text: 'An error happened, please check again.',
  domain_check_installation_label: 'SDK Installation checking',
  domain_check_installed_message: 'The SDK is present and the clientID is conforme',
  domain_check_not_installed_message: 'The SDK has not been found',
  domain_check_last_check_label: 'Last check',
  domain_check_checking_progress: 'Checking in progress',
  domain_check_check_now: 'Check now',
  dpo_editor_page_title: 'Details',
  dpo_editor_fieldset_infos_title: 'Additional details',
  dpo_editor_textarea_label: 'Bio',
  dpo_editor_phone_label: 'Telephone',
  dpo_editor_picture_label: 'Photo / Avatar',
  dpo_editor_fieldset_contact_title: 'Contact and claims',
  dpo_editor_claim_form_label: 'Allow claims',
  dpo_editor_contact_form_label: 'Show contact form',
  dpo_editor_fieldset_rights_title: 'Exercise of rights',
  dpo_editor_subject_rights_label: 'What procedure do you want to explain to your users',
  dpo_rights_page_title: 'Use pre-made templates',
  dpo_rights_new_right_title: 'Or start from scratch',
  dpo_rights_title_label: 'Title',
  dpo_rights_description_label: 'Description',
  embed_form_embed_list_title: 'Pages',
  embed_form_embed_list_css_target: 'Target',
  embed_form_embed_list_css_method: 'Injection method',
  embed_form_injection_method_first: 'inside the target element, at first',
  embed_form_injection_method_last: 'inside the target element, at last',
  embed_form_injection_method_eq: 'inside the target element, a a specified index',
  embed_form_injection_method_before: 'before the target element',
  embed_form_injection_method_after: 'after the target element',
  embed_form_injection_method_replace: 'replace the target element',
  embed_form_injection_method_eq_label: 'item index',
  embed_form_injection_margins_title: 'Widget container styles',
  embed_form_injection_margins_label: 'Margins',
  embed_form_injection_shadow_label: 'Box-shadow',
  embed_form_injection_minwidth_label: 'Width',
  embed_form_injection_minheight_label: 'Height',
  content_fieldset_title: 'Title',
  content_fieldset_subtitle: 'Subtitle',
  content_fieldset_message_label: 'Message',
  content_fieldset_image_chooser_label: 'Image',
  content_fieldset_detail_link_text: 'Text of the link to the consent details',
  dpo_fieldset_title_label: 'Title of job position',
  dpo_fieldset_title_placeholder: 'DPO',
  dpo_fieldset_fullname_label: 'Full name',
  dpo_fieldset_fullname_placeholder: 'Full name of your DPO',
  dpo_fieldset_organization_label: 'Org / company / entity',
  dpo_fieldset_organization_text: 'If he works for another company',
  dpo_fieldset_email_label: 'Email',
  dpo_fieldset_email_text: 'Claim address',
  dpo_fieldset_address_label: 'Address',
  embeddings_fieldset_title: 'Integrations',
  embeddings_fieldset_name_label: 'Widget identifier',
  project_infos_name_label: 'Project title',
  project_infos_name_text: 'Title of the website, name of the customer, company, ...',
  project_infos_website_url_label: 'Main domain name for the project',
  user_experience_helpers_label: 'The augmented consent: "Yes, but..."',
  user_experience_buttons_label: 'Buttons',
  user_experience_consent_label: 'Confirmation screens',
  portability_editor_name_label: 'Name of the widget',
  portability_editor_title_label: 'Title',
  portability_editor_message_label: 'Explanation',
  portability_editor_embed_list_label: 'Integrations',
  portability_card_title: 'Untitled data sharing widget',
  processor_form_identifier_label: 'Processor identifier *',
  processor_form_name_label: 'Name of the processor',
  processor_short_description_label: 'Short description',
  processor_role_label: 'Role',
  processor_role_text: 'Explain here the purpose of the personal data processing operated by this processor. If the processor wants to process data for other purposes than the initial one, it must collect a new consent from the user.',
  processor_is_global_label: 'Is it an international company',
  processor_country_label: 'Country',
  processor_country_other_label: 'What country',
  processor_protection_mechanism_label: 'Protection mechanisms',
  processor_is_privacy_shield_label: 'Is this company a privacy shiel participant',
  processor_website_label: 'Website',
  project_design_widget_title: 'Widget preview',
  project_design_widget_text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea quisquam repellat soluta! A ab aliquid, architecto autem consequuntur doloribus facere facilis hic incidunt maiores non, nostrum perferendis quas sit soluta.',
  project_design_widget_short_description: 'Software editor',
  project_design_widget_website_short_description: 'Lorem ipsum dolor sit amet',
  project_design_paint_hue_title: 'Colors',
  project_design_paint_hue_colors: 'Paint color',
  project_design_primary_colors: 'Primary colors and text',
  project_design_secondary_buttons_colors: 'Secondary buttons',
  project_design_primary_button_colors: 'Pimary button',
  project_design_consent_button_colors: 'Consent button',
  project_design_project_infos_title: 'Project details',
  project_design_dpo_title: 'The Data Processing Officer (DPO)',
  project_integration_text: '<p>To add Axeptio to your website, you just need to embed our code snippet.</p>',
  project_integration_title: 'Registration of proof of consent and identification of the user',
  project_integration_step_one: '<h4><strong>Step 1</strong> Token generation<h4>\n<p>Axeptio\'s consent proofs are attached to a unique identifier that we call "token". When no token is specified, Axeptio generates one for each page load.</p>\n<p>When one of our widget is embedded into a form, a field named axeptio_token is added, which contains the value of the token generated.</p>',
  project_integration_step_two: '<h4><strong>Étape 2</strong> Token registration</h4>\n<p>It is then necessary to register at home, together with the data of the form, the token associated with the consent, in order to demonstrate, in case of complaint or control, the validity of the consent given.\n</p>\n<p>In order to present to your user a history of his consent, you must specify in the Axeptio script the\n<code>token</code> that you have previously associated with the user in your database. This is done by passing the attribute\n <code>data-token</code> 12/5000\nto the tag.&nbsp;</p>',
  project_integration_step_button: 'Show me!',
  project_integration_step_three: '<h4><strong>Étape 3</strong> Origin of the identifier</h4>\n<p>For convenience, you have the option to change your user ID to Axeptio as a property.<code>user_id</code>,a customer code or even an email address. The bottom line is that this identifier is absolutely unique in your database.</p>\n<p>In the case of a registration form or an order form, it is very likely that an identifier will be generated immediately <b>after</b> sending the form and wanting to attach the user consent history. Axeptio provides an API specifically for this need, which allows to reassign the history associated with an existing token to a new token</p>',
  project_integration_token_recovery: '<h4>Retrieving the user token in the page</h4>\n<p>Assuming that your user\'s identifier is accessible directly in the page, for example in a menu "my account" or a variable<code>user</code> in your page</p>\n<p>If you want to use this identifier, instead of specifying it on the server side, you can use the Axeptio SDK token recovery methods..</p>',
  project_integration_recovery_content: '<ul>\n  <li>\n    <label>Retrieve the content of an element</label>\n    <p>\n      <code>\n        <b>data-token</b>\n        ="innerHTML:header.main .user span.name"\n      </code>\n    </p>\n  </li>\n  <li>\n    <label>\n      Retrieve the value of an attribute of an element\n    </label>\n    <p>\n      <code>\n        <b>data-token</b>\n        ="attr(value):input[type=\'hidden\'][name=\'username\']"\n      </code>\n    </p>\n  </li>\n  <li>\n    <label>\n      Access a variable defined in the object\n      <code>window</code>\n    </label>\n    <p>\n      <code>\n        <b>data-token</b>\n        ="getVar:myCMS.user.id"\n      </code>\n    </p>\n  </li>\n</ul>',
  project_invoices_card_title: 'You don\'t have invoices for this project',
  project_invoices_widget_button_download: 'Download',
  project_invoices_widget_button_pay: 'Pay',
  project_invoices_invoice_title: 'Invoice #',
  project_invoices_invoice_line_info: 'Period',
  project_invoices_invoice_date: 'Date',
  project_invoices_invoice_id: 'Identifier',
  project_invoices_invoice_amount_due: 'Due',
  project_invoices_invoice_amount_paid: 'Paid',
  project_invoices_invoice_amount_remaining: 'Amount remaining',
  publish_button_confirm: 'Confirm',
  publish_button_cancel: 'Cancel',
  publish_button_publish: 'Publish',
  publish_footer_explanation: 'To make your changes live, you need to publish your configuration on our CDN. Only active services will be published',
  publish_footer_publish: 'Publish',
  publish_footer_activate: 'Activate the project to publish it live',
  app_header_projects: 'My projects',
  project_users_invitation_label: 'Invite someone',
  project_users_invitation_button: 'Send',
  project_users_quit_button: 'Leave this project',
  project_users_quit_confirm_text: 'Watch out ! Leave this project will make it disappear from your project list.',
  project_users_quit_confirm_text_02: 'Please confirm your action by typing this text in the field below:',
  project_users_quit_confirm_button: 'I do want to leave this project',
  project_users_quit_cancel_button: 'Cancel',
  subscription_monthly_price_label: '€/mo\n',
  subscription_billing_services_title: 'Services',
  subscription_billing_details_title: 'Billing',
  subscription_billing_title: 'Payment',
  subscription_voucher_label: 'Coupon',
  subscription_voucher_text: 'Write your coupon here to enable the discount 🥳',
  subscription_periodicity_title: 'Periodicity',
  subscription_periodicity_yearly: 'Yearly subscription',
  subscription_periodicity_monthly: 'Monthly subscription',
  subscription_credit_card_label: 'Payment card',
  subscription_credit_card_button: 'Delete the card',
  subscription_credit_card_number: 'Card Number',
  subscription_credit_card_expiry: 'Expiration Date',
  subscription_credit_card_cvc: 'CVC',
  subscription_credit_card_postal_code: 'Postal Code',
  subscription_total_label: 'Net total',
  subscription_periodicity_month: 'month',
  subscription_periodicity_year: 'year',
  subscription_fisrt_step_button: '1/3 • Next step',
  subscription_second_step_button: '2/3 • Next step',
  subscription_billing_valid_button: 'Subscribe',
  subscription_billing_valid_button_billing_infos: 'Enter Billing Info',
  subscription_billing_valid_button_payment_method: 'Select a payment method',
  subscription_fisrt_step_alert: '1/3 • Add one service or more',
  subscription_second_step_alert: '2/3 • Please fill your billing informaiton',
  subscription_third_step_alert: '3/3 • Please add a payment method',
  subscription_unsubscribe_from_all_services: 'Unsubscribe',
  project_modal_title: 'Congratulations, your new project has been created !',
  project_modal_subtitle: 'You\'ll just have to put this snippet on your website.',
  project_modal_description: 'Don\'t forget to set it up on your developement or test websites ! To pilot your cookies, we do recommend to set up as well',
  project_modal_close_button: 'All good for me !',
  project_services_title: 'Services',
  project_configuration_title: 'Configuration',
  project_form_infos_title: 'Project details',
  project_form_dpo_title: 'Data Protection Officer (DPO)',
  project_form_confirm_button: 'Create the project',
  project_form_saving_failed: 'Could not save your project',
  sdk_integration_token_title: 'This is an example value and should be modified',
  sdk_integration_options_label: 'Load a custom cookie version',
  sdk_integration_version_placeholder: 'The oldest configuration will be loaded if this is let empty',
  sdk_integration_user_token_label: 'User token',
  sdk_integration_user_token_text: 'The user token is a generated key that we use to attach the consent proof to the user for the following services: contracts, personal data processings, data sharing',
  sdk_integration_not_a_dev: 'Coding is not your major?',
  sdk_integration_send_button: 'Send a notice to your dev',
  projects_filter: 'Filter my websites',
  auth_seperator_label: 'or',
  auth_fb_login_title: 'Facebook Login',
  auth_fb_login_description: 'Use your facebook credentials',
  auth_intro_line: 'Welcome to Axeptio',
  auth_email_label: 'Email',
  auth_password_label: 'Password',
  auth_btn_login: 'Login',
  auth_signin_link_signup: 'Create a new account',
  auth_signin_link_resetpassword: 'Forgot password ?',
  auth_btn_resetpassword: 'Reset',
  auth_resetpassword_link_signin: 'Login',
  auth_resetpassword_link_signup: 'Sign up',
  auth_resetpassword_token: 'Authorization code you received by email',
  auth_resetpassword_password: 'New password',
  auth_resetpassword_passwordrepeat: 'Repeat your new password',
  auth_resetpassword_update: 'Update',
  auth_resetpassword_impossible: 'Impossible to change your password, because you logged in with your Google or Facebook account',
  auth_displayname_label: 'Display name',
  auth_lastname_label: 'Last Name',
  auth_firstname_label: 'First Name',
  auth_passwordrepeat_label: 'Repeat your password',
  auth_btn_signup: 'Sign up',
  auth_signup_link_signin: 'Sign in',
  auth_connect_with: 'Or connect with',
  auth_password_wrong: 'Your password must contain at least 8 characters including at least one uppercase letter, one lowercase letter, a number and one of these special characters (!@#.$%^&*)',
  auth_marketing_consent_label: 'By checking this box, I agree to receive delicious, crisp information straight from the Axeptio oven.',
  auth_country_label: 'Country',
  item_users_add: 'Add collaborators',
  item_users_remove: 'Remove',
  users_title: 'Collaborators',
  project_design_text_font_title: 'Body font family',
  project_design_title_font_title: 'Title font family',
  project_design_borderRadius_title: 'Border radius of the widgets',
  project_design_padding_title: 'Padding',
  project_design_load_font: 'Load font',
  project_design_body_size_title: 'Body font size',
  project_design_title_size_title: 'Title font size',
  project_design_load_font_help: 'Click the checkbox if the font you choose is not already loaded on your website',
  consent_editor_string_widgetTitle: 'Widget Title',
  consent_details_view_title: 'Consent details',
  consent_details_card_title: 'Edit consent details',
  consent_details_card_description: 'All the GDPR details are here: processors, ',
  embeddings_customInputName_label: 'Custom "name" attribute for the input',
  embeddings_customInputName_help: 'Customize the "name" attribute value to handle the consent information on your server side',
  embed_form_boundInputSelector_label: 'Bind the consent to an existing checkbox',
  embed_form_boundInputSelector_help: 'If you already have a checkbox for consent, you might want to keep it. By specifying a selector to the checkbox, Axeptio will mirror the consent widget value to the checkbox one. ',
  embed_form_boundInputSelector_placeholder: 'input[type="checkbox"][name="contact_form_newsletter"]',
  embed_form_elementsToHide_label: 'Elements to hide',
  embed_form_elementsToHide_help: 'Enter the CSS Selectors of the elements you need to hide when the widget is added to your page.',
  embed_form_boundTokenSelector_label: 'Connect the token value',
  embed_form_boundTokenSelector_help: 'If you need to write the value of the user token to another input in your page, write down its CSS selector',
  embed_form_boundTokenSelector_placeholder: 'input[name="token"]',
  project_design_button_style: 'Button style',
  custom_button_label: 'Use a custom button',
  custom_button_image_size_label: 'Image size',
  custom_button_label_name: 'Label',
  custom_button_icon: 'Icon',
  custom_button_padding_y: 'Padding Y',
  custom_button_padding_x: 'Padding X',
  custom_button_font_size: 'Font size',
  custom_button_font_weight: 'Font weight',
  custom_button_line_height: 'Line height',
  custom_button_text_color: 'Text color',
  custom_button_bg_color: 'Background color',
  custom_button_bg_transparent: 'Use a transparent background',
  custom_button_box_shadow: 'Active box shadow',
  custom_button_border_radius: 'Border radius',
  project_design_reset_colors: 'Reset default colors preferences',
  project_design_widget_position_label: 'Widget position',
  project_design_widget_position_left: 'Left',
  project_design_widget_position_right: 'Right',
  project_design_widget_offsetY_label: 'Offset Y',
  project_design_widget_offsetX_label: 'Offset X',
  embed_pages_empty_warning: 'Warning: no pages where added. This embedding will not load!',
  embeddings_javascriptIntegration_label: 'Javascript integration',
  embeddings_javascriptIntegration_help: 'You can "mount" Axeptio widgets on your page using the imperative API. You\'ll have to use the method <code>mountWidget</code> which accept an object composed of the following properties <code>service</code>, <code>name</code> or <code>id</code> and <code>node</code>. The <code>node</code> attribute must be the DOM element in which the widget will be appended. In this sample code we used the vanilla <code>getElementById</code> method, but you could also use a jQuery selector: <code>$(\'#my-element\').get(0)</code>.',
  project_consents_pagination_preferences_title: 'User settings',
  project_consents_pagination_headers_title: 'Request headers',
  project_consents_pagination_back_button: 'Back',
  project_consents_pagination_next_button: 'Next',
  project_consents_pagination_per_page: 'Per page',
  project_consents_pagination_page: 'Page',
  project_consents_token_label: 'Token',
  project_consents_service_label: 'Service',
  project_consents_service_processings: 'Personal Data Processing Consents',
  project_consents_service_contracts: 'Contracts',
  project_consents_service_portability: 'Data Sharing',
  project_consents_widget_id: 'Widget ID',
  project_consents_filter_button: 'Filter',
  project_consents_table_th_uid: 'UID',
  project_consents_table_th_service: 'Service',
  project_consents_table_th_widget: 'Widget',
  project_consents_table_th_user_token: 'User Token',
  project_consents_table_th_date: 'Date',
  vendors_limit_description: 'You\'ll need to activate this service in order to add more vendors',
  vendors_limit_title: 'Free tier limit reached',
  change_strings_title: 'Change texts',
  service_limit_reached_title: 'Free tier service limit reached',
  service_limit_reached_description: 'To add more widget, please activate this service.',
  service_no_item_title: 'Vous n\'avez aucune configuration cookies.',
  service_no_item_paragraph: 'It means our widget can\'t be displayed on your site for the time being. ',
  service_no_item_button: 'Create a new cookies configuration',
  service_no_item_choose_a_config: 'Pick one from the existing configurations below 👇🏻',
  service_no_item_or: 'Or',
  dpo_free_limit_title: 'These features are not available in the free tier',
  dpo_free_limit_description: 'Activate the "DPO" service in your subscription to enable them.',
  change_strings_description: 'Change texts',
  project_billing_title: 'Billing',
  confirm_delete: 'Are you sure that you want to delete this item? Deletion is definitive.',
  cookies_editor_settings_form_hide_button_help: 'Warning: hiding the Axeptio button requires you to place a link in the footer of your website so that the user can reopen the widget at any time. For this, you can, for example, use the following code:',
  cookies_editor_settings_form_hide_button_a_content: 'Click here to update your cookies settings ',
  cookies_editor_settings_ask_new_consent: 'Ask for consent again if a new vendor is present in the project',
  project_color_label: 'Theme color',
  cookies_editor_entitled_label: 'Entitled',
  consent_widget_string_form_table_row_login: 'Login',
  consent_widget_string_form_table_row_value: 'Value',
  subject_right_form_card_title: 'New right',
  embed_form_new_page_label: 'New page',
  embed_list_new_item_title: 'Create a new injection',
  embed_list_new_item_description: 'Tell us where and how you want the widget to be injected in your page.',
  embed_binding_parent_title: 'Binding to the parent form',
  content_field_set_button_illustration_value_modify: 'Edit illustration',
  content_field_set_button_illustration_value_choose: 'Choose an illustration',
  content_field_set_button_illustration_title: 'Modifier l\'illustration',
  button_form_value_none: 'None',
  button_form_value_primary: 'primary',
  button_form_value_secondary: 'secondary',
  button_form_value_tertiary: 'tertiary',
  processings_time_of_day_options_value_morning: 'morning',
  processings_time_of_day_options_value_lunch: 'lunch',
  processings_time_of_day_options_value_afternoon: 'afternoon',
  processings_time_of_day_options_value_evening: 'evening',
  processings_weekdays_monday: 'M',
  processings_weekdays_tuesday: 'T',
  processings_weekdays_wednesday: 'W',
  processings_weekdays_thursday: 'T',
  processings_weekdays_friday: 'F',
  processings_weekdays_saturday: 'S',
  processings_weekdays_sunday: 'S',
  btn_save_and_close: 'Save and close',
  btn_confirm: 'Confirm',
  btn_delete: 'Delete',
  gdpr_datatypes_warning: 'Warning !',
  gdpr_datatypes_content: 'Collection of the user\'s consent must be specific. If you declare several different types of personal data for the same consent, make sure that they serve the same purpose of processing.',
  gdpr_datatypes_data_input_placeholder: 'Type of the data, eg name, first name, etc.',
  gdpr_datatypes_button_add: 'Add',
  dropzone_drop_content: 'Drop your files here or',
  dropzone_drop_content_bold: 'select a file',
  dropzone_added_label: 'Documents added',
  custom_strings_CookiesOptOutDisclaimer: 'Warning: before unchecking this box, make sure your cookies respond well',
  custom_strings_CookiesOptOutDisclaimer_after_link: 'the regulatory and legal conditions recalled by the CNIL',
  custom_strings_GenericDisclaimer_content: 'Are you sure you have all our warnings?',
  custom_strings_CookiesDurationDisclaimer: 'Please ensure that the cookies validity period complies with applicable regulations.',
  custom_strings_CookiesDurationDisclaimer_1: 'The default consent duration is 6 months but can be modified using the following instructions: ',
  custom_strings_CookiesDurationDisclaimer_faq_link: 'https://support.axeptio.eu/hc/en-gb/articles/4402466807185',
  custom_strings_CookiesDurationDisclaimer_2: 'If you modify the consent duration, please ensure that the cookie validity period complies with applicable regulations.',
  custom_strings_GdprPurposeDisclaimer_alert: '\nYou are required to specify the purposes of the processing which must be "determined, explicit and legitimate and not further processed in a manner incompatible with those purposes". Article 5.B.',
  custom_strings_GdprPurposeDisclaimer_title: 'Article 5 - Principles concerning the processing of personal data',
  custom_strings_GdprPurposeDisclaimer_personal_data: 'Personal data must be:',
  custom_strings_GdprPurposeDisclaimer_personal_data_a: '(a) treated lawfully, fairly and transparently with regard to the person concerned (lawfulness, loyalty, transparency);',
  custom_strings_GdprPurposeDisclaimer_personal_data_b: '(b) collected for specified, explicit and legitimate purposes and not further processed in a manner incompatible with those purposes; the treatment\nfor archival purposes in the public interest, for scientific or historical research purposes or for statistical purposes is not considered, in accordance with Article 89 (1), to be incompatible with the original purposes (limitation of objectives). ',
  custom_strings_GdprPurposeDisclaimer_personal_data_c: '(c) adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed (data minimization);',
  custom_strings_GdprPurposeDisclaimer_personal_data_d: 'd) exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude);',
  custom_strings_GdprPurposeDisclaimer_personal_data_e: '(e) kept in a form permitting the identification of the persons concerned for a period not exceeding that necessary for the purposes for which they are processed; personal data may be stored for longer periods of time to the extent that they are processed solely for archival purposes in the public interest, for scientific or historical research purposes or for statistical purposes in accordance with Article 89 , paragraph 1, provided that the appropriate technical and organizational measures required by this Regulation are implemented in order to guarantee the rights and freedoms of the data subject (limitation of retention);',
  custom_strings_GdprPurposeDisclaimer_personal_data_f: '(f) processed in such a way as to ensure adequate security of personal data, including protection against unauthorized or unlawful processing and against accidental loss, destruction or damage, by means of technical or technical measures; organizational structures (integrity and confidentiality);',
  custom_strings_GdprPurposeDisclaimer_controller: 'The controller is responsible for compliance with paragraph 1 and can demonstrate that it is respected (responsibility).',
  custom_strings_GdprCustomTextDisclaimer_alert: 'Be sure to use only',
  custom_strings_GdprCustomTextDisclaimer_alert_bold: '"Understandable terms that are easily accessible and formulated in clear and simple terms"',
  custom_strings_GdprCustomTextDisclaime_title: 'Article 7 - Conditions of consent',
  custom_strings_GdprCustomTextDisclaime_1: 'In cases where the processing is based on consent, the controller is able to demonstrate that the data subject has consented to the processing of his / her personal data.',
  custom_strings_GdprCustomTextDisclaime_2: 'If the consent of the data subject is given in the context of a written statement which also relates to other issues, the consent request is presented in a form that clearly distinguishes it from these other issues in an understandable and easily accessible form. , and formulated in clear and simple terms. No part of this declaration which constitutes a violation of this Regulation is binding.',
  custom_strings_GdprCustomTextDisclaime_3: 'The data subject has the right to withdraw consent at any time. Withdrawal of consent does not compromise the lawfulness of consent-based treatment prior to withdrawal. The person concerned is informed before giving his consent. It is as easy to withdraw as to give consent.',
  custom_strings_GdprCustomTextDisclaime_4: 'In determining whether consent is given freely, the question of whether, inter alia, the performance of a contract, including the supply of a service, is subordinate consent to the processing of personal data which is not necessary for the performance of that contract.',
  custom_strings_Article32Disclaimer_alert: 'Be careful to respect the requirements of article 82 of the law of January 6, 1978 by using "clear and complete" formulations.',
  custom_strings_Article32Disclaimer_legal_1: 'Any subscriber or user of an electronic communications service must be informed in a clear and complete manner, unless he or she has been previously informed by the controller or his or her representative:',
  custom_strings_Article32Disclaimer_legal_2: 'Such access or registration may only take place if the subscriber or user has expressed, after receiving such information, his or her consent, which may result from appropriate settings on his or her connection device or any other device under his or her control.',
  custom_strings_Article32Disclaimer_legal_3: 'These provisions shall not apply if access to information stored in the user\'s terminal equipment or the recording of information in the user\'s terminal equipment :',
  custom_strings_Article32Disclaimer_legal_4: 'Either has the exclusive purpose of allowing or facilitating communication by electronic means;',
  custom_strings_Article32Disclaimer_legal_5: 'Or is strictly necessary for the provision of an online communication service at the express request of the user.',
  custom_strings_Disclaimer: 'Be careful with your wording. The information delivered to the user must always be "clear and complete" to comply with legal requirements.',
  credit_card_expires_label: 'Expires',
  billing_form_label_socialReason: 'Social reason',
  billing_form_label_name: 'Contact name',
  billing_form_label_email: 'Billing email',
  billing_form_label_address: 'Address',
  billing_form_label_postalCode: 'Postal code',
  billing_form_label_city: 'City',
  billing_form_label_country: 'Country',
  consent_editor_title: 'Edit widget texts',
  cookies_step_wizard_category: 'Blank category',
  project_design_card_example_title: 'Sample card',
  project_design_card_example_description: 'Hello World',
  axeptio_consent_save: 'Consent registered',
  axeptio_consent_consentID: 'Consent ID',
  axeptio_consent_personalID: 'Personal ID',
  sdk_integration_code_generate: 'Generate a token each time',
  sdk_integration_code_server: 'Inject server side token',
  sdk_integration_code_innerHTML: 'Get the token inside an HTML tag of the page',
  sdk_integration_code_attribute: 'Retrieve the value of an attribute of an element',
  sdk_integration_code_variable: 'Access a variable defined in the page',
  project_integration_old_token: 'oldToken',
  project_integration_new_ancient: 'newToken',
  editable_list_new_item_title: 'New',
  editable_list_new_item_description: 'Add a new item',
  project_design_style_title: 'Widget style',
  project_design_typo_title: 'Typography',
  font_chooser_category_label: 'Category',
  font_chooser_family_label: 'Family',
  font_chooser_variant_label: 'Variant',
  font_chooser_custom_font_title: 'Font name (font-family)',
  paint_hue_picker_option_color_chart: 'Color chart',
  paint_hue_picker_option_sliders: 'Sliders',
  paint_hue_label_color: 'Color',
  paint_hue_label_saturation: 'Saturation',
  paint_hue_label_brightness: 'Brightness',
  widget_consent_certified: 'Consent certified by',
  processing_widget_button_modify: 'Edit my choices',
  user_experience_fieldset_card_accept: 'Acceptance',
  user_experience_fieldset_card_partial_accept: 'Partial acceptance',
  user_experience_fieldset_card_refusal: 'Refusal',
  dpo_editor_add_right: 'Add a right',
  locale_FR: 'French',
  locale_EN: 'English',
  locale_DE: 'German',
  locale_NL: 'Dutch',
  locale_ES: 'Spanish',
  locale_PT: 'Portugese',
  locale_IT: 'Italian',
  CookiesCard_noTitle: 'Untitled version',
  btn_close: 'Save and quit',
  btn_close_2: 'Close',
  project_onboarding_title: 'New project',
  project_onboarding_url_title: 'What is your website url ?',
  project_onboarding_next_button: 'Next',
  project_onboarding_button_save: 'Save changes',
  project_onboarding_button_saving: 'Saving...',
  project_onboarding_integration_title: 'Integration on your website',
  project_onboarding_integration_setup_01: 'Copy/paste this code in the',
  project_onboarding_integration_setup_02: 'tag of your website',
  project_onboarding_cookies_title: 'Cookies',
  project_onboarding_cookies_description: 'An innovative and respectful solution for your users',
  project_onboarding_forms_title: 'Forms',
  project_onboarding_forms_description: 'Granular consents that prevent the erosion of your databases.',
  project_onboarding_dpo_title: 'DPO',
  project_onboarding_dpo_description: 'Easily deploy an identity card and a contact form to isolate these specific requests.',
  project_onboarding_step_website: 'Adresse du site',
  project_onboarding_step_integration: 'Intégration',
  project_onboarding_step_config: 'Configuration',
  project_onboarding_language_title: 'Please choose the language of your project ',
  project_onboarding_language_placeholder: 'French, English...',
  project_onboarding_language_available: 'Translations available',
  project_onboarding_language_others: 'Other languages',
  embed_form_createButton_label: 'Insert a button to open the widget ',
  embed_form_createButton_help: 'If you are short on space, you can rather inject in your form a button that will open the widget in the overlay where our cookies usually reside.',
  embed_form_buttonText_label: 'Button label',
  embed_form_addPage: 'Add a page',
  embeddings_showAdvanced: 'Show advanced integration options',
  embeddings_hideAdvanced: 'Hide the advanced integration options',
  cookies_editor_entitled_placeholder: 'eg. Landing Page German, Blog EN, etc.\n',
  cookies_editor_name_placeholder: 'eg. landing_v1, blog_US',
  processings_weekdays_daysList_label: 'Day list',
  processings_weekdays_daysList_day: 'Day',
  processings_weekdays_daysList_displayed: 'Displayed',
  processings_weekdays_daysList_active: 'Active',
  button_delete_service_item: 'Delete',
  button_confirmBox_confirm: 'Confirm',
  button_confirmBox_cancel: 'Cancel',
  title_delete_service_item: 'Delete configuration :',
  title_rename_service_item: 'Rename configuration :',
  title_duplicate_service_item: 'Duplicate configuration :',
  button_rename_service_item: 'Rename',
  button_duplicate_service_item: 'Duplicate',
  file_upload_comp_choose_file: 'Choose file',
  file_upload_comp_upload_file: 'Upload file',
  file_upload_comp_upload_files: 'Upload files',
  cookies_editor_settings_pages_label: 'Load this version on the following pages:',
  strings_change_tone_apply: 'Apply',
  strings_change_tone_title: 'Use one of our presets',
  embed_form_buttonText_placeholder: 'If empty, Axeptio will use the title of your widget',
  embed_form_embed_list_displayedAs: 'Displayed as:',
  embed_form_embed_list_displayedAs_button: 'button',
  embed_form_embed_list_displayedAs_widget: 'widget',
  embed_form_embed_list_display: 'Display',
  embed_form_embed_list_injection: 'Injection',
  embed_form_tokenMethod_value: 'Get the value from an input field in the form',
  embed_form_tokenMethod_attr: 'Get the attribute content of an HTML tag',
  embed_form_tokenMethod_innerHTML: 'Get the content of an HTML tag',
  embed_form_tokenMethod_getVar: 'Read a variable from the page javascript',
  embed_form_token_label: 'User identification',
  project_consents_download_button: 'Download every proof of consent (CSV)',
  cookies_editor_settings_form_javascriptIntegration_label: 'Custom javascript integration',
  cookies_editor_settings_form_javascriptIntegration_html: 'A "cookies:complete" event is dispatched.',
  cookies_editor_settings_form_javascriptIntegration_comment: '// Choices is a JS object containing the vendors activated or not by the user.',
  home_cookies_params: 'Manage your cookies',
  image_chooser_customImageUpload_locked: 'Custom image upload is not available in the free tier',
  image_chooser_customImageUpload_unlockButton: 'Unlock now',
  cookies_editor_language_label: 'Widget language',
  auto_save_saving: 'Saving changes...',
  auto_save_saved: 'Changes saved',
  cookies_step_iab_title: 'IAB CMP Transparency Framework integration',
  cookies_step_iab_stub_title: 'CMP Stub',
  cookies_step_iab_stub_formText: 'Warning, you have to add the CMP stub in the top\nmost part of your document\'s &lt;head&gt;',
  cookies_step_iab_consentString_title: 'Get the Consent String',
  cookies_step_iab_consentString_formText: 'To get the consentString, you can use this event handler',
  cookies_step_iab_consentString_comment: 'Pass the consent string to your advertising partner',
  cookies_editor_saveConsent_label: 'Save consent proof in Axeptio\'s audit trail',
  step_disablePaint: 'Disable the paint background',
  step_image_pick: 'Pick an image',
  step_image_delete: 'Remove',
  step_image_change: 'Change image',
  step_disablePaint_upgrade: 'This feature is not available in your subscription. Click here to upgrade',
  access_upgrade_text: 'This feature is not available in your subscription.',
  access_upgrade_btn: 'Upgrade your subscription',
  cookies_editor_empty_preview: '<p>Your Axeptio widget is empty!<p>\n<p>To make our cookies easy to digest, we decided to split the acceptance workflow in steps for your users.<p>\n<p>On the right side of the app, use the big button "add step" to create your first screen.</p>',
  subscription_upgrade_main_title: 'Instantly unlock all premium features',
  subscription_upgrade_main_description: 'Do you intensify your collection and your treatments? Switch right now on our PRO version to edit everything as you want, and customize our widgets to your colors.',
  subscription_upgrade_main_button: 'Upgrade to a higher version',
  subscription_upgrade_first_mention: 'Start a 30 days free trial',
  subscription_upgrade_second_mention: 'Cancellation at any time',
  subscription_upgrade_slide_01_title: 'A complete and personalized widget for your cookies',
  subscription_upgrade_slide_01_image: 'icon-cookies',
  subscription_upgrade_list_item_01: 'Add as many cookies as you want.',
  subscription_upgrade_list_item_02: 'Store them in steps for better digestion.',
  subscription_upgrade_list_item_03: 'Customize the solution to your colors.',
  gdpr_storage_duration_unit: 'month',
  cookies_editor_settings_form_storage_duration_label: 'Consent storage duration\n',
  cookies_editor_settings_form_storage_duration_unit: 'Days',
  project_design_consentButton_borderRadius_title: 'Consent Buttons Border Radius',
  cookies_editor_step_form_waitTimeout_label: 'Go to this screen after waiting...',
  publish_error_freemium_title: 'Houston, we have a problem',
  publish_error_freemium_description: 'Unfortunately we weren\'t able to publish your config because you\'ve reached the limits of your plan. You can either subscribe to an higher plan or go back to your current plan by downgrading the folllowing settings.',
  publish_error_freemium_subtitle: 'You went a bit off the charts\n',
  project_design_reset_fonts: 'Reset to default fonts',
  account_info_title: 'Informations',
  account_identities_title: 'Identities',
  account_organizations_title: 'My organizations',
  orgs_blling_info_title: 'Billing info',
  orgs_users_title: 'User accounts',
  orgs_plan_title: 'Agency subscription',
  orgs_plan_interval: 'Interval',
  orgs_plan_product: 'Product',
  orgs_plan_payment_source: 'Payment',
  account_info_email: 'Email',
  account_info_lastName: undefined,
  account_info_firstName: 'First Name',
  account_info_displayName: 'Display Name',
  account_info_companyName: 'Company Name',
  orgs_plan_subscription_update: 'Edit subscription',
  orgs_plan_subscription_end: 'Cancel subscription',
  orgs_plan_subscription_start: 'Subscribe',
  subscription_paymentType_sepa_debit: 'SEPA Direct Debit (IBAN)',
  subscription_paymentType_card: 'Credit cards',
  billing_form_label_vat: 'VAT Number',
  publish_error_errors: 'Publish errors',
  cookies_create_vendor: 'Add a new cookie',
  subscription_payment_title: 'Payment',
  subscription_services_title: 'Active widgets',
  subscription_plan_title: 'Discover our flavours',
  currency_eur: '€',
  vendor_select_customVendors: 'Vendors you created',
  vendor_select_standardVendors: 'Standard vendors',
  project_onboarding_button_submit: 'Create',
  project_onboarding_name_title: 'How should we call this project?',
  cookies_editor_projectDesign: 'Customize the widget look',
  cookies_editor_projectIntegration: 'Integration on your website',
  step_sameWeightForAcceptAndRejectButtons: 'Use the same weight for the accept and reject buttons',
  nav_agencyButton: 'Agency',
  content_fieldset_name: 'Label',
  empty_items: 'Nothing here',
  project_dropdown_id: 'Project ID',
  project_dropdown_id_copy: 'Copy',
  project_dropdown_id_copied: 'Copied',
  project_dropdown_duplicate_project: 'Duplicate project',
  project_dropdown_add_group_project: 'Add to group',
  project_dropdown_manage_group_project: 'Manage groups',
  project_design_reset_all: 'Reset to free settings',
  cookies_editor_settings_steps_label_1: 'SDK integration',
  cookies_editor_settings_steps_label_2: 'Opening settings',
  cookies_editor_settings_steps_label_3: 'Synchronization',
  cookies_editor_settings_link_label: 'Link to the documentation',
  cookies_editor_settings_form_link_next_button: 'Next step',
  cookies_editor_settings_form_link_doc_button: 'Detailed documentation',
  cookies_editor_settings_form_sync_label: 'User\'s events synchronization',
  cookies_editor_settings_form_sync_text: '<p>Your widget is ready. But there\'s one more step to take control of your cookies. If you don\'t have a developer in your team, we recommend that you use Google Tag Manager (free tool 100% free proposed by Google : <a href={"https://tagmanager.google.com"} target="_blank" rel="noreferrer noopener" >tagmanager.google.com</a>)</p>',
  cookies_editor_settings_form_select_button_left: 'Google Tag Manager',
  cookies_editor_settings_form_select_button_right: 'Custom integration',
  vendors_select_button_custom: 'My cookies',
  vendors_select_button_chat: 'Online chat',
  vendors_select_button_testing: 'A/B testing',
  vendors_select_button_monitoring: 'Monitoring',
  vendors_select_button_marketing: 'Marketing',
  vendors_select_button_crm: 'CRM',
  vendors_select_button_email: 'Emailing',
  vendors_select_button_other: 'Other',
  vendors_link_find_cookies: 'Need help to find the cookies used on your website ?',
  select_language_placeholder: 'Select this configuration language',
  project_design_disableScrollHijack_label: 'Don\'t hijack browser scroll',
  project_design_disableScrollHijack_help: 'By default, Axeptio will hijack the native browser scroll to be able to stay in front of the user and avoid scrolling issues. If you prefer to disable this feature, check this box.',
  billing_form_label_TaxId: 'Add a tax id',
  subscription_taxIncluded: 'Tax incl.',
  subscription_taxExcluded: 'Tax excl.',
  subscription_cancel_subscription: 'Cancel subscription',
  subscription_edit_subscription: 'Edit subscription',
  subscription_existingSourceExplainer: 'You have an existing payment method associated with this subscription. It is saved by Stripe, and will be used to collect the next payments.',
  subscription_changeSource_label: 'To attach a new payment method to this subscription, simply write down the new card info in the form below',
  subscription_isProfessional_yes: 'A Pro',
  subscription_isProfessional_no: 'An Individual',
  billing_form_label_companyName: 'Company Name',
  billing_form_label_isProfessional: 'You\'re',
  subscription_edit_customer: 'Edit billing information',
  billing_form_label_individualName: 'First and Last Name',
  billing_form_label_taxRate: 'Tax Rate',
  textAlign_left: 'Left',
  textAlign_right: 'Right',
  textAlign_center: 'Center',
  cookies_editor_titleTextAlign_label: 'Title Text Align',
  cookies_editor_contentTextAlign_label: 'Content Text Align',
  project_design_boxShadow_default: 'Default',
  project_design_boxShadow_none: 'None',
  project_design_boxShadow_custom: 'Custom shadow (CSS)',
  project_design_colorPicker_submit: 'Submit',
  project_design_boxShadow_label: 'Box Shadow',
  subscription_card_period_label: 'Ongoing period',
  subscription_card_period_from: 'From',
  subscription_card_period_to: 'to',
  subscription_card_amount_label: 'Amount tax excl.',
  subscription_card_status_label: 'Status',
  subscription_taxIds_placeholder: 'You have not entered a tax ID yet. We know you probably don\'t have this info right here, but it\'s worth checking. As we\'re a company based in France, we apply a 20% EU VAT. Enter your Tax Ids to either get the tax back or get a tax excluded price.',
  subscription_changeSource_button: 'Update the credit card',
  project_onboarding_organization_title: 'Project\'s organization',
  organizationSelect_emptyOption: 'None, it\'s a standalone project',
  organizationSelect_placeHolder: 'Choose an existing organization',
  project_organization_label: 'Project\'s organization',
  subscription_congrats_title: 'Congrats!',
  subscription_congrats_subtitle: 'and welcome to the club!',
  subscription_congrats_paragraph: 'Everything is now set up and you should be good to go. If you have unpublished changes, or were brought here because of the restrictions of the free tier, you might want to \npublish your project now :',
  subscription_congrats_spread_title: '🥰 Spread the love 🥰 ',
  subscription_congrats_spread_description: 'Do you know how you can help your network with being compliant and cool at the same time?',
  subscription_congrats_spread_tweet_text: 'We selected @axeptio_eu for our website compliance',
  subscription_congrats_spread_tweet_link_text: 'Let me tweet this',
  subscription_congrats_spread_tweet_hashtags: 'gdpr,ux,cookies',
  subscription_congrats_spread_facebook_link_text: 'I want to follow and like your page',
  subscription_congrats_spread_facebook_page: 'https://facebook.com/axeptio',
  subscription_congrats_link_project: 'Back to my project',
  subscription_congrats_link_project_design: 'Change my widget style',
  subscription_congrats_link_documentation: 'Read the docs',
  subscription_congrats_link_documentation_url: 'https://developers.axeptio.eu',
  subscription_congrats_spread_tweet_url: 'https://axeptio.eu',
  'integration_docURL_all-integrations': 'https://support.axeptio.eu/hc/en-gb/sections/24131788478737-Plugins-and-integrations',
  'integration_docURL_big-commerce': 'https://support.axeptio.eu/hc/en-gb/articles/17629787618321-BigCommerce-integration',
  integration_docURL_bubble: 'https://support.axeptio.eu/hc/en-gb/articles/24851749428241-Bubble-Integration',
  integration_docURL_drupal: 'https://support.axeptio.eu/hc/en-gb/articles/17627389096209-Drupal-integration',
  'integration_docURL_google-tag-manager': 'https://support.axeptio.eu/hc/en-gb/articles/4404040466321-Tag-Manager-Interface',
  integration_docURL_hubspot: 'https://support.axeptio.eu/hc/en-gb/articles/24449020823825-Hubspot-integration',
  integration_docURL_joomla: 'https://support.axeptio.eu/hc/en-gb/articles/17629983128081-Joomla-integration',
  integration_docURL_magento: 'https://support.axeptio.eu/hc/en-gb/articles/22046907336849-Magento-integration',
  integration_docURL_prestashop: 'https://support.axeptio.eu/hc/en-gb/articles/17618369976337-Prestashop-integration',
  integration_docURL_shopify: 'https://support.axeptio.eu/hc/en-gb/articles/17628559386641-Shopify-integration',
  integration_docURL_squarespace: 'https://support.axeptio.eu/hc/en-gb/articles/17629521827345-Square-Space-integration',
  integration_docURL_typeform: 'https://developers.axeptio.eu/integration/integration-cms/typeform',
  integration_docURL_webflow: 'https://support.axeptio.eu/hc/en-gb/articles/24851994775953-Webflow-integration',
  integration_docURL_wix: 'https://support.axeptio.eu/hc/en-gb/articles/21894790192785-Wix-integration',
  integration_docURL_wordpress: 'https://support.axeptio.eu/hc/en-gb/articles/17616260428561-Wordpress-integration',
  axeptio_support_request_link: 'https://meetings.hubspot.com/thomas-angot/set-up',
  axeptio_support_docs_link: 'https://support.axeptio.eu/hc/en-gb/articles/22570846852753-How-to-setup-Google-Consent-Mode-v2',
  'integration_cms_all-integrations': 'Plugins and Integration',
  'integration_cms_big-commerce': 'Big Commerce',
  integration_cms_bubble: 'Bubble',
  integration_cms_drupal: 'Drupal',
  'integration_cms_google-tag-manager': 'Google Tag Manager',
  integration_cms_hubspot: 'HubSpot',
  integration_cms_joomla: 'Joomla',
  integration_cms_magento: 'Magento',
  integration_cms_prestashop: 'Prestashop',
  integration_cms_shopify: 'Shopify',
  integration_cms_squarespace: 'Squarespace',
  integration_cms_typeform: 'Typeform',
  integration_cms_webflow: 'Webflow',
  integration_cms_wix: 'Wix',
  integration_cms_wordpress: 'Wordpress',
  embed_form_checkboxText_label: 'Checkbox text',
  embed_form_checkboxText_placeholder: 'I want to receive information about...',
  embed_form_checkboxDetailsText_label: 'Details text',
  embed_form_checkboxDetailsText_placeholder: 'Know more',
  embed_form_displayAs_widget: 'Widget',
  embed_form_displayAs_button: 'Button',
  embed_form_displayAs_checkbox: 'Checkbox',
  embed_form_displayAs_label: 'Display mode',
  project_users_delete_modal_title: 'Are you sure you want to delete this user ?',
  project_users_delete_button: 'Delete',
  project_users_cancel_delete_button: 'Cancel',
  about_us_base: 'Base text',
  about_us_trusted3rdParty: 'Trusted 3rd Party',
  about_us_trusted3rdPartyWithoutImage: 'Trusted 3rd Party - Without image',
  about_us_label: 'About Axeptio',
  about_us_description: 'Panel that opens when a user interacts with our mention',
  step_image_dimensions: 'Dimensions',
  step_image_dimensions_width: 'Width',
  step_image_dimensions_height: 'Height',
  account_info_country: 'Country',
  account_info_company: 'Company',
  account_info_email_disclaimer: 'To modify the email your account is linked to, please contact our support team.',
  cookies_editor_specialSteps_title: 'Special screens',
  project_design_overlayBackdrop_opacity_label: 'Overlay opacity',
  project_design_overlayBackdrop_style_label: 'Overlay fill style',
  project_design_overlayBackdrop_style_radial: 'Radial',
  project_design_overlayBackdrop_style_solid: 'Solid',
  step_special_apparition_title: 'Apparition',
  step_special_showWhenUserClicksOnALink: 'Show when the user clicks on a link',
  step_special_showAfterScroll: 'Show after user scrolls',
  step_special_showAfterDelay: 'Show after delay',
  unit_seconds: 'secondes',
  step_apparition_title: 'Apparition',
  button_togglePublish_service_item: 'Publish / Unpublish',
  step_publishStep: 'Publish this screen',
  project_form_featureFlags_title: 'Features preview',
  project_form_featureFlags_description: 'Beware! Those features are not for the faint hearted... And that\'s because they\'re not completely polished yet. You can switch on preview versions of our upcoming features but be advised that they might change in function or pricing. ',
  step_special_showAfterPopState: 'Show after a history change (window.onpopstate)',
  project_onboarding_duplicate_title: 'Duplicate',
  project_onboarding_duplicate_warning: 'This project will be created from',
  subscription_per_domain: 'per domain',
  subscription_choose_plan: 'Choose plan',
  step_welcome_showContinueWithoutConsent: 'Show a "continue without consent" button on top of the widget',
  step_acceptAndRejectButtons: 'Accept, reject and close actions',
  subscription_choosen_plan: 'Current plan',
  subscription_summary: 'Subscription summary',
  project_design_widget_section_vendors: 'Vendors list (cookies or third parties)',
  project_design_widget_consentButton: 'Consent button',
  project_design_widget_cards: 'Cards',
  project_design_widget_discardButton: 'Close the widget',
  project_design_discardButton_borderRadius_title: 'Discard button\'s border radius',
  button_togglePublish_service_item_published: 'Unpublish',
  button_togglePublish_service_item_unpublished: 'Publish',
  subscription_cancel_subscription_title: 'Are you sure you want to cancel this subscription ?',
  project_invoices_invoice_vat: 'VAT',
  project_invoices_invoice_excvat: 'excl. tax',
  project_invoices_invoice_incvat: 'incl. tax',
  project_invoices_invoice_payment: 'Payment',
  project_invoices_invoice_payment_allpaid: 'Paid',
  project_invoices_invoice_payment_unpaid: 'Unpaid',
  subscription_billing_invoices_list: 'My invoices',
  project_consents_download_daterange: 'Download proofs of consent',
  project_consents_download_selected_daterange: 'Download for this daterange',
  step_welcome_continueWithoutConsentShouldRejectCookies: '"Continue without consent" button rejects all cookies',
  project_consents_daterange: 'Date range',
  support_btn: 'Support',
  subscription_no_item: 'No plan subscribed',
  subscription_no_item_description: 'All plans are available at the top of this page.',
  gdpr_consent_withdraw_help: 'Indicate here the process for withdrawing the consent. Warning: Consent must be withdrawable as easily as it can be given.',
  subscription_cancel_subscription_cancel: 'Cancel',
  processor_country_gdpr: 'This country is subject to GDPR.',
  processor_country_adequacy_decision: 'This country received an adequacy decision from European Commission (equivalent guarantee to GDPR).',
  subscription_periodicity_yearly_mobile: 'Yearly',
  subscription_periodicity_monthly_mobile: 'Monthly',
  subscription_sidebar_missing_infos: 'We are still missing some info:',
  subscription_form_errors_companyName: 'Company name',
  subscription_form_errors_email: 'Email',
  subscription_form_errors_address: 'Address',
  subscription_form_errors_postalCode: 'Postal code',
  subscription_form_errors_paymentCard: 'Payment card',
  subscription_active: 'Active',
  subscription_canceled: 'Canceled',
  subscription_legacy_plan: 'Old plan',
  subscription_legacy_plan_description: 'Newer plans are available.<br/>\nThey <strong><em>may</em></strong> include new features.<br/>\nFind and compare our current offers below.',
  edit: 'Edit',
  select_all: 'All',
  vendors_table_editor: 'Editor',
  vendors_table_icon: 'Icon',
  vendors_table_category: 'Category',
  vendors_table_created_by: 'Created by',
  vendors_table_language: 'Language',
  vendors_table_go_to_page: 'Go to page',
  vendors_table_show: 'Show',
  vendors_table_page_of: 'Of',
  project_main_title: 'Main',
  project_stats_modal_title: 'Analytics are there',
  project_stats_modal_text: '\n    \n<p><em>\nWe\'ve been working on this for a long time, and for just as long\nas you\'ve been asking us for it! It\'s official, it\'s in production,\nand now you\'ll find in your admin console the\nconsent and visit statistics for the sites where you\nhave installed our little cookies.</em>\n</p>\n<h2>How does it work?</h2>\n<p>\nRegarding the consent metrics, we analyze the data\nthat we store for you in our consent registry.\nThis analysis involves cross-referencing, hour by hour, and\ndimension by dimension, the acceptance, refusal, and\nconfiguration information. This also applies to the collection of\nmarketing consents.\n</p>\n<p>\nFor your visit statistics, we aggregate the anonymized logs\nfrom our CDN and count unique and\nrecurring visitors based on the HTTP statuses of the requests.\nAdditionally, the Axeptio script that you load on your site will append\na parameter r=0 or r=1 to the request loading your configuration\ndepending on the presence of an existing consent on the\nvisitor\'s browser.\n</p>\n<h2>\nIs this a tracker, and should I add Axeptio to my cookie list?\n</h2>\n<p>\nNo. We do not track individual requests and do not use any\ntracking technology. We are not able to trace a\nuser\'s journey, only to identify which visits are first visits and\nwhich visits are subsequent visits.\n</p>\n',
  project_stats_modal_showButton: 'About statistics',
  project_stats_modal_title_v2: 'Analytics are there',
  project_stats_modal_text_v2: '\n        <p><em>We\'ve been working on this for a long time, and you\'ve been asking for it for just as long! It\'s official, it\'s in production, and now you\'ll find consent and site visit statistics in your admin console for the sites where you\'ve installed our little cookies.</em>\n        </p>\n        <h2>How does it work?</h2>\n        <p>For consent metrics, we analyze the proofs that we store for you in our consent proof register. This analysis mainly involves collecting acceptance, refusal, and partial consent information. This also applies to the collection of marketing consents.\n        </p>\n        <p>\n          For your visit statistics, we do not individualize requests and do not use any tracking technology. Therefore, you do not need to list Axeptio among your list of cookies.\n        </p>',
  project_stats_kpi_newVisitor: 'First visists',
  project_stats_kpi_returningVisitor: 'Next visits',
  project_stats_kpi_visitor: 'Page views',
  project_stats_kpi_optInRate: 'Acceptance rate',
  project_stats_kpi_interactionRate: 'Interaction rate',
  project_stats_dailyOverview: 'Daily overview',
  project_stats_consentDistribution: 'Consents distribution',
  project_stats_consentDistributionByDevice: 'Consents distribution by device',
  project_stats_visitsByHosts: 'Page views by host',
  project_stats_devicesDistribution: 'Devices distribution',
  project_stats_optInRateByDevice: 'Acceptance rate by device',
  project_stats_optInRateByConfig: 'Acceptance rate by config',
  project_stats_metrics_newVisitor: 'First visit',
  project_stats_metrics_returningVisitor: 'Next visit',
  project_stats_metrics_nonInteractiveReturningVisitor: 'Next visit without consent',
  project_stats_metrics_accept: 'Acceptance',
  project_stats_metrics_reject: 'Rejection',
  project_stats_metrics_partial: 'Partial acceptance',
  project_stats_metrics_bot: 'Bot',
  project_stats_dimension_device: 'Device',
  project_stats_dimension_host: 'Host',
  project_stats_dimension_config: 'Configuration',
  project_stats_dimension_browser: 'Browser',
  project_stats_dimension_collection: 'Consent type',
  project_stats_dimension_os: 'Operating system',
  project_stats_filter_from: 'From',
  project_stats_filter_to: 'To',
  project_stats_filter_period: 'Period',
  project_stats_filter_dimension: 'Filters',
  unit_days: 'Days',
  project_stats_interactionRateByHosts: 'Interaction rate by host',
  project_stats_interactionRateByDevice: 'Interaction rate by device',
  project_stats_dailyRates: 'Evolution of the consent rate ',
  step_welcome_discardConsentExpiration: 'Discard consent cookie expiration',
  step_welcome_discardConsentExpiration_page: 'Current page',
  step_welcome_discardConsentExpiration_session: 'Current session',
  step_welcome_discardConsentExpiration_days: 'For a given number of days',
  step_welcome_discardConsentExpiration_sameAsAccept: 'Same as consent',
  step_welcome_discardConsentExpirationDays_label: 'Days',
  project_stats_dailyVisitsOverview: 'Daily visits overview',
  step_welcome_showDiscardButtonOnDesktop: 'Show "contine without consent" button on desktop too',
  user_invitation_advices: 'You can invite multiple mails using a ; to separate them.<br>\nYou can also include a name by enclosing email in <>.<br>\nex: <code>John Doe &lt;john@example.com&gt;; hddp@example.net; Pete &lt;Pete@example.org&gt;</code><br>\nYou can resend an invite by submitting an already invited email address.',
  wrong_email_address_format: 'Wrong email address format',
  invitation_sent_projects: 'User(s) will receive an email to asking them to create an account to join the project.',
  invitation_sent_organizations: 'User(s) will receive an email to asking them to create an account to join your organization.',
  organization_users_quit_button: 'Leave this organization',
  organization_users_quit_confirm_text: 'Warning ! After leaving this organization, you won\'t be able to access it anymore.',
  organization_users_quit_confirm_button: 'I do want to leave this orgzanization',
  stripe_card_holder: 'Card holder',
  stripe_card_holder_placeholder: 'John Wayne',
  subscription_incomplete: 'Incomplete',
  subscription_subtotal: 'Subtotal',
  subscription_form_errors_cardHolder: 'Card holder',
  invitation_sent_title: 'Your invite has been sent !',
  errors_auth_mail_already_exists: 'A registered user with this email already exists',
  selected_cookies_config_name: 'Selected cookies config id version',
  errors_version_id_already_exists: 'This version id already exists, please choose another one',
  user_menu_administration: 'Administration',
  project_delete_button: 'Delete this project',
  project_delete_confirm_text: 'Watch out! You\'re about to irreversibly delete this project. We won\'t be able to restore it afterwards.',
  project_delete_cancel_subscription_confirm_text: 'Your currect active subscription will be canceled accordingly.',
  project_delete_confirm_text_02: 'Please confirm your action by typing this text in the field below:',
  project_delete_confirm_button: 'I do want to delete this project',
  project_delete_confirm_cancel_subscription: ' and cancel my currently active subscription',
  subscription_voucher_button: 'Apply',
  modal_leave_confirm_title: 'Are you really sure ?',
  modal_leave_project_confirm_value: 'leave-this-project',
  modal_delete_project_confirm_value: 'delete-this-project',
  modal_leave_organization_confirm_value: 'leave-this-organization',
  consent_wall_policy_url_label: 'Read confidentiality policy',
  project_stats_upgrade_modal_title: 'Discover our flavours',
  project_stats_upgrade_modal_subtitle: 'Start for free and switch to the pro formula when you feel the need !',
  paid_feature_only_text: 'This feature is available with paid subscriptions only',
  project_stats_go_premium_title: 'Premium feature',
  project_stats_reset_filters_button: 'Reset filters',
  contact_sales_modal_title: 'Please contact your sales representatives',
  contact_sales_modal_description: 'Sorry, this feature is unavailable with your plan',
  contact_sales_modal_button_label: 'Contact us',
  project_stats_statsAllowedAggregateBy: 'Statistics are aggregated by',
  hour: 'hour',
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
  subscription_taxId_not_valid: 'Whoops ! Sounds like your VAT number is invalid !',
  billing_form_button_addTaxId: 'Add',
  billing_form_button_removeTaxId: 'Remove',
  subscription_active_description: 'The subscription is in good standing and the most recent payment was successful.',
  subscription_trialing_description: 'The subscription is currently in a trial period and it’s safe to provision your product for your customer. The subscription transitions automatically to active when the first payment is made.',
  subscription_incomplete_description: 'Payment failed when you created the subscription. A successful payment needs to be made within 23 hours to activate the subscription.',
  subscription_incomplete_expired_description: 'The initial payment on the subscription failed and no successful payment was made within 23 hours of creating the subscription.',
  subscription_past_due_description: 'Payment on the latest invoice either failed or wasn’t attempted.',
  subscription_canceled_description: 'The subscription has been canceled. During cancellation, automatic collection for all unpaid invoices is disabled.',
  subscription_unpaid_description: 'The latest invoice hasn’t been paid but the subscription remains in place. The latest invoice remains open and invoices continue to be generated but payments aren’t attempted.',
  subscription_contact_support: 'If the problem persists, please <a href="https://support.axeptio.eu/" target="_blank">contact technical support</a>.',
  vendors_search_bar_placeholder: 'available cookies',
  vendors_search_bar_placeholder_text: 'Search for a cookie',
  project_stats_statsPeriod: 'Filterable period up to',
  project_stats_statsPeriod_lastWeek: 'Last week',
  project_stats_statsPeriod_lastMonth: 'Last month',
  project_stats_statsPeriod_lastYear: 'Last year',
  project_stats_statsPeriod_allTime: 'No limit',
  vendor_delete_confirmation_text: 'Do you really want to delete this vendor ?',
  subscription_custom_project_product: 'Custom-made plan',
  subscription_custom_project_product_description: 'This plan was custom-made for you, and is different from our standard plans below',
  subscription_voucher_invalid: 'Coupon is invalid',
  billing_form_label_required: 'required',
  dropdown_account: 'My account',
  dropdown_menu_title: 'Menu',
  subscription_custom_plan_label: 'Custom plan',
  project_name_missing: 'Please fill a name for this project',
  project_language_missing: 'Please choose at least one language for your project',
  stats_limit_on_config_filter: 'Filtering the cookies configurations is limiting the data we’re able to display (such as visits-related stats)',
  project_stats_optInRate: 'Acceptance rate',
  subscription_stripe_error_label: 'Error due to Stripe',
  subscription_stripe_error_code: 'Error code',
  subscription_plan_chooser_disabled_contact_sales: 'Changing plan is disabled because you have a custom subscription. If you need to change it, please contact our <a href="https://www.axeptio.eu/en/contact" target="_blank" rel="noopener nofollow noreferrer">sales department</a>.',
  project_design_enableScrollHijack_label: 'Hijack browser scroll',
  project_design_enableScrollHijack_help: 'By default, Axeptio will not hijack the native browser scroll to allow scroll on your website. Checking this checkbox, you hijack scroll on your website.',
  payment_sepa_mandate: 'Mandate',
  subscription_invalid_email: 'Invalid e-mail',
  subscription_form_errors_IBAN: 'IBAN',
  add_organization: 'Add organization',
  stripe_add_credit_card: 'Add credit card',
  step_welcome_discardConsentPosition: 'Button position',
  step_welcome_discardConsentPosition_top: 'Above',
  step_welcome_discardConsentPosition_inner: 'Inside',
  home_search_no_result: 'Sorry, we didn\'t find anything!',
  unknown_card_language: 'Unknown language',
  btn_saving: 'Saving...',
  subscription_billing_credit_notes_list: 'My credit notes',
  project_credit_notes_credit_note_title: 'Credit Note N°',
  project_consents_service_cookies: 'Cookies consent management',
  billingform_stripecustomerfound_title: 'Have we met already ?',
  billingform_stripecustomerfound_subtitle: 'With your email, we found these billing informations in your other projects/organizations. Would you like to reuse them now, along with your payment methods?',
  billingform_stripecustomerfound_button_approve: 'Use these existing values',
  billingform_stripecustomerfound_button_cancel: 'Discard',
  subscription_unlimited_projects: 'Unlimited projects',
  subscription_max_projects: 'Max projects',
  contractsEditor_confSettings: 'Configurations settings',
  contractsEditor_confSettings_language: 'Language',
  contractsEditor_createVersion_btn: 'Save as a new version',
  contractsEditor_confSettings_title: 'Title',
  contractsEditor_confSettings_description: 'Description',
  contractsEditor_confSettings_rename: 'Rename the configuration',
  contractsEditor_confSettings_delete: 'Delete this configuration',
  contractsEditor_confSettings_delete_btn: 'Delete this configuration',
  contractsEditor_sectionSettings: 'Current section settings',
  contractsEditor_sectionSettings_title: 'Section title',
  contractsEditor_sectionSettings_title_help: 'Will be displayed in the Table of Contents and links.',
  contractsEditor_sectionSettings_name: 'Section name',
  contractsEditor_sectionSettings_name_help: 'Used for navigating from section to section',
  contractsEditor_sectionSettings_displayInToC: 'Display in ToC',
  contractsEditor_sectionSettings_delete: 'Delete current section',
  contractsEditor_sectionSettings_delete_btn: 'Delete current section',
  contractsEditor_blockSettings: 'Selected block settings',
  contractsEditor_blockSettings_delete: 'Delete',
  contractsEditor_sections: 'Sections',
  contractsEditor_sections_reorder_help: 'To reorder sections, simply click and drag inside the list',
  contractsEditor_sections_addNew: 'Add a new section',
  contractsEditor_strings: 'Strings',
  contractsEditor_strings_availableVariables: 'Available variables',
  contractsEditor_users: 'Users',
  versioningModal_levels_major_title: 'Breaking changes',
  versioningModal_levels_major_text: 'Choose this option if you made important changes that requires collecting the consent of all of your users.',
  versioningModal_levels_minor_title: 'Minor changes',
  versioningModal_levels_minor_text: 'You altered the content of the contract, but do not want to subject your users to this new version. Nota: users having accepted the an even older version of this contract will get asked again.',
  versioningModal_levels_patch_title: 'Cosmetic changes',
  versioningModal_levels_patch_text: 'If you only changed text formatting or updated an image, choose this release type. It won\'t affect your existing user base.',
  versioningModal_title: 'Deploy a new version',
  versioningModal_versionName: 'Name of the new version',
  versioningModal_versionName_help: 'You can name this version to help you or your users identify the different releases. Use this field as well if you want to implement your own versioning sequence.',
  versioningModal_level: 'Version level',
  versioningModal_requiresConsent: 'Requires consent',
  versioningModal_createVersion_btn: 'Create version VERSION_NUMBER',
  contractsEditor_export_sections_json: 'Export sections to JSON',
  contractsEditor_import_sections_json: 'Import sections from JSON',
  contractsEditor_import_sections_json_wrong_type: 'You must provide a JSON file',
  contractsEditor_import_sections_validation_error: 'JSON structure validation error',
  contractsEditor_import_sections_reading_error: 'File reading error',
  contractsEditor_import_sections_syntax_error: 'JSON Syntax error',
  contractsEditor_import_docx: 'Import Word document (.docx)',
  contractsEditor_import_docx_wrong_type: 'You must provide a .docx file',
  contractsEditor_import_sections_reading_error_docx: 'Your docx should be structured with headers to properly parse sections',
  contractsEditor_doc_link: 'Access the documentation',
  contractsEditor_docx_template: 'Word document template (.docx)',
  subscription_create_customer: 'Save billing information',
  subscription_create_customer_cancel_button: 'Cancel',
  stripe_sepa_debit_holder: 'Account holder',
  subscription_createSource_button: 'Create credit card',
  subscription_createSource_button_card: 'Create credit card',
  subscription_createSource_button_sepa: 'Add a new IBAN',
  subscription_createCard_label: 'Add a new credit card',
  subscription_deleteSource_tooltip: 'Delete this payment method',
  subscription_deleteSource_confirmation: 'Do you really want to delete this payment method ?',
  subscription_SetSourceAsSubscriptionDefault_tooltip: 'Set as default payment method for this subscription',
  subscription_createSepaDebit_label: 'Add a new IBAN',
  subscription_SetSourceAsCustomerDefault_tooltip: 'Set as default payment method for these billing infos',
  subscription_form_errors_paymentSource: 'Payment method',
  contractsEditor_nextSection_button: 'Next section',
  contractsEditor_prevSection_button: 'Previous section',
  contractsEditor_EditableBlocks_title: 'Title',
  contractsEditor_EditableBlocks_spacer: 'Spacer',
  contractsEditor_EditableBlocks_linkToSection: 'Link to section',
  contractsEditor_EditableBlocks_image: 'Image',
  contractsEditor_EditableBlocks_richText: 'Rich text',
  contractsEditor_blockSettings_hasBackground: 'Has Background',
  contractsEditor_importSections_title: 'This import will overwrite existing sections. Do you want to continue ?',
  contractsEditor_importSections_cancel: 'Cancel',
  contractsEditor_importSections_confirm: 'Confirm import',
  publishModal_title: 'Publish options',
  publishModal_warning_vendors: 'Warning: you are about to publish a banner with no cookies set',
  publishModal_warning_not_published: 'Warning: you are about to publish a banner that is not marked as published',
  publishModal_no_cookies: 'No cookies set',
  publishModal_explanations: 'Before publishing a new version of your Axeptio configuration, please review the following settings and make sure to select the intended versions. Check the documents you want to see go live and click publish whenever you\'re ready.',
  publishModal_projectSettings_title: 'Project settings',
  publishModal_publishDesign_title: 'Publish design changes',
  publishModal_publishDesign_text: 'If you have made changes to your project design, colors or fonts, check this box to make sure the next published version reflects them',
  publishModal_publishDpo_title: 'Publish DPO changes',
  publishModal_publishDpo_text: 'Have you modified any information about your DPO and would like to have its card updated? Tick this box and the changes will be published in this version.',
  custom_strings_Article32Disclaimer_legal_1_1: 'The purpose of any action to access, by electronic transmission, information already stored in his electronic communications terminal equipment, or to write information into such equipment;',
  custom_strings_Article32Disclaimer_legal_1_2: 'The means he has to oppose it.',
  project_invoices_invoice_payment_uncollectible: 'Not due',
  contractsEditor_keywords_label: 'Keywords',
  contractsEditor_keywords_help: 'You can add keywords (3 characters min.) to this section. They will used in search with the section text.',
  integration_copiedCode: 'Copied',
  integration_copyCode: 'Copy',
  integration_code_title: 'Our "code snippet" to integrate into your web site',
  step_showPurposes: 'Show purposes',
  step_purpose: 'Data Processing Purpose',
  subscription_meteredBilling_minUsage: 'for {criteria} for this period',
  subscription_meteredBilling_graduatedPricing: 'graduated pricing beyond',
  subscription_meteredBilling_from: 'from',
  subscription_meteredBilling_to: 'to',
  subscription_meteredBilling_publishedProjects: 'Published projects',
  subscription_meteredBilling_price: 'Price',
  subscription_meteredBilling_nextInvoiceAmount: 'Next Invoice',
  payment_failed_message: 'Warning! You have projects in default of payment. Please update your project(s) concerned:',
  payment_failed_short_message: 'Payment failed !',
  subscription_meteredBilling_pageViews: 'Page views',
  subscription_meteredBilling_seeFeatures: 'See features and services',
  subscription_disable_agilitation_iban: 'You can\'t use our own IBAN ! 🕵️',
  subscription_agencyMetered_publishedProjectsCount: 'Currently published projects',
  subscription_meteredBilling_cancelation_nextInvoice: 'You\'ll have to pay this next invoice amount',
  publishModal_freeTrialWithoutSubscription: 'Before going any further, you need to subscribe to a plan (even for free).',
  publishModal_freeTrialWithoutSubscription_integration: 'By the way, have you integrated our service on your website? If you have not, feel free to watch how to do it and then publish!',
  button_confirmBox_okLetsGo: 'Choose a plan',
  subscription_agencyMetered_autoUpscaleNotification: 'Depending on the number of published projects, your plan will be automatically upscaled to the higher plan',
  subscription_meteredBilling_minUsage_freeTrial: 'for {count} new unique visitors for this period',
  subscription_meteredBilling_newVisitors: 'New unique visitors',
  publish_error_technicalError_label: 'Technical error',
  publish_error_technicalError_message: 'A technical error has been encountered. Please try again in a few minutes. If it still doesn\'t work, please contact our support with the following error message',
  home_cgu_params: 'Terms Of Use',
  home_data_privacy_params: 'Privacy policy',
  home_legal_notice_params: 'Legal notice',
  home_dpa_params: 'Data Processing Agreement',
  user_account_preferred_language: 'Language',
  user_account_select_preferred_language_placeholder: 'Select your language',
  network_error: 'Network error: it seems that an ad blocker (ex: AdBlock Plus, AdBlock, uBlock ...) is blocking the connection to our servers. Please disable it (or add axept.io to whitelist) and refresh the page before trying a new connection.',
  contractsEditor_sectionSettings_excludeFromPdf: 'Exclude from contract PDF',
  header_warning_freeTrialLimitBrokenAt: 'You\'ve broken the 200 unique visitors limit',
  header_warning_freeTrialLimitBrokenAt_upgradeLink: 'Upgrade',
  project_consents_table_no_data: 'No proof of consent',
  contractsEditor_template_new_section_title: 'Untitled section',
  contractsEditor_template_new_sections_html: '<p>New section\'s content</p>',
  contractsEditor_template_cannot_download_pdf: 'You cannot download the PDF in edit mode',
  contractsEditor_template_untitled_contract_title: 'Untitled Contract',
  contractsEditor_template_untitled_contract_edit_me: 'Edit me',
  contractsEditor_template_untitled_contract_double_click_edit: 'Double click to edit',
  contractsEditor_versions: 'Versions',
  contractsEditor_versions_latest_published: 'Latest version published in project config',
  notification_breadcrumb_title: 'Notifications',
  notification_btn_title_singular: 'notification',
  notification_btn_title_plural: 'notifications',
  notifications_main_title: 'Notifications',
  notifications_you_ve_got: 'You\'ve got',
  notifications_to_read_singular: 'notification to read',
  notifications_to_read_plural: 'notifications to read',
  notifications_mark_as_read: 'Mark as read',
  notifications_no_notification_yet: 'No notification yet',
  subscription_scheduledUpdate_to: 'Subscription update to',
  subscription_scheduledUpdate_for: 'scheduled for',
  subscription_scheduledUpdate_toastMessage: 'Your subscription update has been scheduled at the end of its current period',
  publishModal_toggleAll: 'Toggle all',
  scanner_domain: 'Hostname to scan',
  scanner_follow_subdomain: 'Scan subdomains',
  scanner_update_configuration: 'Update cookies configuration',
  scanner_now_schedule: 'Now',
  scanner_day_schedule: 'Every day',
  scanner_week_schedule: 'Every week',
  scanner_month_schedule: 'Every month',
  scanner_schedule: 'Scan frequency:',
  scanner_sends_email_result: 'Send results to emails: (optional)',
  scanner_stop_schedule: 'Stop scheduled scan',
  scanner_start_schedule: 'Restart scheduled scan',
  scanner_stop_schedule_button: 'Stop',
  scanner_start_schedule_button: 'Launch',
  scanner_identifier: 'Identifier',
  scanner_job_details: 'Scan result',
  scanner_result_domain: 'Domain',
  scanner_result_identifier: 'Scan id',
  scanner_result_started_at: 'Launched on',
  scanner_created_by: 'Created by',
  scanner_pages_scanned: 'Number of scanned pages',
  scanner_finished_at: 'Finished at',
  scanner_nb_vendors_found: 'Number of vendors found',
  scanner_vendors_identified: 'Identified vendor',
  scanner_result_name: 'Name',
  scanner_result_category: 'Category',
  scanner_result_company: 'Company',
  scanner_result_pages: 'Pages',
  scanner_result_triggered: 'Compliance',
  scanner_result_details: 'Details',
  scanner_result_description: 'Description',
  scanner_vendors_unidentified: 'Unidentified vendor',
  scanner_create_new_report: 'Schedule a new scanner',
  scanner_create_new_report_button: 'Schedule the scanner',
  scanner_modify_report: 'Edit the scanner',
  scanner_modify_report_button: 'Edit the scanner',
  scanner_last_scan: 'Lastest scan',
  scanner_KPI_date: 'Date',
  scanner_KPI_vendor: 'Vendors found',
  scanner_KPI_errors: 'Uncompliant vendor',
  scanner_KPI_categories: 'Categories',
  scanner_KPI_unknown: 'Unknown vendor',
  scanner_schedule_title: 'Scheduled scans',
  scanner_new_schedule_job: 'Schedule a new scanner',
  scanner_result_history: 'Scan history',
  scanner_result_status: 'Status',
  scanner_result_status_done: 'Done',
  scanner_result_status_active: 'Active',
  scanner_result_status_error: 'Error',
  scanner_result_status_error_display_action: 'Display error',
  scanner_result_status_pending: 'Pending',
  scanner_result_actions: 'Actions',
  scanner_result_download_pdf: 'Download PDF result',
  scanner_result_download_xls: 'Download XLSX result',
  scanner_result_compliant: 'Compliant',
  scanner_result_uncompliant: 'Not compliant',
  scanner_copy: 'Copy',
  scanner_copied: 'Copied',
  scanner_delete_history: 'Delete this scan history',
  scanner_create_new_email: 'Add',
  scanner_email_invalid: 'is invalid',
  scanner_no_email_options_message: 'Enter your emails',
  project_stats_kpi_pageviews_v2: 'Page views',
  project_stats_kpi_visitor_v2: 'Users',
  project_stats_kpi_newVisitor_v2: 'New users',
  project_stats_kpi_consentRate_v2: 'Consent rate',
  project_stats_kpi_interactionRate_v2: 'Interaction rate',
  project_stats_kpi_bounceRate_v2: 'Bounce rate',
  project_stats_chart_consents_v2: 'Consents',
  project_stats_consentDistribution_v2: 'Consents distribution',
  project_stats_consentDistributionByDevice_v2: 'Consents distribution by device',
  project_stats_visitsByHosts_v2: 'Page views by host',
  project_stats_devicesDistribution_v2: 'Devices distribution',
  project_stats_consentRateByDevice_v2: 'Consent rate by device',
  project_stats_consentRateByConfig_v2: 'Consent rate by config',
  project_stats_metrics_visitor_v2: 'Users',
  project_stats_metrics_newVisitor_v2: 'New users',
  project_stats_metrics_accept_v2: 'Acceptance',
  project_stats_metrics_reject_v2: 'Rejection',
  project_stats_metrics_partial_v2: 'Partial acceptance',
  project_stats_metrics_bot_v2: 'Bot',
  project_stats_metrics_pageview_v2: 'Page views',
  project_stats_metrics_consentRate_v2: 'Consent rate',
  project_stats_metrics_interactionRate_v2: 'Interaction rate',
  project_stats_metrics_bounceRate_v2: 'Bounce rate',
  project_stats_chart_visitors_v2: 'Users',
  project_stats_dimension_device_v2: 'Device',
  project_stats_dimension_host_v2: 'Host',
  project_stats_dimension_config_v2: 'Configuration',
  project_stats_dimension_browser_v2: 'Browser',
  project_stats_dimension_collection_v2: 'Consent type',
  project_stats_dimension_os_v2: 'Operating system',
  project_stats_filter_from_v2: 'From',
  project_stats_filter_to_v2: 'To',
  project_stats_filter_period_v2: 'Period',
  project_stats_filter_dimension_v2: 'Filters',
  unit_days_v2: 'Days',
  project_stats_interactionRateByHosts_v2: 'Interaction rate by host',
  project_stats_interactionRateByDevice_v2: 'Interaction rate by device',
  project_stats_dailyRates_v2: 'Evolution of the consent rate',
  project_stats_bounceRateByDuration_all_visitors_legend_v2: 'Percentage of all visitors who left before x seconds, regardless of the number of pages they viewed.',
  project_stats_bounceRateByDuration_visitors_that_bounced_legend_v2: 'Percentage of visitors who saw a single page and left before x seconds.',
  project_stats_bounceRateByDuration_opening_timeout_legend_v2: 'Opening timeout',
  project_stats_bounceRateByDuration_opening_timeout_legend_description_v2: 'Configured delay before widget appears, by configuration.',
  project_vendor_axeptio_cookies: 'Cookies Axeptio',
  project_vendor_my_cookies: 'My personal cookies',
  project_unpublished_warning_toast_title: 'One last step',
  project_unpublished_warning_toast_text: 'Once your widget is online, you must remember to condition\nthe deposit of your cookies according to the consent of your\nvisitors. Otherwise, they will continue to be dropped,\nregardless of their opinion! ',
  project_unpublished_warning_toast_text_ok: 'Is that OK with you ?',
  project_unpublished_warning_toast_text_ok_for_me: 'OK for me!',
  project_unpublished_warning_toast_link: 'https://support.axeptio.eu/hc/en-gb/articles/4404040466321-Tag-Manager-Interface',
  project_unpublished_warning_toast_button_title: 'Help center',
  scanner_form_create_conf: 'Create new configuration',
  vendor_duplicated_view: 'Show me !',
  vendor_duplicated: 'Duplicate done!',
  vendor_duplicated_info: 'The cookie {} has just been added to your personal cookies',
  scanner_schedule_card_next_scan: 'Next scan on',
  subscription_review_payment_method: 'Review payment method',
  scanner_created_cookies: 'Create new configuration in',
  scanner_coming_soon: 'Coming soon...',
  scanner_identifier_helper: 'Defines the name of the scan that you are going to set up, but also those of the configurations that it will create',
  scanner_options: 'Options',
  scanner_sent_to: 'Send to',
  scanner_helpertip_creation: 'This option is not available for daily and weekly scanners',
  scanner_result_creation: 'Language of created projects',
  dpo_editor_illustration_label: 'Top right corner image',
  dpo_editor_illustration_helper: 'For an optimal display, Axeptio recommends to put an image of 100px X 150px',
  subscription_sepa_delay_warning: 'Processing your payment can take between 2 and 14 days.',
  project_consents_service_contractsV2: 'Contracts (Terms)',
  project_invoices_widget_button_downloading: 'Downloading...',
  cookies_editor_consent_wall_title: 'Contextual Consent Wall',
  contractsEditor_error_uniqueName: 'You already have a contract with the same name for this project',
  subscription_features_titles: 'Features',
  subscription_features_show_more: 'Show more',
  subscription_features_show_less: 'Show less',
  project_consents_pagination_total: 'total',
  subscription_meteredBilling_termsConsents: 'Terms Consents',
  contractsEditor_confSettings_rename_warning: 'Also remember to change the configuration name in your Terms embed code.',
  step_special_enableOnMobile: 'Enable Consent Wall on Mobile',
  auth_updatePassword_logoutWarning: 'You\'ll have to log back in once the change is made.',
  my_usage_title: 'My usage',
  my_usage_periodicity: 'Period',
  my_usage_periodicity_month: 'Monthly',
  my_usage_periodicity_year: 'Yearly',
  my_usage_from: 'From',
  my_usage_to: 'to',
  my_usage_progress_title: 'In my plan',
  my_usage_usage_type_pageViews: 'page views',
  my_usage_usage_type_termsConsents: 'consents',
  my_usage_usage_type_publishedProjects: 'projects',
  my_usage_limit_exceeded: 'Subscription limit exceeded.',
  marketplace_title: 'Analytics Connectors',
  marketplace_text: 'Axeptio is connected with several services. Choose all the services you use',
  marketplace_piano_title: 'Piano Analytics',
  marketplace_piano_input_function_name: 'Name of the function',
  marketplace_piano_input_function_description: 'Change this value',
  marketplace_piano_activate_privacy_mode: 'Activate privacy mode',
  marketplace_piano_activate_privacy_mode_desc: 'Activate privacy mode',
  marketplace_activate_service: 'Activate the service',
  marketplace_save_button_service: 'Save',
  marketplace_googleanalytics_title: 'Google Analytics 4',
  marketplace_googleanalytics_input_function_name: 'Name of the function',
  marketplace_googleanalytics_input_function_description: 'Change this value',
  marketplace_piano_input_function_value: 'pa',
  marketplace_googleanalytics_input_function_value: 'gtag',
  marketplace_publish_widgets_toast_title: 'Publish your widgets',
  marketplace_publish_widgets_toast_description: 'Your widgets must be republished to apply your changes',
  scanner_helpertip_update: 'This option allows us to update the configuration you want.\nOur automatons will not delete your work, it will only add new vendors if necessary.\nIt is strongly advised to check the option <b> Delete vendors </b> in order to have better results. Always check an updated configuration before publishing it!',
  scanner_delete_vendors: 'Let our tool manage your configuration',
  auth_error_email_already_exists: 'A user already exists with that email',
  auth_error_invalid_email: 'Invalid email',
  auth_password_number: 'One number',
  auth_password_lowercase: 'One lowercase character',
  auth_password_uppercase: 'One uppercase character',
  auth_password_special_char: 'One special character',
  auth_password_length: '8 characters minimum',
  auth_error_reset_password_nonlocal_provider: 'You can\'t reset your password with a SSO access (Google Signup, Facebook Login)',
  auth_captcha_error: 'The anti-robot verification failed, please retry',
  scanner_stylesheet_button: 'Apply the site design to the widget',
  scanner_stylesheet_warning_helper: 'Please note, by checking this box you authorize our tool to modify the graphic design parameters of your project and therefore <b>of all its configurations</b>, including those already existing.',
  scanner_stylesheet_warning_helper_schedule: 'Please note, you have activated the automatic design feature. This action authorizes our tool to modify the graphic design parameters of your project and therefore <b>of all its configurations</b>, including those already existing.',
  user_invitation_email_language: 'Email invitation language',
  user_invitation_email: 'E-mail(s) to invite',
  scanner_domain_helper: 'The scanner scans a maximum of 70 pages, if you would like to do a more in-depth scan afterwards, please contact our support',
  doc_project: 'Project',
  doc_organization: 'Organization',
  user_account_pending_invitations: 'Pending invitations',
  user_account_accept_invitation: 'Accept invitation',
  user_account_delete_invitation: 'Delete invitation',
  user_account_invited_by: 'Invited by',
  project_stats_kpi_quickBounceRate_v2: 'Quick bounce rate',
  project_stats_kpi_hits_v2: 'Hits',
  project_stats_kpi_pageview_v2: 'Page views',
  project_stats_kpi_optInRate_v2: 'Opt-in rate',
  project_stats_kpi_consentRateMobile_v2: 'Consent rate mobile',
  project_stats_kpi_consentRateDesktop_v2: 'Consent rate desktop',
  project_stats_kpi_interactionRateMobile_v2: 'Interaction rate mobile',
  project_stats_kpi_interactionRateDesktop_v2: 'Interaction rate desktop',
  project_stats_metrics_visitorsPercent_v2: 'All visitors',
  project_stats_metrics_bounceVisitorsPercent_v2: 'Visitors which bounced',
  project_stats_bounceRateByDuration_v2: 'Percentage of visitors who leaved the site after x seconds',
  stepDefaultPlaceholder: '(empty)',
  emailValidationRequired: 'To start using our services, please validate your email address (link received by email). If you can\'t find this email, you can click here:',
  emailValidationRequired_resendEmail: 'Send it back.',
  emailValidationRequired_resendEmail_success: 'Email successfully sent !',
  project_stats_kpi_pageviews_tooltip_v2: 'The total number of pages a user has navigated. A page visited multiple times is counted multiple times.',
  project_stats_kpi_pageview_tooltip_v2: 'The number of different pages a user has navigated. A page visited multiple times is counted only once.',
  project_stats_kpi_visitor_tooltip_v2: 'The number of unique visitors over a given period. If the visitor returns during this period, they are only counted once.',
  project_stats_kpi_newVisitor_tooltip_v2: 'The number of visitors who have never visited the site before the studied period.',
  project_stats_kpi_consentRate_tooltip_v2: 'Percentage of users who have accepted or partially accepted cookies compared to all visitors who have accepted or refused cookies.',
  project_stats_kpi_interactionRate_tooltip_v2: 'Percentage of visitors who interacted with the widget compared to those who had the opportunity to do so, i.e., new visitors as well as old visitors who have never interacted with the widget',
  project_stats_kpi_optInRate_tooltip_v2: 'Percentage of visitors who have accepted cookies compared to all visitors who had the opportunity to do so, i.e., new visitors as well as old visitors who have never interacted with the widget. The opt-in rate is equal to the consent rate multiplied by the interaction rate.',
  project_stats_kpi_hits_tooltip_v2: 'A \'hit\' is recorded when a user requests our servers to download the Axeptio script. This counter represents only a fraction of the actual traffic, because web browsers save our script to use it again later. This value was previously used to tally \'pageviews\'.',
  project_stats_kpi_bounceRate_tooltip_v2: 'Percentage of visitors who viewed only one page and left the site',
  project_stats_kpi_quickBounceRate_tooltip_v2: 'Percentage of visitors who viewed only one page and left the site in less than 5 seconds.',
  project_stats_kpi_consentRateMobile_tooltip_v2: 'Percentage of mobile users who have accepted or partially accepted cookies compared to all visitors who have accepted or refused cookies.',
  project_stats_kpi_consentRateDesktop_tooltip_v2: 'Percentage of PC users who have accepted or partially accepted cookies compared to all visitors who have accepted or refused cookies.',
  project_stats_kpi_interactionRateMobile_tooltip_v2: 'Percentage of mobile visitors who interacted with the widget compared to those who had the opportunity to do so, i.e., new visitors as well as old visitors who have never interacted with the widget.',
  project_stats_kpi_interactionRateDesktop_tooltip_v2: 'Percentage of PC visitors who interacted with the widget compared to those who had the opportunity to do so, i.e., new visitors as well as old visitors who have never interacted with the widget.',
  untitled: 'Untitled',
  cookies_editor_stepImageAlign_label: 'Illustration image alignment',
  stepImageAlign_left: 'Left',
  stepImageAlign_right: 'Right',
  stepImageAlign_center: 'Center',
  resetAll: 'Reset all strings to default',
  scanner_dont_delete_vendors: 'Add an extra step containing all missing vendors',
  scanner_delete_vendors_info: 'Our tool will take care of modifying the third-party services declared in your steps. It does not modify or delete its content, but will disable the publication of unused steps.',
  reset_text_to: 'Reset text to',
  clear_text: 'Clear text',
  service_button_translate: 'Translate',
  service_button_translate_title: 'Translate this configuration',
  service_button_translate_from: 'From',
  service_button_translate_to: 'To',
  service_button_translate_duplicate_config: 'Duplicate configuration before translating',
  service_button_translate_button: 'Translate',
  service_button_translate_cancel_button: 'Cancel',
  service_button_translate_in_progress_title: 'Translation in progress...',
  service_button_translate_success: 'Your configuration is now translated. Please review it before publication.',
  service_button_translate_success2: 'Your personalized cookie descriptions are not translated.',
  service_button_translate_review: 'Review translation',
  service_button_translate_close: 'Close',
  service_button_translate_success_title: 'Translation done!',
  service_button_translate_unavailable_title: 'Automated translation unavailable',
  service_button_translate_unavailable_tooltip: 'Translation unavailable',
  service_button_translate_unavailable_description: 'To enjoy this feature, a higher plan \u2028is required',
  service_button_translate_unavailable_button: 'Explore our plans',
  service_button_translate_error_select_language: 'Please choose a language to translate to',
  service_button_translate_no_default_title: 'The language is not defined',
  service_button_translate_no_default_description: 'Please select the configuration language in the settings page.',
  service_button_translate_no_default_button: 'Go to configuration settings',
  service_button_translate_ai_translation: 'Translate my personalized texts with AI',
  marketplace_matomo_title: 'Matomo',
  marketplace_piwik_title: 'Piwik',
  marketplace_beyable_title: 'Beyable',
  marketplace_matomo_input_function_value: 'matomo',
  marketplace_piwik_input_function_value: 'piwik',
  marketplace_beyable_input_function_value: 'beyable',
  marketplace_matomo_description: 'This service will send two events to Matomo. When the widget pops and the visitor answers.',
  marketplace_piwik_description: 'This service will send two events to Piwik. When the widget pops and the visitor answers.',
  marketplace_beyable_description: 'This service will send two events to Beyable. When the widget pops and the visitor answers.',
  marketplace_googleanalytics_description: 'This service will send two events to Google Analytics. When the widget pops and the visitor answers.',
  marketplace_piano_description: 'This service will send two events to Piano. When the widget pops and the visitor answers.',
  cstools_breadcrumb_title: 'CS Tools',
  cstools_users_breadcrumb_title: 'Users',
  enterprise_only_feature: 'Enterprise-only feature',
  disclaimer_reject_buttons_loi25: 'Although it’s not mandatory under Law 25, keeping the opt-out button preserves the trust visitors place in your brand.',
  marketplace_visitors_analytics_title: 'Visitor Analytics',
  marketplace_visitors_analytics_input_function_value: 'va',
  marketplace_visitors_analytics_description: 'This service will send two events to Visitor Analytics. When the widget pops and the visitor answers.',
  marketplace_plausible_title: 'Plausible',
  marketplace_plausible_description: 'This service will send two events to Plausible. When the widget pops and the visitor answers.',
  marketplace_plausible_input_function_value: 'plausible',
  marketplace_fathom_title: 'Fathom',
  marketplace_fathom_description: 'This service will send two events to Fathom. When the widget pops and the visitor answers.',
  marketplace_fathom_input_function_value: 'fathom',
  marketplace_amplitude_title: 'Amplitude',
  marketplace_amplitude_description: 'This service will send two events to Amplitude. When the widget pops and the visitor answers.',
  marketplace_amplitude_input_function_value: 'amplitude',
  projects_freetrial_limit_reached: 'You have reached the limit of free trial projects. You won\'t be able to publish a new one, unless you subscribe to an upper plan.',
  projects_published: 'Published',
  projects_unpublished: 'Unpublished',
  projects_freetrial_limit_reached_title: 'Free projects limit reached',
  projects_freetrial_limit_reached_description: 'You have reached the limit of free trial projects.<br />To publish more projects, discover the most suitable plans.',
  projects_freetrial_limit_reached_choose_plan: 'Choose a plan',
  projects_freetrial_limit_reached_book_demo: 'Book a demo',
  projects_freetrial_limit_reached_understand: 'Continue',
  projects_freetrial_limit_reached_contact_cs: 'Contact your customer associate',
  projects_and_configs_table_title: 'Projects & configurations',
  projects_and_configs_table_header_name: 'Name',
  projects_and_configs_table_header_last_modified: 'Last modified',
  projects_and_configs_table_header_status: 'Status',
  projects_and_configs_table_header_plan: 'Plan',
  projects_and_configs_table_header_type: 'Type',
  projects_and_configs_table_type_project: 'Project',
  group_search_bar_placeholder: 'Search for a group',
  projects_search_bar_placeholder: 'Search for a project',
  projects_no_projects_yet: 'You do not have any project yet',
  projects_no_results_found: 'No project found',
  projects_clear_filters_button: 'Clear filters',
  projects_filters_button: 'Filters',
  projects_fetch_error: 'Could not fetch your projects',
  project_filter_product: 'Product',
  project_filter_region: 'Region',
  project_filter_language: 'Language',
  project_filter_creation_date: 'Creation date',
  project_filter_date_anytime: 'Anytime',
  project_filter_date_between: 'Between',
  project_filter_date_after: 'After',
  project_filter_date_before: 'Before',
  project_filter_date_option_today: 'Today',
  project_filter_date_option_last_7_days: 'Last 7 days',
  project_filter_date_option_last_30_days: 'Last 30 days',
  project_filter_date_option_this_year: 'This year',
  project_filter_date_option_last_year: 'Last year',
  project_filter_date_option_custom: 'Custom period',
  project_filter_subscription: 'Subscription',
  project_filter_status: 'Status',
  project_filter_status_published: 'Published',
  project_filter_status_unpublished: 'Unpublished',
  projects_filters_search_button: 'Search',
  comov2_banner: '🎉&nbsp;&nbsp;<b>NEW FEATURE - </b>Axeptio offers you the best experience with Google\'s <b>Consent Mode V2</b>. <a target=\'_blank\' href=\'https://info.axeptio.eu/google-consent-mode-v2\' rel=\'noopener\' target=\'_blank\'>Click here</a> to learn more!',
  cookies_editor_consent_mode_v2_title: 'Consent Mode V2',
  cookies_editor_consent_mode_v2_display_label: 'Enable Consent Mode V2 (step)',
  cookies_editor_consent_mode_v2_enable_optional_consent_types_label: 'Enable Consent Mode V2 optional parameters',
  cookies_editor_consent_mode_v2_position_label: 'Step Position',
  cookies_editor_consent_mode_v2_position_second: 'Right after Welcome screen',
  cookies_editor_consent_mode_v2_position_last: 'Last step',
  cookies_editor_consent_mode_v2_advanced_settings_label: 'Advanced Settings',
  cookies_editor_consent_mode_v2_advanced_settings_ads_data_redaction_label: 'Redact ads data',
  cookies_editor_consent_mode_v2_advanced_settings_url_passthrough_label: 'Pass through ad click, client ID, and session ID information in URLs',
  cookies_editor_consent_mode_v2_warning: 'You can use a "ConsentModeV2" configuration in your widget for the following services. Please note that a "ConsentModeV2" configuration is mandatory to ensure proper functioning for these services within the following territories : European Economic Area (EEA) and Switzerland.',
  cookies_editor_consent_mode_v2_warning_CA: 'You can use a "ConsentModeV2" configuration in your widget for the following services:',
  cookies_editor_consent_mode_v2_warning_EU: 'We have found a vendor requiring a "ConsentModeV2" configuration in your widget. From March 6, 2024, ConsentModeV2 is mandatory to ensure proper functioning for these services.',
  cookies_editor_consent_mode_v2_advanced_settings_doc_label: 'Learn more on advanced settings...',
  cookies_editor_consent_mode_v2_advanced_settings_doc_url: 'https://developers.google.com/tag-platform/security/guides/consent?hl=en#passthroughs',
  auth_connect_with_google: 'Connect with Google',
  auth_connect_with_facebook: 'Connect with Facebook',
  projects_freetrial_limit_dont_show_again: 'Don\'t show again',
  cookies_editor_settings_form_inp_label: 'Enable INP enhancements',
  cookies_editor_settings_focus_when_open_label: 'Take focus when widget opens (useful for accessibility)',
  unknown_card_regulation: 'No regulation selected',
  cookies_editor_regulation_label: 'Associated Regulation',
  cookies_editor_regulation_description: 'Select the language and region, and we will adjust the settings based on the most appropriate regulation for your widget.',
  cookies_editor_no_regulation: 'Please select a language and a region to get the associated regulation.',
  tcf_admin_banner: '<b>#TCFMigration</b> : You’re seeing this message because you are potentially affected by the migration to the Axeptio For Publishers CMP. <a href="https://info.axeptio.eu/axeptio-for-publishers-migration" target="_blank">Click here</a> to find out what you need to do.',
  tcf_admin_tooltip: '#TCFMigration : You’re seeing this message because you are potentially affected by the migration to the Axeptio For Publishers CMP.',
  project_page_v2_on_demand: 'On demand',
  project_page_v2_modal_title: 'This product is not included in your subscription',
  project_page_v2_modal_demo_text: 'Book a demo',
  project_page_v2_modal_demo_link: 'https://meetings.hubspot.com/product-demo-bo/axeptio',
  project_page_v2_modal_text_contractsV2: 'If you\'re interested in our contract management tool Terms, find out more about it on our website, or by booking a demo with a member of our team.',
  project_page_v2_modal_discover_text_contractsV2: 'Discover Terms',
  project_page_v2_modal_discover_link_contractsV2: 'https://www.axept.io/terms-consent-contract',
  project_page_v2_modal_text_processings: 'If you\'re interested in our marketing consent management tool Subs, find out more about it on our website, or by booking a demo with a member of our team.',
  project_page_v2_modal_discover_text_processings: 'Discover Subs',
  project_page_v2_modal_discover_link_processings: 'https://www.axept.io/consent-widget',
  project_page_v2_modal_text_tcf: 'If you\'re interested in our publishers consent banner, find out more about it on our website, or by booking a demo with a member of our team.',
  project_page_v2_modal_discover_text_tcf: 'Discover Publishers',
  project_page_v2_modal_discover_link_tcf: 'https://www.axept.io/blog/axeptio-for-publishers',
  service_tcf_welcome_screen_title: 'Text',
  service_tcf_title: 'Title',
  service_tcf_title_placeholder: 'Professional and Free',
  service_tcf_surtitle: 'Surtitle',
  service_tcf_surtitle_placeholder: 'For Quality Information',
  service_tcf_subtitle: 'Sub title',
  service_tcf_subtitle_placeholder: 'We need cookie',
  service_tcf_standard_text: 'Standard information text',
  service_tcf_standard_text_warning: 'In order to be compliant with the TCF 2.2 framework from IAB, this text is not customizable.',
  service_tcf_cookie_statement_url: 'Cookie statement url',
  service_tcf_privacy_url: 'Privacy policy url',
  service_tcf_time_to_live: 'Time to live',
  service_tcf_select_vendors_title: 'Select your vendors',
  service_tcf_select_vendors_description: 'Add or delete vendors',
  cookies_editor_consent_mode_v2_illustrations_label: 'Illustrations',
  cookies_editor_consent_mode_v2_illustrations_display_label: 'Display Consent Mode v2 Cookies illustrations',
  service_tcf_show_vendors_selected_only: 'Show selected vendors only',
  service_tcf_all_vendors: 'All vendors',
  service_tcf_collapsed_vendor_legitimate_interest: 'The vendor relies on his legitimate interest for :',
  service_tcf_storage_duration: 'Storage duration',
  service_tcf_storage_duration_unit: 'days',
  service_tcf_collected_data_categories: 'Collected data categories',
  service_tcf_more_information: 'More information',
  service_tcf_vendor_privacy_policy: 'Privacy policy',
  service_tcf_vendor_list: 'List',
  service_tcf_select_stack_title: 'Select stacks',
  service_tcf_select_stack_info: 'The vendor you selected requiring',
  service_tcf_select_stack_desc: 'Please select the stacks you want to group purposes in the widget to avoid displaying the full list of purposes. You are free to decide what stack you want to display.',
  service_tcf_search_by_name: 'Search a vendor',
  project_page_modal_limit_reached_title: 'Page view limit reached',
  project_page_modal_limit_reached_text: 'You have reached the page view limit with your plan.\u2028<br />You will now be charged for every additional page views.<br />\u2028Maybe it\'s time to discover the most suitable plans.',
  project_page_modal_limit_reached_contact_us_link: 'https://meetings.hubspot.com/thomas-angot',
  project_page_modal_limit_reached_contact_us_label: 'Contact us',
  project_page_modal_limit_reached_plan_label: 'Choose a plan',
  billing_form_label_currency: 'Currency',
  cookies_editor_consent_mode_v2_default_documentation_text: 'While activating Consent Mode v2, don\'t forget to set the default parameters to ensure that it works properly. ',
  cookies_editor_consent_mode_v2_default_documentation_link_text: 'See how in our documentation.',
  cookies_editor_consent_mode_v2_default_documentation_link_href: 'https://support.axeptio.eu/hc/en-gb/articles/22570846852753#h_01HPPCB6MNG8SS99QEQ4H7PCN5',
  tcf_editor_consent_mode_v2_display_label: 'Enable Consent Mode V2',
  cookies_editor_consent_mode_v2_alert_info_next_visitor_show_cmp: 'Next time a visitor who has already interacted with your CMP returns, it will reappear to present the Consent Mode V2 page.',
  auto_save_error: 'Error while saving',
  tcf_editor_cs_user_introduction: 'You can modify this text because you are a CS users. The user cannot modify this text.',
  tcf_editor_manage_cookies_website_link_field_label: '"Manage cookies" link on your website',
  tcf_editor_manage_cookies_app_button_field_label: '"Manage cookies" button on your mobile application',
  tcf_editor_cookies_complete_javascript: 'A "cookies:complete" event is dispatched.',
  tcf_editor_cookies_complete_documentation: 'https://support.axeptio.eu/hc',
  tcf_editor_cmp_version: 'CMP version',
  marketplace_modal_warning_title: 'To activate this option, you must upgrade your plan',
  marketplace_modal_warning_description: 'Analytics connections allow for the collection of events in your audience measurement tool.<br />This way, you can centralize all statistics in this tool for better readability',
  marketplace_modal_warning_btn_more_info_link: 'https://www.axept.io/demo',
  marketplace_modal_warning_btn_more_info_label: 'Book a demo',
  marketplace_modal_warning_btn_upgrade_label: 'Upgrade my plan',
  publishModal_no_vendors: 'No vendors set',
  tcf_color_scheme: 'Color Scheme',
  tcf_color_scheme_light: 'Light mode',
  tcf_color_scheme_dark: 'Dark Mode',
  tcf_editor_expiration_ttl: 'Maximum delay consent to ask consent again',
  tcf_editor_open_widget: 'Open the widget',
  tcf_editor_import_vendors: 'PLEASE ENTER A COMMA-SEPARATED LIST OF IDS',
  tcf_edtior_import_vendors_desc: 'IAB and/or custom Axeptio',
  tcf_editor_import_button: 'Import',
  tcf_editor_import_vendors_button: 'Import vendor lists',
  tcf_editor_create_vendor_button: 'Create a new vendor',
  tcf_vendor_legitimate_interest: 'Legitimate interests purposes',
  tcf_vendor_storage_duration: 'Storage duration',
  tcf_vendor_collected_data: 'Collected data categories',
  tcf_vendor_purpose: 'Purpose',
  mandatory: 'Mandatory',
  tcf_position_centered: 'Centered',
  tcf_position_bottom_left: 'Bottom left',
  tcf_position_bottom_right: 'Bottom right',
  project_design_tcf_widget_position: 'Widget position',
  cookies_editor_no_regulation_no_translation: 'We do not yet have translations or regulations associated with the language you have selected.',
  subscription_tabs_subscription_title: 'My subscription',
  subscription_tabs_payment_title: 'Payment and billing',
  subscription_tabs_payment_address_edit_button: 'Edit my address',
  tcf_vendors_tcString: 'tcString',
  tcf_vendors_tcString_description: '<p>Paste your tcString. <a target="_blank" href="https://support.axeptio.eu/hc/fr/articles/24045909792529-2-Configurer-le-widget-TCF#h_01HW33S71GETPNHD2RB6XEVPMB">Need help ?</a></p>',
  tcf_editor_import_tcString_button: 'Import from a tcString',
  step_message_hidden_button: 'Additional message (if you hide the Axeptio button)',
  subscription_meteredBilling_monthlyActiveUsers: 'Monthly active users',
  custom_strings_ClearFormulationsDisclaimer_alert: 'If you choose to customize the widget\'s wording, make sure you always use clear wording that is easily understood by users in order to comply with your legal and/or regulatory obligations.',
  project_stats_title_web_kpis: 'Web KPIs',
  project_stats_title_app_sdk_kpis: 'App SDK KPIs',
  tcf_editor_additional_consent: 'Additional Consent V2',
  tcf_editor_additional_consent_activate: 'Activate Addtional Consent V2',
  tcf_editor_additional_consent_read_more: 'Learn more on Addtionial Consent v2',
  tcf_editor_additional_consent_read_more_link: 'https://support.google.com/admob/answer/9681920?hl=en',
  marketplace_documentation_link: 'https://support.axeptio.eu/hc/en-gb/sections/24093133691537-Analytics-Connectors',
  marketplace_tagmanager_title: 'Google Tag Manager',
  marketplace_tagmanager_description: 'Send consent.display and consent.answer via dataLayer.push()',
  recommended: 'Recommended',
  comov2_description: 'Data is collected for the purpose of personnalizing and measuring the effectiveness of advertising',
  comov2_link: 'https://www.axept.io/blog/google-consent-mode-v2',
  comov2_link_label: 'Learn more about how Google processes personal information',
  project_comov2_modal_title: 'Enable Consent Mode v2',
  project_comov2_modal_text_start: 'We\'ve noticed that one or more of your cookie configurations (the ones with the label',
  project_comov2_modal_text_end: ') require Consent Mode V2 to be compliant.',
  project_comov2_modal_text_final: 'Fix this in just a few clicks!',
  comov2_label_pellet: 'CoMoV2 !',
  comov2_pellet_tooltip: 'This configuration requires Consent Mode V2 to be compliant',
  project_comov2_modal_btn_contact: 'Contact Axeptio Team',
  project_comov2_modal_btn_docs: 'Follow the documentation',
  subscription_cancellation_unavailable: 'You can\'t cancel your subscription for now.',
  subscription_cancellation_unavailable_description: 'Your account has a debit balance, you cannot cancel your subscription. Please contact <a href="mailto:compta@agilitation.fr">compta@agilitation.fr</a> to regularize your situation.',
  project_deletion_unavailable: 'You can\'t delete this project for now.',
  no_config_to_publish_modal_title: 'Before publishing, you first need to select a product then create a configuration.',
  publish_button_no_configurations_tooltip: 'Configure before',
  integrate_project_button: 'Integrate on your website',
  publish_modal_integration_message: 'By the way, have you integrated our service on your website? If you have not, feel free to watch how to do it and then publish!',
  project_sidebar_description: 'Load the Axeptio configuration in your script before publishing following one of these available options.',
  project_sidebar_description_gtm: 'You must paste this project id into the Axeptio CMP tag field.',
  project_sidebar_description_cms: 'You must paste this project id into the Axeptio CMP tag field. Take a glance at the documentation related to the CMS you manage.',
  project_sidebar_description_global: 'You must first integrate this code in the <head> or the <body> tag. Make sure to load it as soon as possible to avoid it from being blocked by other scripts.',
  project_sidebar_title_project_id: 'Project ID',
  project_sidebar_button_tab_global: 'Global',
  project_sidebar_button_cta_documentation: 'Link to the documentation',
  project_sidebar_button_cta_gtm_documentation: 'Consult the Tag Manager Interface documentation',
  project_sidebar_button_cta_gtm_url: 'https://support.axeptio.eu/hc/en-gb/articles/4404040466321-Tag-Manager-Interface',
  project_sidebar_collapse_me: 'Collapse sidebar',
  project_sidebar_button_cta_link_doc_global: 'https://support.axeptio.eu/hc/en-gb/articles/4404040521105-Install-Axeptio-on-your-website',
  disclaimer_show_purposes: 'If you desactivate this module, you will have to list the purposes of the cookies in your widget weclome text using clear and precise terms.',
  project_infos_double_subscription_title: 'Change organization',
  project_infos_double_subscription_description: 'You are about to switch organization.<br /><br />This means that this project will now be included in your organization\'s subscription.',
  project_infos_double_subscription_button_confirm: 'Yes, I confirm',
  project_infos_double_subscription_button_return: 'Cancel',
  project_infos_double_subscription_success_msg: 'Your organization will be updated once you have saved.',
  subscription_legacy_plan_description_with_differences: 'Newer plans are available.<br />\nThey include new features.<br />\nFind and compare our current offers below.',
  project_subscription_no_access_title: 'Your subscription is managed elsewhere (by an agency, in a CMS, ...)',
  project_subscription_no_access_description: 'To change your plan, please contact our support by email by clicking the button below',
  contact_support_button_label: 'Contact support',
  project_subscription_no_access_contact_subject: 'I\'d like to change my subscription',
  breadcrumb_text: 'Edit texts',
  onboarding_modal_welcome_title: 'Hello and welcome to Axeptio !',
  onboarding_modal_welcome_description: 'Before you start, let\'s get to know each other. These few questions will help us learn about you and what you do. Don’t worry it will only take a few seconds !',
  onboarding_modal_company_name_input_label: 'What’s your company’s name ?',
  onboarding_modal_company_location_input_label: 'Where’s your company located ?',
  onboarding_modal_company_name_input_placeholder: 'Apple',
  onboarding_modal_learn_about_you_title: 'Hello ! We’d love to learn more about you',
  onboarding_modal_learn_about_you_description: 'These few questions will help us learn about you and what you do. Don’t worry it will only take a few seconds !',
  onboarding_modal_learn_about_you_instruction: 'Please confirm your first and last name, or make it appear it the correct field',
  onboarding_modal_learn_about_you_first_name_input_label: 'First Name',
  onboarding_modal_learn_about_you_last_name_input_label: 'Last Name',
  field_required: 'This field is required.',
  onboarding_modal_company_type_input_label: 'What type of company are you in ?',
  onboarding_modal_company_field_input_label: 'How did you end-up using Axeptio ?',
  onboarding_modal_company_type_agency: 'My digital agency chose Axeptio for our different websites (agency plan)',
  onboarding_modal_company_type_enterprise: 'My company chose Axeptio (enterprise plan)',
  onboarding_modal_company_type_self_service: 'I personally chose Axeptio (self-service plan)',
  onboarding_modal_company_department_tech: 'Tech',
  onboarding_modal_company_department_product: 'Product',
  onboarding_modal_company_department_marketing: 'Marketing',
  onboarding_modal_company_department_data: 'Data',
  onboarding_modal_company_department_business: 'Business',
  onboarding_modal_company_department_legal: 'Legal',
  onboarding_modal_company_department_other: 'Other',
  select_default_placeholder: 'Choose ...',
  onboarding_modal_company_field_of_industry_label: 'What’s your field of industry?',
  onboarding_modal_company_department_input_label: 'What kind of agency do you work for ?',
  onboarding_modal_company_field_of_industry_publishers: 'Publishers & Media',
  onboarding_modal_company_field_of_industry_real_estate: 'Real estate',
  onboarding_modal_company_field_of_industry_tech: 'Tech & software',
  onboarding_modal_company_field_of_industry_children: 'Children',
  onboarding_modal_company_field_of_industry_sport: 'Sport',
  onboarding_modal_company_field_of_industry_insurance: 'Insurance & Financial Services',
  onboarding_modal_company_field_of_industry_health: 'Health & Pharmaceuticals',
  onboarding_modal_company_field_of_industry_telecom: 'Telecoms',
  onboarding_modal_company_field_of_industry_consumer_goods: 'Consumer Goods',
  onboarding_modal_company_field_of_industry_tourism: 'Tourism & Travel',
  onboarding_modal_company_field_of_industry_culture: 'Culture & entertainement',
  onboarding_modal_company_field_of_industry_manufacturing: 'Manufacturing industry',
  onboarding_modal_company_field_of_industry_retail: 'Retail',
  onboarding_modal_company_field_of_industry_agriculture: 'Agriculture',
  onboarding_modal_company_field_of_industry_education: 'Education',
  onboarding_modal_company_field_of_industry_food: 'Food & Beverages',
  onboarding_modal_company_field_of_industry_npo: 'Non Profit Organization',
  onboarding_modal_company_field_of_industry_other: 'Other',
  onboarding_modal_company_agency_kind_digital: 'Digital marketing (360 agencies)',
  onboarding_modal_company_agency_kind_seo: 'SEO agencies',
  onboarding_modal_company_agency_kind_paid_acquisition: 'Paid acquisition',
  onboarding_modal_company_agency_kind_data: 'Data & BI',
  onboarding_modal_company_agency_kind_web_dev: 'Web developer',
  onboarding_modal_company_agency_kind_e_commerce: 'E-commerce developer',
  onboarding_modal_company_agency_kind_analytics: 'Analytics & Tracking',
  onboarding_modal_company_agency_kind_design: 'Design',
  onboarding_modal_company_agency_kind_cms_crm: 'CMS/CRM integrator',
  onboarding_modal_company_agency_kind_esn: 'ESN',
  onboarding_modal_company_agency_kind_consultant: 'Consultant',
  onboarding_modal_company_cms_label: 'Are you using a CMS?',
  yes: 'Yes',
  nope: 'Nope',
  onboarding_modal_company_which_cms_label: 'Which CMS are you using ?',
  onboarding_modal_complete_step_title: 'Thank you for your answers & welcome to Axeptio!',
  onboarding_modal_complete_step_button_label: 'Let\'s go!',
  save_before_leaving_modal_title: 'Leave without saving?',
  save_before_leaving_modal_description: 'Be careful, if you leave this page without saving, your changes will be lost.',
  save_before_leaving_modal_leave_button: 'Leave without saving',
  save_before_leaving_modal_save_button: 'Save and continue',
  project_menu_config_modal_delete: 'Delete configuration',
  project_menu_config_modal_translate_config: 'Translate this configuration',
  project_menu_config_modal_translate_title: 'AI-generated translation review',
  project_menu_config_modal_translate_ai_text: 'We use artificial intelligence tools to provide Axeptio® in multiple language versions. We recommend you check the accuracy and understandability of these translations for your end-users.',
  regulation_choice: 'Choose a regulation',
  language_available_config: 'Languages availables',
  multiple_language_config: 'Multilingual',
  language_preview: 'Language to preview',
  multiple_language_code: 'multilingual',
  config_multilingue: 'Multilingual configuration',
  config_multilingue_desc: 'One regulation many languages',
  projects_group_all_projects: 'All projects',
  projects_group_list_new_group: 'New group',
  projects_group_list_create_modal_title: 'Create a new group',
  projects_group_list_duplicate_modal_title: 'Duplicate the group',
  projects_group_list_rename_modal_title: 'Rename the group',
  projects_group_list_create_modal_group_name: 'Group name',
  projects_group_list_create_modal_select_orga: 'Select your organization',
  projects_group_list_create_modal_no_orga_choise: 'No organization (Keep for me)',
  projects_group_list_create_modal_select_orga_instruction: 'This group will be added to the selected organization',
  btn_cancel: 'Cancel',
  projects_group_list_created_message_success: 'The group has been successfully created',
  projects_group_list_created_message_error: 'The group could not be created',
  projects_group_list_updated_message_success: 'The group has been successfully updated',
  projects_group_list_updated_message_error: 'The group could not be updated',
  projects_group_list_duplicate_message_success: 'The group has been successfully duplicated',
  projects_group_list_duplicate_message_error: 'The group could not be duplicated',
  projects_group_list_deleted_message_success: 'The group has been successfully deleted',
  projects_group_list_deleted_message_error: 'The group could not be deleted',
  projects_group_manage_modal_title: 'Manage groups',
  projects_group_delete_modal_title: 'Delete the group',
  projects_group_delete_modal_description: 'You are about to delete the group "{group_name}" (which contains {projects_count} projects). This action can not be canceled. Are you sure you want to continue ?',
  projects_group_list_duplicate_message_error_same_name: 'Please enter a different name from the original group',
  projects_group_create_a_new_group: 'Create a new group',
  projects_add_to_group_btn: 'Add to group',
  projects_group_selected: 'selected',
  project: 'project',
  projects_group_manage_modal_empty_state: 'You don\'t have any groups yet. Please create one using the button below',
  projects_group_manage_modal_filtered_empty_state: 'No groups match your search',
  account_breadcrumb_title: 'Account',
  organization_breadcrumb_title: 'Organization',
  user_account_avatar: 'Avatar',
  no_description_available: 'no description available'
};

export default adminStrings;
import styled from 'styled-components';
import { useLocale } from '../../contexts/LocaleContext';
import ProductCardLabel from '../ProjectComponents/ProductCardLabel';
import assets from '../../services/Assets';
import WarningModal from '../Modal/WarningModal';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ServicesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const ServiceButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export default function NoConfigToPublishModal({ onClose, projectId }) {
  const { services } = useLocale();
  const { t } = useTranslation();

  function renderServiceLabel(serviceName) {
    const service = services.find(service => service.name === serviceName);

    if (!service) {
      return null;
    }

    return (
      <Link to={'/projects/' + projectId + '/' + serviceName}>
        <ServiceButton
          type="button"
          onClick={() => {
            onClose();
          }}
        >
          <ProductCardLabel
            size="large"
            labelColor={service.labelColor}
            src={assets.image(service.labelIcon)}
            alt={service.name}
            labelName={service.labelName}
          />
        </ServiceButton>
      </Link>
    );
  }

  return (
    <WarningModal isOpen onClose={onClose} modalContent={{ title: t('no_config_to_publish_modal_title') }}>
      <ServicesContainer>
        {renderServiceLabel('cookies')} {renderServiceLabel('tcf')}
      </ServicesContainer>
      <ServicesContainer>
        {renderServiceLabel('contractsV2')} {renderServiceLabel('processings')}
      </ServicesContainer>
    </WarningModal>
  );
}

const services = [
  {
    name: 'cookies',
    title: 'Bannière de cookies',
    description: 'Goûtez aux cookies par Axeptio : une approche fraîche et respectueuse de vos utilisateurs pour recueillir leur consentement en matière de cookies. Des idées simples (temporisation, transparence, style conversationnel) au service d\'une meilleure conversion.',
    icon: 'icon_v2_cookie_happy',
    collection: 'cookies',
    templates: true,
    isversioned: true,
    monthlyPrice: 15,
    yearlyPrice: 150,
    planMonthly: 'plan_EK56NVSaUTYeVX',
    planYearly: 'plan_EK59JO3Qc05BEZ',
    page_title: 'Réconcilliez vos utilisateurs avec les bandeaux cookies',
    page_subtitle: 'Une solution rassurante, transparente et interactive',
    page_features: '<li>100% personnalisable</li>\n<li>Synchronisation avec Google Tag Manager</li>\n<li>Rédactionnels convaincants</li>',
    illustration: 'new_service_hover_cookies_v2',
    illustration_width: 600,
    comment_img: 'new_service_logo_1',
    comment_img_size: 600,
    existingItemsTitle: 'Configurations existantes',
    newItemTitle: 'Créer une nouvelle configuration cookies',
    no_item_title: 'Vous n\'avez aucune configuration cookies.',
    no_item_button: 'Créer une nouvelle configuration cookies',
    newItemDescription: 'Vous avez la possibilité de créer autant de versions de configuration que vous le souhaitez.',
    isActive: true,
    isPlanActive: true,
    isPlanSlim: false,
    endpoint: 'vault',
    isActiveV2: true,
    labelName: 'Brands',
    labelColor: '#F7ECCF',
    labelIcon: 'product_icon_cookie',
    newIllustration: 'product_cookie_fr',
    bgColor: '#FAE4A6',
    newTitle: 'Mes bandeaux cookies',
    newDescription: 'Le bandeau de consentement iconique : gérez vos cookies, textes, couleurs et plus encore.',
    order: 1,
    featureToggle: false
  },
  {
    name: 'themes',
    title: 'Gestion des themes graphiques',
    description: 'Générez facilement le visuel de votre widget à l\'aide de thèmes changeable depuis votre site web.',
    icon: 'artlovo',
    paint: 'yellow',
    collection: 'themes',
    templates: false,
    isversioned: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    planMonthly: 'plan_EK50il5T7mxm9G',
    planYearly: 'plan_ELW93Mgba1zjLr',
    page_title: 'Générez en ligne vos personalisations graphique',
    page_subtitle: 'Une flexibilité sur le style graphique de votre widget en quelques cliques',
    illustration: 'service_cookies_hover',
    illustration_width: 300,
    comment_img: 'new_service_logo_2',
    comment_img_size: 600,
    no_item_title: 'Vous n\'avez aucun theme graphique.',
    no_item_button: 'Créer une nouvelle configuration',
    isActive: false,
    isPlanActive: false,
    isPlanSlim: true,
    endpoint: 'vault',
    featureToggle: false
  },
  {
    name: 'processings',
    title: 'Recueil des consentements marketing',
    description: 'Newsletters, SMS, jeux concours... Mettez votre marketing au carré avec un recueil de consentement contextuel et strictement conforme aux exigences réglementaires. Cerise sur le gâteau, vous pouvez désormais gérer un consentement graduel, le "oui, mais..."',
    icon: 'icon-consentement-newsletter',
    collection: 'processings',
    templates: true,
    isversioned: false,
    monthlyPrice: 10,
    yearlyPrice: 100,
    planMonthly: 'plan_EK55PXn95UPRqQ',
    planYearly: 'plan_EK555jEoVfSLQc',
    page_title: 'Entrez dans l’ère du marketing choisi',
    page_subtitle: 'Un recueil de consentement conforme au service de vos opt-ins ! ',
    page_features: '<li>Configuration en 2 minutes</li>\n<li>Contrôle de la pression publicitaire</li>\n<li>Blocs prêts à l\'emploi</li>',
    illustration: 'new_service_hover_processing_v2',
    illustration_width: 600,
    comment_img: 'new_service_logo_2',
    comment_img_size: 600,
    existingItemsTitle: 'Vos traitements de données personnelles',
    newItemTitle: 'Nouveau traitement ',
    no_item_title: 'Vous n\'avez aucune configuration consentements marketing.',
    no_item_button: 'Créer une nouvelle configuration',
    isActive: true,
    isPlanActive: true,
    isPlanSlim: true,
    endpoint: 'vault',
    dataFeature: 'marketing_consents',
    isActiveV2: true,
    labelName: 'Subs',
    labelColor: '#ECF9F7',
    labelIcon: 'product_icon_subs',
    newIllustration: 'product_subs_fr',
    bgColor: '#B6E2DD',
    newTitle: 'Mes consentements marketing',
    newDescription: 'Un encart pour gérer l\'inscription à vos newsletters, formulaires ou concours.',
    order: 4,
    featureToggle: false
  },
  {
    name: 'contracts',
    title: 'Recueil des consentements contractuels',
    description: 'Conditions générales de vente ou d\'utilisation, accord de confidentialité, etc. Nous mettons à votre disposition un bloc de consentement conforme, intégrant une visionneuse de PDF. ',
    icon: 'icon_v2_papers',
    collection: 'contracts',
    templates: true,
    isversioned: false,
    monthlyPrice: 15,
    yearlyPrice: 150,
    planMonthly: 'plan_EK54BWp89WXHz3',
    planYearly: 'plan_EK54w5PASnn7pD',
    page_title: 'Gardez un oeil sur vos contrats',
    page_subtitle: 'CGU, CGV, NDA : stockez les consentements de vos documents contractuels',
    page_features: '<li>Configuration en 2 minutes</li>\n<li>Versionning de documents</li>\n<li>Visionneuse de PDF</li>',
    illustration: 'new_service_hover_contracts',
    illustration_width: 600,
    comment_img: 'new_service_logo_3',
    comment_img_size: 600,
    existingItemsTitle: 'Vos documents',
    newItemTitle: 'Nouveau contrat',
    no_item_title: 'Vous n\'avez aucune configuration consentements contractuels.',
    no_item_button: 'Créer une nouvelle configuration',
    isActive: true,
    isPlanActive: true,
    isPlanSlim: true,
    endpoint: 'vault',
    dataFeature: 'contract_consents',
    featureToggle: false
  },
  {
    name: 'contractsV2',
    title: 'Terms',
    description: 'Nouvelle mouture de notre widget de gestion des contrats',
    icon: 'icon_v2_terms',
    collection: 'contracts',
    templates: false,
    isversioned: false,
    monthlyPrice: 15,
    yearlyPrice: 150,
    planMonthly: 'plan_EK54BWp89WXHz3',
    planYearly: 'plan_EK54w5PASnn7pD',
    page_title: 'Gardez un oeil sur vos contrats',
    page_subtitle: 'CGU, CGV, NDA : stockez les consentements de vos documents contractuels',
    page_features: '<li>Configuration en 2 minutes</li>\n<li>Versionning de documents</li>\n<li>Visionneuse de PDF</li>',
    illustration: 'new_service_hover_contracts_v2',
    illustration_width: 600,
    comment_img: 'new_service_logo_3',
    comment_img_size: 600,
    existingItemsTitle: 'Vos documents',
    newItemTitle: 'Nouveau contrat',
    no_item_title: 'Vous n\'avez aucune configuration consentements contractuels.',
    no_item_button: 'Créer une nouvelle configuration',
    isActive: true,
    isPlanActive: true,
    isPlanSlim: true,
    endpoint: 'content',
    dataFeature: 'contract_consents_v2',
    isActiveV2: true,
    labelName: 'Terms',
    labelColor: '#FFF0E5',
    labelIcon: 'product_icon_terms',
    newIllustration: 'product_terms_fr',
    bgColor: '#FAC6A1',
    newTitle: 'Mes conditions générales',
    newDescription: 'Un encart pour exposer vos CGV & CGU.<br />Plus de transparence, moins de litiges.',
    order: 3,
    featureToggle: false
  },
  {
    name: 'portability',
    title: 'Partage de données personnelles',
    description: 'Vous souhaitez partager des données à caractère personnel avec des tiers ? Nous avons ce qu\'il vous faut ! Comme pour les consentements marketing, présentez à vos utilisateurs un encart rassurant et détaillé et dites "au revoir" aux vieilles cases à cocher "partenaires".',
    icon: 'icon_v2_portabilité',
    collection: 'portability',
    templates: true,
    isversioned: true,
    monthlyPrice: 10,
    yearlyPrice: 100,
    planMonthly: 'plan_EK52rY1ao30RDA',
    planYearly: 'plan_EK52mn0VmTfRfM',
    page_title: 'Un encart rassurant pour partager les données',
    page_subtitle: 'Des idées simples pour convaincre vos clients',
    page_features: '<li>Sélection des partenaires</li>\n<li>Rédactionnels convaincants</li>\n<li>Installation en 2 minutes</li>',
    illustration: 'service-icon-portability',
    illustration_width: 600,
    comment_img: 'new_service_logo_4',
    comment_img_size: 600,
    existingItemsTitle: 'Vos widgets de partage de données',
    newItemTitle: 'Créer un nouveau widget',
    no_item_title: 'Vous n\'avez aucune configuration partage de données personnelles.',
    no_item_button: 'Créer une nouvelle configuration',
    isActive: false,
    isPlanActive: false,
    isPlanSlim: true,
    endpoint: 'vault',
    featureToggle: false
  },
  {
    name: 'dpo',
    title: 'Fiche d\'identification pour votre DPO',
    description: 'Créez une fiche d\'identification de votre délégué à la protection des données. Vous pourrez ensuite ajouter un encart sur votre site pour mettre en valeur votre mise en conformité RGPD et offrir un accès simple et direct pour vos utilisateurs.',
    icon: 'icon_v2_DPO',
    collection: 'dpo',
    templates: false,
    isversioned: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    inBundle: 'pro',
    planMonthly: 'plan_EK50B7g5jAMCGd',
    planYearly: 'plan_EK50V8LPUD3TDo',
    page_title: 'Offrez un encart valorisant à votre DPO',
    page_subtitle: 'Un accès simple et direct pour vos utilisateurs',
    page_features: '<li>Profil complet</li>\n<li>Formulaire de réclamation</li>\n<li>Droits détaillés</li>',
    illustration: 'new_service_hover_dpo',
    illustration_width: 600,
    comment_img: 'new_service_logo_1',
    comment_img_size: 600,
    newItemTitle: 'Créer une nouveau widget de DPO',
    no_item_title: 'Vous n\'avez aucune configuration fiche d\'identification DPO.',
    no_item_button: 'Créer une nouvelle configuration',
    newItemDescription: 'Ce widget est une carte d\'identité numérique de votre DPO et vous permet de mettre en avant votre conformité sur votre site.',
    isActive: true,
    isPlanActive: false,
    isPlanSlim: true,
    endpoint: 'vault',
    featureToggle: false
  },
  {
    name: 'privacypolicy',
    title: 'Générateur de politique de confidentialité',
    description: 'Générez facilement votre politique de confidentialité grâce au formulaire simple et ludique conçu par notre avocat Maître Christophe Landat. Rédigée par un professionnel du droit, elle sera qui plus est tout à fait accessible par vos utilisateurs.',
    icon: 'service-icon-privacypolicy',
    paint: 'yellow',
    templates: false,
    isversioned: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    planMonthly: 'plan_EK50il5T7mxm9G',
    planYearly: 'plan_ELW93Mgba1zjLr',
    page_title: 'Générez en ligne votre politique de confidentialité',
    page_subtitle: 'Rédigée et validée par un avocat également Délégué à la Protection des Données Personnelles',
    page_features: '<li>Votre document en 3 minutes</li>\n<li>À partir d’un simple formulaire</li>\n<li>Format éditable (doc) ou PDF</li>',
    illustration: 'service-icon-privacypolicy',
    illustration_width: 300,
    comment_img: 'new_service_logo_2',
    comment_img_size: 600,
    no_item_title: 'Vous n\'avez aucune configuration générateur de politique de confidentialité.',
    no_item_button: 'Créer une nouvelle configuration',
    isActive: false,
    isPlanActive: false,
    isPlanSlim: true,
    endpoint: 'vault',
    featureToggle: false
  },
  {
    name: 'allInOne',
    title: 'All In One',
    description: 'description all in one',
    icon: 'service-icon-privacypolicy',
    paint: 'yellow',
    collection: 'all-in-one',
    templates: true,
    isversioned: true,
    page_title: 'titre all in one\n',
    page_subtitle: 'soustitre all in one',
    page_features: 'feature all in one',
    illustration: 'service-icon-privacypolicy',
    illustration_width: 300,
    comment_img: 'new_service_logo_3',
    comment_img_size: 600,
    no_item_title: 'Vous n\'avez aucune configuration All In One.',
    no_item_button: 'Créer une nouvelle configuration',
    isActive: true,
    isPlanActive: false,
    featureToggle: false
  },
  {
    name: 'shake',
    title: 'Shake',
    page_subtitle: 'spatchChange(\'save\', service.name);',
    isActive: false,
    labelName: 'Shake',
    labelColor: '#E6EEFF',
    labelIcon: 'product_icon_shake',
    comingSoon: true,
    comingSoonLabel: 'Bientôt dispo',
    comingSoonText: '<b>Votre scanner de conformité Shake arrive bientôt !<br /><br />D’ici peu, vous pourrez profiter d’un nouvel outil qui va faire du bien à vos bandeaux cookies !</b> ',
    newIllustration: 'product_shake_fr',
    bgColor: '#A5BFF1',
    newTitle: 'Mes scans de conformité',
    newDescription: 'Scanner et actualiser mes bandeaux cookies pour garder ma conformité à jour.',
    featureToggle: false
  },
  {
    name: 'tcf',
    title: 'Bandeau de cookies TCF',
    description: 'TCF for publishers',
    icon: 'icon_v2_cookie_happy',
    collection: 'tcf',
    templates: true,
    illustration: 'new_service_hover_cookies_v2',
    illustration_width: 600,
    comment_img: 'new_service_logo_1',
    comment_img_size: 600,
    existingItemsTitle: 'Configurations existantes',
    newItemTitle: 'Nouveau traitement ',
    no_item_title: 'Vous n\'avez aucune configuration TCF.',
    no_item_button: 'Créer une nouvelle configuration',
    newItemDescription: 'Vous avez la possibilité de créer autant de versions de configuration que vous le souhaitez.',
    isActive: false,
    isActiveV2: true,
    labelName: 'Publishers',
    labelColor: '#E6EEFF',
    labelIcon: 'product_icon_cookie',
    newIllustration: 'product_publishers_fr',
    bgColor: '#A5BFF1',
    newTitle: 'Mes bandeaux cookies TCF',
    newDescription: 'Une version adaptée pour les sites avec de la publicité, compatible TCF v2.2 de l\'IAB.',
    order: 2,
    featureToggle: false
  }
];

export default services;
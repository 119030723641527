import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import FR from './data/FR/index.js';
import EN from './data/EN/index.js';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  lng: 'en',
  resources: {
    en: {
      translation: EN.adminStrings
    },
    fr: {
      translation: FR.adminStrings
    }
  },

  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

export default i18n;

const taxIds = [
  {
    country: 'United Arab Emirates',
    countryCode: 'AE',
    type: 'ae_trn',
    name: 'TRN',
    pattern: 123456789012345,
    description: 'TRN — United Arab Emirates'
  },
  {
    country: 'Australia',
    countryCode: 'AU',
    type: 'au_abn',
    name: 'AU ABN',
    pattern: 12345678912,
    description: 'AU ABN — Australia'
  },
  {
    country: 'Brazil',
    countryCode: 'BR',
    type: 'br_cnpj',
    name: 'CNPJ',
    pattern: '01.234.456/5432-10',
    description: 'CNPJ — Brazil'
  },
  {
    country: 'Brazil',
    countryCode: 'BR',
    type: 'br_cpf',
    name: 'CPF',
    pattern: '123.456.789-87',
    description: 'CPF — Brazil'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    type: 'ca_bn',
    name: 'BN',
    pattern: 123456789,
    description: 'BN — Canada'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    type: 'ca_qst',
    name: 'QST',
    pattern: '1234567890TQ1234',
    description: 'QST — Canada'
  },
  {
    country: 'Switzerland',
    countryCode: 'CH',
    type: 'ch_vat',
    name: 'TVA',
    pattern: 'CHE-123.456.789 MWST',
    description: 'TVA — Switzerland'
  },
  {
    country: 'Chile',
    countryCode: 'CL',
    type: 'cl_tin',
    name: 'TIN',
    pattern: '12.345.678-K',
    description: 'TIN — Chile'
  },
  {
    country: 'Spain',
    countryCode: 'ES',
    type: 'es_cif',
    name: 'CIF',
    pattern: 'A12345678',
    description: 'CIF — Spain'
  },
  {
    country: 'Austria',
    countryCode: 'AT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'ATU12345678',
    description: 'TVA — Austria'
  },
  {
    country: 'Belgium',
    countryCode: 'BE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'BE0123456789',
    description: 'TVA — Belgium'
  },
  {
    country: 'Bulgaria',
    countryCode: 'BG',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'BG0123456789',
    description: 'TVA — Bulgaria'
  },
  {
    country: 'Cyprus',
    countryCode: 'CY',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'CY12345678Z',
    description: 'TVA — Cyprus'
  },
  {
    country: 'Czech Republic',
    countryCode: 'CZ',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'CZ1234567890',
    description: 'TVA — Czech Republic'
  },
  {
    country: 'Germany',
    countryCode: 'DE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'DE123456789',
    description: 'TVA — Germany'
  },
  {
    country: 'Denmark',
    countryCode: 'DK',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'DK12345678',
    description: 'TVA — Denmark'
  },
  {
    country: 'Estonia',
    countryCode: 'EE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'EE123456789',
    description: 'TVA — Estonia'
  },
  {
    country: 'Spain',
    countryCode: 'ES',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'ESA1234567Z',
    description: 'TVA — Spain'
  },
  {
    country: 'Finland',
    countryCode: 'FI',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'FI12345678',
    description: 'TVA — Finland'
  },
  {
    country: 'France',
    countryCode: 'FR',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'FRAB123456789',
    description: 'TVA — France'
  },
  {
    country: 'United Kingdom',
    countryCode: 'GB',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'GB123456789',
    description: 'TVA — United Kingdom'
  },
  {
    country: 'Greece',
    countryCode: 'GR',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'EL123456789',
    description: 'TVA — Greece'
  },
  {
    country: 'Croatia',
    countryCode: 'HR',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'HR12345678912',
    description: 'TVA — Croatia'
  },
  {
    country: 'Hungary',
    countryCode: 'HU',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'HU12345678912',
    description: 'TVA — Hungary'
  },
  {
    country: 'Ireland',
    countryCode: 'IE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'IE1234567AB',
    description: 'TVA — Ireland'
  },
  {
    country: 'Italy',
    countryCode: 'IT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'IT12345678912',
    description: 'TVA — Italy'
  },
  {
    country: 'Lithuania',
    countryCode: 'LT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'LT123456789123',
    description: 'TVA — Lithuania'
  },
  {
    country: 'Luxembourg',
    countryCode: 'LU',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'LU12345678',
    description: 'TVA — Luxembourg'
  },
  {
    country: 'Latvia',
    countryCode: 'LV',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'LV12345678912',
    description: 'TVA — Latvia'
  },
  {
    country: 'Malta',
    countryCode: 'MT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'MT12345678',
    description: 'TVA — Malta'
  },
  {
    country: 'Netherlands',
    countryCode: 'NL',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'NL123456789B12',
    description: 'TVA — Netherlands'
  },
  {
    country: 'Poland',
    countryCode: 'PL',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'PL1234567890',
    description: 'TVA — Poland'
  },
  {
    country: 'Portugal',
    countryCode: 'PT',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'PT123456789',
    description: 'TVA — Portugal'
  },
  {
    country: 'Romania',
    countryCode: 'RO',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'RO1234567891',
    description: 'TVA — Romania'
  },
  {
    country: 'Sweden',
    countryCode: 'SE',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'SE123456789123',
    description: 'TVA — Sweden'
  },
  {
    country: 'Slovenia',
    countryCode: 'SI',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'SI12345678',
    description: 'TVA — Slovenia'
  },
  {
    country: 'Slovakia',
    countryCode: 'SK',
    type: 'eu_vat',
    name: 'TVA',
    pattern: 'SK1234567891',
    description: 'TVA — Slovakia'
  },
  {
    country: 'Hong Kong',
    countryCode: 'HK',
    type: 'hk_br',
    name: 'BR',
    pattern: 12345678,
    description: 'BR — Hong Kong'
  },
  {
    country: 'Indonesia',
    countryCode: 'ID',
    type: 'id_npwp',
    name: 'NPWP',
    pattern: '12.345.678.9-012.345',
    description: 'NPWP — Indonesia'
  },
  {
    country: 'India',
    countryCode: 'IN',
    type: 'in_gst',
    name: 'GST',
    pattern: '12ABCDE3456FGZH',
    description: 'GST — India'
  },
  {
    country: 'Japan',
    countryCode: 'JP',
    type: 'jp_cn',
    name: 'Hōjin Bangō',
    pattern: 1234567891234,
    description: 'Hōjin Bangō — Japan'
  },
  {
    country: 'Korea, Republic of',
    countryCode: 'KR',
    type: 'kr_brn',
    name: 'BRN',
    pattern: '123-45-67890',
    description: 'BRN — Korea, Republic of'
  },
  {
    country: 'Liechtenstein',
    countryCode: 'LI',
    type: 'li_uid',
    name: 'UID',
    pattern: 'CHE123456789',
    description: 'UID — Liechtenstein'
  },
  {
    country: 'Mexico',
    countryCode: 'MX',
    type: 'mx_rfc',
    name: 'RFC',
    pattern: 'ABC010203AB9',
    description: 'RFC — Mexico'
  },
  {
    country: 'Malaysia',
    countryCode: 'MY',
    type: 'my_frp',
    name: 'FRP',
    pattern: 12345678,
    description: 'FRP — Malaysia'
  },
  {
    country: 'Malaysia',
    countryCode: 'MY',
    type: 'my_itn',
    name: 'ITN',
    pattern: 'C 1234567890',
    description: 'ITN — Malaysia'
  },
  {
    country: 'Malaysia',
    countryCode: 'MY',
    type: 'my_sst',
    name: 'SST',
    pattern: 'A12-3456-78912345',
    description: 'SST — Malaysia'
  },
  {
    country: 'Norway',
    countryCode: 'NO',
    type: 'no_vat',
    name: 'VAT',
    pattern: '123456789MVA',
    description: 'VAT — Norway'
  },
  {
    country: 'New Zealand',
    countryCode: 'NZ',
    type: 'nz_gst',
    name: 'GST',
    pattern: 123456789,
    description: 'GST — New Zealand'
  },
  {
    country: 'Russian Federation',
    countryCode: 'RU',
    type: 'ru_inn',
    name: 'INN',
    pattern: 1234567891,
    description: 'INN — Russian Federation'
  },
  {
    country: 'Saudi Arabia',
    countryCode: 'SA',
    type: 'sa_vat',
    name: 'VAT',
    pattern: 123456789012345,
    description: 'VAT — Saudi Arabia'
  },
  {
    country: 'Singapore',
    countryCode: 'SG',
    type: 'sg_gst',
    name: 'GST',
    pattern: 'M12345678X',
    description: 'GST — Singapore'
  },
  {
    country: 'Singapore',
    countryCode: 'SG',
    type: 'sg_uen',
    name: 'UEN',
    pattern: '123456789F',
    description: 'UEN — Singapore'
  },
  {
    country: 'Thailand',
    countryCode: 'TH',
    type: 'th_vat',
    name: 'VAT',
    pattern: 1234567891234,
    description: 'VAT — Thailand'
  },
  {
    country: 'Taiwan',
    countryCode: 'TW',
    type: 'tw_vat',
    name: 'VAT',
    pattern: 12345678,
    description: 'VAT — Taiwan'
  },
  {
    country: 'United States',
    countryCode: 'US',
    type: 'us_ein',
    name: 'EIN',
    pattern: '12-3456789',
    description: 'EIN — United States'
  },
  {
    country: 'South Africa',
    countryCode: 'ZA',
    type: 'za_vat',
    name: 'VAT',
    pattern: 4123456789,
    description: 'VAT — South Africa'
  }
];

export default taxIds;
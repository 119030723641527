import React, { Component } from 'react';
import createContextHOC from './createContextHOC';
import { withAccess } from './AccessContext';
import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';
import PublishErrorsModal from 'src/components/AxeptioAdmin/PublishErrorsModal';
import { progress } from 'src/utils/projects';
import ButtonText from 'src/components/ButtonText/ButtonText';
import Toast from 'src/components/Toast/Toast';
import languages from '@cospired/i18n-iso-languages';
import countries from 'i18n-iso-countries/index.js';
import { onGoingOrIncompleteStripeSubscriptionStatus } from '../base/billingHelpers';
import { useTranslation } from 'react-i18next';

countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
languages.registerLocale(require(`@cospired/i18n-iso-languages/langs/fr.json`));
languages.registerLocale(require(`@cospired/i18n-iso-languages/langs/en.json`));

const ProjectContext = React.createContext({ publishErrors: [], project: {} });

const ProjectManager = withAccess(
  class extends Component {
    state = {
      isFetched: false,
      projectId: this.props.projectId,
      projectUsers: [],
      project: {},
      projectOrganization: null,
      projectMetadata: {},
      projectGroups: [],
      projectAccessMapping: null,
      accessToInvoices: null,
      accessToSubscription: null,
      fetch: cb => {
        this.props.api.getProject(this.props.projectId, { embed: ['organization'], with: ['metadata'] }, async project => {
          if (!project) {
            window.location.href = '/';
            return this.props.api.signout();
          }
          const organization = project.organization?._id ? { ...project.organization } : null;
          delete project.organization;
          const metadata = project.metadata || {};
          delete project.metadata;

          if (!Array.isArray(window.dataLayer)) {
            window.dataLayer = [];
          }

          const createdDate = new Date(project.createdAt);
          const now = new Date();
          const oneDay = 1000 * 60 * 60 * 24; // One day in milliseconds
          const diffTime = Math.abs(now - createdDate);
          const diffDays = Math.ceil(diffTime / oneDay);
          const projectSubscription = project.billing?.subscription || project.thirdPartyBilling?.subscription;

          window.dataLayer.push({
            project: {
              created_at: createdDate.toISOString().substring(0, 10),
              from: diffDays,
              id: project.id,
              organization_id: organization?._id || null,
              project_subscription_status: projectSubscription?.status || null,
              organization_subscription_status: organization?.subscription?.status || null
            }
          });
          const projectAccessMapping = await this.props.api.getProjectAccessMapping(this.props.projectId).response;
          const { accessToInvoices, accessToSubscription } = this.getInvoicesAndSubscriptionAccess(project, organization);
          this.setState(
            {
              project,
              isFetched: true,
              projectOrganization: organization,
              projectMetadata: metadata,
              projectAccessMapping,
              projectGroups: project.groups || [],
              groupsQueryParams: [...new Set([...(project.groups || []), `project_${this.props.projectId}`])].join(','),
              accessToInvoices,
              accessToSubscription
            },
            () => {
              this.props.setProjectForAccess(project, projectAccessMapping, cb);
            }
          );
        });
      },
      fetchUsers: cb => {
        if (!this.props.projectId) {
          return;
        }
        this.props.api.getProjectUsers(this.props.projectId, projectUsers => {
          this.setState({ projectUsers }, cb);
        });
      },
      fetchPublishJob: cb => {
        if (!this.props.projectId) {
          return;
        }
        const safeCb = typeof cb === 'function' ? cb : () => {};
        this.props.api.client
          .get(`${this.props.api.baseURL}/publish/jobs/${this.props.projectId}`)
          .then(response => {
            if (response.data.length > 0) {
              this.setState({ publishJob: response.data[0] }, () => {
                this.poll();
                safeCb(this.state.publishJob);
              });
            } else {
              safeCb(null);
            }
          })
          .catch(err => {
            this.props.api.logError(err);
            safeCb(null);
          });
      },
      patch: (diff, cb) => {
        this.props.api.patch('projects', this.props.projectId, diff, project => this.setState({ project }, cb));
      },
      errors: [],
      clearPublishErrors: () => {
        this.setState({ errors: [] });
      },
      publishErrors: [],
      /**
       * Will return the list of configurations for a specific service
       * @param {string} service
       * @returns {Object[]}
       */
      fetchConfigurations: service => {
        const { api, projectId } = this.props;
        const { response, cancel } = api.get(`${api.baseURL}/vault/${service}?data.projectId=${projectId}`);
        return {
          response: response.then(response => response?.data),
          cancel
        };
      },
      fetchUnpublishedVersions: service => {
        const { api, projectId } = this.props;
        return api.client
          .get(`${api.baseURL}/app/projects/${projectId}/${service}:get-unpublished-versions-since-latest`)
          .then(response => response.data);
      },
      publish: (params = {}, cb) => {
        if (!this.props.projectId) {
          return;
        }

        const payload = { ...params };
        this.props.api.client
          .post(`${this.props.api.baseURL}/publish/jobs/${this.props.projectId}`, payload)
          .then(response => {
            if (!Array.isArray(window.dataLayer)) {
              window.dataLayer = [];
            }

            window.dataLayer.push({
              event: 'project_published'
            });

            this.setState({ publishJob: response.data }, () => {
              this.poll(cb);
            });
          })
          .catch(err => {
            this.props.api.logError(err);
            this.setState({
              errors: [err.response.data.message || err.message]
            });
          });
      },
      cancelPublish: cb => {
        const safeCb = typeof cb === 'function' ? cb : () => {};
        if (!this.props.projectId) {
          return safeCb();
        }
        this.props.api.client.delete(`${this.props.api.baseURL}/publish/jobs/${this.props.projectId}`).then(() => {
          this.setState({ publishJob: null, publishErrors: [] }, safeCb);
        });
      }
    };

    componentDidMount() {
      this.props.api.onChange(this.onApiChange);
      if (!this.state.isFetched && this.props.projectId) {
        this.state.fetch();
        this.state.fetchPublishJob();
      }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
      if (!this.props.projectId) {
        if (prevProps.projectId) {
          this.props.setProjectForAccess({}, null, () => {
            this.setState({
              project: {},
              isFetched: false,
              projectUsers: [],
              publishErrors: [],
              projectId: null,
              projectOrganization: null,
              projectAccessMapping: null
            });
          });
        }
        return;
      }
      if (this.props.projectId !== prevProps.projectId) {
        this.setState({ projectId: this.props.projectId, isFetched: false }, () => {
          this.state.fetch();
          this.state.fetchPublishJob();
        });
      }
      if (
        this.props.projectId &&
        this.state.project &&
        this.state.isFetched &&
        (JSON.stringify(this.state.project.billing?.subscription) !== JSON.stringify(prevState.project.billing?.subscription) ||
          this.state.project.organizationId !== prevState.project?.organizationId ||
          this.props.projectId !== prevProps.projectId ||
          !this.state.projectAccessMapping)
      ) {
        const projectAccessMapping = await this.props.api.getProjectAccessMapping(this.props.projectId).response;
        this.props.setProjectForAccess(this.state.project, projectAccessMapping, () => this.setState({ projectAccessMapping }));
      }
    }

    componentWillUnmount() {
      this.props.api.removeChangeHandler(this.onApiChange);
    }

    onApiChange = (event, payload) => {
      if (event === 'project' && payload.id === this.props.projectId) {
        this.setState({ project: { ...this.state.project, ...payload } });
      }
    };

    poll(cb) {
      const end = () => (typeof cb === 'function' ? cb() : null);
      if (!this.state.publishJob) {
        return end();
      }
      this.props.api.client.get(this.state.publishJob.links.self).then(response => {
        this.setState({ publishJob: response.data }, () => {
          const { status } = this.state.publishJob;
          if (status && status !== 'publishFile_success' && status !== 'complete' && status.indexOf('error') === -1) {
            setTimeout(() => this.poll(cb), response.data.timeout || 500);
          } else {
            if (status === 'errored') {
              this.setState({ publishErrors: response.data.reason });
            }
            return end();
          }
        });
      });
    }

    /**
     *
     * @param {Object}  project
     * @param {Object?} organization
     * @returns {{accessToInvoices: boolean, accessToSubscription: boolean}}
     */
    getInvoicesAndSubscriptionAccess(project, organization) {
      if (project.marketplaceWidgetIntegration) {
        return { accessToSubscription: false, accessToInvoices: false };
      }

      if (!organization) {
        return { accessToSubscription: true, accessToInvoices: Boolean(project.billing?.subscription?.status) };
      }

      const projectHasSub = () => onGoingOrIncompleteStripeSubscriptionStatus.includes(project.billing?.subscription?.status);
      const orgHasSub = () => onGoingOrIncompleteStripeSubscriptionStatus.includes(organization.subscription?.status);
      const orgaIsAgencyOrEnterprise = () =>
        ['agency', 'enterprise'].includes(organization.customer?.metadata.customerType) ||
        organization.subscription.items.data.find(
          item =>
            item.price.product === process.env.REACT_APP_AGENCY_METERED_PRODUCT ||
            item.price.product === process.env.REACT_APP_ENTERPRISE_METERED_PRODUCT
        );

      if (!projectHasSub() && orgHasSub() && orgaIsAgencyOrEnterprise()) {
        return { accessToSubscription: false, accessToInvoices: Boolean(project.billing?.subscription?.status) };
      }

      return { accessToSubscription: true, accessToInvoices: true };
    }

    render() {
      return (
        <ProjectContext.Provider value={this.state}>
          {!this.state.isFetched && this.props.projectId && <LoadingIndicator absolute />}
          {this.props.children}
        </ProjectContext.Provider>
      );
    }
  }
);
const getProps = projectMgrState => {
  return {
    isProjectFetched: projectMgrState.isFetched,
    project: projectMgrState.project,
    // used
    projectId: projectMgrState.projectId,
    // not used
    projectGroups: projectMgrState.projectGroups,
    // used
    projectOrganization: projectMgrState.projectOrganization,
    // used
    projectMetadata: projectMgrState.projectMetadata,
    // used
    projectUsers: projectMgrState.projectUsers,
    // used in Axeptio admin to empty the error messages
    clearPublishErrors: projectMgrState.clearPublishErrors.bind(projectMgrState),
    // used in AxeptioAdmin to display toast error messages
    publishErrors: projectMgrState.publishErrors,
    // used in the withEditor HOC
    fetchProjectUsers: projectMgrState.fetchUsers.bind(projectMgrState),
    // used in PublishButton
    startProjectPublish: projectMgrState.publish.bind(projectMgrState),
    // used in PulishButton
    fetchUnpublishedVersions: projectMgrState.fetchUnpublishedVersions.bind(projectMgrState),
    fetchConfigurations: projectMgrState.fetchConfigurations.bind(projectMgrState),
    // used in ContractV2
    onProjectFetchPublishJob: projectMgrState.fetchPublishJob.bind(projectMgrState),
    // used in VendorForm and Service
    groupsQueryParams: projectMgrState.groupsQueryParams,
    projectAccessMapping: projectMgrState.projectAccessMapping,
    accessToInvoices: projectMgrState.accessToInvoices,
    accessToSubscription: projectMgrState.accessToSubscription,
    fetchProject: projectMgrState.fetch
  };
};

function ProjectErrorsToast({ projectCtx }) {
  return projectCtx.errors.length > 0 ? (
    <Toast status="error" title={projectCtx.errors.join(', ')} onClose={() => projectCtx.clearPublishErrors()} />
  ) : null;
}

function PublishJobToast({ projectCtx, state, handleToastClose }) {
  const { t } = useTranslation();
  return projectCtx.publishJob && projectCtx.publishJob._id !== state.hiddenJobId && projectCtx.publishErrors?.length === 0 ? (
    <Toast
      message={projectCtx.publishJob.status}
      actions={
        [
          'complete',
          'publishFile_success',
          'putFile_error',
          'publishFile_error',
          'invalidateFile_error',
          'checkInvalidation_error'
        ].includes(projectCtx.publishJob.status) === false ? (
          <ButtonText onClick={projectCtx.cancelPublish}>{t('publish_button_cancel')}</ButtonText>
        ) : null
      }
      progress={progress(projectCtx.publishJob.status)}
      onClose={() => handleToastClose(projectCtx.publishJob._id)}
    />
  ) : null;
}

function PublishErrorsModalWrapper({ projectCtx, locale, api }) {
  return projectCtx.publishErrors?.length > 0 && window.location.search.indexOf('disable_publish_error') === -1 ? (
    <PublishErrorsModal
      onClose={projectCtx.cancelPublish}
      errors={projectCtx.publishErrors}
      projectId={projectCtx.projectId}
      project={projectCtx.project}
      projectOrganization={projectCtx.projectOrganization}
      locale={locale}
      api={api}
    />
  ) : null;
}

const withProjectContext = createContextHOC(ProjectContext, getProps);
export { withProjectContext, ProjectManager, ProjectErrorsToast, PublishJobToast, PublishErrorsModalWrapper };
export default ProjectContext;

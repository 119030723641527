import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useApi } from '../../contexts/ApiContext';
import CheckboxRadioCard from '../CheckboxRadioCard/CheckboxRadioCard';
import { addToast } from '../Toast/Toast';
import Footer from './Footer';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 20px;

  p {
    margin: 0px;
  }
`;
const CheckboxRadioContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
const LoaderContainer = styled.div`
  margin: 96px 0px;
`;
const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  &:after {
    content: '*';
    color: red;
    margin-left: 4px;
  }
`;
const Error = styled.span`
  font-size: 9px;
  color: red;
`;

const UserCompanyFieldStep = ({ footerCount, currentStep, onBack, onNext }) => {
  const { t } = useTranslation();
  const api = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isFieldForm, setIsFieldForm] = useState(false);
  const [fieldSelected, setFieldSelected] = useState(null);
  const [fieldError, setFieldError] = useState(null);

  const onSubmit = async event => {
    event.preventDefault();
    if (!fieldSelected) {
      setFieldError(t.field_required);
    } else if (!isLoading) {
      setIsLoading(true);
      await api
        .updateUser({ 'data.company.field': fieldSelected })
        .then(() => onNext())
        .catch(error => addToast.error(error.message));
      setIsLoading(false);
    }
  };

  const getUser = useCallback(async () => {
    await api
      .getUser(e => {
        setIsFieldForm(e.data.company.type === 'enterprise');
        setIsLoadingUser(false);
      })
      ?.catch(error => addToast.error(error.message));
  }, [api]);

  const industryFields = [
    {
      value: 'Publishers & Media',
      title: t('onboarding_modal_company_field_of_industry_publishers'),
      image: 'icon_v2_cookie_mobile'
    },
    { value: 'Real Estate', title: t('onboarding_modal_company_field_of_industry_real_estate'), image: 'money' },
    {
      value: 'Technology and Software Publishers',
      title: t('onboarding_modal_company_field_of_industry_tech'),
      image: 'tech'
    },
    { value: 'Childhood', title: t('onboarding_modal_company_field_of_industry_children'), image: 'children' },
    {
      value: 'Insurance & Financial Sector',
      title: t('onboarding_modal_company_field_of_industry_insurance'),
      image: 'shield'
    },
    { value: 'Sports', title: t('onboarding_modal_company_field_of_industry_sport'), image: 'sport' },
    { value: 'Health & Pharmaceuticals', title: t('onboarding_modal_company_field_of_industry_health'), image: 'health' },
    {
      value: 'Telecommunications',
      title: t('onboarding_modal_company_field_of_industry_telecom'),
      image: 'icon-consentement-phone'
    },
    { value: 'Consumer Goods', title: t('onboarding_modal_company_field_of_industry_consumer_goods'), image: 'consumer_goods' },
    { value: 'Tourism/Travel', title: t('onboarding_modal_company_field_of_industry_tourism'), image: 'travel' },
    {
      value: 'Culture & Entertainment',
      title: t('onboarding_modal_company_field_of_industry_culture'),
      image: 'icon_v2_favorite'
    },
    {
      value: 'Manufacturing Industry',
      title: t('onboarding_modal_company_field_of_industry_manufacturing'),
      image: 'manufacturing'
    },
    {
      value: 'Retail & E-commerce',
      title: t('onboarding_modal_company_field_of_industry_retail'),
      image: 'icon_v2_tag_managment'
    },
    { value: 'Agriculture', title: t('onboarding_modal_company_field_of_industry_agriculture'), image: 'agriculture' },
    { value: 'Education', title: t('onboarding_modal_company_field_of_industry_education'), image: 'education' },
    { value: 'Food and Bevarages', title: t('onboarding_modal_company_field_of_industry_food'), image: 'food' },
    { value: 'ONG', title: t('onboarding_modal_company_field_of_industry_npo'), image: 'ong' },
    { value: 'Other', title: t('onboarding_modal_company_field_of_industry_other'), image: 'music' }
  ];

  const agencyKind = [
    {
      value: 'Digital Marketing (360 agencies)',
      title: t('onboarding_modal_company_agency_kind_digital'),
      image: 'announcement'
    },
    { value: 'SEO', title: t('onboarding_modal_company_agency_kind_seo'), image: 'goals' },
    {
      value: 'Paid acquisition, performance marketing & SEA',
      title: t('onboarding_modal_company_agency_kind_paid_acquisition'),
      image: 'money'
    },
    { value: 'Data Marketing and BI', title: t('onboarding_modal_company_agency_kind_data'), image: 'icon_v2_statistiques' },
    { value: 'Web development', title: t('onboarding_modal_company_agency_kind_web_dev'), image: 'web_laptop' },
    { value: 'E-commerce development', title: t('onboarding_modal_company_agency_kind_e_commerce'), image: 'icon_v2_buy' },
    { value: 'Analytics and tracking', title: t('onboarding_modal_company_agency_kind_analytics'), image: 'statistics' },
    { value: 'Design (UX / UI / CRO)', title: t('onboarding_modal_company_agency_kind_design'), image: 'icon_v2_project_design' },
    { value: 'CMS/CRM Integrator', title: t('onboarding_modal_company_agency_kind_cms_crm'), image: 'icon_v2_chat2' },
    { value: 'ESN', title: t('onboarding_modal_company_agency_kind_esn'), image: 'manufacturing' },
    { value: 'Freelancers and consultants', title: t('onboarding_modal_company_agency_kind_consultant'), image: 'idea' }
  ];

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <Container onSubmit={onSubmit}>
      <Label>
        {isFieldForm ? t('onboarding_modal_company_type_input_label') : t('onboarding_modal_company_department_input_label')}
      </Label>
      {isLoadingUser ? (
        <LoaderContainer>
          <LoadingIndicator />
        </LoaderContainer>
      ) : (
        <CheckboxRadioContainer>
          {(isFieldForm ? industryFields : agencyKind).map(field => (
            <CheckboxRadioCard
              key={field.value}
              title={field.title}
              name={field.value}
              image={field.image}
              type="radio"
              size="small"
              value={field.value === fieldSelected}
              onChange={() => {
                setFieldError(null);
                setFieldSelected(field.value);
              }}
              width="215px"
            />
          ))}
        </CheckboxRadioContainer>
      )}
      {fieldError && <Error>{fieldError}</Error>}
      <Footer count={footerCount} currentStep={currentStep} onBack={() => onBack()} isLoading={isLoading} />
    </Container>
  );
};

export default UserCompanyFieldStep;

import styled from 'styled-components';
import BulletSteps from '../BulletSteps/BulletSteps';
import Button from '../Button/Button';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BackButton = styled(Button)`
  &:disabled {
    visibility: hidden;
  }
`;

const Footer = ({ onBack, onNext, count, currentStep, isLoading }) => {
  return (
    <Container>
      <BackButton type="button" onClick={onBack} disabled={currentStep === 0}>
        Back
      </BackButton>
      <BulletSteps count={count} currentStep={currentStep} />
      <Button primary type="submit" blackFont onClick={onNext}>
        {isLoading ? <LoadingIndicator small color="white" /> : 'Confirm'}
      </Button>
    </Container>
  );
};

export default Footer;

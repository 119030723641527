import React, { Component } from 'react';
import update from 'immutability-helper';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { parallel } from 'async';
import { withLocaleContext } from '../../contexts/LocaleContext';
import { debounce } from 'throttle-debounce';
import { InputGroup, Label } from '../Input/Input';
import { withProjectContext } from '../../contexts/ProjectContext';
import Localization from '@axeptio/widget-client/src/services/Localization.js';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import LocaleSelect from '../LocaleSelect/LocaleSelect';
import VendorsHydrater from './Services/Cookies/CookiesEditor/VendorsHydrater';
import ConfigUtils from '@axeptio/widget-client/src/services/ConfigUtils.js';
import styled from 'styled-components';
import COLORS from '../../constants/colors';
import { getAllObjectLeaves } from '../../base/helpers';
import {
  sanitizeCountryCode,
  sanitizeLanguageCode,
  sanitizeSubdivisionCode
} from '@axeptio/widget-client/src/utils/templateEngine.js';
import AutoSaveMessage from '../AutoSaveMessage/AutoSaveMessage';
import { withTranslation } from 'react-i18next';

function withEditor(WrappedComponent, errorManagement = false) {
  class WithEditor extends Component {
    constructor(props) {
      super(props);
      const defaultConfig = this.props.defaultValue || {};
      this.state = {
        item: { data: defaultConfig },
        localePack: Localization.getDefaultLanguagePack(),
        availableLanguages: [],
        availableLocales: {},
        languageHasLocales: false,
        configLocale: this.buildConfigLocale(defaultConfig.language, defaultConfig.country, defaultConfig.subdivision),
        regulation: {},
        regulationFeatures: {},
        regulationDefaultValues: {},
        errors: null,
        isModified: false,
        isFetched: false,
        isFetching: false,
        isMounted: false
      };
    }

    componentDidMount() {
      this.setState({ isMounted: true }, async () => {
        this.fetch();
        this.props.fetchProjectUsers();
        await this.getAvailableLanguages();
        await this.getAvailableLocales();
        this.save = debounce(process.env.REACT_APP_AUTOSAVE_DEBOUNCE_TIMER, false, this.save);
      });
    }

    componentWillUnmount() {
      this.setState({ isMounted: false });
    }

    componentDidUpdate(prevProps, prevState) {
      if (!this.props.projectId) {
        return;
      }
      if (
        prevProps.projectId !== this.props.projectId ||
        prevProps.id !== this.props.id ||
        prevProps.service.name !== this.props.service.name
      ) {
        if (this.state.isMounted) {
          this.fetch();
        }
      } else {
        const isModified = this.isModified();
        if (isModified && this.state.isFetched) {
          if (errorManagement && this.state.errors) {
            if (!this.state.isError && !this.state.isModified) {
              this.setState({
                isError: true,
                isModified: true
              });
            }
          } else {
            if (!this.state.isModified) {
              this.setState({
                isModified: true
              });
            }
          }
          this.save();
        }
      }
      if (
        prevState.item.data.language !== this.state.item.data.language ||
        prevState.item.data.country !== this.state.item.data.country ||
        prevState.item.data.subdivision !== this.state.item.data.subdivision
      ) {
        this.setState({
          configLocale: this.buildConfigLocale(
            this.state.item.data.language,
            this.state.item.data.country,
            this.state.item.data.subdivision
          )
        });
      }
    }

    isModified() {
      return !(JSON.stringify(this.state.item.data) === this.state.canonical);
    }

    itemURL() {
      const { service, api, id } = this.props;
      return `${api.baseURL}/${service.endpoint || 'vault'}/${service.collection}/${id}`;
    }

    fetch() {
      if (this.state.isFetching || !this.state.isMounted) {
        return;
      }
      this.setState({ isFetching: true }, () => {
        parallel([cb => this.fetchConfig(cb), cb => this.fetchConfigUsers(cb)], () =>
          this.setState({ isFetched: true, isFetching: false })
        );
      });
    }

    fetchConfig(cb) {
      this.props.updateFieldBreadCrumb('ConfigName', '...');
      this.props.api.client
        .get(this.itemURL())
        .then(async response => {
          const remoteItem = response.data;
          // new Versioned Docs service has the content at ti
          const remoteItemData = this.props.service.endpoint === 'content' ? remoteItem : remoteItem.data;
          const config = await this.cleanConfiguration(Object.assign({}, this.props.defaultValue || {}, remoteItemData));
          const newState = update(this.state, {
            item: { data: { $merge: config } },
            isModified: { $set: false }
          });
          newState.canonical = JSON.stringify(newState.item.data);
          this.setState(newState, async () => {
            await this.updateLocalePack();
            await this.updateRegulationData();
            this.props.updateFieldBreadCrumb('ConfigName', newState.item.data.title || this.props.service.name);
            cb();
          });
        })
        .catch(err => {
          console.error(err);
          //config not found
          window.location.pathname = '/404';
        });
    }

    buildConfigLocale(language, country, subdivision) {
      let configLocale;
      switch (true) {
        case country !== undefined && subdivision === undefined:
          configLocale = `${language}-${country}`;
          break;
        case country !== undefined && subdivision !== undefined:
          configLocale = `${language}-${country}-${subdivision}`;
          break;
        default:
          configLocale = language;
      }
      return configLocale;
    }

    fetchConfigUsers(cb) {
      this.props.api.client
        .get(`${this.itemURL()}/users`)
        .then(response => {
          this.setState({ itemUsers: response.data }, cb);
        })
        .catch(err => {
          console.error('Could not fetch item users', err);
          cb();
        });
    }

    async getAvailableLanguages() {
      const { api, service } = this.props;
      const response = await api.getAvailableLanguages(service.name);
      this.setState({ availableLanguages: response || [] });
    }

    async getAvailableLocales() {
      const { api, service } = this.props;
      const response = await api.getAvailableLocales(service.name);
      this.setState({ availableLocales: response || {} });
    }

    save() {
      const { api, service } = this.props;
      const oldCanonical = this.state.canonical;
      this.setState(
        {
          canonical: JSON.stringify(this.state.item.data)
        },
        () => {
          api.client
            .put(this.itemURL(), this.state.item.data)
            .then(response => {
              api.dispatchChange('save', service.name);
              this.setState(
                update(this.state, {
                  item: { data: { $set: response.data.data } },
                  errors: { $set: null },
                  isModified: { $set: false },
                  isError: { $set: false }
                })
              );
            })
            .catch(err => {
              if (errorManagement) {
                this.setState({ canonical: oldCanonical, errors: err?.response?.data?.errors, isError: true });
              } else {
                this.setState({ canonical: oldCanonical });
              }
            });
        }
      );
    }

    async delete(cb) {
      return new Promise((resolve, reject) => {
        const { api, service } = this.props;
        api.client
          .delete(this.itemURL())
          .then(() => {
            api.dispatchChange('delete', service.name);
            if (typeof cb === 'function') {
              cb();
            }
            return resolve();
          })
          .catch(reject);
      });
    }

    addUser(userId, displayName = null) {
      const payload = {
        roles: ['owner'],
        userId: userId,
        displayName
      };
      this.props.api.client.post(`${this.itemURL()}/users`, payload).then(response => {
        this.setState({ itemUsers: response.data });
      });
    }

    removeUser(userId) {
      this.props.api.client.delete(`${this.itemURL()}/users/${userId}`).then(response => {
        this.setState({ itemUsers: response.data });
      });
    }

    handleLanguageChange() {
      return language => {
        const specs = {
          language: { $set: language },
          country: { $set: undefined },
          subdivision: { $set: undefined }
        };
        this.setState(
          update(this.state, {
            item: { data: { ...specs } }
          }),
          async () => {
            await this.updateLocalePack();
          }
        );
      };
    }

    handleLocaleChange() {
      return locale => {
        const [language, country, subdivision] = locale.split('-');
        const specs = {
          language: { $set: language },
          country: { $set: country },
          subdivision: { $set: subdivision }
        };
        this.setState(
          update(this.state, {
            item: { data: { ...specs } },
            languageHasLocales: { $set: !country && getAllObjectLeaves(this.state.availableLocales).includes(language) }
          }),
          async () => {
            await this.updateLocalePack();
            await this.updateRegulationData();
          }
        );
      };
    }

    async cleanConfiguration(configuration) {
      // Update locale
      configuration.language = sanitizeLanguageCode(configuration.language);
      if (Object.hasOwn(configuration, 'country')) {
        configuration.country = sanitizeCountryCode(configuration.country);
      }
      if (Object.hasOwn(configuration, 'subdivision')) {
        configuration.subdivision = sanitizeSubdivisionCode(configuration.subdivision);
      }
      const serviceStrings = (await this.props.api.getFormStrings()).filter(
        stringEntry =>
          stringEntry.usage === 'service' && (stringEntry.service === this.props.service.name || stringEntry.service === 'shared')
      );
      // get keys for each object to clean
      const stringKeys = serviceStrings.map(stringEntry => stringEntry.name);
      const a11yStringKeys = serviceStrings
        .filter(stringEntry => stringEntry.has_accessibility)
        .map(stringEntry => stringEntry.name);
      const stepStringKeys = serviceStrings
        .filter(stringEntry => stringEntry.editable_in_step)
        .map(stringEntry => stringEntry.name);
      const a11yStepStringKeys = serviceStrings
        .filter(stringEntry => stringEntry.has_accessibility)
        .filter(stringEntry => stringEntry.editable_in_step)
        .map(stringEntry => stringEntry.name);

      // Set default aboutUsId
      if (typeof configuration.aboutUsId !== 'string' && this.props.service.name !== 'tcf') {
        configuration.aboutUsId = 'base';
      }
      delete configuration.aboutUs;
      // Delete unused strings
      if (Object.keys(configuration.strings || {}).length) {
        configuration.strings = Object.fromEntries(
          Object.entries(configuration.strings).filter(([key, value]) => value !== '' && stringKeys.includes(key))
        );
      }

      if (Object.keys(configuration.a11yStrings || {}).length) {
        configuration.a11yStrings = Object.fromEntries(
          Object.entries(configuration.a11yStrings).filter(([key, value]) => value !== '' && a11yStringKeys.includes(key))
        );
      }

      if (!['processings', 'portability'].includes(this.props.service.name) && configuration.consentWidgetStrings) {
        delete configuration.consentWidgetStrings;
      }

      switch (this.props.service.name) {
        case 'cookies':
          // Google Consent Mode v2
          if (!Object.hasOwn(configuration, 'googleConsentMode')) {
            configuration.googleConsentMode = {
              display: undefined,
              position: 'second',
              ads_data_redaction: false,
              url_passthrough: false
            };
          }
          // Delete unused step strings
          configuration.steps.forEach(step => {
            if (step.strings && typeof step.strings === 'object' && !Array.isArray(step.strings)) {
              step.strings = Object.fromEntries(
                Object.entries(step.strings).filter(([key, value]) => value !== '' && stepStringKeys.includes(key))
              );
              if (Object.keys(step.a11yStrings || {}).length) {
                step.a11yStrings = Object.fromEntries(
                  Object.entries(step.a11yStrings).filter(([key, value]) => value !== '' && a11yStepStringKeys.includes(key))
                );
              }
            } else {
              step.strings = {};
            }
            // set allowOptOut in Consent Wall
            if (step.layout === 'consent_wall') {
              if (!Object.hasOwn(step, 'allowOptOut') || typeof step.allowOptOut !== 'boolean') {
                step.allowOptOut = true;
              }
            }
          });
          break;
        case 'tcf':
          if (typeof configuration.googleConsentMode.additional_google_consent_mode !== 'undefined') {
            delete configuration.googleConsentMode.additional_google_consent_mode;
          }
          if (typeof configuration.settings.expirationTtlDays === 'undefined') {
            configuration.settings.expirationTtlDays = this.props.defaultValue.settings.expirationTtlDays;
          }
          if (typeof configuration.googleAdditionalConsent === 'undefined') {
            configuration.googleAdditionalConsent = false;
          }
          if (typeof configuration.aboutUsId === 'undefined') {
            configuration.aboutUsId = this.props.defaultValue.aboutUsId;
          }
          break;
        default:
          break;
      }
      return configuration;
    }

    async configurationHasGoogleVendors(configuration) {
      const { api } = this.props;
      const hydratedConfiguration = await new VendorsHydrater({ api, language: configuration.language }).hydrateConfig(
        configuration
      );
      return new ConfigUtils(hydratedConfiguration).needsConsentModeV2();
    }

    async updateLocalePack() {
      const { api } = this.props;
      const localePack = await Localization.getLocalePackFromApi(
        api,
        this.state.item.data?.language,
        this.state.item.data?.country,
        this.state.item.data?.subdivision
      );

      this.setState({
        localePack
      });
    }

    async updateRegulationData() {
      const { api, service } = this.props;
      const regulation = await api.getRegionRegulation(this.state.item.data?.country, this.state.item.data?.subdivision);
      const regulationFeatures =
        (await api.getRegionRegulationFeatures(this.state.item.data?.country, this.state.item.data?.subdivision))?.[
          service.name
        ] || {};
      const regulationDefaultValues =
        (await api.getRegionRegulationDefaultValues(this.state.item.data?.country, this.state.item.data?.subdivision))?.[
          service.name
        ] || {};
      this.setState({
        regulation,
        regulationFeatures,
        regulationDefaultValues
      });
      // Verify Rules
      let configHasChanged = false;
      const configuration = this.state.item.data;
      switch (service.name) {
        case 'cookies':
          Object.entries(regulationFeatures).forEach(([stepName, stepFeatures]) => {
            if (stepFeatures.exactlyOneOfRejectButtonIsMandatory) {
              let allSteps = configuration.steps;
              if (Array.isArray(configuration.specialSteps)) {
                allSteps = [...configuration.steps, ...configuration.specialSteps];
              }
              allSteps.forEach(step => {
                if (step.layout === stepName) {
                  if (!step.allowOptOut && !step.showContinueWithoutConsent) {
                    step.allowOptOut = regulationDefaultValues[stepName]?.allowOptOut || false;
                    step.showContinueWithoutConsent = regulationDefaultValues[stepName]?.showContinueWithoutConsent || false;
                    step.sameWeightForAcceptAndRejectButtons =
                      regulationDefaultValues[stepName]?.sameWeightForAcceptAndRejectButtons || false;
                    configHasChanged = true;
                  }
                }
              });
            }
          });
          break;
        case 'tcf':
          // Google Consent Mode v2 (Default Values)
          if (regulationDefaultValues.comoV2) {
            if (typeof configuration.googleConsentMode?.display !== 'boolean') {
              configuration.googleConsentMode.display = regulationDefaultValues.comoV2.displayCoMoV2;
              configHasChanged = true;
            }
            if (typeof configuration.googleConsentMode?.ads_data_redaction !== 'boolean') {
              configuration.googleConsentMode.ads_data_redaction = regulationDefaultValues.comoV2.adsDataRedaction;
              configHasChanged = true;
            }
            if (typeof configuration.googleConsentMode?.url_passthrough !== 'boolean') {
              configuration.googleConsentMode.url_passthrough = regulationDefaultValues.comoV2.urlPassthrough;
              configHasChanged = true;
            }
          }
          break;
        default:
          break;
      }

      if (configHasChanged) {
        this.setState(
          update(this.state, {
            item: { data: { $merge: configuration } }
          })
        );
      }
    }

    onUpdateNestedField = (fieldPath, value, cb) => {
      if (fieldPath.length > 0) {
        const item = fieldPath.reverse().reduce(
          (acc, key) => {
            return { [key]: acc };
          },
          { $set: value }
        );
        this.setState(update(this.state, { item: { data: item } }), cb);
      } else {
        cb();
      }
    };

    onDeleteNestedField = (fieldPath, cb) => {
      if (fieldPath.length > 0) {
        const item = fieldPath.reverse().reduce((acc, key, index) => {
          if (index === 0) {
            return { $unset: [key] };
          } else {
            return { [key]: acc };
          }
        }, {});
        this.setState(update(this.state, { item: { data: item } }), cb);
      }
    };

    render() {
      const { t } = this.props;
      return this.state.isFetched && this.props.project ? (
        <>
          <AutoSaveMessage saved={!this.state.isModified} error={this.state.isError}>
            {this.state.isError ? t('auto_save_error') : this.state.isModified ? t('auto_save_saving') : t('auto_save_saved')}
          </AutoSaveMessage>
          <WrappedComponent
            {...this.props}
            errors={this.state.errors}
            item={this.state.item}
            id={this.props.id}
            value={this.state.item.data}
            languagePack={this.state.localePack}
            regulation={this.state.regulation}
            regulationFeatures={this.state.regulationFeatures}
            regulationDefaultValues={this.state.regulationDefaultValues}
            languageHasLocales={this.state.languageHasLocales}
            project={this.props.project}
            projectUsers={this.props.projectUsers}
            itemUsers={this.state.itemUsers}
            isModified={this.isModified}
            handleChange={e =>
              this.setState(
                update(this.state, {
                  item: { data: { [e.target.name]: { $set: e.target.value } } }
                })
              )
            }
            onUpdateSpecs={(specs, cb) => {
              this.setState(
                update(this.state, {
                  item: { data: { ...specs } }
                }),
                cb
              );
            }}
            onUpdateSpecsAsync={async specs => {
              const { api } = this.props;
              let cpState = update(this.state, {
                item: { data: { ...specs } }
              });
              await api.client.put(this.itemURL(), cpState.item.data);
              return;
            }}
            onUpdateField={(fieldName, value, cb) =>
              this.setState(
                update(this.state, {
                  item: { data: { [fieldName]: { $set: value } } }
                }),
                cb
              )
            }
            onUpdateFieldAsync={async (fieldName, value) => {
              const { api } = this.props;
              let cpState = update(this.state, {
                item: { data: { [fieldName]: { $set: value } } }
              });

              await api.client.put(this.itemURL(), cpState.item.data);
              return;
            }}
            onUpdateNestedField={this.onUpdateNestedField}
            onDeleteNestedField={this.onDeleteNestedField}
            onUpdate={(value, cb) =>
              this.setState(
                update(this.state, {
                  item: { data: { $set: value } }
                }),
                cb
              )
            }
            handleUpdateNestedProperty={(propertyPath, event) => {
              switch (event.type) {
                case 'change':
                  this.onUpdateNestedField([...propertyPath, event.target.name], event.target.value);
                  break;
                case 'reset':
                  this.onDeleteNestedField([...propertyPath, event.detail.name]);
                  break;
                case 'delete':
                  this.onUpdateNestedField([...propertyPath, event.detail.name], '');
                  break;
                default:
                  break;
              }
            }}
            onAddUser={(userId, displayName) => this.addUser(userId, displayName)}
            onRemoveUser={userId => this.removeUser(userId)}
            onSave={() => this.save()}
            onDelete={() => this.delete()}
            getLanguageField={() => (
              <InputGroup>
                <Label>{t('cookies_editor_language_label')}</Label>
                <LanguageSelect
                  availableLanguages={this.state.availableLanguages}
                  placeHolder={t('select_language_placeholder')}
                  locale={this.props.locale}
                  value={this.state.item.data.language?.toLowerCase()}
                  onChange={this.handleLanguageChange()}
                />
              </InputGroup>
            )}
            getLocaleField={(otherLanguages = false) => (
              <InputGroup>
                <Label>{t('cookies_editor_language_label')}</Label>
                <LocaleSelect
                  availableLocales={this.state.availableLocales}
                  otherLanguages={otherLanguages}
                  placeHolder={t('select_language_placeholder')}
                  locale={this.props.locale}
                  value={this.state.configLocale}
                  onChange={this.handleLocaleChange()}
                />
              </InputGroup>
            )}
          />
        </>
      ) : (
        <LoadingIndicator />
      );
    }
  }

  WithEditor.displayName = `WithEditor(${getDisplayName(WrappedComponent)})`;
  return withProjectContext(withLocaleContext(withTranslation()(WithEditor)));
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const Error = styled.span`
  color: ${COLORS.RED};
  font-weight: 500;
  margin-top: 5px;
  font-size: 12px;
`;

export function isError(errors, path) {
  return errors?.find(x => x.instancePath === path);
}

export function ErrorComponent({ errors, path }) {
  const error = isError(errors, path);

  if (error) {
    return <Error>{error.message}</Error>;
  }
  return null;
}

export default withEditor;

import axios from 'axios';
import { debounce } from 'throttle-debounce';
import { forEach as forEachAsync } from 'async';
import { browserLocale, isLiteralObject, getRedirectUri, getUserBrowsingLanguage } from './base/helpers';
import dataLayer from './utils/dataLayer';
import { startOfDay, endOfDay } from 'date-fns';
import * as Sentry from '@sentry/browser';

export default class Api {
  statsLocalCache = {};

  constructor(configuration) {
    this.baseURL = configuration.baseURL;
    this.client = axios.create({
      baseURL: configuration.baseURL,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    this.token = params.access_token || localStorage.getItem(Api.AUTH_TOKEN);
    if (params.impersonatedByAdmin === 'true') {
      this.impersonatedByAdmin = true;
    }
    this.initialQueryParams = params;
    this.onChangeHandlers = [];
    this.patch = debounce(process.env.REACT_APP_AUTOSAVE_DEBOUNCE_TIMER, false, this.patch);

    this.customerSupportAllowedUsers = [];
  }

  dispatchChange(event, payload) {
    this.onChangeHandlers.forEach(handler => handler(event, payload));
  }

  onChange(handler) {
    this.onChangeHandlers.push(handler);
  }

  removeChangeHandler(handler) {
    this.onChangeHandlers = this.onChangeHandlers.filter(h => h !== handler);
  }

  /**
   * A cancelable request
   */
  request(method, url, axiosRequestConfig) {
    const controller = new AbortController();

    const response = this.client.request({
      ...axiosRequestConfig,
      url,
      method,
      signal: controller.signal
    });

    return {
      response: response.catch(error => {
        if (axios.isCancel(error)) {
          return null;
        }
        throw error;
      }),
      cancel: () => controller.abort()
    };
  }

  get(url, axiosRequestConfig) {
    return this.request('GET', url, axiosRequestConfig);
  }

  post(url, axiosRequestConfig) {
    return this.request('POST', url, axiosRequestConfig);
  }

  getUser(cb) {
    if (this.token) {
      this.addAuthorizationHeader();
      const params = {};
      const redirectUri = getRedirectUri();
      if (redirectUri) {
        params.redirectURI = redirectUri;
      }

      this.client
        .get('/auth/me', { params })
        .then(async response => {
          this.user = response.data;

          Sentry.setUser({
            email: this.user.email,
            id: this.user._id,
            ip_address: '{{auto}}'
          });

          dataLayer.pushUser(this.user);

          const { email, _id } = this.user;
          this.saveToken();
          try {
            const foundBrowserLanguage = browserLocale()?.split('-').shift();
            if (foundBrowserLanguage && (!this.user.data?.preferredLanguage || !this.user.data?.preferredLocale)) {
              await this.updateUser({
                data: {
                  ...this.user.data,
                  preferredLanguage: this.user.data?.preferredLanguage ?? foundBrowserLanguage,
                  preferredLocale: this.user.data?.preferredLocale ?? browserLocale()
                }
              });
            }
          } catch (e) {
            this.logError(e);
          }

          window.told?.('addHiddenFields', { email, userId: _id, language: getUserBrowsingLanguage() });

          this.handleRedirectUri(redirectUri);
          await this.setCustomerSupportAllowedUsers();
          cb(response.data);
        })
        .catch(err => {
          this.logError(err);
          if (err.response?.data?.message === 'invalid redirectURI') {
            window?.history?.replaceState(
              '',
              document?.title,
              window?.location?.pathname + `?error=${err.response.data.message}`
            );
          }
          this.signout();
          cb();
        });
    } else {
      cb();
    }
  }

  getTcfPurposes = async locale => {
    if (locale === 'fr') {
      return await axios.get('https://static.axept.io/gvl/purposes-fr.json');
    } else {
      return await axios.get('https://static.axept.io/gvl/vendor-list.json');
    }
  };

  getGvl = async locale => {
    if (locale === 'fr') {
      return await axios.get('https://static.axept.io/gvl/vendor-list-fr.json');
    } else {
      return await axios.get('https://static.axept.io/gvl/vendor-list.json');
    }
  };

  getVendorsBaseQuery = (page = 1, perPage = 10, search = '', locale = { locale: 'en' }, selected = null) => {
    const params = new URLSearchParams({
      page: page,
      perPage: perPage,
      'data.__enable[bool]': 'true',
      'data.deprecated[bool]': 'false',
      sort: `data.title.__lang.${locale.locale.toLowerCase()}`
    });

    if (search.length > 0) {
      if (selected) {
        params.append(`data.title.__lang.${locale.locale.toLowerCase()}*`, search);
      } else {
        params.append(`searchTerm`, search);
      }
    }

    return params;
  };

  getVendorQuery = async (baseParams, additionalParams, selected) => {
    const params = new URLSearchParams(baseParams);
    for (const [key, value] of additionalParams) {
      params.append(key, value);
    }

    const body = {};

    if (selected) {
      body.ids = selected;
      return await this.client.post(`/vendors/solutions/documents:get?${params.toString()}`, body);
    }
    return await this.client.get(`/vendors/solutions/search?${params.toString()}`, body);
  };

  getIabVendors = (page, perPage, search, locale, selected = null) => {
    const baseParams = this.getVendorsBaseQuery(page, perPage, search, locale, selected);
    const additionalParams = new URLSearchParams({ 'data.iabId[lt]': '10000' });
    return this.getVendorQuery(baseParams, additionalParams, selected);
  };

  getAxeptioTcfVendors = (page, perPage, search, locale, selected = null) => {
    const baseParams = this.getVendorsBaseQuery(page, perPage, search, locale, selected);
    const additionalParams = new URLSearchParams({ 'data.iabId[gte]': '10000' });
    return this.getVendorQuery(baseParams, additionalParams, selected);
  };

  getAllVendors = (page, perPage, search, locale, selected = null) => {
    const baseParams = this.getVendorsBaseQuery(page, perPage, search, locale, selected);
    const additionalParams = new URLSearchParams({ 'data.iabId[gte]': '0' });
    return this.getVendorQuery(baseParams, additionalParams, selected);
  };

  getGoogleAtpVendors = (page, perPage, search, locale, selected = null) => {
    const baseParams = this.getVendorsBaseQuery(page, perPage, search, locale, selected);
    const additionalParams = new URLSearchParams({
      'data.iabId[gte]': '0',
      'data.google.atp.isCommonlyUsed[exists]': 'true'
    });
    return this.getVendorQuery(baseParams, additionalParams, selected);
  };

  getGoogleAtpCommonlyUsedVendors = (page, perPage, search, locale, selected = null) => {
    const baseParams = this.getVendorsBaseQuery(page, perPage, search, locale, selected);
    const additionalParams = new URLSearchParams({
      'data.iabId[gte]': '0',
      'data.google.atp.isCommonlyUsed[bool]': 'true'
    });
    return this.getVendorQuery(baseParams, additionalParams, selected);
  };

  getVendorsByIabIds = iabdIdsArray => {
    const baseParams = this.getVendorsBaseQuery();
    const additionalParams = new URLSearchParams({
      'data.iabId[in]': iabdIdsArray
    });
    return this.getVendorQuery(baseParams, additionalParams, null);
  };

  async updateUser(payload) {
    const response = await this.client.patch('/auth/me', payload);
    return response.data;
  }

  async updatePassword(payload) {
    const response = await this.client.put('/auth/local/update-password', payload);
    return response.data;
  }

  addAuthorizationHeader() {
    this.client.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
  }

  saveToken() {
    localStorage.setItem(Api.AUTH_TOKEN, this.token);
  }

  async signout() {
    try {
      await this.client.get('/auth/logout');
    } catch (e) {
      this.logError(e);
    }
    this.token = null;
    localStorage.removeItem(Api.AUTH_TOKEN);
    delete this.client.defaults.headers.common.Authorization;
    window.location = '/';
    this.dispatchChange('signout');
  }

  async auth(params, mode) {
    delete this.client.defaults.headers.common.Authorization;
    try {
      const { redirectURI, ...rest } = params;
      const url = `/auth/local/${mode}${redirectURI ? `?${new URLSearchParams({ redirectURI }).toString()}` : ''}`;
      const response = await this.client.post(url, rest);
      if (mode === 'reset-password-token') {
        return response.data.success;
      }
      this.token = response.data.token;
      this.saveToken();
      this.addAuthorizationHeader();
      const userResponse = await this.client.get('/auth/me');

      this.handleRedirectUri(response.data.redirectURI);
      await this.setCustomerSupportAllowedUsers();

      return userResponse.data;
    } catch (e) {
      localStorage.removeItem(Api.AUTH_TOKEN);
      throw e;
    }
  }

  handleRedirectUri = redirectURI => {
    if (!redirectURI) {
      return;
    }
    const redirectUri = new URL(redirectURI);
    redirectUri.searchParams.append('access_token', this.token);
    return (window.location.href = redirectUri.href);
  };

  me(cb) {
    this.client
      .get('/auth/me')
      .then(response => {
        cb(response.data);
      })
      .catch(() => {
        cb(null);
      });
  }

  async getUserByInvitationToken(invitationToken) {
    try {
      const response = await this.client.get(`/auth/invitations/${invitationToken}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  acceptInvitation(user, invitationToken, cb) {
    this.client
      .post(`/auth/invitations/${invitationToken}`)
      .then(response => cb(response.data))
      .catch(res => this.logError(res));
  }

  async deleteInvitation(invitationToken, cb) {
    this.client
      .delete(`/auth/invitations/${invitationToken}`)
      .then(response => cb(response.data))
      .catch(res => this.logError(res));
  }

  uploadFile(file, cb) {
    const url = '/assets';
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return this.client
      .post(url, formData, config)
      .then(response => {
        cb(url, response.data);
      })
      .catch(res => console.error(res));
  }

  getProjects(cb, pagination, query, searchTerm) {
    this.fetchDocs('projects', cb, { ...pagination }, query, searchTerm);
  }

  getProjectsWithConfs(pagination, filters, searchTerm, sort) {
    function formatRegionQuery(region) {
      if (!region?.length) {
        return {};
      }

      const countries = [];
      const subdivisions = [];

      region.forEach(region => {
        const [country, subdivision] = region.split('-');
        countries.push(country);
        if (subdivision) {
          subdivisions.push(subdivision);
        }
      });

      const query = { 'service.country': countries };
      query['service.subdivision'] = subdivisions;

      return query;
    }

    function formatSort(sort) {
      if (sort?.fieldName && sort?.order) {
        const prefix = sort.fieldName.startsWith('metadata.') ? '' : 'data.';
        return { sort: `${sort.order === 'desc' ? '-' : ''}${prefix}${sort.fieldName}` };
      }
      return {};
    }

    const { product, region, language, after, before, status, group } = filters;

    const filtersParamsForProjects = {
      ...(searchTerm?.length > 1 ? { term: searchTerm } : {}),
      ...(status?.length === 1 ? { published: status.includes('published') } : {}),
      ...(group?.length > 0 ? { groups: group.join(',') } : {}),
      ...(after ? { 'createdAt[after]': startOfDay(after).toISOString() } : {}),
      ...(before ? { 'createdAt[before]': endOfDay(before).toISOString() } : {})
    };

    const filtersParamsForConfigs = {
      ...(product?.length ? { services: product } : {}),
      ...formatRegionQuery(region),
      ...(language?.length ? { 'service.language': language } : {})
    };

    const controller = new AbortController();

    const response = this.client.get(`/vault/projects/extended`, {
      signal: controller.signal,
      params: {
        ...pagination,
        ...filtersParamsForProjects,
        ...filtersParamsForConfigs,
        ...formatSort(sort),
        noEmpty: Object.keys(filtersParamsForConfigs).length > 0,
        with: ['metadata'],
        embed: ['organization']
      }
    });

    return {
      response: response.catch(error => {
        if (axios.isCancel(error)) {
          return;
        }
        throw error;
      }),
      abort: () => controller.abort()
    };
  }

  fetchDocs(collection, cb, pagination, query = {}, searchTerm) {
    const queries = !!searchTerm ? { ...query, term: searchTerm } : query;
    const url = !!searchTerm && collection === 'projects' ? '/app/projects:search' : `/vault/${collection}`;
    this.client
      .get(`${url}?${new URLSearchParams(queries).toString()}`, {
        params: { ...pagination }
      })
      .then(response => {
        cb(
          response.data.map(entry => {
            const configuration = entry.data;
            configuration._id = entry.id;
            if (!query?.with?.includes('creator')) {
              configuration.ownerId = entry.createdBy;
            }
            query?.with?.map(prop => (configuration[prop] = entry[prop]));
            if (collection === 'projects') {
              configuration.createdAt = new Date(entry.createdAt);
            }
            return configuration;
          }),
          { ...response.headers }
        );
      });
  }

  getStats(projectId, params) {
    const key = `${projectId}${JSON.stringify(params)}`;
    const cached = this.statsLocalCache[key];

    // It is useful to get stats locally for development purpose
    // or to fix some UI issues.
    // https://github.com/agilitation/caas-styleguide#-mock-stats-locally
    if (process.env.REACT_APP_STATS_MOCK === 'true') {
      const response = axios.get(
        `${process.env.REACT_APP_BASE_URL}/stats/stats-${params.type}${params.aggregateBy ? `-${params.aggregateBy}` : ''}${
          params.dimension ? `-${params.dimension}` : ''
        }.json`,
        params
      );
      return { response: response.then(({ data }) => data), cancel: () => {} };
    }

    if (typeof cached === 'object' && cached.results) {
      return { response: Promise.resolve(cached), cancel: () => {} };
    }

    const { response, cancel } = this.post(`/stats/${projectId}`, { data: params });

    return {
      response: response.then(res => {
        if (!res) {
          return null;
        }
        this.statsLocalCache[key] = res.data;
        return res.data;
      }),
      cancel
    };
  }

  getOrganization(id, cb) {
    this.getDoc('organizations', id, 'default', {}, cb);
  }

  getDoc(type, id, state, queries = {}, cb) {
    this.client
      .get(`/vault/${type}/${id}`, { params: queries })
      .then(response => {
        cb({
          _id: response.data.id,
          id: response.data.id,
          groups: response.data.groups,
          createdAt: response.data.createdAt,
          createdBy: response.data.createdBy,
          metadata: response.data.metadata,
          ...response.data.data
        });
      })
      .catch(err => {
        console.error(err);
        cb(null);
      });
  }

  getProject(id, queries = {}, cb) {
    this.getDoc('projects', id, 'published', queries, cb);
  }

  getProjectAccessMapping(projectId, subscriptionStatuses = null) {
    const config = {};
    if (subscriptionStatuses) {
      config.params = { subscriptionStatuses };
    }

    const { response, cancel } = this.get(`/app/projects/${projectId}:get-services-access`, config);

    return {
      response: response.then(res => res?.data),
      cancel
    };
  }

  getPaginatedMedia(page) {
    return this.client
      .get(`/vault/media`, {
        params: { page }
      })
      .catch(err => {
        this.logError(err);
      });
  }

  deleteMedia(media, cb) {
    cb(null);
    this.client
      .delete(`/vault/media/${media.id}`)
      .then(response => {
        cb(true);
      })
      .catch(err => {
        this.logError(err);
        cb(false);
      });
  }

  createMedias(files, projectId, cb) {
    const medias = [];
    forEachAsync(
      files,
      (file, fileCb) => {
        this.upload(file).then(uploadResponse => {
          this.client.post('/vault/media', Object.assign({ projectId }, uploadResponse.data[0])).then(response => {
            medias.push(response.data);
            fileCb();
          });
        });
      },
      () => cb(medias)
    );
  }

  duplicateProject(projectId, payload, cb) {
    this.client.post(`/app/projects/${projectId}/duplicate`, payload).then(response => {
      cb(response.data.newProjectId);
    });
  }

  upload(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.client.post(`/assets`, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  createCookieVersion(project, data, cb) {
    return this.client
      .post('/vault/cookies', {
        projectId: project._id,
        ...data
      })
      .then(response => {
        cb(response.data);
        this.dispatchChange('cookies');
      });
  }

  saveProject(project, cb) {
    this.save('projects', project, cb);
  }

  async deleteProject(projectId) {
    const response = await this.client.delete(`/vault/projects/${projectId}`);
    return response.data;
  }

  /**
   * save document to docs.vault.* collection in DB
   * @param {string} collection  collection name (ex: projects)
   * @param {Object} doc the document to save
   * @param {?function} cb  callback function
   * @type cb
   */
  async save(collection, doc, cb) {
    // remove "s" from collection name projects => project
    const docType = collection.substring(0, collection.length - 1);
    let conf = {};
    if (!doc._id) {
      const newDoc = await this.client.post(`/vault/${collection}/`, doc);
      conf = newDoc.data.data;
      conf._id = newDoc.data.id;
    } else {
      const updatedDoc = await this.client.put(`/vault/${collection}/${doc._id}`, doc);
      conf = updatedDoc.data.data;
      conf._id = doc._id;
    }
    this.dispatchChange(collection);
    this.dispatchChange(docType, conf);
    if (typeof cb === 'function') {
      cb(conf);
    }
    return conf;
  }

  patchAsync = async (collection, id, diff) => {
    const docType = collection.substring(0, collection.length - 1);
    const response = await this.client.patch(`/vault/${collection}/${id}`, diff);
    const modifiedConf = response.data.data;
    modifiedConf._id = id;
    modifiedConf.id = id;
    this.dispatchChange(docType, modifiedConf);
  };

  patch(collection, id, diff, cb, errorCb) {
    const docType = collection.substring(0, collection.length - 1);
    this.client
      .patch(`/vault/${collection}/${id}`, diff)
      .then(response => {
        const modifiedConf = response.data.data;
        modifiedConf._id = id;
        modifiedConf.id = id;
        if (typeof cb === 'function') {
          cb(modifiedConf);
        }
        this.dispatchChange(collection);
        this.dispatchChange(docType, modifiedConf);
      })
      .catch(error => {
        if (typeof errorCb === 'function') {
          errorCb(error);
        } else {
          this.logError(error);
        }
      });
  }

  getProjectUsers(projectId, cb) {
    this.getUsersFrom('projects', projectId, cb);
  }

  getUsersFrom(collection, id, cb) {
    this.client.get(`/vault/${collection}/${id}/users`).then(response => {
      cb(response.data);
    });
  }

  removeUserFromProject(projectId, userId, cb) {
    this.removeUserFrom('projects', projectId, userId, cb);
  }

  removeUserFrom(collection, id, userId, cb) {
    this.client.delete(`/vault/${collection}/${id}/users/${userId}`).then(response => {
      cb(response.data);
      this.dispatchChange(collection, response);
    });
  }

  inviteTo({ collection, id, templateVars, email, displayName, language, cb }) {
    const payload = {
      email,
      data: {
        collection,
        id,
        templateVars: {
          ...templateVars,
          inviter: {
            _id: this.user._id,
            email: this.user.email,
            displayName: !!this.user.displayName?.trim() ? this.user.displayName : this.user.email
          }
        },
        language
      },
      displayName
    };

    this.client.post('/auth/invitations', payload).then(response => {
      cb(null, response.data);
    });
  }

  addUserTo(collection, id, userId, displayName, cb) {
    const payload = {
      roles: ['owner'],
      userId,
      displayName
    };
    this.client.post(`/vault/${collection}/${id}/users`, payload).then(response => {
      cb(response.data);
      this.dispatchChange(collection, response);
    });
  }

  handleZendeskSso = async () => {
    const params = new URLSearchParams(window.location.search.substring(1));
    if (!params.get('return_to')) window.location.href = '/';
    params.delete('access_token');

    try {
      const res = await this.client.get(`/app/zendesk/sso?${params.toString()}`);
      if (!res?.data?.jwtEndpoint && !res?.data?.jwt) {
        return (window.location.href = '/');
      }
      const payload = { jwt: res.data.jwt, return_to: params.get('return_to') };
      const form = document.createElement('form');
      form.method = 'post';
      form.action = res.data.jwtEndpoint;
      document.body.appendChild(form);
      Object.entries(payload).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });
      form.submit();
    } catch (e) {
      this.logError(e);
    }
  };

  getOctobatInvoices = async params => {
    try {
      const res = await this.client.get(`/app/subscriptions/${params.subscriptionId}/invoices`);
      return res.data.invoices;
    } catch (e) {
      this.logError(e);
    }
  };

  getUnreadNotificationsCount = async () => {
    try {
      const response = await this.client.get(`/app/notifications/count-unread`);
      return response?.data?.nbUnreadNotifications || 0;
    } catch (e) {
      this.logError(e);
    }
  };

  getAllActiveNotifications = async () => {
    try {
      const response = await this.client.get(`/app/notifications/active`);
      return response?.data?.notifications;
    } catch (e) {
      this.logError(e);
    }
  };

  readOneNotification = async notificationId => {
    try {
      const response = await this.client.post(`/app/notifications/${notificationId}/read`);
      return response;
    } catch (e) {
      this.logError(e);
    }
  };

  getFormStrings = async (params = null) => {
    try {
      const config = isLiteralObject(params) ? { params } : {};
      const response = await this.client.get('templates/strings', config);
      return response.data.map(entry => entry.data);
    } catch (e) {
      this.logError(e);
    }
  };

  /**
   * Get available languages for a given service
   * @param {string} service Service name
   * @returns {Promise<string[]>} List of available languages
   */
  getAvailableLanguages = async service => {
    try {
      const response = await this.client.get(`templates/${service}/languages`);
      return response.data || [];
    } catch (e) {
      this.logError(e);
    }
  };

  /**
   * Get available locales for a given service
   * @param service Service name
   * @returns {Promise<unknown>} List of available locales
   */
  getAvailableLocales = async service => {
    try {
      const response = await this.client.get(`governance/${service}/locales`);
      return response.data || {};
    } catch (e) {
      this.logError(e);
    }
  };

  getLanguagePack = async language => {
    try {
      const response = await this.client.get(`templates/strings/pack?language=${language}`);
      return response.data;
    } catch (e) {
      this.logError(e);
    }
  };

  getLocalePack = async (language, country, subdivision) => {
    try {
      const response = await this.client.get(
        `templates/strings/pack?language=${language}&country=${country}&subdivision=${subdivision}`
      );
      return response.data;
    } catch (e) {
      this.logError(e);
    }
  };

  getTCFDefaultContent = async (language, country, subdivision) => {
    try {
      const response = await this.client.get(
        `templates/tcf/compiled/default?language=${language}&country=${country}&subdivision=${subdivision}`
      );
      return response.data;
    } catch (e) {
      this.logError(e);
    }
  };

  duplicateCookies = async (configId, title, name) => {
    const payload = { title, name };
    return await this.client.post(`vault/cookies/${configId}/duplicate`, payload);
  };

  duplicateTcf = async (configId, title, name) => {
    const payload = { title, name };
    return await this.client.post(`vault/tcf/${configId}/duplicate`, payload);
  };

  translateCookies = async (configId, useAI, language, country, subdivision) => {
    const payload = { useAI, language, country, subdivision };
    return await this.client.patch(`vault/cookies/${configId}/translate`, payload);
  };

  translateTcf = async (configId, useAI, language, country, subdivision) => {
    const payload = { useAI, language, country, subdivision };
    return await this.client.patch(`vault/tcf/${configId}/translate`, payload);
  };

  duplicateAndTranslateCookies = async (configId, title, name, useAI, language, country, subdivision) => {
    const payload = { title, name, useAI, language, country, subdivision };
    return await this.client.post(`vault/cookies/${configId}/duplicate-and-translate`, payload);
  };

  duplicateAndTranslateTcf = async (configId, title, name, useAI, language, country, subdivision) => {
    const payload = { title, name, useAI, language, country, subdivision };
    return await this.client.post(`vault/tcf/${configId}/duplicate-and-translate`, payload);
  };

  duplicateService = async (service, payload) => {
    try {
      await this.client.post(`vault/${service}`, payload);
    } catch (e) {
      this.logError(e);
    }
  };

  setCustomerSupportAllowedUsers = async () => {
    const defaultValue = [];

    const isAllowed = ['@axeptio.eu', '@agilitation.fr', '@axept.io'].some(domain => this.user?.email?.endsWith(domain));

    if (!isAllowed) {
      this.customerSupportAllowedUsers = defaultValue;
      return;
    }
    try {
      const response = await this.client.get('/support/users:get-allowed-cs-users');
      this.customerSupportAllowedUsers = response.data;
    } catch (e) {
      this.customerSupportAllowedUsers = defaultValue;
    }
  };

  isCurrentUserCustomerSupport() {
    return this.customerSupportAllowedUsers.includes(this.user?.email);
  }

  /**
   * Get applicable regulation for a given country and subdivision
   * @param country ISO 3166-1 alpha-2 country code
   * @param subdivision ISO 3166-2 subdivision code
   * @returns {Promise<unknown>}
   */
  getRegionRegulation = async (country, subdivision) => {
    try {
      const response = await this.client.get(`/governance/regulations/current?country=${country}&subdivision=${subdivision}`);
      return response.data;
    } catch (e) {
      this.logError(e);
    }
  };

  /**
   * Get available features for a given region
   * @param country ISO 3166-1 alpha-2 country code
   * @param subdivision ISO 3166-2 subdivision code
   * @returns {Promise<unknown>}
   */
  getRegionRegulationFeatures = async (country, subdivision) => {
    try {
      const response = await this.client.get(`/governance/regulations/features?country=${country}&subdivision=${subdivision}`);
      return response.data;
    } catch (e) {
      this.logError(e);
    }
  };

  /**
   * Get default values for a given region
   * @param country ISO 3166-1 alpha-2 country code
   * @param subdivision ISO 3166-2 subdivision code
   * @returns {Promise<unknown>}
   */
  getRegionRegulationDefaultValues = async (country, subdivision) => {
    try {
      const response = await this.client.get(
        `/governance/regulations/default-values?country=${country}&subdivision=${subdivision}`
      );
      return response.data;
    } catch (e) {
      this.logError(e);
    }
  };

  isCurrentUserFromAPaidOrganization = async organizationType => {
    if (!this.user?.groups?.find(group => group.startsWith('organization_'))) {
      return false;
    }

    const allowedOrganizationTypes = ['agency', 'enterprise'];
    if (!organizationType) {
      organizationType = allowedOrganizationTypes;
    }
    if (typeof organizationType === 'string') {
      organizationType = [organizationType];
    }
    if (!Array.isArray(organizationType)) {
      throw new Error('Invalid organization type');
    }
    organizationType.forEach(type => {
      if (!['agency', 'enterprise'].includes(type)) {
        throw new Error('Invalid organization type');
      }
    });

    try {
      const response = await this.client.get('/app/users/me:is-part-of-an-organization', { params: { organizationType } });
      return response.data;
    } catch (e) {
      this.logError(e);
      return false;
    }
  };

  countFreeTrialProjects = async () => {
    try {
      const response = await this.client.get('/app/projects:count-free-trials');
      return response.data;
    } catch (e) {
      this.logError(e);
      return 0;
    }
  };

  async addJob(job) {
    try {
      const response = await this.client.post('/automator/jobs', job);
      return response.data;
    } catch (error) {
      this.logError(error);
      throw error;
    }
  }

  async addScheduledJob(job) {
    try {
      const response = await this.client.post('/vault/schedulejobs', job);
      return response.data;
    } catch (error) {
      this.logError(error);
      throw error;
    }
  }

  async updateScheduledJob(jobId, job) {
    try {
      const response = await this.client.put(`/vault/schedule/${jobId}`, job);
      return response.data;
    } catch (error) {
      this.logError(error);
      throw error;
    }
  }

  async getVendorCategories() {
    try {
      const response = await this.client.get('/vendors/categories?perPage=0', { timeout: 40000 });
      return response.data;
    } catch (error) {
      this.logError(error);
      throw error;
    }
  }

  async getAxeptioVendors({ page, perPage, sort, keywords = '', categoryId = '' }) {
    const params = new URLSearchParams({ 'data.__enable[bool]': true, page, perPage, sort });

    if (keywords) {
      params.append('searchTerm', keywords);
    }
    if (categoryId) {
      params.append('data.categoryIds', categoryId);
    }

    const response = await this.client.get(`/vendors/solutions/search?${params}`);

    return {
      vendors: response.data,
      totalCount: parseInt(response.headers['x-total-count']),
      lastPage: parseInt(response.headers['x-last-page'])
    };
  }

  async getCustomVendors({ project, page, perPage, sort, keywords = '', categoryName = '' }) {
    if (this.user?.isAdmin) {
      const result = await this.client.get(`/auth/users/${project.createdBy}/access_token`);
      if (result?.data?.token) {
        this.client.interceptors.request.use(config => {
          config.headers.common['Authorization'] = `Bearer ${result.data.token}`;
          return config;
        });
      }
    }

    const query = { with: ['creator', 'parentId'] };
    if (keywords) {
      query['data.title*'] = keywords;
    }
    if (categoryName) {
      query['data.type*'] = categoryName;
    }

    const promise = new Promise(resolve =>
      this.fetchDocs('vendors', (vendors, headers) => resolve({ vendors, headers }), { page, perPage, sort }, query)
    );

    this.client.interceptors.request.handlers = [];

    const { vendors, headers } = await promise;

    return {
      vendors,
      totalCount: parseInt(headers['x-total-count']),
      lastPage: parseInt(headers['x-last-page'])
    };
  }

  async addProjectsGroup(newGroup) {
    try {
      const response = await this.client.post('/vault/projects-group', newGroup);
      return response.data;
    } catch (error) {
      this.logError(error);
      throw error;
    }
  }

  async updateProjectsGroup(groupId, data) {
    try {
      const response = await this.client.patch(`/vault/projects-group/${groupId}`, data);
      return response.data;
    } catch (error) {
      this.logError(error);
      throw error;
    }
  }

  async deleteProjectsGroup(groupId) {
    try {
      const response = await this.client.delete(`/vault/projects-group/${groupId}`);
      return response.data;
    } catch (error) {
      this.logError(error);
      throw error;
    }
  }

  async getProjectsGroups() {
    try {
      const response = await this.client.get('/vault/projects-group');
      return response.data?.sort((a, b) => a.data.name.localeCompare(b.data.name));
    } catch (error) {
      this.logError(error);
      throw error;
    }
  }

  logError = error => {
    if (this.baseURL.includes('localhost')) {
      console.error('Error', error);
      // eslint-disable-next-line
      console.log('resp', error.response);
    } else if (error.response) {
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    } else if (error.request) {
      console.error(error.request);
    } else {
      console.error('Error', error);
    }
  };
}

Api.AUTH_TOKEN = 'authentificationToken';

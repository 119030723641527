import hex2rgb from 'hex-to-rgb';

const COLORS = {
  v2: {
    YELLOW_TEXT: '#cc9700'
  },
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  YELLOW: '#ffc823',
  YELLOW_50: '#fffef9',
  YELLOW_100: '#FFFBEE',
  YELLOW_200: '#FFF4D4',
  YELLOW_300: '#FFEDBB',
  YELLOW_400: '#FFE7A1',
  YELLOW_500: '#FFE088',
  YELLOW_600: '#FFDA6E',
  YELLOW_700: '#FFD355',
  YELLOW_800: '#FFCD3B',
  YELLOW_900: '#FFCF46',
  YELLOW_100_D: '#FFC10E',
  YELLOW_200_D: '#F8B800',
  YELLOW_300_D: '#E4A900',
  YELLOW_400_D: '#CF9A00',
  YELLOW_500_D: '#715502',
  YELLOW_700_D: '#A77C00',
  YELLOW_800_D: '#926C00',
  YELLOW_900_D: '#7E5D00',
  BLUE: '#282E68',
  BLUE_50: '#f4f5fd',
  BLUE_100: '#edf0fd',
  BLUE_200: '#DFE1F3',
  BLUE_300: '#C5C8E9',
  BLUE_400: '#ABB0DF',
  BLUE_500: '#9198D5',
  BLUE_600: '#787FCB',
  BLUE_700: '#5E67C1',
  BLUE_800: '#4650B5',
  BLUE_900: '#3C459C',
  BLUE_100_D: '#252B61',
  BLUE_200_D: '#222759',
  BLUE_300_D: '#1F2452',
  BLUE_400_D: '#1D214B',
  BLUE_500_D: '#1A1E43',
  BLUE_600_D: '#171A3C',
  BLUE_700_D: '#141734',
  BLUE_800_D: '#11142D',
  BLUE_900_D: '#0E1126',
  ORANGE: '#f4a336',
  ORANGE_50: '#ffeed7',
  RED: '#FF5F5F',
  RED_50: '#FEF6F6',
  RED_100: '#ffdede',
  RED_200: '#F3AFAF',
  RED_300: '#F09E9E',
  RED_400: '#ED8C8C',
  RED_500: '#EB7A7A',
  RED_600: '#E86969',
  RED_700: '#E55757',
  RED_800: '#E24545',
  RED_900: '#E03434',
  RED_100_D: '#FF4545',
  RED_200_D: '#FF2C2C',
  RED_300_D: '#FF1212',
  RED_400_D: '#F80000',
  RED_500_D: '#DE0000',
  RED_600_D: '#C50000',
  RED_700_D: '#AB0000',
  RED_800_D: '#920000',
  RED_900_D: '#780000',
  REDORANGE: '#c2452a',
  REDORANGE_50: '#fff3f0',
  REDORANGE_100: '#ffe8e3',
  GREEN: '#C9E232',
  GREEN_50: '#f8fbe5',
  GREEN_100: '#f0f7cd',
  GREEN_200: '#e5f19d',
  GREEN_300: '#e2ef90',
  GREEN_400: '#deed82',
  GREEN_500: '#dbeb75',
  GREEN_600: '#d7ea68',
  GREEN_700: '#d4e85a',
  GREEN_800: '#d0e64d',
  GREEN_900: '#cde43f',
  GREEN_100_D: '#c4df20',
  GREEN_200_D: '#b5ce1d',
  GREEN_300_D: '#a5bc1b',
  GREEN_400_D: '#96aa18',
  GREEN_500_D: '#869816',
  GREEN_600_D: '#768713',
  GREEN_700_D: '#667511',
  GREEN_800_D: '#57630e',
  GREEN_900_D: '#47510b',
  GRAY_DARK: '#555555',
  GRAY: '#212121',
  GRAY_50: '#FAFAFA',
  GRAY_75: '#F2F2F2',
  GRAY_100: '#EDEDED',
  GRAY_150: '#EDEDED',
  GRAY_200: '#E0E0E0',
  GRAY_250: '#D3D3D3',
  GRAY_300: '#C7C7C7',
  GRAY_350: '#BABABA',
  GRAY_400: '#ADADAD',
  GRAY_450: '#A0A0A0',
  GRAY_500: '#949494',
  GRAY_550: '#878787',
  GRAY_600: '#7A7A7A',
  GRAY_650: '#6D6D6D',
  GRAY_700: '#616161',
  GRAY_750: '#545454',
  GRAY_800: '#474747',
  GRAY_850: '#3A3A3A',
  GRAY_900: '#2E2E2E'
};

const BRANDS_COLORS = {
  AXEPTIO: COLORS.YELLOW,
  JACADI: '#8798BF'
};

const MAIN_COLORS = {
  PRIMARY_COLOR: BRANDS_COLORS.AXEPTIO,
  SECONDARY_COLOR: COLORS.BLUE,
  TERTIARY_COLOR: COLORS.REDORANGE
};

const SHADOWS = {
  CONTROL_SHADOW: '0 1px 5px rgba(0, 0, 0, 0.12)',
  CARD_SHADOW: '0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 2px 1px -2px rgba(0, 0, 0, 0.02)',
  SELECTION_SHADOW: color => `0 0 0 3px rgba(${hex2rgb(color)}, 0.25);
`
};

export { MAIN_COLORS, BRANDS_COLORS, SHADOWS };
export default COLORS;

import countries from 'i18n-iso-countries';
import * as iso3166 from 'iso-3166-2';
import languages from '@cospired/i18n-iso-languages';
import {
  sanitizeCountryCode,
  sanitizeLanguageCodeWeak,
  sanitizeSubdivisionCode
} from '@axeptio/widget-client/src/utils/templateEngine.js';

class LocalizationBackOffice {
  static getCountryName(countryCode, displayedLocale) {
    let name;
    if (countryCode === 'EU') {
      // EU is not part of the iso-3166-1 standard, but it is reserved by
      // the ISO 3166 Maintenance Agency for use in the European Union
      switch (displayedLocale) {
        case 'fr':
          name = 'Union européenne';
          break;
        default:
          name = 'European Union';
      }
    } else {
      name = countries.getName(countryCode, displayedLocale);
    }
    return name;
  }

  static getSubdivisionName(countryCode, subdivisionCode, displayedLocale) {
    // TODO: Add i18n support for subdivisions
    // TODO: at the time of writing, I can't find any library to get translations for subdivisions names
    let name;
    if (countryCode && subdivisionCode) {
      name = iso3166.subdivision(countryCode, subdivisionCode)?.name;
    }
    return name;
  }

  static getLocaleNameByLocaleCode(localeCode, displayedLocale, defaultName) {
    const [languageCode, countryCode, subdivisionCode] = localeCode?.split('-');
    return this.getLocaleName(languageCode, countryCode, subdivisionCode, displayedLocale, defaultName);
  }

  static getLanguageNameByLocaleCode(localeCode, displayedLocale, defaultName) {
    const [languageCode] = localeCode?.split('-');
    return languages.getName(languageCode, displayedLocale) || defaultName;
  }

  static getRegionNameByLocaleCode(localeCode, displayedLocale, defaultName) {
    const [languageCode, countryCode, subdivisionCode] = localeCode?.split('-');
    const languageName = languages.getName(languageCode, displayedLocale);
    const countryName = this.getCountryName(countryCode, displayedLocale);
    const subdivisionName = this.getSubdivisionName(countryCode, subdivisionCode, displayedLocale);

    return languageName ? `${countryCode ? `${countryName}` : ''}${subdivisionCode ? ` (${subdivisionName})` : ''}` : defaultName;
  }

  static getLocaleName(languageCode, countryCode, subdivisionCode, displayedLocale, defaultName) {
    const sanitizedCountryCode = sanitizeCountryCode(countryCode);
    const sanitizedSubdivisionCode = sanitizeSubdivisionCode(subdivisionCode);
    const languageName = languages.getName(sanitizeLanguageCodeWeak(languageCode), displayedLocale);
    const countryName = this.getCountryName(sanitizedCountryCode, displayedLocale);
    const subdivisionName = this.getSubdivisionName(sanitizedCountryCode, sanitizedSubdivisionCode, displayedLocale);

    return languageName
      ? `${languageName}${sanitizedCountryCode ? ` - ${countryName}` : ''}${
          sanitizedSubdivisionCode ? ` (${subdivisionName})` : ''
        }`
      : defaultName;
  }
}

export default LocalizationBackOffice;

import React from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import COLORS from '../../constants/colors';
import Assets from '../../services/Assets';
import Img from '../Img/Img';
import { AdminStyles } from '../../base/AdminStyles';

const fullScreen = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100%;
  width: 100%;
`;

const MaintenanceStyles = createGlobalStyle`
  html {
    height: 100vh;
    height: -webkit-fill-available;
  }

  body {
    ${fullScreen};
    background: ${COLORS.WHITE};
  }
`;

const Style = styled.div`
  ${fullScreen};
`;

const Illustration = styled.div`
  position: relative;
  margin-bottom: 10px;

  img:first-child {
    position: absolute;
    top: 0;
    left: 50%;
  }
`;

const Box = styled.div`
  position: relative;
  padding: 30px;
  max-width: 500px;
  text-align: center;
  border-radius: 8px;
  background: ${COLORS.WHITE};

  img {
    margin-bottom: 20px;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    color: ${COLORS.GRAY_600};
  }
`;

const MaintenanceMode = ({ title, description }) => (
  <Style>
    <AdminStyles />
    <MaintenanceStyles />
    <Box>
      <Illustration>
        <img src={Assets.image('cookie-ordi-vide-opt')} width={90} alt="cookie-ordi-vide-opt" />
        <Img src={Assets.image('maintenance-mode')} width={100} />
      </Illustration>
      <h1>{title}</h1>
      {description && <p>{description}</p>}
    </Box>
  </Style>
);

MaintenanceMode.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default MaintenanceMode;

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import React from 'react';
import Card from './Card';
import styled from 'styled-components';
const Hamburger = styled.span`
  z-index: 1000;
  position: relative;
  top: 1px;
  display: block;
  width: 18px;
  height: 11px;
  opacity: 0.25;
  margin-right: 20px;
  cursor: row-resize;
  background: -webkit-linear-gradient(top, #000, #000 20%, #fff 0, #fff 40%, #000 0, #000 60%, #fff 0, #fff 80%, #000 0, #000);
  background: linear-gradient(180deg, #000, #000 20%, #fff 0, #fff 40%, #000 0, #000 60%, #fff 0, #fff 80%, #000 0, #000);
`;

export const CartDragHandle = SortableHandle(() => <Hamburger />);

const SortableContainerHOC = SortableContainer(props => (
  <div>
    {Array.isArray(props.children)
      ? props.children.map((child, index) =>
          React.cloneElement(child, {
            index: index,
            key: `item-${index}`
          })
        )
      : null}
  </div>
));

export const SortableCardList = props => (
  <SortableContainerHOC helperClass="modal_reorder_helper" useDragHandle onSortEnd={props.onSortEnd}>
    {props.children}
  </SortableContainerHOC>
);

export const SortableCard = SortableElement(props => <Card handle={<CartDragHandle />} {...props} />);

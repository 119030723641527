const publishMessages = {
  checking_subscription: {
    name: 'checking_subscription',
    title: 'Checking your subscription 👨\u200d✈️',
    isError: false
  },
  deploying: {
    name: 'deploying',
    title: 'Deploying configuration 🦸🏽\u200d♀️',
    isError: false
  },
  publishFile_success: {
    name: 'publishFile_success',
    title: 'Publishing is successful 🏖',
    isError: false
  },
  publishFile_error: {
    name: 'publishFile_error',
    title: 'Publishing has failed  😟',
    isError: true
  },
  invalidateFile_success: {
    name: 'invalidateFile_success',
    title: 'Previous version has been invalidated',
    isError: false
  },
  invalidateFile_error: {
    name: 'invalidateFile_error',
    title: 'Something wrong happened during invalidation 😟',
    isError: true
  },
  checkInvalidation_error: {
    name: 'checkInvalidation_error',
    title: 'Something wrong happened during invalidation 😟',
    isError: true
  },
  checkInvalidation_success: {
    name: 'checkInvalidation_success',
    title: 'Cache invalidation success 🚀',
    isError: false
  },
  checkInvalidation_progress: {
    name: 'checkInvalidation_progress',
    title: 'Checking cache invalidation 🧐',
    isError: false
  },
  putFile_success: {
    name: 'putFile_success',
    title: 'Upload OK 😀',
    isError: false
  },
  putFile_error: {
    name: 'putFile_error',
    title: 'Upload KO 😟',
    isError: true
  },
  complete: {
    name: 'complete',
    title: 'Publishing is a success 🏖',
    isError: false
  },
  save_customer: {
    name: 'save_customer',
    title: 'Saving customer information',
    isError: false
  },
  create_payment_source: {
    name: 'create_payment_source',
    title: 'Creating payment source',
    isError: false
  },
  update_subscription: {
    name: 'update_subscription',
    title: 'Updating subscription',
    isError: false
  },
  create_subscription: {
    name: 'create_subscription',
    title: 'Create subscription',
    isError: false
  },
  err_no_such_coupon: {
    name: 'err_no_such_coupon',
    title: 'The coupon you specified does not exists!',
    isError: true
  },
  started: {
    name: 'started',
    title: 'Deploy just started',
    isError: false
  },
  patching_project: {
    name: 'patching_project',
    title: 'Patching your configuration',
    isError: false
  },
  billing_error: {
    name: 'billing_error',
    title: 'Billing error',
    isError: true
  },
  billing_fetching: {
    name: 'billing_fetching',
    title: 'Fetching billing information',
    isError: false
  },
  save_tax_ids: {
    name: 'save_tax_ids',
    title: 'Saving Tax IDs',
    isError: false
  },
  cancel_subscription: {
    name: 'cancel_subscription',
    title: 'Cancelling your subscription',
    isError: false
  },
  creating_version_pdf: {
    name: 'creating_version_pdf',
    title: 'Generating PDF 🖨',
    description: '...'
  },
  uploading_version_pdf: {
    name: 'uploading_version_pdf',
    title: 'Deploying PDF',
    description: '...'
  },
  updating_version_pdf_url: {
    name: 'updating_version_pdf_url',
    title: 'Updating version',
    description: '...'
  },
  generating_version_json: {
    name: 'generating_version_json',
    title: 'Creating version configuration',
    description: '...'
  },
  uploading_version_json: {
    name: 'uploading_version_json',
    title: 'Deploying version 📤',
    description: '...'
  },
  delete_payment_source: {
    name: 'delete_payment_source',
    title: 'Removing payment source',
    isError: false
  },
  change_payment_source: {
    name: 'change_payment_source',
    title: 'Changing default payment source',
    isError: false
  }
};

export default publishMessages;
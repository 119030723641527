import React, { forwardRef } from 'react';
import styled from 'styled-components';
import hex2rgb from 'hex-to-rgb';
import COLORS, { MAIN_COLORS } from '../../constants/colors';
import FONTS from '../../constants/fonts';
import Icon from '../Icon/Icon';
import { withClient } from '@axeptio/widget-client/src/contexts/ClientContext.js';
import Img from '../Img/Img';
import Assets from '../../services/Assets';
import PropTypes from 'prop-types';

const buttonIconMixin = color => `
  .svgr {
    svg {
      line,
      circle,
      polyline {
        stroke: rgba(${hex2rgb(color)}, 0.8)};
      }

      rect,
      path,
      polygon {
        fill: rgba(${hex2rgb(color)}, 0.8)};
      }
    }
  }

  &:hover {
    .svgr {
      svg {
        line,
        circle,
        polyline {
          stroke: ${color};
        }

        rect,
        path,
        polygon {
          fill: ${color};
        }
      }
    }
  }
`;

const buttonMixin = color => `
  color: ${COLORS.WHITE};
  background-color: ${color};
  box-shadow: 0 3px 10px rgba(${hex2rgb(color)}, 0.15);

  ${buttonIconMixin(color)};

  &:hover {
    background-color: ${color};
    box-shadow: 0 5px 20px rgba(${hex2rgb(color)}, 0.3);

    &::after {
      background-color: ${COLORS.BLACK};
      opacity: 0.1;
    }
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 3px rgba(${hex2rgb(color)}, 0.4);
  }
`;

const buttonSimpleMixin = color => `
  color: ${color};
  background: rgba(${hex2rgb(color)}, 0.05);
  box-shadow: none;

  &:hover {
    background: none;
    box-shadow: none;

    &::after {
      background-color: ${color};
      opacity: 0.1;
    }
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 3px rgba(${hex2rgb(color)}, 0.25);
  }
`;

const buttonSlimMixin = color => `
  color: ${color};
  background: none;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
`;

export const ButtonStyle = withClient(styled.button.attrs(props => ({
  'data-feature': props.dataFeature
}))`
  outline: none;
  border: none;
  user-select: none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 14px 40px;
  font-family: ${FONTS.PRIMARY};
  font-size: 13px;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.075em;

  color: ${COLORS.GRAY_800};
  background-color: ${COLORS.GRAY_50};
  border-radius: 1000px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 3px 10px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  transition: all 0.2s ease;

  &::after {
    content: '';
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1000px;
    opacity: 0;
    transition: all 0.2s ease;
  }
  > img {
    margin-right: 8px;
  }
  > span {
    z-index: 20;
    position: relative;
  }

  &:-moz-focusring {
    outline: none;
  }

  ${buttonIconMixin(COLORS.GRAY_600)};

  ${props =>
    !props.disabled &&
    `
    > canvas {
      z-index: 30;
    }
  `};

  ${props =>
    !props.link &&
    !props.simple &&
    !props.slim &&
    !props.disabled &&
    `
      &:hover {
        background-color: ${COLORS.GRAY_100};
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 4px 20px rgba(0, 0, 0, 0.06);
      }
  `};

  ${props =>
    !props.disabled &&
    `
      &:active,
      &:focus {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.08);
      }
  `};

  ${props =>
    props.primary &&
    `
    ${buttonMixin(props.colors.main)};
  `};

  ${props =>
    props.secondary &&
    `
    ${buttonMixin(MAIN_COLORS.SECONDARY_COLOR)};
  `};

  ${props =>
    props.tertiary &&
    `
    ${buttonMixin(MAIN_COLORS.TERTIARY_COLOR)};
  `};

  ${props =>
    props.black &&
    `
    ${buttonMixin('#1f1f1f')};
  `};

  ${props =>
    props.link &&
    `
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.05em;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  `};

  ${props =>
    props.simple &&
    `
    box-shadow: none;
    background-color: ${COLORS.GRAY_50};

    ${
      props.primary &&
      `
      ${buttonSimpleMixin(props.colors.main)};
    `
    };

    ${
      props.secondary &&
      `
      ${buttonSimpleMixin(MAIN_COLORS.SECONDARY_COLOR)};
    `
    };

    ${
      props.tertiary &&
      `
      ${buttonSimpleMixin(MAIN_COLORS.TERTIARY_COLOR)};
    `
    };

    ${
      props.black &&
      `
      ${buttonSimpleMixin('#1f1f1f')};
    `
    };

    ${
      !props.primary &&
      !props.secondary &&
      !props.tertiary &&
      !props.black &&
      !props.disabled &&
      `
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    `
    };
  `};

  ${props =>
    props.slim &&
    `
    padding: 2px 4px;
    letter-spacing: 0.05em;
    box-shadow: none;
    background: none;
    border-radius: 4px;

    &::after {
      display: none;
    }

    ${
      props.iconAfter &&
      `
      margin-right: 0;
      padding-right: 0;
    `
    };

    ${
      props.primary &&
      `
      ${buttonSlimMixin(props.colors.main)};
    `
    };

    ${
      props.secondary &&
      `
      ${buttonSlimMixin(MAIN_COLORS.SECONDARY_COLOR)};
    `
    };

    ${
      props.tertiary &&
      `
      ${buttonSlimMixin(MAIN_COLORS.TERTIARY_COLOR)};
    `
    };

    ${
      props.black &&
      `
      ${buttonSlimMixin('#1f1f1f')};
    `
    };

    ${
      !props.disabled &&
      `
      &:active,
      &:focus {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.08);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    `
    };

    ${
      props.disabled &&
      `
      &:hover {
        background: none;
      }
    `
    };
  `};

  ${props =>
    props.small &&
    `
    padding: 10px 20px;
    font-size: 12px;
  `};

  ${props =>
    props.xsmall &&
    `
    padding: 5px 6px;
    font-size: 10px;
    margin: 4px;
  `};

  ${props =>
    props.medium &&
    `
    padding: 16px 40px;
    font-size: 14px;
  `};

  ${props =>
    props.large &&
    `
    padding: 18px 44px;
    font-size: 15px;
  `};

  ${props =>
    props.fluid &&
    `
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  `};

  ${props =>
    props.uppercase &&
    `
    text-transform: uppercase;
  `};

  ${props =>
    props.nowrap &&
    `
    white-space: nowrap;
  `};

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `};

  ${props =>
    props.customPadding &&
    `
    padding: ${props.customPadding};
  `};

  ${props =>
    props.blackFont &&
    `
    color: ${COLORS.GRAY_900};
  `};

  ${props =>
    props.displayRight &&
    `
    margin-left: auto;
    width: auto;
  `};

  ${props =>
    props.tabLink &&
    `
      position: relative;
      align-items: center;
      justify-content: left;
      text-align: left;
      padding: 13px;
      background: none;
      box-shadow: none;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0;
      color: ${COLORS.GRAY_700};
      border: 0;
      border-bottom: 1px solid ${COLORS.GRAY_250};
      border-radius: 0;
      margin-right: 0;

      span {
        position: unset;
      }
  `};
`);

const Button = forwardRef(({ children, className, dataFeature, ...props }, ref) => (
  <ButtonStyle ref={ref} className={className} dataFeature={dataFeature} {...props}>
    {props.iconBefore && <Icon name={props.iconBefore} size={18} />}
    <span data-feature={dataFeature}>{children}</span>
    {props.iconAfter && <Icon name={props.iconAfter} size={18} />}
  </ButtonStyle>
));

Button.propTypes = {
  disabled: PropTypes.bool,
  nowrap: PropTypes.bool,
  uppercase: PropTypes.bool,
  fluid: PropTypes.bool,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  xsmall: PropTypes.bool,
  small: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  slim: PropTypes.bool,
  iconAfter: PropTypes.string,
  simple: PropTypes.bool,
  link: PropTypes.bool
};

export const ButtonsStack = styled.div`
  button {
    margin-bottom: 10px;
  }
`;

export const ButtonsToolbar = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

  ${props =>
    props.alignRight &&
    `
    justify-content: flex-end;
  `};

  a,
  button {
    margin-right: ${props => (props.large ? `12px` : `4px`)};
  }
`;

export const ButtonLink = ({ children, href, target, ...props }) => (
  <ButtonStyle as="a" href={href} target={target} {...props}>
    {props.image && <Img src={Assets.image(props.image)} width={props.imageWidth} />}
    <span>{children}</span>
  </ButtonStyle>
);

const ButtonIconStyle = withClient(styled.button`
  outline: none;
  border: none;
  user-select: none;
  text-decoration: none;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 0;
  width: ${props => props.size ?? (props.small ? '28px' : '32px')};
  height: ${props => props.size ?? (props.small ? '28px' : '32px')};
  border-radius: 50%;
  cursor: pointer;

  ${buttonIconMixin(COLORS.GRAY_800)};

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    ${buttonIconMixin(COLORS.GRAY_900)};
  }

  &:active,
  &:focus,
  &[aria-expanded='true'] {
    background: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    ${buttonIconMixin(COLORS.GRAY_900)};
  }

  ${props =>
    props.primary &&
    `
    ${buttonIconMixin(props.colors.main)};
  `};

  ${props =>
    props.secondary &&
    `
    ${buttonIconMixin(MAIN_COLORS.SECONDARY_COLOR)};
  `};

  ${props =>
    props.tertiary &&
    `
    ${buttonIconMixin(MAIN_COLORS.TERTIARY_COLOR)};
  `};
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `};
`);

export const ButtonIcon = forwardRef(({ icon, small, primary, secondary, tertiary, ...props }, ref) => (
  <ButtonIconStyle ref={ref} {...props} primary={primary} secondary={secondary} tertiary={tertiary}>
    <Icon name={icon} size={small ? 20 : 22} />
  </ButtonIconStyle>
));

export default Button;

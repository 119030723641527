import React from 'react';

const MessageForm = React.lazy(() => import('./Processings/Helpers/MessageForm'));
const EmailForm = React.lazy(() => import('./Processings/Helpers/EmailForm'));
const WeekdaysForm = React.lazy(() => import('./Processings/Helpers/WeekdaysForm'));
const ContractCardV2 = React.lazy(() => import('./ContractsV2/ContractCardV2'));
const TCFCard = React.lazy(() => import('./TCF/TcfCard'));
const ProcessingCard = React.lazy(() => import('./Processings/ProcessingCard'));
const ContractCard = React.lazy(() => import('./Contracts/ContractCard'));
const CookiesCard = React.lazy(() => import('./Cookies/CookiesCard'));
const PortabilityCard = React.lazy(() => import('./Portability/PortabilityCard'));
const ProjectConsents = React.lazy(() => import('../Pages/ProjectConsents'));
const ProjectIntegration = React.lazy(() => import('../Pages/ProjectIntegration'));
const Subscription = React.lazy(() => import('../Pages/Subscription/Subscription'));
const Users = React.lazy(() => import('./Users'));
const ProjectAuditScanner = React.lazy(() => import('../Pages/ProjectAuditScanner'));
const ProjectAccountingDocs = React.lazy(() => import('../Pages/ProjectAccountingDocs'));
const ProjectDesign = React.lazy(() => import('../Pages/ProjectDesign'));
const ProjectForm = React.lazy(() => import('../ProjectForm/ProjectForm'));
const ProjectStatsV2 = React.lazy(() => import('../Pages/ProjectStatsV2/ProjectStatsV2'));
const MarketPlace = React.lazy(() => import('../Pages/MarketPlace'));
const Service = React.lazy(() => import('./Service'));
const ContractsEditorV2 = React.lazy(() => import('./ContractsV2/ContractsEditorV2'));
const TcfEditor = React.lazy(() => import('./TCF/TcfEditor'));
const DPOEditor = React.lazy(() => import('./DPO/DPOEditor'));
const CookiesEditor = React.lazy(() => import('./Cookies/CookiesEditor/CookiesEditor'));
const ProcessingEditor = React.lazy(() => import('./Processings/ProcessingEditor'));
const ContractsEditor = React.lazy(() => import('./Contracts/ContractsEditor/ContractsEditor'));
const PortabilityEditor = React.lazy(() => import('./Portability/PortabilityEditor/PortabilityEditor'));

const Map = {
  Services: {
    processings: Service,
    contracts: Service,
    contractsV2: Service,
    portability: Service,
    cookies: Service,
    dpo: DPOEditor,
    tcf: Service
  },
  Editors: {
    cookies: CookiesEditor,
    processings: ProcessingEditor,
    contracts: ContractsEditor,
    contractsV2: ContractsEditorV2,
    portability: PortabilityEditor,
    tcf: TcfEditor
  },
  Cards: {
    cookies: CookiesCard,
    portability: PortabilityCard,
    contracts: ContractCard,
    contractsV2: ContractCardV2,
    processings: ProcessingCard,
    tcf: TCFCard
  },
  ProjectConfiguration: {
    consents: ProjectConsents,
    integration: ProjectIntegration,
    subscription: Subscription,
    users: Users,
    scanner: ProjectAuditScanner,
    invoices: ProjectAccountingDocs,
    design: ProjectDesign,
    infos: ProjectForm,
    stats: ProjectStatsV2,
    marketplace: MarketPlace
  },
  ProcessingHelpersForms: {
    message: MessageForm,
    email: EmailForm,
    weekdays: WeekdaysForm
  },
  Templates: {
    processings: 'processingsTemplates',
    contracts: 'contractsTemplates',
    contractsV2: 'contractsTemplates',
    portability: 'portabilityTemplates',
    tcf: 'tcfTemplates'
  }
};
export default Map;

const augmentedConsentHelpers = [
  {
    name: 'message',
    label: 'Custom message field',
    description: 'Kind words or a question, simply add a message box into your form.',
    icon: 'helpers_custom',
    active: true,
    defaultName: 'message',
    defaultLabel: 'Leave us a comment'
  },
  {
    name: 'email',
    label: 'Dedicated email address',
    description: 'Be smart and fair and let users choose.',
    icon: 'helpers_mail',
    active: true,
    defaultName: 'email',
    defaultLabel: 'Feel free to put your spam email, no bad feelings 😘'
  },
  {
    name: 'weekdays',
    label: 'Choose between weekdays',
    description: 'Let your users tell you when it\'s a good day to contact them.',
    icon: 'helper_days',
    active: true,
    defaultName: 'weekdays',
    defaultLabel: 'Which day should we contact you?'
  },
  {
    name: 'timeOfDay',
    label: 'Choose a time of day',
    description: 'In the morning, during lunch or after work ? Let the users choose when it\'s the right time to ping them.',
    icon: 'helper_hours',
    active: true,
    defaultName: 'timeOfDay',
    defaultLabel: 'What time of day suits you the best?'
  },
  {
    name: 'frequency',
    label: 'Choose a custom frequency',
    description: 'Everyday, every week ? Once a year ? A personalized rythm will make the customers happy and your brand wil look smarter and more respectful.',
    icon: 'helper_frequency',
    active: false,
    defaultName: 'frequency'
  },
  {
    name: 'doubleOptIn',
    label: 'Delayed double opt-in',
    description: 'What if your user could test your marketing pressure. After a month, we ask him if it\'s OK. If he does not respond, his consent is withdrawn.',
    icon: 'icon_v2_integration',
    active: false,
    defaultName: 'doubleOptIn'
  }
];

export default augmentedConsentHelpers;
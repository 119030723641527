import React from 'react';
import styled from 'styled-components';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';
import hex2rgb from 'hex-to-rgb';
import { withClient } from '@axeptio/widget-client/src/contexts/ClientContext.js';

const BtnStyle = withClient(styled.button`
  &&&&&& {
    outline: none !important;
    border: none !important;
    user-select: none !important;
    text-decoration: none !important;
    text-transform: none !important;
    text-overflow: unset !important;
    background: none !important;
    overflow: unset !important;

    position: relative !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: auto !important;
    height: auto !important;
    min-width: auto !important;
    min-height: auto !important;
    margin: 0 !important;
    padding: 12px 8px !important;

    font-family: ${props => props.fonts.text.family || FONTS.PRIMARY}, sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 1.25 !important;
    white-space: normal !important;
    letter-spacing: 0 !important;
    border-radius: 0 !important;
    color: ${props => props.colors.button_text || COLORS.GRAY_800} !important;
    background-color: ${props => props.colors.button_bg || COLORS.WHITE} !important;
    cursor: pointer !important;
    transition: all 0.15s ease !important;
    box-shadow: 0 0 0 0 inset rgba(0, 0, 0, 0) !important;

    &:hover {
      color: ${props => props.colors.button_text_hover || COLORS.GRAY_600} !important;
      background-color: ${props => props.colors.button_bg_hover || COLORS.WHITE} !important;
      transform: none !important;
    }

    &:active,
    &:focus {
      z-index: 1000 !important;
      box-shadow: 0 0 0 30px inset rgba(0, 0, 0, 0.04) !important;
    }

    ${props =>
      props.primary &&
      `
      font-weight: 700 !important;
      color: ${props.colors.primary_button_text || COLORS.AXEPTIO} !important;
      background-color: ${props.colors.primary_button_bg || COLORS.WHITE} !important;

      &:hover {
        color: ${props.colors.primary_button_text_hover || COLORS.AXEPTIO} !important;
        background-color: ${props.colors.primary_button_bg_hover || COLORS.WHITE} !important;
      }

      &:active,
      &:focus {
        z-index: 1000 !important;
        box-shadow: 0 0 0 3px rgba(${hex2rgb(props.colors.primary_button_text_hover)}, 0.25) !important;
      }
    `};

    &:disabled {
      cursor: default !important;
      box-shadow: none !important;
    }
  }
`);

const defaultButtonBorderRadius = 8;

const getButtonGroupRadius = props => {
  return `${
    typeof props.widgetStyle.borderRadius === 'undefined' ? defaultButtonBorderRadius : props.widgetStyle.borderRadius
  }px !important`;
};
const ButtonGroupStyle = withClient(styled.div`
  &&&&&& {
    z-index: 200 !important;
    position: relative !important;
    display: flex !important;
    justify-content: center !important;
    align-items: stretch !important;
    width: 100% !important;
    border-top: 1px solid ${props => props.colors.button_border || COLORS.GRAY_100} !important;

    > ${BtnStyle.WrappedComponent} {
      flex: 1 1 100% !important;

      &:first-of-type {
        border-bottom-left-radius: ${props => getButtonGroupRadius(props)};
      }

      &:last-of-type {
        border-bottom-right-radius: ${props => getButtonGroupRadius(props)};
      }

      &:not(:last-child) {
        border-right: 1px solid ${props => props.colors.button_border || COLORS.GRAY_100} !important;
      }
    }
  }
`);

const ButtonGroup = ({ children, ...props }) => <ButtonGroupStyle {...props}>{children}</ButtonGroupStyle>;

const Btn = ({ children, ...props }) => {
  let definitiveProps = Object.assign({}, props);
  if (props.color) {
    definitiveProps.primary = props.color === 'primary';
    definitiveProps.secondary = props.color === 'secondary';
    definitiveProps.tertiary = props.color === 'tertiary';
  }
  return <BtnStyle {...definitiveProps}>{children}</BtnStyle>;
};

export { Btn, BtnStyle };
export default ButtonGroup;
